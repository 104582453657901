import { camelToSnake, CamelToSnakeCaseNested, pythonify } from "@ncs/ts-utils"

export interface PreparePortalParamOptions {
	snakeCasedValueParams?: string[] // Defaults to `["ordering"]`
	keysToNotPythonify?: string[]
	manualPagination?: boolean
}

/**
 * Handing casing transforms, plus removes pagination params because those are handled
 * further down the line.
 */
export function preparePortalParams<Params>(
	params: Params,
	options?: PreparePortalParamOptions
): CamelToSnakeCaseNested<Params>
export function preparePortalParams(params: null, options?: PreparePortalParamOptions): null
export function preparePortalParams(
	params: undefined,
	options?: PreparePortalParamOptions
): undefined
export function preparePortalParams<Params>(
	params: Params | null | undefined,
	options?: PreparePortalParamOptions
): CamelToSnakeCaseNested<Params> | null | undefined {
	// Portal expects some params to have their values pythonified as well.
	// Add to this list as necessary.
	const snakeCasedValueParams = options?.snakeCasedValueParams ?? ["ordering"]

	// Unless you're doing manual pagination and want them to stay in the URL,
	// remove these keys from the params if they're in there.
	const keysToRemove = options?.manualPagination ? [] : ["page", "pageSize"]

	if (params == null) {
		return params as null | undefined
	} else {
		return pythonify(
			Object.fromEntries(
				Object.entries(params)
					.filter(([key]) => {
						return !keysToRemove.includes(key)
					})
					.map(([key, value]) => {
						if (snakeCasedValueParams.includes(key) && typeof value === "string") {
							return [key, camelToSnake(value)]
						} else {
							return [key, value]
						}
					})
			),
			options?.keysToNotPythonify ?? ["pageSize"]
		) as CamelToSnakeCaseNested<Params>
	}
}
