import { FC } from "react"
import { Box, Button, Heading, Paragraph } from "@ncs/web-legos"
import WelcomeDesktop from "./../../../images/other/welcome_desktop.png"
import WelcomeMobile from "./../../../images/other/welcome_mobile.png"
import { css, Theme } from "@emotion/react"
import { useAuth } from "@ncs/ncs-api/contexts"
interface WelcomeNewAccountProps {
	setToggleModal: (value: boolean) => void
}

export const WelcomeNewAccount: FC<WelcomeNewAccountProps> = ({ setToggleModal }) => {
	const auth = useAuth()

	const toggleModal = () => {
		setToggleModal(false)
	}

	return (
		<div css={modalBackground}>
			<Box css={box}>
				<Box textAlign="center" />
				<div css={container}>
					<img src={WelcomeDesktop} css={welcomeDesktop} alt="Welcome Desktop" />
					<img src={WelcomeMobile} css={welcomeMobile} alt="Welcome Mobile" />
					<Box css={modal}>
						<Paragraph bold color="secondary" mb={0.5} customColor="#003264">
							WELCOME
						</Paragraph>
						<Heading color="primary" variant="h2" bold customColor="#111827">
							{auth?.user?.name}
						</Heading>
						<Paragraph mt={1} mb={2} customColor="#374151">
							Here you can find the high-quality parts and chemicals you need to
							maximize your carwash’s uptime and profitability.
						</Paragraph>
						<Button width="100%" variant="primary-cta" onClick={toggleModal}>
							EXPLORE NOW
						</Button>
					</Box>
				</div>
			</Box>
		</div>
	)
}

const welcomeDesktop = (theme: Theme) => css`
	width: 50%;
	${theme.breakpoints.down("xs")} {
		display: none;
	}
`

const modalBackground = (theme: Theme) => css`
	position: fixed;
	z-index: 2;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.4);
`

const welcomeMobile = (theme: Theme) => css`
	display: none;
	${theme.breakpoints.down("md")} {
		display: none;
	}
	${theme.breakpoints.down("xs")} {
		display: inline;
	}
`

const box = (theme: Theme) => css`
	position: relative;
	border-radius: 1rem;
	max-width: 56rem;
	overflow: hidden;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	${theme.breakpoints.down("md")} {
		max-width: 40rem;
	}
	${theme.breakpoints.down("xs")} {
		max-width: 22rem;
	}
`

const container = (theme: Theme) => css`
	display: flex;
	${theme.breakpoints.down("xs")} {
		flex-direction: column;
	}
`

const modal = (theme: Theme) => css`
	padding: 9rem 3rem 9rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;

	${theme.breakpoints.down("md")} {
		padding: 2.5rem 2.5rem 2.5rem 0;
	}
	${theme.breakpoints.down("xs")} {
		padding: 0rem 2.5rem 2.5rem 2.5rem;
	}
`
