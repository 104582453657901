import React from "react"

import { css, Theme } from "@emotion/react"
import { TreeItem as MuiTreeItem, TreeItemProps as MuiTreeItemProps } from "@material-ui/lab"

import { Icon } from "../typography"

export const TreeItem: React.FC<MuiTreeItemProps> = ({ children, ...rest }) => {
	return (
		<MuiTreeItem
			css={style}
			expandIcon={<Icon icon="caret-right" family="solid" />}
			collapseIcon={<Icon icon="caret-down" family="solid" />}
			{...rest}
		>
			{children}
		</MuiTreeItem>
	)
}

const style = (theme: Theme) => css`
	&.MuiTreeItem-root {
		margin-bottom: 0.5rem;
		&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:not(:hover) {
			background: none;
		}
		&.Mui-expanded > .MuiTreeItem-content .MuiTreeItem-label {
			background-color: ${theme.palette.grey[100]} !important;
		}
	}
	.MuiTreeItem-group {
		margin-left: 2rem;
	}
	.MuiTreeItem-label {
		font-weight: bold;
		padding: 0.25rem 0 0 0.5rem;
		border-radius: ${theme.shape.borderRadius}px;
		transition: background-color 100ms ease-out;
	}
`
