/**
 * Truncates a string to a maximum number of characters and adds `...` to the end if needed.
 */
export const maxChars = (text: string, limit: number, suffix = "..."): string => {
	if (!text) {
		return text
	}

	return `${text.slice(0, limit).trim()}${text.length > limit ? suffix : ""}`
}

export interface YesOrNoOptions {
	fallBackToNo: boolean
	lowercase: boolean
}

const defaultYesOrNoOptions: YesOrNoOptions = {
	fallBackToNo: false,
	lowercase: false,
}

/**
 * Pass in whatever and test if it's a proper boolean value, and if so, return 'Yes' or 'No'.
 */
export const yesOrNo = (
	value: boolean | string | number | null | undefined,
	options?: Partial<YesOrNoOptions>
): string | null => {
	const { fallBackToNo, lowercase } = {
		...defaultYesOrNoOptions,
		...options,
	}

	let result: string | null = null

	if (typeof value === "boolean") {
		if (value === true) {
			result = "Yes"
		} else {
			result = "No"
		}
	}

	if (typeof value === "string") {
		if (value === "True" || value === "true" || value === "Yes") {
			result = "Yes"
		}
		if (value === "False" || value === "false" || value === "No") {
			result = "No"
		}
	}

	if (fallBackToNo && result === null) {
		result = "No"
	}

	if (!result) {
		return null
	}

	return lowercase ? result.toLowerCase() : result
}

/**
 * Trim white space, capitalize the very first letter, and add a period to the end if it does not exist already.
 */
export const sentence = (rawString: string | null | undefined, trailingSpace = true): string => {
	if (!rawString) {
		return ""
	}

	const str = rawString.trim()

	return `${str.slice(0, 1).toUpperCase()}${str.slice(1)}${str.endsWith(".") ? "" : "."}${
		trailingSpace ? " " : ""
	}`
}

export function titleCase(str: string): string
export function titleCase(str: null): null
export function titleCase(str: undefined): undefined
export function titleCase(str: string | null): string | null
export function titleCase(str: string | undefined): string | undefined
export function titleCase(str: null | undefined): null | undefined
export function titleCase(str: string | null | undefined): string | null | undefined {
	if (!str) return str

	return str
		.trim()
		.split(" ")
		.map((word) => `${word[0].toUpperCase()}${word.substring(1)}`)
		.join(" ")
}
