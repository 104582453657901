import { z } from "zod"

export enum PaymentsUrlPaths {
	Payments = "payments",
	Methods = "methods",
	BankAccounts = "bank_accounts",
	Cards = "cards",
	SetDefault = "set-default",
}

export enum PaymentSource {
	CARD = "card",
	BANK_ACCOUNT = "bank_account",
}

export enum PaymentProvider {
	SQUARE = "square",
	STRIPE = "stripe",
}

export enum PaymentMethod {
	CUSTOMER_ACCOUNT = "CUSTOMER_ACCOUNT",
	STORED_CARD = "STORED_CARD",
	STORED_BANK_ACCOUNT = "STORED_BANK_ACCOUNT",
	SQUARE_CARD = "SQUARE_CARD",
}

export enum CardType {
	CREDIT = "credit",
	DEBIT = "debit",
	PREPAID = "prepaid",
	UNKNOWN = "unknown",
}

/**
 * Portal's `CardBrand` enum.
 */
export enum CreditCardBrand {
	AMERICAN_EXPRESS = "amex",
	DINERS_CLUB = "diners",
	DISCOVER = "discover",
	JCB = "jcb",
	MASTERCARD = "mastercard",
	UNIONPAY = "unionpay",
	VISA = "visa",
	OTHER = "other",
}

/**
 * Portal's `SquareCardBrand` enum. It's what comes back from Square:
 * https://developer.squareup.com/reference/square/objects/Card
 */
export enum SquareCardBrand {
	AMERICAN_EXPRESS = "AMERICAN_EXPRESS",
	DINERS_CLUB = "DISCOVER_DINERS",
	DISCOVER = "DISCOVER",
	JCB = "JCB",
	MASTERCARD = "MASTERCARD",
	UNIONPAY = "CHINA_UNIONPAY",
	VISA = "VISA",
	OTHER = "OTHER_BRAND",

	// In Square's TS typings, this enum is given `UNKNOWN` as the key for
	// OTHER_BRAND, rather than `OTHER`. Unclear which is correct.
	UNKNOWN = "OTHER_BRAND",
}

export const CreditCardSchema = z.object({
	id: z.string(),
	last4: z.string(),
	expMonth: z.number(),
	expYear: z.number(),
	brand: z.nativeEnum(CreditCardBrand),
	provider: z.literal("square"),
	type: z.string(),
})
/** What the Portal returns for a card in Customer Payment Methods. */
export type CreditCard = z.infer<typeof CreditCardSchema>

export interface AddSquareCardPost {
	provider: "square"
	nonce: string
	verificationToken: string
	card: {
		brand: SquareCardBrand
		last4: string
		expMonth: number
		expYear: number
		digitalWalletType: string
	}
	contact: {
		givenName: string
		familyName: string
		email: string // Can be empty string.
		address: {
			organization: string // Can be empty string.
			line1: string
			line2: string
			city: string
			state: string
			postalCode: string
			country: string
		}
	}
}

export enum StripeBankAccountType {
	INDIVIDUAL = "individual",
	COMPANY = "company",
}

export enum StripeBankAccountStatus {
	NEW = "new", // Just created, still needs to be verified.
	VALIDATED = "validated", // Validated just means it's a real account, I think.
	VERIFIED = "verified", // It's verified after the micro deposit transactions are confirmed.
	VERIFICATION_FAILURE = "verification_failure",
	ERRORED = "errored",
}

export const StripeBankAccountSchema = z.object({
	id: z.string(),
	accountHolderName: z.string(),
	accountHolderType: z.nativeEnum(StripeBankAccountType),
	accountNumberLast4: z.string(),
	bankName: z.string(),
	country: z.string(),
	currency: z.string(),
	provider: z.literal("stripe"),
	routingNumber: z.string(),
	status: z.nativeEnum(StripeBankAccountStatus),
})
export type StripeBankAccount = z.infer<typeof StripeBankAccountSchema>

export interface AddStripeBankAccountPost {
	provider: "stripe"
	accountType: string
	accountName: string
	routingNumber: string
	accountNumber: string
	currency: string
	country: string
}

export interface VerifyStripeBankAccountPost {
	amount1: number
	amount2: number
}

export interface CustomerPaymentMethods {
	bankAccounts: StripeBankAccount[]
	cards: CreditCard[]
	defaultPaymentMethod: null | {
		id: string
		source: PaymentSource
	}
}

export type SourceProviderPair =
	| {
			source: PaymentSource.BANK_ACCOUNT
			provider: PaymentProvider.STRIPE
	  }
	| {
			source: PaymentSource.CARD
			provider: PaymentProvider.SQUARE
	  }

export type SetDefaultPaymentMethodPost = SourceProviderPair & {
	id: string
}
