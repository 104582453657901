import { OldLookup } from "../common"
import { PurchaseOrderStatusId } from "../parts-order"
import { ErpStatusId } from "../workorders"

export enum LookupsUrlPaths {
	ApplicationAssignments = "application_assignments",
	Applications = "applications",
	BinTypes = "bin_types",
	BusinessUnits = "business_units",
	CallTypes = "call_types",
	Containers = "containers",
	ContractCoverageTypes = "contract_coverage_types",
	ContractEntityTypes = "contract_entity_types",
	CreditMemoReasons = "credit_memo_reasons",
	CustomerClasses = "customer_classes",
	CustomerContactRequestType = "customer_contact_request_type",
	CustomerFreightClasses = "customer_freight_classes",
	CustomerSubclasses = "customer_subclasses",
	CustomerTypes = "customer_types",
	DefaultApplicationAssignments = "default_application_assignments",
	DefaultReportAssignments = "default_report_assignments",
	DispatchPriorities = "dispatch_priorities",
	DispatchStatuses = "dispatch_statuses",
	EquipmentRepairProposalStatuses = "equipment_repair_proposal_statuses",
	EquipmentVintages = "equipment_vintages",
	Groups = "groups",
	InventoryRequestStatus = "inventory_request_status",
	InventoryType = "inventory_type",
	InvoiceStatuses = "invoice_statuses",
	Lookups = "lookups",
	LtlCarrier = "ltl_carrier",
	OrderLineOverrideReason = "order_line_override_reason",
	PartOrderStatuses = "part_order_statuses",
	PartProductTypes = "part_product_types",
	PermanentPriceUpdateStatus = "permanent_price_update_status",
	Portals = "portals",
	PortalUsers = "portal_users",
	PurchaseOrderStatus = "purchase_order_status",
	SalesmanRoles = "salesman_roles",
	SalesmanTypes = "salesman_types",
	SiteProfitMonths = "site_profit_months",
	VacuumFilterGauge = "vacuum_filter_gauge",
	VacuumSystemGauge = "vacuum_system_gauge",
	Vendors = "vendors",
	Warehouses = "warehouses",
	WorkOrderLineItemTypes = "work_order_line_item_types",
}

export interface EqpModel {
	id: string
	family: {
		id: string
		code: string
		description: string
	} | null
	isCurrent: boolean
	manufacturer: {
		id: string
		code: string
		name: string
	} | null
	modelName: string
}

export interface EquipmentVintageLookup {
	id: string
	isActive: boolean
	isCurrent: boolean
	isInternationalization: boolean
	isPrimary: boolean
	model: EqpModel | null
	name: string | null
}

export interface InvoiceStatus {
	id: string
	code: string
	description: string
}

export type PurchaseOrderStatus = OldLookup<PurchaseOrderStatusId>

export interface Warehouse {
	id: string
	description: string
	locationCode: string
}

export interface PriceOverrideReason<Id extends string | number = string> {
	id: Id
	description: string
	isBillable: boolean
	reasonTypeId: PriceOverrideReasonTypeId
	textField: boolean
}

export enum PriceOverrideReasonTypeId {
	Default = 1,
	Freight = 2,
}

export interface LtlCarrier {
	id: string
	description: string
}

export enum LtlCarrierId {
	Other = "1",
	FedEx = "2",
}

/**
 * Ie, super_user, what you get back when you lookup all groups.
 */
export interface RoleGroup {
	id: string
	code: string
	description: string
}

export interface SalesmanRole {
	id: string
	description: string
}

export interface SalesmanType {
	id: string
	description: string
}

export interface ContractCoverageType<Id extends string | number = string> {
	id: Id
	description: string
}

export interface ErpStatus {
	id: ErpStatusId
	code: string
	description: string
}

export interface ProfitabilityMonth {
	id: number
	description: string
	value: string // Naive date
}

export interface Container {
	id: string
	name: string
	fillableHeight: string
	mlPerMm: string
	gallons: number | null
}

export interface PartProductType {
	id: string
	description: string
}

export interface InventoryRequestStatus {
	id: number
	description: string
}

export interface InventoryRequestType {
	id: number
	description: string
}

export interface BinType {
	id: string
	description: string
}

export interface PricingAdjustmentFormStatus {
	id: string
	name: string
}
