import { FC, memo } from "react"

import { css, Theme } from "@emotion/react"

import { PortalReport } from "@ncs/ncs-api"

import { BarChart } from "../charts"

export interface TableBarChartProps {
	report: PortalReport
}

export const TableBarChart: FC<TableBarChartProps> = memo(({ report }) => {
	return (
		<div css={chartContainerCss}>
			<BarChart report={report} height={225} />
		</div>
	)
})

const chartContainerCss = (theme: Theme) => css`
	background: ${theme.palette.grey[100]};
	border: 1px solid ${theme.palette.grey[100]};
	box-shadow: ${theme.tableBoxShadow};
	padding-top: 2rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
`
