import {
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPostMutation,
	DataExport,
	useDataExport,
	useGetRequest,
	useInfiniteGetRequest,
	usePostRequest,
} from "../../request-hooks"
import { CustomerDispatchReportQueryParams } from "../dispatches"
import { ReportAssignment, SecurityUrlPaths, UserProfile } from "../security"
import {
	AssignUserReportsPost,
	ChemicalRevSummaryReportLine,
	ChemicalRevSummaryReportQueryParams,
	CustomerPortalPurchasersReportLine,
	CustomerPortalPurchasersReportQueryParams,
	CustomerPortalUsageSummary,
	CustomerPortalUsageSummaryReportQueryParams,
	CustomerProfitReport,
	CustomerWashCountHistoryReportParams,
	DispatchCountsReportLine,
	DispatchCountsReportQueryParams,
	DispatchHistoryReportLine,
	DispatchHistoryReportQueryParams,
	DispatchReport,
	ErpDetailReportLine,
	ErpDetailReportQueryParams,
	ErpSummaryReportLine,
	ErpSummaryReportQueryParams,
	GetProfitReportsPost,
	InventoryConsolidationReportLine,
	InventoryConsolidationReportQueryParams,
	LaborRatesReportLine,
	LaborRatesReportQueryParams,
	MachineEventsReportParams,
	MilestoneDetailReportLine,
	MilestoneDetailReportQueryParams,
	NegativeOnHandReportLine,
	NegativeOnHandReportQueryParams,
	PartOrderDetailReportLine,
	PartOrderDetailReportQueryParams,
	PickListDetailReportLine,
	PickListDetailReportQueryParams,
	PmpiDetailReportLine,
	PmpiDetailReportQueryParams,
	PmpiSummaryReportLine,
	PmpiSummaryReportQueryParams,
	PnlReport,
	PortalReport,
	PricingModificationsReportLine,
	PricingModificationsReportQueryParams,
	ProfitBreakdownReport,
	Report,
	ReportParamKeys,
	ReportPermissions,
	ReportUrlPaths,
	SalesRepAssignmentsReportLine,
	SalesRepAssignmentsReportQueryParams,
	SalesRepDetailReportLine,
	SalesRepDetailReportQueryParams,
	SalesRepInvoicesReportLine,
	SalesRepInvoicesReportQueryParams,
	ServiceCentsPerWashReportParams,
	ServiceChemicalLevelsReportParams,
	ServiceTrendReportLine,
	ServiceTrendReportQueryParams,
	SiteSurveyGroupedReportLine,
	SiteSurveyGroupedReportQueryParams,
	SiteSurveyReportLine,
	SiteSurveyReportQueryParams,
	VacuumTrendReport,
	VacuumTrendReportParams,
	WarehouseOrderReportLine,
	WarehouseOrderReportQueryParams,
	WaterQualityReportParams,
	WeatherReportParams,
	WorkOrderDetailReportLine,
	WorkOrderDetailReportQueryParams,
} from "./types"

// Old style, replace when we can.
export const useDispatchCostsReport = (
	params?: Partial<CustomerDispatchReportQueryParams>
): ApiGetQuery<DispatchReport> => {
	return useGetRequest(
		[
			ReportUrlPaths.Reports,
			ReportUrlPaths.CustomerDashboard,
			ReportUrlPaths.Dispatches,
			ReportUrlPaths.DispatchesByMonth,
		],
		{
			params,
		}
	)
}

export const useCustomerWashCountHistoryReport = (
	params?: CustomerWashCountHistoryReportParams
): ApiGetQuery<PortalReport> => {
	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.Customer, ReportUrlPaths.WashCountHistory],
		{
			params,
		}
	)
}

export const useMachineEventsReport = (
	params?: MachineEventsReportParams
): ApiGetQuery<PortalReport> => {
	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.Customer, ReportUrlPaths.MachineEvents],
		{
			params,
		}
	)
}

export const useWaterQualityReport = (
	params?: WaterQualityReportParams
): ApiGetQuery<PortalReport> => {
	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.Customer, ReportUrlPaths.WaterQuality],
		{
			params,
		}
	)
}

export const useServiceChemicalLevelsReport = (
	params?: ServiceChemicalLevelsReportParams
): ApiGetQuery<PortalReport> => {
	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.Customer, ReportUrlPaths.ServiceChemicalLevels],
		{
			params,
		}
	)
}
export const useServiceCentsPerWashReport = (
	params?: ServiceCentsPerWashReportParams
): ApiGetQuery<PortalReport> => {
	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.Customer, ReportUrlPaths.ServiceCentsPerWash],
		{
			params,
		}
	)
}

/**
 * Use c12y's useCustomerWeather.
 * @deprecated
 */
export const useWeatherReport = (
	params?: Partial<WeatherReportParams>
): ApiGetQuery<PortalReport> => {
	const enabled = !!params && !!params[ReportParamKeys.Site]

	return useGetRequest<PortalReport, Partial<WeatherReportParams>>(
		[ReportUrlPaths.Reports, ReportUrlPaths.Customer, ReportUrlPaths.Weather],
		{
			params,
			queryConfig: {
				enabled,
			},
		}
	)
}

export const useVacuumTrendReport = (
	options: ApiGetQueryOptions<VacuumTrendReport, VacuumTrendReportParams>
): ApiGetQuery<VacuumTrendReport> => {
	const enabled = !!(
		options.params?.device &&
		options?.params.start_date &&
		options?.params?.end_date &&
		options?.params?.field
	)

	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.Connectivity, ReportUrlPaths.VacuumTrend],
		{
			...options,
			queryConfig: {
				enabled,
				...options?.queryConfig,
			},
		}
	)
}

export const useReports = (): ApiGetQuery<Report[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.ReportsList])
}

export const useReportAssignments = (defaultOnly?: boolean): ApiGetQuery<ReportAssignment[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.ReportAssignments], {
		params: {
			isDefault: defaultOnly || undefined,
		},
	})
}

export const useAssignUserReports = (): ApiPostMutation<AssignUserReportsPost, UserProfile> => {
	return usePostRequest([ReportUrlPaths.Reports, ReportUrlPaths.AssignUserReports], {
		keyToInvalidate: [ReportUrlPaths.Reports, SecurityUrlPaths.Security],
	})
}

export const useReportPermissions = (): ApiGetQuery<ReportPermissions> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.Permissions])
}

export const useChemicalRevSummaryReport = (
	options?: ApiInfiniteGetQueryOptions<
		ChemicalRevSummaryReportLine,
		ChemicalRevSummaryReportQueryParams
	>
): ApiInfiniteGetQuery<ChemicalRevSummaryReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.ChemicalDetail], options)
}

export const useExportChemicalRevSummaryReport = (
	params: ChemicalRevSummaryReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.ChemicalDetail], params)
}

export const useInventoryConsolidationReport = (
	options?: ApiGetQueryOptions<
		InventoryConsolidationReportLine[],
		InventoryConsolidationReportQueryParams
	>
): ApiGetQuery<InventoryConsolidationReportLine[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.PartConsolidation], options)
}

export const useExportInventoryConsolidationReport = (
	params: InventoryConsolidationReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.PartConsolidation], params)
}

export const useDispatchHistoryReport = (
	options?: ApiInfiniteGetQueryOptions<
		DispatchHistoryReportLine,
		DispatchHistoryReportQueryParams
	>
): ApiInfiniteGetQuery<DispatchHistoryReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.DispatchHistory], options)
}

export const useExportDispatchHistoryReport = (
	params: DispatchHistoryReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.DispatchHistory], params)
}

export const useErpDetailReport = (
	options?: ApiInfiniteGetQueryOptions<ErpDetailReportLine, ErpDetailReportQueryParams>
): ApiInfiniteGetQuery<ErpDetailReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.ErpDetail], options)
}

export const useExportErpDetailReport = (params: ErpDetailReportQueryParams): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.ErpDetail], params)
}

export const useErpSummaryReport = (
	options?: ApiInfiniteGetQueryOptions<ErpSummaryReportLine, ErpSummaryReportQueryParams>
): ApiInfiniteGetQuery<ErpSummaryReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.ErpSummary], options)
}

export const useExportErpSummaryReport = (params: ErpSummaryReportQueryParams): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.ErpSummary], params)
}

export const useLaborRatesReport = (
	options?: ApiInfiniteGetQueryOptions<LaborRatesReportLine, LaborRatesReportQueryParams>
): ApiInfiniteGetQuery<LaborRatesReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.LaborPricing], options)
}

export const useExportLaborRatesReport = (params: LaborRatesReportQueryParams): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.LaborPricing], params)
}

export const useMilestoneDetailReport = (
	options?: ApiInfiniteGetQueryOptions<
		MilestoneDetailReportLine,
		MilestoneDetailReportQueryParams
	>
): ApiInfiniteGetQuery<MilestoneDetailReportLine> => {
	return useInfiniteGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.MachineMilestone],
		options
	)
}

export const useExportMilestoneDetailReport = (
	params: MilestoneDetailReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.MachineMilestone], params)
}

export const useNegativeOnHandReport = (
	options?: ApiInfiniteGetQueryOptions<NegativeOnHandReportLine, NegativeOnHandReportQueryParams>
): ApiInfiniteGetQuery<NegativeOnHandReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.NegativeOnHand], options)
}

export const useExportNegativeOnHandReport = (
	params: NegativeOnHandReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.NegativeOnHand], params)
}

export const usePartOrderDetailReport = (
	options?: ApiInfiniteGetQueryOptions<
		PartOrderDetailReportLine,
		PartOrderDetailReportQueryParams
	>
): ApiInfiniteGetQuery<PartOrderDetailReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.PartOrderDetail], options)
}

export const useExportPartOrderDetailReport = (
	params: PartOrderDetailReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.PartOrderDetail], params)
}

export const usePickListDetailReport = (
	options?: ApiInfiniteGetQueryOptions<PickListDetailReportLine, PickListDetailReportQueryParams>
): ApiInfiniteGetQuery<PickListDetailReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.PickListDetail], options)
}

export const useExportPickListDetailReport = (
	params: PickListDetailReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.PickListDetail], params)
}

export const usePricingModificationsReport = (
	options?: ApiInfiniteGetQueryOptions<
		PricingModificationsReportLine,
		PricingModificationsReportQueryParams
	>
): ApiInfiniteGetQuery<PricingModificationsReportLine> => {
	return useInfiniteGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.PricingModifications],
		options
	)
}

export const useExportPricingModificationsReport = (
	params: PricingModificationsReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.PricingModifications], params)
}

export const useSalesRepInvoicesReport = (
	options?: ApiGetQueryOptions<SalesRepInvoicesReportLine[], SalesRepInvoicesReportQueryParams>
): ApiGetQuery<SalesRepInvoicesReportLine[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.SalesRepInvoices], options)
}

export const useSalesRepAssignmentsReport = (
	options?: ApiGetQueryOptions<
		SalesRepAssignmentsReportLine[],
		SalesRepAssignmentsReportQueryParams
	>
): ApiGetQuery<SalesRepAssignmentsReportLine[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.CustomerSalesRep], options)
}

export const useSalesRepDetailReport = (
	options?: ApiGetQueryOptions<SalesRepDetailReportLine[], SalesRepDetailReportQueryParams>
): ApiGetQuery<SalesRepDetailReportLine[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.SalesRepDetail], options)
}

export const useServiceTrendReport = (
	options?: ApiInfiniteGetQueryOptions<ServiceTrendReportLine, ServiceTrendReportQueryParams>
): ApiInfiniteGetQuery<ServiceTrendReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.ServiceTrend], options)
}

export const useExportServiceTrendReport = (params: ServiceTrendReportQueryParams): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.ServiceTrend], params)
}

export const useSiteSurveyReport = (
	options?: ApiInfiniteGetQueryOptions<SiteSurveyReportLine, SiteSurveyReportQueryParams>
): ApiInfiniteGetQuery<SiteSurveyReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.SiteSurvey], options)
}

export const useExportSiteSurveyReport = (params: SiteSurveyReportQueryParams): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.SiteSurvey], params)
}

export const useSiteSurveyGroupedReport = (
	options?: ApiGetQueryOptions<SiteSurveyGroupedReportLine[], SiteSurveyGroupedReportQueryParams>
): ApiGetQuery<SiteSurveyGroupedReportLine[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.SiteSurveyGrouped], options)
}

export const useWarehouseOrderReport = (
	options?: ApiInfiniteGetQueryOptions<WarehouseOrderReportLine, WarehouseOrderReportQueryParams>
): ApiInfiniteGetQuery<WarehouseOrderReportLine> => {
	return useInfiniteGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.WarehouseOrderReport],
		options
	)
}

export const useExportWarehouseOrderReport = (
	params: WarehouseOrderReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.WarehouseOrderReport], params)
}

export const useWorkOrderDetailReport = (
	options?: ApiInfiniteGetQueryOptions<
		WorkOrderDetailReportLine,
		WorkOrderDetailReportQueryParams
	>
): ApiInfiniteGetQuery<WorkOrderDetailReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.WorkOrderDetail], options)
}

export const useExportWorkOrderDetailReport = (
	params: WorkOrderDetailReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.WorkOrderDetail], params)
}

export const useDispatchCountsReport = (
	options?: ApiGetQueryOptions<DispatchCountsReportLine[], DispatchCountsReportQueryParams>
): ApiGetQuery<DispatchCountsReportLine[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.DispatchCounts], options)
}

export const usePmpiDetailReport = (
	options?: ApiInfiniteGetQueryOptions<PmpiDetailReportLine, PmpiDetailReportQueryParams>
): ApiInfiniteGetQuery<PmpiDetailReportLine> => {
	return useInfiniteGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.PmpiDetail], options)
}

export const useExportPmpiDetailReport = (
	params: WorkOrderDetailReportQueryParams
): DataExport => {
	return useDataExport([ReportUrlPaths.Reports, ReportUrlPaths.PmpiDetail], params)
}

export const usePmpiSummaryReport = (
	options?: ApiGetQueryOptions<PmpiSummaryReportLine[], PmpiSummaryReportQueryParams>
): ApiGetQuery<PmpiSummaryReportLine[]> => {
	return useGetRequest([ReportUrlPaths.Reports, ReportUrlPaths.PmpiSummary], options)
}

export const useGetProfitSummaryReport = (): ApiPostMutation<
	GetProfitReportsPost,
	PnlReport[]
> => {
	return usePostRequest([ReportUrlPaths.Reports, ReportUrlPaths.Profitability])
}

export const useGetProfitBreakdownReport = (): ApiPostMutation<
	GetProfitReportsPost,
	ProfitBreakdownReport[]
> => {
	return usePostRequest([ReportUrlPaths.Reports, ReportUrlPaths.ProfitSummary])
}

export const useGetCustomerProfitReport = (): ApiPostMutation<
	GetProfitReportsPost,
	CustomerProfitReport[]
> => {
	return usePostRequest([ReportUrlPaths.Reports, ReportUrlPaths.ArDetail])
}

export const useGetCustomerPortalUsageSummaryReport = (
	options?: ApiGetQueryOptions<
		CustomerPortalUsageSummary,
		CustomerPortalUsageSummaryReportQueryParams
	>
): ApiGetQuery<CustomerPortalUsageSummary> => {
	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.CustomerPortalUsageSummary],
		options
	)
}

export const useCustomerPortalPurchasersReport = (
	options?: ApiGetQueryOptions<
		CustomerPortalPurchasersReportLine[],
		CustomerPortalPurchasersReportQueryParams
	>
): ApiGetQuery<CustomerPortalPurchasersReportLine[]> => {
	return useGetRequest(
		[ReportUrlPaths.Reports, ReportUrlPaths.CustomerPortalPurchasers],
		options
	)
}
