import { captureMessage } from "@sentry/react"

import { isEnumMember } from "@ncs/ts-utils"

import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import { useEnumPropertyValidation, validateEnum } from "../../util"
import { APPLICATION } from "../auth"
import { LineItemType } from "../common"
import { InventoryUrlPaths, RepairPickListPatch, UpdatePickListPatch } from "../inventory"
import { LookupsUrlPaths } from "../lookups"
import { WorkordersUrlPath } from "../workorders"

import {
	AddPartOrderLineItemPost,
	AppliedPromotions,
	BulkReceivePartOrderPost,
	BulkReceivePurchaseOrderPost,
	CallFedExPost,
	CancelDropShipPost,
	CheckOrderItemsPost,
	CheckOrderItemsResult,
	CheckPartOrderItemLevelsPost,
	CreateLinePriceOverrideRequestPost,
	CreatePackageSizePost,
	CreatePartCrossReferencePost,
	CreatePartOrderPost,
	CreatePickListPost,
	CustomerPartOrder,
	CustomerPartOrderPost,
	CustomerPartOrderQueryParams,
	DefaultAccessorial,
	FinalizeNonGrimesPurchaseOrderPost,
	LineItemStockInfo,
	LineItemStockInfoQueryParams,
	OrderDeliveryCommentPost,
	OrderDocument,
	PartCrossReference,
	PartCrossReferenceQueryParams,
	PartOrder,
	PartOrderItemLevels,
	PartOrderList,
	PartOrderListQueryParams,
	PartOrderPickList,
	PartOrderShipment,
	PartOrderShipmentQueryParams,
	PartOrdersUrlPaths,
	PartOrderTrackingPost,
	Promotion,
	PurchaseOrder,
	PurchaseOrderLineItemPatch,
	PurchaseOrderLineItemPost,
	PurchaseOrderPatch,
	PurchaseOrderPost,
	PurchaseOrdersQueryParams,
	SchedulerDashboardData,
	ShipmentPackageSize,
	UpdatePartCrossReferencePatch,
	UpdatePartOrderApproveAdditionalEntryPatch,
	UpdatePartOrderLineItemPatch,
	UpdatePartOrderPatch,
	UpdatePartOverInvoicePatch,
	UpdateShipmentPatch,
	UpdateShipmentQuantitiesPatch,
	ValidatePromotionsPost,
} from "./types"

export const useCustomerPartOrder = (orderId?: string | null): ApiGetQuery<CustomerPartOrder> => {
	return useGetRequest([PartOrdersUrlPaths.CustomerOrders, orderId ?? ""], {
		queryConfig: {
			enabled: !!orderId,
		},
	})
}

export const initialCustomerPartOrderQueryParams: CustomerPartOrderQueryParams = {
	search: null,
	ordering: null,
	startDate: null,
	endDate: null,
	site: null,
	partOrderStatus: null,
}

export const useCustomerPartOrders = (
	options?: ApiInfiniteGetQueryOptions<CustomerPartOrder, CustomerPartOrderQueryParams>
): ApiInfiniteGetQuery<CustomerPartOrder> => {
	return useInfiniteGetRequest([PartOrdersUrlPaths.CustomerOrders], options)
}

export const useCreateCustomerPartOrder = (): ApiPostMutation<
	CustomerPartOrderPost,
	CustomerPartOrder
> => {
	return usePostRequest<CustomerPartOrderPost, CustomerPartOrder>(
		[PartOrdersUrlPaths.CustomerOrders],
		{
			keyToInvalidate: [PartOrdersUrlPaths.CustomerOrders],
			pythonifyExcludeList: ["last4"],
		}
	)
}

export const useCreatePunchoutOrder = (): ApiPostMutation<CustomerPartOrderPost, string> => {
	return usePostRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Punchout, PartOrdersUrlPaths.Create],
		{
			keyToInvalidate: [PartOrdersUrlPaths.CustomerOrders],
		}
	)
}

export const useCreatePartOrder = (): ApiPostMutation<CreatePartOrderPost, PartOrder> => {
	return usePostRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.Create],
		{
			keyToInvalidate: [PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.PartOrders],
		}
	)
}

export const usePartOrderDocuments = (
	partOrderId: string | null | undefined
): ApiGetQuery<OrderDocument[]> => {
	return useGetRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			partOrderId ?? "",
			PartOrdersUrlPaths.Documents,
		],
		{
			queryConfig: {
				enabled: !!partOrderId,
			},
		}
	)
}

// Normally this hook would ask for the ID as a param of the function it returns,
// not when you initially call the hook, but because of how the endpoint is setup
// on the server we have to do it this way.
export const useRecreatePartOrderDocuments = (partOrderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.RecreateOrderDoc, partOrderId],
		{
			keyToInvalidate: [PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.PartOrders],
		}
	)
}

export const useCheckPartOrderItemLevels = (): ApiPostMutation<
	CheckPartOrderItemLevelsPost,
	PartOrderItemLevels
> => {
	return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.CheckItemLevels])
}

export const useBulkReceivePartOrder = (): ApiPostMutation<BulkReceivePartOrderPost> => {
	return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.BulkReceivePartOrder], {
		keyToInvalidate: [
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.PurchaseOrders,
		],
	})
}

export const useCheckFullShipment = (partOrderId: string): ApiPostMutation<void, boolean> => {
	return usePostRequest([
		PartOrdersUrlPaths.PartOrders,
		partOrderId,
		PartOrdersUrlPaths.CheckFullShipment,
	])
}

export const useUpdatePartOrderApproveAdditionalEntry = (
	partOrderId: string,
	invoiceId: string | undefined
): ApiPatchMutation<UpdatePartOrderApproveAdditionalEntryPatch> => {
	return usePatchRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.AdditionalEntryApproval,
		],
		{
			keyToInvalidate: [
				[
					PartOrdersUrlPaths.Parts,
					PartOrdersUrlPaths.Order,
					PartOrdersUrlPaths.Detail,
					partOrderId ?? "",
				],
				[WorkordersUrlPath.Workorders, WorkordersUrlPath.Invoices, invoiceId ?? ""],
			],
		}
	)
}

export const useUpdatePartOrderInvoice = (
	partOrderId: string
): ApiPatchMutation<UpdatePartOverInvoicePatch> => {
	return usePatchRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			partOrderId ?? "",
			PartOrdersUrlPaths.Invoice,
		],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useSubmitPartOrder = (partOrderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PartOrders, partOrderId, PartOrdersUrlPaths.Submit],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useUpdateShipment = (): ApiPatchMutation<UpdateShipmentPatch> => {
	return usePatchRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Shipments], {
		keyToInvalidate: [
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
			LookupsUrlPaths.Lookups,
		],
	})
}

export const useUpdateShipmentQuantities = (): ApiPatchMutation<UpdateShipmentQuantitiesPatch> => {
	return usePatchRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Shipments], {
		urlPathsAfterId: [PartOrdersUrlPaths.UpdateQuantities],
		keyToInvalidate: [
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
			LookupsUrlPaths.Lookups,
		],
	})
}

export const useCompleteShipment = (): ApiPatchMutation => {
	return usePatchRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Shipments], {
		urlPathsAfterId: [PartOrdersUrlPaths.Complete],
		keyToInvalidate: [
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
			LookupsUrlPaths.Lookups,
		],
	})
}

export const useDeleteShipment = (): ApiDeleteMutation => {
	return useDeleteRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Shipments], {
		keyToInvalidate: [
			PartOrdersUrlPaths.PartOrders,
			PartOrdersUrlPaths.Parts,
			LookupsUrlPaths.Lookups,
		],
	})
}

export const useAddPartOrderTracking = (
	partOrderId: string
): ApiPostMutation<PartOrderTrackingPost> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PartOrders, partOrderId, PartOrdersUrlPaths.AddTrackingInfo],
		{
			keyToInvalidate: [
				PartOrdersUrlPaths.PartOrders,
				PartOrdersUrlPaths.Parts,
				InventoryUrlPaths.Inventory,
			],
		}
	)
}

export const useRecalculatePartOrder = (partOrderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PartOrders, partOrderId, PartOrdersUrlPaths.Recalculate],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useDeallocatePartOrder = (partOrderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PartOrders, partOrderId, PartOrdersUrlPaths.Deallocate],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useUploadPartOrderAttachment = (): ((
	file: File,
	partOrderId: string
) => Promise<void>) => {
	const request = usePostRequest<FormData, string>(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.UploadAttachment],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)

	const call = async (file: File, partOrderId: string): Promise<void> => {
		const data = new FormData()
		data.append("file", file)
		data.append("part_order_id", partOrderId)

		await request(data)
	}

	return call
}

export const useAddPartOrderLineItem = (): ApiPostMutation<AddPartOrderLineItemPost> => {
	return usePostRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.Line],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useUpdatePartOrderLineItem = (): ApiPatchMutation<UpdatePartOrderLineItemPatch> => {
	return usePatchRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.Line],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useDeletePartOrderLineItem = (): ApiDeleteMutation => {
	return useDeleteRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.Line],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const usePartOrderShipments = (
	options?: ApiGetQueryOptions<PartOrderShipment[], PartOrderShipmentQueryParams>
): ApiGetQuery<PartOrderShipment[]> => {
	return useGetRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Shipments], options)
}

export const initialPurchaseOrdersQueryParams: PurchaseOrdersQueryParams = {
	ordering: null,
	search: null,
	organization: null,
	region: null,
	enterprise: null,
	territory: null,
	customer: null,
	startDate: null,
	endDate: null,
	purchaseOrderStatus: null,
	sourceId: null,
	vendor: null,
	warehouse: null,
}

export const usePurchaseOrder = (orderId: string | null): ApiGetQuery<PurchaseOrder> => {
	return useGetRequest([PartOrdersUrlPaths.PurchaseOrders, orderId ?? ""], {
		queryConfig: {
			enabled: !!orderId,
			refetchOnWindowFocus: true,
		},
	})
}

export const usePurchaseOrders = (
	options?: ApiInfiniteGetQueryOptions<PurchaseOrder, PurchaseOrdersQueryParams>
): ApiInfiniteGetQuery<PurchaseOrder> => {
	return useInfiniteGetRequest([PartOrdersUrlPaths.PurchaseOrders], {
		...options,
		queryConfig: {
			refetchOnWindowFocus: true,
			...options?.queryConfig,
		},
	})
}

export const useCreatePurchaseOrder = (): ApiPostMutation<PurchaseOrderPost, PurchaseOrder> => {
	return usePostRequest([PartOrdersUrlPaths.PurchaseOrders], {
		keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
	})
}

export const useUpdatePurchaseOrder = (): ApiPatchMutation<PurchaseOrderPatch> => {
	return usePatchRequest([PartOrdersUrlPaths.PurchaseOrders], {
		keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
	})
}

export const useCancelPurchaseOrder = (orderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId, PartOrdersUrlPaths.Cancel],
		{
			keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
		}
	)
}

export const usePurchaseOrderDocuments = (
	orderId: string | null
): ApiGetQuery<OrderDocument[]> => {
	return useGetRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId ?? "", PartOrdersUrlPaths.Documents],
		{
			queryConfig: {
				enabled: !!orderId,
			},
		}
	)
}

export const useRecreatePurchaseOrderDocuments = (orderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId, PartOrdersUrlPaths.RecreateDocument],
		{
			keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
		}
	)
}

export const useUploadPurchaseOrderAttachment = (): ((
	file: File,
	purchaseOrderId: string
) => Promise<void>) => {
	const request = usePostRequest<FormData, string>(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.PurchaseOrder,
			PartOrdersUrlPaths.UploadAttachment,
		],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PurchaseOrders],
		}
	)

	const call = async (file: File, purchaseOrderId: string): Promise<void> => {
		const data = new FormData()
		data.append("file", file)
		data.append("purchase_order_id", purchaseOrderId)

		await request(data)
	}

	return call
}

export const useAddPurchaseOrderLineItem = (
	orderId: string
): ApiPostMutation<PurchaseOrderLineItemPost> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId, PartOrdersUrlPaths.LineItems],
		{
			keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
		}
	)
}

export const useUpdatePurchaseOrderLineItem = (
	orderId: string
): ApiPatchMutation<PurchaseOrderLineItemPatch> => {
	return usePatchRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId, PartOrdersUrlPaths.LineItems],
		{
			keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
		}
	)
}

export const useDeletePurchaseOrderLineItem = (orderId: string): ApiDeleteMutation => {
	return useDeleteRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId, PartOrdersUrlPaths.LineItems],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PurchaseOrders],
		}
	)
}

export const useBulkReceivePurchaseOrder = (): ApiPostMutation<BulkReceivePurchaseOrderPost> => {
	return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.ReceivePurchaseOrder], {
		keyToInvalidate: [
			PartOrdersUrlPaths.PurchaseOrders,
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.PartOrders,
		],
	})
}

export const useFinalizePurchaseOrder = (
	orderId: string | null
): ApiPostMutation<FinalizeNonGrimesPurchaseOrderPost | void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.FinalizePurchaseOrder, orderId ?? ""],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PurchaseOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useMakePurchaseOrderInProgress = (purchaseOrderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PurchaseOrders, purchaseOrderId, PartOrdersUrlPaths.OrderInProgress],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PurchaseOrders],
		}
	)
}

export const useForcePurchaseOrder = (orderId: string): ApiPostMutation<void> => {
	return usePostRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId, PartOrdersUrlPaths.ForceShip],
		{
			keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
		}
	)
}

export const useAddOrderDeliveryComment = (): ApiPostMutation<OrderDeliveryCommentPost> => {
	return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.AddDeliveryComment], {
		keyToInvalidate: [PartOrdersUrlPaths.PurchaseOrders, PartOrdersUrlPaths.Parts],
	})
}

export const useRecalculatePartOrderLineItem = (
	orderId: string
): ApiPostMutation<{ id: string }> => {
	return usePostRequest([PartOrdersUrlPaths.PartOrders, orderId, PartOrdersUrlPaths.LineItems], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: PartOrdersUrlPaths.RecalculateLine,
		keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
	})
}

export const useDeallocatePartOrderLineItem = (
	orderId: string
): ApiPostMutation<{ id: string }> => {
	return usePostRequest([PartOrdersUrlPaths.PartOrders, orderId, PartOrdersUrlPaths.LineItems], {
		addRequestDataIdToUrl: true,
		urlPathsAfterId: PartOrdersUrlPaths.DeallocateLine,
		keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
	})
}

export const useReopenPurchaseOrder = (orderId: string): ApiPatchMutation<void> => {
	return usePatchRequest(
		[PartOrdersUrlPaths.PurchaseOrders, orderId, PartOrdersUrlPaths.Reopen],
		{
			keyToInvalidate: PartOrdersUrlPaths.PurchaseOrders,
		}
	)
}

export const usePartOrderPickLists = (
	partOrderId: string | null | undefined
): ApiGetQuery<PartOrderPickList[]> => {
	return useGetRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.PickLists,
			partOrderId ?? "",
		],
		{
			queryConfig: {
				enabled: !!partOrderId,
			},
		}
	)
}

export const useCreatePartOrderPickList = (
	partOrderId: string
): ApiPostMutation<CreatePickListPost> => {
	return usePostRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.PickLists,
			partOrderId ?? "",
		],
		{
			keyToInvalidate: [
				PartOrdersUrlPaths.PartOrders,
				PartOrdersUrlPaths.Parts,
				InventoryUrlPaths.Inventory,
			],
		}
	)
}

export const useUpdatePartOrderPickList = (
	partOrderId: string
): ApiPatchMutation<UpdatePickListPatch> => {
	return usePatchRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.PickLists,
			partOrderId ?? "",
		],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useRepairPickListStatus = (
	partOrderId: string
): ApiPatchMutation<RepairPickListPatch> => {
	return usePatchRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.PickLists,
			partOrderId ?? "",
		],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

/**
 * Returns the accessorials that have been set as defaults for the passed customer.
 */
export const useDefaultAccessorials = (
	customer: string | null | undefined
): ApiGetQuery<DefaultAccessorial[]> => {
	const [data, ...request] = useGetRequest<DefaultAccessorial[]>(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.FreightAccessorial,
			PartOrdersUrlPaths.Customer,
			customer ?? "",
		],
		{
			queryConfig: {
				enabled: !!customer,
			},
		}
	)

	useEnumPropertyValidation(data, "lineItemTypeId", LineItemType)

	return [data, ...request]
}

export const usePartOrderList = (
	options?: ApiInfiniteGetQueryOptions<PartOrderList[number], PartOrderListQueryParams>
): ApiInfiniteGetQuery<PartOrderList[number]> => {
	return useInfiniteGetRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.Search],
		options
	)
}

export const usePartOrder = (partOrderId: string | null | undefined): ApiGetQuery<PartOrder> => {
	const [data, ...restOfResponse] = useGetRequest<PartOrder>(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.Detail,
			partOrderId ?? "",
		],
		{
			queryConfig: {
				enabled: !!partOrderId,
			},
		}
	)

	if (data) {
		data.lineItems.forEach((line) => {
			const lineType = line.lineTypeId
			if (!isEnumMember(lineType, LineItemType)) {
				captureMessage(`Unrecognized line item type received: ${lineType}`)
			}
			const app = line.overridePermission?.description
			if (app && !isEnumMember(app, APPLICATION)) {
				captureMessage(`Unrecognized APPLICATION received: ${app}`)
			}
		})
	}

	return [data, ...restOfResponse]
}

export const useUpdatePartOrder = (orderId: string): ApiPatchMutation<UpdatePartOrderPatch> => {
	return usePatchRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.Detail],
		{
			keyToInvalidate: [
				[
					PartOrdersUrlPaths.Parts,
					PartOrdersUrlPaths.Order,
					PartOrdersUrlPaths.Detail,
					orderId ?? "",
				],
				[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Order, PartOrdersUrlPaths.Search],
			],
		}
	)
}

export const useLineItemStockInfo = (
	lineItemId: string | null | undefined,
	params: LineItemStockInfoQueryParams,
	config?: ApiGetQueryOptions<LineItemStockInfo, LineItemStockInfoQueryParams>["queryConfig"]
): ApiGetQuery<LineItemStockInfo> => {
	return useGetRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.Line,
			lineItemId ?? "",
			PartOrdersUrlPaths.Info,
		],
		{
			...config,
			queryConfig: {
				enabled: !!lineItemId,
				...config,
			},
			params,
		}
	)
}

export const useCancelDropShip = (lineItemId: string): ApiPostMutation<CancelDropShipPost> => {
	return usePostRequest(
		[
			PartOrdersUrlPaths.Parts,
			PartOrdersUrlPaths.Order,
			PartOrdersUrlPaths.Line,
			lineItemId,
			PartOrdersUrlPaths.CancelDrop,
		],
		{
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		}
	)
}

export const useShipmentPackageSizes = (): ApiGetQuery<ShipmentPackageSize[]> => {
	return useGetRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.ShipmentPackageItems])
}

export const useCreateShipmentPackageSize = (): ApiPostMutation<
	CreatePackageSizePost,
	ShipmentPackageSize
> => {
	return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.ShipmentPackageItems], {
		keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
	})
}

export const useCurrentPromotions = (): ApiGetQuery<Promotion[]> => {
	return useGetRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Promotions])
}

export const useValidatePromotions = (): ApiPostMutation<
	ValidatePromotionsPost,
	AppliedPromotions[]
> => {
	return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Promotions])
}

export const useCallFedEx = (): ApiPostMutation<CallFedExPost> => {
	return usePostRequest(
		[PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.Shipments, PartOrdersUrlPaths.FedEx],
		{
			keyToInvalidate: [PartOrdersUrlPaths.Parts],
		}
	)
}

export const useSchedulerDashboardData = (): ApiGetQuery<SchedulerDashboardData> => {
	return useGetRequest([
		PartOrdersUrlPaths.Parts,
		PartOrdersUrlPaths.Order,
		PartOrdersUrlPaths.Scheduler,
		PartOrdersUrlPaths.Dashboard,
	])
}

export const useCheckOrderItems = (): ApiPostMutation<
	CheckOrderItemsPost,
	CheckOrderItemsResult
> => {
	return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.CheckOrderItems], {
		onSuccess: ({ data }) => {
			validateEnum(data.freight_totals?.line_item_type, LineItemType)
			validateEnum(data.freight_totals?.override_permission?.description, APPLICATION)
			validateEnum(data.fuel_surcharge?.line_item_type, LineItemType)
			validateEnum(data.fuel_surcharge?.override_permission?.description, APPLICATION)
			data.accessorials?.forEach((accessorial) => {
				validateEnum(accessorial.line_item_type_id, LineItemType)
			})
		},
	})
}

export const useCreateLinePriceOverrideRequest =
	(): ApiPostMutation<CreateLinePriceOverrideRequestPost> => {
		return usePostRequest([PartOrdersUrlPaths.Parts, PartOrdersUrlPaths.LineOverrideRequest], {
			keyToInvalidate: [PartOrdersUrlPaths.PartOrders, PartOrdersUrlPaths.Parts],
		})
	}

export const usePartCrossReferences = (
	options?: ApiInfiniteGetQueryOptions<PartCrossReference, PartCrossReferenceQueryParams>
): ApiInfiniteGetQuery<PartCrossReference> => {
	return useInfiniteGetRequest([PartOrdersUrlPaths.PartCrossReference], options)
}

export const usePartCrossReference = (
	referenceId: string | null | undefined,
	options?: ApiGetQueryOptions<PartCrossReference>
): ApiGetQuery<PartCrossReference> => {
	return useGetRequest([PartOrdersUrlPaths.PartCrossReference, referenceId ?? ""], {
		...options,
		queryConfig: {
			enabled: !!referenceId,
			...options?.queryConfig,
		},
	})
}

export const useCreatePartCrossReference = (): ApiPostMutation<CreatePartCrossReferencePost> => {
	return usePostRequest([PartOrdersUrlPaths.PartCrossReference], {
		keyToInvalidate: PartOrdersUrlPaths.PartCrossReference,
	})
}

export const useUpdatePartCrossReference = (): ApiPatchMutation<UpdatePartCrossReferencePatch> => {
	return usePatchRequest([PartOrdersUrlPaths.PartCrossReference], {
		keyToInvalidate: PartOrdersUrlPaths.PartCrossReference,
	})
}

export const useDeletePartCrossReference = (): ApiDeleteMutation => {
	return useDeleteRequest([PartOrdersUrlPaths.PartCrossReference], {
		keyToInvalidate: PartOrdersUrlPaths.PartCrossReference,
	})
}
