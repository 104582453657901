import { Accessorial, LineItemType } from "@ncs/ncs-api"

/**
 * @deprecated
 */
export const accessorialLineItems: Record<
	Accessorial,
	{
		name: Accessorial
		lineItemType: LineItemType
		label: string
		price: number
	}
> = {
	[Accessorial.PrivateResidence]: {
		name: Accessorial.PrivateResidence,
		lineItemType: LineItemType.PrivateResidence,
		label: "Private residence",
		price: 36.83,
	},
	[Accessorial.JobSiteDelivery]: {
		name: Accessorial.JobSiteDelivery,
		lineItemType: LineItemType.JobSiteDelivery,
		label: "Job site delivery",
		price: 65.38,
	},
	[Accessorial.TankerEndorsement]: {
		name: Accessorial.TankerEndorsement,
		lineItemType: LineItemType.TankEndorsement,
		label: "Tanker endorsement ",
		price: 200,
	},
	[Accessorial.PalletJack]: {
		name: Accessorial.PalletJack,
		lineItemType: LineItemType.PalletJack,
		label: "Pallet jack",
		price: 10,
	},
	[Accessorial.InsideDelivery]: {
		name: Accessorial.InsideDelivery,
		lineItemType: LineItemType.InsideDeliveryPickup,
		label: "Inside delivery/pickup",
		price: 86.31,
	},
	[Accessorial.GuaranteedDelivery]: {
		name: Accessorial.GuaranteedDelivery,
		lineItemType: LineItemType.GuaranteedDelivery,
		label: "Guaranteed delivery",
		price: 69.11,
	},
	[Accessorial.HighCostRegion]: {
		name: Accessorial.HighCostRegion,
		lineItemType: LineItemType.HighCostRegion,
		label: "High cost region",
		price: 28.23,
	},
	[Accessorial.AppointmentNotification]: {
		name: Accessorial.AppointmentNotification,
		lineItemType: LineItemType.AppointmentNotification,
		label: "Appointment notification",
		price: 24.15,
	},
	[Accessorial.LimitedAccess]: {
		name: Accessorial.LimitedAccess,
		lineItemType: LineItemType.LimitedAccess,
		label: "Limited access",
		price: 62.52,
	},
	[Accessorial.HandlingFee]: {
		name: Accessorial.HandlingFee,
		lineItemType: LineItemType.HandlingFee,
		label: "Handling fee",
		price: 96.62,
	},
	[Accessorial.LiftGate]: {
		name: Accessorial.LiftGate,
		lineItemType: LineItemType.LiftGate,
		label: "Lift gate",
		price: 0,
	},
}

export const isAccessorialLineItemKey = (
	key: string
): key is keyof typeof accessorialLineItems => {
	return Object.keys(accessorialLineItems).includes(key)
}
