import { useEffect, useRef } from "react"

import { captureMessage } from "@sentry/react"

import { arrayWrap, isEnumMember } from "@ncs/ts-utils"

/**
 * Pass in a value and an enum and a Sentry error will be created if the value is not
 * a recognized member of the enum.
 */
export const useEnumValidation = (value: string | null | undefined, enumToCheck: {}): void => {
	const hasChecked = useRef(false)

	useEffect(() => {
		if (value && hasChecked.current === false) {
			if (!isEnumMember(value, enumToCheck)) {
				const message = `Unrecognized enum value received: ${value}. Expected ${Object.values(
					enumToCheck
				).join(", ")}`
				console.error(message)
				captureMessage(message)
			}

			hasChecked.current = true
		}
	}, [value, enumToCheck])
}

/**
 * Take an object or a list of objects and check if a key on it is a valid enum member.
 * Reports to Sentry if it is not.
 */
export function useEnumPropertyValidation<T>(
	obj: T | T[] | null | undefined,
	key: keyof T,
	enumToCheck: {}
): void {
	const hasChecked = useRef(false)

	useEffect(() => {
		if (obj && hasChecked.current === false) {
			const toCheck = arrayWrap(obj)

			toCheck.forEach((item) => {
				if (item && item[key] && !isEnumMember(item[key], enumToCheck)) {
					const message = `Unrecognized enum value received: ${
						item[key]
					}. Expected ${Object.values(enumToCheck).join(", ")}`
					console.error(message)
					captureMessage(message)
				}
			})

			hasChecked.current = true
		}
	}, [obj, key, enumToCheck])
}

/**
 * Validate the enum in a non-hook way.
 */
export const validateEnum = (value: string | null | undefined, enumToCheck: {}): void => {
	if (value) {
		if (!isEnumMember(value, enumToCheck)) {
			const message = `Unrecognized enum value received: ${value}. Expected ${Object.values(
				enumToCheck
			).join(", ")}`
			console.error(message)
			captureMessage(message)
		}
	}
}
