import { FC, memo } from "react"

import { LtlCarrier, LtlCarrierId, useLtlCarriers } from "@ncs/ncs-api"

import { useChangeCallback } from "../../util"
import { ExtendableSelectProps, Select, ThrottledTextInput } from "../inputs"
import { AnimatedEntrance } from "../transitions"

export interface LtlCarrierSelectorProps extends ExtendableSelectProps<LtlCarrier> {
	newCarrierValue: string | null
	newCarrierOnChange: (newValue: string | null) => void
}

export const LtlCarrierSelector: FC<LtlCarrierSelectorProps> = memo(
	({ value, newCarrierValue, newCarrierOnChange, fillContainer, ...rest }) => {
		const [carriers, isLoading] = useLtlCarriers()

		// When value changes, clear out the "Other" text.
		useChangeCallback(value, (newId) => {
			if (newId !== LtlCarrierId.Other) {
				newCarrierOnChange(null)
			}
		})

		return (
			<>
				<Select
					value={value}
					isLoading={isLoading}
					label="LTL carrier"
					valueAccessor="id"
					textAccessor="description"
					fillContainer={fillContainer}
					{...rest}
					options={carriers ?? []}
					sortOptions
				/>

				<AnimatedEntrance show={value === LtlCarrierId.Other} direction="down" mt={0.5}>
					<ThrottledTextInput
						fillContainer={fillContainer}
						value={newCarrierValue}
						onChange={newCarrierOnChange}
						label="Other carrier name (required)"
					/>
				</AnimatedEntrance>
			</>
		)
	}
)
