import React, { memo, useMemo } from "react"

import { css } from "@emotion/react"

import { withCssUnit } from "@ncs/ts-utils"

import {
	collegeOfClean,
	csi,
	macneil,
	ncsBlue,
	ncsWhite,
	oem,
	ryko,
	services,
	tss,
	vacutech,
} from "../images/logos"

export type NcsBrand =
	| "collegeOfClean"
	| "csi"
	| "macneil"
	| "oem"
	| "ryko"
	| "services"
	| "tss"
	| "vacutech"

export type LogoNames = NcsBrand | "ncs" | "ncsWhite"

export interface LogoProps {
	company?: LogoNames
	maxWidth?: string | number
	/** Set either width or height, but not both. */
	width?: string | number
	/** Set either width or height, but not both. */
	height?: string | number
}

export const Logo: React.FC<LogoProps> = memo(({ company = "ncs", maxWidth, width, height }) => {
	if (!!width && !!height) {
		throw new Error(
			"Logo component is intended to be passed either a width or a height, but not both"
		)
	}

	const logoSrc = useMemo(() => {
		switch (company) {
			case "collegeOfClean": {
				return collegeOfClean
			}
			case "csi": {
				return csi
			}
			case "macneil": {
				return macneil
			}
			case "ncs": {
				return ncsBlue
			}
			case "ncsWhite": {
				return ncsWhite
			}
			case "ryko": {
				return ryko
			}
			case "oem": {
				return oem
			}
			case "services": {
				return services
			}
			case "tss": {
				return tss
			}
			case "vacutech": {
				return vacutech
			}
		}
	}, [company])

	const style = useMemo(() => {
		return css`
			display: inline-block;
			max-width: ${maxWidth != null ? withCssUnit(maxWidth) : "none"};
			width: ${withCssUnit(width)};
			height: ${withCssUnit(height)};
			img {
				${width && "height: auto; width: 100%;"}
				${height && "width: auto; height: 100%;"}
			}
		`
	}, [width, maxWidth, height])

	return (
		<div css={style}>
			<img src={logoSrc} alt={`${company} logo`} />
		</div>
	)
})
