import { Dispatch, ReactElement, SetStateAction, useMemo } from "react"

import { AmountDue, AmountDueSelector } from "../selectors"

export interface AmountDueQueryFilterProps<QueryParamState extends AmountDueQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

interface AmountDueQueryParams {
	paymentDue: string | null
	partiallyPaid: string | null
	paidInFull: string | null
}

export const AmountDueQueryFilter = <QueryParamState extends AmountDueQueryParams>({
	queryParamState,
	setQueryParamState,
}: AmountDueQueryFilterProps<QueryParamState>): ReactElement => {
	const value = useMemo(() => {
		if (queryParamState.paidInFull) return AmountDue.PaidInFull
		if (queryParamState.partiallyPaid) return AmountDue.PartiallyPaid
		if (queryParamState.paymentDue) return AmountDue.PaymentDue

		return null
	}, [queryParamState])

	const handleChange = (newValue: string | null) => {
		const amountDueQueryParams: AmountDueQueryParams = {
			paymentDue: null,
			partiallyPaid: null,
			paidInFull: null,
		}

		switch (newValue) {
			case AmountDue.PaidInFull: {
				amountDueQueryParams.paidInFull = "true"
				break
			}
			case AmountDue.PartiallyPaid: {
				amountDueQueryParams.partiallyPaid = "true"
				break
			}
			case AmountDue.PaymentDue: {
				amountDueQueryParams.paymentDue = "true"
				break
			}
		}

		setQueryParamState((prev) => ({
			...prev,
			...amountDueQueryParams,
		}))
	}

	return (
		<AmountDueSelector
			value={value}
			onChange={handleChange}
			fillContainer
			noSelectionOptionText="Any amount due"
			disableNoSelectionOption={false}
		/>
	)
}
