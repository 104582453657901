import React, { useState } from "react"
import { NavLink } from "react-router-dom"
import { APPLICATION, useAuth, useIsUser, UserId, useUserCanUse } from "@ncs/ncs-api"
import { Box, Button, Icon, Paragraph } from "@ncs/web-legos"
import { DbUserPinModal } from "~/shared-components"

export interface SiteNavigationProps {
	className?: string
	location?: "header" | "footer" | "modal"
}

export const SiteNavigation: React.FC<SiteNavigationProps> = React.memo(
	({ className, location = "header" }) => {
		const { isPrivilegedDbUser } = useAuth()
		const canUseConnectivity = useUserCanUse(APPLICATION.Connectivity)
		const canUseEquipment = useUserCanUse(APPLICATION.Equipment)
		const canUseDispatch = useUserCanUse([
			APPLICATION.CreateDispatch,
			APPLICATION.SearchDispatch,
		])
		const [showDbPinModal, setShowDbPinModal] = useState(false)

		// There are some special rules for the Driven Brands user account.
		const isDb = useIsUser(UserId.DrivenBrands)

		return (
			<nav className={className}>
				<ul>
					{isDb && location === "header" && (
						<li>
							{isPrivilegedDbUser ?
								<Box d="flex" gap={0.5} alignItems="center" mt={-0.3}>
									<Icon icon="check" color="gray" />
									<Paragraph secondary mt={0.3}>
										Viewing expanded parts list
									</Paragraph>
								</Box>
							:	<Button icon="lock" onClick={() => setShowDbPinModal(true)}>
									Enter Parts PIN
								</Button>
							}
						</li>
					)}
					<li>
						<NavLink to="/shop">Shop</NavLink>
					</li>
					{canUseConnectivity && (
						<li>
							<NavLink to="/connectivity">Connectivity</NavLink>
						</li>
					)}
					{!isDb && canUseDispatch && (
						<li>
							<NavLink to="/service-requests">Service Requests</NavLink>
						</li>
					)}
					{!isDb && canUseEquipment && (
						<li>
							<NavLink to="/my-equipment">My Equipment</NavLink>
						</li>
					)}
					{!isDb && (
						<li>
							<NavLink to="/account">Account</NavLink>
						</li>
					)}
					{(location === "footer" || location === "modal") && (
						<NavLink to="/auth/logout">Sign Out</NavLink>
					)}
				</ul>

				{showDbPinModal && <DbUserPinModal onClose={() => setShowDbPinModal(false)} />}
			</nav>
		)
	}
)
