import React from "react"

import { css } from "@emotion/react"

export interface DisabledProps {
	disabled?: boolean
}

export const Disabled: React.FC<DisabledProps> = ({ disabled = true, children }) => {
	return <div css={[basedDisabledCss, disabled ? disabledCss : undefined]}>{children}</div>
}

const basedDisabledCss = css`
	opacity: 1;
	transition: opacity 150ms ease-out;
`
const disabledCss = css`
	opacity: 0.35;
	pointer-events: none;
	filter: saturate(0);
`
