import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"

import { useLocation } from "react-router-dom"

import { decodeUrlState, UrlState, useUrlStateContext } from "./UrlStateContext"

export type SetUrlState<State extends UrlState> = Dispatch<SetStateAction<State>>
export type UpdateUrlValue<State extends UrlState> = <Key extends keyof State>(
	key: Key,
	value: State[Key]
) => void
export type ResetUrlValues = () => void

export type UseUrlStateOptions = {
	/**
	 * When component unmounts, should we strip the state keys from the URL?
	 * * @default true
	 */
	removeKeysOnUnmount: boolean
	/**
	 * When one of your keys wasn't found in the URL, do you want to get null back, or just
	 * keeping returning your initial state value?
	 * @default false
	 */
	fallbackToInitialState: boolean
	/**
	 * @default null
	 */
	debuggingName: string | null
}

const defaultUseUrlStateOptions: UseUrlStateOptions = {
	removeKeysOnUnmount: true,
	debuggingName: null,
	fallbackToInitialState: false,
}

export const useUrlState = <State extends UrlState>(
	/**
	 * Provide the object that you want to keep in sync with the URL. The keys on this object
	 * are the keys that you get back.
	 */
	initialState: State,
	optionsArg?: Partial<UseUrlStateOptions>
): [
	State,
	{
		setUrlState: SetUrlState<State>
		updateUrlValue: UpdateUrlValue<State>
		resetUrlValues: ResetUrlValues
	},
] => {
	const location = useLocation()
	const [, queryParamDispatch] = useUrlStateContext()
	const keys = useRef(Object.keys(initialState))
	const hasInitialized = useRef(false)
	const [urlReady, setUrlReady] = useState(false)

	const options = {
		...defaultUseUrlStateOptions,
		...optionsArg,
	}

	// On first load, put this initial state object into master query param object.
	useEffect(() => {
		if (hasInitialized.current === false) {
			queryParamDispatch({
				type: "initialize slice",
				payload: { ...initialState },
			})
			hasInitialized.current = true
		}
	}, [queryParamDispatch, initialState])

	// Read the current values right from the URL.
	const decodedValues = useMemo(() => {
		return decodeUrlState(location.search)
	}, [location.search])

	// In order to allow reading directly from the URL, we have to confirm that every key
	// in the initial state is either present in the URL, or has a passed in initial value of
	// null. After that, we can just return what's found in the URL if it's there or go back
	// to null / initial value. Otherwise, we end up with a few frames right at URL change
	// where the initial values you passed in aren't present in the URL yet and you get back
	// a bunch of unexpected nulls.
	useEffect(() => {
		if (!urlReady) {
			const truthyKeysAccountedFor = Object.entries(initialState).every(([key, value]) => {
				return Object.keys(decodedValues).includes(key) || value == null
			})

			if (truthyKeysAccountedFor) {
				setUrlReady(true)
			}
		}
	}, [urlReady, decodedValues, initialState])

	// Calculate what to return back to the component as current state.
	const state = useMemo(() => {
		return Object.fromEntries(
			Object.entries(initialState).map(([key, value]) => {
				if (Object.keys(decodedValues).includes(key)) {
					return [key, decodedValues[key] ?? value]
				} else {
					// If the key wasn't found in the URL, then check if the URL has been set up or not.
					if (!urlReady) {
						return [key, initialState[key]]
					} else {
						// If URL is set up, then only return the initial value if we are set to fallback
						// to it. Also handle arrays.
						return [
							key,
							options.fallbackToInitialState ? value
							: Array.isArray(initialState[key]) ? []
							: null,
						]
					}
				}
			})
		) as State
	}, [initialState, options.fallbackToInitialState, decodedValues, urlReady])

	// When the component unmounts, remove its keys from the URL.
	useEffect(() => {
		const keysToRemove = keys.current

		return () => {
			if (options.removeKeysOnUnmount) {
				queryParamDispatch({
					type: "remove slice",
					payload: keysToRemove,
				})
			}
		}
	}, [queryParamDispatch, options.removeKeysOnUnmount])

	/**
	 * Update your URL slice object.
	 */
	const setUrlState: SetUrlState<State> = useCallback(
		(update) => {
			queryParamDispatch({
				type: "patch slice",
				payload: typeof update === "function" ? update(state) : update,
			})
		},
		[queryParamDispatch, state]
	)

	/**
	 * Provide one key/value pair to update.
	 */
	const updateUrlValue: UpdateUrlValue<State> = useCallback(
		(key, value) => {
			queryParamDispatch({
				type: "update value",
				payload: {
					key: key as string,
					value,
				},
			})
		},
		[queryParamDispatch]
	)

	/**
	 * Set the keys in this slice back to the initial values passed in.
	 */
	const resetUrlValues: ResetUrlValues = useCallback(() => {
		queryParamDispatch({
			type: "reset",
			payload: { ...initialState },
		})
	}, [queryParamDispatch, initialState])

	return [
		state,
		{
			setUrlState,
			updateUrlValue,
			resetUrlValues,
		},
	]
}

/**
 * A hacky fix. This system only syncs in the URL -> local state direction when the pathname
 * changes. That means if you change the search query params in the URL via a history push
 * with the `search` param but the pathname isn't different, the changes won't be seen.
 */
export const toggleTrailingSlash = (pathname: string): string => {
	return pathname.endsWith("/") ? pathname.slice(0, -1) : `${pathname}/`
}
