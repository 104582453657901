import React, { memo } from "react"

import { Box, faWeatherIconLookup, Icon, Paragraph } from "@ncs/web-legos"

export interface WeatherProps {
	temperature?: number | null
	icon?: string | null // Pass in the Open Weather API icon.
}

export const Weather: React.FC<WeatherProps> = memo(({ temperature, icon }) => {
	return (
		<Box display="flex">
			{!!temperature && (
				<Paragraph small mr={0.5}>
					{temperature}°
				</Paragraph>
			)}

			{!!icon && <Icon icon={faWeatherIconLookup(icon)} />}
		</Box>
	)
})
