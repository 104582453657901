import { FC } from "react"

import { MachineCodePriority } from "@ncs/ncs-api"

import { Select, SelectProps } from "../inputs"

export interface MachineCodePrioritySelectorProps
	extends Omit<SelectProps<(typeof options)[number]>, "options"> {
	value: string | null
}

const options = Object.entries(MachineCodePriority)
	.filter(([key]) => Number.isNaN(Number(key))) // Weirdness with enum's with numeric values.
	.map(([key, value]) => ({
		text: key,
		value,
	}))

export const MachineCodePrioritySelector: FC<MachineCodePrioritySelectorProps> = ({
	value,
	...rest
}) => {
	return (
		<Select
			label="Code priority"
			noSelectionOptionText="Any"
			disableNoSelectionOption={false}
			{...rest}
			options={options}
			value={value}
			valueAccessor={(option) => String(option.value)}
		/>
	)
}
