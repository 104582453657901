import dayjs, { Dayjs } from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import quarterOfYear from "dayjs/plugin/quarterOfYear"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.extend(quarterOfYear)

export enum DateFormat {
	Date = "MMM DD, YYYY",
	DateQueryParam = "YYYY-MM-DD",
	DateShort = "MM/DD/YY",
	DateTime = "MMM DD, YYYY h:mm a",
	DateTimeShort = "MM/DD/YY h:mm a",
	DateTimeLong = "dddd, MMM DD, YYYY, h:mm:ss a",
	DateTimeQueryParam = "YYYY-MM-DDTHH:mm:ss.SSSZ", // cspell:disable-line
	DateTimeQueryParamNoTz = "YYYY-MM-DD HH:mm:ss", // Be sure to call `.utc()` first if this is coming from a TZ date.
	DateTimeSeconds = "MMM DD, YYYY, h:mm:ss a",
	Filename = "YYYY-MM-DD HH:mm",
	MonthYear = "MMMM YYYY",
	Time = "h:mm a",
	TimeLong = "h:mm:ss a",
}

const formatDateTimeInternal = (
	utcDate: string | number,
	format?: string,
	invalidDateText?: string,
	formatInUtc = false
) => {
	// We assume that all incoming dates are in UTC.
	let date = dayjs.utc(utcDate)
	// Then convert it to local, unless you tell us to keep it in UTC.
	if (!formatInUtc) date = date.local()

	const formattedDate = date.format(format)
	return (
			typeof invalidDateText !== "undefined" &&
				(typeof utcDate === "undefined" || formattedDate === "Invalid date")
		) ?
			invalidDateText
		:	formattedDate
}

export interface FormatDateOptions {
	format: string | DateFormat
	invalidDateText: string
	formatInUtc: boolean // Set this to true when your date is both not timezone aware and in central time!!
}

export function formatDate(
	utcDate: string | number,
	formatOrOptions?: Partial<FormatDateOptions>
): string
export function formatDate(
	utcDate: string | number,
	formatOrOptions?: string | DateFormat,
	invalidDateText?: string,
	formatInUtc?: boolean
): string
export function formatDate(
	utcDate: string | number,
	formatParamOrOptions?: string | DateFormat | Partial<FormatDateOptions>,
	invalidDateTextParam?: string,
	formatInUtcParam?: boolean
): string {
	// Initialize with the legacy params and some defaults.
	let format: string | DateFormat =
		typeof formatParamOrOptions === "string" ? formatParamOrOptions : DateFormat.Date
	let invalidDateText = invalidDateTextParam ?? "N/A"
	let formatInUtc = formatInUtcParam ?? false

	// If an object was passed in as second param, then grab the options from there.
	if (typeof formatParamOrOptions === "object") {
		if (formatParamOrOptions.format) format = formatParamOrOptions.format
		if (formatParamOrOptions.invalidDateText)
			invalidDateText = formatParamOrOptions.invalidDateText
		if (formatParamOrOptions.formatInUtc) formatInUtc = formatParamOrOptions.formatInUtc
	}

	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const formatDateTime = (
	utcDate: string | number,
	format: string | DateFormat = DateFormat.DateTime,
	invalidDateText = "N/A",
	formatInUtc = false
): string => {
	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const formatDateTimeForFilename = (
	utcDate: string | number,
	format: string | DateFormat = DateFormat.Filename,
	invalidDateText = "N/A",
	formatInUtc = false
): string => {
	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const formatLongDateTime = (
	utcDate: string | number,
	format: string | DateFormat = DateFormat.DateTimeLong,
	invalidDateText = "N/A",
	formatInUtc = false
): string => {
	return formatDateTimeInternal(utcDate, format, invalidDateText, formatInUtc)
}

export const getTimezoneAbbreviation = (): string => {
	return dayjs.tz(dayjs()).format("z")
}

export enum TimeAgo {
	Today = "today",
	Yesterday = "yesterday",
	WeekAgo1 = "weekAgo1",
	WeeksAgo2 = "weeksAgo2",
	DaysAgo30 = "daysAgo30",
	ThisMonth = "thisMonth",
	ThisQuarter = "thisQuarter",
	LastMonth = "lastMonth",
	LastQuarter = "lastQuarter",
	MonthsAgo3 = "monthsAgo3",
	MonthsAgo6 = "monthsAgo6",
	YearToDate = "yearToDate",
	YearAgo1 = "yearAgo1",
	YearsAgo2 = "yearsAgo2",
	YearsAgo5 = "yearsAgo5",
}

export const isTimeAgo = (value: TimeAgo | string | null | undefined): value is TimeAgo => {
	if (value == null) {
		return false
	} else {
		return Object.values(TimeAgo).includes(value as TimeAgo)
	}
}

/**
 * Given a TimeAgo, calculate the actual date object that corresponds to it.
 */
export function getTimeAgoStartDate(timeAgo: TimeAgo): Dayjs
export function getTimeAgoStartDate(timeAgo: TimeAgo | null): Dayjs | null
export function getTimeAgoStartDate(timeAgo: TimeAgo | null): Dayjs | null {
	const today = dayjs().startOf("day")

	switch (timeAgo) {
		case TimeAgo.YearsAgo5:
			return today.subtract(5, "years")
		case TimeAgo.YearsAgo2:
			return today.subtract(2, "years")
		case TimeAgo.YearAgo1:
			return today.subtract(1, "years")
		case TimeAgo.YearToDate:
			return today.startOf("year")
		case TimeAgo.MonthsAgo3:
			return today.subtract(3, "months")
		case TimeAgo.MonthsAgo6:
			return today.subtract(6, "months")
		case TimeAgo.ThisMonth:
			return today.startOf("month")
		case TimeAgo.ThisQuarter:
			return today.startOf("quarter")
		case TimeAgo.LastMonth:
			return today.subtract(1, "months").startOf("month")
		case TimeAgo.LastQuarter:
			return today.subtract(1, "quarter").startOf("quarter")
		case TimeAgo.DaysAgo30:
			return today.subtract(30, "days")
		case TimeAgo.WeeksAgo2:
			return today.subtract(14, "days")
		case TimeAgo.WeekAgo1:
			return today.subtract(7, "days")
		case TimeAgo.Yesterday:
			return today.subtract(1, "days")
		case TimeAgo.Today:
			return today
		default:
			return null
	}
}

/**
 * Given a date string, go through all our TimeAgos and see if any of them match it.
 */
export const getTimeAgoFromStartDate = (
	startDate: Dayjs | string | null | undefined
): TimeAgo | null => {
	if (!startDate) {
		return null
	}

	const date = typeof startDate === "string" ? dayjs(startDate) : startDate

	if (!date.isValid()) {
		return null
	}

	return (
		Object.values(TimeAgo).find((timeAgo) => {
			const timeAgoDate = getTimeAgoStartDate(timeAgo)

			return timeAgoDate.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
		}) ?? null
	)
}

export const getStartAndEndDateQueryParams = (
	timeAgo: TimeAgo | null
): [string, string] | [null, null] => {
	const start = timeAgo ? getTimeAgoStartDate(timeAgo).format(DateFormat.DateQueryParam) : null
	const today = formatDate(new Date().toISOString(), DateFormat.DateQueryParam)

	if (start) {
		return [start, today]
	} else {
		return [null, null]
	}
}

export const getTimeRemaining = (futureDate: Parameters<typeof dayjs>[0]) => {
	const totalSeconds = dayjs(futureDate).unix() - dayjs().unix()
	const seconds = Math.floor(totalSeconds % 60)
	const minutes = Math.floor((totalSeconds / 60) % 60)
	const hours = Math.floor((totalSeconds / (60 * 60)) % 24)
	const days = Math.floor(totalSeconds / (60 * 60 * 24))

	return {
		totalSeconds,
		days,
		hours,
		minutes,
		seconds,
	}
}

/**
 * Date formatter wrapped with logic for handling nullish values.
 */
export function displayDate(
	date: string | null | undefined,
	resultIfNullish?: string,
	options?: Partial<FormatDateOptions>
): string
export function displayDate(
	date: string | null | undefined,
	resultIfNullish?: string | null,
	options?: Partial<FormatDateOptions>
): string | null
export function displayDate(
	date: string | null | undefined,
	resultIfNullish?: string | null,
	options?: Partial<FormatDateOptions>
): string | null {
	const fallback = typeof resultIfNullish === "undefined" ? "-" : resultIfNullish

	if (!date) return fallback

	return (
		formatDate(date, {
			invalidDateText: "",
			...options,
		}) || fallback
	)
}

/**
 * Date formatter wrapped with logic for handling nullish values.
 */
export function displayDateTime(
	date: string | null | undefined,
	resultIfNullish?: string,
	options?: Partial<FormatDateOptions>
): string
export function displayDateTime(
	date: string | null | undefined,
	resultIfNullish?: string | null,
	options?: Partial<FormatDateOptions>
): string | null
export function displayDateTime(
	date: string | null | undefined,
	resultIfNullish: string | null = "-",
	options?: Partial<FormatDateOptions>
): string | null {
	if (!date) return resultIfNullish

	return (
		formatDate(date, {
			invalidDateText: "",
			format: DateFormat.DateTime,
			...options,
		}) || resultIfNullish
	)
}

/** Pass in a total number of minutes and get it reduced to days, hours, minutes. */
export const formatDuration = (totalMinutes?: number | null): string => {
	if (totalMinutes == null) return ""

	let minuteCount = totalMinutes
	const dayCount = Math.floor(totalMinutes / (60 * 24))
	minuteCount = minuteCount - dayCount * 60 * 24
	const hourCount = Math.floor(minuteCount / 60)
	minuteCount = minuteCount - hourCount * 60

	const result: string[] = []
	if (dayCount) result.push(`${dayCount} day${dayCount !== 1 ? "s" : ""}`)
	if (hourCount) result.push(`${hourCount} hr${hourCount !== 1 ? "s" : ""}`)
	if (minuteCount) result.push(`${minuteCount} min`)

	return result.join(", ")
}

/**
 * Pass in two dates to see if now is in between them.
 */
export const dateRangeIsActive = (
	startDate: string | Date | Dayjs,
	endDate: string | Date | Dayjs
): boolean => {
	const now = dayjs()
	const start = dayjs(startDate).startOf("day")
	const end = dayjs(endDate).endOf("day")

	return now.isAfter(start) && now.isBefore(end)
}

/**
 * Take a date and add a set number of days to it, skipping weekends.
 * Does not support holidays.
 */
export const addBusinessDays = (days: number, startDate?: string | Date | Dayjs): Dayjs => {
	let result = startDate ? dayjs(startDate) : dayjs()
	let counter = 0

	while (counter < days) {
		result = result.add(1, "day")

		if (result.day() !== 0 && result.day() !== 6) {
			counter++
		}
	}

	return result
}
