import React, { useMemo, useState } from "react"

import { captureException } from "@sentry/minimal"

import {
	CreditCard,
	CustomerInvoice,
	makeApiErrorMessage,
	PaymentMethod,
	PaymentMethodBundle,
	SquareCardBrand,
	StripeBankAccount,
	useAuth,
	usePayCustomerInvoice,
	useSites,
	useUserProfile,
} from "@ncs/ncs-api"
import { extractNumber, noFalsy } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Button,
	ErrorText,
	GridContainer,
	GridItem,
	Heading,
	Information,
	useSquareCardContext,
} from "@ncs/web-legos"

import { PaymentSelector } from "~/shared-components"

export interface InvoiceDetailPayNowTabProps {
	invoice: CustomerInvoice | null
}

export const InvoiceDetailPayNowTab: React.FC<InvoiceDetailPayNowTabProps> = ({ invoice }) => {
	const auth = useAuth()
	const [profile] = useUserProfile(auth.user?.id)

	// Hopefully the logged in user has permissions to either the invoice's customer or the invoice's
	// bill-to customer.
	const [userSites] = useSites()
	const site = useMemo(() => {
		const billToSite = (userSites ?? []).find((s) => s.id === invoice?.billToCustomer?.id)
		const shipToSite = (userSites ?? []).find((s) => s.id === invoice?.customer?.id)

		return billToSite ?? shipToSite ?? null
	}, [invoice?.billToCustomer?.id, invoice?.customer?.id, userSites])

	const payInvoice = usePayCustomerInvoice(invoice?.id ?? null)
	const { card, verifyBuyer } = useSquareCardContext()

	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(null)
	const [storedCard, setStoredCard] = useState<CreditCard | null>(null)
	const [bankAccount, setBankAccount] = useState<StripeBankAccount | null>(null)

	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [showSuccess, setShowSuccess] = useState(false)

	const onPay = async () => {
		if (!invoice || !Number(invoice?.amountDue)) {
			throw new Error("no amount due")
		}

		setIsSubmitting(true)
		setErrorMessage(null)

		switch (paymentMethod) {
			case PaymentMethod.SQUARE_CARD: {
				try {
					if (!profile || !site) {
						throw new Error(
							"Trying to process payment but required data was not found."
						)
					}
					if (!card) {
						throw new Error("Square card instance not found.")
					}

					const tokenResult = await card.tokenize()
					if (tokenResult.status === "OK" && tokenResult.token) {
						const verificationResult = await verifyBuyer(tokenResult.token, {
							amount: extractNumber(invoice.amountDue).toFixed(2),
							currencyCode: "USD",
							intent: "CHARGE",
							billingContact: {
								familyName: profile.lastName,
								givenName: profile.firstName,
								email: profile.email,
								countryCode: "US",
								city: site.city,
								addressLines: noFalsy([site.address1, site.address2]),
								state: site.state,
								postalCode: site.postalCode,
							},
						})
						const { details } = tokenResult
						if (!verificationResult || !details?.card)
							throw new Error("Error verifying card.")

						const payment: PaymentMethodBundle = {
							method: PaymentMethod.SQUARE_CARD,
							data: {
								last4: details.card.last4,
								expMonth: details.card.expMonth,
								expYear: details.card.expYear,
								brand: details.card.brand as unknown as SquareCardBrand,
								nonce: tokenResult.token,
								verificationToken: verificationResult.token,
								billingPostalCode:
									tokenResult.details?.billing?.postalCode ?? null,
								digitalWalletType: "NONE",
							},
						}

						const paymentResponse = await payInvoice({
							amount: {
								value: invoice.amountDue,
								currency: "USD",
							},
							payment,
						})
						if (paymentResponse.data.payment_intent_id) {
							onSuccess()
						}
					} else {
						throw new Error("Could not process card.")
					}
				} catch (e) {
					console.error(e)
					captureException(e)
					setErrorMessage(makeApiErrorMessage(e))
				} finally {
					setIsSubmitting(false)
				}
				break
			}

			case PaymentMethod.STORED_BANK_ACCOUNT: {
				if (bankAccount) {
					try {
						const response = await payInvoice({
							amount: {
								value: invoice.amountDue,
								currency: "USD",
							},
							payment: {
								method: PaymentMethod.STORED_BANK_ACCOUNT,
								data: bankAccount?.id,
							},
						})
						if (response.data.payment_intent_id) {
							onSuccess()
						}
					} catch (e) {
						console.error(e)
						captureException(e)
						setErrorMessage(makeApiErrorMessage(e))
					} finally {
						setIsSubmitting(false)
					}
				}
				break
			}

			case PaymentMethod.STORED_CARD: {
				if (storedCard) {
					try {
						const response = await payInvoice({
							amount: {
								value: invoice.amountDue,
								currency: "USD",
							},
							payment: {
								method: PaymentMethod.STORED_CARD,
								data: storedCard.id,
							},
						})
						if (response.data.payment_intent_id) {
							onSuccess()
						}
					} catch (e) {
						console.error(e)
						captureException(e)
						setErrorMessage(makeApiErrorMessage(e))
					} finally {
						setIsSubmitting(false)
					}
				}
				break
			}
			default: {
				setIsSubmitting(false)
				console.error("No handler for selected payment method")
			}
		}
	}

	const onSuccess = () => {
		setShowSuccess(true)
		setIsSubmitting(false)
	}

	return (
		<GridContainer>
			<GridItem sm={12} md={8} mb={3}>
				{showSuccess && (
					<Heading icon="check-circle" iconColor="success">
						Payment submitted. Thank you!
					</Heading>
				)}

				{!!invoice && invoice?.amountDue && !showSuccess && (
					<>
						<PaymentSelector
							paymentMethodValue={paymentMethod}
							onChangeMethod={setPaymentMethod}
							storedCardValue={storedCard}
							onChangeStoredCard={setStoredCard}
							bankAccountValue={bankAccount}
							onChangeBankAccount={setBankAccount}
							hiddenMethods={[PaymentMethod.CUSTOMER_ACCOUNT]}
						/>

						<AnimatedEntrance show={!!errorMessage} mb={1}>
							<ErrorText>{errorMessage}</ErrorText>
						</AnimatedEntrance>

						<Button
							variant="primary-cta"
							disabled={!paymentMethod}
							onClick={onPay}
							isLoading={isSubmitting}
						>
							Pay Invoice Now
						</Button>
					</>
				)}
			</GridItem>
			<GridItem sm={12} md={4} px={2}>
				<Information color="warn">
					<strong>Please note:</strong> New payments take up to 24 hours to process and
					therefore will not be reflected here right away.
				</Information>
			</GridItem>
		</GridContainer>
	)
}
