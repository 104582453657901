import React from "react"

import { Skeleton } from "@material-ui/lab"

import { withCssUnit } from "@ncs/ts-utils"

import { Box, BoxProps } from "../layout"

export interface SkeletonRowsProps {
	width?: number | string
	height?: number | string
	rows?: number
	rowProps?: BoxProps
	containerProps?: BoxProps
}

export const SkeletonRows: React.FC<SkeletonRowsProps> = ({
	width,
	height,
	rows = 3,
	rowProps,
	containerProps,
}) => {
	const skeleton = (
		<Skeleton variant="rect" width={withCssUnit(width)} height={withCssUnit(height)} />
	)

	return (
		<Box {...containerProps}>
			{new Array(rows).fill(skeleton).map((row, i) => (
				<Box key={i} my={0.5} {...rowProps}>
					{row}
				</Box>
			))}
		</Box>
	)
}
