import { FC, useState } from "react"

import { Cell, Column } from "react-table"

import {
	ConnectedDevice,
	FourDigitMachineCode,
	FourDigitMachineCodesQueryParams,
	useFourDigitMachineCodes,
} from "@ncs/ncs-api"
import { displayDateTime, getTimezoneAbbreviation, Typify } from "@ncs/ts-utils"
import {
	Box,
	EmptyValueDash,
	KeyboardEndDateQueryFilter,
	KeyboardStartDateQueryFilter,
	Paragraph,
	SearchQueryFilter,
	SearchQueryFilterProps,
	Table,
	useUrlState,
} from "@ncs/web-legos"

import { Weather } from "~/shared-components"

import { MachineEventDetailModal } from "./MachineEventDetailModal"

export interface MachineModalEventsTabProps {
	device: ConnectedDevice
}

export type MachineEventsTabUrlState = Typify<FourDigitMachineCodesQueryParams>

export const MachineModalEventsTab: FC<MachineModalEventsTabProps> = ({ device }) => {
	const [params, { setUrlState: setParams }] = useUrlState<MachineEventsTabUrlState>(
		defaultFourDigitMachineCodesQueryParams
	)
	const [detailModalEvent, setDetailModalEvent] = useState<FourDigitMachineCode | null>(null)

	const query = useFourDigitMachineCodes(device.id, {
		params,
		pageSize: 25,
	})

	return (
		<>
			<Table
				query={query}
				columns={columns}
				toggledQueryFilters={[
					KeyboardEndDateQueryFilter,
					KeyboardStartDateQueryFilter,
					MachineSearchQueryFilter,
				]}
				showToggledFiltersByDefault={Object.values(params).some((v) => !!v)}
				queryParamState={params}
				setQueryParamState={setParams}
				storeColumnVisibility
				onRowClick={({ original }) => setDetailModalEvent(original)}
			/>

			{!!detailModalEvent && (
				<MachineEventDetailModal
					event={detailModalEvent}
					onClose={() => setDetailModalEvent(null)}
				/>
			)}
		</>
	)
}

const MachineSearchQueryFilter = (props: SearchQueryFilterProps<MachineEventsTabUrlState>) => {
	return <SearchQueryFilter {...props} placeholder="Code, wash count..." />
}

const columns: Column<FourDigitMachineCode>[] = [
	{
		Header: "Code",
		id: "fourDigitCode_MachineCode",
		accessor: ({ fourDigitCode }) => fourDigitCode?.machineCode || "-",
		Cell: ({
			row: {
				original: { fourDigitCode },
			},
		}: Cell<FourDigitMachineCode>) => {
			return (
				<Box display="flex" alignItems="center" columnGap={0.5}>
					<Paragraph small light mt={0.1}>
						{fourDigitCode?.machineCode || "-"}
					</Paragraph>
				</Box>
			)
		},
	},
	{
		Header: "Seq.",
		accessor: (original) => original.sequence?.machineCode,
		disableSortBy: true,
	},
	{
		Header: "Washes (last 4)",
		hiddenByDefault: true,
		disableSortBy: true,
		accessor: (original) => (original.countString ? original.countString : <EmptyValueDash />),
	},
	{
		Header: `Reported date (${getTimezoneAbbreviation()})`,
		id: "createdOn",
		accessor: (original) => displayDateTime(original.eventOn),
	},
	{
		Header: "Event date - time",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: (original) => `${original.eventDate} - ${original.eventTime}`,
	},
	{
		Header: "Code descriptions",
		id: "description",
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<FourDigitMachineCode>) => (
			<>
				<div>
					<span>Code: {original.fourDigitCode?.description || "-"}</span>
				</div>
				<div>
					<span>Seq: {original.sequence?.description}</span>
				</div>
			</>
		),
	},
	{
		Header: "2-Digit code",
		accessor: (original) => original?.twoDigitCode?.machineCode ?? <EmptyValueDash />,
		hiddenByDefault: true,
		disableSortBy: true,
	},
	{
		Header: "2-Digit description",
		disableSortBy: true,
		hiddenByDefault: true,
		accessor: (original) => original.twoDigitCode?.description ?? <EmptyValueDash />,
	},
	{
		Header: "Weather",
		disableSortBy: true,
		Cell: ({ row: { original } }: Cell<FourDigitMachineCode>) =>
			original.hourlyWeather ?
				<Weather
					temperature={original.hourlyWeather?.temperature}
					icon={original.hourlyWeather?.icon}
				/>
			:	<EmptyValueDash />,
	},
]

export const defaultFourDigitMachineCodesQueryParams: FourDigitMachineCodesQueryParams = {
	startDate: null,
	endDate: null,
	codePriority: null,
	search: null,
	ordering: null,
}
