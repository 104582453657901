import React from "react"
// eslint-disable-next-line react/no-deprecated
import { render } from "react-dom"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import { setBaseUrl } from "@ncs/ncs-api"
import { integrateAnalyticsPlatforms, setUspsUserName } from "@ncs/web-legos"

import App from "~/App"
import { AppProvider } from "~/contexts"
import { isDebug } from "~/util"

Sentry.init({
	enabled: !!import.meta.env.VITE_SENTRY_DSN,
	dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [new Integrations.BrowserTracing()],
	tracesSampleRate: isDebug() ? 0 : 0.01,
})

// Pass in some config info that our libraries need.
setBaseUrl(import.meta.env.VITE_PORTAL_SERVICE_URL)
setUspsUserName(import.meta.env.VITE_USPS_USER_NAME)

const app = (
	<Sentry.ErrorBoundary
		showDialog={isDebug()}
		fallback={
			<p>
				Whoops, an error has occurred! Sorry about that. You can try refreshing the page
				and starting over.
			</p>
		}
	>
		<AppProvider>
			<App />
		</AppProvider>
	</Sentry.ErrorBoundary>
)

render(app, document.getElementById("root"))
integrateAnalyticsPlatforms()
