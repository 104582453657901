import {
	DeviceStatusCategory,
	MachineCodePriority,
	StockStatus,
	VacutechStatus,
} from "../portal-apps"

/** The four generic statuses we boil everything down to. */
export enum GenericDeviceStatus {
	Error = "error",
	Warning = "warning",
	Normal = "normal",
	Unknown = "unknown",
}

/** Union of all the specific device statuses objects there are. */
export type AnyNcsDeviceStatus =
	| GenericDeviceStatus
	/* Comes back on the Connected Site object, not specific to a type of device */
	| DeviceStatusCategory
	/* Vacuums */
	| VacutechStatus
	/* Machines */
	| MachineCodePriority
	/* Warehouse stock status */
	| StockStatus

export type GetGenericDeviceStatusOptions = {
	offlineIsError: boolean
}

const defaultGetGenericDeviceStatusOptions: GetGenericDeviceStatusOptions = {
	offlineIsError: false,
}

export const getGenericDeviceStatus = (
	specificStatus: AnyNcsDeviceStatus | undefined | null,
	optionsProp?: Partial<GetGenericDeviceStatusOptions>
): GenericDeviceStatus => {
	const options = {
		...defaultGetGenericDeviceStatusOptions,
		optionsProp,
	}

	switch (specificStatus) {
		case GenericDeviceStatus.Error:
		case VacutechStatus.Error:
		case MachineCodePriority.Severe:
		case MachineCodePriority.Error:
		case StockStatus.None:
		case DeviceStatusCategory.Error: {
			return GenericDeviceStatus.Error // Red
		}

		case GenericDeviceStatus.Warning:
		case VacutechStatus.Warning:
		case MachineCodePriority.Warning:
		case StockStatus.Partial:
		case DeviceStatusCategory.Warning: {
			return GenericDeviceStatus.Warning // Yellow
		}

		case GenericDeviceStatus.Normal:
		case VacutechStatus.Ready:
		case VacutechStatus.Running:
		case StockStatus.All:
		case DeviceStatusCategory.Normal: {
			return GenericDeviceStatus.Normal // Green
		}

		case GenericDeviceStatus.Unknown:
		case DeviceStatusCategory.Unknown:
		case VacutechStatus.Unknown:
		case MachineCodePriority.Info:
		case StockStatus.Unknown: {
			return GenericDeviceStatus.Unknown // Gray
		}

		case DeviceStatusCategory.Offline: {
			if (options.offlineIsError) {
				return GenericDeviceStatus.Error
			} else {
				return GenericDeviceStatus.Unknown
			}
		}

		default:
			return GenericDeviceStatus.Unknown
	}
}

export const isGenericDeviceStatus = (
	str: string | undefined | null
): str is GenericDeviceStatus => {
	return !!str && Object.values(GenericDeviceStatus).includes(str as GenericDeviceStatus)
}

export const getGenericDeviceStatusSortingPriority = (status: AnyNcsDeviceStatus): number => {
	const genericStatus = getGenericDeviceStatus(status)

	switch (genericStatus) {
		case GenericDeviceStatus.Error: {
			return 0
		}
		case GenericDeviceStatus.Unknown: {
			return 1
		}
		case GenericDeviceStatus.Warning: {
			return 2
		}
		case GenericDeviceStatus.Normal: {
			return 3
		}
	}
}
