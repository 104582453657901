import { FC } from "react"

import { formatDate, FormatDateOptions } from "@ncs/ts-utils"

import { EmptyValueDash } from "./EmptyValueDash"

export interface FormattedDateProps {
	value: string | number | null | undefined
	formatDateProps?: Partial<FormatDateOptions>
}

export const FormattedDate: FC<FormattedDateProps> = ({ value, formatDateProps }) => {
	return <>{value ? formatDate(value, formatDateProps) : <EmptyValueDash />}</>
}
