import React, { memo, useMemo, useState } from "react"

import dayjs from "dayjs"

import {
	PortalReportName,
	ReportParamKeys,
	ReportTimeSpan,
	ServiceChemicalLevelsReportParams,
	useCustomerWashCountHistoryReport,
	useServiceChemicalLevelsReport,
	useWeatherReport,
} from "@ncs/ncs-api"
import { DateFormat, getTimeAgoStartDate, TimeAgo } from "@ncs/ts-utils"
import {
	ChartCard,
	ChartType,
	GenericReportChart,
	makeChartOverlayBundle,
	ReportFrequencySelector,
	Select,
	SiteSelector,
	TimeAgoSelector,
	validateChartConfiguration,
} from "@ncs/web-legos"

export interface ServiceChemicalLevelsChartProps {
	siteId?: string
}

export const ServiceChemicalLevelsChart: React.FC<ServiceChemicalLevelsChartProps> = memo(
	({ siteId: siteIdProp }) => {
		const [siteId, setSiteId] = useState<string | null>(siteIdProp ?? null)
		const [frequency, setFrequency] = useState(ReportTimeSpan.Weekly)
		const [timeAgo, setTimeAgo] = useState<TimeAgo | null>(TimeAgo.WeeksAgo2)
		const [overlayReportName, setOverlayReportName] = useState<PortalReportName | null>(null)

		const params = useMemo(() => {
			const result: ServiceChemicalLevelsReportParams = {
				[ReportParamKeys.Frequency]: frequency,
				[ReportParamKeys.StartDate]:
					timeAgo ?
						getTimeAgoStartDate(timeAgo).format(DateFormat.DateQueryParam)
					:	null,
				[ReportParamKeys.Site]: siteId,
			}
			return result
		}, [timeAgo, frequency, siteId])

		const [chemicalLevelsReport, chemicalLevelsReportLoading] =
			useServiceChemicalLevelsReport(params)
		const [washCountsReport, washCountsReportLoading] =
			useCustomerWashCountHistoryReport(params)
		const [weatherReport, weatherReportLoading] = useWeatherReport(params)
		const loading =
			chemicalLevelsReportLoading || washCountsReportLoading || weatherReportLoading

		const overlayReportBundle = useMemo(() => {
			return makeChartOverlayBundle(overlayReportName, {
				[PortalReportName.WashCounts]: washCountsReport,
				[PortalReportName.Weather]: weatherReport,
			})
		}, [overlayReportName, washCountsReport, weatherReport])

		const errorMessage = useMemo(() => {
			return validateChartConfiguration({
				report: chemicalLevelsReport,
				overlayReportBundle,
				loading,
				params,
			})
		}, [chemicalLevelsReport, overlayReportBundle, loading, params])

		const title = useMemo(() => {
			return `Chemical Levels: ${getTimeAgoStartDate(timeAgo)?.format(
				DateFormat.Date
			)} - ${dayjs().format(DateFormat.Date)}`
		}, [timeAgo])

		return (
			<ChartCard heading={title}>
				<GenericReportChart
					report={chemicalLevelsReport}
					overlayReportBundle={overlayReportBundle}
					isLoading={loading}
					defaultChartType={ChartType.Bar}
					errorMessageText={errorMessage}
					filters={[
						siteIdProp ? undefined : (
							<SiteSelector
								key="SiteSelector"
								value={siteId}
								onChange={setSiteId}
								disableNoSelectionOption={false}
								noSelectionOptionText="All sites"
							/>
						),
						<TimeAgoSelector
							key="TimeAgoSelector"
							fillContainer
							value={timeAgo}
							onChange={setTimeAgo}
							showNoSelectionOption={false}
						/>,
						<ReportFrequencySelector
							key="ReportFrequencySelector"
							value={frequency}
							onChange={setFrequency}
							fillContainer
						/>,
						<Select
							key="overlayReportSelector"
							label="Data overlay"
							fillContainer
							disableNoSelectionOption={false}
							value={overlayReportName}
							onChange={(newSiteName) =>
								setOverlayReportName(newSiteName as PortalReportName | null)
							}
							options={[
								{
									value: PortalReportName.WashCounts,
									text: PortalReportName.WashCounts,
								},
								{
									value: PortalReportName.Weather,
									text: PortalReportName.Weather,
								},
							]}
						/>,
					]}
				/>
			</ChartCard>
		)
	}
)
