import React, { memo } from "react"

import TimeAgo from "react-timeago"

import { ConnectedDevice, useConnectedMachineDetails } from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import { Box, BoxProps, Icon, Paragraph, SkeletonRows } from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

import { StatusIcon } from "./StatusIcon"

export interface DeviceStatusProps extends BoxProps {
	device: ConnectedDevice
}

export const DeviceStatus: React.FC<DeviceStatusProps> = memo(({ device, ...rest }) => {
	const [details, detailsLoading] = useConnectedMachineDetails(device.id, {
		queryConfig: c12yAutoRefreshConfig,
	})

	return (
		<Box mt={0.5} mb={1} {...rest}>
			<Box d="flex" alignItems="center" gap={0.5}>
				<Box textAlign="center" pt={0.15}>
					<StatusIcon status={device.state} />
				</Box>
				<Paragraph small>Status: {titleCase(device.state)}</Paragraph>
			</Box>
			<Box d="flex" alignItems="center" gap={0.5} ml={1}>
				<Icon icon="satellite-dish" />
				<Paragraph small>Last communication:</Paragraph>
				{detailsLoading ?
					<SkeletonRows rows={1} width={10} rowProps={{ my: 0 }} />
				:	<>
						{details?.lastUpdatedOn != null ?
							<TimeAgo date={details.lastUpdatedOn} />
						:	<Paragraph small secondary>
								Unknown
							</Paragraph>
						}
					</>
				}
			</Box>
		</Box>
	)
})
