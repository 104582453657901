import { FC, useMemo, useState } from "react"

import { CellProps, Column } from "react-table"

import {
	CustomerPartOrder,
	initialCustomerPartOrderQueryParams,
	useCustomerPartOrders,
} from "@ncs/ncs-api"
import { formatCurrency, formatDate, noFalsy } from "@ncs/ts-utils"
import {
	EmptyValueDash,
	Heading,
	PartOrderStatusQueryFilter,
	SearchQueryFilter,
	SiteQueryFilter,
	Table,
	TimeAgoQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

import { OrderDetailModal } from "./components"

export type OrdersTabUrlState = {
	orderModalId: string | null
}

export const OrdersTab: FC = () => {
	const [queryParamState, setQueryParamState] = useState(initialCustomerPartOrderQueryParams)
	const partOrdersQuery = useCustomerPartOrders({ params: queryParamState })

	const [{ orderModalId }, { updateUrlValue }] = useUrlState<OrdersTabUrlState>({
		orderModalId: null,
	})

	const columns: Column<CustomerPartOrder>[] = useMemo(() => {
		return [
			{
				Header: "Order #",
				accessor: "orderId",
				disableSortBy: true,
			},
			{
				Header: "Site",
				disableSortBy: true,
				accessor: ({ alternateAddress, shipToCustomer }) => {
					return noFalsy(
						alternateAddress ?
							[
								alternateAddress.address1,
								alternateAddress.address2,
								alternateAddress.city,
								alternateAddress.state,
							]
						:	[
								shipToCustomer.name,
								shipToCustomer.address2,
								shipToCustomer.address1,
								shipToCustomer.address3,
								shipToCustomer.city,
								shipToCustomer.state,
							]
					).join(", ")
				},
			},
			{
				id: "orderStatus_Description",
				Header: "Status",
				accessor: (order) => order.status?.description,
			},
			{
				Header: "PO #",
				noWrap: true,
				accessor: ({ purchaseOrderNumber }) => purchaseOrderNumber || "-",
				disableSortBy: true,
			},
			{
				id: "orderDate",
				noWrap: true,
				Header: "Order Date (CDT)",
				accessor: "orderDate",
				Cell: ({ row: { original } }: CellProps<CustomerPartOrder>) => (
					<span>
						{original.orderDate ? formatDate(original.orderDate) : <EmptyValueDash />}
					</span>
				),
			},
			{
				id: "total",
				Header: "Total",
				accessor: (order) => formatCurrency(order.total),
			},
		]
	}, [])

	return (
		<>
			<Heading bold>Order History</Heading>

			<Table
				columns={columns}
				query={partOrdersQuery}
				queryParamState={queryParamState}
				setQueryParamState={setQueryParamState}
				toggledQueryFilters={[
					SearchQueryFilter,
					TimeAgoQueryFilter,
					SiteQueryFilter,
					PartOrderStatusQueryFilter,
				]}
				loadingText="Loading your orders..."
				noDataText="No orders found"
				onRowClick={(row) => updateUrlValue("orderModalId", row.original.id)}
			/>

			<OrderDetailModal
				isOpen={!!orderModalId}
				onClose={() => updateUrlValue("orderModalId", null)}
				orderId={orderModalId}
			/>
		</>
	)
}
