import { useMemo } from "react"

import {
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPatchMutation,
	ApiPostMutation,
	DataExport,
	useDataExport,
	useGetRequest,
	useInfiniteGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import {
	ChemicalContainerDetail,
	ChemicalCustomer,
	ChemicalCustomerDetail,
	ChemicalCustomerQueryParams,
	ConnectedDevice,
	ConnectedDevicesAtSite,
	ConnectedDeviceWorkOrder,
	ConnectedDeviceWorkOrdersQueryParams,
	ConnectedMachineDetails,
	ConnectedMachinesAtSiteQueryParams,
	ConnectedMachineWashCounts,
	ConnectedMachineWashCountsQueryParams,
	ConnectedSite,
	ConnectedSiteQueryParams,
	ConnectedVacuumsAtSiteQueryParams,
	ConnectedWashCountersAtSiteQueryParams,
	ConnectivityUrlPaths,
	CreateChemicalPackagePost,
	CreateContainerChemicalPost,
	CustomerConnectedMachine,
	CustomerDeviceStatusSummary,
	CustomerMachineEvent,
	CustomerMachineEventQueryParams,
	CustomerPackageWashCounts,
	CustomerVacuumCharts,
	CustomerVacuumChartsQueryParams,
	CustomerVacuumEvent,
	CustomerVacuumEventQueryParams,
	CustomerWeather,
	CustomerWeatherQueryParams,
	DeviceRegistrationPost,
	DeviceRegistrationResults,
	FourDigitMachineCode,
	FourDigitMachineCodesQueryParams,
	TwoDigitMachineCode,
	TwoDigitMachineCodesQueryParams,
	UpdateChemicalPackagePatch,
	UpdateConnectedMachinePatch,
	UpdateContainerChemicalPatch,
	VacuumConfig,
	VacuumConfigPatch,
	VacuumConfigQueryParams,
	VacuumDevice,
	VacuumEventHistory,
	VacuumEventsQueryParams,
	VacuumMessageHistory,
	VacuumMessagesQueryParams,
	VacuumStartupHistory,
	VacuumStartupsQueryParams,
	WashCounterWashCounts,
	WashCounterWashCountsQueryParams,
} from "./types"

export const useConnectedMachinesAtSites = (
	options?: ApiGetQueryOptions<ConnectedDevicesAtSite[], ConnectedMachinesAtSiteQueryParams>
): ApiGetQuery<ConnectedDevicesAtSite[]> => {
	return useGetRequest(
		[ConnectivityUrlPaths.Connectivity, ConnectivityUrlPaths.V2, ConnectivityUrlPaths.Machine],
		options
	)
}

// We'll fudge this endpoint by calling for the plural machines endpoint and passing a device ID.
// Note that if something goes wrong and we get back more than one site with one device, you just
// stay `undefined`, there isn't a 404 thrown or anything.
export const useConnectedMachine = (
	deviceId: string | null
): [ConnectedDevice | undefined, boolean] => {
	const [machinesAtSites, isLoading] = useConnectedMachinesAtSites({
		params: {
			device: deviceId,
		},
		queryConfig: {
			enabled: !!deviceId,
		},
	})

	const machine = useMemo(() => {
		if (!machinesAtSites) return undefined
		if (machinesAtSites.length !== 1) return undefined
		if (machinesAtSites[0].devices.length !== 1) return undefined

		return machinesAtSites[0].devices[0]
	}, [machinesAtSites])

	return [machine, isLoading]
}

export const useUpdateConnectedMachine = (): ApiPatchMutation<UpdateConnectedMachinePatch> => {
	return usePatchRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Machine,
			ConnectivityUrlPaths.Device,
			ConnectivityUrlPaths.Update,
		],
		{
			keyToInvalidate: ConnectivityUrlPaths.Connectivity,
		}
	)
}

export const useFourDigitMachineCodes = (
	deviceId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<FourDigitMachineCode, FourDigitMachineCodesQueryParams>
): ApiInfiniteGetQuery<FourDigitMachineCode> => {
	return useInfiniteGetRequest(
		[
			ConnectivityUrlPaths.C12y,
			ConnectivityUrlPaths.V1,
			ConnectivityUrlPaths.Machine,
			deviceId ?? "",
			ConnectivityUrlPaths.FourDigitCode,
		],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!deviceId,
			},
		}
	)
}

export const useTwoDigitMachineCodes = (
	deviceId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<TwoDigitMachineCode, TwoDigitMachineCodesQueryParams>
): ApiInfiniteGetQuery<TwoDigitMachineCode> => {
	return useInfiniteGetRequest(
		[
			ConnectivityUrlPaths.C12y,
			ConnectivityUrlPaths.V1,
			ConnectivityUrlPaths.Machine,
			deviceId ?? "",
			ConnectivityUrlPaths.TwoDigitCode,
		],
		{
			...options,
			queryConfig: {
				enabled: !!deviceId,
				...options?.queryConfig,
			},
		}
	)
}

export const useConnectedMachineDetails = (
	deviceId: string | null | undefined, // Connectivity device's have a regular database ID, and a `deviceId`. This is just the regular ID.
	options?: ApiGetQueryOptions<ConnectedMachineDetails>
): ApiGetQuery<ConnectedMachineDetails> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.C12y,
			ConnectivityUrlPaths.V1,
			ConnectivityUrlPaths.Machine,
			deviceId ?? "",
			ConnectivityUrlPaths.MachineStatus,
		],
		{
			...options,
			queryConfig: {
				enabled: !!deviceId,
				...options?.queryConfig,
			},
		}
	)
}

export const useConnectedMachineWashCounts = (
	deviceId: string | null | undefined,
	options?: ApiGetQueryOptions<
		ConnectedMachineWashCounts[],
		ConnectedMachineWashCountsQueryParams
	>
): ApiGetQuery<ConnectedMachineWashCounts[]> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.C12y,
			ConnectivityUrlPaths.V1,
			ConnectivityUrlPaths.Machine,
			deviceId ?? "",
			ConnectivityUrlPaths.WashCount,
		],
		{
			...options,
			queryConfig: {
				enabled: !!deviceId,
				...options?.queryConfig,
			},
		}
	)
}

export const useConnectedDeviceWorkOrders = (
	options?: ApiGetQueryOptions<ConnectedDeviceWorkOrder[], ConnectedDeviceWorkOrdersQueryParams>
): ApiGetQuery<ConnectedDeviceWorkOrder[]> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Machine,
			ConnectivityUrlPaths.WorkOrders,
		],
		options
	)
}

export const useWashCountersAtSite = (
	options?: ApiInfiniteGetQueryOptions<
		ConnectedDevicesAtSite,
		ConnectedWashCountersAtSiteQueryParams
	>
): ApiInfiniteGetQuery<ConnectedDevicesAtSite> => {
	return useInfiniteGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.V2,
			ConnectivityUrlPaths.WashCounters,
		],
		options
	)
}

export const useVacuumsAtSite = (
	options?: ApiInfiniteGetQueryOptions<ConnectedDevicesAtSite, ConnectedVacuumsAtSiteQueryParams>
): ApiInfiniteGetQuery<ConnectedDevicesAtSite> => {
	return useInfiniteGetRequest(
		[ConnectivityUrlPaths.Connectivity, ConnectivityUrlPaths.V2, ConnectivityUrlPaths.Vacuums],
		options
	)
}

export const useSiteVacuums = (siteId: string | null | undefined): ApiGetQuery<VacuumDevice[]> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Devices,
		],
		{
			queryConfig: {
				enabled: !!siteId,
			},
			params: {
				customer: siteId,
			},
		}
	)
}

export const useVacuumMessages = (
	params: Partial<VacuumMessagesQueryParams>
): ApiInfiniteGetQuery<VacuumMessageHistory> => {
	return useInfiniteGetRequest<VacuumMessageHistory>(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Message,
		],
		{
			queryConfig: {
				enabled: !!params?.device,
			},
			params: {
				...defaultVacuumMessagesQueryParams,
				...params,
			},
		}
	)
}

export const defaultVacuumMessagesQueryParams: VacuumMessagesQueryParams = {
	device: null,
	startDate: null,
	endDate: null,
	ordering: null,
	search: null,
}

export const useExportVacuumMessages = (
	params: Partial<VacuumMessagesQueryParams>
): DataExport => {
	return useDataExport(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Message,
		],
		params
	)
}

export const defaultVacuumEventsQueryParams: VacuumEventsQueryParams = {
	device: null,
	startDate: null,
	endDate: null,
	ordering: null,
	search: null,
	eventType: null,
}

export const useVacuumEvents = (
	params: Partial<VacuumEventsQueryParams>
): ApiInfiniteGetQuery<VacuumEventHistory> => {
	return useInfiniteGetRequest<VacuumEventHistory>(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Events,
		],
		{
			queryConfig: {
				enabled: !!params?.device,
			},
			params: {
				...defaultVacuumEventsQueryParams,
				...params,
			},
		}
	)
}

export const useExportVacuumEvents = (params: Partial<VacuumEventsQueryParams>): DataExport => {
	return useDataExport(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Events,
		],
		params
	)
}

export const defaultVacuumStartupsQueryParams: VacuumStartupsQueryParams = {
	device: null,
	startDate: null,
	endDate: null,
	ordering: null,
}

export const useVacuumStartups = (
	params: Partial<VacuumStartupsQueryParams>
): ApiInfiniteGetQuery<VacuumStartupHistory> => {
	return useInfiniteGetRequest<VacuumStartupHistory>(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Startup,
		],
		{
			queryConfig: {
				enabled: !!params?.device,
			},
			params: {
				...defaultVacuumStartupsQueryParams,
				...params,
			},
		}
	)
}

export const useVacuumConfig = (
	options: ApiGetQueryOptions<VacuumConfig, VacuumConfigQueryParams>
): ApiGetQuery<VacuumConfig> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Config,
		],
		{
			params: options.params,
			queryConfig: {
				enabled: !!options.params?.device,
			},
		}
	)
}

export const useVacuumConfigPatch = (): ApiPatchMutation<VacuumConfigPatch> => {
	return usePatchRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Config,
		],
		{
			keyToInvalidate: [ConnectivityUrlPaths.Connectivity, ConnectivityUrlPaths.Vacuum],
		}
	)
}

/* CUSTOMER */

/**
 * @deprecated
 * Returns stale status
 */
export const useCustomerConnectedSites = (
	options?: ApiGetQueryOptions<ConnectedSite[], ConnectedSiteQueryParams>
): ApiGetQuery<ConnectedSite[]> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.V2,
			ConnectivityUrlPaths.Customer,
		],
		options
	)
}

/* MACHINES - CUSTOMER */

/**
 * @deprecated
 * Returns bad status
 */
export const useCustomerConnectedMachines = (
	customerId: string | null | undefined,
	options?: ApiGetQueryOptions<CustomerConnectedMachine[]>
): ApiGetQuery<CustomerConnectedMachine[]> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.V2,
			ConnectivityUrlPaths.Customer,
			customerId ?? "",
			ConnectivityUrlPaths.Machine,
		],
		{
			...options,
			queryConfig: {
				enabled: !!customerId,
				...options?.queryConfig,
			},
		}
	)
}

export const useCustomerConnectedMachine = (
	customerId: string | null,
	machineId: string | null
): [CustomerConnectedMachine | undefined, boolean] => {
	const [machines, loading] = useCustomerConnectedMachines(customerId)

	const machine = useMemo(() => {
		return machineId ?
				machines?.find((m: CustomerConnectedMachine) => m.id === machineId)
			:	undefined
	}, [machines, machineId])

	return [machine, loading]
}

export const useCustomerConnectedMachineWashCounts = (
	machineId: string | null,
	options?: ApiGetQueryOptions<CustomerPackageWashCounts[]>
): ApiGetQuery<CustomerPackageWashCounts[]> => {
	return useGetRequest<CustomerPackageWashCounts[]>(
		[
			ConnectivityUrlPaths.C12y,
			ConnectivityUrlPaths.V1,
			ConnectivityUrlPaths.Machine,
			machineId ?? "",
			ConnectivityUrlPaths.WashCount,
			ConnectivityUrlPaths.Aggregate,
		],
		{
			...options,
			queryConfig: {
				enabled: !!machineId,
				...options?.queryConfig,
			},
		}
	)
}

export const useCustomerConnectedMachineEvents = (
	options?: ApiInfiniteGetQueryOptions<CustomerMachineEvent, CustomerMachineEventQueryParams>
): ApiInfiniteGetQuery<CustomerMachineEvent> => {
	return useInfiniteGetRequest<CustomerMachineEvent, CustomerMachineEventQueryParams>(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Machine,
			ConnectivityUrlPaths.Events,
		],
		{
			...options,
			queryConfig: {
				...options?.queryConfig,
				enabled: !!options?.params?.device,
			},
		}
	)
}

/* VACUUMS - CUSTOMER */

// Fudging a vacuum-specific endpoint with the Connected Sites endpoint.
export const useCustomerVacuums = (
	siteId: string | null,
	sitesQueryOptions?: ApiGetQueryOptions<ConnectedSite[], ConnectedSiteQueryParams>
): [CustomerDeviceStatusSummary[] | undefined, boolean] => {
	const [sites, sitesLoading] = useCustomerConnectedSites({
		...sitesQueryOptions,
		params: {
			...sitesQueryOptions?.params,
			customer: siteId,
		},
	})
	const site = (sites ?? []).find((s) => String(s.customer.id) === siteId)

	return [site?.vacuums, sitesLoading]
}

export const useCustomerVacuumCharts = (
	options?: ApiGetQueryOptions<CustomerVacuumCharts[], CustomerVacuumChartsQueryParams>
): ApiGetQuery<CustomerVacuumCharts[]> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Charts,
		],
		options
	)
}

export const useCustomerVacuumEvents = (
	options?: ApiInfiniteGetQueryOptions<CustomerVacuumEvent, CustomerVacuumEventQueryParams>
): ApiInfiniteGetQuery<CustomerVacuumEvent> => {
	return useInfiniteGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Events,
		],
		{
			...options,
			queryConfig: {
				enabled: !!options?.params?.device,
				...options,
			},
		}
	)
}

export const defaultCustomerVacuumEventsQueryParams: CustomerVacuumEventQueryParams = {
	startDate: null,
	endDate: null,
	device: null,
	eventType: null,
	search: null,
	ordering: null,
}

export const useCustomerVacuumEventsDataExport = (
	params?: Partial<CustomerVacuumEventQueryParams>
): DataExport => {
	return useDataExport(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Vacuum,
			ConnectivityUrlPaths.Events,
		],
		params
	)
}

/* CHEMICALS - CUSTOMER */

export const useChemicalCustomers = (
	options?: ApiGetQueryOptions<ChemicalCustomer[], ChemicalCustomerQueryParams>
): ApiGetQuery<ChemicalCustomer[]> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Chemicals,
		],
		options
	)
}

export const useCreateContainerChemical = (): ApiPostMutation<CreateContainerChemicalPost> => {
	return usePostRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Chemicals,
		],
		{
			addRequestDataIdToUrl: true,
			keyToInvalidate: [ConnectivityUrlPaths.Connectivity],
		}
	)
}

export const useChemicalCustomerDetail = (
	customerId: string | null | undefined
): ApiGetQuery<ChemicalCustomerDetail> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Chemicals,
			customerId ?? "",
		],
		{
			queryConfig: {
				enabled: !!customerId,
			},
		}
	)
}

export const useChemicalContainerDetail = (
	containerId: string | null | undefined
): ApiGetQuery<ChemicalContainerDetail> => {
	return useGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Container,
			containerId ?? "",
		],
		{
			queryConfig: {
				enabled: !!containerId,
			},
		}
	)
}

export const useUpdateContainerChemical = (): ApiPatchMutation<UpdateContainerChemicalPatch> => {
	return usePatchRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Container,
		],
		{
			keyToInvalidate: [ConnectivityUrlPaths.Connectivity],
		}
	)
}

export const useCreateChemicalPackage = (): ApiPostMutation<CreateChemicalPackagePost> => {
	return usePostRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Package,
		],
		{
			keyToInvalidate: [ConnectivityUrlPaths.Connectivity],
		}
	)
}

export const useUpdateChemicalPackage = (): ApiPatchMutation<UpdateChemicalPackagePatch> => {
	return usePatchRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Customer,
			ConnectivityUrlPaths.Package,
		],
		{
			keyToInvalidate: [ConnectivityUrlPaths.Connectivity],
		}
	)
}

export const useCustomerWeather = (
	customerId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<CustomerWeather, CustomerWeatherQueryParams>
): ApiInfiniteGetQuery<CustomerWeather> => {
	return useInfiniteGetRequest(
		[ConnectivityUrlPaths.C12y, ConnectivityUrlPaths.V1, ConnectivityUrlPaths.Weather],
		{
			...options,
			params: {
				customer: customerId,
				...options?.params,
			},
			queryConfig: {
				enabled: !!customerId,
				...options?.queryConfig,
			},
		}
	)
}

export const useRegisterDevice = (): ApiPostMutation<
	DeviceRegistrationPost,
	DeviceRegistrationResults
> => {
	return usePostRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.Device,
			ConnectivityUrlPaths.Registration,
		],
		{
			keyToInvalidate: [ConnectivityUrlPaths.Connectivity],
		}
	)
}

export const useWashCounterWashCounts = (
	deviceId: string | null | undefined,
	options?: ApiInfiniteGetQueryOptions<WashCounterWashCounts, WashCounterWashCountsQueryParams>
): ApiInfiniteGetQuery<WashCounterWashCounts> => {
	return useInfiniteGetRequest(
		[
			ConnectivityUrlPaths.Connectivity,
			ConnectivityUrlPaths.V1,
			ConnectivityUrlPaths.Machine,
			ConnectivityUrlPaths.Counts,
		],
		{
			...options,
			params: {
				...options?.params,
				device: deviceId ?? "",
			},
			queryConfig: {
				enabled: !!deviceId,
				...options?.queryConfig,
			},
		}
	)
}
