import { AxiosInstance, AxiosRequestConfig } from "axios"
import decodeJwt, { JwtPayload } from "jwt-decode"
import { z } from "zod"

import { isAxiosError } from "./make-api-error-message"

// a little time before expiration to try refresh (seconds)
const EXPIRE_FUDGE = 10

const AuthTokenSchema = z.object({
	accessToken: z.string(),
	refreshToken: z.string(),
	punchOutSessionId: z.string().nullable().optional(),
})

export type AuthTokens = z.infer<typeof AuthTokenSchema>

// EXPORTS
export const isLoggedIn = (): boolean => {
	return !!getStoredRefreshToken()
}

/** Set the auth tokens in local storage. */
export const setAuthTokens = async (tokens: AuthTokens): Promise<void> =>
	localStorage.setItem(getTokenStorageKey(), JSON.stringify(AuthTokenSchema.parse(tokens)))

export const setAccessToken = async (token: string): Promise<void> => {
	const tokens = await getStoredAuthTokens()

	if (!tokens) {
		return Promise.reject(
			"Trying to set new access token but no auth tokens found in storage. This should not happen."
		)
	}

	tokens.accessToken = token
	await setAuthTokens(tokens)
}

/** Delete the auth tokens from local storage. */
export const clearAuthTokens = async (): Promise<void> => {
	localStorage.removeItem(getTokenStorageKey())

	return Promise.resolve()
}

const getTokenStorageKey = (): string => `auth-tokens-${process.env.NODE_ENV}`

const getStoredAuthTokens = async (): Promise<AuthTokens | null> => {
	const tokensRaw = localStorage.getItem(getTokenStorageKey())

	if (!tokensRaw) {
		return null
	}

	try {
		// parse stored tokens JSON
		return AuthTokenSchema.parse(JSON.parse(tokensRaw))
	} catch (err) {
		console.error("Failed to parse auth tokens: ", tokensRaw, err)

		throw err
	}
}

export const getStoredRefreshToken = async (): Promise<string | null> => {
	const tokens = await getStoredAuthTokens()

	return tokens?.refreshToken || null
}

export const getStoredAccessToken = async (): Promise<string | null> => {
	const tokens = await getStoredAuthTokens()

	return tokens?.accessToken || null
}

export const getStoredPunchOutSessionId = async (): Promise<string | null> => {
	const tokens = await getStoredAuthTokens()

	return tokens?.punchOutSessionId || null
}

const isTokenExpired = (token: string): boolean => {
	const expiration = getExpiresInFromJWT(token) - EXPIRE_FUDGE
	return !expiration || expiration < 0
}

const getTokenExpiresTimeStamp = (token: string): number | undefined => {
	const decoded = decodeJwt<JwtPayload>(token)

	return decoded.exp
}

const getExpiresInFromJWT = (token: string): number => {
	const exp = getTokenExpiresTimeStamp(token)
	return !exp ? -1 : exp - Date.now() / 1000
}

/** Gets the refresh token from local storage and passes it into the refreshing function that you provide. */
const refreshAccessToken = async (requestRefresh: TokenRefreshRequest): Promise<string> => {
	const refreshToken = await getStoredRefreshToken()
	if (!refreshToken) {
		return Promise.reject("No refresh token available")
	}

	try {
		// do refresh with default axios client (we don't want our interceptor applied for refresh)
		const res = await requestRefresh(refreshToken)
		// save tokens
		await setAccessToken(res)
		return res
	} catch (err) {
		// failed to refresh...

		// check error type
		if (
			isAxiosError(err) &&
			err.response &&
			(err.response.status === 401 || err.response.status === 422)
		) {
			// got invalid token response for sure, remove saved tokens because they're invalid
			try {
				localStorage.removeItem(getTokenStorageKey())
			} catch (removeError) {
				return Promise.reject(
					`Got 401 on token refresh: ${err}; Resetting auth token failed: ${removeError}`
				)
			}
			return Promise.reject(`Got 401 on token refresh; Resetting auth token: ${err}`)
		} else {
			// some other error, probably network error
			return Promise.reject(`Failed to refresh auth token: ${err}`)
		}
	}
}

export interface TokenRefreshRequest {
	(token: string): Promise<string>
}
export interface AuthTokenInterceptorConfig {
	header?: string
	headerPrefix?: string
	requestRefresh: TokenRefreshRequest
	authFailureCallbacks?: {
		onNoRefreshToken: (() => void) | null
		onFailedRefresh: (() => void) | null
	}
}

const authTokenInterceptor =
	({
		header = "Authorization",
		headerPrefix = "Bearer ",
		requestRefresh,
		authFailureCallbacks,
	}: AuthTokenInterceptorConfig) =>
	async (requestConfig: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
		// we need refresh token to do any authenticated requests
		if (!(await getStoredRefreshToken())) {
			if (authFailureCallbacks?.onNoRefreshToken) authFailureCallbacks.onNoRefreshToken()
			return requestConfig
		}

		// do refresh if needed
		let accessToken
		try {
			accessToken = await refreshTokenIfNeeded(requestRefresh)
		} catch (err) {
			console.error(err)
			if (authFailureCallbacks?.onFailedRefresh) authFailureCallbacks.onFailedRefresh()
			return Promise.reject(
				`Unable to refresh access token for request: ${requestConfig} due to token refresh error: ${err}`
			)
		}

		// add token to headers
		if (accessToken) {
			requestConfig.headers[header] = `${headerPrefix}${accessToken}`
		}

		return requestConfig
	}

export const refreshTokenIfNeeded = async (
	requestRefresh: TokenRefreshRequest
): Promise<string | undefined> => {
	// use access token (if we have it)
	let accessToken = await getStoredAccessToken()

	// check if access token is expired
	if (!accessToken || isTokenExpired(accessToken)) {
		// do refresh
		accessToken = await refreshAccessToken(requestRefresh)
	}

	return accessToken
}

export const applyAuthTokenInterceptor = (
	axios: AxiosInstance,
	config: AuthTokenInterceptorConfig
): void => {
	if (!axios.interceptors) {
		throw new Error(`invalid axios instance: ${axios}`)
	}
	axios.interceptors.request.use(authTokenInterceptor(config))
}
