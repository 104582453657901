import React, { memo, ReactElement } from "react"

import { ResponsiveContainer } from "recharts"

export interface ResponsiveChartContainerProps {
	height?: number
	children: ReactElement
}

export const ResponsiveChartContainer: React.FC<ResponsiveChartContainerProps> = memo(
	({ height = 400, children }) => {
		return (
			// Why 99%? https://stackoverflow.com/questions/50891591/recharts-responsive-container-does-not-resize-correctly-in-flexbox
			<ResponsiveContainer width="99%" height={height}>
				{children}
			</ResponsiveContainer>
		)
	}
)
