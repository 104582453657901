import React from "react"

import { ReportTimeSpan } from "@ncs/ncs-api"
import { getTimeAgoFromStartDate, TimeAgo } from "@ncs/ts-utils"

import { useInitialId } from "../../util"
import { ExtendableSelectProps, Select } from "../inputs"

export interface TimeAgoSelectorProps
	extends ExtendableSelectProps<(typeof options)[number], TimeAgo> {
	/** Optionally provide a string that will be parsed as a date and then checked
	 * against the list of TimeAgos in the selector. */
	initialStartDate?: string | null
	/**
	 * Pass in a report time span that can be used to put some sanity on what Time Ago
	 * options are available, ie, can't choose hourly for 6 months ago.
	 */
	reportFrequency?: ReportTimeSpan
}

const options: {
	value: TimeAgo | null
	text: string
}[] = [
	{ value: TimeAgo.Today, text: "Today" },
	{ value: TimeAgo.Yesterday, text: "Since yesterday" },
	{ value: TimeAgo.WeekAgo1, text: "Within 1 week" },
	{ value: TimeAgo.WeeksAgo2, text: "Within 2 weeks" },
	{ value: TimeAgo.DaysAgo30, text: "Within 30 days" },
	{ value: TimeAgo.ThisMonth, text: "This month" },
	{ value: TimeAgo.LastMonth, text: "Since last month" },
	{ value: TimeAgo.ThisQuarter, text: "This quarter" },
	{ value: TimeAgo.LastQuarter, text: "Since last quarter" },
	{ value: TimeAgo.MonthsAgo3, text: "Within 3 months" },
	{ value: TimeAgo.MonthsAgo6, text: "Within 6 months" },
	{ value: TimeAgo.YearToDate, text: "Year-to-date" },
	{ value: TimeAgo.YearAgo1, text: "Within 1 year" },
	{ value: TimeAgo.YearsAgo2, text: "Within 2 years" },
	{ value: TimeAgo.YearsAgo5, text: "Within 5 years" },
]

export const tooBigForHourly: (TimeAgo | null)[] = [
	TimeAgo.DaysAgo30,
	TimeAgo.ThisMonth,
	TimeAgo.LastMonth,
	TimeAgo.ThisQuarter,
	TimeAgo.LastQuarter,
	TimeAgo.MonthsAgo3,
	TimeAgo.MonthsAgo6,
	TimeAgo.YearToDate,
	TimeAgo.YearAgo1,
	TimeAgo.YearsAgo2,
	TimeAgo.YearsAgo5,
]

export const TimeAgoSelector: React.FC<TimeAgoSelectorProps> = ({
	initialStartDate,
	onChange,
	reportFrequency,
	label = "Time Period",
	...rest
}) => {
	useInitialId({
		currentValue: rest.value,
		onChange,
		initialId: initialStartDate,
		findInitialOption: () => getTimeAgoFromStartDate(initialStartDate),
	})

	return (
		<Select
			label={label}
			noSelectionOptionText="Any date"
			disableNoSelectionOption={false}
			{...rest}
			onChange={(newValue) => onChange(newValue as TimeAgo | null)}
			options={options}
			disabledAccessor={
				reportFrequency && reportFrequency === ReportTimeSpan.Hourly ?
					(option) => tooBigForHourly.includes(option.value)
				:	undefined
			}
		/>
	)
}
