import { FC, memo, useMemo } from "react"

import { css, useTheme } from "@emotion/react"

import { AnyNcsDeviceStatus, GenericDeviceStatus, getGenericDeviceStatus } from "@ncs/ncs-api"
import { withCssUnit } from "@ncs/ts-utils"

import { Icon, IconName, IconProps } from "../typography"

export interface StatusBlipProps {
	status: AnyNcsDeviceStatus | undefined | null
	size?: string | number
}

export const StatusBlip: FC<StatusBlipProps> = memo(
	({ status: statusProp, size = "0.75rem", ...rest }) => {
		const theme = useTheme()

		const status = getGenericDeviceStatus(statusProp)

		const backgroundColor = useMemo(() => {
			switch (status) {
				case GenericDeviceStatus.Error:
					return theme.palette.error.main
				case GenericDeviceStatus.Warning:
					return theme.palette.warning.light
				case GenericDeviceStatus.Normal:
					return theme.palette.success.main
				case GenericDeviceStatus.Unknown:
					return theme.palette.grey[300]
				default:
					return theme.palette.grey[300]
			}
		}, [status, theme.palette])

		const iconName: IconName | null = useMemo(() => {
			switch (status) {
				case GenericDeviceStatus.Error:
					return "exclamation-triangle"
				case GenericDeviceStatus.Normal:
					return "check-circle"
				case GenericDeviceStatus.Warning:
					return "minus-hexagon"
				case GenericDeviceStatus.Unknown:
					return "question-circle"
				default:
					return null
			}
		}, [status])

		const iconColor: IconProps["color"] = useMemo(() => {
			switch (status) {
				case GenericDeviceStatus.Error:
					return "error"
				case GenericDeviceStatus.Warning:
					return "warning"
				case GenericDeviceStatus.Normal:
					return "success"
				case GenericDeviceStatus.Unknown:
					return "gray"
				default:
					return "text"
			}
		}, [status])

		const blipStyle = useMemo(
			() => css`
				position: relative;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				height: ${withCssUnit(size)};
				width: ${withCssUnit(size)};
				border-radius: 50%;
				background: ${backgroundColor};
				flex: 0 0 auto; // In case it's the child of a flex parent, don't let it change size.
				box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
			`,
			[backgroundColor, size]
		)

		if (iconName) {
			return <Icon family="solid" icon={iconName} color={iconColor} fontSize={size} />
		}

		/* The drawn blip here is just a fallback if there's no icon. */
		return <span {...rest} css={blipStyle} />
	}
)
