import { FC, memo, useMemo } from "react"

import { css, keyframes, Theme, useTheme } from "@emotion/react"
import { Cell, Pie, PieChart } from "recharts"

import { Disabled } from "../disabled"
import { Box } from "../layout"
import { AnimatedEntrance } from "../transitions"
import { Paragraph } from "../typography"

export interface GaugeChartProps {
	/** Width of the chart. */
	width?: number
	/** Float between 0 and 1. 0 is all the way off, 1 is full blast!! If value is above 1, it will be reduced to 1. */
	needlePercentage?: number
	/** The text of the current value to display. */
	currentValueText?: string | number | null
	/** Label for the chart, ie, what it's measuring. */
	label?: string
	/** The values of all your sections should add up to 100. */
	sections?:
		| {
				value: number
				fillColor?: string
		  }[]
		| null
	disabled?: boolean
}

export const GaugeChart: FC<GaugeChartProps> = memo(
	({
		width = 150,
		needlePercentage = 0,
		currentValueText,
		label,
		sections = [
			{
				value: 100,
			},
		],
		disabled = false,
	}) => {
		const theme = useTheme()

		const height = width * 0.5
		const arcWidth = width / 8

		const needleAnimation = useMemo(() => {
			return keyframes`
				from {
					transform: rotate(0deg);
				}
				to {
					transform: rotate(${(needlePercentage <= 1 ? needlePercentage : 1) * 180}deg);
				}
			`
		}, [needlePercentage])

		const needleDerivedStyle = useMemo(() => {
			return css`
				width: ${width / 2 - arcWidth / 0.75}px;
				height: ${width / 15}px;
				border-radius: ${width / 2}px;
				animation: ${needleAnimation} 0.5s 1.25s ease-out forwards;
			`
		}, [width, arcWidth, needleAnimation])

		const data = useMemo(() => {
			return (
				sections ?? [
					{
						value: 100,
					},
				]
			)
		}, [sections])

		return (
			<Disabled disabled={disabled}>
				<Box display="inline-block">
					{currentValueText && (
						<Paragraph textAlign="center" mb={0.5} bold>
							{currentValueText}
						</Paragraph>
					)}

					<Box>
						<PieChart
							width={width}
							height={height}
							margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
						>
							<Pie
								data={data}
								dataKey="value"
								cx={width / 2}
								cy={height}
								startAngle={180}
								endAngle={0}
								outerRadius={width / 2}
								innerRadius={width / 2 - arcWidth}
								paddingAngle={3}
								strokeWidth={0}
							>
								{data.map((entry, i) => (
									<Cell
										key={`cell-${i}`}
										fill={entry.fillColor ?? theme.palette.primary.light}
									/>
								))}
							</Pie>
						</PieChart>

						<AnimatedEntrance show direction="fade" delay={750}>
							<div css={[needleBaseStyle, needleDerivedStyle]} />
						</AnimatedEntrance>
					</Box>

					{label && (
						<Paragraph textAlign="center" mt={0.5}>
							{label}
						</Paragraph>
					)}
				</Box>
			</Disabled>
		)
	}
)

const needleBaseStyle = (theme: Theme) => css`
	position: absolute;
	bottom: 0;
	right: 50%;
	transform-origin: center right;
	clip-path: polygon(0 50%, 90% 0, 100% 0, 100% 100%, 90% 100%, 0 50%);
	background: ${theme.palette.primary.main};
`
