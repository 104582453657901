import { ReactElement } from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { SiteSelector, SiteSelectorProps } from "../selectors"

export interface SiteSelectorFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<SiteSelectorProps, "value" | "onChange"> {
	control: Control<TFieldValues>
	name: Path<TFieldValues>
	emptyValueFallback?: string | null
}

export const SiteSelectorFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	emptyValueFallback,
	...rest
}: SiteSelectorFormFieldProps<TFieldValues>): ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<SiteSelector
						{...rest}
						onBlur={onBlur}
						value={value != null ? String(value) : emptyValueFallback ?? null}
						onChange={(newValue) => onChange(newValue ?? emptyValueFallback ?? null)}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}
