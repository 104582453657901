import { FC, memo, useCallback, useEffect, useMemo, useState } from "react"

import { Checkbox } from "@material-ui/core"

import { CustomerSite, useSites } from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ControlLabel,
	ExtendableModalProps,
	getAddressFields,
	LoadingSpinner,
	Modal,
	Paragraph,
	ThrottledTextInput,
	useChangeCallback,
} from "@ncs/web-legos"

export interface SelectPermittedSitesModalProps extends ExtendableModalProps {
	startingSelections: string[]
	onChoose: (sites: CustomerSite[]) => void
	isEdit: boolean
}

export const SelectPermittedSitesModal: FC<SelectPermittedSitesModalProps> = memo(
	({ startingSelections, onChoose, isEdit, ...rest }) => {
		const [search, setSearch] = useState<string | null>(null)
		const [checkedSites, setCheckedSite] = useState<{ [siteId: string]: boolean }>({})
		const [errorText, setErrorText] = useState<string | null>(null)

		const [sites, sitesLoading] = useSites()

		const changeAll = (newState: boolean) => {
			setSearch(null)
			setCheckedSite((prev) => {
				return Object.fromEntries(Object.keys(prev).map((siteId) => [siteId, newState]))
			})
		}

		const handleUseSelections = () => {
			const sitesSelected = (sites ?? []).filter((site) => checkedSites[site.id])

			if (!isEdit && sitesSelected.length === 0) {
				setErrorText(
					"New accounts must be set up with permission to at least one of your sites"
				)
			} else {
				onChoose(sitesSelected)
				rest.onClose()
			}
		}

		// When the sites call comes back, set up our checked state.
		useChangeCallback(
			sites,
			(newSites) => {
				if (newSites) {
					setCheckedSite(
						Object.fromEntries(
							newSites.map((site) => {
								return [site.id, startingSelections.includes(site.id)]
							})
						)
					)
				}
			},
			{ callOnSetup: true }
		)

		const handleCheckChange = useCallback((id: string, newState: boolean) => {
			setCheckedSite((prev) => ({
				...prev,
				[id]: newState,
			}))
		}, [])

		const preparedSites = useMemo(() => {
			const searchChunks = search?.split(" ").map((c) => c.toUpperCase())

			return (sites ?? [])
				.filter((site) => {
					if (!search || !searchChunks) return true

					const siteString = Object.values(site).join("").toUpperCase()

					return searchChunks.every((chunk) => siteString.includes(chunk))
				})
				.sort((a, b) => (a.name > b.name ? 1 : -1))
		}, [sites, search])

		const [selectedCount, totalCount] = useMemo(() => {
			const states = Object.values(checkedSites)

			return [states.filter((state) => state).length, states.length]
		}, [checkedSites])

		useEffect(() => {
			setErrorText(null)
		}, [checkedSites])

		return (
			<Modal
				{...rest}
				title="Choose Sites"
				fullHeight
				disableBackdropClick
				disableEscapeKeyDown
				rightButtons={{
					buttonText: `Use selections (${formatNumber(selectedCount)})`,
					onClick: handleUseSelections,
					disabled: sitesLoading,
				}}
				errorText={errorText}
				stickyTopContent={
					<>
						<Paragraph mb={1}>
							Which of your sites should this user have permissions for?
						</Paragraph>

						<ThrottledTextInput
							value={search}
							onChange={setSearch}
							icon="search"
							label="Search"
							clearable
						/>
						<Box display="flex" gap={1} mb={2}>
							<Button icon="check-square" onClick={() => changeAll(true)}>
								Check all
							</Button>
							<Button icon="square" onClick={() => changeAll(false)}>
								Check none
							</Button>
						</Box>

						{!!sites?.length && (
							<Paragraph>
								<strong>{formatNumber(selectedCount)}</strong> of{" "}
								<strong>{formatNumber(totalCount)}</strong> sites selected
							</Paragraph>
						)}
					</>
				}
			>
				{sitesLoading && <LoadingSpinner />}

				{preparedSites.map((site) => {
					return (
						<SiteCheckboxRow
							key={site.id}
							id={site.id}
							checked={!!checkedSites[site.id]}
							onChange={handleCheckChange}
							name={site.name}
							address={getAddressFields(site, {
								exclude: ["name", "zip"],
							}).join(", ")}
						/>
					)
				})}

				{!!search && !sitesLoading && preparedSites.length === 0 && (
					<Paragraph small secondary>
						No sites matched <strong>"{search}"</strong>
					</Paragraph>
				)}

				{!sitesLoading && !!sites && sites.length === 0 && (
					<Paragraph small secondary>
						No sites found to choose from
					</Paragraph>
				)}
			</Modal>
		)
	}
)

SelectPermittedSitesModal.displayName = "SelectPermittedSitesModal"

interface SiteCheckboxRowProps {
	id: string
	checked: boolean
	onChange: (id: string, newState: boolean) => void
	name: string
	address: string
}

/**
 * Memoized sub-component for performance.
 */
const SiteCheckboxRow: FC<SiteCheckboxRowProps> = memo(
	({ id, checked, onChange, name, address }) => {
		return (
			<Box key={id} display="flex" alignItems="flex-start" mb={1} ml={-0.5}>
				<Checkbox
					id={id}
					name={id}
					color="primary"
					checked={checked}
					onChange={(e) => onChange(id, e.target.checked)}
				/>
				<ControlLabel htmlFor={id} top="10px">
					<Paragraph>{name}</Paragraph>
					<Paragraph small secondary>
						{address}
					</Paragraph>
				</ControlLabel>
			</Box>
		)
	}
)
