import { FC } from "react"

import { Dialog } from "@material-ui/core"

import { Button } from "../buttons"
import { Box } from "../layout"
import { Heading, Paragraph } from "../typography"

export interface ConfirmCloseModalProps {
	handleConfirm: () => void
	handleCancel: () => void
	message?: string
}

export const ConfirmCloseModal: FC<ConfirmCloseModalProps> = ({
	message,
	handleConfirm,
	handleCancel,
}) => {
	return (
		<Dialog open maxWidth="sm" onClose={handleCancel}>
			<Box p={1} minWidth={20}>
				<Heading mb={2} bold>
					Exit Modal?
				</Heading>

				<Paragraph mb={2}>{message || "Confirm: Exit this modal?"}</Paragraph>

				<Box
					d="flex"
					justifyContent="flex-end"
					gap={1}
					alignItems="center"
					xsProps={{ flexDirection: "column-reverse" }}
				>
					<Button variant="clear" onClick={handleCancel}>
						CANCEL
					</Button>
					<Button variant="primary-cta" onClick={handleConfirm}>
						Yes
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}
