import React, { useState } from "react"

import { Redirect } from "react-router-dom"
import { Column } from "react-table"

import {
	APPLICATION,
	CustomerInvoiceSummary,
	initialCustomerInvoiceQueryParams,
	useAuth,
	useCustomerInvoicesSummary,
} from "@ncs/ncs-api"
import { formatCurrency, formatDate } from "@ncs/ts-utils"
import {
	AmountDueQueryFilter,
	EmptyValueDash,
	Heading,
	InvoiceTypeQueryFilter,
	SearchQueryFilter,
	SiteQueryFilter,
	Table,
	TimeAgoQueryFilter,
	useUrlState,
} from "@ncs/web-legos"

import { InvoiceDetailModal } from "./components"

const columns: Column<CustomerInvoiceSummary>[] = [
	{
		Header: "Invoice #",
		accessor: "invoiceNumber",
		disableSortBy: true,
	},
	{
		id: "customer_Name",
		Header: "Site",
		accessor: (invoice) => invoice.customer?.name,
	},
	{
		Header: "Invoice type",
		accessor: "type",
	},
	{
		Header: "Invoice date",
		accessor: "invoiceDate",
		Cell: ({ row: { original } }) => (
			<span>
				{original.invoiceDate ? formatDate(original.invoiceDate) : <EmptyValueDash />}
			</span>
		),
	},
	{
		Header: "Due Date",
		accessor: "dueDate",
		Cell: ({ row: { original } }) => (
			<span>{original.dueDate ? formatDate(original.dueDate) : <EmptyValueDash />}</span>
		),
		hiddenByDefault: true,
	},
	{
		id: "total",
		Header: "Amount",
		accessor: (invoice) => formatCurrency(invoice.total),
	},
	{
		id: "amountDue",
		Header: "Amount Due",
		accessor: (invoice) => formatCurrency(invoice.amountDue),
	},
]

export type InvoicesTabUrlState = {
	invoiceModalId: string | null
}

export const InvoicesTab: React.FC = () => {
	const { user } = useAuth()
	const [queryParamState, setQueryParamState] = useState(initialCustomerInvoiceQueryParams)
	const invoicesQuery = useCustomerInvoicesSummary({ params: queryParamState })

	const [{ invoiceModalId }, { updateUrlValue }] = useUrlState<InvoicesTabUrlState>({
		invoiceModalId: null,
	})

	if (!!user && user.apps.includes(APPLICATION.HidePaymentInfo)) {
		return <Redirect to="/account" />
	}

	return (
		<>
			<Heading bold>Invoice History</Heading>

			<Table
				columns={columns}
				onRowClick={(row) => updateUrlValue("invoiceModalId", row.original.id)}
				noDataText="No invoices found."
				loadingText="Loading your invoices..."
				query={invoicesQuery}
				queryParamState={queryParamState}
				setQueryParamState={setQueryParamState}
				toggledQueryFilters={[
					TimeAgoQueryFilter,
					SiteQueryFilter,
					InvoiceTypeQueryFilter,
					AmountDueQueryFilter,
					SearchQueryFilter,
				]}
			/>

			<InvoiceDetailModal
				isOpen={!!invoiceModalId}
				onClose={() => updateUrlValue("invoiceModalId", null)}
				invoiceId={invoiceModalId}
			/>
		</>
	)
}
