import { CustomerPartOrderPostLineItem, LineItemType } from "@ncs/ncs-api"

import { Shipment, ShopContextState } from "~/contexts"

export const makeNewOrderLineItems = (
	cart: ShopContextState["cart"],
	shipments: Shipment[] | null,
	accessorials: ShopContextState["checkout"]["accessorials"],
	freightTotal: number
): CustomerPartOrderPostLineItem[] => {
	const partLineItems = (shipments ?? []).reduce((items, shipment) => {
		return [
			...items,
			...shipment.parts.flatMap((shippitPart) => {
				const cartPart = cart.find(({ part }) => part.id === shippitPart.id)

				// The order line item requires data from both the shippit response as well as from the
				// cart. It will normally be there, but if you've just removed it from the cart there
				// may be a few frames where shippit has a part but it's not in the cart, so weed
				// that out here.
				if (!cartPart) {
					return []
				}

				if (!shipment.service) {
					throw new Error("Shipment does not have a service selected")
				}

				const newLineItem: CustomerPartOrderPostLineItem = {
					partId: cartPart.part.part.id,
					onlinePartId: cartPart.part.id,
					taxRateUsed: ((shippitPart.taxRate ?? 0) / 100).toFixed(7),
					unitTax: (
						shippitPart.price *
						shippitPart.quantity *
						((shippitPart.taxRate ?? 0) / 100)
					).toFixed(7),
					unitPrice: shippitPart.price,
					description: shippitPart.title ?? "",
					quantity: shippitPart.quantity,
					shipping: shipment.service,
					lineTypeId: LineItemType.Parts,
				}

				return [newLineItem]
			}),
		]
	}, [] as CustomerPartOrderPostLineItem[])

	const accessorialLineItems = accessorials.map(
		(accessorial): CustomerPartOrderPostLineItem => ({
			partId: null,
			onlinePartId: null,
			taxRateUsed: "0",
			unitTax: "0",
			shipping: null,
			quantity: 1,
			unitPrice: accessorial.rate,
			description: accessorial.lineItemDescription,
			lineTypeId: accessorial.lineItemTypeId.toString() as LineItemType,
		})
	)

	const freightLineItem = {
		partId: null,
		onlinePartId: null,
		taxRateUsed: "0",
		unitTax: "0",
		shipping: null,
		quantity: 1,
		unitPrice: freightTotal,
		description: "Freight",
		lineTypeId: LineItemType.Freight,
	}

	return [...partLineItems, ...accessorialLineItems, freightLineItem]
}
