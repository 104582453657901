import React, {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react"

import { AxiosResponse } from "axios"

import { extractNumber } from "@ncs/ts-utils"

import { usePrevious } from "../util"
import { ClientVersionHeader } from "./NcsApiProvider"

export interface ClientVersionContextState {
	requiredClientVersion: number | null
	clientStaleSince: string | null
	clientVersionHeader: ClientVersionHeader | null
}

const defaultClientVersionContextState: ClientVersionContextState = {
	requiredClientVersion: null,
	clientStaleSince: null,
	clientVersionHeader: null,
}

type SetClientVersionContextState = Dispatch<SetStateAction<ClientVersionContextState>>

const ClientVersionContext = createContext<
	[ClientVersionContextState, SetClientVersionContextState] | undefined
>(undefined)

ClientVersionContext.displayName = "ClientVersionContext"

export interface ClientVersionContextProviderProps {
	clientVersionHeader: ClientVersionHeader
}

export const ClientVersionContextProvider: FC<ClientVersionContextProviderProps> = ({
	clientVersionHeader,
	children,
}) => {
	const clientVersionContext = useState<ClientVersionContextState>({
		...defaultClientVersionContextState,
		clientVersionHeader,
	})
	const [{ requiredClientVersion, clientStaleSince }, setClientVersionContext] =
		clientVersionContext

	// After a client version update in the database, the backend can still sometimes respond with
	// the old version in some calls at the same time as responding with the new one in other calls.
	// Do a timeout here to only label client as stale once we've given the backend a chance to
	// "settle" after a client version bump. Note that old versions may still come back, even after
	// this timeout, but this ensures that we've at least seen our newest version and that's what'll
	// be stored in state and compared to. So if an older (smaller) version comes back after this it
	// will be ignored when it fails newVersion > prevVersion.
	const hasSettled = useRef(false)
	useEffect(() => {
		const settleTimeout = setTimeout(() => {
			hasSettled.current = true
		}, 10000)

		return (): void => {
			clearTimeout(settleTimeout)
		}
	}, [])

	// Watch for a bump in the version and mark as stale if necessary.
	const prevVersion = usePrevious(requiredClientVersion)
	useEffect(() => {
		// These start off as null, so test for truthiness first.
		if (
			!clientStaleSince &&
			!!requiredClientVersion &&
			!!prevVersion &&
			requiredClientVersion > prevVersion &&
			hasSettled.current === true
		) {
			setClientVersionContext((prev) => ({
				...prev,
				clientStaleSince: new Date().toISOString(),
			}))
		}
	}, [clientStaleSince, prevVersion, requiredClientVersion, setClientVersionContext])

	return (
		<ClientVersionContext.Provider value={clientVersionContext}>
			{children}
		</ClientVersionContext.Provider>
	)
}

export const useClientVersionContext = (): [
	ClientVersionContextState,
	SetClientVersionContextState,
] => {
	const context = useContext(ClientVersionContext)

	if (!context) {
		throw new Error("useClientVersionContext must be used within ClientVersionContextProvider")
	}

	return context
}

type EvaluateHeaders = (headers: AxiosResponse["headers"]) => void

export const useSetClientVersionFromHeaders = (): EvaluateHeaders => {
	const [{ requiredClientVersion, clientVersionHeader }, setClientVersionState] =
		useClientVersionContext()

	const evaluateHeaders: EvaluateHeaders = (headers) => {
		if (!clientVersionHeader) {
			throw new Error(
				"Cannot look for version header because it is null. Did you forget to pass the `clientVersionHeader` prop to `NcsApiProvider`?"
			)
		}

		const newVersion = extractNumber(headers[clientVersionHeader])

		if (newVersion > (requiredClientVersion || 0)) {
			setClientVersionState((prev) => ({
				...prev,
				requiredClientVersion: newVersion,
			}))
		}
	}

	return evaluateHeaders
}
