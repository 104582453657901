/**
 * Brands are a different, you just get all of them in one import.
 */
import { fab } from "@fortawesome/free-brands-svg-icons"
/**
 * Regular-style icons are our default, so if you import from light or solid,
 * specify that in the import name to avoid collisions.
 */
import { faTimes as faTimesLight } from "@fortawesome/pro-light-svg-icons"
import {
	faAngleDown,
	faAngleUp,
	faArrowDown,
	faBan,
	faBars,
	faBell,
	faBellOn,
	faBolt,
	faBox,
	faBullhorn,
	faCalendar,
	faCarCrash,
	faCarWash,
	faChartBar,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faCloud,
	faCloudRain,
	faClouds,
	faCloudShowersHeavy,
	faCloudsMoon,
	faCloudSnow,
	faCloudsSun,
	faCloudSun,
	faCloudSunRain,
	faCommentAltLines,
	faCreditCard,
	faDollarSign,
	faDownload,
	faEnvelope,
	faFileInvoiceDollar,
	faFilePdf,
	faFilter,
	faGlobe,
	faHome,
	faHurricane,
	faInfoCircle,
	faLink,
	faLock,
	faLongArrowDown,
	faLongArrowLeft,
	faLongArrowRight,
	faLongArrowUp,
	faMinus,
	faMoneyBillWave,
	faMoon,
	faMoonCloud,
	faPaperPlane,
	faPencil,
	faPersonDigging,
	faPhone,
	faPlus,
	faPrescriptionBottle,
	faQuestionCircle,
	faSatelliteDish,
	faSearch,
	faShoppingCart,
	faSignIn,
	faSignOut,
	faSms,
	faSpinnerThird,
	faSquare,
	faSun,
	faSunCloud,
	faSync,
	faTable,
	faTag,
	faThunderstorm,
	faTimes,
	faTrash,
	faUniversity,
	faUsdCircle,
	faUser,
	faUserHardHat,
	faUserHeadset,
	faUserPlus,
	faUsersCog,
	faWifiSlash,
	faWind,
} from "@fortawesome/pro-regular-svg-icons"
import {
	faCaretDown as faCaretDownSolid,
	faCaretRight as faCaretRightSolid,
	faCaretUp as faCaretUpSolid,
	faCheckCircle as faCheckCircleSolid,
	faExclamationTriangle as faExclamationTriangleSolid,
	faInfoCircle as faInfoCircleSolid,
	faLock as faLockSolid,
	faShoppingCart as faShoppingCartSolid,
	faUser as faUserSolid,
	faWrench as faWrenchSolid,
} from "@fortawesome/pro-solid-svg-icons"

export const faIcons = [
	faAngleDown,
	faAngleUp,
	faArrowDown,
	fab,
	faBan,
	faBars,
	faBell,
	faBellOn,
	faBolt,
	faBox,
	faBullhorn,
	faCalendar,
	faCarCrash,
	faCaretDownSolid,
	faCaretRightSolid,
	faCaretUpSolid,
	faCarWash,
	faChartBar,
	faCheck,
	faCheckCircle,
	faCheckCircleSolid,
	faCheckSquare,
	faCloud,
	faCloudRain,
	faClouds,
	faCloudShowersHeavy,
	faCloudsMoon,
	faCloudSnow,
	faCloudsSun,
	faCloudSun,
	faCloudSunRain,
	faCommentAltLines,
	faCreditCard,
	faDollarSign,
	faDownload,
	faEnvelope,
	faExclamationTriangleSolid,
	faFileInvoiceDollar,
	faFilePdf,
	faFilter,
	faGlobe,
	faHome,
	faHurricane,
	faInfoCircle,
	faInfoCircleSolid,
	faLink,
	faLock,
	faLockSolid,
	faLongArrowDown,
	faLongArrowLeft,
	faLongArrowRight,
	faLongArrowUp,
	faMinus,
	faMoneyBillWave,
	faMoon,
	faMoonCloud,
	faPaperPlane,
	faPencil,
	faPersonDigging,
	faPhone,
	faPlus,
	faPrescriptionBottle,
	faQuestionCircle,
	faSatelliteDish,
	faSearch,
	faShoppingCart,
	faShoppingCartSolid,
	faSignIn,
	faSignOut,
	faSms,
	faSpinnerThird,
	faSquare,
	faSun,
	faSunCloud,
	faSync,
	faTable,
	faTag,
	faThunderstorm,
	faTimes,
	faTimesLight,
	faTrash,
	faUniversity,
	faUsdCircle,
	faUser,
	faUserHardHat,
	faUserHeadset,
	faUserPlus,
	faUsersCog,
	faUserSolid,
	faWifiSlash,
	faWind,
	faWrenchSolid,
]
