import React, { memo } from "react"

import { PurchaseOrderStatus, usePurchaseOrderStatuses } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PurchaseOrderStatusSelectorProps
	extends ExtendableSelectProps<PurchaseOrderStatus> {}

export const PurchaseOrderStatusSelector: React.FC<PurchaseOrderStatusSelectorProps> = memo(
	(props) => {
		const [purchaseOrderStatuses, purchaseOrderStatusesAreLoading] = usePurchaseOrderStatuses()

		return (
			<Select
				label="Order status"
				disabled={purchaseOrderStatusesAreLoading}
				{...props}
				options={purchaseOrderStatuses ?? []}
				valueAccessor={(option) => String(option.id)}
				textAccessor="description"
			/>
		)
	}
)
