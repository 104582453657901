import React, { useMemo, useState } from "react"

import { css } from "@emotion/react"

import {
	CustomerPartQueryParams,
	initialCustomerPartQueryParams,
	usePartCategories,
	usePartManufacturers,
	usePartSystems,
} from "@ncs/ncs-api"
import { arrayWrap, SubTypeIncluded, toggleArrayItem } from "@ncs/ts-utils"
import {
	Button,
	CheckboxGroup,
	Heading,
	Paragraph,
	Select,
	trackEvent,
	TrackingEvent,
	SkeletonRows,
	useUrlState,
} from "@ncs/web-legos"

import { PriceRange, priceRanges } from "../part-search-results-util"
import { PartSearchResultsUrlState } from "../PartSearchResults"

export const PartSearchFilters: React.FC = () => {
	const [brands, loadingBrands] = usePartManufacturers()
	const [categories, loadingCategories] = usePartCategories()
	const [systems, loadingSystems] = usePartSystems()
	const [showAllBrands, setShowAllBrands] = useState(false)
	const [showAllSystems, setShowAllSystems] = useState(false)
	const [showAllCategories, setShowAllCategories] = useState(false)

	const [partQueryParams, { setUrlState, resetUrlValues }] =
		useUrlState<PartSearchResultsUrlState>(initialCustomerPartQueryParams, {
			removeKeysOnUnmount: false,
		})

	const queryManufacturers = arrayWrap(partQueryParams.manufacturers).map((m) => String(m))
	const querySystems = arrayWrap(partQueryParams.systems).map((s) => String(s))
	const queryCategories = arrayWrap(partQueryParams.categories).map((c) => String(c))

	const toggleFilterCheckbox = (
		idToToggle: string,
		name: string | null,
		type: keyof SubTypeIncluded<CustomerPartQueryParams, string[]>
	) => {
		setUrlState((prev) => ({
			...prev,
			[type]: toggleArrayItem(
				idToToggle,
				arrayWrap(partQueryParams[type]).map((p) => String(p))
			),
		}))
		trackEvent(TrackingEvent.FILTERS, { id: idToToggle, name, type })
	}

	const updatePriceRange = (newPriceRange?: PriceRange) => {
		const newMin = newPriceRange?.min ?? null
		const newMax = newPriceRange?.max ?? null

		setUrlState((prev) => ({
			...prev,
			price_Gte: newMin ? String(newMin) : null,
			price_Lt: newMax ? String(newMax) : null,
		}))

		trackEvent(TrackingEvent.FILTERS, {
			id: newPriceRange?.id,
			name: newPriceRange?.text,
			type: "price",
		})
	}

	const selectedPriceRangeId = useMemo(() => {
		const currentMin = partQueryParams.price_Gte
		const currentMax = partQueryParams.price_Lt

		return priceRanges.find(
			(r) =>
				(r.min ? String(r.min) : null) === currentMin &&
				(r.max ? String(r.max) : null) === currentMax
		)?.id
	}, [partQueryParams.price_Lt, partQueryParams.price_Gte])

	// Take our big list of systems and filter it down if a brand is selected.
	const systemsForSelectedBrands = useMemo(() => {
		return queryManufacturers.length > 0 ?
				systems?.filter((system) =>
					queryManufacturers.includes(String(system.manufacturer.id))
				) ?? []
			:	systems ?? []
	}, [queryManufacturers, systems])

	// Decide on which brands, systems, and categories should actually be shown.
	const brandRows = useMemo(() => {
		return showAllBrands ?
				brands ?? []
			:	(brands ?? []).flatMap((brand, i) =>
					i <= 3 || queryManufacturers.includes(brand.id.toString()) ? [brand] : []
				)
	}, [brands, queryManufacturers, showAllBrands])
	const systemRows = useMemo(() => {
		return showAllSystems ?
				systemsForSelectedBrands ?? []
			:	(systemsForSelectedBrands ?? []).flatMap((system, i) =>
					i <= 3 || querySystems.includes(system.id.toString()) ? [system] : []
				)
	}, [systemsForSelectedBrands, querySystems, showAllSystems])
	const categoryRows = useMemo(() => {
		return showAllCategories ?
				categories ?? []
			:	(categories ?? []).flatMap((category, i) =>
					i <= 3 || queryCategories.includes(category.id.toString()) ? [category] : []
				)
	}, [categories, queryCategories, showAllCategories])

	return (
		<>
			<div css={filterContainer}>
				<Heading bold>
					Brands {queryManufacturers.length > 0 && `(${queryManufacturers.length})`}
				</Heading>
				{loadingBrands && <SkeletonRows />}
				<CheckboxGroup
					mt={0.75}
					groupName="brands"
					rows={brandRows}
					onChange={(brand) =>
						toggleFilterCheckbox(String(brand.id), brand.name, "manufacturers")
					}
					valueAccessor={(row) => row.id.toString()}
					labelAccessor="name"
					checkedAccessor={(brand) => queryManufacturers.includes(String(brand.id))}
				/>
				<Button onClick={() => setShowAllBrands(!showAllBrands)}>
					Show {showAllBrands ? "fewer" : "all"} brands
				</Button>
			</div>

			<div css={filterContainer}>
				<Heading bold>
					Systems {querySystems.length > 0 && `(${querySystems.length})`}
				</Heading>
				{systemsForSelectedBrands.length === 0 && queryManufacturers.length > 0 && (
					<Paragraph small color="secondary" mt={0.5}>
						No systems for selected brand(s)
					</Paragraph>
				)}
				{systemsForSelectedBrands.length > 0 && queryManufacturers.length > 0 && (
					<Paragraph small color="secondary" mt={0.5}>
						for{" "}
						{queryManufacturers.map((brandId, i) => {
							return `${(brands ?? []).find(
								(brand) => brand.id.toString() === brandId
							)?.name}${i !== queryManufacturers.length - 1 ? ", " : ""}`
						})}
					</Paragraph>
				)}
				{loadingSystems && <SkeletonRows />}
				<CheckboxGroup
					mt={0.75}
					groupName="systems"
					rows={systemRows}
					onChange={(system) =>
						toggleFilterCheckbox(String(system.id), system.modelName, "systems")
					}
					valueAccessor={(row) => row.id.toString()}
					labelAccessor="modelName"
					checkedAccessor={(system) => querySystems.includes(String(system.id))}
				/>
				{systemsForSelectedBrands.length > 4 && (
					<Button onClick={() => setShowAllSystems(!showAllSystems)}>
						Show {showAllSystems ? "fewer" : "all"} systems
					</Button>
				)}
			</div>

			<div css={filterContainer}>
				<Heading bold>
					Categories {queryCategories.length > 0 && `(${queryCategories.length})`}
				</Heading>
				{loadingCategories && <SkeletonRows />}
				<CheckboxGroup
					mt={0.75}
					groupName="categories"
					rows={categoryRows}
					onChange={(category) =>
						toggleFilterCheckbox(String(category.id), category.name, "categories")
					}
					valueAccessor={(row) => row.id.toString()}
					labelAccessor="name"
					checkedAccessor={(category) => queryCategories.includes(String(category.id))}
				/>
				<Button onClick={() => setShowAllCategories(!showAllCategories)}>
					Show {showAllCategories ? "fewer" : "all"} categories
				</Button>
			</div>

			<div css={filterContainer}>
				<Heading bold>Price {selectedPriceRangeId ? " (1)" : ""}</Heading>
				<Select
					options={priceRanges}
					value={selectedPriceRangeId ?? null}
					onChange={(rangeId, range) => updatePriceRange(range)}
					valueAccessor="id"
					disableNoSelectionOption={false}
					mt={0.75}
					mb={0}
				/>
			</div>

			<Button
				icon="times"
				iconFamily="light"
				onClick={() => {
					resetUrlValues()
					setShowAllBrands(false)
					setShowAllSystems(false)
					setShowAllCategories(false)
				}}
			>
				Clear filters
			</Button>
		</>
	)
}

const filterContainer = css`
	margin-bottom: 3rem;
	h3 {
		margin-bottom: 0.25rem;
	}
	ul {
		margin-bottom: 0.75rem;
		li {
			margin-bottom: -0.35rem;
		}
		label {
			font-size: 0.875rem;
		}
	}
`
