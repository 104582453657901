import React, { useMemo, useState } from "react"

import { captureException } from "@sentry/minimal"

import {
	CustomerDispatch,
	makeApiErrorMessage,
	useCustomerDispatchCallLogs,
	useUpdateCustomerDispatch,
} from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	Button,
	ErrorText,
	GridContainer,
	GridItem,
	Heading,
	LabeledData,
	LoadingSpinner,
	Textarea,
} from "@ncs/web-legos"

export interface ServiceRequestDetailCommentsTabProps {
	dispatch?: CustomerDispatch
}

export const ServiceRequestDetailCommentsTab: React.FC<ServiceRequestDetailCommentsTabProps> = ({
	dispatch,
}) => {
	const [callsResult, callsLoading] = useCustomerDispatchCallLogs(dispatch?.id ?? null)
	const updateDispatch = useUpdateCustomerDispatch()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [newCommentValue, setNewCommentValue] = useState<string | null>(null)

	const calls = useMemo(() => {
		// Put the newest messages on top.
		return (callsResult ?? []).sort((a, b) => (a.createdDate > b.createdDate ? -1 : 1))
	}, [callsResult])

	const onSubmitComment = async () => {
		if (newCommentValue && dispatch?.id) {
			setIsSubmitting(true)
			setErrorMessage(null)
			try {
				await updateDispatch({
					updates: {
						comments: newCommentValue,
						// Status ID field is required, but we don't want to change it, so just include it as-is.
						statusId: dispatch?.statusId,
					},
					id: dispatch.id,
				})
				setNewCommentValue(null)
			} catch (e) {
				setErrorMessage(makeApiErrorMessage(e))
				console.error(e)
				captureException(e)
			} finally {
				setIsSubmitting(false)
			}
		}
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={6}>
				{(!dispatch || callsLoading) && <LoadingSpinner />}

				{(calls ?? []).map((call) => (
					<Box key={call.id}>
						<LabeledData
							label={`${call.contactedBy.name ?? ""} at ${formatDateTime(
								call.createdDate
							)}`}
						>
							{call.comments}
						</LabeledData>
					</Box>
				))}
				{!!dispatch?.callReceivedDate && !callsLoading && (
					<LabeledData label={formatDateTime(dispatch.callReceivedDate)}>
						Service request created
					</LabeledData>
				)}
			</GridItem>

			<GridItem xs={12} sm={6}>
				<Heading icon="comment-alt-lines" variant="h5" bold mb={1}>
					Add New Comment
				</Heading>
				<Textarea
					value={newCommentValue}
					onChange={setNewCommentValue}
					placeholder="Your comment..."
					rows={7.5}
				/>
				{!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
				<Button
					icon="plus"
					variant="secondary-cta"
					containerProps={{ mt: 1 }}
					isLoading={isSubmitting}
					onClick={onSubmitComment}
				>
					Add Comment
				</Button>
			</GridItem>
		</GridContainer>
	)
}
