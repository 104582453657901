import {
	Dispatch,
	PropsWithChildren,
	ReactElement,
	SetStateAction,
	useMemo,
	useState,
} from "react"

import { Row } from "react-table"

import { DataExport, PortalReport } from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"

import { Button, ButtonProps } from "../buttons"
import { Box } from "../layout"
import { DataExportModal } from "../modals"
import { FilterGrid } from "../query-filters"
import { AnimatedEntrance } from "../transitions"
import { Paragraph } from "../typography"
import { ColumnChooser } from "./ColumnChooser"
import { TableProps } from "./Table"
import { PageSizes } from "./table-util"
import { TableBarChart } from "./TableBarChart"

export interface AboveTableProps<RowType extends Object, QueryParamState extends {}> {
	columns: TableProps<RowType, QueryParamState>["columns"]
	queryParamState: TableProps<RowType, QueryParamState>["queryParamState"]
	setQueryParamState: TableProps<RowType, QueryParamState>["setQueryParamState"]
	pinnedQueryFilters: TableProps<RowType, QueryParamState>["pinnedQueryFilters"]
	toggledQueryFilters: TableProps<RowType, QueryParamState>["pinnedQueryFilters"]
	bulkActionButtons: TableProps<RowType, QueryParamState>["bulkActionButtons"]
	contentUnderFilters: TableProps<RowType, QueryParamState>["contentUnderFilters"]
	onReset: TableProps<RowType, QueryParamState>["onReset"]
	filterResetValues?: Partial<QueryParamState>
	toggledFiltersFillDirection: TableProps<
		RowType,
		QueryParamState
	>["toggledFiltersFillDirection"]
	showToggledFiltersByDefault?: boolean
	selectedFlatRows: Row<RowType>[]
	toggleAllRowsSelected: (value?: boolean | undefined) => void
	isAllRowsSelected: boolean
	columnVisibility: Record<string, boolean | undefined>
	setColumnVisibility: React.Dispatch<React.SetStateAction<Record<string, boolean | undefined>>>
	dataExport?: DataExport
	leftButtons?: ButtonProps[]
	rightButtons?: ButtonProps[]
	setPagination?: Dispatch<SetStateAction<{ page: number; pageSize: PageSizes }>>
	barChartReport?: PortalReport
	expandAllRowsButton: boolean
	isSomeRowsExpanded: boolean
	toggleAllRowsExpanded: (value?: boolean) => void
}

/** Everything that renders above the table. */
export const AboveTable = <RowType extends object, QueryParamState extends {}>({
	columns,
	queryParamState,
	setQueryParamState,
	pinnedQueryFilters = [],
	toggledQueryFilters = [],
	bulkActionButtons = [],
	onReset,
	filterResetValues,
	selectedFlatRows,
	toggleAllRowsSelected,
	isAllRowsSelected,
	columnVisibility,
	setColumnVisibility,
	dataExport,
	toggledFiltersFillDirection,
	showToggledFiltersByDefault,
	leftButtons,
	rightButtons,
	setPagination,
	contentUnderFilters,
	barChartReport,
	expandAllRowsButton,
	isSomeRowsExpanded,
	toggleAllRowsExpanded,
}: PropsWithChildren<AboveTableProps<RowType, QueryParamState>>): ReactElement => {
	const [moreFiltersIsExpanded, setMoreFiltersIsExpanded] = useState(
		!!showToggledFiltersByDefault
	)
	const [showDataExportModal, setShowDataExportModal] = useState(false)

	/** Are we using the Choose Columns functionality? */
	const usingHiddenColumns = useMemo(() => columns.some((c) => c.hiddenByDefault), [columns])

	/** Do we have anything to render on the right? */
	const rightAreaNeeded =
		toggledQueryFilters.length > 0 ||
		usingHiddenColumns ||
		!!dataExport ||
		!!rightButtons?.length ||
		expandAllRowsButton

	/** Do we have anything to render on the left? */
	const leftAreaNeeded = bulkActionButtons.length > 0 || !!leftButtons?.length

	/** Do we need to render anything above the table at all? */
	const tableHeaderNeeded = rightAreaNeeded || leftAreaNeeded

	return (
		<>
			{/* The primary query filters */}
			{pinnedQueryFilters.length > 0 && !!queryParamState && !!setQueryParamState && (
				<FilterGrid
					filters={pinnedQueryFilters}
					queryParamState={queryParamState}
					setQueryParamState={setQueryParamState}
					showReset={false}
					fillRightToLeft={false}
				/>
			)}

			{tableHeaderNeeded && (
				<Box
					mt={1}
					mb={1}
					display="flex"
					justifyContent="flex-end"
					alignItems="flex-end"
					flexWrap="wrap"
					rowGap={2}
				>
					{/* The left area */}
					{leftAreaNeeded && (
						<Box
							display="flex"
							mr="auto"
							alignItems="center"
							flexWrap="wrap"
							columnGap={1.52}
							rowGap={1}
						>
							{bulkActionButtons.map((b) => (
								<Button
									key={b.buttonText}
									{...b}
									containerProps={{
										...b.containerProps,
									}}
									disabled={b.disabled || selectedFlatRows.length === 0}
									onClick={() => {
										if (b.onClick)
											b.onClick({
												selectedRows: selectedFlatRows,
												toggleAllRowsSelected,
												isAllRowsSelected,
											})
									}}
								/>
							))}
							{selectedFlatRows.length > 0 && (
								<AnimatedEntrance show>
									<Paragraph small>
										({formatNumber(selectedFlatRows.length)} row
										{selectedFlatRows.length === 1 ? "" : "s"} selected)
									</Paragraph>
								</AnimatedEntrance>
							)}

							{(leftButtons ?? []).map((b) => (
								<Button key={b.buttonText} {...b} />
							))}
						</Box>
					)}

					{/* The right area */}
					{rightAreaNeeded && (
						<Box
							display="flex"
							flexWrap="wrap"
							columnGap={1.52}
							rowGap={1}
							justifyContent="flex-end"
						>
							{(rightButtons ?? []).map((b) => (
								<Button key={b.buttonText} {...b} />
							))}

							{!!expandAllRowsButton && (
								<Button
									icon={
										isSomeRowsExpanded ? "arrows-to-line" : "arrows-from-line"
									}
									onClick={() => toggleAllRowsExpanded(!isSomeRowsExpanded)}
								>
									{isSomeRowsExpanded ? "Collapse" : "Expand"} all rows
								</Button>
							)}

							{!!dataExport && (
								<Button
									icon="external-link"
									onClick={() => setShowDataExportModal(true)}
								>
									Export data
								</Button>
							)}

							{usingHiddenColumns && (
								<ColumnChooser
									allColumns={columns}
									columnVisibility={columnVisibility}
									setColumnVisibility={setColumnVisibility}
								/>
							)}

							{toggledQueryFilters.length > 0 && (
								<Button
									icon="search"
									onClick={() =>
										setMoreFiltersIsExpanded(!moreFiltersIsExpanded)
									}
								>
									{moreFiltersIsExpanded ?
										pinnedQueryFilters.length > 0 ?
											"Hide more filters"
										:	"Hide filters"
									: pinnedQueryFilters.length > 0 ?
										"Show more filters"
									:	"Show filters"}
								</Button>
							)}
						</Box>
					)}
				</Box>
			)}

			{/* The toggled query filters */}
			{!!queryParamState && !!setQueryParamState && toggledQueryFilters.length > 0 && (
				<AnimatedEntrance show={moreFiltersIsExpanded}>
					<FilterGrid
						filters={toggledQueryFilters}
						queryParamState={queryParamState}
						setQueryParamState={setQueryParamState}
						onReset={onReset}
						resetValues={filterResetValues}
						fillRightToLeft={toggledFiltersFillDirection === "rtl"}
					/>
				</AnimatedEntrance>
			)}

			{barChartReport && <TableBarChart report={barChartReport} />}

			{!!contentUnderFilters && <Box>{contentUnderFilters}</Box>}

			{!!dataExport && (
				<DataExportModal
					isOpen={showDataExportModal}
					onClose={() => setShowDataExportModal(false)}
					dataExport={dataExport}
				/>
			)}
		</>
	)
}
