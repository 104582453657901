import "react-big-calendar/lib/css/react-big-calendar.css"

import React, { ComponentProps } from "react"

import { css } from "@emotion/react"
import dayjs from "dayjs"
import { Calendar, dayjsLocalizer } from "react-big-calendar"

import { withCssUnit } from "@ncs/ts-utils"

export interface BigCalendarProps extends Partial<ComponentProps<typeof Calendar>> {
	/** Specify the height of the calendar or it will not be visible. */
	height?: string | number
	localizer?: ComponentProps<typeof Calendar>["localizer"]
}

const defaultLocalizer = dayjsLocalizer(dayjs)

export const BigCalendar: React.FC<BigCalendarProps> = ({
	height = "1000px",
	localizer,
	...rest
}) => {
	const style = css`
		height: ${withCssUnit(height)};
	`

	return (
		<div css={style}>
			<Calendar localizer={localizer ?? defaultLocalizer} {...rest} />
		</div>
	)
}
