import { Dispatch, ReactElement, SetStateAction } from "react"

import { TwoDigitCodeStatusSelector } from "../selectors"

export interface TwoDigitCodeStatusParamState {
	active: boolean | null
}

export interface TwoDigitCodeStatusQueryFilterProps<
	QueryParamState extends TwoDigitCodeStatusParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const MachineTwoDigitCodeStatusQueryFilter = <
	QueryParamState extends TwoDigitCodeStatusParamState,
>({
	queryParamState,
	setQueryParamState,
}: TwoDigitCodeStatusQueryFilterProps<QueryParamState>): ReactElement => {
	return (
		<TwoDigitCodeStatusSelector
			value={queryParamState.active}
			fillContainer
			onChange={(newValue) =>
				setQueryParamState((prev) => {
					const twoDigitCodeStatusParamState: TwoDigitCodeStatusParamState = {
						active: newValue,
					}

					return {
						...prev,
						...twoDigitCodeStatusParamState,
					}
				})
			}
		/>
	)
}
