import { useEffect, useMemo, useRef, useState } from "react"

import axios, { AxiosError, Canceler } from "axios"
import queryString from "query-string"
import { useQuery } from "react-query"

import { arrayWrap, HeaderLinks, parseLinkHeader, unpythonify } from "@ncs/ts-utils"

import { useSetClientVersionFromHeaders } from "../contexts"
import { apiClient, buildUrl, preparePortalParams } from "../util"
import { ApiGetQuery, ApiGetQueryOptions } from "./types"

export const useGetRequest = <Payload, Params = Object>(
	endpoint: string | string[],
	options?: ApiGetQueryOptions<Payload, Params>
): ApiGetQuery<Payload> => {
	const [linkHeaders, setLinkHeaders] = useState<HeaderLinks | null>(null)
	const setClientVersion = useSetClientVersionFromHeaders()

	// Make the axios cancel token.
	const CancelToken = useMemo(() => axios.CancelToken, [])
	const canceler = useRef<Canceler>()

	useEffect(() => {
		return (): void => {
			const cancelerRef = canceler

			if (cancelerRef.current) {
				cancelerRef.current()
			}
		}
	}, [])

	// Construct the URL.
	const endpointArray = useMemo(() => arrayWrap(endpoint), [endpoint])
	const endpointString = useMemo(() => `${endpointArray.join("/")}/`, [endpointArray])

	const preparedParams = useMemo(
		() => preparePortalParams(options?.params, options?.preparePortalParamsOptions),
		[options?.params, options?.preparePortalParamsOptions]
	)

	// Now put the query key together with the prepared params.
	const queryKey = useMemo(() => {
		const result: (string | object)[] = [...endpointArray]
		if (preparedParams) result.push(preparedParams)

		return result
	}, [endpointArray, preparedParams])

	const endpointWithParams = useMemo(
		() =>
			preparedParams ?
				`${endpointString}?${queryString.stringify(preparedParams, {
					skipEmptyString: true,
					skipNull: true,
					arrayFormat: "comma",
				})}`
			:	endpointString,
		[endpointString, preparedParams]
	)

	const query = useQuery<Payload, AxiosError, Payload>(
		queryKey,
		() => {
			return apiClient
				.get(buildUrl(endpointWithParams), {
					cancelToken: new CancelToken((c) => {
						canceler.current = c
					}),
				})
				.then((response) => {
					// Keep client version context up to date with the latest header.
					setClientVersion(response.headers)

					// Keep our local state of link headers up to date.
					setLinkHeaders(parseLinkHeader(response.headers.link))

					if (options?.mapper) {
						return options.mapper(response.data)
					} else {
						return unpythonify(response.data)
					}
				})
		},
		options?.queryConfig
	)

	return [query.data, query.isLoading, { query, linkHeaders, queryKey }]
}
