import React, { useCallback, useMemo } from "react"

import { captureException } from "@sentry/minimal"

import {
	createDocumentToken,
	CustomerInvoiceDocument,
	getDocumentUrlForToken,
	useCustomerInvoice,
	useCustomerInvoiceDocument,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency, formatDate } from "@ncs/ts-utils"
import {
	Button,
	EmptyValueDash,
	GridContainer,
	GridItem,
	Icon,
	LabeledData,
	LoadingSpinner,
	Modal,
	Tabs,
	TabsProps,
} from "@ncs/web-legos"

import { CombinedLineItem, InvoiceDetailLineItemsTab } from "./InvoiceDetailLineItemsTab"
import { InvoiceDetailPayNowTab } from "./InvoiceDetailPayNowTab"

export interface InvoiceDetailModalProps {
	isOpen: boolean
	onClose: () => void
	invoiceId: string | null
}

export const InvoiceDetailModal: React.FC<InvoiceDetailModalProps> = ({
	isOpen,
	onClose,
	invoiceId,
}) => {
	const [invoice, invoiceLoading] = useCustomerInvoice(invoiceId)
	const [document] = useCustomerInvoiceDocument(invoiceId)

	const handleViewDocument = useCallback(async (invoiceToView: CustomerInvoiceDocument) => {
		try {
			await createDocumentToken(invoiceToView.id, invoiceToView.documentType).then(
				({ token }) => {
					const url = getDocumentUrlForToken(token)
					window.open(url, "_blank")
				}
			)
		} catch (e) {
			console.error(e)
			captureException(e)
		}
	}, [])

	const combinedLineItems = useMemo(() => {
		if (!invoice) return []

		const result: CombinedLineItem[] = []

		return result.concat(
			invoice.invoiceLineItems ?? [],
			invoice.partOrderInvoice ?? [],
			invoice.kbmInvoiceLines ?? [],
			invoice.deliveryInvoiceLineItems ?? []
		)
	}, [invoice])

	const tabsPanels = useMemo(() => {
		const panels: TabsProps["panels"] = [
			{
				component: (
					<InvoiceDetailLineItemsTab
						lineItems={combinedLineItems}
						isLoading={invoiceLoading}
					/>
				),
				navLabel: "Line Items",
			},
		]

		if (!!invoice && extractNumber(invoice?.amountDue) > 0) {
			panels.push({
				component: <InvoiceDetailPayNowTab invoice={invoice ?? null} />,
				navLabel: "Pay Now",
				navIcon: <Icon icon="dollar-sign" />,
			})
		}

		return panels
	}, [combinedLineItems, invoice, invoiceLoading])

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title={`Invoice #${invoice?.invoiceNumber ?? ""}`}
			titleDetail={invoice?.billToCustomer?.name ?? ""}
			maxWidth="lg"
		>
			<GridContainer mb={1} rowGap={0}>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Invoice number">{invoice?.invoiceNumber}</LabeledData>
				</GridItem>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Order number">
						{invoice?.partOrder?.orderId ?? <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Created date">
						{invoice?.invoiceDate ?
							formatDate(invoice?.invoiceDate)
						:	<EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Due date">
						{invoice?.dueDate ? formatDate(invoice?.dueDate) : <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Total">{formatCurrency(invoice?.total ?? "")}</LabeledData>
				</GridItem>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Amount paid">
						{formatCurrency(invoice?.amountPaid ?? "")}
					</LabeledData>
				</GridItem>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Amount pending">
						{formatCurrency(invoice?.amountPending ?? "")}
					</LabeledData>
				</GridItem>
				<GridItem xs={6} sm={4} md={3}>
					<LabeledData label="Amount due">
						{formatCurrency(invoice?.amountDue ?? "")}
					</LabeledData>
				</GridItem>
				<GridItem xs={12}>
					<LabeledData label="Comments">{invoice?.comments}</LabeledData>
				</GridItem>
				{!!document && Object.keys(document).length > 0 && (
					<GridItem xs={12} my={1} onClick={() => handleViewDocument(document)}>
						<Button icon="download">Download Invoice</Button>
					</GridItem>
				)}
			</GridContainer>

			{invoiceLoading && <LoadingSpinner />}

			{!!invoice && <Tabs minHeight="20rem" panels={tabsPanels} />}
		</Modal>
	)
}
