import { useMutation, useQueryClient } from "react-query"

import { CamelToSnakeCaseNested, pythonify } from "@ncs/ts-utils"

import { apiClient, makeMutationEndpoint } from "../util"
import { ApiDeleteMutation, ApiDeleteMutationOptions, MutationUrlConfig } from "./types"

export const useDeleteRequest = <BodyData = void, ParamsObject = void>(
	baseUrl: string | string[],
	options?: ApiDeleteMutationOptions
): ApiDeleteMutation<BodyData, ParamsObject> => {
	const queryClient = useQueryClient()

	const lookForBodyData = (
		callConfig: MutationUrlConfig<BodyData, ParamsObject>
	): CamelToSnakeCaseNested<BodyData> | undefined => {
		if (typeof callConfig !== "string" && callConfig.body) {
			return pythonify(callConfig.body, callConfig.pythonifyBodyExcludedKeys)
		}

		return undefined
	}

	const mutation = useMutation(
		(callConfig: MutationUrlConfig<BodyData, ParamsObject>) =>
			apiClient.delete(
				makeMutationEndpoint(
					baseUrl,
					typeof callConfig === "string" ? callConfig : (
						{
							...callConfig,
							// Look for url segments passed into hook options. More likely
							// they'd be there than in the mutation call.
							urlSegmentsAfterId:
								callConfig.urlSegmentsAfterId ?? options?.urlSegmentsAfterId,
						}
					)
				),
				{
					data: lookForBodyData(callConfig),
				}
			),
		{
			onError: (e) => {
				console.error("useDeleteRequest mutation error", e)
			},
			onSuccess: () => {
				if (options?.keyToInvalidate) {
					const keys =
						typeof options.keyToInvalidate === "string" ?
							[options.keyToInvalidate]
						:	options.keyToInvalidate
					keys.forEach((key) => {
						void queryClient.invalidateQueries(key)
					})
				}
			},
			retry: false,
		}
	)

	return mutation.mutateAsync
}
