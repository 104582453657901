import React from "react"

import DayJsUtils from "@date-io/dayjs"
import { Global, ThemeProvider } from "@emotion/react"
import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { HelmetProvider } from "react-helmet-async"
import { BrowserRouter as Router } from "react-router-dom"

import { ClientVersionHeader, NcsApiProvider } from "@ncs/ncs-api"
import { SquareCardContextProvider, ToastProvider, UrlStateProvider } from "@ncs/web-legos"

import { fonts, globalStyles, reset, theme } from "~/styles"

import { ShopContextProvider } from "./ShopContext"

export const AppProvider: React.FC = ({ children }) => (
	<HelmetProvider>
		<NcsApiProvider clientVersionHeader={ClientVersionHeader.Customer}>
			<SquareCardContextProvider
				squareUrl={import.meta.env.VITE_SQUAREUP_URL}
				applicationId={import.meta.env.VITE_SQUAREUP_APPLICATION_ID}
				locationId={import.meta.env.VITE_SQUAREUP_LOCATION_ID}
			>
				<MuiThemeProvider theme={theme}>
					<ThemeProvider theme={theme}>
						<MuiPickersUtilsProvider utils={DayJsUtils}>
							<Global styles={reset} />
							<Global styles={globalStyles} />
							<StylesProvider
								injectFirst // injectFirst lets us override MUI with emotion CSS
							>
								<Router>
									<UrlStateProvider>
										<ToastProvider>
											<ShopContextProvider>{children}</ShopContextProvider>
										</ToastProvider>
									</UrlStateProvider>
								</Router>
							</StylesProvider>
							<Global styles={fonts} />
						</MuiPickersUtilsProvider>
					</ThemeProvider>
				</MuiThemeProvider>
			</SquareCardContextProvider>
		</NcsApiProvider>
	</HelmetProvider>
)
