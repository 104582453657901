import React, { FocusEventHandler } from "react"

import { cssMixins } from "../../util"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"
import { css } from "@emotion/react"

export interface TextareaProps extends FieldContainerProps {
	value: string | null
	onChange: (newValue: string | null) => void
	onBlur?: FocusEventHandler
	placeholder?: string
	rows?: number
	maxLength?: number
	autoFocus?: boolean
	emptyValueFallback?: string | null
}

export const Textarea: React.FC<TextareaProps> = React.memo(
	({
		value,
		onChange,
		onBlur,
		placeholder,
		label,
		fillContainer,
		rows = 3,
		maxLength,
		autoFocus,
		emptyValueFallback = null,
		...rest
	}) => {
		const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
			if (onChange) onChange(e.target.value || emptyValueFallback)
		}

		return (
			<FieldContainer label={label} {...rest}>
				<textarea
					css={[cssMixins.nonMuiFormElementStyle, textareaStyle]}
					rows={rows}
					autoFocus={autoFocus}
					onChange={handleChange}
					onBlur={onBlur}
					value={value ?? ""}
					placeholder={placeholder ?? (label ? `${label}...` : undefined)}
					maxLength={maxLength}
				/>
			</FieldContainer>
		)
	}
)

const textareaStyle = css`
	padding: 1rem;
	border-radius: 0.5rem;
	border: 1px solid #6b7280;
`
