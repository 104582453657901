import { css, Theme } from "@emotion/react"
import { Paragraph } from "@ncs/web-legos/components"
import React from "react"
import { Link } from "@ncs/web-legos/components"
import { envelope, phone } from "./icons"

const ContactUs: React.FC = () => (
	<nav css={footerNavigation} aria-label="Footer Navigation">
		<Paragraph customColor="#87CEFA" bold mb={1} display="flex">
			CONTACT US
		</Paragraph>
		<ul>
			<li>
				<span>{envelope()}</span>
				<Link mailTo to="Parts@NCSwash.com">
					<span>Parts@NCSwash.com</span>
				</Link>
			</li>
			<li>
				<span>{envelope()}</span>
				<Link mailTo to="VC-CustomerService@NCSwash.com">
					<span>VC-CustomerService@NCSwash.com</span>
				</Link>
			</li>
			<li>
				<span>{phone()}</span>
				<Link phoneNumber to="833-NCS-WASH">
					<span>833-NCS-WASH</span>
				</Link>
			</li>
		</ul>
	</nav>
)

export default ContactUs

const footerNavigation = (theme: Theme) => css`
	${theme.breakpoints.down("xs")} {
		margin: 3rem 0;
	}

	li span {
		display: flex;
		margin-right: 0.5rem;
	}

	li {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}

	li a {
		color: #ffffff;
		text-decoration: none;
		font-weight: 700;

		&:focus {
			outline: 2px dashed #87cefa;
			outline-offset: 2px;
		}
	}
`
