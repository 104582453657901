import { FC, useMemo, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
	CustomerAlternateAddress,
	makeApiErrorMessage,
	useCreateCustomerAlternateAddress,
	useCustomerAlternateAddresses,
	useDeleteCustomerAlternateAddress,
	useUpdateCustomerAlternateAddress,
} from "@ncs/ncs-api"
import { unpythonify } from "@ncs/ts-utils"

import { Button } from "../buttons"
import { ExtendableSelectProps, Select } from "../inputs"
import { Box, GridContainer, GridItem } from "../layout"
import { ExtendableModalProps, Modal } from "../modals"
import { StateSelectorFormField, TextInputFormField } from "../react-hook-form"

export interface AlternateShipToSelectorProps
	extends ExtendableSelectProps<CustomerAlternateAddress> {
	customerId: string | null
	hideCrudControls?: boolean
}

export const AlternateShipToSelector: FC<AlternateShipToSelectorProps> = ({
	customerId,
	hideCrudControls,
	onChange,
	value,
	...rest
}) => {
	const [addresses, addressesLoading] = useCustomerAlternateAddresses(customerId)
	const [addressToEdit, setAddressToEdit] = useState<CustomerAlternateAddress | null>(null)
	const [isAddingNew, setIsAddingNew] = useState(false)

	const makeOptionText = (option: CustomerAlternateAddress): string => {
		const pieces = []

		// Not sure what the best info to show here is...
		if (option.description) pieces.push(option.description)
		if (option.name) pieces.push(option.name)
		if (option.address2) pieces.push(option.address2)
		if (option.city) pieces.push(option.city)

		return pieces.join(", ")
	}

	const selectedOption = useMemo(() => {
		return (addresses ?? []).find((address) => address.id === value)
	}, [addresses, value])

	return (
		<>
			<Select
				label="Alternate shipping destination"
				disabled={!customerId}
				isLoading={addressesLoading}
				disableNoSelectionOption={false}
				{...rest}
				options={addresses ?? []}
				value={selectedOption?.id ?? null}
				valueAccessor="id"
				textAccessor={makeOptionText}
				onChange={onChange}
			/>

			{!hideCrudControls && !!customerId && (
				<Box display="flex" columnGap={1} mt={-0.5}>
					{!!selectedOption && (
						<Button icon="pencil" onClick={() => setAddressToEdit(selectedOption)}>
							Edit
						</Button>
					)}
					<Button icon="plus" onClick={() => setIsAddingNew(true)}>
						Add new address
					</Button>
				</Box>
			)}

			{!!customerId && (
				<EditAlternateShipToModal
					customerId={customerId}
					addressToEdit={addressToEdit}
					isOpen={!!addressToEdit || isAddingNew}
					onClose={() => {
						setAddressToEdit(null)
						setIsAddingNew(false)
					}}
					onCreate={(newAddress) => {
						if (onChange) onChange(newAddress.id, newAddress)
					}}
					onUpdate={(updatedAddress) => {
						if (onChange) onChange(updatedAddress.id, updatedAddress)
					}}
					onDelete={() => {
						if (onChange) onChange(null, undefined)
					}}
				/>
			)}
		</>
	)
}

const AlternateAddressFormSchema = z.object({
	description: z.string().min(1, "Required"),
	name: z.string().nullable(),
	address2: z.string().min(1, "Required"),
	address1: z.string().nullable(),
	city: z.string().min(1, "Required"),
	state: z.string().min(1, "Required"),
	postalcode: z.string().min(5, "Invalid postal code"),
})
type AlternateAddressForm = z.infer<typeof AlternateAddressFormSchema>

const alternateAddressFormDefaultValues: AlternateAddressForm = {
	description: "",
	name: null,
	address2: "",
	address1: null,
	city: "",
	state: "",
	postalcode: "",
}

interface EditAlternateShipToModalProps extends ExtendableModalProps {
	customerId: string
	addressToEdit: CustomerAlternateAddress | null
	onCreate?: (newAddress: CustomerAlternateAddress) => void
	onUpdate?: (updatedAddress: CustomerAlternateAddress) => void
	onDelete?: (deletedId: string) => void
}

const EditAlternateShipToModal: FC<EditAlternateShipToModalProps> = ({
	onClose,
	customerId,
	addressToEdit,
	onCreate,
	onUpdate,
	onDelete,
	...rest
}) => {
	const createAddress = useCreateCustomerAlternateAddress(customerId)
	const updateAddress = useUpdateCustomerAlternateAddress(customerId)
	const deleteAddress = useDeleteCustomerAlternateAddress(customerId)

	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [isDeleting, setIsDeleting] = useState(false)
	const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

	const { control, handleSubmit, reset } = useForm<AlternateAddressForm>({
		resolver: zodResolver(AlternateAddressFormSchema),
		defaultValues: alternateAddressFormDefaultValues,
	})

	const onSubmit = async (formData: AlternateAddressForm) => {
		if (addressToEdit) {
			try {
				setIsSaving(true)
				const res = await updateAddress({
					updates: formData,
					id: addressToEdit.id,
				})
				const updatedAddress = unpythonify(res.data)
				if (onUpdate) onUpdate(updatedAddress)
				handleClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsSaving(false)
			}
		} else {
			try {
				setIsSaving(true)
				const res = await createAddress(formData)
				const newAddress = unpythonify(res.data)
				if (onCreate) onCreate(newAddress)
				handleClose()
			} catch (e) {
				setErrorText(
					makeApiErrorMessage(e, {
						closer: "Note that the address is verified by USPS and will error if not valid",
					})
				)
				setIsSaving(false)
			}
		}
	}

	const handleDelete = async () => {
		if (addressToEdit) {
			try {
				setIsDeleting(true)
				await deleteAddress(addressToEdit.id)
				if (onDelete) onDelete(addressToEdit.id)
				handleClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsDeleting(false)
			}
		}
	}

	const handleClose = () => {
		setErrorText(null)
		setIsSaving(false)
		setIsDeleting(false)
		setIsConfirmingDelete(false)
		onClose()
	}

	const onOpen = () => {
		if (addressToEdit) {
			reset({
				description: addressToEdit.description ?? "",
				name: addressToEdit.name ?? null,
				address2: addressToEdit.address2 ?? "",
				address1: addressToEdit.address1 ?? null,
				city: addressToEdit.city ?? "",
				state: addressToEdit.state ?? "",
				postalcode: addressToEdit.postalcode ?? "",
			})
		} else {
			reset(alternateAddressFormDefaultValues)
		}
	}

	return (
		<Modal
			title={addressToEdit ? "Edit Address" : "New Alternate Address"}
			{...rest}
			onClose={handleClose}
			onOpen={onOpen}
			errorText={errorText}
			leftButtons={
				addressToEdit ?
					isConfirmingDelete ?
						[
							{
								icon: "times",
								buttonText: "Cancel",
								onClick: () => setIsConfirmingDelete(false),
								variant: "text",
							},
							{
								icon: "check",
								buttonText: "Confirm: Delete address?",
								onClick: handleDelete,
								isLoading: isDeleting,
								variant: "text",
							},
						]
					:	{
							icon: "trash",
							buttonText: "Delete",
							onClick: () => setIsConfirmingDelete(true),
							variant: "text",
						}

				:	undefined
			}
			rightButtons={{
				buttonText: "Save",
				onClick: handleSubmit(onSubmit),
				isLoading: isSaving,
			}}
		>
			<TextInputFormField
				control={control}
				name="description"
				label="Alternate address description"
				emptyValueFallback=""
			/>
			<TextInputFormField control={control} name="name" />
			<TextInputFormField
				control={control}
				name="address2"
				label="Street address"
				emptyValueFallback=""
			/>
			<TextInputFormField control={control} name="address1" label="Attn, suite, etc" />
			<GridContainer>
				<GridItem xs={12} sm={4}>
					<TextInputFormField control={control} name="city" emptyValueFallback="" />
				</GridItem>
				<GridItem xs={12} sm={4}>
					<StateSelectorFormField
						control={control}
						name="state"
						disableNoSelectionOption
					/>
				</GridItem>
				<GridItem xs={12} sm={4}>
					<TextInputFormField
						control={control}
						name="postalcode"
						label="Zip code"
						emptyValueFallback=""
					/>
				</GridItem>
			</GridContainer>
		</Modal>
	)
}
