import { useCallback, useContext } from "react"

import { ToastAction, ToastContext, ToastState } from "./toast-context"

export type MakeToast = (toastState: ToastState) => void
export type MakeSuccessToast = (message: string, action?: ToastAction) => void
export type MakeErrorToast = (message: string, action?: ToastAction) => void

export const useToast = (): {
	makeToast: MakeToast
	makeSuccessToast: MakeSuccessToast
	makeErrorToast: MakeErrorToast
} => {
	const toastContext = useContext(ToastContext)

	if (!toastContext) {
		throw new Error("useToast must be used within a ToastProvider")
	}

	const setState = toastContext[1]

	const makeToast = (toastState: ToastState) => {
		setState(toastState)
	}

	const makeSuccessToast: MakeSuccessToast = useCallback(
		(message, action) => {
			setState({
				message,
				iconName: "check-circle",
				iconColor: "success",
				iconFamily: "solid",
				action,
			})
		},
		[setState]
	)

	const makeErrorToast: MakeErrorToast = useCallback(
		(message, action) => {
			setState({
				message,
				iconName: "exclamation-triangle",
				iconColor: "error",
				iconFamily: "solid",
				action,
			})
		},
		[setState]
	)

	return {
		makeToast,
		makeSuccessToast,
		makeErrorToast,
	}
}
