import { FC, useMemo } from "react"
import { css, useTheme } from "@emotion/react"
import { useHistory } from "react-router-dom"
import { CustomerBrandCard } from "@ncs/ncs-api"
import {
	Box,
	Button,
	Card,
	CARD_X_PADDING_MD_UP,
	CARD_X_PADDING_SM_DOWN,
	Link,
} from "@ncs/web-legos"

export interface ShopBrandCtaProps {
	card: CustomerBrandCard
}

export const ShopBrandCta: FC<ShopBrandCtaProps> = ({ card }) => {
	const history = useHistory()
	const { breakpoints } = useTheme()

	const brandBannerCss = useMemo(() => {
		return css`
			display: block;
			background: #ddd;
			cursor: pointer;
			overflow: hidden;
			height: 10rem;

			${breakpoints.up("md")} {
				margin: 0 calc(-${CARD_X_PADDING_MD_UP}rem - 2px);
			}
			${breakpoints.down("sm")} {
				margin: 0 calc(-${CARD_X_PADDING_SM_DOWN}rem - 2px);
			}
			a {
				display: block;
				height: inherit;
				max-width: 55rem;
				margin: 0 auto;
				background-image: url(${card.bannerImageUrl});
				background-size: cover;
				background-position: center center;
				transition-duration: 200ms;
				transition-timing-function: ease-out;
				transition-property: transform, opacity;
				&:hover {
					opacity: 0.9;
					transform: scale(1.05);
				}
			}
		`
	}, [card.bannerImageUrl, breakpoints])

	const handleClick = () => {
		if (card.buttonDestinationOpensNewWindow) {
			window.open(card.buttonDestinationUrl)
		} else {
			history.push(card.buttonDestinationUrl)
		}
	}

	const isExternalLink = card.buttonDestinationUrl.startsWith("http")

	return (
		<Card variant="droplet">
			<Box textAlign="center">
				<Link
					to={card.buttonDestinationUrl}
					newTab={card.buttonDestinationOpensNewWindow}
					css={brandLogoCss}
					external={isExternalLink}
				>
					<img src={card.logoImageUrl} alt={`Logo for ${card.name}`} />
				</Link>
				<Link
					css={brandBannerCss}
					to={card.buttonDestinationUrl}
					newTab={card.buttonDestinationOpensNewWindow}
					external={isExternalLink}
				>
					<div />
				</Link>
				<span css={taglineTextCss}>{card.taglineText}</span>
				<Button
					onClick={handleClick}
					variant="primary-cta"
					icon="long-arrow-right"
					containerProps={{ maxWidth: 30 }}
					fillContainer
				>
					{card.buttonLabel}
				</Button>
			</Box>
		</Card>
	)
}

const brandLogoCss = css`
	margin-bottom: 1.5rem;
	img {
		height: 3rem;
	}
`
const taglineTextCss = css`
	display: block;
	font-size: 1.15rem;
	margin: 1.15rem 0;
`
