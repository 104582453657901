import React from "react"

import { css, Theme } from "@emotion/react"
import { Link } from "react-router-dom"

import { Icon } from "./Icon"

export interface BreadcrumbsProps {
	pages?: {
		name: string
		to?: string
	}[]
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = React.memo(({ pages = [] }) => {
	return (
		<div css={style}>
			{pages.length > 0 && (
				<>
					<Link to="/">
						<Icon icon="home" />
					</Link>
					{pages.map(({ name, to }, i) => (
						<React.Fragment key={name}>
							<span className="divider">/</span>
							{i === pages.length - 1 ?
								<span className="current">{name}</span>
							:	<Link to={to ?? "/"}>{name}</Link>}
						</React.Fragment>
					))}
				</>
			)}
		</div>
	)
})

const style = (theme: Theme) => css`
	font-size: 0.75rem;
	.divider {
		margin: 0 0.35rem;
	}
	.current {
		font-weight: bold;
	}
	a {
		color: ${theme.palette.error.dark};
		text-decoration: none;
		font-weight: normal;
		&:hover {
			color: ${theme.palette.error.main};
		}
	}
`
