import { FC, useState } from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { z } from "zod"
import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { CreateAccountPost, makeApiErrorMessage, useAuth } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	Divider,
	Heading,
	Paragraph,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"
import { SupportIssueModal } from "~/shared-components"
import { CurrentCustomerHelpModal } from "./components"
import { css, Theme } from "@emotion/react"

const NewAccountFormSchema = z.object({
	firstName: z.string({ required_error: "Please enter first name." }),
	lastName: z.string({ required_error: "Please enter last name." }),
	email: z.string({ required_error: "Please enter email address." }).email(),
	password: z
		.string({ required_error: "Please enter password." })
		.min(6, "Must be at least 6 characters long")
		.max(100, "Cannot be more than 100 characters"),
	customerNumber: z.string().max(30).nullable(),
})

type NewAccountForm = z.infer<typeof NewAccountFormSchema>

export const CreateAccount: FC = () => {
	const { createAccountAndLogin } = useAuth()
	const { makeErrorToast } = useToast()
	const history = useHistory()
	const [showHelpModal, setShowHelpModal] = useState(false)
	const [showSupportModal, setShowSupportModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<NewAccountForm>({
		resolver: zodResolver(NewAccountFormSchema),
		defaultValues: {
			customerNumber: null,
		},
	})

	const onSubmit = async (formData: NewAccountForm) => {
		const newAccountPost: CreateAccountPost = {
			firstName: formData.firstName,
			lastName: formData.lastName,
			email: formData.email,
			password: formData.password,
			// this fields will be deleted because they are not used anymore
			customerNumber: formData.customerNumber,
			previousPurchase: !!formData.customerNumber,
			invoiceNumber: null,
		}

		try {
			setIsLoading(true)
			await createAccountAndLogin(newAccountPost)
			history.push("/shop", { newUser: true })
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
			setIsLoading(false)
		}
		trackEvent(TrackingEvent.SIGNUP, {
			email: formData.email,
		})
	}

	return (
		<div css={mainContainer}>
			<Box b={1} borderRadius={0.75} maxWidth={35} css={boxContainer}>
				<AnimatedEntrance show>
					<Heading variant="h2" bold textAlign="center" customColor="#111827">
						Create Account
					</Heading>
					<Paragraph
						textAlign="center"
						display="flex"
						justifyContent="center"
						mt={0.25}
						customColor="#4B5563"
					>
						Enter your details to create an account.
					</Paragraph>
					<Box justifyContent="center" my={3}>
						<TextInputFormField
							control={control}
							name="firstName"
							label="First name"
							emptyValueFallback=""
							placeholder="Enter first name"
							errorStyle={errors.firstName?.message}
							mb={2.5}
						/>
						<TextInputFormField
							control={control}
							name="lastName"
							label="Last name"
							placeholder="Enter last name"
							errorStyle={errors.lastName?.message}
							emptyValueFallback=""
							mb={2.5}
						/>
						<TextInputFormField
							control={control}
							name="email"
							emptyValueFallback=""
							placeholder="Enter email address"
							errorStyle={errors.email?.message}
							mb={2.5}
						/>
						<TextInputFormField
							control={control}
							name="password"
							label="Enter password"
							type="password"
							emptyValueFallback=""
							errorStyle={errors.password?.message}
						/>
					</Box>
					<Box display="flex" flexDirection="column">
						<Heading variant="h3" bold mb={0.75} customColor="#111827">
							Link Your Account
						</Heading>
						<Paragraph customColor="#4B5563">
							To link your online account with your existing customer profile, please
							provide your NCS customer number.
						</Paragraph>
						<TextInputFormField
							mt={2}
							mb={3}
							control={control}
							name="customerNumber"
							label="Customer Number (Optional)"
							placeholder="Enter customer number"
						/>
					</Box>
					<Button
						variant="primary-cta"
						fillContainer
						onClick={handleSubmit(onSubmit)}
						isLoading={isLoading}
					>
						Create Account
					</Button>
					<Divider />
					<Box display="flex" flexDirection="column">
						<div css={linkContainer}>
							<Paragraph mr={1.5} customColor="#111827" fontSize={1.125}>
								Already have an account?
							</Paragraph>
							<Button onClick={() => history.push("/auth/login")}>
								<span css={buttonLink}>Sign in</span>
							</Button>
						</div>
						<div css={linkContainer}>
							<Paragraph mr={1.5} customColor="#111827" fontSize={1.125}>
								Or need help?
							</Paragraph>
							<Button onClick={() => setShowSupportModal(true)}>
								<span css={buttonLink}>Contact Support</span>
							</Button>
						</div>
					</Box>
				</AnimatedEntrance>
			</Box>

			<CurrentCustomerHelpModal
				isOpen={showHelpModal}
				onClose={() => setShowHelpModal(false)}
			/>
			<SupportIssueModal
				isOpen={showSupportModal}
				onClose={() => setShowSupportModal(false)}
			/>
		</div>
	)
}

const linkContainer = css`
	display: flex;
	margin-bottom: 1.5rem;
`

const buttonLink = css`
	color: #0b75e1;
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 700;
`
const boxContainer = (theme: Theme) => css`
	margin: 0 auto;
	background-color: #ffffff;
	padding: 3rem;
	${theme.breakpoints.only("sm")} {
		padding: 2rem;
	}
	${theme.breakpoints.only("xs")} {
		padding: 1.5rem;
	}
`
const mainContainer = (theme: Theme) => css`
	padding: 4rem 0 8rem 0;

	${theme.breakpoints.only("xs")} {
		padding: 3rem 1.5rem 8rem 1.5rem;
	}
`
