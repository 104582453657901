import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { CustomerMinimal, CustomerWithTerritory } from "../customers"
import { PaymentMethodBundle } from "../parts-order"

export enum InvoiceUrlPaths {
	CustomerInvoices = "customer-invoices",
	CustomerInvoicesSummary = "customer-invoices-summary",
	Documents = "documents",
	Invoice = "invoice",
	InvoicePrint = "invoice_print",
	SubmitPayment = "submit-payment",
}

export type InvoiceDispatch = {
	id: string
	dispatchNumber: string | null
}

export type InvoicePartOrder = {
	id: string
	orderId: string | null
}

export type InvoiceDeliveryOrder = {
	id: string
}

export type InvoicePartOrderLineItem = {
	id: string
	description?: string | null
	quantity: string
	unitPrice: string
	unitTax: string
}

export type WorkOrderLineItem = {
	id: string
	description: string | null
	quantity: string
	netPrice: string
	tax: string
}

export type KbmInvoiceLineItem = {
	id: string
	description: string | null
	quantity: string
	unitPrice: string
	tax: string
}

export type DeliveryOrderLineItem = {
	id: string
	description: string | null
	quantity: string
	netPrice: string
	unitTax: string
}

export interface CustomerInvoiceDocument {
	id: number
	name: string
	documentType: number
}

export interface CustomerInvoice {
	id: string
	amountDue: string
	amountPaid: string
	amountPending: string
	billToCustomer: CustomerMinimal | null
	comments: string | null
	customer: CustomerMinimal | null
	deliveryInvoiceLineItems: DeliveryOrderLineItem[]
	deliveryOrder: InvoiceDeliveryOrder | null
	dispatch: InvoiceDispatch | null
	dueDate: string | null
	invoiceDate: string | null
	invoiceLineItems: WorkOrderLineItem[]
	invoiceNumber: string
	kbmBatch?: string | null
	kbmInvoiceLines: KbmInvoiceLineItem[]
	kbmNumber?: string | null
	partOrder: InvoicePartOrder | null
	partOrderInvoice: InvoicePartOrderLineItem[]
	subtotal: string
	taxTotal: string
	total: string
	type: string
	workCompletedDate: string
}

export interface CustomerInvoicePost {
	amount: {
		value: string
		currency: "USD"
	}
	payment: PaymentMethodBundle
}

export interface PaymentIntent {
	paymentIntentId: number
}

export interface CustomerInvoiceSummary {
	id: string
	invoiceNumber: string
	total: string
	invoiceDate: string | null
	dueDate: string | null
	customer: CustomerMinimal
	amountPaid: string
	amountPending: string
	amountDue: string
	type: string
}

export interface CustomerInvoiceQueryParams extends SearchQueryParams, OrderingQueryParams {
	site: string | null
	type: string | null
	paymentDue: string | null
	partiallyPaid: string | null
	paidInFull: string | null
	startDate: string | null
	endDate: string | null
}

export interface InvoiceWorkorderSerializer {
	id: string
	workorderNumber: string
	waaSignatureObtained: boolean
	waaSignatureName: string | null
	waaNoSignatureReason: string | null
	document: {
		id: string
		name: string
		url: string | null
		description: string
		type: number
	}[]
	otherDocument: {
		id: string
		name: string
		url: string | null
		description: string
		type: number
	}[]
	images: {
		id: string
		name: string
		url: string | null
		description: string
		type: number
	}[]
}

export interface InvoiceDispatchSerializer {
	id: string
	dispatchNumber: string
	poTicket: string | null
	billNotToExceed: string | null
	priority: string | null
	callType: string | null
	symptoms: string | null
	createdDate: string
	workorders: InvoiceWorkorderSerializer[]
}

/**
 * Based on `InvoiceSourceSerializer`
 */
export interface InvoiceSource {
	id: string
	billToCustomer: null | {
		id: string
		name: string
		customerNumber: string
	}
	customer: CustomerWithTerritory
	deliveryOrderId: number | null
	dispatch: null | InvoiceDispatchSerializer
	document: null | {
		documentId: string | number
		documentType: 6
	}
	purchaseOrder: string | null
	invoiceDate: string | null
	invoiceNumber: number
	invoiceType: string | null
	partOrderId: string | null
	statusCode: string | null
	total: string
	workCompletedDate: string | null
}

export interface InvoiceSourceQueryParams extends SearchQueryParams, OrderingQueryParams {
	startDate: string | null
	endDate: string | null
	region: string | null
	territory: string | null
	enterprise: string | null
	organization: string | null
	customer: string | null
	invoiceType: string | null
	status: string | null
	billToCustomer: string | null
	hasDocuments: boolean | null
	includeZeroDollar: boolean | null
}

export type PrintInvoicesPost = {
	_id: string
	invoiceNumber: string
}[]
