import React, {
	memo,
	ReactElement,
	ReactNode,
	SVGProps,
	useCallback,
	useMemo,
	useState,
} from "react"

import { useTheme } from "@emotion/react"
import { darken, transparentize } from "polished"
import {
	Area,
	Bar,
	CartesianGrid,
	CartesianGridProps,
	ComposedChart,
	Legend,
	Line,
	LineProps,
	Tooltip,
	XAxis,
	XAxisProps,
	YAxis,
	YAxisProps,
} from "recharts"

import { PortalReport } from "@ncs/ncs-api"
import { formatNumber, pythonify, snakeToCamel } from "@ncs/ts-utils"

import { useScreenSizeMatch } from "../../util"
import { Box, GridContainer, GridItem } from "../layout"
import { ChartType } from "../selectors"
import { LoadingSpinner } from "../transitions"
import { ErrorText, Paragraph } from "../typography"
import { mergeOverlayData, ReportOverlayBundle } from "./charts-util"
import { ResponsiveChartContainer } from "./ResponsiveChartContainer"

export interface ChartProps {
	report?: PortalReport
	filters?: (ReactElement | null | undefined)[]
	isLoading?: boolean
	keysToExclude?: string[]
	showLegend?: boolean
	showTooltip?: boolean
	colorArray?: string[]
	defaultChartType?: ChartType
	xAxisTickFormatter?: XAxisProps["tickFormatter"]
	yAxisTickFormatter?: YAxisProps["tickFormatter"]
	xAxisDomain?: XAxisProps["domain"]
	yAxisDomain?: XAxisProps["domain"]
	hideXAxis?: boolean
	hideYAxis?: boolean
	gridProps?: Omit<SVGProps<SVGElement> & CartesianGridProps, "ref">
	/** The X key field is describe by the value of `report.xKey`.
	 *  That's not normally converted out of snake because, but when we access
	 *  that in the data array it will be converted, because it's a key
	 *  instead of a value. So, you can make them match here. */
	pythonifyXKey?: boolean
	overlayReportBundle?: ReportOverlayBundle
	errorMessageText?: string | null
	tooltipLabelFormatter?: (xValue: string) => string
	/** In order to prevent some jank with massive datasets coming in, you can
	 * pass a cap in and we'll just slice off and show the first X. */
	dataRecordCountCap?: number
	/** Arbitrary content to render above the chart and below the heading and filters. */
	contentAboveChart?: ReactNode

	/** Specific to Line chart */
	lineType?: LineProps["type"]
	dot?: boolean
}

export const GenericReportChart: React.FC<ChartProps> = memo(
	({
		report: reportWithoutOverlay,
		filters,
		isLoading,
		keysToExclude = [],
		showLegend = true,
		showTooltip = true,
		colorArray,
		defaultChartType,
		xAxisTickFormatter,
		yAxisTickFormatter,
		xAxisDomain,
		yAxisDomain,
		hideXAxis,
		hideYAxis,
		pythonifyXKey = true,
		overlayReportBundle,
		errorMessageText,
		tooltipLabelFormatter,
		dataRecordCountCap = 1000,
		contentAboveChart,
		gridProps,
		lineType = "monotone",
		dot = true,
	}) => {
		const theme = useTheme()
		const screenIsSmall = useScreenSizeMatch("sm")
		const [chartType] = useState(defaultChartType ?? ChartType.StackedBar)
		const [legendIsHovered, setLegendIsHovered] = useState(false)
		const [currentLegendDataKey, setCurrentLegendDataKey] = useState<string | null>(null)

		const report = useMemo(() => {
			if (!!reportWithoutOverlay && overlayReportBundle?.report?.data?.length) {
				return mergeOverlayData(reportWithoutOverlay, overlayReportBundle.report)
			}

			// This shouldn't happen, but just in case...
			if (!!reportWithoutOverlay && !reportWithoutOverlay?.data) {
				return undefined
			}

			return reportWithoutOverlay
		}, [reportWithoutOverlay, overlayReportBundle])

		const getColor = useCallback(
			(index: number, dataKey?: string, reverse?: boolean) => {
				// If we run out of colors passed in, start going through the theme.
				// Loop back to the beginning if you run out.
				const colors = (colorArray ?? []).concat(theme.palette.chartColors)
				// You can reverse if you want to, like for the overlay items.
				if (reverse) colors.reverse()
				const color = colors[index % colors.length]

				if (legendIsHovered) {
					if (dataKey === currentLegendDataKey) {
						return darken(0.1, color)
					} else {
						return transparentize(0.75, color)
					}
				}

				return color
			},
			[colorArray, currentLegendDataKey, legendIsHovered, theme.palette.chartColors]
		)

		return (
			<>
				<GridContainer rowGap={0} mb={1}>
					{(filters ?? [])
						.filter((filter): filter is NonNullable<typeof filter> => filter != null)
						.map((filter) => (
							<GridItem key={filter.key} xs={12} sm={4} md={2}>
								{filter}
							</GridItem>
						))}
					{/* Commenting out because so we haven't needed to let user choose chart type. */}
					{/* <GridItem xs={12} sm={4} md={2}>
						<ChartTypeSelector
							value={chartType}
							onChange={setChartType}
							fillContainer
						/>
					</GridItem> */}
				</GridContainer>

				{!!contentAboveChart && <Box mb={1}>{contentAboveChart}</Box>}

				{!!report?.data?.length && report.data.length > dataRecordCountCap && (
					<ErrorText my={1}>
						Chart data truncated to display first {dataRecordCountCap} records
					</ErrorText>
				)}
				{!!errorMessageText && <ErrorText my={1}>{errorMessageText}</ErrorText>}

				{!!report && report.data.length === 0 && !isLoading && (
					<Paragraph small color="secondary">
						No data to display.
					</Paragraph>
				)}

				{isLoading ?
					<LoadingSpinner height={18.5} />
				:	<>
						{!!report && report?.data?.length > 0 && (
							<ResponsiveChartContainer>
								<ComposedChart data={report.data.slice(0, dataRecordCountCap)}>
									{!hideXAxis && (
										<XAxis
											dataKey={
												pythonifyXKey && report?.xKey ?
													snakeToCamel(report.xKey)
												:	undefined
											}
											domain={xAxisDomain}
											interval="preserveStartEnd"
											tickFormatter={xAxisTickFormatter}
										/>
									)}

									{!hideYAxis && (
										<YAxis
											domain={yAxisDomain}
											tickFormatter={(value, index) => {
												return yAxisTickFormatter ?
														yAxisTickFormatter(value, index)
													:	formatNumber(value)
											}}
											orientation="left"
										/>
									)}

									{!!overlayReportBundle && !hideYAxis && (
										<YAxis
											yAxisId="overlay"
											orientation="right"
											tickFormatter={(value, index) =>
												overlayReportBundle.yAxisFormatter ?
													overlayReportBundle.yAxisFormatter(
														value,
														index
													)
												:	formatNumber(value)
											}
										/>
									)}

									<CartesianGrid
										stroke={theme.palette.grey[200]}
										horizontal
										vertical={false}
										{...gridProps}
									/>

									{showLegend && (
										<Legend
											align={screenIsSmall ? "center" : "right"}
											layout={screenIsSmall ? "horizontal" : "vertical"}
											wrapperStyle={{
												zIndex: 1,
												...(screenIsSmall ?
													{
														paddingTop: "1rem",
													}
												:	{
														paddingLeft: "2rem",
														paddingBottom: "1.5rem",
														maxWidth: "18rem",
														maxHeight: "100%",
														overflowY: "auto",
													}),
											}}
											// Recharts' type defs are... hit or miss.
											// eslint-disable-next-line
											formatter={(value: string, { payload }: any) => {
												if (payload.yAxisId === "overlay") {
													return overlayReportBundle?.report?.dataKeys[
														pythonify(value)
													]
												}
												return report?.dataKeys[pythonify(value)]
											}}
											// eslint-disable-next-line
											onMouseEnter={(data: any) => {
												setLegendIsHovered(true)
												setCurrentLegendDataKey(data.dataKey)
											}}
											onMouseLeave={() => {
												setLegendIsHovered(false)
												setCurrentLegendDataKey(null)
											}}
										/>
									)}
									{showTooltip && (
										<Tooltip
											labelFormatter={tooltipLabelFormatter}
											formatter={(value: number, name: string) => {
												return [
													value,
													report?.dataKeys[pythonify(name)] ??
														`${overlayReportBundle?.name}: ${overlayReportBundle
															?.report?.dataKeys[pythonify(name)]}`,
												]
											}}
										/>
									)}

									{chartType === ChartType.Line &&
										Object.keys(report.dataKeys)
											.filter((key) => !keysToExclude.includes(key))
											.map((key, i) => (
												<Line
													key={key}
													dataKey={key}
													strokeWidth={2}
													stroke={getColor(i, key)}
													type={lineType}
													dot={dot}
												/>
											))}

									{chartType === ChartType.Bar &&
										Object.keys(report.dataKeys)
											.filter((key) => !keysToExclude.includes(key))
											.map((key, i) => (
												<Bar
													key={key}
													dataKey={key}
													minPointSize={1}
													fill={getColor(i, key)}
												/>
											))}

									{chartType === ChartType.StackedBar &&
										Object.keys(report.dataKeys)
											.filter(
												(key) =>
													!keysToExclude.includes(key) && key !== "total"
											)
											.map((key, i) => (
												<Bar
													key={key}
													dataKey={key}
													stackId="A" // Can be anything.
													fill={getColor(i, key)}
													maxBarSize={75}
												/>
											))}

									{chartType === ChartType.Area &&
										Object.keys(report.dataKeys)
											.filter(
												(key) =>
													!keysToExclude.includes(key) && key !== "total"
											)
											.map((key, i) => (
												<Area
													key={key}
													dataKey={key}
													type="natural"
													stackId="A" // Can be anything.
													fill={getColor(i, key)}
													stroke={getColor(i, key)}
												/>
											))}

									{Object.keys(overlayReportBundle?.report?.dataKeys ?? {}).map(
										(key, i) => (
											<Line
												key={key}
												yAxisId="overlay"
												dataKey={key}
												strokeWidth={2}
												stroke={getColor(i, key, true)}
												type={lineType}
												dot={dot}
											/>
										)
									)}
								</ComposedChart>
							</ResponsiveChartContainer>
						)}

						{dataRecordCountCap &&
							report?.data &&
							report.data.length > dataRecordCountCap && (
								<Paragraph mt={2} small color="secondary">
									(Note: {formatNumber(report.data.length)} records received.
									Showing first {formatNumber(dataRecordCountCap)})
								</Paragraph>
							)}
					</>
				}
			</>
		)
	}
)
