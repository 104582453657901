import { FC, useEffect, useMemo, useState } from "react"

import { Column } from "react-table"

import {
	AdditionalUser,
	CustomerSite,
	makeApiErrorMessage,
	useAdditionalUserDetails,
	useCreateAdditionalUser,
	useUpdateAdditionalUser,
} from "@ncs/ncs-api"
import { noNullish } from "@ncs/ts-utils"
import {
	Box,
	Button,
	Callout,
	Checkbox,
	ConfirmationModal,
	ConfirmationModalConfig,
	Disabled,
	ExtendableModalProps,
	getAddressFields,
	GridContainer,
	GridItem,
	HeadingDivider,
	LoadingSpinner,
	Modal,
	Paragraph,
	PhoneInput,
	SearchQueryFilter,
	Table,
	TextInput,
	TooltipIcon,
	useChangeCallback,
	useToast,
} from "@ncs/web-legos"

import { SelectPermittedSitesModal } from "./SelectPermittedSitesModal"

export interface EditAdditionalUserModalProps extends ExtendableModalProps {
	user: AdditionalUser | null
}

export const EditAdditionalUserModal: FC<EditAdditionalUserModalProps> = ({ user, ...rest }) => {
	const { makeSuccessToast } = useToast()
	const [firstName, setFirstName] = useState<string | null>(user?.firstName || null)
	const [lastName, setLastName] = useState<string | null>(user?.lastName || null)
	const [email, setEmail] = useState<string | null>(user?.email || null)
	const [phone, setPhone] = useState<string | null>(user?.mobile || null)
	const [poRequired, setPoRequired] = useState(user?.poRequired ?? false)
	const [sites, setSites] = useState<CustomerSite[]>([])
	const [errorText, setErrorText] = useState<string | null>(null)
	const [showSitesModal, setShowSitesModal] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)
	const [filterState, setFilterState] = useState<{
		search: string | null
	}>({
		search: null,
	})

	const [details, detailsLoading] = useAdditionalUserDetails(user?.id.toString())
	const createUser = useCreateAdditionalUser()
	const updateUser = useUpdateAdditionalUser()

	const handleSave = async () => {
		try {
			if (!firstName || !lastName) {
				throw new Error("First and last name are required")
			}
			if (!email) {
				throw new Error("Valid email address is required")
			}
			if (!isEdit && !sites.length) {
				throw new Error(
					"New accounts must be set up with permission to at least one of your sites"
				)
			}

			setIsSaving(true)
			if (isEdit) {
				await updateUser({
					id: user.id.toString(),
					updates: {
						firstName,
						lastName,
						mobile: phone?.trim() || null,
						email,
						poRequired,
						sites: sites.map((s) => s.id),
					},
				})
				makeSuccessToast("Permissions updated")
				rest.onClose()
			} else {
				await createUser({
					firstName,
					lastName,
					mobile: phone,
					email,
					poRequired,
					sites: sites.map((s) => s.id),
				})
				makeSuccessToast("Permissions added")
				rest.onClose()
			}
		} catch (e) {
			setIsSaving(false)
			setErrorText(makeApiErrorMessage(e))
		}
	}

	const removeAllSites = async () => {
		if (isEdit) {
			try {
				await updateUser({
					id: user.id.toString(),
					updates: { sites: [] },
				})
				makeSuccessToast("Site permissions removed")
				rest.onClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
			}
		}
	}

	useChangeCallback(
		details,
		(newDetails) => {
			if (newDetails) {
				setSites(newDetails.sites)
			}
		},
		{ callOnSetup: true }
	)

	useEffect(() => {
		setErrorText(null)
	}, [firstName, lastName, email, sites])

	const preparedSites = useMemo(() => {
		const searchChunks = (filterState.search?.toUpperCase() || "").split(" ")

		return sites
			.filter((site) => {
				if (!searchChunks.length) return true

				const searchable = noNullish([
					site.customerNumber,
					site.name,
					site.address2,
					site.address1,
					site.city,
					site.state,
					site.postalCode,
				])
					.join("")
					.toUpperCase()

				return searchChunks.every((chunk) => searchable.includes(chunk))
			})
			.sort((a, b) => (a.name > b.name ? 1 : -1))
	}, [sites, filterState.search])

	const isEdit = !!user
	const isEmployee = isEdit && user.isCustomer === false
	const isSuperuser = isEdit && user.isCustomerSuperuser === true

	return (
		<Modal
			{...rest}
			errorText={errorText}
			title={`${isEdit ? "Edit" : "Add"} Additional User`}
			maxWidth="md"
			leftButtons={
				isEdit ?
					{
						buttonText: "Remove all permissions",
						disabled: detailsLoading,
						icon: "ban",
						variant: "text",
						onClick: () =>
							setConfirmationConfig({
								title: "Remove All Permissions",
								message: disableUserConfirmationMessage,
								onConfirm: async () => await removeAllSites(),
							}),
					}
				:	undefined
			}
			rightButtons={{
				buttonText: isEdit ? "Save Changes" : "Add User",
				onClick: handleSave,
				isLoading: isSaving,
			}}
		>
			{(isEmployee || isSuperuser) && (
				<Paragraph small secondary mb={1}>
					{isEmployee && "This account belongs to an NCS employee"}
					{isSuperuser && "This account is also a customer superuser"}, so you cannot
					edit their contact information. You can still edit which of your sites they
					have permissions to.
				</Paragraph>
			)}
			<Disabled disabled={isEmployee || isSuperuser}>
				<Box mb={2}>
					<GridContainer rowGap={0}>
						<GridItem xs={12} sm={6}>
							<TextInput
								value={firstName}
								onChange={setFirstName}
								label="First name"
							/>
						</GridItem>
						<GridItem xs={12} sm={6}>
							<TextInput value={lastName} onChange={setLastName} label="Last name" />
						</GridItem>
						<GridItem xs={12} sm={6}>
							<TextInput value={email} onChange={setEmail} label="Email" />
						</GridItem>
						<GridItem xs={12} sm={6}>
							<PhoneInput value={phone} onChange={setPhone} label="Phone #" />
						</GridItem>
					</GridContainer>

					<Checkbox
						value={poRequired}
						onChange={setPoRequired}
						label={
							<span>
								Require purchase order number when checking out
								<TooltipIcon>
									When this user is completing a purchase through this portal,
									should they be required to fill out the purchase order number
									field?
								</TooltipIcon>
							</span>
						}
					/>

					{!isEdit && (
						<Callout icon="lock" variant="info" my={1}>
							New users should go to <strong>portal.ncswash.com</strong> and click{" "}
							<strong>Forgot/change password</strong> to set their password for the
							first time.
						</Callout>
					)}
				</Box>
			</Disabled>

			<HeadingDivider headingVariant="h4" bold mt={1}>
				User Sites
			</HeadingDivider>
			<Button
				icon="plus"
				buttonText="Add/remove sites from user"
				onClick={() => setShowSitesModal(true)}
				containerProps={{ mt: 0.5, mb: 2 }}
				disabled={detailsLoading}
			/>
			{detailsLoading && <LoadingSpinner />}
			{sites.length > 0 && (
				<>
					<Paragraph>User has permissions for the following sites:</Paragraph>
					<Table
						data={preparedSites}
						columns={columns}
						noDataText={
							filterState.search ?
								`No sites match "${filterState.search}"`
							:	"No sites added to user"
						}
						defaultSort={defaultSort}
						queryParamState={filterState}
						setQueryParamState={setFilterState}
						toggledQueryFilters={[SearchQueryFilter]}
					/>
				</>
			)}

			{showSitesModal && (!!details || !isEdit) && (
				<SelectPermittedSitesModal
					startingSelections={sites.map((s) => s.id) ?? []}
					onChoose={setSites}
					onClose={() => setShowSitesModal(false)}
					isEdit={isEdit}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</Modal>
	)
}

const columns: Column<CustomerSite>[] = [
	{
		Header: "Site name",
		accessor: ({ name }) => name,
	},
	{
		Header: "Address",
		accessor: (original) =>
			getAddressFields(original, {
				exclude: ["name", "zip"],
			}).join(", "),
	},
	{
		Header: "NCS Customer #",
		minWidth: 500,
		accessor: ({ customerNumber }) => customerNumber,
	},
]

const defaultSort = [
	{
		id: "Site name",
	},
]

const disableUserConfirmationMessage =
	"Confirm: Remove all site permissions? This will disassociate this user will all of your sites. If you change your mind, you can come back to this screen and create their account again."
