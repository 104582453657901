import React from "react"

import { css, Theme } from "@emotion/react"
import { useHistory } from "react-router-dom"

import { useIsUser, UserId } from "@ncs/ncs-api"
import { Button, Card, Heading, Paragraph } from "@ncs/web-legos"

import { PartSearchFilters } from "./PartSearchFilters"

export const PartSearchSidebar: React.FC = () => {
	const isDb = useIsUser(UserId.DrivenBrands)
	const history = useHistory()

	return (
		<div css={containerStyle}>
			<PartSearchFilters />

			{isDb === false && (
				<Card variant="droplet" mt={4} ml={1}>
					<Heading bold>Quick Order</Heading>
					<Paragraph my={2}>
						Choose from a tailored list of products that work with the equipment you
						have at your sites.
					</Paragraph>
					<Button icon="bolt" onClick={() => history.push("/shop/quick-order")}>
						Start Order
					</Button>
				</Card>
			)}
		</div>
	)
}

const containerStyle = (theme: Theme) => css`
	position: sticky;
	left: -1rem;
	top: 1rem;
	max-width: 17rem;
	max-height: calc(100vh - 1rem);
	overflow-y: auto;
	border-right: 1px solid #eee;
	padding-right: 2rem;
	padding-bottom: 4rem;
	${theme.breakpoints.down("md")} {
		padding-right: 1rem;
	}
`
