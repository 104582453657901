import React from "react"

import { CellProps, Column } from "react-table"

import { EquipmentContract } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import { EmptyValueDash, Table } from "@ncs/web-legos"

export interface EquipmentContractsTabProps {
	contracts: EquipmentContract[]
}

const columns: Column<EquipmentContract>[] = [
	{
		Header: "Contract Type",
		accessor: "type",
	},
	{
		Header: "Effective Date",
		accessor: "effectiveDate",
		Cell: ({ row: { original } }: CellProps<EquipmentContract>) => (
			<span>
				{original.effectiveDate ? formatDate(original.effectiveDate) : <EmptyValueDash />}
			</span>
		),
	},
	{
		Header: "Expiration Date",
		accessor: "expirationDate",
		Cell: ({ row: { original } }: CellProps<EquipmentContract>) => (
			<span>
				{original.expirationDate ?
					formatDate(original.expirationDate)
				:	<EmptyValueDash />}
			</span>
		),
	},
	{
		Header: "Status",
		accessor: (contract) => (contract.isActive ? "Active" : "Not active"),
	},
]

export const EquipmentContractsTab: React.FC<EquipmentContractsTabProps> = ({ contracts }) => {
	return (
		<>
			<Table data={contracts} columns={columns} noDataText="No contracts found" />
		</>
	)
}
