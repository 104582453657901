import React, { memo } from "react"

import { faWeatherIconLookup } from "../../util"
import { Box } from "../layout"
import { Icon, Paragraph } from "../typography"

export interface WeatherProps {
	temperature?: number | null
	icon?: string | null // Pass in the Open Weather API icon.
}

export const Weather: React.FC<WeatherProps> = memo(({ temperature, icon }) => {
	return (
		<Box display="flex" alignItems="center">
			{!!temperature && (
				<Paragraph small mr={0.5}>
					{temperature}°
				</Paragraph>
			)}

			{!!icon && <Icon icon={faWeatherIconLookup(icon)} />}
		</Box>
	)
})
