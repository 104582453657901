import { FC, useMemo, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import {
	ConnectedDevice,
	ConnectedMachineWashCounts,
	useConnectedMachineWashCounts,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Checkbox,
	CssColumns,
	FormattedNumber,
	Heading,
	Label,
	LabeledData,
	LoadingSpinner,
	Paragraph,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

export interface MachineModalWashCountsTabProps {
	device: ConnectedDevice
}

export const MachineModalWashCountsTab: FC<MachineModalWashCountsTabProps> = ({ device }) => {
	const [showAllPackages, setShowAllPackages] = useState(false)
	const [hideEmpties, setHideEmpties] = useState(true)

	const [rawCounts, isLoading] = useConnectedMachineWashCounts(device.id, {
		queryConfig: c12yAutoRefreshConfig,
	})

	const allPackagesCounts = useMemo((): CountsByPackage[string] | null => {
		if (!rawCounts?.length) return null

		const result: Record<TimePeriodName, number | null> = {
			Today: null,
			"This week": null,
			"This month": null,
			"All time": null,
		}

		const washesByPeriod = getPeriodWashes(rawCounts)

		Object.entries(washesByPeriod).forEach(([name, washes]) => {
			const timePeriodName = name as TimePeriodName // Not sure why TS loses this type...

			const first = washes[washes.length - 1]
			const last = washes[0]

			if (timePeriodName === "All time") {
				// For all time all we need is the total for the most recent count.
				result["All time"] = last.total
			} else {
				result[timePeriodName] = (last?.total ?? 0) - (first?.total ?? 0)
			}
		})

		return result
	}, [rawCounts])

	const countsByPackage = useMemo((): CountsByPackage => {
		const result: CountsByPackage = {}

		if (!rawCounts || !rawCounts.length) return result

		const packageNames = Object.keys(rawCounts[0].counts)

		const washesByPeriod = getPeriodWashes(rawCounts)

		packageNames.forEach((key) => {
			if (showAllPackages || defaultWashPackageNames.includes(key)) {
				const allTimeCount = rawCounts[0].counts[key] ?? 0

				if (allTimeCount || !hideEmpties) {
					result[key] = {
						Today: null,
						"This week": null,
						"This month": null,
						"All time": allTimeCount,
					}

					if (washesByPeriod.Today.length) {
						const first = washesByPeriod.Today[washesByPeriod.Today.length - 1]
						const last = washesByPeriod.Today[0]
						result[key].Today = (last.counts[key] ?? 0) - (first.counts[key] ?? 0)
					}
					if (washesByPeriod["This week"].length) {
						const first =
							washesByPeriod["This week"][washesByPeriod["This week"].length - 1]
						const last = washesByPeriod["This week"][0]
						result[key]["This week"] =
							(last.counts[key] ?? 0) - (first.counts[key] ?? 0)
					}
					if (washesByPeriod["This month"].length) {
						const first =
							washesByPeriod["This month"][washesByPeriod["This month"].length - 1]
						const last = washesByPeriod["This month"][0]
						result[key]["This month"] =
							(last.counts[key] ?? 0) - (first.counts[key] ?? 0)
					}
				}
			}
		})

		return result
	}, [rawCounts, showAllPackages, hideEmpties])

	if (isLoading) {
		return <LoadingSpinner text="Loading wash counts..." />
	}

	if (!rawCounts?.length) {
		return <Paragraph secondary>No wash counts found</Paragraph>
	}

	return (
		<>
			<Heading variant="h5" bold mb={0.25}>
				All Packages Total
			</Heading>
			<Box d="flex" gap={4} smProps={{ justifyContent: "space-between", gap: 1 }} mb={3}>
				{allPackagesCounts &&
					Object.entries(allPackagesCounts)
						.reverse()
						.map(([timePeriodName, value]) => (
							<div key={timePeriodName}>
								<Label>{timePeriodName}</Label>
								<Heading variant="h1" bold={false}>
									<FormattedNumber value={value} />
								</Heading>
							</div>
						))}
			</Box>

			<CssColumns
				columnCounts={{ xs: 1, sm: 1, md: 2, lg: 3 }}
				cells={Object.entries(countsByPackage).map(([packageName, values]) => {
					return (
						<Box key={packageName} mb={1}>
							<Heading variant="h5" bold mb={0.25}>
								{packageName}
							</Heading>
							<Box
								d="flex"
								gap={2}
								xsProps={{
									justifyContent: "space-between",
								}}
							>
								{Object.entries(values)
									.reverse()
									.map(([timePeriod, value]) => {
										return (
											<LabeledData
												key={timePeriod}
												label={timePeriod}
												mb={0}
											>
												<FormattedNumber value={value} />
											</LabeledData>
										)
									})}
							</Box>
						</Box>
					)
				})}
			/>

			<Box my={2}>
				{showAllPackages && (
					<Checkbox
						value={hideEmpties}
						onChange={setHideEmpties}
						label="Hide packages with no recorded washes"
					/>
				)}

				{showAllPackages ?
					<Button
						icon="angle-up"
						onClick={() => setShowAllPackages(false)}
						containerProps={{ mt: 2 }}
					>
						Show fewer
					</Button>
				:	<Button icon="angle-down" onClick={() => setShowAllPackages(true)}>
						Show all packages
					</Button>
				}
			</Box>

			<Paragraph tiny secondary>
				Week starts on Monday
			</Paragraph>
		</>
	)
}

interface CountsByPackage {
	[packageKey: string]: Record<TimePeriodName, number | null>
}

type TimePeriodName = "Today" | "This week" | "This month" | "All time"

const getTimePeriods = () => {
	const result: Record<TimePeriodName, Dayjs | null> = {
		Today: null,
		"This week": null,
		"This month": null,
		"All time": null,
	}

	// Get Dayjs objects for beginning of today, the week, and the month.
	const now = dayjs()
	const beginningOfToday = now.startOf("day")
	result["Today"] = beginningOfToday

	const beginningOfWeek = now.startOf("week")
	result["This week"] = beginningOfWeek

	const beginningOfMonth = now.startOf("month")
	result["This month"] = beginningOfMonth

	return result
}

const getPeriodWashes = (
	counts: ConnectedMachineWashCounts[] | null | undefined
): Record<TimePeriodName, ConnectedMachineWashCounts[]> => {
	const periods = getTimePeriods()

	// We loop through all the counts and for every one, put it into ALL the time periods
	// it qualifies for.
	const result: Record<TimePeriodName, ConnectedMachineWashCounts[]> = {
		Today: [],
		"This week": [],
		"This month": [],
		"All time": counts ?? [], // The one for all time is just all the counts.
	}

	;(counts ?? []).forEach((count) => {
		const countDate = dayjs(count.eventDate)

		if (countDate.isSame(periods.Today, "day")) {
			result.Today.push(count)
		}
		if (countDate.isSame(periods["This week"], "week")) {
			result["This week"].push(count)
		}
		if (countDate.isSame(periods["This month"], "month")) {
			result["This month"].push(count)
		}
	})

	return result
}

const defaultWashPackageNames = [
	"WashPkg1",
	"WashPkg2",
	"WashPkg3",
	"WashPkg4",
	"WashPkg5",
	"WashPkg6",
	"WashPkg9",
	"WP7/ALA1",
	"WP8/ALA2",
	"WshPkg10",
	"WshPkg11",
	"WshPkg12",
	"WP14ALA2",
	"WP15ALA1",
	"WshPkg13",
]
