import React, {
	createContext,
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"

import * as Sentry from "@sentry/react"
import jwtDecode from "jwt-decode"

import {
	APPLICATION,
	CreateAccountPost,
	useCreateCustomerAccount,
	login as postLogin,
	LoginResponseSchema,
	punchOutLogin as getPunchOutLogin,
	refreshAccessToken,
	User,
	useUserProfile,
	UserSchema,
} from "../portal-apps"
import {
	clearAuthTokens,
	getStoredAccessToken,
	getStoredPunchOutSessionId,
	getStoredRefreshToken,
	setAuthFailureCallbacks,
	setAuthTokens,
} from "../util"

export interface AuthState {
	user: User | null
	login: (email: string, password: string) => Promise<void>
	punchOutLogin: (sessionId: string) => Promise<void>
	createAccountAndLogin: (newAccountData: CreateAccountPost) => Promise<void>
	logout: () => void
	initialized: boolean
	punchOutSessionId: string | null
	isPrivilegedDbUser: boolean
	setIsPrivilegedDbUser: Dispatch<SetStateAction<boolean>>
	isDev: boolean | undefined
}

const AuthContext = createContext<AuthState | undefined>(undefined)
AuthContext.displayName = "AuthContext"

export interface AuthProviderProps {
	isDev?: boolean
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ isDev, ...rest }) => {
	const [user, setUser] = useState<User | null>(null)
	const [initialized, setInitialized] = useState(false)
	const [authenticatedPunchOutSessionId, setAuthenticatedPunchOutSessionId] = useState<
		string | null
	>(null)
	const createAccount = useCreateCustomerAccount()
	const [latestUserProfile, latestUserProfileLoading] = useUserProfile(user?.id)
	const [isPrivilegedDbUser, setIsPrivilegedDbUser] = useState(false)

	// The user we've added into state from the auth tokens might not have the most up-to-date
	// data, so we'll drop in to state the freshest data from the user's profile.
	// Eventually when auth token expires or user logs out and in again this will sync back up.
	useEffect(() => {
		if (!latestUserProfileLoading) {
			const latestName = `${latestUserProfile?.firstName} ${latestUserProfile?.lastName}`

			setUser((prev) => {
				if (prev) {
					return {
						...prev,
						name: latestName,
					}
				} else {
					return prev
				}
			})
		}
	}, [
		latestUserProfileLoading,
		latestUserProfile?.firstName,
		latestUserProfile?.lastName,
		latestUserProfile?.applicationsGroup,
	])

	const authFromJwt = useCallback(
		async (
			accessToken: string,
			refreshToken: string,
			punchOutSessionId?: string | null
		): Promise<void> => {
			await setAuthTokens({
				accessToken,
				refreshToken,
				punchOutSessionId,
			})

			const data = jwtDecode<Record<string, unknown>>(accessToken)

			const decodedUserData = {
				id: String(data.user_id) || null,
				email: data.email,
				name: data.name,
				apps: data.apps as APPLICATION[],
			}

			const result = UserSchema.safeParse(decodedUserData)

			if (!result.success) {
				if (isDev) {
					console.warn(result.error.toString())
				}
			}

			// Set local state accordingly.
			setUser(decodedUserData as unknown as User)
			if (punchOutSessionId) setAuthenticatedPunchOutSessionId(punchOutSessionId)

			// set user for sentry reporting
			Sentry.configureScope((scope) => {
				scope.setUser({
					id: (decodedUserData.id || "could not parse id from JWT") as string,
					email: (decodedUserData.email || "could not parse email from JWT") as string,
					username: (decodedUserData.name ||
						"could not parse username from JWT") as string,
				})
			})

			setInitialized(true)
		},
		[isDev]
	)

	const login = useCallback(
		async (
			email: string,
			password: string,
			businessRole: "internal" | "customer" = "customer"
		): Promise<void> => {
			const response = await postLogin(email, password, businessRole)
			await authFromJwt(response.access, response.refresh)
		},
		[authFromJwt]
	)

	const punchOutLogin = useCallback(
		async (sessionId: string): Promise<void> => {
			const response = await getPunchOutLogin(sessionId)
			await authFromJwt(response.access, response.refresh, sessionId)
		},
		[authFromJwt]
	)

	const createAccountAndLogin = useCallback(
		async (newAccountData: CreateAccountPost): Promise<void> => {
			const response = await createAccount(newAccountData)
			const { access, refresh } = LoginResponseSchema.parse(response.data)
			await authFromJwt(access, refresh)
		},
		[createAccount, authFromJwt]
	)

	const logout = useCallback(async () => {
		setUser(null)
		setAuthenticatedPunchOutSessionId(null)
		await clearAuthTokens()
		Sentry.configureScope((scope) => {
			scope.setUser(null)
		})
	}, [])

	// handle initial login
	const getInitialUser = useCallback(async () => {
		const accessToken = await getStoredAccessToken()
		if (!accessToken) {
			void logout()
			setInitialized(true)
			return
		}

		const refreshToken = await getStoredRefreshToken()
		if (!refreshToken) {
			void logout()
			setInitialized(true)
			return
		}

		const punchOutSessionId = await getStoredPunchOutSessionId()

		// Force a refresh of the login session with the token we found.
		try {
			const refreshedLogin = await refreshAccessToken(refreshToken)

			await authFromJwt(refreshedLogin.access, refreshedLogin.refresh, punchOutSessionId)
		} catch (e) {
			void logout()
			setInitialized(true)
			return
		}
	}, [authFromJwt, logout])

	useEffect(() => {
		// Tell the API what to do if its attempts to refresh auth tokens fails.
		setAuthFailureCallbacks({
			onNoRefreshToken: logout,
			onFailedRefresh: logout,
		})
	}, [logout])

	useEffect(() => {
		// Log in on the first render
		void getInitialUser()
	}, [getInitialUser])

	const value = useMemo(
		() => ({
			user,
			login,
			punchOutLogin,
			createAccountAndLogin,
			logout,
			initialized,
			punchOutSessionId: authenticatedPunchOutSessionId,
			isPrivilegedDbUser,
			setIsPrivilegedDbUser,
			isDev,
		}),
		[
			initialized,
			login,
			logout,
			punchOutLogin,
			createAccountAndLogin,
			user,
			authenticatedPunchOutSessionId,
			isPrivilegedDbUser,
			isDev,
		]
	)

	return <AuthContext.Provider value={value} {...rest} />
}

export const useAuth = (): AuthState => {
	const context = useContext(AuthContext)

	if (context === undefined) {
		throw new Error("useAuth must be used within a AuthProvider")
	}

	return context
}
