import React, { memo } from "react"

import { CustomerDispatch, useCustomerDispatch } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import { EmptyValueDash, GridContainer, GridItem, LabeledData, Modal, Tabs } from "@ncs/web-legos"

import { ServiceRequestDetailCommentsTab } from "./ServiceRequestDetailCommentsTab"
import { ServiceRequestDetailDetailsTab } from "./ServiceRequestDetailDetailsTab"
import { ServiceRequestDetailWorkOrdersTab } from "./ServiceRequestDetailWorkOrdersTab"

export interface ServiceRequestDetailModalProps {
	isOpen: boolean
	onClose: () => void
	dispatchId: string | null
	initialDispatchData?: CustomerDispatch | null
}

export const ServiceRequestDetailModal: React.FC<ServiceRequestDetailModalProps> = memo(
	({ isOpen, onClose, initialDispatchData, dispatchId }) => {
		const [dispatch] = useCustomerDispatch(dispatchId, {
			queryConfig: {
				initialData: initialDispatchData ?? undefined,
			},
		})

		return (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				title={`Service Request #${dispatch?.dispatchNumber ?? ""}`}
				titleDetail={dispatch?.customer?.name}
				maxWidth="lg"
			>
				<GridContainer rowGap={0}>
					<GridItem xs={12} sm={4}>
						<LabeledData label="Status">
							{dispatch?.status ?? <EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem xs={12} sm={4}>
						<LabeledData label="Call received">
							{dispatch?.callReceivedDate ?
								formatDateTime(dispatch?.callReceivedDate)
							:	<EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem xs={12} sm={4}>
						<LabeledData label="Closed date">
							{dispatch?.closedDate ?
								formatDateTime(dispatch?.closedDate)
							:	<EmptyValueDash />}
						</LabeledData>
					</GridItem>
				</GridContainer>

				<Tabs
					minHeight="400px"
					panels={[
						{
							navLabel: "Details",
							component: <ServiceRequestDetailDetailsTab dispatch={dispatch} />,
						},
						{
							navLabel: "Work Orders",
							component: <ServiceRequestDetailWorkOrdersTab dispatch={dispatch} />,
						},
						{
							navLabel: "Comments",
							component: <ServiceRequestDetailCommentsTab dispatch={dispatch} />,
						},
					]}
				/>
			</Modal>
		)
	}
)
