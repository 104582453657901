import React, { memo, useMemo, useState } from "react"

import dayjs from "dayjs"

import {
	PortalReportName,
	ReportParamKeys,
	ReportTimeSpan,
	ServiceCentsPerWashReportParams,
	useCustomerWashCountHistoryReport,
	useServiceCentsPerWashReport,
	useWaterQualityReport,
	useWeatherReport,
} from "@ncs/ncs-api"
import { DateFormat, formatCurrency, getTimeAgoStartDate, TimeAgo } from "@ncs/ts-utils"
import {
	ChartCard,
	ChartType,
	GenericReportChart,
	makeChartOverlayBundle,
	ReportFrequencySelector,
	Select,
	SiteSelector,
	TimeAgoSelector,
	validateChartConfiguration,
} from "@ncs/web-legos"

export interface ServiceCentsPerWashChartProps {
	siteId?: string | null
}

const disabledFrequencies = [ReportTimeSpan.Daily, ReportTimeSpan.Hourly]

export const ServiceCentsPerWashChart: React.FC<ServiceCentsPerWashChartProps> = memo(
	({ siteId: siteIdProp }) => {
		const [siteId, setSiteId] = useState<string | null>(siteIdProp ?? null)
		const [frequency, setFrequency] = useState(ReportTimeSpan.Monthly)
		const [timeAgo, setTimeAgo] = useState<TimeAgo | null>(TimeAgo.MonthsAgo6)
		const [overlayReportName, setOverlayReportName] = useState<PortalReportName | null>(null)

		const params = useMemo(() => {
			const result: ServiceCentsPerWashReportParams = {
				[ReportParamKeys.Frequency]: frequency,
				[ReportParamKeys.StartDate]:
					timeAgo ?
						getTimeAgoStartDate(timeAgo).format(DateFormat.DateQueryParam)
					:	null,
				[ReportParamKeys.Site]: siteId,
			}

			return result
		}, [timeAgo, frequency, siteId])

		const [centsPerWash, centsPerWashLoading] = useServiceCentsPerWashReport(params)
		const [waterQualityReport, waterQualityReportLoading] = useWaterQualityReport(params)
		const [washCountsReport, washCountsReportLoading] =
			useCustomerWashCountHistoryReport(params)
		const [weatherReport, weatherReportLoading] = useWeatherReport(params)
		const loading =
			centsPerWashLoading ||
			waterQualityReportLoading ||
			washCountsReportLoading ||
			weatherReportLoading

		const overlayReportBundle = useMemo(() => {
			return makeChartOverlayBundle(overlayReportName, {
				[PortalReportName.WashCounts]: washCountsReport,
				[PortalReportName.WaterQuality]: waterQualityReport,
				[PortalReportName.Weather]: weatherReport,
			})
		}, [overlayReportName, washCountsReport, waterQualityReport, weatherReport])

		const errorMessage = useMemo(() => {
			return validateChartConfiguration({
				report: centsPerWash,
				overlayReportBundle,
				loading,
				params,
			})
		}, [centsPerWash, overlayReportBundle, loading, params])

		const title = useMemo(() => {
			return `Cost Per Wash: ${getTimeAgoStartDate(timeAgo)?.format(
				DateFormat.Date
			)} - ${dayjs().format(DateFormat.Date)}`
		}, [timeAgo])

		return (
			<ChartCard heading={title}>
				<GenericReportChart
					report={centsPerWash}
					overlayReportBundle={overlayReportBundle}
					isLoading={loading}
					defaultChartType={ChartType.Bar}
					yAxisTickFormatter={(value) => formatCurrency(value)}
					errorMessageText={errorMessage}
					filters={[
						siteIdProp ? undefined : (
							<SiteSelector
								key="SiteSelector"
								value={siteId}
								onChange={setSiteId}
								disableNoSelectionOption={false}
								noSelectionOptionText="All sites"
							/>
						),
						<TimeAgoSelector
							key="TimeAgoSelector"
							fillContainer
							value={timeAgo}
							onChange={setTimeAgo}
							showNoSelectionOption={false}
						/>,
						<ReportFrequencySelector
							key="ReportFrequencySelector"
							value={frequency}
							onChange={setFrequency}
							fillContainer
							disabledAccessor={(option) =>
								disabledFrequencies.includes(option.value)
							}
						/>,
						<Select
							key="overlayReportSelector"
							label="Data overlay"
							fillContainer
							disableNoSelectionOption={false}
							value={overlayReportName}
							onChange={(newSiteName) =>
								setOverlayReportName(newSiteName as PortalReportName | null)
							}
							options={[
								{
									value: PortalReportName.WashCounts,
									text: PortalReportName.WashCounts,
								},
								{
									value: PortalReportName.WaterQuality,
									text: PortalReportName.WaterQuality,
								},
								{
									value: PortalReportName.Weather,
									text: PortalReportName.Weather,
								},
							]}
						/>,
					]}
				/>
			</ChartCard>
		)
	}
)
