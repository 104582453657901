import { FC } from "react"

import { css } from "@emotion/react"

export const NoWrap: FC = ({ children }) => {
	return <span css={style}>{children}</span>
}

const style = css`
	white-space: nowrap;
	flex-shrink: 0;
`
