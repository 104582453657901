import { FC, HTMLProps, useMemo } from "react"

import { css, useTheme } from "@emotion/react"

export interface SimpleButtonProps {
	underline?: boolean
	bold?: boolean
	onClick?: HTMLProps<HTMLButtonElement>["onClick"]
	disabled?: HTMLProps<HTMLButtonElement>["disabled"]
	title?: HTMLProps<HTMLButtonElement>["title"]
}

/**
 * A button that's designed to look like a link and be inserted into text tags that
 * would complain if they have a div in them.
 */
export const SimpleButton: FC<SimpleButtonProps> = ({
	underline = true,
	bold = true,
	children,
	...rest
}) => {
	const theme = useTheme()

	const buttonCss = useMemo(() => {
		return css`
			color: ${theme.palette.primary.main};
			font-weight: ${bold ? "bold" : "inherit"};
			text-decoration: ${underline ? "underline" : "inherit"};
			font-family: inherit;
			font-size: inherit;
			line-height: inherit;
			padding: 0;
			margin: 0;
			border: 0;
			background: none;
			cursor: pointer;
		`
	}, [theme.palette.primary.main, bold, underline])

	return (
		<button css={buttonCss} {...rest}>
			{children}
		</button>
	)
}
