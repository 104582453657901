import { FC, useMemo, useState } from "react"

import { css } from "@emotion/react"

import {
	Box,
	Button,
	ButtonProps,
	Divider,
	GridContainer,
	GridItem,
	Heading,
	HeadingProps,
} from "@ncs/web-legos"

import { PartPreviewCard } from "./PartPreviewCard"

export interface PartPreviewRowProps {
	heading?: string
	partIds: (string | null | undefined)[]
	button?: ButtonProps
	headingVariant?: HeadingProps["variant"]
	count?: number
}

export const PartPreviewRow: FC<PartPreviewRowProps> = ({
	heading,
	partIds,
	button,
	headingVariant = "h2",
	count = 4,
}) => {
	const [erroredIds, setErroredIds] = useState<string[]>([])

	const partIdsToShow = useMemo(() => {
		const idsArray = partIds
			// Remove any nullish values.
			.flatMap((id) => (id ? [id] : []))
			// Remove any IDs that returned an error.
			.filter((id) => !erroredIds.includes(id))

		// Use a Set to weed out duplicates.
		return [...new Set(idsArray)].slice(0, count)
	}, [erroredIds, partIds, count])

	if (partIdsToShow.length === 0) {
		return null
	}

	return (
		<>
			{heading && (
				<Heading variant={headingVariant} bold mt={1} mb={1.5}>
					{heading}
				</Heading>
			)}

			<GridContainer>
				{partIdsToShow.map((id) => (
					<GridItem key={id} xs={12} sm={6} md={3}>
						<PartPreviewCard
							partId={id}
							css={previewCardStyle}
							onError={(badId) => setErroredIds((prev) => [...prev, badId])}
						/>
					</GridItem>
				))}
			</GridContainer>

			{button && (
				<Box mt={2}>
					<Button {...button} />
				</Box>
			)}

			<Divider mt={3} mb={5} />
		</>
	)
}

const previewCardStyle = css`
	// Have this fill to the max height of the container.
	height: 100%;
`
