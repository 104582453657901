import { FC } from "react"

import { ConnectivityDeviceTypeId } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ConnectivityDeviceTypeSelectorProps
	extends ExtendableSelectProps<(typeof options)[number]> {
	/**
	 * Exclude some device types from the selector.
	 */
	exclude?: ConnectivityDeviceTypeId[]
}

export const ConnectivityDeviceTypeSelector: FC<ConnectivityDeviceTypeSelectorProps> = ({
	exclude,
	...rest
}) => {
	return (
		<Select
			label="Device type"
			{...rest}
			options={options.filter((o) => (exclude ?? []).includes(o.value) === false)}
			textAccessor="description"
		/>
	)
}

const options: {
	value: ConnectivityDeviceTypeId
	description: string
}[] = [
	{
		value: ConnectivityDeviceTypeId.Machine,
		description: "Monitoring Device",
	},
	{
		value: ConnectivityDeviceTypeId.WashCount,
		description: "Wash Counter Device",
	},
	{
		value: ConnectivityDeviceTypeId.Vacuum,
		description: "Vacuum Device",
	},
]
