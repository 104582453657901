import { FC, useMemo, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
	makeApiErrorMessage,
	useAuth,
	useCreateCustomerIssue,
	useCustomerIssueReasons,
} from "@ncs/ncs-api"
import {
	ExtendableModalProps,
	Modal,
	Paragraph,
	SelectFormField,
	TextareaFormField,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

export const SupportIssueFormSchema = z.object({
	contactEmail: z.string().min(1, "Required").email("Hmm, that doesn't look like a valid email"),
	reason: z.string().min(1, "Required"),
	description: z.string().min(1, "Required"),
})

export type SupportIssueForm = z.infer<typeof SupportIssueFormSchema>

export const SupportIssueModal: FC<ExtendableModalProps> = ({ ...rest }) => {
	const { user } = useAuth()
	const { makeSuccessToast } = useToast()
	const [isSaving, setIsSaving] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)

	const createIssue = useCreateCustomerIssue()
	const [reasons, reasonsLoading] = useCustomerIssueReasons()

	const filteredReasons = useMemo(() => {
		return (reasons ?? []).filter((r) => !r.requiresAuth || !!user?.id)
	}, [reasons, user?.id])

	const {
		control,
		reset: formReset,
		handleSubmit,
		formState: { isValid, submitCount },
	} = useForm<SupportIssueForm>({
		resolver: zodResolver(SupportIssueFormSchema),
		defaultValues: {
			contactEmail: user?.email ?? "",
			reason: "",
			description: "",
		},
	})

	const submit = async (formData: SupportIssueForm) => {
		try {
			setIsSaving(true)
			await createIssue({
				user: user?.id ?? null,
				contactEmail: formData.contactEmail,
				description: formData.description,
				reason: formData.reason,
			})
			makeSuccessToast("Thank you! Your message has been sent.")
			rest.onClose()
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
			setIsSaving(false)
		}
	}

	const reset = () => {
		setIsSaving(false)
		setErrorText(null)
		formReset({
			contactEmail: user?.email ?? "",
			reason: "",
			description: "",
		})
	}

	return (
		<Modal
			title="Contact Support"
			onOpen={reset}
			errorText={errorText}
			rightButtons={{
				buttonText: "Submit",
				icon: "paper-plane",
				isLoading: isSaving,
				onClick: handleSubmit(submit),
				disabled: submitCount > 0 && !isValid,
			}}
			{...rest}
		>
			<Paragraph mb={1}>
				If you're experiencing any issues with our site, please let us know!
			</Paragraph>

			<Paragraph>Issue type:</Paragraph>
			<SelectFormField
				control={control}
				name="reason"
				options={filteredReasons}
				disabled={reasonsLoading}
				valueAccessor="id"
				textAccessor="description"
				mb={2}
			/>

			<Paragraph>Anything else we should know?</Paragraph>
			<TextareaFormField
				control={control}
				name="description"
				mb={2}
				placeholder="Additional notes..."
				maxLength={3000}
				label=""
				emptyValueFallback=""
			/>

			<Paragraph>What's a good email to reach you at?</Paragraph>
			<TextInputFormField
				control={control}
				name="contactEmail"
				placeholder="Email..."
				maxLength={255}
				label=""
				emptyValueFallback=""
			/>
		</Modal>
	)
}
