import React, { memo } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Box, BoxProps } from "../layout"
import { Paragraph } from "../typography"

export interface LoadingSpinnerProps extends BoxProps {
	fontSize?: string
	color?: string
	text?: string
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = memo(
	({ fontSize = "2rem", color = "#bbb", text, ...rest }) => {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				fontSize={fontSize}
				py={2}
				{...rest}
			>
				{text && (
					<Paragraph color="secondary" small mr={1}>
						{text}
					</Paragraph>
				)}
				<FontAwesomeIcon icon={["far", "spinner-third"]} color={color} spin />
			</Box>
		)
	}
)
