import { HTMLProps, KeyboardEvent, memo, MouseEvent, useCallback, useMemo } from "react"

import { css, Interpolation, Theme } from "@emotion/react"

import { withCssUnit } from "@ncs/ts-utils"

import { useScreenSizeMatch } from "../../util"

export interface BoxProps {
	id?: string
	/**
	 * @default "relative"
	 */
	position?: "relative" | "absolute" | "fixed" | "sticky"
	/**
	 * @default "block"
	 */
	display?: "block" | "inline-block" | "flex" | "inline-flex" | "inline" | "none"
	/**
	 * Shorthand for display
	 * @default "block"
	 */
	d?: BoxProps["display"]
	gap?: number | string
	/** Beware, `column-gap` can cause grid blowouts on smaller screens. */
	columnGap?: number | string
	rowGap?: number | string
	textAlign?: "left" | "center" | "right"
	alignSelf?: "stretch" | "center" | "start" | "end" | "flex-start" | "flex-end" | "stretch"
	order?: number
	flex?: number
	flexDirection?: "row" | "column" | "row-reverse" | "column-reverse"
	flexGrow?: number
	flexShrink?: number
	flexWrap?: "nowrap" | "wrap" | "wrap-reverse"
	alignItems?: "normal" | "stretch" | "center" | "flex-start" | "flex-end" | "baseline"
	justifyContent?:
		| "center"
		| "start"
		| "end"
		| "flex-start"
		| "flex-end"
		| "space-between"
		| "space-around"
		| "space-evenly"
		| "stretch"
	height?: number | string
	maxHeight?: number | string
	minHeight?: number | string
	width?: number | string
	maxWidth?: number | string
	minWidth?: number | string
	m?: number | string // Margin
	mx?: number | string // Margin left and right
	my?: number | string // Margin top and bottom
	mt?: number | string // Margin top
	mr?: number | string // Margin right
	mb?: number | string // etc...
	ml?: number | string
	p?: number | string
	px?: number | string
	py?: number | string
	pt?: number | string
	pr?: number | string
	pb?: number | string
	pl?: number | string
	top?: number | string
	right?: number | string
	bottom?: number | string
	left?: number | string
	b?: number // Border width, in pixels.
	bt?: number
	br?: number
	bb?: number
	bl?: number
	fontSize?: number | string
	className?: string
	onClick?: (e: MouseEvent | KeyboardEvent) => void
	mdProps?: Exclude<BoxProps, "mdProps" | "smProps" | "xsProps">
	smProps?: Exclude<BoxProps, "mdProps" | "smProps" | "xsProps">
	xsProps?: Exclude<BoxProps, "mdProps" | "smProps" | "xsProps">
	onMouseEnter?: HTMLProps<HTMLDivElement>["onMouseEnter"]
	onMouseLeave?: HTMLProps<HTMLDivElement>["onMouseLeave"]
	opacity?: number
	borderRadius?: number
	overflow?: string
}
/**
 * MUI's box is awesome, but it quickly slows down. https://github.com/mui-org/material-ui/issues/21657.
 * Making a new one here that just covers the most frequently used positioning styles.
 */
export const Box: React.FCC<BoxProps> = memo((props) => {
	const isMd = useScreenSizeMatch("md")
	const isSm = useScreenSizeMatch("sm")
	const isXs = useScreenSizeMatch("xs")

	const {
		id,
		className,
		onClick,
		mdProps,
		smProps,
		xsProps,
		onMouseEnter,
		onMouseLeave,
		children,
	} = props

	const boxCssProps = useCallback((styleProps: BoxProps) => {
		return css`
			position: ${styleProps.position ?? "relative"};
			display: ${styleProps.d ?? styleProps.display ?? "block"};
			gap: ${withCssUnit(styleProps.gap)};
			column-gap: ${withCssUnit(styleProps.columnGap)};
			row-gap: ${withCssUnit(styleProps.rowGap)};
			text-align: ${styleProps.textAlign};
			align-self: ${styleProps.alignSelf};
			flex-direction: ${styleProps.flexDirection};
			flex-grow: ${styleProps.flexGrow};
			flex-shrink: ${styleProps.flexShrink};
			flex-wrap: ${styleProps.flexWrap};
			align-items: ${styleProps.alignItems};
			order: ${styleProps.order};
			flex: ${styleProps.flex};
			justify-content: ${styleProps.justifyContent};
			height: ${withCssUnit(styleProps.height)};
			max-height: ${withCssUnit(styleProps.maxHeight)};
			min-height: ${withCssUnit(styleProps.minHeight)};
			width: ${withCssUnit(styleProps.width)};
			max-width: ${withCssUnit(styleProps.maxWidth)};
			min-width: ${withCssUnit(styleProps.minWidth)};
			margin: ${withCssUnit(styleProps.m)};
			margin-top: ${withCssUnit(styleProps.my)};
			margin-bottom: ${withCssUnit(styleProps.my)};
			margin-left: ${withCssUnit(styleProps.mx)};
			margin-right: ${withCssUnit(styleProps.mx)};
			margin-top: ${withCssUnit(styleProps.mt)};
			margin-right: ${withCssUnit(styleProps.mr)};
			margin-bottom: ${withCssUnit(styleProps.mb)};
			margin-left: ${withCssUnit(styleProps.ml)};
			padding: ${withCssUnit(styleProps.p)};
			padding-top: ${withCssUnit(styleProps.py)};
			padding-bottom: ${withCssUnit(styleProps.py)};
			padding-left: ${withCssUnit(styleProps.px)};
			padding-right: ${withCssUnit(styleProps.px)};
			padding-top: ${withCssUnit(styleProps.pt)};
			padding-right: ${withCssUnit(styleProps.pr)};
			padding-bottom: ${withCssUnit(styleProps.pb)};
			padding-left: ${withCssUnit(styleProps.pl)};
			top: ${withCssUnit(styleProps.top)};
			right: ${withCssUnit(styleProps.right)};
			bottom: ${withCssUnit(styleProps.bottom)};
			left: ${withCssUnit(styleProps.left)};
			border: ${styleProps.b != null ? `${styleProps.b}px solid ${borderColor}` : undefined};
			border-top: ${styleProps.bt != null ?
				`${styleProps.bt}px solid ${borderColor}`
			:	undefined};
			border-right: ${styleProps.br != null ?
				`${styleProps.br}px solid ${borderColor}`
			:	undefined};
			border-bottom: ${styleProps.bb != null ?
				`${styleProps.bb}px solid ${borderColor}`
			:	undefined};
			border-left: ${styleProps.bl != null ?
				`${styleProps.bl}px solid ${borderColor}`
			:	undefined};
			border-radius: ${withCssUnit(styleProps.borderRadius)};
			font-size: ${withCssUnit(styleProps.fontSize)};
			cursor: ${styleProps.onClick ? "pointer" : undefined};
			opacity: ${styleProps.opacity};
			overflow: ${styleProps.overflow};
			border-radius: ${withCssUnit(styleProps.borderRadius)};
		`
	}, [])

	const baseBoxStyles = useMemo(() => boxCssProps(props), [props, boxCssProps])

	const breakpointBoxStyles = useMemo(() => {
		const styles: Interpolation<Theme> = []

		if (isMd && mdProps) {
			styles.push(boxCssProps({ ...props, ...mdProps }))
		}
		if (isSm && smProps) {
			styles.push(boxCssProps({ ...props, ...smProps }))
		}
		if (isXs && xsProps) {
			styles.push(boxCssProps({ ...props, ...xsProps }))
		}

		return styles
	}, [props, isMd, isSm, isXs, mdProps, smProps, xsProps, boxCssProps])

	// If you passed a click handler, we need to also make it keyboard accessible.
	const handleKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
		if (onClick && (e.code === "Enter" || e.code === "Space")) {
			onClick(e)
		}
	}

	return (
		<div
			id={id}
			css={[baseBoxStyles, ...breakpointBoxStyles]}
			className={className}
			onClick={onClick}
			onKeyUp={onClick ? handleKeyUp : undefined}
			tabIndex={onClick ? 0 : undefined}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			role={onClick ? "button" : undefined}
		>
			{children}
		</div>
	)
})

const borderColor = "#eee"
