import { FC, ReactElement, useState } from "react"

import { Cell, Column } from "react-table"

import {
	ConnectedDevice,
	GenericDeviceStatus,
	TwoDigitMachineCode,
	TwoDigitMachineCodesQueryParams,
	useTwoDigitMachineCodes,
} from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import {
	Box,
	EmptyValueDash,
	KeyboardStartDateQueryFilter,
	KeyboardStartDateQueryFilterProps,
	MachineTwoDigitCodeStatusQueryFilter,
	NoWrap,
	Paragraph,
	StatusBlip,
	Table,
} from "@ncs/web-legos"

import { c12yAutoRefreshConfig } from "~/util"

export interface MachineModalStatesTabProps {
	device: ConnectedDevice
}

export const MachineModalStatesTab: FC<MachineModalStatesTabProps> = ({ device }) => {
	const [params, setParams] = useState(defaultTwoDigitMachineCodesQueryParams)
	const query = useTwoDigitMachineCodes(device.id, {
		params,
		queryConfig: {
			...c12yAutoRefreshConfig,
		},
	})

	return (
		<Table
			query={query}
			columns={twoDigitCodeTableColumns}
			queryParamState={params}
			setQueryParamState={setParams}
			toggledQueryFilters={[
				MachineTwoDigitCodeStatusQueryFilter,
				StartOnAfterQueryFilter,
				StartOnBeforeQueryFilter,
			]}
			filterResetValues={defaultTwoDigitMachineCodesQueryParams}
		/>
	)
}

const StartOnAfterQueryFilter = (
	props: KeyboardStartDateQueryFilterProps<TwoDigitMachineCodesQueryParams>
): ReactElement => {
	return (
		<KeyboardStartDateQueryFilter {...props} label="Start date after" param="startOnAfter" />
	)
}

const StartOnBeforeQueryFilter = (
	props: KeyboardStartDateQueryFilterProps<TwoDigitMachineCodesQueryParams>
): ReactElement => {
	return (
		<KeyboardStartDateQueryFilter {...props} label="Start date before" param="startOnBefore" />
	)
}
const twoDigitCodeTableColumns: Column<TwoDigitMachineCode>[] = [
	{
		Header: "Code",
		id: "twoDigitCode_MachineCode",
		Cell: ({
			row: {
				original: { twoDigitCode },
			},
		}: Cell<TwoDigitMachineCode>) => {
			return (
				<Box display="flex" alignItems="center" columnGap={0.5}>
					<StatusBlip
						status={
							twoDigitCode?.machineCode === "--" ?
								GenericDeviceStatus.Normal
							:	twoDigitCode?.suggestedPriority
						}
					/>
					<Paragraph small light mt={0.2}>
						{twoDigitCode?.machineCode || "-"}
					</Paragraph>
				</Box>
			)
		},
	},
	{
		Header: "Start Time",
		id: "eventStartOn",
		accessor: (original) =>
			original.eventStartOn ?
				<NoWrap>{formatDateTime(original.eventStartOn)}</NoWrap>
			:	<EmptyValueDash />,
	},
	{
		Header: "End Time",
		id: "eventEndOn",
		accessor: (original) =>
			original.eventEndOn ?
				<NoWrap>{formatDateTime(original.eventEndOn)}</NoWrap>
			:	<EmptyValueDash />,
	},
	{
		Header: "Description",
		accessor: ({ twoDigitCode }) => twoDigitCode?.description,
		Cell: ({ row: { original } }: Cell<TwoDigitMachineCode>) => {
			return original.twoDigitCode?.description || <EmptyValueDash />
		},
		disableSortBy: true,
	},
	{
		Header: "Definition",
		accessor: ({ twoDigitCode }) => twoDigitCode?.definition,
		Cell: ({ row: { original } }: Cell<TwoDigitMachineCode>) => {
			return original.twoDigitCode?.definition || <EmptyValueDash />
		},
		disableSortBy: true,
	},
	{
		Header: "Potential cause",
		accessor: ({ twoDigitCode }) => twoDigitCode?.potentialCause,
		Cell: ({ row: { original } }: Cell<TwoDigitMachineCode>) => {
			return original.twoDigitCode?.potentialCause || <EmptyValueDash />
		},
		disableSortBy: true,
	},
]

const defaultTwoDigitMachineCodesQueryParams: TwoDigitMachineCodesQueryParams = {
	ordering: null,
	search: null,
	active: true,
	startOnAfter: null,
	startOnBefore: null,
}
