import { MouseEvent, ReactElement, useState } from "react"

import { css, Theme } from "@emotion/react"
import { Popover } from "@material-ui/core"
import { transparentize } from "polished"
import { Row } from "react-table"

import { IconButton } from "../buttons"
import { Box } from "../layout"
import { Icon } from "../typography"
import { TableProps } from "./Table"

interface TableRowMenuProps<RowType extends object> {
	menu: Exclude<TableProps<RowType, Object>["rowMenu"], undefined>
	row: Row<RowType>
}

export const TableRowMenu = <RowType extends object>({
	menu,
	row,
}: TableRowMenuProps<RowType>): ReactElement => {
	const [rowMenuAnchorEl, setRowMenuAnchorEl] = useState<HTMLButtonElement | null>(null)

	return (
		<>
			{/* Just using this click listener to let user click menu without also expanding row. */}
			{/* eslint-disable */}
			<td
				css={rowMenuCell}
				onClick={(e) => {
					e.stopPropagation()
				}}
			>
				{/* eslint-enable */}
				<Box position="absolute" top={0} right={0.15}>
					<IconButton
						icon="caret-down"
						family="solid"
						onClick={(e: MouseEvent<HTMLButtonElement>) => {
							e.stopPropagation()
							setRowMenuAnchorEl(e.currentTarget)
						}}
						color="primary"
					/>
				</Box>

				<Popover
					open={!!rowMenuAnchorEl}
					anchorEl={rowMenuAnchorEl}
					onClose={() => {
						setRowMenuAnchorEl(null)
					}}
					anchorOrigin={{
						horizontal: "right",
						vertical: "top",
					}}
					transformOrigin={{
						horizontal: "right",
						vertical: "top",
					}}
				>
					<ul css={rowMenuStyle}>
						{menu.map((menuItem) => {
							if (
								!menuItem ||
								(typeof menuItem.hiddenAccessor === "function" &&
									menuItem.hiddenAccessor(row) === true)
							) {
								return null
							}

							return (
								<li key={menuItem.label}>
									<button
										type="button"
										onClick={(e) => {
											e.stopPropagation()
											setRowMenuAnchorEl(null)
											menuItem.onClick(row)
										}}
										disabled={
											menuItem.disabledAccessor ?
												menuItem.disabledAccessor(row)
											:	false
										}
									>
										{!!menuItem.iconName && (
											<Box display="inline-block" pr={0.5}>
												<Icon
													icon={menuItem.iconName}
													family={menuItem.iconFamily}
													fixedWidth
												/>
											</Box>
										)}
										{menuItem.label}
									</button>
								</li>
							)
						})}
					</ul>
				</Popover>
			</td>
		</>
	)
}

const rowMenuCell = css`
	position: relative;
	text-align: right;
	width: 3rem;
	opacity: 0.6;
	transition: opacity 200ms ease-out;
	&:hover {
		opacity: 1;
	}
`
const rowMenuStyle = (theme: Theme) => css`
	padding: 0.5rem 0;
	margin: 0;
	li {
		list-style: none;
		border-bottom: 1px solid #eee;
		&:last-of-type {
			border: none;
		}
		button {
			width: 100%;
			min-width: 10rem;
			text-align: left;
			padding: 0.75rem 1rem;
			background: none;
			border: none;
			&:hover:not(:disabled) {
				background: ${transparentize(0.9, theme.palette.primary.main)};
			}
			&:disabled {
				cursor: not-allowed;
			}
		}
	}
`
