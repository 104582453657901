import { Dispatch, ReactElement, SetStateAction } from "react"

import { PartOrderStatusSelector } from "../selectors"

export interface PartOrderStatusQueryParams {
	partOrderStatus: string | null
}

export interface PartOrderStatusQueryFilterProps<
	QueryParamState extends PartOrderStatusQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

export const PartOrderStatusQueryFilter = <QueryParamState extends PartOrderStatusQueryParams>({
	queryParamState,
	setQueryParamState,
}: PartOrderStatusQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			partOrderStatus: newValue || null,
		}))
	}

	return (
		<PartOrderStatusSelector
			value={queryParamState.partOrderStatus}
			onChange={handleChange}
			fillContainer
			disableNoSelectionOption={false}
			noSelectionOptionText="Any status"
		/>
	)
}
