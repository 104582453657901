import { ReactElement, SVGProps } from "react"

import { CartesianGridProps, YAxisProps } from "recharts"
import { AxisDomain, AxisInterval, BaseAxisProps, Margin } from "recharts/types/util/types"

import { PortalReport, PortalReportName, ReportParamKeys, ReportTimeSpan } from "@ncs/ncs-api"
import { DateFormat, formatDate, TimeAgo } from "@ncs/ts-utils"

import { ResponsiveChartContainerProps } from "./ResponsiveChartContainer"

export const mergeOverlayData = (
	originalReport: PortalReport,
	overlayReport: PortalReport
): PortalReport => {
	// We need to splice the overlay data into the original report's data.
	const combinedData: Record<string, number>[] = []

	// We'll do this by going through each original report data object one by one and
	// looking for a data object in the overlay report that has the same x key value.
	originalReport.data.forEach((originalData) => {
		const dataToInsert = Object.keys(overlayReport.dataKeys).reduce(
			(newObject, overlayKey) => {
				const matchingOverlayData = overlayReport.data.find((overlayData) => {
					const originalDataXValue = originalData[originalReport.xKey]
					const overlayDataXValue = overlayData[overlayReport.xKey]

					return originalDataXValue === overlayDataXValue
				})

				const result = {
					...newObject,
					[overlayKey]: matchingOverlayData ? matchingOverlayData[overlayKey] : null,
				}

				return result
			},
			{}
		)

		combinedData.push({
			...dataToInsert,
			...originalData,
		})
	})

	return {
		...originalReport,
		data: combinedData,
	}
}

export interface ReportOverlayBundle {
	name: PortalReportName
	report: PortalReport | undefined
	yAxisFormatter?: YAxisProps["tickFormatter"]
}

export const makeChartOverlayBundle = (
	name: PortalReportName | null,
	reports: Partial<Record<PortalReportName, PortalReport | undefined>>
): ReportOverlayBundle | undefined => {
	switch (name) {
		case PortalReportName.MachineEvents: {
			return {
				name: PortalReportName.MachineEvents,
				report: reports[PortalReportName.MachineEvents],
			}
		}
		case PortalReportName.WashCounts: {
			return {
				name: PortalReportName.WashCounts,
				report: reports[PortalReportName.WashCounts],
			}
		}
		case PortalReportName.WaterQuality: {
			return {
				name: PortalReportName.WaterQuality,
				report: reports[PortalReportName.WaterQuality],
			}
		}
		case PortalReportName.Weather: {
			return {
				name: PortalReportName.Weather,
				report: reports[PortalReportName.Weather],
				yAxisFormatter: (value: string, index?: number) => `${value}°`,
			}
		}
		default: {
			return undefined
		}
	}
}

export const validateChartConfiguration = ({
	report,
	overlayReportBundle,
	loading,
	params,
}: {
	report: PortalReport | undefined
	overlayReportBundle: ReportOverlayBundle | undefined
	loading: boolean
	params: Partial<Record<ReportParamKeys, unknown>>
}): string | null => {
	// Don't show an error if we're loading.
	if (loading) {
		return null
	}
	// The report itself should have data.
	if (!!report && !report?.data?.length) {
		return "No data returned for the selected configuration"
	}
	// Can't get weather data without specifying a site.
	if (overlayReportBundle?.name === PortalReportName.Weather && !params[ReportParamKeys.Site]) {
		return "Weather data overlay requires a site to be selected"
	}
	// Weather data, at least in its current form, doesn't really work hourly because we don't it
	// frequently enough.
	if (
		overlayReportBundle?.name === PortalReportName.Weather &&
		params[ReportParamKeys.Frequency] === ReportTimeSpan.Hourly
	) {
		return "Weather data may appear incomplete in hourly view"
	}
	// Water quality data only works with monthly.
	if (
		overlayReportBundle?.name === PortalReportName.WaterQuality &&
		params[ReportParamKeys.Frequency] !== ReportTimeSpan.Monthly
	) {
		return "Water quality data requires monthly data frequency"
	}
	// The overlay data didn't return any data.
	if (!!overlayReportBundle && !overlayReportBundle?.report?.data?.length) {
		return "No overlay data returned for the selected configuration"
	}

	return null
}

export interface ExtendableBarChartProps extends ExtendableChartProps {
	onBarClick?: (data: { payload: Record<string, string | number> }) => void
}

export interface ExtendableChartProps {
	chartMargins?: Margin
	colorArray?: string[]
	gridProps?: Omit<SVGProps<SVGElement> & CartesianGridProps, "ref">
	height?: ResponsiveChartContainerProps["height"]
	legendPosition?: "right" | "bottom"
	tooltipFormatter?: (value: number, name: string) => string | [string, string]
	withLegend?: boolean
	withTooltip?: boolean
	xAxisDomain?: AxisDomain
	xAxisInterval?: AxisInterval
	xAxisLabel?: BaseAxisProps["label"]
	xAxisTick?: CustomTick
	xAxisTickFormatter?: (value: string | number) => string
	xAxisType?: "number" | "category"
	yAxisDomain?: AxisDomain
	yAxisInterval?: AxisInterval
	yAxisLabel?: BaseAxisProps["label"]
	yAxisTickFormatter?: (value: string | number) => string
	yAxisType?: "number" | "category"
}

export type CustomTick =
	| SVGProps<SVGTextElement>
	| ReactElement<SVGElement>
	| ((props: TickProps) => ReactElement<SVGElement>)
	| boolean

export interface TickProps {
	className: string
	fill: string
	height: number
	index: number
	orientation: string
	payload: {
		coordinate: number
		index: 0
		offset: number
		value: string // The actual tick text label
	}
	stroke: string
	textAnchor: string
	tickFormatter: ((value: string) => string) | undefined
	type: string
	verticalAnchor: string
	visibleTicksCount: number
	width: number
	x: number
	y: number
}

export const formatDateTimeTick = (
	value: string,
	timeSpan: ReportTimeSpan,
	timeAgo?: TimeAgo
): string => {
	switch (timeSpan) {
		case ReportTimeSpan.Hourly: {
			return (
					([TimeAgo.Today, TimeAgo.Yesterday] as (TimeAgo | undefined)[]).includes(
						timeAgo
					)
				) ?
					formatDate(value, DateFormat.Time)
				:	formatDate(value, DateFormat.DateTimeShort)
		}
		case ReportTimeSpan.Daily: {
			return formatDate(value, "M/D")
		}
		case ReportTimeSpan.Weekly: {
			return `Week of ${formatDate(value, "M/DD")}`
		}
		case ReportTimeSpan.Monthly: {
			return formatDate(value, "MMM")
		}
	}
}
