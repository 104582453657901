import { apiClient, buildUrl } from "./axios"

// The generic document shape that comes back pretty much everywhere.
export interface WorkOrderDocument {
	id: number
	documentType: number
	name: string
}

export const createDocumentToken = async (
	documentId: string | number,
	documentType: number
): Promise<{ token: string }> => {
	const result = await apiClient.post("/workorders/document_tokens/", {
		document_id: documentId,
		document_type: documentType,
	})

	return result.data
}

export const getDocumentUrlForToken = (token: string): string => {
	// Open this URL (probably in a new window) to download your document.
	return buildUrl(`/api/document?token=${token}`)
}
