import { FC } from "react"

import { css } from "@emotion/react"

export interface YouTubeVideoProps {
	/** In a normal YouTube URL, it'll have `v=xyx`. This is that. It's also the same number in a YouTube share url, ie `https://youtu.be/xyz`. */
	v: string
}

export const YouTubeVideo: FC<YouTubeVideoProps> = ({ v }) => {
	return (
		<div css={youTubeContainerCss}>
			<iframe
				width="100%"
				height="100%"
				css={css`
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					border-radius: 10px;
				`}
				src={`https://www.youtube.com/embed/${v}`}
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>
	)
}

const youTubeContainerCss = css`
	position: relative;
	box-shadow: 0 7px 25px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	// Weird trick to make fill up the size of it's container and keep aspect ration.
	padding-bottom: 56.25%;
`
