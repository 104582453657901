import { extractNumber } from "./numbers"

export type StringAccessor<Item> = keyof Item | ((item: Item) => string)
/**
 * Get a string off an object with an accessing key or function.
 * Lets you know if it found something other than a string.
 */
export const accessString = <Item>(
	item: Item,
	accessor: StringAccessor<Item>,
	fallback = "",
	suppressNullishWarning?: boolean
): string => {
	const getString = (value: unknown): string => {
		if (typeof value === "string") {
			return value
		}
		if (value == undefined) {
			if (!suppressNullishWarning) {
				console.warn("accessString accessed something nullish. Returning fallback.")
			}
			return fallback
		}
		console.warn("accessString accessed something other than a string. Coercing result.")
		return String(value)
	}

	if (typeof accessor === "function") {
		return getString(accessor(item))
	}

	return getString(item[accessor])
}

export type NumberAccessor<Item> = keyof Item | ((item: Item) => number)
/**
 * Get a number off an object with an accessing key or function.
 * Lets you know if it found something other than a number.
 */
export const accessNumber = <Item extends Record<string, unknown>>(
	item: Item,
	accessor: NumberAccessor<Item>,
	fallback = 0,
	suppressNullishWarning?: boolean
): number => {
	const getNumber = (value: unknown): number => {
		if (Number.isNaN(value)) {
			console.warn("accessNumber accessed NaN. Returning fallback.")
			return fallback
		}
		if (typeof value === "number") {
			return value
		}
		if (typeof value === "string") {
			console.warn("accessNumber accessed a string. Coercing result.")
			return extractNumber(value)
		}
		if (typeof value == undefined && !suppressNullishWarning) {
			console.warn(
				"accessNumber accessed something other than a number or a string. Returning fallback."
			)
		}
		return fallback
	}

	if (typeof accessor === "function") {
		return getNumber(accessor(item))
	}

	return getNumber(item[accessor])
}

export type BooleanAccessor<Item> = keyof Item | ((item: Item) => boolean)
/**
 * Get a boolean off an object with an accessing key or function.
 * Lets you know if it found something other than a boolean.
 */
export const accessBoolean = <Item>(
	item: Item,
	accessor: BooleanAccessor<Item>,
	fallback = false,
	suppressNullishWarning?: boolean
): boolean => {
	const getBoolean = (value: unknown): boolean => {
		if (typeof value === "boolean") {
			return value
		}
		if (value == undefined) {
			if (!suppressNullishWarning) {
				console.warn("accessBoolean accessed a nullish value. Returning fallback.")
			}
			return fallback
		}
		console.warn("accessBoolean accessed something other than a boolean. Coercing result.")
		return !!value
	}

	if (typeof accessor === "function") {
		return getBoolean(accessor(item))
	}

	return getBoolean(item[accessor])
}

export type ArrayAccessor<Item> = keyof Item | ((item: Item) => unknown[])
/**
 * Get an array off an object with an accessing key or function.
 * Lets you know if it found something other than an array.
 */
export const accessArray = <Item>(
	item: Item,
	accessor: ArrayAccessor<Item>,
	fallback = [],
	suppressNullishWarning?: boolean
): unknown[] => {
	const getArray = (value: unknown): unknown[] => {
		if (Array.isArray(value)) {
			return value
		}
		if (value == undefined) {
			if (!suppressNullishWarning) {
				console.warn("accessArray accessed a nullish value. Returning fallback.")
			}
			return fallback
		}
		console.warn("accessArray accessed something other than an array. Putting it in an array.")
		return [value]
	}

	if (typeof accessor === "function") {
		return getArray(accessor(item))
	}

	return getArray(item[accessor])
}

/**
 * Get a value off an object with an accessing key or function.
 */
export const getWithAccessor = <Item>(
	item: Item,
	accessor: keyof Item | ((item: Item) => Item[keyof Item])
): Item[keyof Item] => {
	if (typeof accessor === "function") {
		return accessor(item)
	}

	return item[accessor]
}
