import { ApiGetQuery, useGetPrefetch, useGetRequest } from "../../request-hooks"
import { useEnumPropertyValidation } from "../../util/use-enum-validation"
import { Lookup } from "../common"
import { VacuumFilterGauge, VacuumSystemGauge } from "../connectivity"
import { ContractBusinessUnit, ContractType } from "../contracts"
import {
	CustomerClass,
	CustomerSubclass,
	CustomerType,
	CustomerUserContactType,
	FreightClass,
	FreightClassDescription,
	FreightClassId,
	Portal,
} from "../customers"
import { DispatchStatus, DispatchStatusId } from "../dispatches"
import { Vendor } from "../inventory"
import { PartOrderStatus } from "../parts-order"
import { ApplicationAssignment, UserMinimal } from "../security"
import {
	BinType,
	Container,
	ContractCoverageType,
	EquipmentVintageLookup,
	ErpStatus,
	InventoryRequestStatus,
	InventoryRequestType,
	InvoiceStatus,
	LookupsUrlPaths,
	LtlCarrier,
	PartProductType,
	PriceOverrideReason,
	PriceOverrideReasonTypeId,
	PricingAdjustmentFormStatus,
	ProfitabilityMonth,
	PurchaseOrderStatus,
	RoleGroup,
	SalesmanRole,
	SalesmanType,
	Warehouse,
} from "./types"

const lookupStaleTime: number = 1000 * 60 * 60 // 1 hour stale time

export const useDispatchCallTypes = (): ApiGetQuery<Lookup[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.CallTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useDispatchStatuses = (): ApiGetQuery<DispatchStatus[]> => {
	const [data, ...rest] = useGetRequest<DispatchStatus[]>(
		[LookupsUrlPaths.Lookups, LookupsUrlPaths.DispatchStatuses],
		{
			queryConfig: {
				staleTime: lookupStaleTime,
			},
		}
	)

	useEnumPropertyValidation(data, "id", DispatchStatusId)

	return [data, ...rest]
}

export const useDispatchPriorities = (): ApiGetQuery<Lookup<number>[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.DispatchPriorities], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const usePartOrderStatuses = (): ApiGetQuery<PartOrderStatus[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.PartOrderStatuses], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useVendors = (): ApiGetQuery<Vendor[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.Vendors], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useEquipmentVintagesLookup = (): ApiGetQuery<EquipmentVintageLookup[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.EquipmentVintages], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

// The call must return an array, so we'll make it single here.
export const useVacuumSystemGaugeValues = (): [VacuumSystemGauge | undefined, boolean] => {
	const [data, isLoading] = useGetRequest<VacuumSystemGauge[]>(
		[LookupsUrlPaths.Lookups, LookupsUrlPaths.VacuumSystemGauge],
		{
			queryConfig: {
				staleTime: lookupStaleTime,
			},
		}
	)

	return [data ? data[0] : undefined, isLoading]
}

// The call must return an array, so we'll make it single here.
export const useVacuumFilterGaugeValues = (): [VacuumFilterGauge | undefined, boolean] => {
	const [data, isLoading] = useGetRequest<VacuumFilterGauge[]>(
		[LookupsUrlPaths.Lookups, LookupsUrlPaths.VacuumFilterGauge],
		{
			queryConfig: {
				staleTime: lookupStaleTime,
			},
		}
	)

	return [data ? data[0] : undefined, isLoading]
}

export const useInvoiceStatuses = (): ApiGetQuery<InvoiceStatus[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.InvoiceStatuses], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const usePurchaseOrderStatuses = (): ApiGetQuery<PurchaseOrderStatus[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.PurchaseOrderStatus], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useWarehouses = (): ApiGetQuery<Warehouse[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.Warehouses], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useDefaultApplicationAssignments = (): ApiGetQuery<ApplicationAssignment[]> => {
	return useGetRequest(
		[LookupsUrlPaths.Lookups, LookupsUrlPaths.DefaultApplicationAssignments],
		{
			queryConfig: {
				staleTime: lookupStaleTime,
			},
		}
	)
}

export const useCustomerUserContactTypes = (): ApiGetQuery<CustomerUserContactType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.CustomerContactRequestType], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const usePriceOverrideReasons = (): ApiGetQuery<PriceOverrideReason[]> => {
	const [data, ...rest] = useGetRequest<PriceOverrideReason[]>(
		[LookupsUrlPaths.Lookups, LookupsUrlPaths.OrderLineOverrideReason],
		{
			queryConfig: {
				staleTime: lookupStaleTime,
			},
		}
	)

	useEnumPropertyValidation(data, "reasonTypeId", PriceOverrideReasonTypeId)

	return [data, ...rest]
}

export const useLtlCarriers = (): ApiGetQuery<LtlCarrier[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.LtlCarrier], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useContractTypes = (): ApiGetQuery<ContractType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.ContractEntityTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useContractBusinessUnits = (): ApiGetQuery<ContractBusinessUnit[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.BusinessUnits], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useRoleGroups = (): ApiGetQuery<RoleGroup[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.Groups], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useSalesmanRoles = (): ApiGetQuery<SalesmanRole[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.SalesmanRoles], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useSalesmanTypes = (): ApiGetQuery<SalesmanType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.SalesmanTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useContractCoverageTypes = (): ApiGetQuery<ContractCoverageType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.ContractCoverageTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useErpStatuses = (): ApiGetQuery<ErpStatus[]> => {
	return useGetRequest(
		[LookupsUrlPaths.Lookups, LookupsUrlPaths.EquipmentRepairProposalStatuses],
		{
			queryConfig: {
				staleTime: lookupStaleTime,
			},
		}
	)
}

export const usePortals = (): ApiGetQuery<Portal[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.Portals], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const usePortalUsers = (): ApiGetQuery<UserMinimal[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.PortalUsers], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useProfitabilityMonths = (): ApiGetQuery<ProfitabilityMonth[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.SiteProfitMonths], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useContainers = (): ApiGetQuery<Container[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.Containers], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const usePartProductTypes = (): ApiGetQuery<PartProductType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.PartProductTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useCustomerTypes = (): ApiGetQuery<CustomerType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.CustomerTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useCustomerClasses = (): ApiGetQuery<CustomerClass[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.CustomerClasses], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useCustomerSubclasses = (): ApiGetQuery<CustomerSubclass[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.CustomerSubclasses], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useInventoryRequestStatuses = (): ApiGetQuery<InventoryRequestStatus[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.InventoryRequestStatus], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useInventoryRequestTypes = (): ApiGetQuery<InventoryRequestType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.InventoryType], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const usePrefetchWorkOrderLineItemTypes = (): void => {
	useGetPrefetch([LookupsUrlPaths.Lookups, LookupsUrlPaths.WorkOrderLineItemTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useFreightClasses = (): ApiGetQuery<FreightClass[]> => {
	const [data, ...rest] = useGetRequest<FreightClass[]>(
		[LookupsUrlPaths.Lookups, LookupsUrlPaths.CustomerFreightClasses],
		{
			queryConfig: {
				staleTime: lookupStaleTime,
			},
		}
	)

	useEnumPropertyValidation(data, "id", FreightClassId)
	useEnumPropertyValidation(data, "description", FreightClassDescription)

	return [data, ...rest]
}

export const useCreditMemoReasons = (): ApiGetQuery<Lookup[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.CreditMemoReasons], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const useBinTypes = (): ApiGetQuery<BinType[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.BinTypes], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}

export const usePricingAdjustmentFormStatuses = (): ApiGetQuery<PricingAdjustmentFormStatus[]> => {
	return useGetRequest([LookupsUrlPaths.Lookups, LookupsUrlPaths.PermanentPriceUpdateStatus], {
		queryConfig: {
			staleTime: lookupStaleTime,
		},
	})
}
