import React, { useState } from "react"
import { css, Theme } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import * as Sentry from "@sentry/react"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { trackEvent, TrackingEvent } from "@ncs/web-legos"
import { z } from "zod"
import { isAxiosError, makeApiErrorMessage, useAuth } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	EventTracker,
	Heading,
	Link,
	Paragraph,
	TextInputFormField,
	useUrlState,
} from "@ncs/web-legos"
import { SupportIssueModal } from "~/shared-components"
import { dollarSign, message, screwdriver, shoppingCart, truck } from "./icons"

const SignInFormSchema = z.object({
	email: z.string({ required_error: "Email is required" }).email(),
	password: z.string({ required_error: "Password is required" }),
})

type SignInForm = z.infer<typeof SignInFormSchema>

export type LoginUrlState = {
	redirect: string | null
}

export const Login: React.FC = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [showSupportModal, setShowSupportModal] = useState(false)
	const history = useHistory()
	const [{ redirect }] = useUrlState<LoginUrlState>({ redirect: null })
	const { login } = useAuth()

	const {
		control,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<SignInForm>({
		resolver: zodResolver(SignInFormSchema),
	})

	const onSubmit = (data: SignInForm) => {
		setIsLoading(true)

		login(data.email, data.password)
			.then(() => {
				history.push(redirect || "/")
			})
			.catch((e) => {
				setIsLoading(false)
				if (isAxiosError(e)) {
					if (e.response?.status === 401) {
						setError("password", { message: "Incorrect email/password combination" })
					} else if (e.response?.status === 403) {
						setError("password", {
							message: "Account not currently valid for use on this site",
						})
						Sentry.captureException(e)
					}
				} else {
					setError("password", {
						message: makeApiErrorMessage(e),
					})
					console.error(e)
					Sentry.captureException(e)
				}
			})
		trackEvent(TrackingEvent.SIGNIN, { email: data.email })
	}

	return (
		<>
			<AnimatedEntrance show>
				<div css={outerContainerStyle}>
					<div>
						<Heading variant="h2" bold textAlign="center" mb={3}>
							Welcome Back!
						</Heading>
						<TextInputFormField
							control={control}
							name="email"
							label="Email Address"
							autoFocus
							placeholder="Enter email address"
							emptyValueFallback=""
							errorStyle={errors.email?.message}
							mb={2.5}
						/>
						<TextInputFormField
							control={control}
							name="password"
							label="Password"
							onReturn={handleSubmit(onSubmit)}
							type="password"
							placeholder="Enter password"
							emptyValueFallback=""
							errorStyle={errors.email?.message}
							mb={2.5}
						/>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="flex-end"
							alignItems="flex-end"
							rowGap={1}
						>
							<Link to="/auth/forgot-password" color="#0B75E1" fontSize={0.875}>
								Forgot password?
							</Link>
						</Box>
						<Button
							variant="primary-cta"
							fillContainer
							containerProps={{ mt: 2 }}
							onClick={handleSubmit(onSubmit)}
							isLoading={isLoading}
						>
							Sign In
						</Button>

						<div css={divider} />

						<Box display="flex" alignItems="flex-end" rowGap={1}>
							<span css={text}>Don’t have an account?</span>
							<EventTracker event={TrackingEvent.SIGNUP}>
								<Link to="/auth/create-account" color="#0B75E1" bold>
									Create One
								</Link>
							</EventTracker>
						</Box>
					</div>
					<div css={introDiv}>
						<Paragraph mb={1.5} customColor="#003264" bold>
							NCS Customer Portal
						</Paragraph>
						<Heading variant="h1" bold mb={2}>
							Making Life Better Through Carwashing
						</Heading>
						<ul css={perksListStyle}>
							<li>
								<div css={iconContainer}>{shoppingCart()}</div>
								<span>Shop Parts, Chemicals, & Detail</span>
							</li>
							<li>
								<div css={iconContainer}>{truck()}</div>
								<span>Check Order Status</span>
							</li>
							<li>
								<div css={iconContainer}>{dollarSign()}</div>
								<span>Review & Pay Invoices</span>
							</li>
							<li>
								<div css={iconContainer}>{screwdriver()}</div>
								<span>Submit Service Requests</span>
							</li>
							<li>
								<div css={iconContainer}>{message()}</div>
								<span>Access & Monitor Connected Equipment</span>
							</li>
						</ul>
					</div>
				</div>
			</AnimatedEntrance>
			<SupportIssueModal
				isOpen={showSupportModal}
				onClose={() => setShowSupportModal(false)}
			/>
		</>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	display: flex;
	justify-content: space-evenly;
	padding: 4rem 6rem 0 6rem;
	box-sizing: content-box;
	max-width: 80rem;
	margin: 0 auto;

	> div {
		padding-top: 3rem;
		width: 100%;
		max-width: 35rem;

		${theme.breakpoints.down("sm")} {
			padding-left: 0;
		}

		&:first-of-type {
			padding: 2rem;
			max-height: 42rem;
			background-color: #ffffff;
			border-radius: 12px;
			border: 1px solid #d1d5db;
			margin-bottom: 1rem;
			margin-right: 4rem;
		}
	}

	${theme.breakpoints.down("sm")} {
		display: grid;
		padding: 2rem;
		margin: auto;
	}
`
const perksListStyle = (theme: Theme) => css`
	margin: 2.5rem 0.5rem;
	${theme.breakpoints.down("sm")} {
		margin: 0;
	}
	li {
		display: flex;
		align-items: flex-start;
		column-gap: 1.1rem;
		margin-bottom: 2.25rem;
		align-items: center;
		> svg {
			position: relative;
			top: 3px;
			opacity: 0.75;
		}
		> span {
			font-size: 1.25rem;
		}
	}
`

const introDiv = css`
	background-color: #f9fafb;
`

const divider = css`
	border: 1px solid #eeeeee;
	width: 100%;
	margin: 3rem 0;
`

const iconContainer = css`
	padding: 0.5rem;
	background-color: #003264;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	align-self: center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
`

const text = css`
	margin-right: 1rem;
	font-weight: 500;
`
