import { useEffect, useRef } from "react"

export const usePrevious = <V>(value: V): V => {
	const ref = useRef<V>(value)

	useEffect(() => {
		ref.current = value
	})

	return ref.current
}
