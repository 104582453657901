import qs from "query-string"

export interface HeaderLink {
	url: string
	rel: string
	[queryParam: string]: string
}

export interface HeaderLinks {
	[rel: string]: HeaderLink
}

export const parseLinkHeader = (header: string | null | undefined): HeaderLinks | null => {
	if (!header) {
		return null
	}

	return header
		.split(/,\s*/)
		.map((item) => {
			try {
				const m = item.match(/<?([^>]*)>(.*)/)
				if (!m) return null
				const linkUrl = m[1]
				const parts = m[2].split(";").reduce(
					(obj, x) => {
						const n = x.match(/\s*(.+)\s*=\s*"?([^"]+)"?/)
						if (n) {
							obj[n[1]] = n[2]
						}
						return obj
					},
					{} as Record<string, string>
				)
				const parsedUrl = qs.parseUrl(linkUrl)
				const info: Record<string, string> = {
					...parsedUrl.query,
					...parts,
					url: linkUrl,
				}
				return info
			} catch (e) {
				console.warn("Failed to parse link header", e)
				return null
			}
		})
		.filter((x) => !!x?.rel)
		.reduce((acc, x) => {
			if (!acc) {
				return acc
			}
			x?.rel.split(/\s+/).forEach((rel) => {
				acc[rel] = { ...x, rel: rel, url: x.url }
			})

			return acc
		}, {} as HeaderLinks)
}
