import { arrayWrap } from "@ncs/ts-utils"

import { useAuth } from "../contexts"
import { APPLICATION } from "../portal-apps"

/**
 * Check auth to see if a user can use an application. If an array of applications are passed in,
 * just checks to see if user has at least one, not all.
 *
 * Looks at the app list in user's JWT auth token, so it does not refresh until user logs in/out.
 */
export const useUserCanUse = (requestedApps: APPLICATION | APPLICATION[]): boolean => {
	const { user } = useAuth()

	if (!user?.apps.length) {
		return false
	} else {
		return arrayWrap(requestedApps).some((app) => user.apps.includes(app))
	}
}
