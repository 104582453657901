import {
	Dispatch,
	Fragment,
	PropsWithChildren,
	ReactElement,
	SetStateAction,
	useCallback,
	useMemo,
} from "react"

import { css, Theme } from "@emotion/react"

import { formatNumber } from "@ncs/ts-utils"

import { useScrollToTop } from "../../util"
import { Button } from "../buttons"
import { Select } from "../inputs"
import { Box } from "../layout"
import { ResultCountSummary } from "../pagination"
import { LoadingSpinner } from "../transitions"
import { Paragraph } from "../typography"
import { TableProps } from "./Table"
import { PageSizes, pageSizeSelectOptions } from "./table-util"

export interface BelowTableProps<RowType extends Object> {
	query: TableProps<RowType>["query"]
	data: NonNullable<TableProps<RowType>["data"]>
	dataProp: TableProps<RowType>["data"]
	pagination?: {
		page: number
		pageSize: PageSizes
	}
	setPagination?: Dispatch<SetStateAction<{ page: number; pageSize: PageSizes }>>
	infiniteRowsIncrement: number
	visibleRowCount: number
	setVisibleRowCount: Dispatch<SetStateAction<number>>
	isUpdating: boolean
	usingQueryData: boolean
	toggleAllRowsExpanded: (value?: boolean | undefined) => void
}

export const BelowTable = <RowType extends Object>({
	query,
	data,
	dataProp,
	visibleRowCount,
	setVisibleRowCount,
	isUpdating,
	infiniteRowsIncrement,
	pagination,
	setPagination,
	usingQueryData,
	toggleAllRowsExpanded,
}: PropsWithChildren<BelowTableProps<RowType>>): ReactElement => {
	const scrollToTop = useScrollToTop()
	const currentPage = pagination?.page ?? query?.currentPage ?? 1

	const hasNextPage = useMemo(() => {
		if (pagination) {
			return currentPage < (query?.resultPageCount || 0)
		} else if (query) {
			return query?.hasNextPage || false
		} else if (dataProp) {
			return dataProp.length > infiniteRowsIncrement
		} else {
			return false
		}
	}, [pagination, currentPage, dataProp, infiniteRowsIncrement, query])

	const hasPreviousPage = useMemo(() => pagination && currentPage > 1, [pagination, currentPage])

	const pageNumbersToShow = useMemo(() => {
		if (!pagination || !query?.resultPageCount) return []

		const { resultPageCount } = query

		const pages = [currentPage]

		let hasPagesBehind = true
		let hasPagesAhead = true

		while (pages.length < 5 && (hasPagesBehind || hasPagesAhead)) {
			// Try to add pages behind.
			const oneBack = Math.min(...pages) - 1
			if (oneBack > 0) {
				if (!pages.includes(oneBack)) pages.push(oneBack)
			} else {
				hasPagesBehind = false
			}
			// Try to add pages ahead.
			const oneForward = Math.max(...pages) + 1
			if (oneForward <= resultPageCount) {
				if (!pages.includes(oneForward)) pages.push(oneForward)
			} else {
				hasPagesAhead = false
			}
		}

		// Add the first and last if they're not in there already.
		if (!pages.includes(1)) pages.push(1)
		if (!pages.includes(resultPageCount)) pages.push(resultPageCount)

		pages.sort((a, b) => (a > b ? 1 : -1))

		return pages
	}, [pagination, query, currentPage])

	const goToPage = useCallback(
		(newPage: number) => {
			if (setPagination) {
				setPagination((prev) => ({
					...prev,
					page: newPage,
				}))
				toggleAllRowsExpanded(false)
				scrollToTop()
			}
		},
		[scrollToTop, setPagination, toggleAllRowsExpanded]
	)

	const goToNextPage = useCallback(() => {
		if (setPagination && hasNextPage) {
			setPagination((prev) => ({
				...prev,
				page: prev.page + 1,
			}))
			toggleAllRowsExpanded(false)
			scrollToTop()
		}
	}, [hasNextPage, scrollToTop, setPagination, toggleAllRowsExpanded])

	const goToPreviousPage = useCallback(() => {
		if (setPagination && hasPreviousPage) {
			setPagination((prev) => ({
				...prev,
				page: prev.page - 1,
			}))
			toggleAllRowsExpanded(false)
			scrollToTop()
		}
	}, [hasPreviousPage, scrollToTop, setPagination, toggleAllRowsExpanded])

	const showInfiniteRowControls = useMemo(() => {
		if (pagination) return false

		if (query) {
			return query?.hasNextPage || false
		} else if (dataProp) {
			return visibleRowCount < (dataProp?.length ?? 0)
		}

		return false
	}, [pagination, dataProp, query, visibleRowCount])

	const showInfiniteRowInfo = useMemo(() => {
		if (pagination) return false

		if (query) {
			return (
				query.hasNextPage ||
				(!!query.rawQuery.data?.pages && query.rawQuery.data?.pages.length > 1)
			)
		} else if (dataProp) {
			return dataProp.length > infiniteRowsIncrement
		}

		return false
	}, [dataProp, infiniteRowsIncrement, query, pagination])

	const showPaginationControls = !!pagination && !!query?.data.length
	const showPaginationInfo =
		showPaginationControls && !!setPagination && !!query?.resultCountEstimate

	return (
		<>
			<Box textAlign={pagination ? "center" : undefined}>
				{showInfiniteRowInfo && (
					<ResultCountSummary
						currentTotal={
							usingQueryData ? data.length
							: visibleRowCount < data.length ?
								visibleRowCount
							:	data.length
						}
						totalResults={query?.resultCountEstimate ?? dataProp?.length ?? 0} // Shouldn't ever fallback to 0.
						resultsAreApproximate={query?.hasNextPage}
						resultWord={!query ? "row" : undefined}
					/>
				)}

				{showInfiniteRowControls && (
					<Box mb={5}>
						<Button
							onClick={() => {
								usingQueryData ?
									query?.fetchNextPage()
								:	setVisibleRowCount((prev) => prev + infiniteRowsIncrement)
							}}
							icon="long-arrow-down"
							isLoading={isUpdating}
						>
							Load more {query ? "results" : "rows"}
						</Button>

						{/* Only use the Show All button if we're not using query data. */}
						{!usingQueryData && (
							<Button
								icon="arrow-to-bottom"
								onClick={() => setVisibleRowCount(dataProp?.length ?? 0)}
								containerProps={{ ml: 1 }}
							>
								Load all rows
							</Button>
						)}
					</Box>
				)}

				{!!pagination &&
					(!!query?.rawQuery.isPreviousData || // Show while switching pages
						(!!data.length && query?.resultCountEstimate == null)) && ( // Show while showing cached results, but loading result counts
						<LoadingSpinner py={0} pt={1} pb={1} />
					)}

				{showPaginationInfo && (
					<>
						<Box display="inline-flex" alignItems="center" mb={1}>
							<Paragraph small color="secondary">
								Approximately {formatNumber(query?.resultCountEstimate ?? 0)}{" "}
								results. Show per page:
							</Paragraph>
							<Select
								value={pagination.pageSize.toString()}
								options={pageSizeSelectOptions}
								valueAccessor={(option) => option.value.toString()}
								textAccessor="label"
								onChange={(newValue, option) => {
									if (option?.value) {
										setPagination({
											pageSize: option.value,
											// Changing the page size also means we need to go back to page 1.
											page: 1,
										})
										scrollToTop()
									}
								}}
								showNoSelectionOption={false}
								disableNoSelectionOption
								mb={0}
								ml={0.5}
							/>
						</Box>
					</>
				)}
			</Box>

			{showPaginationControls && query && (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					columnGap={1}
					flexWrap="wrap"
					mb={5}
				>
					<Button
						icon="long-arrow-left"
						disabled={!hasPreviousPage}
						onClick={goToPreviousPage}
					>
						Prev
					</Button>
					<Box
						display="flex"
						columnGap={0.25}
						alignItems="center"
						flexWrap="wrap"
						css={paginationCss}
					>
						{pageNumbersToShow.map((pageNum, i) => (
							<Fragment key={pageNum}>
								<button
									className={pageNum === currentPage ? "current" : undefined}
									onClick={() => goToPage(pageNum)}
								>
									{formatNumber(pageNum)}
								</button>
								{pageNumbersToShow[i + 1] - pageNum > 1 && <span>...</span>}
							</Fragment>
						))}
					</Box>
					<Button
						trailingIcon="long-arrow-right"
						disabled={!hasNextPage}
						onClick={goToNextPage}
					>
						Next
					</Button>
				</Box>
			)}
		</>
	)
}

const paginationCss = (theme: Theme) => css`
	margin-top: 0.25rem;
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		height: 2.5rem;
		padding: 0 0.55rem;
		background: none;
		border: none;
		opacity: 0.5;
		&:hover {
			background: rgb(239, 245, 247);
			opacity: 1;
		}
		&.current {
			background: #eee;
			font-weight: bold;
			opacity: 1;
			color: #555;
			cursor: pointer;
			pointer-events: none;
		}
	}
	span {
		opacity: 0.5;
		font-size: 1.5rem;
		height: 1.5rem;
	}
`
