import { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react"

import { Card, Payments, Square } from "@square/web-payments-sdk-types"

export interface SquareCardContextState {
	card: Card | null
	makeCard: () => void
	clearCard: () => void
	verifyBuyer: Payments["verifyBuyer"]
	errors: Square["errors"] | null
}

export const SquareCardContext = createContext<SquareCardContextState | undefined>(undefined)

SquareCardContext.displayName = "SquareCardContext"

export interface SquareCardContextProviderProps {
	squareUrl: string | null | undefined
	applicationId: string | null | undefined
	locationId: string | null | undefined
}

export const SquareCardContextProvider: FC<SquareCardContextProviderProps> = ({
	squareUrl,
	applicationId,
	locationId,
	children,
}) => {
	const paymentsRef = useRef<ReturnType<Square["payments"]> | null>(null)
	const [card, setCard] = useState<Card | null>(null)

	useEffect(() => {
		if (!squareUrl) {
			console.error("squareUrl not passed to SquareCardContext. Square payments won't work.")
			return
		}
		if (!applicationId) {
			console.error(
				"applicationId not passed to SquareCardContext. Square payments won't work."
			)
			return
		}
		if (!locationId) {
			console.error(
				"locationId not passed to SquareCardContext. Square payments won't work."
			)
			return
		}

		const script = document.createElement("script")
		script.src = squareUrl
		script.async = true

		script.onload = () => {
			if (window.Square) {
				const payments = window.Square.payments(applicationId, locationId)
				paymentsRef.current = payments
			}
		}

		document.body.appendChild(script)
	}, [squareUrl, applicationId, locationId])

	const makeCard = useCallback(() => {
		// Because this can likely be called right as the page is loading and we're still
		// downloading the Square script, keep trying for a few seconds.
		const times = [0.25, 1, 1, 1, 3, 5]
		let i = 0

		const timer = setInterval(async () => {
			if (paymentsRef.current) {
				clearTimeout(timer)
				const newCard = await paymentsRef.current.card()
				setCard(newCard)
			} else if (i + 1 < times.length) {
				console.warn("`payments` was not ready, trying again soon...")
				i++
			} else {
				// Just give it up, man. `Card` will stay null.
				console.error("Unable to find the `payments` object to make a card with.")
				clearTimeout(timer)
			}
		}, times[i] * 1000)
	}, [])

	const clearCard = useCallback(async () => {
		if (card) {
			await card.destroy()
		}
		setCard(null)
	}, [card])

	const verifyBuyer = useCallback((...params: Parameters<Payments["verifyBuyer"]>) => {
		if (!paymentsRef.current) {
			throw new Error("Calling `verifyBuyer` before `payments` was ready.")
		}

		return paymentsRef.current.verifyBuyer(...params)
	}, [])

	return (
		<SquareCardContext.Provider
			value={{
				card,
				makeCard,
				clearCard,
				verifyBuyer,
				errors: window.Square?.errors ?? null,
			}}
		>
			{children}
		</SquareCardContext.Provider>
	)
}

export const useSquareCardContext = (): SquareCardContextState => {
	const context = useContext(SquareCardContext)

	if (context === undefined) {
		throw new Error("useSquareCardContext must be used within SquareCardContextProvider")
	}

	return context
}
