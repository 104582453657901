import { FC, memo, useMemo } from "react"

import { css, useTheme } from "@emotion/react"

import { Box, BoxProps } from "../layout"
import { SkeletonRows } from "../transitions"
import { Icon, IconFamily, IconName, IconProps } from "./Icon"

export interface CalloutProps extends BoxProps {
	variant?: "success" | "warning" | "error" | "info" | "primary"
	icon?: IconName
	iconFamily?: IconFamily
	iconSize?: IconProps["fontSize"]
	fillContainer?: boolean
	isLoading?: boolean
	loadingRowCount?: number
	loadingRowWidth?: string | number
	/** Override the color of the variant if necessary. */
	textColor?: string
	/** Don't show the left border. */
	noBorder?: boolean
}

export const Callout: FC<CalloutProps> = memo(
	({
		variant = "primary",
		icon,
		iconFamily,
		iconSize,
		fillContainer,
		isLoading,
		loadingRowCount = 3,
		loadingRowWidth = 5,
		textColor,
		noBorder,
		children,
		...rest
	}) => {
		const { palette } = useTheme()

		const baseCalloutStyles = useMemo(
			() => css`
				border-radius: 5px;
				border-width: ${noBorder ? 0 : "0 0 0 5px"};
				border-color: transparent;
				border-style: solid;
				box-shadow: 0 2px 3px rgba(0, 0, 0, 0.09);
				p {
					color: inherit;
				}
				.lines-container {
					display: block;
				}
			`,
			[noBorder]
		)

		const variantCalloutStyles = useMemo(() => {
			switch (variant) {
				case "error": {
					return css`
						color: ${textColor || "white"};
						background: ${palette.error.light};
						border-color: #bf0202;
						a {
							color: ${textColor || "white"};
						}
					`
				}
				case "warning": {
					return css`
						color: ${textColor || palette.warning.contrastText};
						background: #ffec8c;
						border-color: #e6d373;
					`
				}
				case "success": {
					return css`
						color: ${textColor || "rgb(19 68 21)"};
						background: #9ce79e;
						border-color: #6cbc6f;
					`
				}
				case "info": {
					return css`
						color: ${textColor || "#555"};
						background: ${infoCalloutBackgroundColor};
						border-color: #e2dfdf;
					`
				}
				case "primary":
				default: {
					return css`
						color: ${textColor || palette.primary.main};
						background: #eff5f7;
						border-color: #9abfcb;
					`
				}
			}
		}, [textColor, palette.error, palette.warning, palette.primary, variant])

		return (
			<Box
				display={fillContainer ? "flex" : "inline-flex"}
				p="0.85rem 1.1rem"
				mb={1}
				{...rest}
				css={[baseCalloutStyles, variantCalloutStyles]}
			>
				{isLoading ?
					<SkeletonRows
						rows={loadingRowCount}
						width={loadingRowWidth}
						containerProps={{ my: 0 }}
						rowProps={{ my: 0.2 }}
					/>
				:	<Box
						display={icon ? "flex" : undefined}
						columnGap={1}
						flexGrow={fillContainer ? 1 : undefined}
					>
						{icon && (
							<div>
								<Icon icon={icon} fontSize={iconSize} family={iconFamily} />
							</div>
						)}
						<div>{children}</div>
					</Box>
				}
			</Box>
		)
	}
)

export const infoCalloutBackgroundColor = "#f8f8f8"
