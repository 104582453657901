import { useEffect, useMemo, useState } from "react"

import { css } from "@emotion/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/minimal"
import { isValidPhoneNumber } from "libphonenumber-js"
import { FieldError, useForm } from "react-hook-form"
import { z } from "zod"

import {
	Contact,
	makeApiErrorMessage,
	useCreateNewContact,
	useDeleteContact,
	useSites,
	useUpdateContact,
} from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Button,
	CheckboxGroupFormField,
	ErrorText,
	GridContainer,
	GridItem,
	Heading,
	Modal,
	Paragraph,
	PhoneInputFormField,
	SkeletonRows,
	TextInput,
	TextInputFormField,
	usePrevious,
} from "@ncs/web-legos"

export interface EditContactModalProps {
	isOpen: boolean
	onClose: (closeActions?: () => void) => void
	contact: Contact | null
}

const RecipientFormSchema = z.object({
	firstName: z.string().nonempty("First name is required "),
	lastName: z.string().nonempty("Last name is required "),
	email: z.string().nonempty("Email address is required ").email(),
	sites: z.string().array().min(1, "At least one site must be selected"),
	phone: z
		.string()
		.nonempty("Phone number is required")
		.refine((value) => isValidPhoneNumber(value, "US"), {
			message: "Not a valid U.S. phone number",
		}),
})

export type RecipientForm = z.infer<typeof RecipientFormSchema>

const defaultRecipientForm: RecipientForm = {
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	sites: [],
}

export const EditContactModal: React.FC<EditContactModalProps> = ({
	isOpen,
	onClose,
	contact,
}) => {
	const [siteFilterQuery, setSiteFilterQuery] = useState("")
	const [saveErrorMessage, setSaveErrorMessage] = useState<null | string>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [deleteErrorMessage, setDeleteErrorMessage] = useState<null | string>(null)
	const [isDeleting, setIsDeleting] = useState(false)
	const [showConfirmDelete, setShowConfirmDelete] = useState(false)

	const [sites, sitesLoading] = useSites()
	const createNewContact = useCreateNewContact()
	const deleteContact = useDeleteContact()
	const updateContact = useUpdateContact()

	const {
		handleSubmit,
		control,
		reset,
		formState: { isDirty, isValid, submitCount, errors },
		setValue,
		watch,
	} = useForm<RecipientForm>({
		resolver: zodResolver(RecipientFormSchema),
		defaultValues: defaultRecipientForm,
	})

	const selectedSites = watch("sites")

	const prevIsOpen = usePrevious(isOpen)
	useEffect(() => {
		// Whenever the modal opens, fill the form up with the passed in contact's details,
		// or provide defaults again, this time with all the site IDs so that they start all checked.
		if (!!isOpen && !prevIsOpen) {
			if (contact) {
				reset({
					firstName: contact.firstName ?? "",
					lastName: contact.lastName ?? "",
					email: contact.email ?? "",
					phone: contact.phone ?? "",
					sites: contact.customers.map((customer) => customer.id) ?? [],
				})
			} else {
				reset({
					...defaultRecipientForm,
					sites: (sites ?? []).map((site) => site.id),
				})
			}
		}
	}, [isOpen, prevIsOpen, reset, sites, contact])

	const handleClose = () => {
		onClose(() => {
			setSiteFilterQuery("")
			setSaveErrorMessage(null)
			setIsSaving(false)
			setDeleteErrorMessage(null)
			setIsDeleting(false)
			setShowConfirmDelete(false)
		})
	}

	const onSubmit = async (data: RecipientForm) => {
		try {
			setIsSaving(true)
			setSaveErrorMessage(null)
			if (contact) {
				await updateContact({
					updates: {
						firstName: data.firstName,
						lastName: data.lastName,
						phone: data.phone,
						email: data.email,
						addedSites: data.sites.filter((siteId) =>
							contact.customers.every((customer) => customer.id !== siteId)
						),
						removedSites: contact.customers
							.filter((customer) => !data.sites.includes(customer.id))
							.map((customer) => customer.id),
					},
					id: contact.id,
				})
			} else {
				await createNewContact(data)
			}
			handleClose()
		} catch (e) {
			captureException(e)
			setSaveErrorMessage(makeApiErrorMessage(e))
		} finally {
			setIsSaving(false)
		}
	}

	const onDelete = async () => {
		if (contact) {
			try {
				setIsDeleting(true)
				setDeleteErrorMessage(null)
				await deleteContact(contact.id)
				handleClose()
			} catch (e) {
				captureException(e)
				setDeleteErrorMessage(makeApiErrorMessage(e))
			} finally {
				setIsDeleting(false)
			}
		}
	}

	const onBulkCheck = (action: "all" | "none") => {
		if (action === "all") {
			setValue(
				"sites",
				(sites ?? []).map((s) => s.id),
				{
					shouldDirty: true,
					shouldValidate: true,
				}
			)
		}
		if (action === "none") {
			setValue("sites", [], {
				shouldDirty: true,
				shouldValidate: true,
			})
		}
		// If you just toggled all the checkboxes, let's also clear our your search to make sure you realize
		// what just happened.
		setSiteFilterQuery("")
	}

	const siteOptions = useMemo(() => {
		return (sites ?? []).filter(
			(site) =>
				site.name?.toUpperCase().includes(siteFilterQuery.toUpperCase()) ||
				site.city?.toUpperCase().includes(siteFilterQuery.toUpperCase())
		)
	}, [sites, siteFilterQuery])

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title={contact?.id ? "Edit Alert Recipient" : "New Alert Recipient"}
			rightButtons={{
				buttonText: "Save",
				variant: "primary-cta",
				disabled: !isDirty || (!isValid && submitCount > 0),
				isLoading: isSaving,
				onClick: handleSubmit(onSubmit),
			}}
			leftButtons={
				contact?.id ?
					showConfirmDelete ?
						[
							{
								icon: "times",
								buttonText: "Cancel",
								onClick: () => setShowConfirmDelete(false),
								variant: "text",
							},
							{
								icon: "check",
								buttonText: "Confirm: Delete this person?",
								onClick: onDelete,
								isLoading: isDeleting,
								variant: "text",
							},
						]
					:	{
							buttonText: "Delete person",
							icon: "trash",
							variant: "text",
							onClick: () => setShowConfirmDelete(true),
						}

				:	undefined
			}
			maxWidth="lg"
		>
			<GridContainer>
				<GridItem sm={12} md={6}>
					<Heading variant="h5" bold mb={1}>
						Contact details
					</Heading>
					<Box maxWidth={30}>
						<TextInputFormField
							control={control}
							name="firstName"
							label="First name"
						/>
						<TextInputFormField control={control} name="lastName" label="Last name" />
						<TextInputFormField control={control} name="email" label="Email address" />
						<PhoneInputFormField control={control} name="phone" label="Phone number" />
					</Box>
				</GridItem>
				<GridItem sm={12} md={6}>
					<Heading variant="h5" bold mb={1}>
						Which sites should this person be associated with?
					</Heading>

					<Box display="flex">
						<Paragraph mb={1} mr={1}>
							{selectedSites.length} site{selectedSites.length !== 1 ? "s" : ""}{" "}
							selected
						</Paragraph>
						<AnimatedEntrance show={submitCount > 0 && !!errors.sites}>
							<ErrorText mb={1}>
								{/* The typings are confused by sites being an array. */}
								{(errors["sites"] as unknown as FieldError)?.message}
							</ErrorText>
						</AnimatedEntrance>
					</Box>

					<TextInput
						value={siteFilterQuery}
						onChange={(newValue) => setSiteFilterQuery(newValue ?? "")}
						placeholder="Type to filter sites"
						icon="search"
					/>

					<Button
						icon="check-square"
						onClick={() => onBulkCheck("all")}
						containerProps={{ mr: 2 }}
					>
						Check all
					</Button>
					<Button icon="square" onClick={() => onBulkCheck("none")}>
						Check none
					</Button>

					<div css={siteListStyle}>
						{sitesLoading && <SkeletonRows width={12} />}

						<CheckboxGroupFormField
							control={control}
							name="sites"
							rows={siteOptions}
							valueAccessor="id"
							labelAccessor="name"
						/>

						{siteOptions.length === 0 && !!siteFilterQuery && (
							<Paragraph small color="secondary">
								No sites found with '{siteFilterQuery}'
							</Paragraph>
						)}
					</div>
				</GridItem>
			</GridContainer>

			{!!saveErrorMessage && <ErrorText textAlign="right">{saveErrorMessage}</ErrorText>}
			{!!deleteErrorMessage && <ErrorText>{deleteErrorMessage}</ErrorText>}
		</Modal>
	)
}

const siteListStyle = css`
	margin-top: 1rem;
	height: 20rem;
	overflow-y: auto;
`
