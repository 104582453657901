import { FC } from "react"

import { VacuumEventType } from "@ncs/ncs-api"

import { Select, SelectProps } from "../inputs"

export interface VacuumEventTypeSelectorProps
	extends Omit<SelectProps<(typeof options)[number]>, "onChange" | "options"> {
	value: VacuumEventType | null
	onChange: (newType: VacuumEventType | null) => void
}

const options = Object.values(VacuumEventType).map((type) => ({
	value: type,
	text: type,
}))

export const VacuumEventTypeSelector: FC<VacuumEventTypeSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	return (
		<Select
			label="Event type"
			noSelectionOptionText="Any"
			disableNoSelectionOption={false}
			value={value}
			onChange={(newValue) => onChange(newValue as VacuumEventType | null)}
			options={options}
			{...rest}
		/>
	)
}
