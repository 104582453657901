import { FC, useMemo } from "react"

import { useCustomerBrandCards } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Divider,
	GridContainer,
	GridItem,
	GridItemProps,
	LoadingSpinner,
} from "@ncs/web-legos"

import { ShopBrandCta } from "./ShopBrandCta"

export interface BrandCtaGridProps {
	/** Don't show any brand cards, no matter what comes back from server for this user. */
	hideAll?: boolean
}

export const BrandCtaGrid: FC<BrandCtaGridProps> = ({ hideAll = false }) => {
	const [cards, cardsLoading] = useCustomerBrandCards()

	const gridItemProps: GridItemProps = useMemo(() => {
		if (!cards || !cards.length) {
			return {}
		}
		if (cards.length === 1) {
			return {
				xs: 12,
			}
		}
		if (cards.length === 2) {
			return {
				xs: 12,
				sm: 6,
			}
		}
		return {
			xs: 12,
			sm: 6,
			md: 4,
		}
	}, [cards])

	if (cardsLoading) {
		return <LoadingSpinner />
	}

	if (hideAll) {
		return null
	}

	return (
		<>
			<GridContainer
				rowGap={3}
				columnGap={3}
				justifyContent="center"
				smProps={{ columnGap: 2 }}
				xsProps={{ columnGap: 0 }}
			>
				{(cards ?? []).map((card, i) => {
					return (
						<GridItem key={card.id} {...gridItemProps}>
							<AnimatedEntrance show delay={75 * i}>
								<ShopBrandCta card={card} />
							</AnimatedEntrance>
						</GridItem>
					)
				})}
			</GridContainer>

			<Divider my={3} />
		</>
	)
}
