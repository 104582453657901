import { FC, useEffect, useMemo } from "react"

import { css, Theme } from "@emotion/react"
import { transparentize } from "polished"

import { OnlineChildPart } from "@ncs/ncs-api"
import { formatCurrency } from "@ncs/ts-utils"
import { Box, Icon, Paragraph } from "@ncs/web-legos"

export interface PartChildSelectorProps {
	/** Comes from the Part Details endpoint called with the parent ID. */
	childParts: OnlineChildPart[]
	selectedChildId: string | null
	setSelectedChildId: (newId: string | null) => void
	label?: string
	loadingPartDetails: boolean
}

export const PartChildSelector: FC<PartChildSelectorProps> = ({
	childParts,
	selectedChildId,
	setSelectedChildId,
	label = "option",
	loadingPartDetails,
}) => {
	const selectedChild = useMemo(() => {
		return childParts.find((part) => part.onlinePartId.toString() === selectedChildId)
	}, [selectedChildId, childParts])

	useEffect(() => {
		if (!!selectedChildId && !selectedChild && !loadingPartDetails) {
			// If you're not loading, and the selected child ID doesn't have a matching child, then
			// set out child selection to null.
			setSelectedChildId(null)
		}
	}, [selectedChildId, selectedChild, loadingPartDetails, setSelectedChildId])

	if (loadingPartDetails) {
		return null
	}

	if (!!selectedChildId && !selectedChild) {
		// Occasionally when part ID changes, you can get a stale child ID with a new list of
		// child parts, and it'll come back null. That's fine, it fixes itself on subsequent
		// renders.
		return null
	}

	if (childParts.length === 0) {
		return (
			<Paragraph small color="secondary">
				Sorry, there are no options currently available
			</Paragraph>
		)
	}

	return (
		<div>
			<Paragraph small>
				Selected {label}: <strong>{selectedChild?.description || ""}</strong>
			</Paragraph>
			<Box display="flex" flexWrap="wrap" columnGap={1} rowGap={1} mt={1}>
				{childParts.map((childPart) => {
					const isSelected = childPart.onlinePartId === selectedChild?.onlinePartId

					return (
						<button
							key={childPart.onlinePartId}
							css={optionButtonStyle}
							className={isSelected ? "selected" : undefined}
							onClick={() => {
								setSelectedChildId(childPart.onlinePartId.toString())
							}}
						>
							<Box display="flex" alignItems="center" columnGap={0.5} mb={0.5}>
								{isSelected && (
									<Icon icon="check-circle" family="solid" color="success" />
								)}
								<Paragraph>{childPart.description}</Paragraph>
							</Box>
							<Paragraph bold>{formatCurrency(childPart.netPrice)}</Paragraph>
						</button>
					)
				})}
			</Box>
		</div>
	)
}

const optionButtonStyle = ({ palette }: Theme) => css`
	padding: 0.5rem 1rem;
	height: 5rem;
	cursor: pointer;
	background: none;
	text-align: left;
	border: 2px solid ${palette.grey[300]};
	&:hover {
		background: ${palette.grey[100]};
	}
	&.selected {
		border-color: ${palette.primary.main};
		background: ${transparentize(0.92, palette.primary.main)};
	}
`
