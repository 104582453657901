import React, { useState } from "react"

import { captureException } from "@sentry/minimal"

import {
	CreditCard,
	makeApiErrorMessage,
	StripeBankAccount,
	useDeleteBankAccount,
	useDeleteCard,
} from "@ncs/ncs-api"
import { ErrorText, Modal, Paragraph } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export interface RemoveMethodModalProps {
	isOpen: boolean
	onClose: () => void
	bankAccount: StripeBankAccount | null
	card: CreditCard | null
}

export const RemoveMethodModal: React.FC<RemoveMethodModalProps> = ({
	isOpen,
	onClose,
	bankAccount,
	card,
}) => {
	const [, dispatch] = useShopContext()
	const [errorMessage, setErrorMessage] = useState("")
	const [isSubmitting, setIsSubmitting] = useState(false)
	const deleteBankAccount = useDeleteBankAccount()
	const deleteCard = useDeleteCard()

	const handleClose = async () => {
		setErrorMessage("")

		try {
			setIsSubmitting(true)
			if (bankAccount) {
				await deleteBankAccount(bankAccount.id)
			}
			if (card) {
				await deleteCard(card.id)
			}
			// Clear out the checkout context's saved payment method, because you
			// might have just removed it.
			dispatch({
				type: "set checkout payment method",
				payload: null,
			})
			onClose()
		} catch (e) {
			setErrorMessage(makeApiErrorMessage(e))
			captureException(e)
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			title="Remove Payment Method"
			rightButtons={{
				buttonText: "Remove",
				variant: "secondary-cta",
				onClick: handleClose,
				isLoading: isSubmitting,
			}}
		>
			{!!bankAccount && (
				<Paragraph>
					Remove {bankAccount.bankName} account ending in{" "}
					{bankAccount.accountNumberLast4}?
				</Paragraph>
			)}
			{!!card && (
				<Paragraph>
					Remove {card.brand} card ending in {card.last4}?
				</Paragraph>
			)}

			{!!errorMessage && <ErrorText textAlign="right">{errorMessage}</ErrorText>}
		</Modal>
	)
}
