import { FC, useMemo } from "react"

import { formatNumber } from "@ncs/ts-utils"

import { EmptyValueDash } from "./EmptyValueDash"

export interface FormattedNumberProps {
	value: number | null | undefined
	preserveZero?: boolean
}

export const FormattedNumber: FC<FormattedNumberProps> = ({
	value: valueProp,
	preserveZero = false,
}) => {
	const value = useMemo(() => {
		if (valueProp == null) return null

		if (valueProp === 0) {
			return preserveZero ? 0 : null
		}

		return valueProp
	}, [valueProp, preserveZero])

	return <>{value != null ? formatNumber(value) : <EmptyValueDash />}</>
}
