import React, { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useConnectedMachinesAtSites } from "@ncs/ncs-api"
import {
	ErrorText,
	getAddressFields,
	Icon,
	LoadingSpinner,
	Tabs,
	useUrlState,
} from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"
import { c12yAutoRefreshConfig } from "~/util"

import { ConnectedDevicesTab, DataReportingTab } from "./components"

export enum ConnectedSiteDetailTab {
	ConnectedDevices = "Connected Devices",
	Reporting = "Data Reporting",
}

export type ConnectedSiteDetailUrlState = {
	tab: ConnectedSiteDetailTab
}

export const ConnectedSiteDetail: React.FC = () => {
	const { siteId } = useParams<{ siteId: string }>()
	const [machinesAtSite, machinesAtSiteLoading] = useConnectedMachinesAtSites({
		params: { customer: siteId },
		queryConfig: {
			...c12yAutoRefreshConfig,
		},
	})
	const [{ tab }, { updateUrlValue }] = useUrlState<ConnectedSiteDetailUrlState>({
		tab: ConnectedSiteDetailTab.ConnectedDevices,
	})

	const { customer, devices } = useMemo(() => {
		const site = (machinesAtSite ?? []).find((s) => s.customer.id === siteId)

		return {
			customer: site?.customer ?? null,
			devices:
				site?.devices ?
					site.devices.sort((a, b) => {
						if (a.name === b.name) return 0
						if (a.name == null) return 1
						if (b.name == null) return 1
						return a.name > b.name ? 1 : -1
					})
				:	null,
		}
	}, [siteId, machinesAtSite])

	if (machinesAtSiteLoading) {
		return <LoadingSpinner />
	}

	if (!customer || !devices) {
		return <ErrorText>Sorry, there was a problem loading this site and its devices.</ErrorText>
	}

	return (
		<PageContentWrapper
			heading={customer.name}
			headingDetail={getAddressFields(customer, { exclude: ["name", "zip"] }).join(", ")}
			breadcrumbs={[{ name: "Connectivity", to: "/connectivity" }, { name: customer.name }]}
			backButtonLink="/connectivity"
		>
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: ConnectedSiteDetailTab.ConnectedDevices,
						navIcon: <Icon icon="car-wash" />,
						component: <ConnectedDevicesTab customer={customer} devices={devices} />,
					},
					{
						navLabel: ConnectedSiteDetailTab.Reporting,
						navIcon: <Icon icon="chart-bar" />,
						component: <DataReportingTab customer={customer} />,
					},
				]}
			/>
		</PageContentWrapper>
	)
}
