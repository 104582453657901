export interface FormatNumberOptions {
	digitsAfterDecimal: number
	fillTrailingZeroes: boolean
	zeroString: string | null
}

const defaultFormatNumberOptions: FormatNumberOptions = {
	digitsAfterDecimal: 3,
	fillTrailingZeroes: false,
	zeroString: null,
}

export function formatNumber(
	value: number,
	optionsOrDigitsAfterDecimal?: Partial<FormatNumberOptions>
): string
export function formatNumber(
	value: number,
	optionsOrDigitsAfterDecimal?: number,
	fillTrailingZeroesArg?: boolean
): string
export function formatNumber(
	value: number,
	optionsOrDigitsAfterDecimal?: number | Partial<FormatNumberOptions>,
	fillTrailingZeroesArg?: boolean
): string {
	const digitsAfterDecimal =
		typeof optionsOrDigitsAfterDecimal === "number" ?
			optionsOrDigitsAfterDecimal
		:	optionsOrDigitsAfterDecimal?.digitsAfterDecimal ??
			defaultFormatNumberOptions.digitsAfterDecimal
	const fillTrailingZeroes =
		typeof fillTrailingZeroesArg === "boolean" ? fillTrailingZeroesArg
		: typeof optionsOrDigitsAfterDecimal === "object" ?
			optionsOrDigitsAfterDecimal.fillTrailingZeroes ??
			defaultFormatNumberOptions.fillTrailingZeroes
		:	defaultFormatNumberOptions.fillTrailingZeroes
	const zeroString =
		typeof optionsOrDigitsAfterDecimal === "number" ? null : (
			optionsOrDigitsAfterDecimal?.zeroString ?? defaultFormatNumberOptions.zeroString
		)

	const result = new Intl.NumberFormat("en-US", {
		minimumFractionDigits: fillTrailingZeroes ? digitsAfterDecimal : 0,
		maximumFractionDigits: digitsAfterDecimal,
	}).format(value)

	if (zeroString && result === "0") {
		return zeroString
	}

	return result
}

export function formatNumberOrNull(value: number | null): string | null
export function formatNumberOrNull(value: number): string
export function formatNumberOrNull(
	value: number | null,
	digitsAfterDecimal = 3,
	fillTrailingZeroes = false
): string | null {
	if (value == null) return null

	return new Intl.NumberFormat("en-US", {
		minimumFractionDigits: fillTrailingZeroes ? digitsAfterDecimal : 0,
		maximumFractionDigits: digitsAfterDecimal,
	}).format(value)
}

export const extractNumber = (str: string | number | null | undefined): number => {
	const result = Number(String(str ?? "").replace(/[^0-9.-]|-(?![0-9])/g, ""))

	if (Number.isNaN(result)) {
		console.warn(`extractInteger got NaN from '${str}'. Returning 0 instead.`)

		return 0
	}

	return result
}

/**
 * Look at a string and find the first instance of 0-9, and keep going until the string ends or
 * goes back to non-0-9.
 */
export const findFirstNumber = (str: string): number | null => {
	let numberBegins: number | null = null
	let numberEnds: number | null = null

	for (let i = 0; i < str.length; i += 1) {
		if (str[i].match(/[0-9]/)) {
			if (numberBegins === null) {
				numberBegins = i
			}
			numberEnds = i
		} else if (numberEnds !== null) {
			break
		}
	}

	if (numberBegins === null || numberEnds === null) {
		return null
	}

	let result = ""
	for (let i = numberBegins; i <= numberEnds; i += 1) {
		result = result + str[i]
	}

	return extractNumber(result)
}

/**
 * Display a number formatted with commas, or some fallback string if it's nullish.
 */
export const displayNumber = (
	value: string | number | null | undefined,
	fallBack = "-"
): string => {
	if (value == null || value === "") {
		return fallBack
	}
	return formatNumber(extractNumber(value))
}

/**
 * Take a number that is going to display as a percent and multiply it by 100 if it's
 * not null or undefined. For example, pass in 0.07 and get 7 back.
 */
export function decimalToPercent(num: number, decimalScale?: number): number
export function decimalToPercent(num: null, decimalScale?: number): null
export function decimalToPercent(num: undefined, decimalScale?: number): undefined
export function decimalToPercent(num: number | null, decimalScale?: number): number | null
export function decimalToPercent(
	num: number | undefined,
	decimalScale?: number
): number | undefined
export function decimalToPercent(num: undefined | null, decimalScale?: number): undefined | null
export function decimalToPercent(
	num: number | null | undefined,
	decimalScale = 5
): number | null | undefined {
	if (num == null) {
		return num
	}

	return extractNumber((num * 100).toFixed(decimalScale))
}

/**
 * Take a number that is being displayed as a percent and divide it by 100
 * where necessary to store it in the DB. For example, pass in 3 and get 0.03.
 */
export function percentToDecimal(num: number, decimalScale?: number): number
export function percentToDecimal(num: null, decimalScale?: number): null
export function percentToDecimal(num: undefined, decimalScale?: number): undefined
export function percentToDecimal(num: number | null, decimalScale?: number): number | null
export function percentToDecimal(
	num: number | undefined,
	decimalScale?: number
): number | undefined
export function percentToDecimal(num: undefined | null, decimalScale?: number): undefined | null
export function percentToDecimal(
	num: number | null | undefined,
	decimalScale = 5
): number | null | undefined {
	if (num === 0) return 0

	if (num == null) {
		return num
	}

	return extractNumber((num / 100).toFixed(decimalScale + 2))
}

/**
 * Takes a number and a final length of digits and converts the number to a string with however
 * many necessary leading zeroes to have it equal the final length.
 */
export const fillLeadingZeroes = (num: number, finalLength: number): string => {
	const zeroesNeeded = finalLength - num.toString().length

	if (zeroesNeeded < 0) return num.toString()

	return `${new Array(zeroesNeeded).fill("0").join("")}${num}`
}

export const formatCurrency = (value: number | string, digitsAfterDecimal = 2): string => {
	return new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: digitsAfterDecimal,
		maximumFractionDigits: digitsAfterDecimal,
	}).format(Number(value))
}

export const formatPercentage = (
	value: number,
	digitsAfterDecimal = 3,
	fillTrailingZeroes = false
): string => {
	return new Intl.NumberFormat("en-US", {
		style: "percent",
		minimumFractionDigits: fillTrailingZeroes ? digitsAfterDecimal : 0,
		maximumFractionDigits: digitsAfterDecimal,
	}).format(Number.isNaN(value) ? 0 : value)
}
