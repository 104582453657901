import React from "react"

import { Box, Heading, Icon, Modal } from "@ncs/web-legos"

export interface ServiceRequestCloseSuccessModalProps {
	isOpen: boolean
	onClose: () => void
}

export const ServiceRequestCloseSuccessModal: React.FC<ServiceRequestCloseSuccessModalProps> = ({
	isOpen,
	onClose,
}) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} closeButtonText="Okay">
			<Box textAlign="center" pt={2}>
				<Icon icon="check-circle" family="solid" color="success" fontSize="2rem" />
				<Heading mt={2}>Service request successfully closed.</Heading>
			</Box>
		</Modal>
	)
}
