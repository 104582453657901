import React, { useCallback, useMemo } from "react"

import { useHistory } from "react-router-dom"
import { CellProps, Column } from "react-table"

import { CustomerEquipmentDispatch, useEquipmentDispatches } from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import { Box, Button, EmptyValueDash, encodeUrlState, Paragraph, Table } from "@ncs/web-legos"

import { ServiceRequestsUrlState, ServiceRequestTab } from "~/views/service-requests"
import { ServiceRequestsMyRequestsTabUrlState } from "~/views/service-requests/components"

export interface EquipmentServiceRequestsTabProps {
	equipmentId: string | null
}

export const EquipmentServiceRequestsTab: React.FC<EquipmentServiceRequestsTabProps> = ({
	equipmentId,
}) => {
	const history = useHistory()
	const [dispatches, loadingDispatches] = useEquipmentDispatches(equipmentId)

	const handleRowClick = useCallback(
		(dispatchId: string) => {
			history.push({
				pathname: "/service-requests",
				search: encodeUrlState<
					ServiceRequestsMyRequestsTabUrlState & ServiceRequestsUrlState
				>({
					tab: ServiceRequestTab.MyRequests,
					serviceRequestModalId: dispatchId,
				}),
			})
		},
		[history]
	)

	const columns: Column<CustomerEquipmentDispatch>[] = useMemo(
		() => [
			{
				Header: "Dispatch #",
				accessor: "dispatchNumber",
				disableSortBy: true,
			},
			{
				Header: "Open Date",
				accessor: "callReceived",
				Cell: ({ row: { original } }) => (
					<span>
						{original.callReceived ?
							formatDateTime(original.callReceived)
						:	<EmptyValueDash />}
					</span>
				),
			},
			{
				Header: "Closed Date",
				accessor: "closedDate",
				Cell: ({ row: { original } }) => (
					<span>
						{original.closedDate ?
							formatDateTime(original.closedDate)
						:	<EmptyValueDash />}
					</span>
				),
			},
			{
				Header: "",
				id: "dispatchLink",
				Cell: ({ row: { original } }: CellProps<CustomerEquipmentDispatch>) => (
					<Box textAlign="right">
						<Button
							onClick={() => handleRowClick(original.id)}
							icon="link"
							variant="table"
						>
							Go to service request
						</Button>
					</Box>
				),
				maxWidth: 20,
			},
		],
		[handleRowClick]
	)

	return (
		<>
			<Paragraph mb={1}>Service requests that have involved this equipment:</Paragraph>

			<Table
				data={dispatches ?? []}
				isLoading={loadingDispatches}
				columns={columns}
				loadingText="Loading service requests..."
				noDataText="No service requests found"
				onRowClick={(row) => handleRowClick(row.original.id)}
			/>
		</>
	)
}
