import { FC, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { isValidPhoneNumber } from "libphonenumber-js"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { makeApiErrorMessage, useUpdateVendorContactInfo, useVendor } from "@ncs/ncs-api"

import { useToast } from "../../contexts/toast"
import { useChangeCallback } from "../../util"
import { PhoneInputFormField, TextInputFormField } from "../react-hook-form"
import { ExtendableModalProps, Modal } from "./Modal"

export interface VendorEditModalProps extends ExtendableModalProps {
	vendorId: string | null
}

const UpdateVendorFormSchema = z.object({
	contactName: z.string().nullable(),
	email: z.string().email().nullable(),
	website: z.string().nullable(),
	phone: z
		.string()
		.nullable()
		.refine((value) => !value || isValidPhoneNumber(value, "US"), "Not a valid phone number"),
})
type UpdateVendorForm = z.infer<typeof UpdateVendorFormSchema>

const defaultVendorManagementUpdates: UpdateVendorForm = {
	contactName: null,
	email: null,
	website: null,
	phone: null,
}

export const VendorEditModal: FC<VendorEditModalProps> = ({ vendorId, onClose, ...rest }) => {
	const [errorText, setErrorText] = useState<string | null>(null)
	const [isSaving, setIsSaving] = useState(false)
	const [vendor] = useVendor(vendorId)
	const updateVendor = useUpdateVendorContactInfo()
	const { makeSuccessToast } = useToast()

	const {
		control,
		reset,
		handleSubmit,
		formState: { isValid, submitCount },
	} = useForm({
		mode: "onSubmit",
		resolver: zodResolver(UpdateVendorFormSchema),
		defaultValues: defaultVendorManagementUpdates,
	})

	/* When a new full vendor comes in, initialize our updates accordingly */
	useChangeCallback(vendor, (newVendorToEdit) => {
		reset({
			contactName: newVendorToEdit?.contactInfo?.contactName ?? null,
			email: newVendorToEdit?.contactInfo?.email ?? null,
			website: newVendorToEdit?.contactInfo?.website ?? null,
			phone: newVendorToEdit?.phone ?? null,
		})
	})

	const onSave = async (formData: UpdateVendorForm) => {
		if (vendor) {
			try {
				setIsSaving(true)
				await updateVendor({
					updates: {
						...formData,
						vendorId: vendor.id,
					},
				})
				makeSuccessToast("Supplier updated")
				handleClose()
			} catch (e) {
				setErrorText(makeApiErrorMessage(e))
				setIsSaving(false)
			}
		}
	}

	const handleClose = () => {
		setIsSaving(false)
		setErrorText(null)
		reset()
		onClose()
	}

	return (
		<>
			<Modal
				title="Edit Supplier"
				onClose={handleClose}
				rightButtons={{
					buttonText: "Save",
					onClick: handleSubmit(onSave),
					isLoading: isSaving,
					disabled: !isValid && submitCount > 0,
				}}
				errorText={errorText}
				{...rest}
			>
				<TextInputFormField
					control={control}
					name="contactName"
					label="Contact name"
					maxLength={255}
				/>
				<PhoneInputFormField control={control} name="phone" emptyValueFallback={null} />
				<TextInputFormField control={control} name="email" label="Email" maxLength={255} />
				<TextInputFormField
					control={control}
					name="website"
					label="Website"
					maxLength={255}
				/>
			</Modal>
		</>
	)
}
