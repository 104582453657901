import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { makeApiErrorMessage, SquareCardBrand, useAddCard } from "@ncs/ncs-api"
import {
	Divider,
	ExtendableModalProps,
	GridContainer,
	GridItem,
	Heading,
	Modal,
	SquareCardForm,
	StateSelectorFormField,
	TextInputFormField,
	useSquareCardContext,
	useToast,
} from "@ncs/web-legos"

export interface NewCardModalProps extends ExtendableModalProps {}

const NewCardContactFormSchema = z.object({
	givenName: z.string().min(1, "Required"),
	familyName: z.string().min(1, "Required"),
	email: z.string().nullable(),
	organization: z.string().nullable(),
	line2: z.string().min(1, "Required"),
	city: z.string().min(1, "Required"),
	state: z.string().min(1, "Required"),
	postalCode: z.string().min(1, "Required"),
})
export type NewCardContactForm = z.infer<typeof NewCardContactFormSchema>

export const NewCardModal: React.FC<NewCardModalProps> = ({ ...rest }) => {
	const { makeSuccessToast } = useToast()
	const { card, verifyBuyer } = useSquareCardContext()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const addCard = useAddCard()

	const {
		control,
		handleSubmit,
		formState: { isValid, submitCount },
	} = useForm<NewCardContactForm>({
		resolver: zodResolver(NewCardContactFormSchema),
		defaultValues: {
			givenName: "",
			familyName: "",
			email: null,
			organization: null,
			line2: "",
			city: "",
			state: "",
			postalCode: "",
		},
	})

	const onSubmit = async (formData: NewCardContactForm) => {
		try {
			if (!card) throw new Error("Square card instance not found.")

			setIsSubmitting(true)

			const tokenResult = await card.tokenize()

			if (tokenResult.status === "OK" && tokenResult.token) {
				const verificationResult = await verifyBuyer(tokenResult.token, {
					intent: "STORE",
					billingContact: {
						familyName: formData.familyName,
						givenName: formData.givenName,
						email: formData.email ?? undefined,
						countryCode: "US",
						city: formData.city,
						addressLines: ["", formData.line2],
						postalCode: formData.postalCode,
					},
				})
				if (!verificationResult?.token) throw new Error("Verification token failed.")

				const cardDetails = tokenResult.details?.card

				if (!cardDetails) {
					throw new Error("Card details not found in Square response")
				}

				await addCard({
					provider: "square",
					nonce: tokenResult.token,
					verificationToken: verificationResult.token,
					card: {
						brand: cardDetails.brand as unknown as SquareCardBrand,
						last4: cardDetails.last4,
						expMonth: cardDetails.expMonth,
						expYear: cardDetails.expYear,
						digitalWalletType: "NONE",
					},
					contact: {
						givenName: formData.givenName,
						familyName: formData.familyName,
						email: formData.email ?? "",
						address: {
							organization: formData.organization ?? "",
							line1: "",
							line2: formData.line2,
							city: formData.city,
							state: formData.state,
							postalCode: formData.postalCode,
							country: "US",
						},
					},
				})
				makeSuccessToast("Card successfully added")
				rest.onClose()
			} else {
				throw new Error("Unable to process card")
			}
		} catch (e) {
			setErrorText(makeApiErrorMessage(e))
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<Modal
			{...rest}
			title="Add New Card"
			rightButtons={{
				buttonText: "Save",
				onClick: handleSubmit(onSubmit),
				disabled: submitCount > 0 && !isValid,
				isLoading: isSubmitting,
			}}
			errorText={errorText}
		>
			<Heading variant="h5" bold mb={1}>
				Credit/Debit Card Information
			</Heading>
			<SquareCardForm />

			<Divider />

			<Heading variant="h5" bold mb={1}>
				Cardholder Name &amp; Address
			</Heading>
			<GridContainer rowGap={0}>
				<GridItem xs={12} sm={6}>
					<TextInputFormField control={control} name="givenName" label="First name" />
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField control={control} name="familyName" label="Last name" />
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="email"
						label="Email address (optional)"
						placeholder="Email address"
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="organization"
						label="Organization (optional)"
						placeholder="Organization"
						emptyValueFallback=""
					/>
				</GridItem>
				<GridItem xs={12}>
					<TextInputFormField control={control} name="line2" label="Address" />
				</GridItem>
				<GridItem xs={12} sm={4}>
					<TextInputFormField control={control} name="city" label="City" />
				</GridItem>
				<GridItem xs={6} sm={4}>
					<StateSelectorFormField control={control} name="state" label="State" />
				</GridItem>
				<GridItem xs={6} sm={4}>
					<TextInputFormField control={control} name="postalCode" label="Postal code" />
				</GridItem>
			</GridContainer>
		</Modal>
	)
}
