import React, { Dispatch, MouseEvent, ReactElement, SetStateAction } from "react"

import { Popover } from "@material-ui/core"
import { Column } from "react-table"

import { Button } from "../buttons"
import { CheckboxGroup } from "../inputs"
import { Box } from "../layout"
import { Paragraph } from "../typography"

export interface ColumnChooserProps<RowType extends object> {
	allColumns: Column<RowType>[]
	columnVisibility: Record<string, boolean | undefined>
	setColumnVisibility: Dispatch<SetStateAction<Record<string, boolean | undefined>>>
}

export const ColumnChooser = <RowType extends object>({
	allColumns,
	columnVisibility,
	setColumnVisibility,
}: ColumnChooserProps<RowType>): ReactElement => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

	const toggleColumnVisibility = (column: Column<RowType>) => {
		setColumnVisibility((prev) => ({
			...prev,
			[String(column.Header)]: !prev[String(column.Header)],
		}))
	}

	const resetColumnVisibility = () => {
		setColumnVisibility({
			...Object.fromEntries(
				allColumns.map((c) => [String(c.Header || c.id), !c.hiddenByDefault])
			),
		})
	}

	const onClose = () => {
		setAnchorEl(null)
	}

	return (
		<>
			<Button
				onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
				icon="table"
			>
				Columns
			</Button>

			<Popover open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
				<Box py={0.5} px={1}>
					<Paragraph my={0.5}>Columns:</Paragraph>
					<CheckboxGroup
						mr={2}
						groupName="table-columns"
						// Our list should not include any columns where toggling is disabled, or if the Header is an empty string,
						// then we assume it's not a column you can toggle, ie, it has a button or a checkbox in it.
						rows={allColumns.filter((c) => !c.disableToggle && !!c.Header)}
						valueAccessor="Header"
						labelAccessor="Header"
						checkedAccessor={(row) => !!columnVisibility[String(row.Header)]}
						onChange={toggleColumnVisibility}
					/>

					<Button onClick={resetColumnVisibility} containerProps={{ mt: 1, mb: 1 }}>
						Reset columns
					</Button>
				</Box>
			</Popover>
		</>
	)
}
