import { FC } from "react"

import { css } from "@emotion/react"

import { useAuth } from "@ncs/ncs-api"
import { Box, Icon, Link, Paragraph } from "@ncs/web-legos"

export const ShopBarUserWelcome: FC = () => {
	const auth = useAuth()

	if (!auth.user) {
		return null
	}

	return (
		<Link to="/account" css={userWelcomeStyle} underline={false}>
			<Box display="flex" alignItems="center" columnGap={0.75}>
				<Icon icon="user" family="solid" color="white" fontSize={1.35} />
				<Box display="flex" flexDirection="column" flexShrink={0}>
					<span>Welcome,</span>
					<Paragraph bold small color="white">
						{auth.user.name}
					</Paragraph>
				</Box>
			</Box>
		</Link>
	)
}

const userWelcomeStyle = css`
	flex-shrink: 0;
	span {
		font-size: 0.8rem;
		color: white;
		font-weight: normal;
		margin-bottom: -0.15rem;
	}
`
