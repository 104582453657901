import React from "react"

import { useHistory } from "react-router-dom"

import { Box, Icon, Modal, Paragraph } from "@ncs/web-legos"

export interface PasswordResetSuccessModalProps {
	isOpen: boolean
}

export const PasswordResetSuccessModal: React.FC<PasswordResetSuccessModalProps> = ({
	isOpen,
}) => {
	const history = useHistory()

	const handleClose = () => {
		history.push("/")
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title="Success"
			rightButtons={{
				buttonText: "Done",
				onClick: handleClose,
			}}
		>
			<Box display="flex" alignItems="center">
				<Icon icon="check-circle" color="success" />
				<Paragraph ml={0.5}>New password set</Paragraph>
			</Box>
		</Modal>
	)
}
