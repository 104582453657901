import { DeepPartial } from "@ncs/ts-utils"

import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { DispatchMinimal } from "../dispatches"

export enum ConnectivityUrlPaths {
	Aggregate = "aggregate",
	C12y = "c12y",
	Charts = "charts",
	Chemicals = "chemicals",
	Config = "config",
	Connectivity = "connectivity",
	Container = "container",
	Counts = "counts",
	Customer = "customer",
	Device = "device",
	Devices = "devices",
	Events = "events",
	FourDigitCode = "four_digit_code",
	Machine = "machine",
	MachineStatus = "machine_status",
	Message = "message",
	Package = "package",
	Registration = "registration",
	Startup = "startup",
	TwoDigitCode = "two_digit_code",
	Update = "update",
	V1 = "v1",
	V2 = "v2",
	Vacuum = "vacuum",
	Vacuums = "vacuums",
	WashCount = "wash_count",
	WashCounters = "wash_counters",
	Weather = "weather",
	WorkOrders = "work_orders",
}

export interface WeatherSnapshot {
	temperature: number
	description: string
	icon: string
	main: string
	windSpeed: number
}

export enum DeviceStatusCategory {
	Error = "error",
	Warning = "warning",
	Normal = "normal",
	Offline = "offline",
	Unknown = "unknown",
}

export enum VacuumEventType {
	Fault = "Fault",
	Warning = "Warning",
	PressureWarning = "PressureWarning",
	VibrationWarning = "VibrationWarning",
}

export enum VacutechStatus {
	Running = "Running",
	Ready = "Ready",
	Warning = "Warning",
	Error = "Error",
	Unknown = "Unknown",
}

/* MACHINES - INTERNAL */

export enum MachineCodeType {
	FourDigit = 1,
	TwoDigit,
	Ifsf,
	Sequence,
}

export interface ConnectedDevicesAtSite {
	customer: {
		id: string
		number: string
		name: string
		address1: string
		address2: string
		city: string
		state: string
		zip: string
	}
	devices: ConnectedDevice[]
	sortingPriority: number
}

export interface ConnectedMachinesAtSiteQueryParams {
	customer: string | null
	territory: string | null
	organization: string | null
	equipment: string | null
	status: string | null
	device: string | null
	search: string | null
	user: string | null
	isActive: boolean
}

// Right now these are identical.
export type ConnectedWashCountersAtSiteQueryParams = ConnectedMachinesAtSiteQueryParams
export type ConnectedVacuumsAtSiteQueryParams = ConnectedMachinesAtSiteQueryParams

export interface ConnectedDevice {
	id: string
	deviceId: string | null // Can be empty string.
	deviceTypeId: number
	equipment: {
		id: string
		serialNumber: string | null
		vintage: string | null
		installDate: string | null
	} | null
	lastCommunication: string | null
	name: string | null
	state: DeviceStatusCategory
	isActive: boolean
}

export interface FourDigitMachineCode {
	id: string
	countString: string
	eventDate: string
	eventOn: string | null // Don't use this in the UI.
	eventTime: string
	fourDigitCode: MachineCodeMinimal | null
	sequence: MachineCodeMinimal | null
	twoDigitCode: MachineCodeMinimal | null
	washCount: number
	hourlyWeather: WeatherSnapshot | null
}

export interface FourDigitMachineCodesQueryParams extends SearchQueryParams, OrderingQueryParams {
	startDate: string | null
	endDate: string | null
	codePriority: string | null
}

export interface TwoDigitMachineCode {
	id: string
	eventEndOn: string | null
	eventStartOn: string | null
	twoDigitCode: MachineCodeMinimal | null
}

export interface TwoDigitMachineCodesQueryParams extends SearchQueryParams, OrderingQueryParams {
	startOnAfter: string | null
	startOnBefore: string | null
	active: boolean | null
}

export enum MachineCodePriority {
	Severe = 1,
	Error,
	Warning,
	Info,
}

export interface MachineCodeMinimal {
	id: string | null
	codeType: MachineCodeType | null
	createdOn: string | null
	definition: string | null // longer
	description: string | null // short
	isActive: boolean | null
	machineCode: string
	modifiedOn: string | null
	potentialCause: string | null // longest
	suggestedPriority: MachineCodePriority | null
}

export interface ConnectedMachineWashCounts {
	counts: Record<string, number>
	doublePass: number | null
	eventDate: string
	singlePass: number | null
	total: number
	weather: WeatherSnapshot | null
}

export interface ConnectedMachineWashCountsQueryParams {
	startDate: string | null
	endDate: string | null
}

export interface ConnectedDeviceWorkOrder {
	openDate: string
	closedDate: string | null
	technician: string | null
	washCount: number | null
	comments: string | null
	dispatchId: string
}

export interface ConnectedDeviceWorkOrdersQueryParams {
	device: string | null
	startDate: string | null
	endDate: string | null
}

export interface UpdateConnectedMachinePatch {
	deviceId: string
	isActive: boolean
	name: string | null
	customerId: string | number
	customerEquipmentId: string | number | null
}

/* VACUUMS - INTERNAL */

export interface VacuumDevice {
	id: string
	accessories: string
	customerId: string
	customerName: string
	customerNumber: string
	deviceInfo: string
	driveSwVersion: string
	frame: string
	lastCommunicated: string | null
	vacSwVersion: string
	status: VacutechStatus | null
}

export interface VacuumMessageHistory {
	id: string
	collectedOn: string
	device: string // Device ID
	message: string | null
	outputCurrent1: string
	outputCurrent2: string | null
	outputFrequency1: number
	outputFrequency2: number | null
	outputPower1: number
	outputPower2: number | null
	p1: string
	p2: string | null
	refFrequency1: number
	refFrequency2: number | null
	status: VacutechStatus | null
	unfoundDevice: null
	vib1: string
	vib2: string | null
}

export interface VacuumMessagesQueryParams extends OrderingQueryParams, SearchQueryParams {
	device: string | null
	startDate: string | null
	endDate: string | null
}

export interface VacuumEventHistory {
	id: string
	endedOn: string | null
	eventCode: string | null
	eventId: string
	eventType: VacuumEventType
	message: string | null
	params: Record<string, string | number> | null
	startedOn: string | null
	status: VacutechStatus | null
	weather: null
}

export interface VacuumEventsQueryParams extends OrderingQueryParams, SearchQueryParams {
	device: string | null
	startDate: string | null
	endDate: string | null
	eventType: string | null
}

export interface VacuumStartupHistory {
	id: string
	collectedOn: string
	device: string // Device ID
	drive1Accessories: string
	drive1Frame: string
	drive1MaxHeatsinkFanSpeed: string
	drive1MaxIgbtUTemp: string
	drive1MaxIgbtVTemp: string
	drive1MaxIgbtWTemp: string
	drive1MaxInternalAirTemp: number
	drive1MaxMotorOverloadStatus: string | null
	drive1MaxOutputCurrent: number
	drive1MaxOutputPower: number
	drive1MaxRectifierTemp: string
	drive1SwVersion: string
	drive1TimeEnabled: string
	drive1TimePowered: number
	vacSwVersion: string
}

export interface VacuumStartupsQueryParams extends OrderingQueryParams {
	device: string | null
	startDate: string | null
	endDate: string | null
}

export interface VacuumConfig {
	id: string
	lastUpdated: string | null
	driveOne: {
		address: number
		type: string
		p1: string | null
		p2: string | null
		vib1: string | null
		vib2: string | null
	}
	driveTwo: {
		address: number | null
		type: string | null
		p1: string | null
		p2: string | null
		vib1: string | null
		vib2: string | null
	}
	serial: {
		stopBits: string
		baudRate: number
		parity: string
	}
	filterPressureHigh: number
	pressureHighInterval: number
	vib1High: number
	dataInterval: number

	// Aren't currently present, but sounds like James wants them to be.
	// Make nullable until they start coming back from server.
	vib2High?: number | null
	vibHighInternal?: number | null
}

export interface VacuumConfigQueryParams {
	device: string | null
}

export type VacuumConfigPatch = DeepPartial<VacuumConfig>

/* CUSTOMER PORTAL TYPES */
/**
 * @deprecated
 */
export interface CustomerDeviceStatusSummary {
	id: string
	lastCommunication: string | null
	status: DeviceStatusCategory
}

/**
 * @deprecated
 */
export interface ConnectedSite {
	customer: {
		id: number
		address1: string | null
		address2: string | null
		city: string
		customerNumber: string
		latitude: number | null
		longitude: number | null
		name: string
		postalCode: string
		state: string
	}
	machines: CustomerDeviceStatusSummary[]
	chemicals: CustomerDeviceStatusSummary[]
	vacuums: CustomerDeviceStatusSummary[]
	priority: number
}

export interface ConnectedSiteQueryParams {
	customer: string | null
	device: string | null
}

/* MACHINES - CUSTOMER PORTAL */

/**
 * @deprecated
 * status is incorrect
 */
export interface CustomerConnectedMachine {
	id: string
	installedDate: string | null
	machineLocation: string | null
	model: string
	openDispatches: DispatchMinimal[]
	serial: string
	status: CustomerDeviceStatusSummary
	vintage: string

	// Used to be there. Maybe we add back?
	// washesToday: number
	// washesTotal: number
}

// Used to be `MachineEventLevel`.
export enum CustomerMachineEventLevel {
	Warning = "warning",
	Error = "error",
}

export interface CustomerMachineEvent {
	id: string
	code: string
	sequence: string
	washCount: number | null
	timestamp: string
	description: string | null
	weather: WeatherSnapshot | null
}

export interface CustomerMachineEventQueryParams {
	level: CustomerMachineEventLevel | null
	device: string | null
}

export interface CustomerPackageWashCounts {
	description: string | null
	today: number | null
	week: number | null
	month: number | null
	allTime: number | null
}

/* VACUUMS - CUSTOMER PORTAL */

export interface CustomerVacuumCharts {
	id: string
	driveOneRpm: number | null
	driveTwoRpm: number | null
	filterPressureValue: number | null
	filterPressureWarning: number | null
	name: string | null
	systemPressure: number | null
}

export interface CustomerVacuumChartsQueryParams {
	device: string | null
	customer: string | null
}

export interface CustomerVacuumEvent {
	id: string
	weather: WeatherSnapshot
	eventType: VacuumEventType
	message: string | null
	startedOn: string
	endedOn: string | null
	status: VacutechStatus
}

export interface CustomerVacuumEventQueryParams extends SearchQueryParams, OrderingQueryParams {
	startDate: string | null
	endDate: string | null
	device: string | null
	eventType: string | null
}

export interface VacuumSystemGauge {
	lowError: string
	lowWarning: string
	normal: string
	highWarning: string
	highError: string
	maxValue: string
}

export interface VacuumFilterGauge {
	minPressure: string
	low: string
	maxPressure: string
}

/* CHEMICALS - CUSTOMER PORTAL */

export interface ConnectedChemical {
	id: string
	productId: string
	productName: string | null
	iLevelId: string | null
	iLevelName: string | null
	containerId: string | null
	containerName: string | null
	containerNumber: number | null
	containerMultiplier: number | null
	maxLevel: number
	minLevel: number
	currentLevel: number
	status: CustomerDeviceStatusSummary
}

/* ALERTING - CUSTOMER PORTAL */

export enum ConnectivityAlertCategory {
	Machines = "Machines",
	Chemicals = "Chemicals",
	Vacuums = "Vacuums",
	ServiceRequests = "Service Requests",
	Orders = "Orders",
}

export interface ConnectivityAlert {
	id: number
	category: ConnectivityAlertCategory
	description: string
}

export interface ConnectivityAlertContact {
	id: string
	name: string
	email: string
	phone: string
	alerts: {
		// Arrays of alert IDs
		email: string[]
		sms: string[]
		push: string[]
	}
}

export interface ConnectedMachineDetails {
	lastUpdatedOn: string | null
	// These are IFSF states.
	activeStates: {
		startOn: string
		machineCode: string
		description: string
		suggestedPriority?: MachineCodePriority
	}[]
}

export interface ChemicalCustomer {
	chemicalCount: number
	customerId: string
	customerName: string
	customerNumber: string
}

export interface ChemicalCustomerQueryParams {
	customer: string | null
	part: string | null
	territory: string | null
}

export interface CreateContainerChemicalPost {
	id: string // Customer ID, will be removed from body and added to URL.
	containerId: string | null
	expectedMlUsage: number
	isActive: boolean
	lowLevelGallons: number
	name: string
	partId: string
	maxBackStockQuantity: number
	pumpSetting: string | null
	tipType: string | null

	// If we're intending to create a new container, we must pass these.
	newContainerName: string | undefined
	newContainerFillableHeight: number | undefined
	newContainerMlPerMm: number | undefined
	newContainerGallons: number | null | undefined
}

export interface ChemicalCustomerDetail {
	customerId: string
	customerName: string
	customerNumber: string
	containers: {
		id: string // ID of the ContainerChemical, not of its related Container
		containerFillableHeight: number
		containerGallons: number | null
		containerMlPerMm: number
		containerName: string
		expectedMlUsage: number | null
		isActive: boolean
		lowLevelGallons: number
		maxBackStockQuantity: number | null
		name: string | null
		partDescription: string
		partId: string
		partNumber: string
		pumpSetting: string | null
		tipType: string | null
	}[]
	packages: {
		id: string
		chemicals: {
			id: string // PackageChemical ID
			containerId: string // ContainerChemical ID
			containerName: string | null // ContainerChemical name
			partDescription: string
			partNumber: string
			passes: number
		}[]
		isActive: boolean
		name: string
		number: number
		price: number | null
	}[]
}

export interface ChemicalContainerDetail {
	id: string
	containerFillableHeight: number
	containerMlPerMm: number
	containerName: string
	containerSize: number | null
	customerId: string
	customerName: string
	customerNumber: string
	expectedMlUsage: number | null
	history: {
		amountBackstocked: number
		createdOn: string
		creator: string
		gallonsInContainer: number | null
		pumpSetting: string
		tip: string | null
		titrationDrops: number
	}[]
	isActive: boolean
	lowLevelGallons: number
	maxBackStockQuantity: number | null
	partDescription: string
	partId: string
	partNumber: string
	pumpSetting: string | null
	tipType: string | null
}

export type UpdateContainerChemicalPatch = Partial<{
	containerId: string // The Container related to this ContainerChemical.
	expectedMlUsage: number
	isActive: boolean
	lowLevelGallons: number
	maxBackStockQuantity: number
	name: string
	partId: string
	pumpSetting: string | null
	tipType: string | null
}>

export interface CreateChemicalPackagePost {
	name: string
	customerId: string
	isActive: boolean
	number: number
	price: number | null
	chemicals: {
		containerId: string
		passes: number
	}[]
}

export type UpdateChemicalPackagePatch = Partial<CreateChemicalPackagePost>

export interface CustomerWeather {
	description: string
	icon: string
	main: string
	temperature: number
	timestamp: string
	windSpeed: number
}

export interface CustomerWeatherQueryParams {
	startDate: string | null
	endDate: string | null
}

export enum ConnectivityDeviceTypeId {
	Machine = "1",
	Chemical = "2",
	Vacuum = "3",
	WashCount = "4",
}

export interface DeviceRegistrationPost {
	customerEquipment: string
	deviceType: string
	deviceInfo: string
}

export interface DeviceRegistrationResults {
	deviceCreated: boolean
	deviceValid: boolean
	serverConnection: boolean
	deviceOnline: boolean
	error: string | null
}

export interface WashCounterWashCounts {
	id: number
	deviceId: string | null
	eventOn: string
	total: number
	washPackageOne: number
	washPackageTwo: number
	washPackageThree: number
	washPackageFour: number
	washPackageFive: number
	washPackageSix: number
}

export interface WashCounterWashCountsQueryParams {
	startDate: string | null
	endDate: string | null
}
