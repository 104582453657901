import { FC, useEffect, useState } from "react"

import { useThrottle } from "@react-hook/throttle"

import { usePrevious } from "../../util"
import { TextInput, TextInputProps } from "./TextInput"

export interface ThrottledTextInputProps extends Omit<TextInputProps, "value" | "onChange"> {
	value: string | null
	onChange: (newValue: string | null) => void
	/**
	 * Throttle changes per second
	 * @default 3
	 */
	fps?: number
	/**
	 * Leading throttle change
	 * @default true
	 */
	leading?: boolean
}

/**
 * Like regular `TextInput`, except we use a local state proxy and the `onChange` prop is throttled.
 */
export const ThrottledTextInput: FC<ThrottledTextInputProps> = ({
	value: valueToThrottle,
	onChange: onChangeToThrottle,
	fps = 3,
	leading = true,
	...rest
}) => {
	const [localValue, setLocalValue] = useState<string | null>(valueToThrottle)
	const [throttledValue, setThrottledValue] = useThrottle(localValue, fps, leading)

	// Whenever local value changes, fire the throttled value updater. You can call setThrottledValue
	// as fast as you want and it'll only go at FPS.
	useEffect(() => {
		setThrottledValue(localValue)
	}, [localValue, setThrottledValue])

	// When throttled value changes, fire the passed in onChange.
	const prevThrottledValue = usePrevious(throttledValue)
	useEffect(() => {
		if (throttledValue !== prevThrottledValue) {
			onChangeToThrottle(throttledValue)
		}
	}, [onChangeToThrottle, throttledValue, prevThrottledValue])

	// The parent can't normally drive the value, but if they set it to null
	// we should respect that.
	const prevValueToThrottle = usePrevious(valueToThrottle)
	useEffect(() => {
		if (prevValueToThrottle !== null && valueToThrottle === null && localValue !== null) {
			setLocalValue(null)
		}
	}, [localValue, valueToThrottle, prevValueToThrottle])

	return <TextInput {...rest} value={localValue} onChange={setLocalValue} />
}
