import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/minimal"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { makeApiErrorMessage, StripeBankAccountType, useAddBankAccount } from "@ncs/ncs-api"
import {
	ErrorText,
	GridContainer,
	GridItem,
	Information,
	Modal,
	Paragraph,
	SelectFormField,
	TextInputFormField,
} from "@ncs/web-legos"

export interface NewBankAccountModalProps {
	isOpen: boolean
	onClose: () => void
}

const bankAccountTypeOptions = [
	{
		value: StripeBankAccountType.INDIVIDUAL,
		text: "Individual",
	},
	{
		value: StripeBankAccountType.COMPANY,
		text: "Company",
	},
]

const BankAccountFormSchema = z.object({
	accountType: z.string().nonempty("Required"),
	accountName: z.string().nonempty("Required"),
	routingNumber: z
		.string()
		.nonempty("Required")
		.regex(/^\d{9}$/, "Invalid routing number"),
	accountNumber: z
		.string()
		.nonempty("Required")
		.regex(/^\d{5,20}$/, "Invalid account number"),
})
export type BankAccountForm = z.infer<typeof BankAccountFormSchema>

export const NewBankAccountModal: React.FC<NewBankAccountModalProps> = ({ isOpen, onClose }) => {
	const [submissionErrorMessage, setSubmissionErrorMessage] = useState("")
	const [isSubmitting, setIsSubmitting] = useState(false)
	const {
		control,
		handleSubmit,
		reset,
		formState: { isValid, submitCount },
	} = useForm<BankAccountForm>({
		resolver: zodResolver(BankAccountFormSchema),
		defaultValues: {
			accountType: StripeBankAccountType.INDIVIDUAL,
		},
	})

	const addBankAccount = useAddBankAccount()

	const onSubmit = async (data: BankAccountForm) => {
		try {
			setSubmissionErrorMessage("")
			setIsSubmitting(true)
			await addBankAccount({
				// Take the form data and add some additional things that we don't need to ask the user for.
				...data,
				provider: "stripe",
				currency: "USD",
				country: "US",
			})
			handleClose()
		} catch (e) {
			setSubmissionErrorMessage(makeApiErrorMessage(e))
			captureException(e)
			console.error(e)
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleClose = () => {
		reset()
		setSubmissionErrorMessage("")
		onClose()
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title="Add New Bank Account"
			rightButtons={{
				buttonText: "Save",
				onClick: handleSubmit(onSubmit),
				isLoading: isSubmitting,
				disabled: submitCount > 0 && !isValid,
			}}
		>
			<GridContainer rowGap={0}>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="accountName"
						label="Account holder name"
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<SelectFormField
						control={control}
						name="accountType"
						options={bankAccountTypeOptions}
						showNoSelectionOption={false}
						label="Account holder type"
						fillContainer
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="routingNumber"
						label="Routing number"
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<TextInputFormField
						control={control}
						name="accountNumber"
						label="Account number"
					/>
				</GridItem>
			</GridContainer>

			<Paragraph color="secondary" small my={1}>
				Please note that only bank accounts in the United States are accepted at this time.
			</Paragraph>

			<Information icon="lock" iconFamily="solid">
				Secured with Stripe
			</Information>

			{!!submissionErrorMessage && (
				<ErrorText textAlign="right">{submissionErrorMessage}</ErrorText>
			)}
		</Modal>
	)
}
