import React, { useMemo } from "react"

import { useTheme } from "@emotion/react"
import {
	CartesianGrid,
	Legend,
	Line,
	LineChart as RechartLineChart,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts"
import { BaseAxisProps, DataKey as RechartDataKey } from "recharts/types/util/types"

import { arrayWrap } from "@ncs/ts-utils"

import { ResponsiveChartContainer } from "./ResponsiveChartContainer"

type DataKey<Item> = Extract<keyof Item, string> | ((dataObj: Item) => string | number)

export interface LineChartOldProps<Item> {
	data: Item[]
	lineKeys: DataKey<Item> | DataKey<Item>[]
	xDataKey?: DataKey<Item>
	xTickFormatter?: BaseAxisProps["tickFormatter"]
	yDataKey?: DataKey<Item>
	yTickFormatter?: BaseAxisProps["tickFormatter"]
	withLegend?: boolean
	withTooltip?: boolean
}

export const LineChartOld = <Item,>({
	data,
	lineKeys,
	xDataKey,
	xTickFormatter,
	yTickFormatter,
	withLegend,
	withTooltip,
}: LineChartOldProps<Item>): React.ReactElement => {
	const theme = useTheme()

	const keys = useMemo(() => arrayWrap(lineKeys), [lineKeys])

	return (
		<ResponsiveChartContainer>
			<RechartLineChart data={data} barSize={20}>
				<XAxis dataKey={xDataKey as RechartDataKey<Item>} tickFormatter={xTickFormatter} />
				<YAxis tickFormatter={yTickFormatter} />

				<CartesianGrid stroke={theme.palette.grey[200]} />

				{withLegend && (
					<Legend
						align="right"
						layout="vertical"
						wrapperStyle={{
							paddingLeft: "2rem",
							paddingBottom: "1.5rem",
						}}
					/>
				)}

				{withTooltip && (
					<Tooltip
						formatter={yTickFormatter}
						labelFormatter={xTickFormatter as (value: string) => string}
					/>
				)}

				{keys.map((barKey, i) => (
					<Line
						type="monotone"
						key={`${barKey}}`}
						dataKey={barKey as RechartDataKey<Item>}
						fill={theme.palette.chartColors[i]}
						connectNulls
					/>
				))}
			</RechartLineChart>
		</ResponsiveChartContainer>
	)
}
