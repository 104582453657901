import React, { MouseEvent, useMemo } from "react"

import { css, useTheme } from "@emotion/react"
import { IconButton as MuiIconButton, lighten } from "@material-ui/core"

import { Box } from "../layout"
import { LoadingSpinner } from "../transitions"
import { Icon, IconProps } from "../typography"

export interface IconButtonProps extends Omit<IconProps, "icon"> {
	icon?: IconProps["icon"]
	onClick: (e: MouseEvent<HTMLButtonElement>) => void
	size?: "sm" | "md" | "lg"
	title?: string
	isLoading?: boolean
	disabled?: boolean
	background?: "primary"
	stopPropagation?: boolean
	className?: string
}

export const IconButton: React.FC<IconButtonProps> = ({
	onClick,
	size = "md",
	title,
	icon = "pencil",
	color = "primary",
	family,
	isLoading,
	disabled,
	background,
	stopPropagation,
	className,
	...rest
}) => {
	const { palette } = useTheme()
	const dimension =
		size === "sm" ? "1rem"
		: size === "md" ? "2.15rem"
		: "5rem"
	const fontSize =
		size === "sm" ? "0.75rem"
		: size === "md" ? "1rem"
		: "2.5rem"

	const muiIconButtonStyle = useMemo(
		() => css`
			height: ${dimension};
			width: ${dimension};
			background: ${background === "primary" ?
				lighten(palette.primary.main, 0.93)
			:	undefined};
		`,
		[dimension, background, palette.primary.main]
	)
	const fontSizeStyle = useMemo(
		() => css`
			font-size: ${fontSize};
		`,
		[fontSize]
	)

	return (
		<MuiIconButton
			title={title}
			className={className}
			css={muiIconButtonStyle}
			disabled={isLoading || disabled}
			onClick={(e) => {
				if (stopPropagation) {
					e.stopPropagation()
				}
				onClick(e)
			}}
		>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				opacity={
					isLoading ? 0.75
					: disabled ?
						0.5
					:	undefined
				}
			>
				{isLoading ?
					<Box position="absolute">
						<LoadingSpinner fontSize={fontSize} />
					</Box>
				:	<Icon
						{...rest}
						color={color}
						icon={icon}
						family={family}
						fixedWidth
						css={fontSizeStyle}
					/>
				}
			</Box>
		</MuiIconButton>
	)
}
