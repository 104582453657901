import React from "react"

import { css, Theme } from "@emotion/react"

import { useAuth } from "@ncs/ncs-api"
import { Box, Icon, Link, useScreenSizeMatch } from "@ncs/web-legos"

import { ContentWrapper } from "../ContentWrapper"
import { ShopBarCartSummary } from "./ShopBarCartSummary"
import { ShopBarNav } from "./ShopBarNav"
import { ShopBarSearch } from "./ShopBarSearch"
import { ShopBarUserWelcome } from "./ShopBarUserWelcome"

export const ShopBar: React.FC = () => {
	const auth = useAuth()
	const screenIsTiny = useScreenSizeMatch("xs")
	const screenIsMediumUp = useScreenSizeMatch("md", "up")

	return (
		<div css={outerContainerStyle}>
			{!!auth.user && (
				<ContentWrapper>
					<Box display="flex" alignItems="center">
						{!screenIsTiny && (
							<Box display="flex" alignItems="center" mr={0.75} mt={0.25}>
								<Link to="/shop/search">
									<Icon icon="wrench" family="solid" color="white" />
								</Link>
							</Box>
						)}
						<ShopBarSearch />
						{screenIsMediumUp && (
							<>
								{/* Ugly, but use a box to throw in a bit of padding */}
								<Box p={1} />
								<ShopBarUserWelcome />
							</>
						)}
					</Box>

					<Box display="flex" alignItems="center" pt={!screenIsMediumUp ? 0.5 : 0}>
						{screenIsMediumUp ?
							<ShopBarNav />
						:	<ShopBarUserWelcome />}
						<ShopBarCartSummary />
					</Box>
				</ContentWrapper>
			)}
		</div>
	)
}

const outerContainerStyle = (theme: Theme) => css`
	background: ${theme.palette.primary.main};
	padding-top: 0.75rem;
	margin-top: 1rem;
	box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
`
