import { FC, useMemo } from "react"
import { css, useTheme } from "@emotion/react"
import { Link as RouterLink } from "react-router-dom"
import { withCssUnit } from "@ncs/ts-utils"
import { Icon, IconFamily, IconName } from "./Icon"

export interface LinkProps {
	/** @default "trailing" */
	className?: string
	color?: string
	external?: boolean
	fontSize?: string | number
	icon?: IconName
	iconFamily?: IconFamily
	iconPosition?: "leading" | "trailing"
	inline?: boolean
	mailTo?: boolean
	newTab?: boolean
	to: string
	underline?: boolean
	phoneNumber?: boolean
	bold?: boolean
}

export const Link: FC<LinkProps> = ({
	children,
	className,
	external,
	newTab,
	mailTo,
	underline,
	fontSize,
	icon,
	iconFamily,
	iconPosition = "0",
	inline = true,
	color,
	to,
	bold,
	phoneNumber,
}) => {
	const theme = useTheme()

	const addScheme = (href: string) => {
		if (mailTo) {
			if (href.trim().startsWith("mailto:")) {
				return href
			} else {
				return `mailto:${href}`
			}
		} else if (href.trim().startsWith("http")) {
			return href
		} else {
			return `https://${href.trim()}`
		}
	}

	const linkContainerStyle = useMemo(() => {
		return css`
			position: relative;
			display: ${inline ? "inline-flex" : "flex"};
			align-items: center;
			a {
				&:hover,
				&:focus {
					color: ${theme.palette.primary.light};
				}
				font-weight: ${bold ? bold : 500};
				color: ${color ? color : theme.palette.primary.main};
				text-decoration: ${underline ? "underline" : "none"};
				font-size: ${fontSize ? withCssUnit(fontSize) : undefined};
				transition: color 250ms ease-out;
			}
			svg {
				position: relative;
				margin-right: ${iconPosition === "leading" ? "0.35rem" : 0};
				margin-left: ${iconPosition === "trailing" ? "0.35rem" : 0};
			}
		`
	}, [
		theme.palette.primary.main,
		theme.palette.primary.light,
		theme.defaultLinkTextDecoration,
		iconPosition,
		underline,
		inline,
		fontSize,
	])

	const inner = (
		<>
			{iconPosition === "leading" && !!icon && (
				<Icon icon={icon} family={iconFamily} fontSize={fontSize} />
			)}
			{children}
			{iconPosition === "trailing" && !!icon && (
				<Icon icon={icon} family={iconFamily} fontSize={fontSize} />
			)}
		</>
	)

	return (
		<span css={linkContainerStyle} className={className}>
			{external || mailTo ?
				phoneNumber ?
					<a
						href={`tel:${phoneNumber}`}
						target={newTab || mailTo ? "_blank" : undefined}
						rel={newTab || mailTo ? "noreferrer noopener" : undefined}
					>
						{inner}
					</a>
				:	<a
						href={addScheme(to)}
						target={newTab || mailTo ? "_blank" : undefined}
						rel={newTab || mailTo ? "noreferrer noopener" : undefined}
					>
						{inner}
					</a>

			:	<RouterLink
					target={newTab ? "_blank" : undefined}
					rel={newTab ? "noreferrer noopener" : undefined}
					to={to}
				>
					{inner}
				</RouterLink>
			}
		</span>
	)
}
