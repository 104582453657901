import { FC, useMemo, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { isCanadianProvince } from "@ncs/ts-utils"

import { UspsAddress, uspsAddressVerify, TrackingEvent, trackEvent } from "../../util"
import { Button } from "../buttons"
import { GridContainer, GridItem } from "../layout"
import { StateSelectorFormField, TextInputFormField } from "../react-hook-form"
import { AnimatedEntrance } from "../transitions"
import { Callout, LabeledData, Paragraph, ParagraphList } from "../typography"
import { ExtendableModalProps, Modal } from "./Modal"

export interface AddressFormModalProps extends ExtendableModalProps {
	/** Function fire with the address that's been verified. */
	onSave: (address: UspsAddress) => void
	/** Pass in preset values that user cannot edit. */
	hardCodedFields?: Partial<Record<keyof AddressForm, string>>
	/**
	 * Should we show the Canadian provinces?
	 * @default true
	 */
	allowCanada?: boolean
}

const AddressFormSchema = z.object({
	name: z.string().min(1, "Required"),
	address2: z.string().min(1, "Required"),
	address1: z.string().nullable(),
	city: z.string().min(1, "Required"),
	state: z.string().min(2, "Required"),
	zip: z.string().min(1, "Required"),
})

export type AddressForm = z.infer<typeof AddressFormSchema>

export const AddressFormModal: FC<AddressFormModalProps> = ({
	onSave,
	hardCodedFields,
	allowCanada = true,
	...rest
}) => {
	const { control, handleSubmit, reset, watch, getValues } = useForm<AddressForm>({
		resolver: zodResolver(AddressFormSchema),
		defaultValues: {
			name: hardCodedFields?.name,
			address2: hardCodedFields?.address2,
			address1: hardCodedFields?.address1 ?? null,
			city: hardCodedFields?.city,
			state: hardCodedFields?.state,
			zip: hardCodedFields?.zip,
		},
	})

	const [isVerifying, setIsVerifying] = useState(false)
	const [errorText, setErrorText] = useState<string | null>(null)
	const [verifiedAddress, setVerifiedAddress] = useState<UspsAddress | null>(null)

	const handlePrimaryActionClick = () => {
		if (isCanada) {
			const values = getValues()
			onUseAddress({
				...values,
				address1: values.address1 ?? undefined,
			})
			trackEvent(TrackingEvent.USE_THIS_ADDRES)
		} else if (verifiedAddress) {
			onUseAddress(verifiedAddress)
			trackEvent(TrackingEvent.USE_THIS_ADDRES)
		} else {
			void handleSubmit(onVerifyAddress)()
			trackEvent(TrackingEvent.VERIFY_ADDRESS)
		}
	}

	const onVerifyAddress = async (formData: AddressForm) => {
		const unverified: UspsAddress = {
			name: formData.name,
			address2: formData.address2,
			address1: formData.address1 ?? undefined,
			city: formData.city,
			state: formData.state,
			zip: formData.zip,
		}

		try {
			setErrorText(null)
			setIsVerifying(true)
			const verified = await uspsAddressVerify(unverified)
			setVerifiedAddress(verified)
		} catch (e) {
			setErrorText(`USPS validation error: ${e}`)
		} finally {
			setIsVerifying(false)
		}
	}

	const onOpen = () => {
		reset()
		setIsVerifying(false)
		setErrorText(null)
		setVerifiedAddress(null)
	}

	const onUseAddress = (address: UspsAddress) => {
		onSave(address)
		rest.onClose()
	}

	const state = watch("state")

	const isCanada = useMemo(() => {
		return isCanadianProvince(state)
	}, [state])

	return (
		<Modal
			onOpen={onOpen}
			title="Enter Shipping Address"
			closeButtonText="Cancel"
			rightButtons={{
				buttonText: verifiedAddress || isCanada ? "Use This Address" : "Verify Address",
				variant: verifiedAddress || isCanada ? "primary-cta" : "secondary-cta",
				isLoading: isVerifying,
				onClick: handlePrimaryActionClick,
			}}
			{...rest}
			errorText={errorText}
		>
			{verifiedAddress ?
				<AnimatedEntrance show direction="down">
					<Paragraph>
						Based on what you entered, the USPS address validation service has
						suggested the following:
					</Paragraph>
					<Callout my={1}>
						<ParagraphList
							lines={[
								verifiedAddress.name,
								verifiedAddress.address2,
								verifiedAddress.address1,
								`${verifiedAddress.city}, ${verifiedAddress.state}`,
								verifiedAddress.zip,
							]}
						/>
					</Callout>
					<div>
						<Button icon="long-arrow-left" onClick={() => setVerifiedAddress(null)}>
							Change
						</Button>
					</div>
				</AnimatedEntrance>
			:	<GridContainer rowGap={0}>
					<GridItem xs={12}>
						{hardCodedFields?.name ?
							<LabeledData label="Name">{hardCodedFields.name}</LabeledData>
						:	<TextInputFormField control={control} name="name" emptyValueFallback="" />
						}
					</GridItem>
					<GridItem xs={12} sm={8}>
						{hardCodedFields?.address2 ?
							<LabeledData label="Address">{hardCodedFields.address2}</LabeledData>
						:	<TextInputFormField
								control={control}
								name="address2"
								label="Address"
								emptyValueFallback=""
							/>
						}
					</GridItem>
					<GridItem xs={12} sm={4}>
						{hardCodedFields?.address1 ?
							<LabeledData label="Attn, suite, etc">
								{hardCodedFields.address1}
							</LabeledData>
						:	<TextInputFormField
								control={control}
								name="address1"
								label="Attn, suite, etc"
							/>
						}
					</GridItem>
					<GridItem xs={12} sm={4}>
						{hardCodedFields?.city ?
							<LabeledData label="City">{hardCodedFields.city}</LabeledData>
						:	<TextInputFormField control={control} name="city" emptyValueFallback="" />
						}
					</GridItem>
					<GridItem xs={12} sm={4}>
						{hardCodedFields?.state ?
							<LabeledData label="State">{hardCodedFields.state}</LabeledData>
						:	<StateSelectorFormField
								control={control}
								name="state"
								allowCanada={allowCanada}
							/>
						}
					</GridItem>
					<GridItem xs={12} sm={4}>
						{hardCodedFields?.zip ?
							<LabeledData label="Zip code">{hardCodedFields.zip}</LabeledData>
						:	<TextInputFormField
								control={control}
								name="zip"
								label="Postal code"
								emptyValueFallback=""
							/>
						}
					</GridItem>
				</GridContainer>
			}

			{isCanada && (
				<Callout variant="info" icon="canadian-maple-leaf" iconFamily="brands" mt={1}>
					<strong>Note: </strong> We're currently unable to validate Canadian addresses
					for accuracy ahead of time. We ask that you double check your address
					information before submitting to prevent shipping errors. Thanks!
				</Callout>
			)}
		</Modal>
	)
}
