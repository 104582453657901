import { FC, ReactNode, useState } from "react"

import { Box } from "../layout"
import { Icon, IconName, IconProps, Paragraph } from "../typography"
import { Modal } from "./Modal"

export interface ConfirmationModalProps {
	config: ConfirmationModalConfig | null
	setConfig: (newConfig: ConfirmationModalConfig | null) => void
}

export interface ConfirmationModalConfig {
	message: string | ReactNode
	onConfirm: () => void | Promise<void>
	title?: string
	bigIcon?: IconName
	bigIconColor?: IconProps["color"]
	confirmButtonText?: string
	confirmButtonLoading?: boolean
	confirmButtonIcon?: IconName
}

/**
 * Does handle its own loading state, but does not catch errors.
 */
export const ConfirmationModal: FC<ConfirmationModalProps> = ({ config, setConfig }) => {
	const [isLoading, setIsLoading] = useState(false)

	const handleConfirm = async () => {
		// Don't catch an error here, so that the implementing component can do what it wants.
		if (config?.onConfirm) {
			try {
				setIsLoading(true)
				await config.onConfirm()
			} finally {
				setIsLoading(false)
				setConfig(null)
			}
		} else {
			setConfig(null)
		}
	}

	return (
		<Modal
			isOpen={!!config}
			title={config?.title ?? "Please Confirm"}
			onClose={() => setConfig(null)}
			closeButtonText="Cancel"
			rightButtons={{
				buttonText: config?.confirmButtonText ?? "Yes",
				icon: config?.confirmButtonIcon,
				onClick: handleConfirm,
				isLoading: isLoading,
			}}
		>
			<Box display="flex" flexDirection="column" gap={1}>
				{!!config?.bigIcon && (
					<Icon
						icon={config.bigIcon}
						color={config.bigIconColor || "gray"}
						fontSize={2}
					/>
				)}
				<Paragraph>{config?.message}</Paragraph>
			</Box>
		</Modal>
	)
}
