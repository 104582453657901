import React, { FC, useMemo } from "react"

import { canadianProvinces, usStates } from "@ncs/ts-utils"

import { ExtendableSelectProps, Select } from "../inputs"

export interface StateSelectorProps extends ExtendableSelectProps<(typeof usStates)[number]> {
	allowCanada?: boolean
}

export const StateSelector: FC<StateSelectorProps> = ({
	value: valueProp,
	allowCanada = true,
	...rest
}) => {
	const options = useMemo(() => {
		return allowCanada ? [...usStates, ...canadianProvinces] : usStates
	}, [allowCanada])

	// What might come back in existing forms is sketchy. Like they might pass
	// "Iowa", they might pass in "IA", or something totally random like "**".
	// Do our best to make sense of it.
	const value: string | null = useMemo(() => {
		if (!valueProp) {
			return null
		}

		const match =
			options.find((option) => valueProp.toUpperCase() === option.abbreviation) ??
			options.find((option) => option.name.toLowerCase() === valueProp.toLowerCase())

		return match?.abbreviation ?? null
	}, [options, valueProp])

	return (
		<Select
			label={allowCanada ? "State / Province" : "State"}
			disableNoSelectionOption={false}
			{...rest}
			value={value}
			options={options}
			valueAccessor="abbreviation"
			textAccessor="name"
		/>
	)
}
