import React, { useMemo } from "react"

import { css } from "@emotion/react"

import { Box, BoxProps } from "./Box"

export interface GridContainerProps extends Omit<BoxProps, "display"> {
	/** Should the cells fill in from right to left?  */
	fillRightToLeft?: boolean
	/** Probably just leave this at 12, but you can change it if you want. */
	totalColumnCount?: number
}

export const GridContainer: React.FC<GridContainerProps> = ({
	totalColumnCount = 12,
	gap = 1,
	fillRightToLeft,
	children,
	...rest
}) => {
	const directionStyle = useMemo(() => {
		if (fillRightToLeft) {
			return css`
				// Fill cells right to left.
				direction: rtl;
				> * {
					// But all their children should behave normally.
					direction: ltr;
				}
			`
		}

		return null
	}, [fillRightToLeft])

	const baseStyle = useMemo(() => {
		return css`
			display: grid;
			grid-template-columns: repeat(${totalColumnCount}, minmax(0, 1fr));
		`
	}, [totalColumnCount])

	return (
		<Box css={[directionStyle, baseStyle]} gap={gap} {...rest}>
			{children}
		</Box>
	)
}
