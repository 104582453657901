import { FC, useMemo, useState } from "react"

import { Column } from "react-table"

import { AdditionalUser, useAuth, useCustomerAdditionalUsers } from "@ncs/ncs-api"
import {
	formatDateTime,
	getTimezoneAbbreviation,
	noNullish,
	tryToFormatPhone,
} from "@ncs/ts-utils"
import {
	ConfirmationModal,
	ConfirmationModalConfig,
	Heading,
	LoadingSpinner,
	Paragraph,
	SearchQueryFilter,
	Table,
	TableProps,
} from "@ncs/web-legos"

import { EditAdditionalUserModal } from "./EditAdditionalUser"

export const AdditionalUsers: FC = () => {
	const { user } = useAuth()
	const [showCreateUser, setShowCreateUser] = useState(false)
	const [filterState, setFilterState] = useState<{
		search: string | null
	}>({
		search: null,
	})
	const [editModalUser, setEditModalUser] = useState<AdditionalUser | null>(null)
	const [confirmationConfig, setConfirmationConfig] = useState<ConfirmationModalConfig | null>(
		null
	)

	const [users, usersLoading] = useCustomerAdditionalUsers()

	const preparedUsers = useMemo(() => {
		if (!user?.id) return []
		const searchChunks = (filterState.search?.toUpperCase() || "").split(" ")

		return (users ?? []).filter((u) => {
			const notCurrentUser = u.id.toString() !== user.id

			const searchable = noNullish([u.firstName, u.lastName, u.email, u.mobile])
				.join("")
				.toUpperCase()

			return (
				notCurrentUser &&
				(!searchChunks.length || searchChunks.every((chunk) => searchable.includes(chunk)))
			)
		})
	}, [users, user?.id, filterState.search])

	const rowMenu = useMemo((): TableProps<AdditionalUser>["rowMenu"] => {
		return [
			{
				label: "Edit",
				iconName: "pencil",
				onClick: ({ original }) => setEditModalUser(original),
			},
		]
	}, [])

	return (
		<>
			<Heading variant="h4" bold mb={1.5}>
				Additional Users
			</Heading>

			<Paragraph maxWidth={40} mb={2}>
				Add or create logins for additional users that can be associated with the sites of
				your choice. Users will be able to see the same information and perform the same
				actions that you can.
			</Paragraph>

			{usersLoading ?
				<LoadingSpinner />
			:	<Table
					data={preparedUsers}
					columns={columns}
					noDataText={
						filterState.search ?
							`No users match "${filterState.search}"`
						:	"No additional users set up"
					}
					onRowClick={({ original }) => setEditModalUser(original)}
					defaultSort={defaultSort}
					rowMenu={rowMenu}
					leftButtons={[
						{
							buttonText: "Add User",
							icon: "user-plus",
							variant: "secondary-cta",
							onClick: () => setShowCreateUser(true),
						},
					]}
					queryParamState={filterState}
					setQueryParamState={setFilterState}
					toggledQueryFilters={[SearchQueryFilter]}
				/>
			}

			{(showCreateUser || editModalUser) && (
				<EditAdditionalUserModal
					user={editModalUser}
					onClose={() => {
						setEditModalUser(null)
						setShowCreateUser(false)
					}}
				/>
			)}
			<ConfirmationModal config={confirmationConfig} setConfig={setConfirmationConfig} />
		</>
	)
}

const columns: Column<AdditionalUser>[] = [
	{
		Header: "First name",
		accessor: ({ firstName }) => firstName,
	},
	{
		Header: "Last name",
		accessor: ({ lastName }) => lastName,
	},
	{
		Header: "Email",
		accessor: ({ email }) => email,
	},
	{
		Header: "Phone",
		accessor: ({ mobile }) => tryToFormatPhone(mobile) || "-",
	},
	{
		Header: `Last login (${getTimezoneAbbreviation()})`,
		accessor: ({ lastLogin }) => (lastLogin ? formatDateTime(lastLogin) : "-"),
	},
]

const defaultSort = [
	{
		id: "Last name",
	},
]
