import { FC, useMemo } from "react"

import { css } from "@emotion/react"
import { Divider } from "@material-ui/core"

import { Heading, HeadingProps, Paragraph } from "../typography"
import { Box, BoxProps } from "./Box"

export interface HeadingDividerProps extends BoxProps {
	headingText?: string
	detailText?: string
	headingVariant?: HeadingProps["variant"]
	/**
	 * Because I often go back and forth between using `Heading` and `HeadingDivider` during
	 * development, I wish that I had just made the variant prop the same as it is on
	 * `Heading`.
	 */
	variant?: HeadingProps["variant"]
	bold?: HeadingProps["bold"]
	uppercase?: HeadingProps["uppercase"]
	icon?: HeadingProps["icon"]
	iconFamily?: HeadingProps["iconFamily"]
	/** Arbitrary content to show opposite the heading  */
	renderRight?: () => React.ReactNode
	backgroundColor?: string
}

export const HeadingDivider: FC<HeadingDividerProps> = ({
	headingText,
	headingVariant = "h2",
	variant,
	bold,
	uppercase,
	detailText,
	icon,
	iconFamily,
	renderRight,
	backgroundColor = "white",
	children,
	...rest
}) => {
	const headingStyle = useMemo(() => {
		return css`
			position: relative;
			display: inline-block;
			background: ${backgroundColor};
			font-weight: bold;
			padding-right: 0.75rem;
			line-height: 1.2;
		`
	}, [backgroundColor])

	return (
		<Box my={1.5} {...rest}>
			<div css={innerContainerStyle}>
				<Heading
					variant={variant || headingVariant}
					bold={bold}
					uppercase={uppercase}
					css={headingStyle}
					icon={icon}
					iconFamily={iconFamily}
				>
					{children}
					{headingText}
				</Heading>
				<Divider css={dividerStyle} light />
				{!!renderRight && <div css={renderRightContainerStyle}>{renderRight()}</div>}
			</div>
			{detailText && (
				<Paragraph color="secondary" mt={0.35} small>
					{detailText}
				</Paragraph>
			)}
		</Box>
	)
}

const innerContainerStyle = css`
	display: flex;
	align-items: baseline;
	position: relative;
	z-index: 1;
`
const dividerStyle = css`
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: -1;
`
const renderRightContainerStyle = css`
	margin-left: auto;
	background: white;
	padding-left: 0.75rem;
`
