import React from "react"

import {
	Redirect,
	Route as RouterRoute,
	RouteProps as RouterProps,
	useLocation,
} from "react-router-dom"

import { useAuth } from "@ncs/ncs-api"
import { arrayWrap } from "@ncs/ts-utils"
import { encodeUrlState } from "@ncs/web-legos"

import { LoginUrlState } from "~/views/auth/login"

interface RouteProps extends Omit<RouterProps, "exact" | "component"> {
	path: string
	component: Exclude<RouterProps["component"], undefined>
	allowAnonymous?: boolean
	redirectIfAuthenticated?: boolean | string
	fuzzy?: boolean
	exact?: boolean
	/** If the authed user's ID is in this list, it will redirect to the homepage.  */
	redirectIds?: string | string[]
}

export const Route: React.FC<RouteProps> = ({
	path,
	component,
	allowAnonymous = false,
	redirectIfAuthenticated,
	fuzzy = false,
	redirectIds,
	...rest
}) => {
	const { user, initialized } = useAuth()
	const { pathname, search } = useLocation()

	const redirect = `${pathname}${search}`

	if (!initialized) {
		return null
	}

	const redirectIdsList = arrayWrap(redirectIds ?? [])

	if (!!user && redirectIdsList.includes(user.id)) {
		return <Redirect to="/" />
	}

	if (!!redirectIfAuthenticated && !!user) {
		return (
			<Redirect
				to={typeof redirectIfAuthenticated === "string" ? redirectIfAuthenticated : "/"}
			/>
		)
	}

	if (allowAnonymous) {
		return <RouterRoute path={path} component={component} exact={!fuzzy} {...rest} />
	}

	return (
		<RouterRoute
			path={path}
			exact={!fuzzy}
			{...rest}
			render={(props) => {
				return user !== null ?
						React.createElement(component, props)
					:	<Redirect to={"/auth/login" + encodeUrlState<LoginUrlState>({ redirect })} />
			}}
		/>
	)
}
