import React, { useState } from "react"

import { useAuth, useConnectedMachinesAtSites } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	Divider,
	encodeUrlState,
	GridContainer,
	GridItem,
	Link,
	LoadingSpinner,
	Paragraph,
} from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"
import { c12yAutoRefreshConfig } from "~/util"
import { AccountTabName, AccountUrlState } from "~/views/account/Account"

import { ConnectedSiteSort, ConnectivitySiteCard, LocalSiteSort } from "./components"

export const ConnectedSites: React.FC = () => {
	const { user } = useAuth()
	const [sort, setSort] = useState<LocalSiteSort>(LocalSiteSort.Priority)
	const [machinesAtSites, machinesLoading] = useConnectedMachinesAtSites({
		params: { user: user?.id },
		queryConfig: {
			...c12yAutoRefreshConfig,
			enabled: !!user,
		},
	})

	return (
		<PageContentWrapper
			heading="Connectivity Sites"
			breadcrumbs={[{ name: "Connectivity" }]}
			renderRight={() => (
				<Box display="flex" alignItems="center">
					<Box mr={3}>
						<Link
							to={`/account/${encodeUrlState<AccountUrlState>({
								tab: AccountTabName.Alerts,
							})}`}
							icon="bell-on"
						>
							Configure alerts
						</Link>
					</Box>
					<ConnectedSiteSort value={sort} onChange={setSort} />
				</Box>
			)}
		>
			<Divider mt={-1} mb={2} />

			{machinesLoading && <LoadingSpinner text="Loading your connected sites..." />}

			{!machinesLoading && !machinesAtSites?.length && (
				<Paragraph secondary>No connected sites found</Paragraph>
			)}

			<GridContainer gap={2} smProps={{ gap: 1 }}>
				{(machinesAtSites ?? [])
					.sort((a, b) => (a.sortingPriority > b.sortingPriority ? -1 : 1))
					.map((site, i) => (
						<GridItem key={site.customer.id} sm={12} md={6} lg={4} alignSelf="stretch">
							<AnimatedEntrance
								show
								delay={90 * i}
								outerContainerProps={{ height: "100%" }}
								height="inherit"
							>
								<ConnectivitySiteCard site={site} />
							</AnimatedEntrance>
						</GridItem>
					))}
			</GridContainer>
		</PageContentWrapper>
	)
}
