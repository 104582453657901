export enum TrackingEvent {
	ADD_TO_CART = "ADD_TO_CART",
	ADD_TO_CART_PDP = "ADD_TO_CART_PDP",
	ADD_ITEMS_AND_CHECKOUT = "ADD_ITEMS_AND_CHECKOUT",
	ADD_ITEMS_TO_CART = "ADD_ITEMS_TO_CART",
	CARD = "CARD",
	CART_VIEW = "CART_VIEW",
	CONGRATS = "CONGRATS",
	CONTINUE_SHOPPING = "CONTINUE_SHOPPING",
	CHECKOUT_VIEW = "CHECKOUT_VIEW",
	FILTERS = "FILTERS",
	GO_TO_CART = "GO_TO_CART",
	GO_TO_PRODUCT = "GO_TO_PRODUCT",
	GROUND = "GROUND",
	HOME_VIEW = "HOME_VIEW",
	LOGIN_VIEW = "LOGIN_VIEW",
	PDP_VIEW = "PDP_VIEW",
	OVERNIGHT = "OVERNIGHT",
	PLACE_ORDER = "PLACE_ORDER",
	PROCEED_TO_CHECKOUT = "PROCEED_TO_CHECKOUT",
	QUICK_ORDER = "QUICK_ORDER",
	QUICK_ORDER_VIEW = "QUICK_ORDER_VIEW",
	SEARCH = "SEARCH",
	SEARCH_CATALOG = "SEARCH_CATALOG",
	SEARCH_VIEW = "SEARCH_VIEW",
	SHIP_HERE = "SHIP_HERE",
	SIGNIN = "SIGNIN",
	SIGNUP = "SIGNUP",
	SHIPMENT = "SHIPMENT",
	USE_THIS_ADDRES = "USE_THIS_ADDRES",
	VERIFY_ADDRESS = "VERIFY_ADDRESS",
}

const EVENTS_BY_PATH: Record<string, TrackingEvent> = {
	"/auth/login": TrackingEvent.LOGIN_VIEW,
	"/shop/checkout": TrackingEvent.CHECKOUT_VIEW,
	"/shop/parts": TrackingEvent.PDP_VIEW,
	"/shop/search": TrackingEvent.SEARCH_VIEW,
	"/shop/cart-summary": TrackingEvent.CART_VIEW,
	"/shop/quick-order": TrackingEvent.QUICK_ORDER_VIEW,
	"/shop": TrackingEvent.HOME_VIEW,
}

export const integrateAnalyticsPlatforms = () => {
	try {
		const context = getContext()
		integrateGA()
		integrateAmplitude()

		context.trackEvent = trackEvent
	} catch (e) {
		console.error("Error integratig analytics platforms: ", e)
	}
}

export const integrateGA = () => {
	const context = getContext()
	context.dataLayer = context.dataLayer || []
	context.gtag = function () {
		context.dataLayer.push(arguments)
	}

	const GAKey = import.meta.env.VITE_GA_KEY
	if (!GAKey) {
		console.error(
			"Error: VITE_GA_KEY is not defined. Please check your environment variables."
		)
		return
	}

	addAnalyticsScript(`https://www.googletagmanager.com/gtag/js?id=${GAKey}`, () => {
		context.dataLayer = context.dataLayer || []
		context.gtag("js", new Date())
		context.gtag("config", GAKey)
	})
}

export const integrateAmplitude = () => {
	const context = getContext()
	const AmplitudeKey = import.meta.env.VITE_AMPLITUDE_KEY
	if (!AmplitudeKey) {
		console.error(
			"Error: VITE_AMPLITUDE_KEY is not defined. Please check your environment variables."
		)
		return
	}
	addAnalyticsScript("https://cdn.amplitude.com/libs/analytics-browser-2.10.0-min.js.gz", () => {
		addAnalyticsScript(
			"https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.6.17-min.js.gz",
			() => {
				context.amplitude
					.add(context.sessionReplay.plugin({ sampleRate: 1 }))
					.promise.then(function () {
						context.amplitude.init(AmplitudeKey, {
							autocapture: { elementInteractions: true },
						})
					})
					.catch((e: any) => {
						console.error("Error initializing Amplitude: ", e)
					})
			}
		)
	})
}

export const trackCustomerPortalNavigation = (location: string) => {
	if (!location) return
	const normalizedLocation = location.toLowerCase()
	const path = Object.keys(EVENTS_BY_PATH).find((path) => normalizedLocation.includes(path))
	if (path) {
		trackEvent(EVENTS_BY_PATH[path])
	}
}

export const trackEvent = (event: string, details: any = {}) => {
	setTimeout(() => {
		const context = getContext()
		context?.amplitude?.logEvent(event, details)
		context?.gtag("event", event, details)
	})
}

const addAnalyticsScript = (src: string, callback: any) => {
	const script = document.createElement("script")
	script.src = src
	script.async = true
	if (callback) script.onload = callback
	script.onerror = (e) => {
		console.error(`Error loading script: ${src}`, e)
	}
	document.head.appendChild(script)
}

const getContext: any = () => window
