import React from "react"

import { Modal, Paragraph } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export interface EmptyCardModalProps {
	isOpen: boolean
	onClose: () => void
}

export const EmptyCartModal: React.FC<EmptyCardModalProps> = ({ isOpen, onClose }) => {
	const [, shopDispatch] = useShopContext()

	const onConfirm = () => {
		// There is an action for just emptying the cart, but for good measure we're going
		// to reset the entire checkout state. This will also clear out selected ship to site,
		// payment methods, shipments, etc.
		shopDispatch({
			type: "reset checkout state",
		})
		onClose()
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			rightButtons={{
				buttonText: "Empty cart",
				variant: "secondary-cta",
				onClick: onConfirm,
			}}
		>
			<Paragraph bold>Remove all items from your cart?</Paragraph>
		</Modal>
	)
}
