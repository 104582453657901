import React, { memo } from "react"

import { css } from "@emotion/react"
import { Link } from "react-router-dom"

import { isNonParentCustomerPart, useCustomerPart } from "@ncs/ncs-api"
import {
	Box,
	Card,
	CardProps,
	Heading,
	Paragraph,
	PartImage,
	Price,
	EventTracker,
	TrackingEvent,
} from "@ncs/web-legos"

export interface PartPreviewCardProps extends CardProps {
	partId: string
	title?: string
	price?: number | string
	nonDiscountedPrice?: string | number
	imageSrc?: string | null
	onError?: (id: string) => void
}

export const PartPreviewCard: React.FC<PartPreviewCardProps> = memo(
	({ partId, title, price, nonDiscountedPrice, imageSrc, onClick, onError, ...rest }) => {
		const [part, loading] = useCustomerPart(partId, {
			queryConfig: {
				onError: () => {
					if (onError) {
						onError(partId)
					}
				},
			},
		})

		return (
			<Card {...rest}>
				<Box
					display="flex"
					flexDirection="column"
					justifyContent="space-between"
					height="inherit"
				>
					<Box textAlign="center" m="0 auto 1rem auto">
						<EventTracker
							event={TrackingEvent.CARD}
							data={{ id: partId, name: part?.title }}
						>
							<Link to={`/shop/parts/${partId}`}>
								<PartImage
									src={part?.imageUrl ?? imageSrc}
									alt={part?.title ?? title}
									isLoading={loading}
									skeleton={{
										size: 150,
										containerProps: {
											height: 13,
										},
									}}
								/>
							</Link>
						</EventTracker>
					</Box>

					<div>
						<EventTracker
							event={TrackingEvent.CARD}
							data={{ id: partId, name: part?.title }}
						>
							<Link to={`/shop/parts/${partId}`} css={headingLinkStyle}>
								<Heading mb={1} css={partTitleStyle} isLoading={!title && loading}>
									{part?.title ?? title}
								</Heading>
							</Link>
						</EventTracker>
						{!!part &&
							(isNonParentCustomerPart(part) ?
								<Price
									price={part?.netPrice ?? price ?? ""}
									nonDiscountedPrice={part?.price ?? nonDiscountedPrice}
									isLoading={!price && loading}
									small
								/>
							:	<Paragraph small color="secondary">
									Multiple options available
								</Paragraph>)}
					</div>
				</Box>
			</Card>
		)
	}
)

const headingLinkStyle = css`
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`
const partTitleStyle = css`
	overflow-wrap: break-word;
`
