import React, { useState } from "react"

import { Column } from "react-table"

import {
	APPLICATION,
	CustomerDispatch,
	CustomerDispatchQueryParams,
	useCustomerDispatches,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	formatCurrency,
	formatDateTime,
	formatDuration,
	getTimezoneAbbreviation,
	maxChars,
} from "@ncs/ts-utils"
import {
	Box,
	Button,
	CallReceivedQueryFilter,
	ClosedDateQueryFilter,
	DispatchCallTypeQueryFilter,
	DispatchStatusQueryFilter,
	EmptyValueDash,
	Paragraph,
	SearchQueryFilter,
	SiteQueryFilter,
	Table,
	useUrlState,
} from "@ncs/web-legos"

import { ServiceRequestCloseSuccessModal } from "./ServiceRequestCloseSuccessModal"
import { ServiceRequestDetailModal } from "./ServiceRequestDetailModal"

const columns: Column<CustomerDispatch>[] = [
	{
		Header: "Service Request #",
		accessor: "dispatchNumber",
		disableSortBy: true,
	},
	{
		Header: "Site",
		id: "customer_Name",
		accessor: (dispatch) => dispatch.customer?.name,
	},
	{
		Header: "Call Type",
		id: "callType_Description",
		accessor: (dispatch) => dispatch.callType?.description,
	},
	{
		Header: "Description",
		id: "symptoms",
		accessor: ({ symptoms }) => maxChars(symptoms ?? "", 100),
		disableSortBy: true,
	},
	{
		Header: "Status",
		id: "status_Description",
		accessor: "status",
	},
	{
		Header: "Response Time",
		id: "responseDuration",
		accessor: (dispatch) => formatDuration(dispatch.responseDuration),
		hiddenByDefault: true,
	},
	{
		Header: "Arrival Time",
		id: "arrivalDuration",
		accessor: (dispatch) => formatDuration(dispatch.arrivalDuration),
		hiddenByDefault: true,
	},
	{
		Header: `Call Received (${getTimezoneAbbreviation()})`,
		id: "callReceived",
		accessor: (dispatch) =>
			dispatch.callReceivedDate ?
				formatDateTime(dispatch.callReceivedDate)
			:	<EmptyValueDash />,
	},
	{
		Header: `Closed Date (${getTimezoneAbbreviation()})`,
		id: "closedDate",
		accessor: (dispatch) =>
			dispatch.closedDate ? formatDateTime(dispatch.closedDate) : <EmptyValueDash />,
	},
	{
		Header: "Total",
		id: "total",
		accessor: (dispatch) => formatCurrency(dispatch.total ?? 0),
		hiddenByDefault: true,
	},
]

export type ServiceRequestsMyRequestsTabUrlState = {
	showCloseConfirmation: number | null
	serviceRequestModalId: string | null
}

export const ServiceRequestsMyRequestsTab: React.FC = () => {
	const canViewDispatches = useUserCanUse(APPLICATION.SearchDispatch)
	const canCreateDispatches = useUserCanUse(APPLICATION.CreateDispatch)
	const canUseCustomerDispatches = canViewDispatches || canCreateDispatches

	const [queryParamState, setQueryParamState] = useState(initialDispatchQueryParams)
	const dispatchQuery = useCustomerDispatches({ params: queryParamState })

	const [{ showCloseConfirmation, serviceRequestModalId }, { updateUrlValue }] =
		useUrlState<ServiceRequestsMyRequestsTabUrlState>({
			showCloseConfirmation: null,
			serviceRequestModalId: null,
		})

	if (!canUseCustomerDispatches) {
		return (
			<>
				<Paragraph color="secondary" textAlign="center" mt={3}>
					It looks like NCS Services are not set up for this account. Please talk to your
					NCS sales representative or click below for more information!
				</Paragraph>

				<Box textAlign="center" pt={3}>
					<Button
						variant="primary-cta"
						icon="external-link"
						onClick={() => {
							window.location.href = "https://www.ncswash.com/solutions/ncs-services"
						}}
					>
						Learn more
					</Button>
				</Box>
			</>
		)
	}

	return (
		<>
			<Table
				columns={columns}
				loadingText="Loading service records..."
				noDataText="No service requests found"
				onRowClick={(row) => updateUrlValue("serviceRequestModalId", row.original.id)}
				query={dispatchQuery}
				queryParamState={queryParamState}
				setQueryParamState={setQueryParamState}
				toggledQueryFilters={[
					CallReceivedQueryFilter,
					ClosedDateQueryFilter,
					SiteQueryFilter,
					DispatchStatusQueryFilter,
					DispatchCallTypeQueryFilter,
					SearchQueryFilter,
				]}
			/>

			<ServiceRequestDetailModal
				isOpen={!!serviceRequestModalId}
				onClose={() => updateUrlValue("serviceRequestModalId", null)}
				initialDispatchData={dispatchQuery.data.find(
					(d) => d.id === serviceRequestModalId
				)}
				dispatchId={serviceRequestModalId}
			/>
			<ServiceRequestCloseSuccessModal
				isOpen={!!showCloseConfirmation}
				onClose={() => updateUrlValue("showCloseConfirmation", null)}
			/>
		</>
	)
}

const initialDispatchQueryParams: CustomerDispatchQueryParams = {
	search: null,
	ordering: null,
	site: null,
	callReceivedGte: null,
	closedDateGte: null,
	statusId: null,
	callTypeId: null,
}
