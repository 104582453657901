import { useEffect, useRef } from "react"

import isEqual from "lodash/isEqual"

import { usePrevious } from "./use-previous"

export interface UseChangeCallbackOptions<Value> {
	/** You can have the callback fire once initially before any change has been detected. */
	callOnSetup: boolean
	/** Use this instead instead of Lodash's isEqual. Return false if the values are not equal. */
	compareFn: (newValue: Value, prevValue: Value) => boolean
	/** If this is true, the compare function won't fire and neither will the callback. */
	disabled: boolean
}

/**
 * Watch a value and fire a callback whenever it changes. Similar to `useEffect`, except
 * that this does a deep comparison via Lodash, so it's better suited for objects than `useEffect`,
 * which would just refire on every render.
 *
 * Note that this doesn't have a dependencies array, so it will only fire when the watch value
 * changes. If any other things that are referenced inside the callback change, it won't fire!
 */
export const useChangeCallback = <Value>(
	/** The object you want to watch for changes. */
	watchedValue: Value,
	/** Function to fire when the object changes. */
	callback: (currentValue: Value, prevValue: Value) => void | Promise<void>,
	/** Options */
	options?: Partial<UseChangeCallbackOptions<Value>>
): void => {
	const prevWatchedValue = usePrevious(watchedValue)
	const hasCalledOnSetup = useRef(false)

	useEffect(() => {
		if (!options?.disabled && options?.callOnSetup && hasCalledOnSetup.current === false) {
			void callback(watchedValue, prevWatchedValue) // In this case they'll be the same.
			hasCalledOnSetup.current = true
		}
	}, [options?.callOnSetup, callback, prevWatchedValue, watchedValue, options?.disabled])

	useEffect(() => {
		const equalityTest = options?.compareFn || isEqual

		if (!options?.disabled && !equalityTest(watchedValue, prevWatchedValue)) {
			void callback(watchedValue, prevWatchedValue)
		}
	}, [watchedValue, prevWatchedValue, callback, options?.compareFn, options?.disabled])
}
