import React from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface InvoiceTypeSelectorProps
	extends ExtendableSelectProps<(typeof invoiceTypeOptions)[number]> {}

export enum InvoiceType {
	Dispatch = "1",
	PartsOrder = "2",
	DeliveryOrder = "4",
	KbmInvoice = "5",
}

const invoiceTypeOptions = [
	{
		id: InvoiceType.Dispatch,
		description: "Dispatches",
	},
	{
		id: InvoiceType.PartsOrder,
		description: "Parts orders",
	},
	{
		id: InvoiceType.DeliveryOrder,
		description: "Delivery orders",
	},
	{
		id: InvoiceType.KbmInvoice,
		description: "KBM Invoices",
	},
]

export const InvoiceTypeSelector: React.FC<InvoiceTypeSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	return (
		<Select
			label="Invoice type"
			{...rest}
			value={value}
			onChange={onChange}
			options={invoiceTypeOptions}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}
