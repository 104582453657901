import { createContext, FC, useContext, useEffect, useReducer } from "react"

import isEqual from "lodash/isEqual"
import qs from "query-string"
import { useHistory, useLocation } from "react-router-dom"
import { z } from "zod"

import { usePrevious } from "../../util"

const ParamValueSchema = z
	.string()
	.or(z.number())
	.or(z.boolean())
	.nullable()
	.or(z.array(z.string().or(z.number()).or(z.boolean()).nullable()))
export type ParamValue = z.infer<typeof ParamValueSchema>

export interface UrlState {
	[key: string]: ParamValue
}

const defaultUrlStateContextState: UrlState = {}

type UrlStateAction =
	| {
			type: "reset"
			payload: UrlState
	  }
	| {
			type: "initialize slice"
			payload: UrlState
	  }
	| {
			type: "patch slice"
			payload: UrlState
	  }
	| {
			type: "remove slice"
			payload: string[]
	  }
	| {
			type: "update value"
			payload: {
				key: keyof UrlState
				value: ParamValue
			}
	  }

export type UrlStateDispatch = (action: UrlStateAction) => void

export const UrlStateContext = createContext<[UrlState, UrlStateDispatch] | undefined>(undefined)

UrlStateContext.displayName = "UrlStateContext"

const urlStateContextReducer = (state: UrlState, action: UrlStateAction) => {
	switch (action.type) {
		case "reset": {
			return {
				...action.payload,
			}
		}
		case "initialize slice": {
			return {
				...action.payload,
				...state,
			}
		}
		case "patch slice": {
			return {
				...state,
				...action.payload,
			}
		}
		case "remove slice": {
			const updatedState = { ...state }

			action.payload.forEach((key) => {
				delete updatedState[key]
			})

			return { ...updatedState }
		}
		case "update value": {
			return {
				...state,
				[action.payload.key]: action.payload.value,
			}
		}
	}
}

export const UrlStateProvider: FC = ({ children }) => {
	const history = useHistory()
	const location = useLocation()

	const [localState, dispatchLocalState] = useReducer(urlStateContextReducer, {
		...defaultUrlStateContextState,
		...decodeUrlState(location.search),
	})

	// Keep the URL params up-to-date as the state changes.
	const prevLocalState = usePrevious(localState)
	useEffect(() => {
		if (!isEqual(localState, prevLocalState)) {
			history.replace({
				search: encodeUrlState(localState),
				state: history.location.state, // (Preserve any current state)
			})
		}
	}, [history, localState, prevLocalState])

	// Whenever we change locations, then we should look to the URL and update what's in
	// our local state to match.
	const prevPathname = usePrevious(location.pathname)
	useEffect(() => {
		if (location.pathname !== prevPathname) {
			dispatchLocalState({
				type: "patch slice",
				payload: decodeUrlState(location.search),
			})
		}
	}, [prevPathname, location.pathname, location.search])

	return (
		<UrlStateContext.Provider value={[localState, dispatchLocalState]}>
			{children}
		</UrlStateContext.Provider>
	)
}

export const useUrlStateContext = (): [UrlState, UrlStateDispatch] => {
	const context = useContext(UrlStateContext)

	if (context === undefined) {
		throw new Error("useUrlStateContext must be used within UrlStateProvider")
	}

	return context
}

export const decodeUrlState = (searchString: string): UrlState =>
	Object.fromEntries(
		Object.entries(qs.parse(searchString, { arrayFormat: "none" })).flatMap(([key, value]) => {
			try {
				// value should never been an array because we did arrayFormat none above.

				// Potential gotcha! If a non-JSON encoded string that's just 0-9 is decoded, it will be
				// returned as a number here.
				return typeof value === "string" ? [[key, decodeUrlValue(value)]] : []
			} catch (e) {
				// JSON parse barfed when we tried to decode it. That just means it wasn't encoded
				// when it it got added to the URL, probably because we got directed here from the
				// portal. We'll take our best guess at what it should be and return that.

				if (!value) {
					console.info(
						`Could not JSON parse param key "${key}". Guessing that it should be null.`
					)
					return [[key, null]]
				}

				// Just telling TS that it's not an array.
				if (Array.isArray(value)) return []

				// If it has a comma, we'll assume it's a comma-separated string representing an array.
				if (value.includes(",")) {
					console.info(
						`Could not JSON parse param key "${key}". Guessing that it should be an array.`
					)
					return [[key, value.split(",")]]
				}

				if (value === "true" || value === "True") {
					console.info(
						`Could not JSON parse param key "${key}". Guessing that it should be boolean.`
					)
					return [[key, true]]
				}

				if (value === "false" || value === "False") {
					console.info(
						`Could not JSON parse param key "${key}". Guessing that it should be boolean.`
					)
					return [[key, false]]
				}

				// It's probably just a string.
				console.info(
					`Could not JSON parse param key "${key}". Guessing that it should be string.`
				)
				return [[key, value]]
			}
		})
	)

export const decodeUrlValue = <State extends UrlState>(value: string): State[typeof value] => {
	const decoded = JSON.parse(decodeURIComponent(value))

	if (!ParamValueSchema.safeParse(decoded).success) {
		console.warn(
			"Decoded a value type from the URL that UrlState did not expect. Strings, numbers, booleans, nulls, and arrays are supported. Proceed with caution..."
		)
	}

	return decoded
}

const encodeUrlValue = <Value,>(value: Value): string => {
	if (!ParamValueSchema.safeParse(value).success) {
		console.warn(
			"Encoded a value type into the URL that UrlState did not expect. Strings, numbers, booleans, nulls, and arrays are supported. Proceed with caution..."
		)
	}

	return encodeURIComponent(JSON.stringify(value))
}

/**
 * Takes an object and returns it encoded in the proper string for the `useUrlState` hook. Does start with "?"
 * if there's anything to add.
 */
export const encodeUrlState = <State extends UrlState>(
	state: Partial<State> | null | undefined
): string => {
	if (!state) return ""

	// This takes the object and turns all its properties into JSON encoded strings.
	// We're assuming that you don't want nullish, empty strings, or empty arrays to be included.
	const obj = Object.fromEntries(
		Object.entries(state).flatMap(([key, value]) => {
			return (
					value != null &&
						(!Array.isArray(value) || value.length > 0) &&
						(typeof value !== "string" || value.length > 0)
				) ?
					[[key, encodeUrlValue(value)]]
				:	[]
		})
	)

	// Now take that object and make it a string.
	const objString = qs.stringify(obj)

	return objString.length ? `?${objString}` : ""
}
