import axios from "axios"

import { refreshAccessToken } from "../portal-apps"
import {
	applyAuthTokenInterceptor,
	AuthTokenInterceptorConfig,
	TokenRefreshRequest,
} from "./axios-jwt"

let _baseUrl: string | null = null
const _authFailureCallbacks: AuthTokenInterceptorConfig["authFailureCallbacks"] = {
	onFailedRefresh: null,
	onNoRefreshToken: null,
}

const defaultAxiosConfig = {
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
}

export const apiClient = axios.create(defaultAxiosConfig)
export const anonymousApiClient = axios.create(defaultAxiosConfig)

export const setBaseUrl = (baseUrl: string): void => {
	if (baseUrl?.length === 0) {
		throw new Error("baseUrl is required")
	}

	_baseUrl = baseUrl

	apiClient.defaults.baseURL = baseUrl
	anonymousApiClient.defaults.baseURL = baseUrl
}

export const buildUrl = (url: string): string => {
	if (url.length === 0) {
		throw new Error("url path is required")
	}

	if (!_baseUrl) {
		throw new Error("baseUrl is not set, call setBaseUrl() first")
	}

	if (_baseUrl.endsWith("/")) {
		if (url.startsWith("/")) {
			url = url.substr(1, url.length)
		}
	} else if (!url.startsWith("/")) {
		url = "/" + url
	}

	return _baseUrl + url
}

export const setAuthFailureCallbacks = (
	handlers: AuthTokenInterceptorConfig["authFailureCallbacks"]
): void => {
	_authFailureCallbacks.onFailedRefresh = handlers?.onFailedRefresh ?? null
	_authFailureCallbacks.onNoRefreshToken = handlers?.onNoRefreshToken ?? null
}

const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<string> => {
	// use a non-intercepted axios instance
	const response = await refreshAccessToken(refreshToken)
	return response.access
}

applyAuthTokenInterceptor(apiClient, {
	requestRefresh,
	authFailureCallbacks: _authFailureCallbacks,
})
