import React, { useState } from "react"

import { DateFormat, getTimeAgoStartDate, TimeAgo } from "@ncs/ts-utils"

import { TimeAgoSelector } from "../selectors"

interface ClosedDateQueryParamState {
	closedDateGte: string | null
}

export interface ClosedDateQueryFilterProps<QueryParamState extends ClosedDateQueryParamState> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
}

export const ClosedDateQueryFilter = <QueryParamState extends ClosedDateQueryParamState>({
	queryParamState,
	setQueryParamState,
}: ClosedDateQueryFilterProps<QueryParamState>): React.ReactElement => {
	// We need to keep the state of the selected option here locally, because
	// what we push up to query param state is different.
	const [timeAgo, setTimeAgo] = useState<TimeAgo | null>(null)

	const handleChange = (newValue: TimeAgo | null) => {
		// Set local state.
		setTimeAgo(newValue)

		// Set the query param state accordingly.
		if (!newValue) {
			setQueryParamState((prev) => ({
				...prev,
				closedDateGte: null,
			}))
		} else {
			setQueryParamState((prev) => ({
				...prev,
				closedDateGte: getTimeAgoStartDate(newValue).format(DateFormat.DateQueryParam),
			}))
		}
	}

	// We need to listen for the reset.
	if (!queryParamState.closedDateGte && !queryParamState.closedDateGte && timeAgo) {
		setTimeAgo(null)
	}

	return (
		<TimeAgoSelector
			label="Closed date"
			value={timeAgo}
			onChange={handleChange}
			fillContainer
		/>
	)
}
