/**
 * If a number is passed in, returns that number with `rem` appended to it.
 * If a string is passed in, returns the string unmodified.
 */
export const withCssUnit = (
	value: string | number | undefined,
	cssUnit = "rem"
): string | undefined => {
	return typeof value === "number" ? `${value}${cssUnit}` : value
}
