import {
	ApiGetQuery,
	ApiInfiniteGetQuery,
	ApiInfiniteGetQueryOptions,
	ApiPostMutation,
	DataExport,
	useDataExport,
	useGetRequest,
	useInfiniteGetRequest,
	usePostRequest,
} from "../../request-hooks"
import {
	CustomerInvoice,
	CustomerInvoiceDocument,
	CustomerInvoicePost,
	CustomerInvoiceQueryParams,
	CustomerInvoiceSummary,
	InvoiceSource,
	InvoiceSourceQueryParams,
	InvoiceUrlPaths,
	PaymentIntent,
	PrintInvoicesPost,
} from "./types"

export const useCustomerInvoice = (invoiceId?: string | null): ApiGetQuery<CustomerInvoice> => {
	return useGetRequest<CustomerInvoice>(
		[InvoiceUrlPaths.Invoice, InvoiceUrlPaths.CustomerInvoices, invoiceId ?? ""],
		{
			queryConfig: {
				enabled: !!invoiceId,
			},
		}
	)
}

export const initialCustomerInvoiceQueryParams: CustomerInvoiceQueryParams = {
	search: null,
	ordering: null,
	site: null,
	type: null,
	paymentDue: null,
	partiallyPaid: null,
	paidInFull: null,
	startDate: null,
	endDate: null,
}

export const useCustomerInvoices = (
	options?: ApiInfiniteGetQueryOptions<CustomerInvoice, CustomerInvoiceQueryParams>
): ApiInfiniteGetQuery<CustomerInvoice> => {
	return useInfiniteGetRequest<CustomerInvoice>(
		[InvoiceUrlPaths.Invoice, InvoiceUrlPaths.CustomerInvoices],
		options
	)
}

export const useCustomerInvoicesSummary = (
	options?: ApiInfiniteGetQueryOptions<CustomerInvoiceSummary, CustomerInvoiceQueryParams>
): ApiInfiniteGetQuery<CustomerInvoiceSummary> => {
	return useInfiniteGetRequest<CustomerInvoiceSummary>(
		[InvoiceUrlPaths.Invoice, InvoiceUrlPaths.CustomerInvoicesSummary],
		options
	)
}

export const useCustomerInvoiceDocument = (
	invoiceId: string | null
): ApiGetQuery<CustomerInvoiceDocument> => {
	return useGetRequest<CustomerInvoiceDocument>(
		[
			InvoiceUrlPaths.Invoice,
			InvoiceUrlPaths.CustomerInvoices,
			invoiceId ?? "",
			InvoiceUrlPaths.Documents,
		],
		{
			queryConfig: {
				enabled: !!invoiceId,
			},
		}
	)
}

export const usePayCustomerInvoice = (
	invoiceId: string | null
): ApiPostMutation<CustomerInvoicePost, PaymentIntent> => {
	return usePostRequest<CustomerInvoicePost, PaymentIntent>(
		[
			InvoiceUrlPaths.Invoice,
			InvoiceUrlPaths.CustomerInvoices,
			invoiceId ?? "",
			InvoiceUrlPaths.SubmitPayment,
		],
		{
			keyToInvalidate: [InvoiceUrlPaths.Invoice, InvoiceUrlPaths.CustomerInvoices],
			pythonifyExcludeList: ["last4"],
		}
	)
}

export const useInvoiceSources = (
	options?: ApiInfiniteGetQueryOptions<InvoiceSource, InvoiceSourceQueryParams>
): ApiInfiniteGetQuery<InvoiceSource> => {
	return useInfiniteGetRequest([InvoiceUrlPaths.Invoice], options)
}

export const usePrintInvoices = (): ApiPostMutation<PrintInvoicesPost> => {
	return usePostRequest([InvoiceUrlPaths.Invoice, InvoiceUrlPaths.InvoicePrint], {
		pythonifyExcludeList: ["_id"],
	})
}

export const useExportInvoices = (params: Partial<InvoiceSourceQueryParams>): DataExport => {
	return useDataExport([InvoiceUrlPaths.Invoice], params)
}
