import React, { memo } from "react"

import { AnyNcsDeviceStatus, GenericDeviceStatus, getGenericDeviceStatus } from "@ncs/ncs-api"
import { Icon } from "@ncs/web-legos"

export interface StatusIconProps {
	status: AnyNcsDeviceStatus
}

export const StatusIcon: React.FC<StatusIconProps> = memo(({ status: statusProp }) => {
	const status = getGenericDeviceStatus(statusProp)

	if (status === GenericDeviceStatus.Normal) {
		return <Icon icon="check-circle" family="solid" color="success" fixedWidth />
	}
	if (status === GenericDeviceStatus.Warning) {
		return <Icon icon="info-circle" family="solid" color="warning" fixedWidth />
	}
	if (status === GenericDeviceStatus.Error) {
		return <Icon icon="exclamation-triangle" family="solid" color="error" fixedWidth />
	}

	return <Icon icon="wifi-slash" fixedWidth />
})
