import { AxiosRequestConfig } from "axios"
import qs from "query-string"
import { useMutation, useQueryClient } from "react-query"

import { arrayWrap, CamelToSnakeCaseNested, pythonify } from "@ncs/ts-utils"

import { apiClient } from "../util"
import { ApiPatchMutation, ApiPatchMutationOptions } from "./types"

export const usePatchRequest = <RequestData extends {} | void, ResponseData = unknown>(
	endpoint: string | string[],
	options?: ApiPatchMutationOptions<RequestData>
): ApiPatchMutation<RequestData, ResponseData> => {
	const queryClient = useQueryClient()

	const mutation = useMutation(
		(
			patchData: RequestData extends void ? void | { id?: string | number; updates?: void }
			:	{ updates: RequestData; id?: string | number }
		) => {
			const id = patchData?.id
			const updates: RequestData = (patchData?.updates ?? {}) as RequestData

			if (
				options?.validatePayload &&
				typeof updates === "object" &&
				Object.keys(updates).length
			) {
				options.validatePayload(updates)
			}

			let axiosConfig: AxiosRequestConfig | undefined
			if (updates instanceof FormData) {
				axiosConfig = {
					headers: {
						"content-type":
							"multipart/form-data; boundary=----WebKitFormBoundary6CQcHKAPzXlSYnJt",
					},
				}
			}

			const callMethod = options?.putNotPatch ? apiClient.put : apiClient.patch

			return callMethod<CamelToSnakeCaseNested<ResponseData>>(
				`${arrayWrap(endpoint).join("/")}/${id ? `${id}/` : ""}${
					options?.urlPathsAfterId ?
						`${arrayWrap(options.urlPathsAfterId).join("/")}/`
					:	""
				}${
					options?.queryParams ? `?${qs.stringify(pythonify(options.queryParams))}` : ""
				}`,
				updates instanceof FormData ? updates : (
					pythonify(updates, options?.pythonifyExcludeList)
				),
				axiosConfig
			)
		},
		{
			onSuccess: () => {
				if (options?.keyToInvalidate) {
					const keys =
						typeof options.keyToInvalidate === "string" ?
							[options.keyToInvalidate]
						:	options.keyToInvalidate
					keys.forEach((key) => {
						void queryClient.invalidateQueries(key)
					})
				}
			},
			retry: false,
		}
	)

	return mutation.mutateAsync
}
