import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { APPLICATION } from "../auth"
import { LineItemType } from "../common"
import { CustomerAddressWithoutFst, CustomerMinimal, CustomerWithTerritory } from "../customers"
import { ErpStatus, PriceOverrideReason } from "../lookups"
import { UserMinimal } from "../security"

export enum WorkordersUrlPath {
	Approve = "approve",
	ApproveMilestone = "approve_milestone",
	BulkNonBillable = "bulk_non_billable",
	Convert = "convert",
	CreditMemo = "credit_memo",
	CreditMemos = "credit_memos",
	CustomerMilestone = "customer_milestone",
	EmailDocument = "email_document",
	Erp = "erp",
	Erp2 = "erp2",
	Finalize = "finalize",
	Invoices = "invoices",
	History = "history",
	LineItems = "line_items",
	Rebill = "rebill",
	RecreateDocument = "recreate_document",
	RegenerateDocument = "regenerate_document",
	Reject = "reject",
	RejectMilestone = "reject_milestone",
	SiteStatus = "site_status",
	Unexpire = "unexpire",
	UploadErpAttachment = "upload_erp_attachment",
	WorkOrderLineItemTypes = "work_order_line_item_types",
	Workorders = "workorders",
}

export interface CustomerMilestone {
	id: string
	appliedDiscountPercent: string
	approvedBy: UserMinimal | null
	approvedOn: string | null
	averageWashPrice: string
	customer: CustomerMinimal
	customerEquipment: CustomerEquipmentMinimal
	dailyAverageWashcount: number
	identifiedOn: string
	milestone: MachineMilestoneMin
	milestoneErp: {
		id: string
	} | null
	rejectedBy: UserMinimal | null
	rejectedOn: string | null
	status: {
		id: CustomerMilestoneStatusId
		description: string
	}
}

export enum CustomerMilestoneStatusId {
	Created = "1",
	Converted = "2",
	Rejected = "3",
}

export interface CustomerMilestoneQueryParams extends OrderingQueryParams {
	customer: string | null
	customerMilestoneStatus: string | null
}

export interface MachineMilestoneMin {
	id: string
	adminMaxDiscountPercent: string
	ageInMonths: number | null
	createdBy: UserMinimal
	createdDate: string
	description: string
	discountPercent: string
	isActive: boolean
	milestoneGroupId: number
	modifiedBy: UserMinimal | null
	modifiedDate: string | null
	userMaxDiscountPercent: string
	washCount: number
}

export interface CustomerEquipmentMinimal {
	id: string
	bay: string | null
	installDate: string | null
	isActive: boolean
	model: string | null
	modelId: number | null
	serialNumber: string
}

export interface RejectMilestonePost {
	id: string // Customer milestone ID
}

export interface ApproveMilestonePost {
	id: string // Customer milestone ID
	averageWashPrice: number
	dailyAverageWashcount: number
	appliedDiscountPercent: number
}

export interface Erp {
	id: string
	billToCustomer: CustomerAddressWithoutFst | null
	closedDate: string | null
	comment: string | null // max 1000
	createdBy: string
	createdDate: string
	customer: CustomerAddressWithoutFst
	customerId: number
	customerPurchaseOrder: string | null
	customerMilestoneId: number | null
	dispatchId: string | null
	erpAttachments: {
		id: number
		name: string
		description: string
		documentType: number
	}[]
	erpDocument: ErpImportDocument[]
	erpLineItem: ErpLineItem[]
	erpStatus: ErpStatus
	erpStatusId: number
	expirationDate: string | null
	isExpired: boolean // Doesn't seem to work!
	partOrderId: string | null
	partOrderKbm: string | null
	subtotal: string
	taxTotal: string
	total: string
}

export interface ErpQueryParams extends SearchQueryParams, OrderingQueryParams {
	organization: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
	erpStatus: string | null
	milestone: boolean | null
}

export interface CreateErpPost {
	comment: string | null
	customerId: string
	customerPurchaseOrder: string | null
	billToCustomerId: string
	erpLineItem: {
		lineItemTypeId: number // It's a LineItemType, but a number
		partId: string | null
		description: string
		netPrice: string
		unitPrice: string
		tax: string // two decimals (total for the line, not per each)
		total: string // two decimals.
		subTotal: string // two decimals.
		quantity: string // two decimals also...
		reasonId: string | null // The price override reason ID.
	}[]
	erpStatusId: number // hard code to 1

	// These were passed by the old UI, but they're ignored by the server.
	// subtotal: string
	// taxTotal: string
	// total: string
}

export interface ErpImportDocument {
	id: number
	effDate: string // Date with no time!
	name: string
	url: string | null
}

export interface ErpLineItem {
	id: string
	description: string
	lineDate: string // Date with no time!
	lineItemNumber: number
	lineItemTypeId: number
	netPrice: string
	partId: string | null
	partNumber: string | null
	partFamily: string | null
	partDescription: string | null
	partWeight: number | null
	quantity: string
	reason: PriceOverrideReason | null
	subTotal: string
	tax: string // Total taxes for the line.
	total: string
	unitPrice: string
}

export enum ErpStatusId {
	IN_PROGRESS = "1",
	SUBMITTED = "2",
	APPROVED = "3",
	REJECTED = "4",
	VOID = "5",
	COMPLETED = "6",
	CONVERTED = "7",
}

export type UpdateErpPatch = Partial<{
	comment: string | null
	customerPurchaseOrder: string | null
	statusId: string
}>

export interface EmailErpDocPost {
	id: string
}

export interface RecreateErpDocPost {
	id: string
}

export interface AddErpLineItemPost {
	id: string // The ID of the quote (aka ERP).
	description: string
	lineItemTypeId: number
	netPrice: string | number // The actual price used per each.
	partId: string | null
	quantity: number
	subTotal: string // net price * quantity.
	tax: string // total taxes on the entire line.
	total: string // subtotal + taxes.
	unitPrice: string // The initial price per each.
	reasonId: string | null // Price override reason
}

export type EditErpLineItemPatch = AddErpLineItemPost

export interface ConvertErpPost {
	id: string // ID of the ERP to convert
	shipTo: string

	// Not doing this anymore.
	// partOrder: ErpLineItem[]
}

export interface UnexpireErpPost {
	id: string // ERP ID
	newExpirationDate: string
}

export interface WorkOrderLineItemType {
	id: LineItemType
	code: string
	description: string
	userSelectable: boolean
}

export interface WorkOrderLineItemTypesQueryParams {
	includeAll: boolean | null
}

export interface WorkOrderSiteStatus {
	id: string
	code: string
	description: string
}

/**
 * An invoice that comes back from the deprecated workorders invoices endpoints.
 */
export interface WorkOrderInvoice {
	id: string
	actions: {
		action: string | null
		actionType: string | null
		billable: boolean
		createdBy: string
		createdDate: string
		description: string | null
		/**
		 * Unsafely huge, don't use.
		 * @deprecated
		 */
		invoice: number
		/**
		 * Unsafely huge, don't use.
		 * @deprecated
		 */
		lineItem: number
		netPrice: string
		part: string | null // Part number
		quantity: string
		reasonForReplacement: string | null
		reasonNonBillable: string | null
		subTotal: string
		tax: string
		total: string
		underWarranty: boolean
		unitPrice: string
		/**
		 * Unsafely huge, don't use.
		 * @deprecated
		 */
		workorder: number
		workorderMachine: string | null // I think it can be a string?
	}[]
	arrivedDate: string | null
	billToCustomer: {
		id: string
		customerNumber: string
		name: string
	} | null
	comments: string | null
	customer: CustomerWithTerritory
	dispatch: {}[] // Type this out if needed.
	history: {
		id: string
		createdBy: string
		createdDate: string
		rejectionReason: string | null
		status: string | null
	}[]
	invoiceDate: string | null
	invoiceDoc: {
		id: string
		name: string | null
		url: string | null
		type: 6
	}[]
	invoiceNumber: string | null
	kbmBatch: string | null
	kbmNumber: string | null
	laborTotal: string
	otherTotal: string
	payByCreditCard: boolean | null
	status: InvoiceStatusId
	statusCode: string
	subtotal: string
	taxTotal: string
	total: string
	totalParts: string
	workCompletedDate: string | null
}

export enum InvoiceStatusId {
	New = 2,
	Combined = 3,
	ReadyForInvoicing = 4,
	InvoiceGenerated = 5,
	Rejected = 6,
	PendingFieldApproval = 9,
	PendingSecondApproval = 10,
	Rebilled = 11,
	AdditionalEntry = 12,
	Invoiced = 13,
	MailInvoice = 14,
	KbmQueued = 15,
	Void = 16,
}

export type UpdateWorkOrderInvoicePatch = Partial<{
	billToCustomerId: string | null
	arrivedDate: string | null
	workCompletedDate: string | null
	comments: string | null
}>

export interface WorkOrderInvoiceLineItem {
	id: string
	approvals: {
		id: string
		approvedOn: string | null
		approver: UserMinimal | null
		approverComments: string | null
		deniedOn: string | null
		pendingApproval: boolean
		rate: string
		reasonComment: string | null
		reasonDescription: string | null
		requestedOn: string
		requestor: UserMinimal
	}[]
	billable: boolean
	netPrice: string
	overridePermissionId: number | null
	overridePermission: APPLICATION | null
	partDescription: string | null
	partId: string | null
	partNumber: string | null
	quantity: string
	reason: string | null // Not an override reason I don't think
	subTotal: string
	tax: string
	total: string
	typeDescription: string | null
	typeId: number
	underWarranty: boolean
	unitPrice: string
	workorder: {
		closedDate: string | null
		comment: string | null
		openDate: string
		technician: string | null
		workorderId: string
		workorderNumber: string
	}
}

export type CreateWorkorderInvoiceLineItemPost = {
	billable: boolean
	binId: number | null
	netPrice: number
	partId: number | null
	quantity: number
	reason: string | null // Description, not anything to do w/ overrides.
	typeId: number
	underWarranty: boolean
	unitPrice: number
}

export type UpdateWorkorderInvoiceLineItemPatch = {
	billable: boolean
	netPrice: number
	quantity: number
	reason: string // Description, not anything to do w/ overrides.
	underWarranty: boolean
	overrideReasonId: number | null
	overrideComment: string | null
	requestedOverrideAmount: number | null
}

export interface CreditMemo {
	id: string
	arrivedDate: string | null
	comments: string | null
	createdBy: UserMinimal
	createdDate: string
	invoiceDate: string | null
	invoiceDoc: {
		id: string
		name: string
		url: string | null
		type: 14
	}[]
	invoiceNumber: string | null
	laborTotal: string
	lineItems: CreditMemoLineItem[]
	otherTotal: string
	reason: string
	status: InvoiceStatusId
	statusCode: string
	subtotal: string
	taxTotal: string
	total: string
	totalParts: string
	workCompletedDate: string | null
}

export interface CreditMemoLineItem {
	id: string
	billable: boolean
	netPrice: string // Number, can be negative.
	partDescription: string | null
	partId: string | number | null // Not sure if `SlugRelatedField` ends up stringifying its result.
	partNumber: string | null
	quantity: string
	subTotal: string
	tax: string
	total: string
	typeDescription: string | null
	typeId: number | null
	underWarranty: boolean
	unitPrice: string
}

export interface CreateCreditMemoPost {
	comments: string
	items: {
		id: string
		netPrice: number
	}[]
	reason: string
}

export type RebillInvoicePost = CreateCreditMemoPost

export interface ApproveCreditMemoPost {
	id: string
}

export interface RejectCreditMemoPost {
	id: string
	comment: string | null
}

export interface ApproveInvoicePost {
	id: string
}

export interface RejectInvoicePost {
	id: string
	comment: string | null
}

export interface FinalizeInvoicePost {
	id: string
}

export interface CreateInvoiceNotePost {
	id: string
	rejectionReason: string // Don't be fooled by the name.
}
