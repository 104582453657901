import React, { Dispatch, memo, SetStateAction } from "react"

import { Box, Paragraph, Select } from "@ncs/web-legos"

export interface ConnectedSiteSortProps {
	value: LocalSiteSort
	onChange: Dispatch<SetStateAction<LocalSiteSort>>
}

export enum LocalSiteSort {
	Name = "site_name",
	NameDesc = "-site_name",
	Priority = "priority",
}

const options: {
	text: string
	value: LocalSiteSort
}[] = [
	{
		text: "Name A - Z",
		value: LocalSiteSort.Name,
	},
	{
		text: "Name Z - A",
		value: LocalSiteSort.NameDesc,
	},
	{
		text: "Device state priority",
		value: LocalSiteSort.Priority,
	},
]

export const ConnectedSiteSort: React.FC<ConnectedSiteSortProps> = memo(({ value, onChange }) => {
	return (
		<Box display="flex" alignItems="center">
			<Paragraph>Sort: </Paragraph>
			<Select
				value={value || null}
				onChange={(newValue, newOption) => onChange(newOption?.value ?? defaultSiteSort)}
				options={options}
				mb={0}
				ml={1}
				showNoSelectionOption={false}
			/>
		</Box>
	)
})

export const defaultSiteSort = LocalSiteSort.Priority
