import { FC, memo } from "react"

import { css } from "@emotion/react"

export interface TableRowCellProps {
	rowIsExpanded: boolean
	rowDepth: number
	rowCanExpand: boolean
	cellRenderFn: (type: string) => void

	// Rest of the cell props
	className?: string | undefined
	role?: string | undefined
}

export const TableRowCell: FC<TableRowCellProps> = memo(
	({ rowIsExpanded, rowDepth, rowCanExpand, className, role, cellRenderFn }) => {
		return (
			<td
				className={className}
				role={role}
				css={
					rowIsExpanded && rowDepth === 0 && rowCanExpand ?
						expandedRowCellStyle
					:	undefined
				}
			>
				{cellRenderFn("Cell")}
			</td>
		)
	}
)

const expandedRowCellStyle = css`
	font-weight: 450;
`
