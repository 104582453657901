import React, { memo, useMemo } from "react"

import { css, useTheme } from "@emotion/react"
import { IconName as FaIconName } from "@fortawesome/fontawesome-common-types"
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome"
import { darken, lighten } from "polished"

import { withCssUnit } from "@ncs/ts-utils"

const iconFamilies = {
	light: "fal" as const,
	regular: "far" as const,
	solid: "fas" as const,
	brands: "fab" as const,
}

// For convenience, re-export IconName from here.
export type IconName = FaIconName
export type IconFamily = keyof typeof iconFamilies

export interface IconProps
	extends Omit<
		FontAwesomeIconProps,
		"name" | "prefix" | "icon" | "size" | "fontFamily" | "onClick"
	> {
	icon: IconName
	color?:
		| "text"
		| "primary"
		| "light-primary"
		| "gray"
		| "light-gray"
		| "success"
		| "dark-success"
		| "error"
		| "warning"
		| "white"
	family?: IconFamily
	fontSize?: string | number
	withContainer?: boolean
	customColor?: string
}

export const Icon: React.FC<IconProps> = memo(
	({ icon, color, family = "regular", fontSize, withContainer, customColor, ...rest }) => {
		const theme = useTheme()

		const colorStyle = useMemo(() => {
			switch (color) {
				case "text": {
					return css`
						color: ${theme.palette.text.primary};
					`
				}
				case "primary": {
					return css`
						color: ${theme.palette.primary.main};
					`
				}
				case "light-primary": {
					return css`
						color: ${theme.palette.primary.light};
					`
				}
				case "gray": {
					return css`
						color: ${theme.palette.text.secondary};
					`
				}
				case "light-gray": {
					return css`
						color: ${lighten(0.25, theme.palette.text.secondary)};
					`
				}
				case "success": {
					return css`
						color: ${theme.palette.success.main};
					`
				}
				case "dark-success": {
					return css`
						color: ${darken(0.18, theme.palette.success.main)};
					`
				}
				case "error": {
					return css`
						color: ${theme.palette.error.main};
					`
				}
				case "warning": {
					return css`
						color: ${theme.palette.warning.main};
					`
				}
				case "white": {
					return css`
						color: white;
					`
				}
			}

			return undefined
		}, [color, theme])

		return (
			<span css={withContainer ? iconContainer : null}>
				<FontAwesomeIcon
					{...rest}
					css={[
						customColor ? customColor : colorStyle,
						css`
							font-size: ${withCssUnit(fontSize)};
						`,
					]}
					icon={[iconFamilies[family], icon]}
				/>
			</span>
		)
	}
)

const iconContainer = css`
	padding: 0.5rem;
	background-color: #ffffff;
	width: 1.75rem;
	height: 1.75rem;
	border-radius: 50%;
	align-self: center;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
`
