import { memo, ReactElement } from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { accessString, StringAccessor, toggleArrayItem } from "@ncs/ts-utils"

import { CheckboxGroup, CheckboxGroupProps } from "../inputs"

export interface CheckboxGroupFormFieldProps<TFieldValues extends FieldValues, Row>
	extends Omit<CheckboxGroupProps<Row>, "onChange"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	rows: Row[]
	valueAccessor?: StringAccessor<Row>
}

/** You pass in the rows, the form field is an array of the values of the
 * items currently checked. */
const CheckboxGroupFormFieldWithoutMemo = <TFieldValues extends FieldValues, Row>({
	name,
	control,
	valueAccessor = "value" as keyof Row,
	rows,
	...rest
}: CheckboxGroupFormFieldProps<TFieldValues, Row>): ReactElement => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value: valuesArray, onChange }, fieldState: { error } }) => {
				return (
					<CheckboxGroup
						rows={rows}
						valueAccessor={valueAccessor}
						{...rest}
						onChange={(option) => {
							const optionValue = accessString(option, valueAccessor)
							onChange(toggleArrayItem(optionValue, valuesArray))
						}}
						checkedAccessor={(option) => {
							const optionValue = accessString(option, valueAccessor)
							return valuesArray.includes(optionValue)
						}}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}

export const CheckboxGroupFormField = memo(
	CheckboxGroupFormFieldWithoutMemo
) as typeof CheckboxGroupFormFieldWithoutMemo
