import React, { memo, useEffect, useRef } from "react"

import { InventoryPartType } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartTypeSelectorProps
	extends ExtendableSelectProps<(typeof partTypeOptions)[number], InventoryPartType> {
	/** Initial value. Will change to this whenever it comes in, but will only change once. */
	initialValue?: InventoryPartType | null
}

const partTypeOptions = [
	{ value: InventoryPartType.All, text: "Parts and chemicals" },
	{ value: InventoryPartType.Parts, text: "Parts" },
	{ value: InventoryPartType.Chemicals, text: "Chemicals" },
]

export const PartTypeSelector: React.FC<PartTypeSelectorProps> = memo(
	({ value, onChange, initialValue, ...rest }) => {
		const initialValueHasBeenSet = useRef(false)

		useEffect(() => {
			if (
				initialValueHasBeenSet.current === false &&
				initialValue != null &&
				value === null
			) {
				onChange(initialValue)
				initialValueHasBeenSet.current = true
			}
		}, [initialValue, onChange, value])

		return (
			<Select
				label="Part types"
				{...rest}
				value={value}
				onChange={(e, option) => onChange(option?.value ?? null)}
				options={partTypeOptions}
			/>
		)
	}
)
