import React from "react"

import { css, Theme } from "@emotion/react"

import { Box, Button, Heading, Icon, Modal, Paragraph } from "@ncs/web-legos"

export interface OrderReceivedModalProps {
	isOpen: boolean
	onClose: () => void
	orderNumber: string | number | null
}

export const OrderReceivedModal: React.FC<OrderReceivedModalProps> = ({
	isOpen,
	onClose,
	orderNumber,
}) => {
	if (!orderNumber) {
		return null
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} maxWidth="md" showCloseButton={false}>
			<Box textAlign="center" pb={3}>
				<Icon css={iconStyle} icon="check-circle" family="solid" />
				<Heading variant="h1" my={3}>
					Thanks! Order #{orderNumber} has been received
				</Heading>
				<Paragraph maxWidth="25rem" mx="auto" my={3}>
					You will receive an email shortly confirming your order. Thank you for choosing
					NCS.
				</Paragraph>
				<Button variant="secondary-cta" onClick={onClose}>
					Done
				</Button>
			</Box>
		</Modal>
	)
}

const iconStyle = (theme: Theme) => css`
	font-size: 4rem;
	color: ${theme.palette.success.main};
`
