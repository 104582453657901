import React from "react"

import { ReportTimeSpan } from "@ncs/ncs-api"
import { TimeAgo } from "@ncs/ts-utils"

import { Select, SelectProps } from "../inputs"
import { tooBigForHourly } from "./TimeAgoSelector"

export interface ReportFrequencySelectorProps
	extends Omit<SelectProps<(typeof options)[number]>, "options" | "value" | "onChange"> {
	value: ReportTimeSpan
	onChange: (newValue: ReportTimeSpan) => void
	timeAgo?: TimeAgo | null
}

const options = [
	{
		value: ReportTimeSpan.Hourly,
		text: "Hourly",
	},
	{
		value: ReportTimeSpan.Daily,
		text: "Daily",
	},
	{
		value: ReportTimeSpan.Weekly,
		text: "Weekly",
	},
	{
		value: ReportTimeSpan.Monthly,
		text: "Monthly",
	},
]

export const ReportFrequencySelector: React.FC<ReportFrequencySelectorProps> = ({
	value = ReportTimeSpan.Daily,
	onChange,
	timeAgo,
	...rest
}) => {
	return (
		<Select
			label="Frequency"
			showNoSelectionOption={false}
			{...rest}
			value={value}
			onChange={(newValue, newTimeSpan) => {
				if (newTimeSpan) onChange(newTimeSpan.value)
			}}
			options={options}
			disabledAccessor={
				timeAgo ?
					(option) =>
						option.value === ReportTimeSpan.Hourly && tooBigForHourly.includes(timeAgo)
				:	undefined
			}
		/>
	)
}
