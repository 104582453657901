import { anonymousApiClient, apiClient, buildUrl } from "../../util"
import {
	LoginRequestSchema,
	LoginResponse,
	LoginResponseSchema,
	RefreshRequestSchema,
} from "./types"

export const login = async (
	email: string,
	password: string,
	businessRole: "customer" | "internal" = "customer"
): Promise<LoginResponse> => {
	const request = LoginRequestSchema.parse({
		email,
		password,
	})
	const response = await anonymousApiClient.post(
		buildUrl(businessRole === "customer" ? "auth/customer-token/" : "auth/token/"),
		request
	)

	return LoginResponseSchema.parse(response.data)
}

export const punchOutLogin = async (sessionId: string): Promise<LoginResponse> => {
	const response = await anonymousApiClient.get(
		buildUrl(`security/punch_out/sign_on/?session=${sessionId}`)
	)

	return LoginResponseSchema.parse(response.data)
}

export const logout = async (): Promise<void> => {
	await apiClient.post(buildUrl("api/logout/"))
}

/** Calls the server with the passed refresh token and refreshes the session. */
export const refreshAccessToken = async (refresh: string): Promise<LoginResponse> => {
	const request = RefreshRequestSchema.parse({
		refresh,
	})
	const response = await anonymousApiClient.post(buildUrl("auth/token/refresh/"), request)

	return LoginResponseSchema.parse(response.data)
}
