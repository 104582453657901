import { useEffect, useMemo, useState } from "react"

import {
	useAuth,
	useContacts,
	useCustomerAdditionalUsers,
	useSites,
	useUserProfile,
} from "@ncs/ncs-api"

/**
 * Unified type for the people that an alert can go to. Combines the logged in user,
 * superuser additional users, and Contacts.
 */
export interface AlertRecipient {
	userId: string | null
	contactId: string | null
	firstName: string
	lastName: string
	email: string | null
	phone: string | null
	siteIds: string[]
}

export const useCombinedAlertRecipients = (
	selectedSiteId: string | null | undefined
): [AlertRecipient[], boolean] => {
	const [recipients, setRecipients] = useState<{
		[email: string]: AlertRecipient
	}>({})

	const { user } = useAuth()
	const [profile, profileLoading] = useUserProfile(user?.id)
	const [sites, sitesLoading] = useSites()
	const [superuserUsers, superuserUsersLoading] = useCustomerAdditionalUsers({
		queryConfig: {
			enabled: profile?.isCustomerSuperuser === true,
		},
	})
	const [contacts, contactsLoading] = useContacts({
		queryConfig: {
			enabled: profile?.isCustomerSuperuser === true,
		},
	})

	const anythingLoading = useMemo(() => {
		return profileLoading || sitesLoading || superuserUsersLoading || contactsLoading
	}, [profileLoading, sitesLoading, superuserUsersLoading, contactsLoading])

	useEffect(() => {
		if (contacts?.length) {
			setRecipients((prev) => {
				const newState = { ...prev }

				contacts.forEach((contact) => {
					// (There aren't any Contacts without an email)
					const email = contact.email ?? "contact-with-no-email"

					// Results from this Contacts will eventually all get replaced by actual Users,
					// but for now we are still drawing from both sources and "joining" by email.
					// The superuser's endpoint is still the source of truth though, so don't blow
					// away what's here with the results of the contact API.

					if (!newState[email]) {
						newState[email] = {
							userId: null,
							contactId: contact.id,
							firstName: contact.firstName,
							lastName: contact.lastName || "",
							email: contact.email,
							phone: contact.phone?.trim() || null,
							siteIds: contact.customers.map((customer) => customer.id),
						}
					}
				})

				return newState
			})
		}
	}, [contacts])

	useEffect(() => {
		if (superuserUsers?.length && !!user?.id) {
			setRecipients((prev) => {
				const newState = { ...prev }

				superuserUsers.forEach((additionalUser) => {
					if (additionalUser.id !== user.id) {
						newState[additionalUser.email] = {
							userId: additionalUser.id,
							contactId: null,
							firstName: additionalUser.firstName,
							lastName: additionalUser.lastName,
							email: additionalUser.email,
							phone: additionalUser.mobile?.trim() || null,
							siteIds: additionalUser.sites,
						}
					}
				})

				return newState
			})
		}
	}, [superuserUsers, user?.id])

	useEffect(() => {
		if (profile && sites) {
			setRecipients((prev) => {
				const newState = {
					...prev,
					[profile.email]: {
						userId: profile.id.toString(),
						contactId: null,
						firstName: profile.firstName,
						lastName: profile.lastName,
						email: profile.email,
						phone: profile.mobile?.trim() || null,
						siteIds: sites.map((site) => site.id),
					},
				}

				return newState
			})
		}
	}, [profile, sites])

	const result = useMemo(() => {
		if (anythingLoading) return []

		return Object.values(recipients)
			.filter((recipient) => {
				// If no site is selected, then in order to display you must have all the sites that the
				// requesting user has.
				if (!selectedSiteId) {
					return recipient.siteIds.length === sites?.length
				}
				return recipient.siteIds.includes(selectedSiteId)
			})
			.sort((a, b) => (a.lastName.toUpperCase() > b.lastName.toUpperCase() ? 1 : -1))
	}, [anythingLoading, recipients, selectedSiteId, sites?.length])

	return [result, anythingLoading]
}
