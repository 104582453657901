import { FC } from "react"
import { Link as RouterLink } from "react-router-dom"
import { TrackingEvent, trackEvent } from "../../util"

export interface EventTrackerProps {
	event: TrackingEvent
	data?: any
}

export const EventTracker: FC<EventTrackerProps> = ({ event, data, children }) => {
	const onClickHandler = () => {
		if (event) {
			trackEvent(event, data)
		}
	}

	return <span onClick={onClickHandler}>{children}</span>
}
