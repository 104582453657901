export enum EmailsUrlPaths {
	Emails = "emails",
	ContactUrl = "contact",
	ContactInfo = "contact_info",
	ContactAlertInfoUrl = "contact_alert_info",
	Alerts = "alerts",
}

export enum AlertCategory {
	MACHINE = "Machine",
	CHEMICAL = "Chemical",
	VACUUM = "Vacuum",
	PART_ORDER = "Part Order",
	DISPATCH = "Dispatch",
}

export interface AlertType {
	id: number
	category: AlertCategory
	description: string
}

export interface Contact {
	id: string
	firstName: string
	lastName: string | null
	email: string | null
	phone: string | null
	customers: {
		id: string
		customerNumber: string
		name: string
	}[]
}

export interface NewContactPost {
	firstName: string
	lastName: string
	phone: string
	email: string
	sites: string[]
}

export interface ContactPatch {
	firstName: string
	lastName: string
	phone: string
	email: string
	addedSites: string[] | null
	removedSites: string[] | null
}

export type ContactInfoPatch = {
	userId: string | null
	contactId: string | null
	alertTypeId: number
	siteIds: string[]
} & Partial<{
	email: boolean
	sms: boolean
	push: boolean
}>

export interface ContactAlertInfo {
	userId: string | null
	contactId: string | null
	name: string | null
	email: string | null
	phone: string | null
	alerts: {
		// These are arrays of `ConnectivityAlert` IDs.
		email: string[]
		sms: string[]
		push: string[]
	}
}
