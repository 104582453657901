import React, { memo } from "react"

import { PartOrderStatus, usePartOrderStatuses } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface PartOrderStatusSelectorProps extends ExtendableSelectProps<PartOrderStatus> {}

export const PartOrderStatusSelector: React.FC<PartOrderStatusSelectorProps> = memo((props) => {
	const [partOrderStatuses, partOrderStatusesAreLoading] = usePartOrderStatuses()

	return (
		<Select
			label="Order status"
			isLoading={partOrderStatusesAreLoading}
			{...props}
			options={partOrderStatuses ?? []}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
})
