import { arrayWrap } from "./arrays"
import { CamelToSnakeCaseNested, SnakeToCamelCaseNested } from "./types"

export const snakeToCamel = (str: string): string =>
	str.replace(/([-_][a-z0-9])/gi, (group) =>
		group.toUpperCase().replace("-", "").replace("_", "")
	)

/** Converts snake_case to camelCase recursively */
export const unpythonify = <PythonishInput = unknown>(
	item: PythonishInput
): SnakeToCamelCaseNested<PythonishInput> =>
	(Array.isArray(item) ? item.map(unpythonify)
	: item && typeof item === "object" ?
		Object.fromEntries(
			Object.entries(item).map(([key, value]) => [
				snakeToCamel(key.startsWith("_") ? key.substring(1) : key),
				unpythonify(value),
			])
		)
	:	item) as unknown as SnakeToCamelCaseNested<PythonishInput>

export const camelToSnake = (str: string): string => {
	return str
		.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
		.replace(/([a-z])([0-9])/g, (match) => `${match[0]}_${match[1]}`)
}

/** Converts camelCase to snake_case recursively */
export const pythonify = <Input = unknown>(
	item: Input,
	excludedKeys?: string | string[]
): CamelToSnakeCaseNested<Input> => {
	const excludeList = arrayWrap(excludedKeys ?? [])

	if (!item) {
		return item as CamelToSnakeCaseNested<Input>
	}
	if (Array.isArray(item)) {
		return item.map((value) => pythonify(value, excludedKeys)) as CamelToSnakeCaseNested<Input>
	}
	if (typeof item === "object" && item !== null) {
		return Object.fromEntries(
			Object.entries(item).map(([key, value]) => {
				return excludeList.includes(key) ?
						[key, value]
					:	[camelToSnake(key), pythonify(value, excludedKeys)]
			})
		) as CamelToSnakeCaseNested<Input>
	}

	return item as CamelToSnakeCaseNested<Input>
}
