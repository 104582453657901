import { FC, useEffect, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import dayjs from "dayjs"
import { useQueryClient } from "react-query"

import { useClientVersionContext } from "@ncs/ncs-api"
import { fillLeadingZeroes, getTimeRemaining } from "@ncs/ts-utils"

import { Button } from "../buttons"
import { Box } from "../layout"
import { Modal } from "../modals"
import { AnimatedEntrance } from "../transitions"
import { Heading, Icon, Paragraph } from "../typography"

export interface ClientUpdaterProps {
	/** Minutes after the client went stale that we'll force a refresh. */
	forceRefreshAfter?: number
	/** Minutes remaining when we'll start showing the countdown. */
	showCountdownAt?: number
}

export const ClientUpdater: FC<ClientUpdaterProps> = ({
	forceRefreshAfter = 15,
	showCountdownAt = 10,
}) => {
	const [{ clientStaleSince }] = useClientVersionContext()
	const queryClient = useQueryClient()
	const [isSaving, setIsSaving] = useState(false)
	const [showHelpModal, setShowHelpModal] = useState(false)
	const [refreshCountdown, setRefreshCountdown] = useState<string | null>(null)
	const [dismissed, setDismissed] = useState(false)

	const handleClick = async () => {
		setIsSaving(true)
		await queryClient.cancelQueries()
		window.location.reload()
	}

	const reloadRequiredAt = useMemo(() => {
		return clientStaleSince ? dayjs(clientStaleSince).add(forceRefreshAfter, "minutes") : null
	}, [clientStaleSince, forceRefreshAfter])

	useEffect(() => {
		if (reloadRequiredAt) {
			setInterval(() => {
				const { minutes, seconds } = getTimeRemaining(reloadRequiredAt)
				if (minutes <= 0 && seconds <= 0) {
					window.location.reload()
				}

				// We'll just tick away in the background here until we want to show the timer.
				if (minutes < showCountdownAt) {
					setRefreshCountdown(
						`${fillLeadingZeroes(minutes, 2)}:${fillLeadingZeroes(seconds, 2)}`
					)
				}
			}, 1000)
		}
	}, [reloadRequiredAt, showCountdownAt])

	if (!clientStaleSince || !reloadRequiredAt) {
		return null
	}

	if (dismissed && !refreshCountdown) {
		return null
	}

	return (
		<div css={outerContainerCss}>
			<AnimatedEntrance
				show
				direction="left"
				css={innerContainerCss}
				maxWidth={30}
				xsProps={{ maxWidth: "none" }}
			>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					columnGap={1}
					mb={1.5}
				>
					<Icon icon="sparkles" color="error" fontSize={1.5} />
					<Heading variant="h4" uppercase={false} bold>
						New version available
					</Heading>
				</Box>
				<Paragraph mb={2} small color="secondary" textAlign="center">
					A browser refresh is required for an important update to this website. Delaying
					may cause errors.{" "}
					<button css={learnMoreButtonCss} onClick={() => setShowHelpModal(true)}>
						Learn more
					</button>
				</Paragraph>

				{!!refreshCountdown && (
					<>
						<Paragraph mb={0.5} small color="secondary" textAlign="center">
							Site will automatically refresh in...
						</Paragraph>
						<Heading mb={1} variant="h4" bold textAlign="center">
							{refreshCountdown}
						</Heading>
					</>
				)}

				<Button
					icon="redo"
					onClick={handleClick}
					variant="primary-cta"
					isLoading={isSaving}
					fillContainer
				>
					Refresh now
				</Button>

				{!dismissed && !refreshCountdown && (
					<Button
						icon="times"
						onClick={() => setDismissed(true)}
						containerProps={{
							display: "flex",
							justifyContent: "center",
							mt: 1,
						}}
					>
						I need more time, remind me in a few minutes
					</Button>
				)}
			</AnimatedEntrance>

			<Modal
				isOpen={showHelpModal}
				onClose={() => setShowHelpModal(false)}
				title="New Version Available"
			>
				<Paragraph mt={1} mb={1}>
					When you visit any website, your browser downloads and displays the latest
					version of that website: all its code, images, etc. The code that you received
					for this website also interacts with NCS servers as you use it.
				</Paragraph>
				<Paragraph mb={1}>
					Occasionally important updates are made to our servers that require the website
					to do things differently. But if you've had this tab open for a while you won't
					be running the latest version of the website, and so it won't know about those
					changes.
				</Paragraph>
				<Paragraph mb={1}>
					That's when you'll see this prompt. Simply refreshing your browser will get the
					latest version of the website and you can continue using it as normal.
				</Paragraph>
				<Paragraph mb={1}>
					If you don't update to the latest version however, you run the risk of
					interacting with the server in a way it doesn't anticipate and this has the
					potential to cause unexpected errors.
				</Paragraph>
			</Modal>
		</div>
	)
}

const outerContainerCss = (theme: Theme) => css`
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 9999;
	${theme.breakpoints.down("xs")} {
		left: 1rem;
	}
`
const innerContainerCss = (theme: Theme) => css`
	padding: 1.5rem;
	border-radius: 10px;
	background: white;
	box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.3);
	border-left: 10px solid ${theme.palette.error.main};
`
const learnMoreButtonCss = (theme: Theme) => css`
	font-weight: bold;
	font-size: 0.8rem;
	text-decoration: underline;
	color: ${theme.palette.primary.main};
	background: none;
	border: 0;
	padding: 0;
`
