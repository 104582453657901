import { FC, FocusEventHandler, memo, ReactNode, useMemo } from "react"

import { css } from "@emotion/react"
import { Checkbox as MuiCheckbox } from "@material-ui/core"
import { nanoid } from "nanoid"

import { Box } from "../layout"
import { ControlLabel } from "../typography"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export interface CheckboxProps extends Omit<FieldContainerProps, "onClick" | "label"> {
	value: boolean
	onChange: (newValue: boolean) => void
	disabled?: boolean
	indeterminate?: boolean
	onBlur?: FocusEventHandler
	onFocus?: FocusEventHandler
	label: string | ReactNode
	isLoading?: boolean
	noLabelWrap?: boolean
}

export type ExtendableCheckboxProps = Omit<CheckboxProps, "value" | "onChange">

export const Checkbox: FC<CheckboxProps> = memo(
	({
		value,
		onChange,
		indeterminate,
		disabled,
		onBlur,
		onFocus,
		label,
		id: idProp,
		isLoading,
		noLabelWrap,
		...rest
	}) => {
		const id = useMemo(() => {
			if (idProp) return idProp
			if (typeof label === "string") {
				return `${label}-checkbox`
			}

			return nanoid()
		}, [idProp, label])

		return (
			<FieldContainer
				/**
				 * MUI puts some really intense spacing around their checkboxes for the hover
				 * effect, which is cool, except it makes them sit really far right. Nudge them left a bit.
				 */
				ml={-0.5}
				{...rest}
				opacity={isLoading ? 0.75 : 1}
			>
				<Box display="inline-block">
					<Box display="flex" alignItems="center">
						<MuiCheckbox
							id={id}
							checked={value}
							onChange={(e) => onChange(e.target.checked)}
							indeterminate={indeterminate}
							onBlur={onBlur}
							onFocus={onFocus}
							disabled={disabled || isLoading}
							color="primary"
							css={css`
								position: relative;
								top: 0.1rem;
								.MuiSvgIcon-root {
									width: 22px;
									height: 22px;
								}
							`}
						/>
						<ControlLabel
							htmlFor={id}
							checked={value}
							disabled={disabled || isLoading}
							noWrap={noLabelWrap}
						>
							{label}
						</ControlLabel>
					</Box>
				</Box>
			</FieldContainer>
		)
	}
)

Checkbox.displayName = "Checkbox"
