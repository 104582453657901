import React, { useCallback, useEffect, useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/minimal"
import { isValidPhoneNumber } from "libphonenumber-js"
import { useForm } from "react-hook-form"
import { useHistory } from "react-router-dom"
import { z } from "zod"

import {
	makeApiErrorMessage,
	UpdateUserProfilePatch,
	useAuth,
	useUpdateUserProfile,
	useUserProfile,
} from "@ncs/ncs-api"
import { formatDateTime, tryToFormatPhone } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Divider,
	EmptyValueDash,
	ErrorText,
	GridContainer,
	GridItem,
	Heading,
	LabeledData,
	PhoneInputFormField,
	TextInputFormField,
	usePrevious,
	useScreenSizeMatch,
} from "@ncs/web-legos"

import { AdditionalUsers } from "./components"

const UserProfileFormSchema = z.object({
	firstName: z.string().min(1, "First name is required"),
	lastName: z.string().min(1, "Last name is required"),
	email: z.string().min(1, "Email is required").email(),
	mobile: z
		.string()
		.nullable()
		.refine((value) => !value || isValidPhoneNumber(value, "US"), {
			message: "Not a valid U.S. phone number",
		}),
})
export type UserProfileForm = z.infer<typeof UserProfileFormSchema>

export const AccountTab: React.FC = () => {
	const screenIsSmall = useScreenSizeMatch("sm")
	const history = useHistory()
	const auth = useAuth()
	const [profile, isLoading] = useUserProfile(auth.user?.id)
	const updateProfile = useUpdateUserProfile()
	const [isEditing, setIsEditing] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [submissionError, setSubmissionError] = useState("")

	const {
		handleSubmit,
		control,
		formState: { isValid, submitCount },
		reset,
	} = useForm<UserProfileForm>({
		mode: "onSubmit",
		resolver: zodResolver(UserProfileFormSchema),
		defaultValues: {
			firstName: profile?.firstName ?? "",
			lastName: profile?.lastName ?? "",
			mobile: profile?.mobile ?? "",
			email: profile?.email ?? "",
		},
	})

	const handleReset = useCallback(() => {
		reset(
			{
				firstName: profile?.firstName ?? "",
				lastName: profile?.lastName ?? "",
				mobile: profile?.mobile ?? "",
				email: profile?.email ?? "",
			},
			{
				keepSubmitCount: false,
			}
		)
		setIsEditing(false)
		setIsSubmitting(false)
		setSubmissionError("")
	}, [profile, reset])

	const prevIsLoading = usePrevious(isLoading)
	useEffect(() => {
		if (!isLoading && prevIsLoading) {
			// Data came back from the first time. Put it into our form state.
			handleReset()
		}
	}, [prevIsLoading, handleReset, isLoading])

	const onSubmit = useCallback(
		async (data: UserProfileForm) => {
			if (auth.user?.id) {
				const updates: UpdateUserProfilePatch = {
					firstName: data.firstName,
					lastName: data.lastName,
					email: data.email,
					mobile: data.mobile,
				}

				try {
					setIsSubmitting(true)
					await updateProfile({ updates, id: auth.user.id })
					setIsEditing(false)
				} catch (e) {
					setSubmissionError(makeApiErrorMessage(e))
					captureException(e)
					console.error(e)
				} finally {
					setIsSubmitting(false)
				}
			}
		},
		[updateProfile, auth.user?.id]
	)

	const isSuperuser = profile?.isCustomerSuperuser === true

	return (
		<>
			<Heading variant="h4" bold mb={1.5}>
				Account Details
			</Heading>

			<GridContainer mb={3}>
				<GridItem sm={12} md={6}>
					<Box maxWidth="22rem">
						{isEditing ?
							<>
								<TextInputFormField
									name="firstName"
									label="First name"
									control={control}
									emptyValueFallback=""
								/>
								<TextInputFormField
									name="lastName"
									label="Last name"
									control={control}
									emptyValueFallback=""
								/>
								<TextInputFormField
									name="email"
									label="Email address"
									control={control}
									emptyValueFallback=""
								/>
								<PhoneInputFormField
									name="mobile"
									label="Phone number"
									control={control}
									emptyValueFallback={null}
								/>
							</>
						:	<Box display="flex" flexDirection="column">
								<LabeledData label="First name" isLoading={isLoading}>
									{profile?.firstName}
								</LabeledData>
								<LabeledData label="Last name" isLoading={isLoading}>
									{profile?.lastName}
								</LabeledData>
								<LabeledData label="Email address" isLoading={isLoading}>
									{profile?.email}
								</LabeledData>
								<LabeledData label="Phone number" isLoading={isLoading}>
									{tryToFormatPhone(profile?.mobile) || <EmptyValueDash />}
								</LabeledData>
							</Box>
						}

						{isEditing ?
							<>
								<AnimatedEntrance
									show
									direction="down"
									display="flex"
									alignItems="center"
									columnGap={2}
									mt={2}
								>
									<Button
										variant="primary-cta"
										onClick={handleSubmit(onSubmit)}
										disabled={submitCount > 0 && !isValid}
										isLoading={isSubmitting}
									>
										Update
									</Button>
									<Button icon="times" onClick={handleReset}>
										Cancel
									</Button>
								</AnimatedEntrance>
								{!!submissionError && (
									<ErrorText mt={1}>{submissionError}</ErrorText>
								)}
							</>
						:	<Button
								icon="pencil"
								onClick={() => setIsEditing(true)}
								containerProps={{ mt: 0.5 }}
							>
								Edit details
							</Button>
						}
					</Box>
				</GridItem>

				<GridItem sm={12} md={6}>
					{screenIsSmall && <Divider my={3} />}

					<Box display="flex" flexDirection="column">
						<LabeledData label="Last login" isLoading={isLoading}>
							{profile?.lastLogin ?
								formatDateTime(profile.lastLogin)
							:	<EmptyValueDash />}
						</LabeledData>
					</Box>
					<div>
						<Button
							containerProps={{ mt: 1 }}
							icon="lock"
							iconFamily="solid"
							onClick={() => history.push("/auth/forgot-password")}
						>
							Reset account password
						</Button>
					</div>
				</GridItem>
			</GridContainer>

			{isSuperuser && <AdditionalUsers />}
		</>
	)
}
