import React, { memo } from "react"

import { CustomerEquipment, useCustomerEquipmentSingle } from "@ncs/ncs-api"
import { formatDate } from "@ncs/ts-utils"
import { EmptyValueDash, GridContainer, GridItem, LabeledData, Modal, Tabs } from "@ncs/web-legos"

import { EquipmentContractsTab } from "./EquipmentContractsTab"
import { EquipmentDocumentationTab } from "./EquipmentDocumentationTab"
import { EquipmentServiceRequestsTab } from "./EquipmentServiceRequestsTab"

export interface EquipmentDetailModalProps {
	isOpen: boolean
	onClose: () => void
	equipmentId: string | null
	initialEquipmentData?: CustomerEquipment | null
}

export const EquipmentDetailModal: React.FC<EquipmentDetailModalProps> = memo(
	({ isOpen, onClose, equipmentId, initialEquipmentData }) => {
		const [equipment] = useCustomerEquipmentSingle(equipmentId, {
			queryConfig: {
				initialData: initialEquipmentData ?? undefined,
			},
		})

		return (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				title={equipment?.eqpVintage?.name}
				titleDetail={equipment?.customer.name}
				maxWidth="lg"
			>
				<GridContainer mb={2}>
					<GridItem sm={6} md={2}>
						<LabeledData label="Serial number">{equipment?.serialNumber}</LabeledData>
					</GridItem>
					<GridItem sm={6} md={2}>
						<LabeledData label="Install date">
							{equipment?.installDate ?
								formatDate(equipment?.installDate)
							:	<EmptyValueDash />}
						</LabeledData>
					</GridItem>
					<GridItem sm={6} md={2}>
						<LabeledData label="Frame warranty expires">
							{equipment?.frameWarrantyExpireDate ?
								formatDate(equipment?.frameWarrantyExpireDate)
							:	"N/A"}
						</LabeledData>
					</GridItem>
					<GridItem sm={6} md={2}>
						<LabeledData label="Parts warranty expires">
							{equipment?.partsWarrantyExpireDate ?
								formatDate(equipment?.partsWarrantyExpireDate)
							:	"N/A"}
						</LabeledData>
					</GridItem>
					<GridItem sm={6} md={2}>
						<LabeledData label="Labor warranty expires">
							{equipment?.laborWarrantyExpireDate ?
								formatDate(equipment?.laborWarrantyExpireDate)
							:	"N/A"}
						</LabeledData>
					</GridItem>
				</GridContainer>

				<Tabs
					minHeight="35rem"
					panels={[
						{
							navLabel: "Service Requests",
							component: <EquipmentServiceRequestsTab equipmentId={equipmentId} />,
						},
						{
							navLabel: "Contracts",
							component: (
								<EquipmentContractsTab contracts={equipment?.contracts ?? []} />
							),
						},
						{
							navLabel: "Documentation",
							component: (
								<EquipmentDocumentationTab
									documents={equipment?.eqpVintage?.documents ?? []}
								/>
							),
						},
					]}
				/>
			</Modal>
		)
	}
)
