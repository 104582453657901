import { useEffect, useRef } from "react"

export interface UseInitialIdConfig<V> {
	initialId: string | null | undefined
	onChange: (option: V) => void
	currentValue: V
	findInitialOption: () => V | undefined
}

/**
 * Uses a passed in initial ID to fire an onChange once to select an option with
 * that ID. Lets you initially populate a Select component based on just an object ID,
 * even when the component's state uses the full object.
 */
export const useInitialId = <V>({
	initialId,
	currentValue,
	findInitialOption,
	onChange,
}: UseInitialIdConfig<V>): void => {
	const canSetInitial = useRef(true)

	// Once current value has been truthy, we shouldn't try to set anything anymore.
	useEffect(() => {
		if (!!currentValue && canSetInitial.current === true) {
			canSetInitial.current = false
		}
	}, [currentValue])

	useEffect(() => {
		if (canSetInitial.current === true && !!initialId && !currentValue) {
			const initialOption = findInitialOption()

			if (initialOption) {
				onChange(initialOption)
				canSetInitial.current = false
			}
		}
	}, [currentValue, findInitialOption, initialId, onChange])
}
