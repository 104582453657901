import { css } from "@emotion/react"

export const cssMixins = {
	adaptMuiInputStyle: css`
		.MuiOutlinedInput-root {
			border-radius: 0;
			border-width: 1px;
			transition: border-color 200ms ease-out;
		}
		.MuiOutlinedInput-adornedEnd {
			padding-right: 0;
		}
		.MuiInputAdornment-positionEnd {
			.MuiButtonBase-root {
				svg {
					height: 1.25rem;
					width: 1.25rem;
					opacity: 0.75;
				}
			}
		}
		input {
			padding: 0.425rem 0.5rem;
		}
	`,
	breakAnywhereStyle: css`
		word-break: break-all;
	`,
	breakWord: css`
		word-break: break-word;
	`,
	hidden: css`
		position: relative;
		height: 0;
		opacity: 0;
		margin: 0;
		pointer-events: none;
		z-index: -1;
	`,
	nonMuiFormElementStyle: css`
		&:disabled {
			opacity: 0.5;
		}
	`,
	noWrap: css`
		white-space: nowrap;
		flex-shrink: 0;
	`,
	preserveLineBreaks: css`
		white-space: pre-wrap;
	`,
	ellipsisOverflow: css`
		display: inline-block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	`,
}
