import React from "react"

import { css } from "@emotion/react"

export const EmptyValueDash: React.FC = () => {
	return <span css={style}>&mdash;</span>
}

const style = css`
	opacity: 0.25;
`
