import React, { useEffect, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { trackEvent, TrackingEvent } from "@ncs/web-legos"

import {
	isNonParentCustomerPart,
	NonParentCustomerPart,
	useCustomerPart,
	useIsUser,
	useOnlinePartDetails,
	UserId,
} from "@ncs/ncs-api"
import {
	Box,
	Button,
	Divider,
	EmptyValueDash,
	EventTracker,
	encodeUrlState,
	Heading,
	Link,
	Paragraph,
	PartImage,
	Price,
	useChangeCallback,
	useScrollToTop,
	useUrlState,
} from "@ncs/web-legos"

import { useShopContext } from "~/contexts"
import {
	AddedToCartModal,
	PageContentWrapper,
	PartPreviewRow,
	QuantityControl,
	QuickOrderCtaWide,
} from "~/shared-components"

import { PartChildSelector } from "./components"

export type PartDetailUrlState = {
	childId: string | null
}

export const PartDetail: React.FC = () => {
	const isDb = useIsUser(UserId.DrivenBrands)
	const history = useHistory()
	const scrollToTop = useScrollToTop()
	const { partId } = useParams<{ partId: string }>()
	// The part that we're immediately pointed to via the ID in the URL.
	const [initialPart, loadingInitialPart, { query }] = useCustomerPart(partId)
	const [partDetails, loadingPartDetails] = useOnlinePartDetails(partId)
	const [localQuantity, setLocalQuantity] = useState(1)
	const [shopState, shopDispatch] = useShopContext()
	const [showAddedToCartModal, setShowAddedToCartModal] = useState(false)

	// Store the selected child's ID.
	const [{ childId: selectedChildId }, { updateUrlValue }] = useUrlState<PartDetailUrlState>({
		childId: null,
	})
	// Fetch the full CustomerPart for the selected child.
	const [selectedChildPart, loadingChildPartRaw] = useCustomerPart(selectedChildId)
	// Loading state of child part should include waiting for its parent too.
	const loadingChildPart = loadingInitialPart || loadingChildPartRaw

	/** This is the actual part we'd add to the cart after figuring out if it's
	 * a parent or not, and if so, selecting the child. */
	const selectedPart: NonParentCustomerPart | null = useMemo(() => {
		if (selectedChildPart && isNonParentCustomerPart(selectedChildPart)) {
			return selectedChildPart
		}
		if (!!initialPart && !initialPart.isParent && isNonParentCustomerPart(initialPart)) {
			return initialPart
		}

		return null
	}, [initialPart, selectedChildPart])

	const alreadyInCartTally = useMemo(() => {
		return shopState.cart.reduce(
			(prev, { part: partInCart, quantity }) =>
				partInCart.id === selectedPart?.id ? prev + quantity : prev,
			0
		)
	}, [selectedPart, shopState.cart])

	const handleAddToCart = () => {
		if (selectedPart) {
			// Add to cart.
			shopDispatch({
				type: "add part to cart",
				payload: {
					part: selectedPart,
					quantity: localQuantity,
				},
			})
			// Show the modal.
			setShowAddedToCartModal(true)
			// Reset our local quantity.
			setLocalQuantity(1)

			trackEvent(TrackingEvent.ADD_TO_CART_PDP, {
				id: selectedPart.id,
				name: selectedPart?.part?.description,
			})
		}
	}

	// If we have child parts but no selected child, then we'll pick one by default if possible.
	useEffect(() => {
		if (
			initialPart?.isParent &&
			!selectedChildId &&
			!!partDetails &&
			partDetails.children.length > 0
		) {
			updateUrlValue("childId", partDetails.children[0].onlinePartId.toString())
		}
	}, [initialPart?.isParent, partDetails, selectedChildId, updateUrlValue])

	// Add the part to the Recent Parts list.
	useChangeCallback(initialPart, (part) => {
		if (part && !part.isChild) {
			shopDispatch({
				type: "add part to recent parts",
				payload: part.id,
			})
		}
	})

	useEffect(() => {
		scrollToTop()
	}, [partId, scrollToTop])

	// If we came to the page with the ID of a child part in the URL, we should
	// redirect to its parent.
	if (initialPart?.isChild && partDetails?.parentId) {
		return (
			<Redirect
				to={`/shop/parts/${partDetails.parentId}${encodeUrlState<PartDetailUrlState>({
					childId: initialPart.id,
				})}`}
			/>
		)
	}

	return (
		<PageContentWrapper
			title={initialPart?.title}
			breadcrumbs={[
				{ name: "Shop", to: "/shop" },
				{ name: "Search", to: "/shop/search" },
				{ name: initialPart?.title ?? "" },
			]}
			showNotFound={query.status === "error"}
		>
			<Button
				icon="long-arrow-left"
				containerProps={{ mb: 3 }}
				onClick={() => history.goBack()}
			>
				Back
			</Button>

			<div css={partContainerStyle}>
				<PartImage
					isLoading={loadingInitialPart}
					textAlign="center"
					src={initialPart?.imageUrl}
					alt={initialPart?.title || initialPart?.part?.description}
					skeleton={{
						size: 300,
						containerProps: {
							height: 20,
						},
					}}
				/>

				<div>
					<Heading variant="h2" isLoading={loadingInitialPart}>
						{initialPart?.title}
					</Heading>

					<Divider my={2} />

					<ul css={detailsListStyle}>
						<li>
							<span>Price</span>
							<Price
								price={selectedPart?.netPrice ?? ""}
								nonDiscountedPrice={selectedPart?.price}
								isLoading={
									loadingInitialPart || loadingChildPart || loadingPartDetails
								}
							/>
						</li>
						<li>
							<span>Part Number</span>
							<Paragraph isLoading={loadingChildPart}>
								{selectedPart?.onlinePartNumber}
							</Paragraph>
						</li>
						<li>
							<span>Description</span>
							<Paragraph isLoading={loadingInitialPart}>
								{initialPart?.description}
							</Paragraph>
						</li>
						<li>
							<span>Brand</span>
							<Paragraph isLoading={loadingPartDetails}>
								{partDetails?.brands
									.map((brand) => brand.brandName)
									.join(", ") || <EmptyValueDash />}
							</Paragraph>
						</li>
						<li>
							<span>System</span>
							<Paragraph isLoading={loadingPartDetails}>
								{partDetails?.systems
									.map((system) => system.systemName)
									.join(", ") || <EmptyValueDash />}
							</Paragraph>
						</li>
						<li>
							<span>Categories</span>
							<Paragraph isLoading={loadingPartDetails}>
								{partDetails?.categories
									.map((category) => category.categoryName)
									.join(", ") || <EmptyValueDash />}
							</Paragraph>
						</li>
					</ul>

					{initialPart?.isParent && !!partDetails && (
						<>
							<Divider my={2} />

							<PartChildSelector
								childParts={partDetails.children}
								selectedChildId={selectedChildId}
								setSelectedChildId={(newId) => updateUrlValue("childId", newId)}
								loadingPartDetails={loadingPartDetails}
							/>
						</>
					)}

					<Divider my={2} />

					<QuantityControl value={localQuantity} onChange={setLocalQuantity} />

					<Box mt={2} mb={3}>
						<Button
							variant="primary-cta"
							icon="shopping-cart"
							iconFamily="solid"
							width="100%"
							onClick={handleAddToCart}
							disabled={
								localQuantity < 1 ||
								loadingInitialPart ||
								loadingChildPart ||
								loadingPartDetails ||
								(initialPart?.isParent && !partDetails?.children.length)
							}
						>
							Add To Cart
						</Button>
					</Box>

					{alreadyInCartTally > 0 && (
						<div>
							<Paragraph display="inline-block" mr={0.5}>
								You currently have {alreadyInCartTally} of this item in your cart.
							</Paragraph>
							<EventTracker event={TrackingEvent.GO_TO_CART}>
								<Link to="/shop/cart-summary" icon="shopping-cart">
									Go to cart
								</Link>
							</EventTracker>
						</div>
					)}
				</div>
			</div>

			<Divider mt={5} mb={10} />

			<PartPreviewRow
				partIds={shopState.recentlyViewedPartIds.filter((id) => id !== partId)}
				heading="Recently Viewed"
				headingVariant="h3"
			/>
			<PartPreviewRow
				partIds={(partDetails?.relatedParts ?? [])
					.map((p) => p.id)
					.filter((id) => id !== partId)}
				heading="Related Products"
				headingVariant="h3"
			/>

			{isDb === false && <QuickOrderCtaWide />}

			<AddedToCartModal
				isOpen={showAddedToCartModal}
				partId={selectedPart?.id}
				onClose={() => setShowAddedToCartModal(false)}
			/>
		</PageContentWrapper>
	)
}

const partContainerStyle = (theme: Theme) => css`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2.5rem;
	${theme.breakpoints.down("sm")} {
		grid-template-columns: 1fr;
	}
`
const detailsListStyle = (theme: Theme) => css`
	li {
		display: flex;
		margin-bottom: 0.5rem;
		${theme.breakpoints.down("sm")} {
			display: block;
		}
		> span {
			width: 10rem;
			font-weight: bold;
			padding-right: 2rem;
			flex-shrink: 0;
		}
		> div {
			flex-grow: 1;
		}
	}
`
