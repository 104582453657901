import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiGetQueryOptions,
	ApiPatchMutation,
	ApiPatchMutationOptions,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	usePatchRequest,
	usePostRequest,
} from "../../request-hooks"
import {
	AlertType,
	Contact,
	ContactAlertInfo,
	ContactInfoPatch,
	ContactPatch,
	EmailsUrlPaths,
	NewContactPost,
} from "./types"

export const useContacts = (options?: ApiGetQueryOptions<Contact[]>): ApiGetQuery<Contact[]> => {
	const [contacts, loading, extras] = useGetRequest<Contact[]>(
		[EmailsUrlPaths.Emails, EmailsUrlPaths.ContactUrl],
		options
	)

	return [contacts, loading, extras]
}

export const useCreateNewContact = (): ApiPostMutation<NewContactPost> => {
	return usePostRequest([EmailsUrlPaths.Emails, EmailsUrlPaths.ContactUrl], {
		keyToInvalidate: [EmailsUrlPaths.Emails],
	})
}

export const useUpdateContact = (): ApiPatchMutation<ContactPatch> => {
	return usePatchRequest([EmailsUrlPaths.Emails, EmailsUrlPaths.ContactUrl], {
		keyToInvalidate: [EmailsUrlPaths.Emails, EmailsUrlPaths.ContactUrl],
	})
}

export const useDeleteContact = (): ApiDeleteMutation => {
	return useDeleteRequest([EmailsUrlPaths.Emails, EmailsUrlPaths.ContactUrl], {
		keyToInvalidate: [EmailsUrlPaths.Emails],
	})
}

export const useUpdateContactChoices = (
	options?: ApiPatchMutationOptions<ContactInfoPatch>
): ApiPatchMutation<ContactInfoPatch> => {
	return usePatchRequest([EmailsUrlPaths.Emails, EmailsUrlPaths.ContactInfo], {
		keyToInvalidate: [EmailsUrlPaths.Emails],
		validatePayload: (data) => {
			if (!data.contactId && !data.userId) {
				throw new Error("Must supply either a contact ID or a user ID")
			}
		},
		...options,
	})
}

export const useAlertTypes = (): ApiGetQuery<AlertType[]> => {
	const [alertTypes, loading, extras] = useGetRequest<AlertType[]>([
		EmailsUrlPaths.Emails,
		EmailsUrlPaths.Alerts,
	])

	return [alertTypes, loading, extras]
}

export const useAlertContactChoices = (
	siteId?: string | null
): ApiGetQuery<ContactAlertInfo[]> => {
	return useGetRequest([EmailsUrlPaths.Emails, EmailsUrlPaths.ContactAlertInfoUrl], {
		params: {
			customer: siteId,
		},
	})
}
