import axios from "axios"
import X2JS from "x2js"
import { z } from "zod"

let _userName: string | null = null

const UspsUserNameSchema = z.string().min(1)

/** Import this above your React tree and call it. */
export const setUspsUserName = (userName: string | undefined): void => {
	const parsed = UspsUserNameSchema.safeParse(userName)

	if (!parsed.success) {
		throw new Error("Invalid USPS user name passed to setUspsUserName.")
	}

	_userName = parsed.data
}

export interface UspsAddress {
	name?: string
	address1?: string // Suite, etc
	address2: string // The actual address
	city?: string
	state?: string
	zip?: string
}

export interface UspsResponse {
	AddressValidateResponse: {
		Address: {
			FirmName?: string
			Address1?: string
			Address2: string
			City?: string
			State?: string
			Zip5?: string
			Zip4?: string
			DeliveryPoint?: string
			ReturnText?: string
			CarrierRoute?: string
			Footnotes?: string
			DPVConfirmation?: string
			DPVCMRA?: string
			DPVFootnotes?: string
			Business?: string
			CentralDeliveryPoint?: string
			Vacant?: string
			_ID?: string
			Error?: {
				Description: string
			}
		}
	}
}

export const uspsAddressVerify = async (address: UspsAddress): Promise<UspsAddress> => {
	const parsed = UspsUserNameSchema.safeParse(_userName)

	if (!parsed.success) {
		throw new Error("USPS username is not valid. You probably forgot to call setUspsUserName.")
	}

	const userName = parsed.data

	const x2js = new X2JS()

	const xmlObj = {
		AddressValidateRequest: {
			_USERID: userName,
			Revision: 1,
			Address: {
				_ID: "0",
				FirmName: address.name ?? {},
				Address1: address.address1 ?? {},
				Address2: address.address2,
				City: address.city ?? {},
				State: address.state ?? {},
				Zip5: address.zip ?? {},
				Zip4: {},
			},
		},
	}

	const xml = x2js.js2xml(xmlObj)

	const response = await axios.get(
		`https://secure.shippingapis.com/ShippingAPI.dll?API=Verify&XML=${xml}`
	)

	const data = x2js.xml2js<UspsResponse>(response.data)
	const error = data.AddressValidateResponse.Address.Error?.Description

	if (error) {
		return Promise.reject(error)
	} else {
		return Promise.resolve({
			name: data.AddressValidateResponse.Address.FirmName,
			address1: data.AddressValidateResponse.Address.Address1,
			address2: data.AddressValidateResponse.Address.Address2,
			city: data.AddressValidateResponse.Address.City,
			state: data.AddressValidateResponse.Address.State,
			zip: `${data.AddressValidateResponse.Address.Zip5}${
				data.AddressValidateResponse.Address.Zip4 ?
					`-${data.AddressValidateResponse.Address.Zip4}`
				:	""
			}`,
		})
	}
}
