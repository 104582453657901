import qs from "query-string"

import { arrayWrap } from "@ncs/ts-utils"

import { MutationUrlConfig } from "../request-hooks"
import { preparePortalParams } from "./prepare-portal-params"

export const makeMutationEndpoint = <BodyData = void, ParamsObject = void>(
	baseUrl: string | string[],
	config: MutationUrlConfig<BodyData, ParamsObject>
): string => {
	let url = arrayWrap(baseUrl).join("/")

	if (typeof config === "string") {
		url = `${url}/${config}`
	} else {
		const { id, urlSegmentsAfterId, params, prepareParamOptions } = config

		if (id) {
			url = `${url}/${id}`
		}
		if (urlSegmentsAfterId) {
			url = `${url}/${arrayWrap(urlSegmentsAfterId).join("/")}`
		}
		if (params) {
			const preparedParams = preparePortalParams(params, prepareParamOptions)
			url = `${url}?${qs.stringify(preparedParams)}`
		}
	}

	return `${url}/`
}
