import React, { useCallback, useState } from "react"

import { captureException } from "@sentry/minimal"
import { useHistory } from "react-router-dom"

import {
	createDocumentToken,
	CustomerDispatch,
	CustomerDispatchDocument,
	getDocumentUrlForToken,
	makeApiErrorMessage,
	useCustomerDispatchDocument,
	useUpdateCustomerDispatch,
} from "@ncs/ncs-api"
import { tryToFormatPhone } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	Divider,
	EmptyValueDash,
	encodeUrlState,
	ErrorText,
	GridContainer,
	GridItem,
	Heading,
	Information,
	LabeledData,
	LoadingSpinner,
	Paragraph,
} from "@ncs/web-legos"

import { ServiceRequestsMyRequestsTabUrlState } from "./ServiceRequestsMyRequestsTab"

export interface ServiceRequestDetailDetailsTabProps {
	dispatch?: CustomerDispatch
}

export const ServiceRequestDetailDetailsTab: React.FC<ServiceRequestDetailDetailsTabProps> = ({
	dispatch,
}) => {
	const history = useHistory()
	const [showCloseConfirmation, setShowCloseConfirmation] = useState(false)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [document, loadingDocument] = useCustomerDispatchDocument(dispatch?.id ?? null)
	const updateDispatch = useUpdateCustomerDispatch()

	const onCloseRequest = async () => {
		if (dispatch?.id) {
			setIsSubmitting(true)
			setErrorMessage(null)
			try {
				await updateDispatch({
					updates: {
						statusId: "2",
						comments: "Service request closed by customer",
					},
					id: dispatch.id,
				})
				history.push({
					pathname: "/service-requests",
					search: encodeUrlState<ServiceRequestsMyRequestsTabUrlState>({
						showCloseConfirmation: 1,
					}),
				})
			} catch (e) {
				setErrorMessage(makeApiErrorMessage(e))
				console.error(e)
				captureException(e)
			} finally {
				setIsSubmitting(false)
			}
		}
	}

	const handleViewDocument = useCallback(async (doc: CustomerDispatchDocument) => {
		if (doc.id && doc.documentType) {
			try {
				await createDocumentToken(doc.id, doc.documentType).then(({ token }) => {
					const url = getDocumentUrlForToken(token)
					window.open(url, "_blank")
				})
			} catch (e) {
				console.error(e)
				captureException(e)
			}
		}
	}, [])

	if (!dispatch) {
		return <LoadingSpinner />
	}

	return (
		<GridContainer>
			<GridItem xs={12} sm={6}>
				<Box display="flex" flexDirection="column">
					<LabeledData label="Caller">
						{dispatch?.callerName ?? <EmptyValueDash />}
					</LabeledData>
					<LabeledData label="Call back number">
						{tryToFormatPhone(dispatch?.callBackNumber) || <EmptyValueDash />}
					</LabeledData>
					<LabeledData label="Call type">
						{dispatch?.callType?.description ?? <EmptyValueDash />}
					</LabeledData>
					<LabeledData label="Priority">
						{dispatch?.priority?.description ?? <EmptyValueDash />}
					</LabeledData>
					<LabeledData label="Symptoms">
						{dispatch?.symptoms ?? <EmptyValueDash />}
					</LabeledData>
				</Box>
			</GridItem>

			<GridItem xs={12} sm={6}>
				<Box mb={2}>
					<Heading variant="h5" icon="file-pdf" mb={1}>
						Documents
					</Heading>
					{loadingDocument && <LoadingSpinner />}
					{document && Object.keys(document).length > 0 && (
						<Box display="flex">
							<Button icon="download" onClick={() => handleViewDocument(document)}>
								Download {document.name}
							</Button>
						</Box>
					)}
					{document && Object.keys(document).length === 0 && (
						<Paragraph color="secondary" small>
							No documents found.
						</Paragraph>
					)}
				</Box>

				{dispatch?.statusId === "1" && (
					<>
						<Divider />
						<Information>
							We automatically close service requests upon resolution, but if you
							have resolved the issue yourself you may close your request here. This
							will cancel any upcoming service visits, and any already completed work
							will be invoiced.
						</Information>

						{showCloseConfirmation ?
							<Box mt={1}>
								<AnimatedEntrance show>
									<Paragraph mb={1}>Confirm: Close this request? </Paragraph>
									<Button
										icon="times"
										onClick={() => setShowCloseConfirmation(false)}
									>
										Cancel
									</Button>
									<Button
										icon="check"
										containerProps={{ ml: 2 }}
										onClick={onCloseRequest}
										isLoading={isSubmitting}
									>
										Yes, close request
									</Button>
									{!!errorMessage && (
										<ErrorText mt={1}>{errorMessage}</ErrorText>
									)}
								</AnimatedEntrance>
							</Box>
						:	<Button
								icon="check"
								containerProps={{ mt: 1 }}
								onClick={() => setShowCloseConfirmation(true)}
							>
								Close this service request?
							</Button>
						}
					</>
				)}
			</GridItem>
		</GridContainer>
	)
}
