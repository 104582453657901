import React, { useState } from "react"

import { css, Theme } from "@emotion/react"
import { Checkbox } from "@material-ui/core"

import {
	AlertCategory,
	AlertType,
	ContactAlertInfo,
	makeApiErrorMessage,
	useUpdateContactChoices,
} from "@ncs/ncs-api"
import { addIfNew } from "@ncs/ts-utils"
import {
	Box,
	Heading,
	Icon,
	IconName,
	LoadingSpinner,
	SkeletonRows,
	Tooltip,
	TreeItem,
	TreeView,
	useScreenSizeMatch,
	useToast,
} from "@ncs/web-legos"

import { AlertRecipient } from "../alerts-tab-util"
import { EditContactModal } from "./EditContactModal"

export interface AlertsCategoryTreeProps {
	alertCategory: AlertCategory
	alerts: AlertType[]
	recipients: AlertRecipient[]
	contactAlertChoices?: ContactAlertInfo[]
	selectedSiteId: string | null
	alertsLoading?: boolean
}

export const AlertsCategoryTree: React.FC<AlertsCategoryTreeProps> = ({
	alertCategory,
	alerts,
	recipients,
	contactAlertChoices,
	selectedSiteId,
	alertsLoading,
}) => {
	const { makeErrorToast } = useToast()
	const screenIsTiny = useScreenSizeMatch("xs")
	const updateContactChoices = useUpdateContactChoices()
	const [showEditRecipientModal, setShowEditRecipientModal] = useState(false)
	const [alertsBeingSaved, setAlertsBeingSaved] = useState<number[]>([])

	const onClickCheckbox = async (
		recipient: AlertRecipient,
		alertTypeId: number,
		mode: "email" | "sms" | "push",
		newState: boolean
	) => {
		try {
			setAlertsBeingSaved((prev) => addIfNew(alertTypeId, prev))
			await updateContactChoices({
				updates: {
					userId: recipient.userId,
					contactId: recipient.contactId,
					alertTypeId,
					siteIds: selectedSiteId ? [selectedSiteId] : recipient.siteIds,
					[mode]: newState,
				},
			})
		} catch (e) {
			makeErrorToast(makeApiErrorMessage(e))
		} finally {
			setAlertsBeingSaved((prev) => prev.filter((id) => id !== alertTypeId))
		}
	}

	return (
		<>
			<Heading mb={0.75} icon={iconLookup[alertCategory]}>
				{headingLookup[alertCategory]}
			</Heading>

			<TreeView>
				<Box ml={!screenIsTiny ? 2 : 0} mb={2}>
					{alertsLoading && <SkeletonRows width={14} />}

					{alerts.map((alert) => {
						const isSaving = alertsBeingSaved.includes(alert.id)

						return (
							<TreeItem
								key={alert.id}
								nodeId={alert.description}
								label={alert.description}
							>
								<Box
									ml={!screenIsTiny ? 1.5 : 0}
									mt={0.5}
									mb={1}
									css={css`
										opacity: ${isSaving ? ".5" : "1"};
										pointer-events: ${isSaving ? "none" : undefined};
									`}
								>
									{isSaving && (
										<div css={loadingSpinnerContainerCss}>
											<LoadingSpinner />
										</div>
									)}
									<table css={tableStyle}>
										<thead>
											<tr>
												<th />
												<th>
													{!screenIsTiny && <Icon icon="envelope" />}{" "}
													Email
												</th>
												<th>
													{!screenIsTiny && <Icon icon="sms" />} Text SMS
												</th>
												<th>
													{!screenIsTiny && <Icon icon="bell" />} App
													Notification
												</th>
											</tr>
										</thead>
										<tbody>
											{recipients.map((recipient) => {
												const alertChoices = (
													contactAlertChoices ?? []
												).find(
													(choices) => choices.email === recipient.email
												)
												const emailChecked =
													alertChoices?.alerts.email.includes(
														String(alert.id)
													) ?? false
												const smsChecked =
													alertChoices?.alerts.sms.includes(
														String(alert.id)
													) ?? false
												const pushChecked =
													alertChoices?.alerts.push.includes(
														String(alert.id)
													) ?? false

												return (
													<tr key={recipient.email}>
														<td className="name">
															<Box
																display="flex"
																alignItems="baseline"
																gap={0.5}
															>
																<div>
																	{recipient.firstName}{" "}
																	{recipient.lastName}
																</div>
																{emailChecked &&
																	!recipient.email && (
																		<Tooltip title="No email address on file. User will not be able to receive email alerts.">
																			<Icon
																				icon="envelope"
																				color="error"
																				fontSize={0.75}
																			/>
																		</Tooltip>
																	)}
																{smsChecked &&
																	!recipient.phone && (
																		<Tooltip title="No phone number on file. User will not be able to receive text message alerts.">
																			<Icon
																				icon="phone"
																				color="error"
																				fontSize={0.75}
																			/>
																		</Tooltip>
																	)}
															</Box>
														</td>
														<td className="checkbox">
															<Checkbox
																color="primary"
																disabled={isSaving}
																checked={emailChecked}
																onChange={(e) =>
																	onClickCheckbox(
																		recipient,
																		alert.id,
																		"email",
																		e.target.checked
																	)
																}
															/>
														</td>
														<td className="checkbox">
															<Checkbox
																color="primary"
																checked={smsChecked}
																onChange={(e) =>
																	onClickCheckbox(
																		recipient,
																		alert.id,
																		"sms",
																		e.target.checked
																	)
																}
															/>
														</td>
														<td className="checkbox">
															<Checkbox
																color="primary"
																checked={pushChecked}
																onChange={(e) =>
																	onClickCheckbox(
																		recipient,
																		alert.id,
																		"push",
																		e.target.checked
																	)
																}
															/>
														</td>
													</tr>
												)
											})}
										</tbody>
									</table>
								</Box>
							</TreeItem>
						)
					})}
				</Box>
			</TreeView>

			<EditContactModal
				isOpen={showEditRecipientModal}
				onClose={() => setShowEditRecipientModal(false)}
				contact={null}
			/>
		</>
	)
}

const tableStyle = (theme: Theme) => css`
	width: 100%;
	max-width: 60rem;
	th,
	td {
		text-align: left;
	}
	th {
		font-size: 0.85rem;
		vertical-align: bottom;
		svg {
			margin-right: 0.25rem;
		}
	}
	.name {
		padding-right: 5rem;
		width: 17rem;
		${theme.breakpoints.down("md")} {
			width: 13rem;
		}
		${theme.breakpoints.down("sm")} {
			width: 12rem;
			padding-right: 3rem;
		}
		${theme.breakpoints.down("xs")} {
			width: auto;
			padding-right: 1rem;
		}
	}
	.checkbox {
		vertical-align: top;
		.MuiIconButton-root {
			position: relative;
			left: -0.8rem;
		}
		svg {
			width: 0.75em;
			height: 0.75em;
		}
	}
`
const loadingSpinnerContainerCss = css`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const headingLookup: Record<AlertCategory, string> = {
	[AlertCategory.MACHINE]: "Machines",
	[AlertCategory.CHEMICAL]: "Chemicals",
	[AlertCategory.VACUUM]: "Vacuums",
	[AlertCategory.DISPATCH]: "Service Requests",
	[AlertCategory.PART_ORDER]: "Orders",
}

const iconLookup: Record<AlertCategory, IconName> = {
	[AlertCategory.MACHINE]: "car-wash",
	[AlertCategory.CHEMICAL]: "prescription-bottle",
	[AlertCategory.VACUUM]: "hurricane",
	[AlertCategory.DISPATCH]: "user-hard-hat",
	[AlertCategory.PART_ORDER]: "box",
}
