import React, { useMemo } from "react"

import { css, useTheme } from "@emotion/react"

import { Box, BoxProps } from "./Box"

export interface GridItemProps extends BoxProps {
	xs?: number
	sm?: number
	md?: number
	lg?: number
	xl?: number
	order?: number
}

export const GridItem: React.FC<GridItemProps> = ({
	xs,
	sm,
	md,
	lg,
	xl,
	order,
	children,
	...rest
}) => {
	const theme = useTheme()

	const columnStyle = useMemo(() => {
		return css`
			order: ${order};
			${theme.breakpoints.up("xs")} {
				grid-column-end: span ${xs ?? sm ?? md ?? lg ?? xl};
			}
			${theme.breakpoints.up("sm")} {
				grid-column-end: span ${sm ?? md ?? lg ?? xl ?? xs};
			}
			${theme.breakpoints.up("md")} {
				grid-column-end: span ${md ?? lg ?? xl ?? sm ?? xs};
			}
			${theme.breakpoints.up("lg")} {
				grid-column-end: span ${lg ?? xl ?? md ?? sm ?? xs};
			}
			${theme.breakpoints.up("xl")} {
				grid-column-end: span ${xl ?? lg ?? md ?? sm ?? xs};
			}
		`
	}, [order, xs, sm, md, lg, xl, theme.breakpoints])

	return (
		<Box css={columnStyle} {...rest}>
			{children}
		</Box>
	)
}
