import { parsePhoneNumberFromString } from "libphonenumber-js"

export const validatePhone = (value: string | number | null | undefined): boolean => {
	const result = parsePhoneNumberFromString(String(value ?? ""), "US")

	return result?.isValid() || false
}

export const formatPhone = (value: string): string | undefined => {
	return parsePhoneNumberFromString(value ?? "", "US")?.formatNational()
}

/**
 * If a given string is a valid US phone number, that will be formatted and returned.
 * Otherwise, just return the value passed in unmodified. For consistency's sake on the
 * return, it will also stringify a number.
 */
export function tryToFormatPhone(value: number | string): string
export function tryToFormatPhone(value: number | string | null): string | null
export function tryToFormatPhone(value: number | string | undefined): string | undefined
export function tryToFormatPhone(
	value: number | string | null | undefined
): string | null | undefined
export function tryToFormatPhone(
	value: number | string | null | undefined
): string | null | undefined {
	const parsed = parsePhoneNumberFromString(String(value ?? ""), "US")

	if (parsed?.isValid()) {
		return parsed.formatNational()
	}

	if (typeof value === "number") return String(value)

	return value
}
