import { FC } from "react"

import { ExtendableModalProps, Heading, Modal, Paragraph } from "@ncs/web-legos"

import { invoiceExamples } from "~/images/other"

export interface CurrentCustomerHelpModalProps extends ExtendableModalProps {}

export const CurrentCustomerHelpModal: FC<CurrentCustomerHelpModalProps> = ({ ...rest }) => {
	return (
		<Modal {...rest} title="Customer and invoice numbers">
			<Paragraph mb={1}>
				You can find these numbers on a recent invoice or on most documents from NCS or one
				of our other brands.
			</Paragraph>
			<Paragraph mb={1}>Here's an example from an NCS invoice:</Paragraph>
			<img src={invoiceExamples} alt="Customer and invoice numbers on an NCS invoice" />
			<Heading variant="h3" bold icon="question-circle" mt={3} mb={1}>
				I don't have either of these
			</Heading>
			<Paragraph>
				If you can't find either of these, no problem. Just leave them blank for now and
				we'll help you connect your account at a later date.
			</Paragraph>
		</Modal>
	)
}
