import { Dispatch, useCallback, useEffect, useMemo, useReducer } from "react"

import { captureException } from "@sentry/react"
import { useLocation } from "react-router-dom"
import { ZodObject, ZodRawShape } from "zod"

import { useAuth } from "@ncs/ncs-api"

import { usePrevious } from "./use-previous"

/**
 * Like useReducer, except automatically syncs with localStorage.
 */
export const useLocalStorageReducer = <State, StateAction>(
	reducer: (state: State, action: StateAction) => State,
	defaultState: State,
	config: {
		keyOverride?: string
		/** Pass this in to fire your own function in the event that an auth change is detected. */
		onAuthChange?: (prevState: State, dispatch: Dispatch<StateAction>) => void
		zodSchema?: ZodObject<ZodRawShape>
	} = {}
): [State, Dispatch<StateAction>] => {
	const auth = useAuth()
	const currentAuthId = auth.user?.id ?? "anonymous"

	const { keyOverride, onAuthChange } = config

	// To avoid naming collisions, we'll use the URL pathname as the default key.
	// You can override that if you need to though, for example if you need
	// multiple things stored at the same URL, or if the URL is dynamically
	// generated.
	const { pathname } = useLocation()

	const key = useMemo(() => {
		// Also prepend everything with the user ID if authenticated, so that things are
		// scoped to whoever is logged in.
		return `${currentAuthId}-${keyOverride ? keyOverride : pathname}-reducer`
	}, [currentAuthId, keyOverride, pathname])

	const getStateFromStorage = useCallback(() => {
		try {
			// Try to get the item from storage.
			const itemAsString = window.localStorage.getItem(key)

			// If we didn't find the item, return default state.
			if (!itemAsString) {
				return defaultState
			}

			// Try to parse what we did find at the key.
			const item = JSON.parse(itemAsString)

			// If you passed in a zod schema, then try to validate with that.
			// If it doesn't work, delete it and return the default state.
			if (config.zodSchema) {
				const parseResult = config.zodSchema.safeParse(item)
				if (parseResult.success) {
					return item
				} else {
					console.warn(`Invalid local storage found at key ${key}. Removing.`)
					console.warn(parseResult.error)
					window.localStorage.removeItem(key)

					return defaultState
				}
			}

			// Otherwise, we'll just assume that what we found is correct. Yikes!
			// Spread it into the default state if we can, and then return.
			try {
				return {
					...defaultState,
					...item,
				}
			} catch {
				return item
			}
		} catch (e) {
			console.error(e)
			captureException(e)

			return defaultState
		}
	}, [config.zodSchema, defaultState, key])

	const [state, dispatch] = useReducer(reducer, getStateFromStorage())

	// Now you'll take this dispatch and use it, and that'll mutate the state here.
	// Keep local storage up to date whenever it changes. Using useEffect because
	// the state setting is async.
	const prevAuthId = usePrevious(currentAuthId)
	useEffect(() => {
		if (currentAuthId === prevAuthId) {
			window.localStorage.setItem(key, JSON.stringify(state))
		} else {
			// The user changed! Should we do something special instead?
			if (onAuthChange) {
				const prevUserStorageState = getStateFromStorage()
				onAuthChange(prevUserStorageState, dispatch)
			} else {
				// Otherwise just do the normal thing.
				window.localStorage.setItem(key, JSON.stringify(state))
			}
		}
	}, [key, state, currentAuthId, prevAuthId, getStateFromStorage, onAuthChange])

	return [state, dispatch]
}
