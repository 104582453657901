import React from "react"

import { ConnectedDevicesAtSite } from "@ncs/ncs-api"
import {
	EmptyValueDash,
	ExtendableModalProps,
	FormattedDate,
	GridContainer,
	GridItem,
	LabeledData,
	Modal,
	Tabs,
} from "@ncs/web-legos"

import { DeviceStatus } from "~/shared-components"

import {
	MachineModalEventsTab,
	MachineModalStatesTab,
	MachineModalWashCountsTab,
} from "./components"

export interface MachineDetailModalProps extends ExtendableModalProps {
	customer: ConnectedDevicesAtSite["customer"]
	device: ConnectedDevicesAtSite["devices"][number]
}

export const MachineDetailModal: React.FC<MachineDetailModalProps> = ({
	customer,
	device,
	...rest
}) => {
	return (
		<Modal
			{...rest}
			title={`${device.name} ${device.equipment?.vintage}`}
			titleDetail={customer.name}
			maxWidth="lg"
		>
			<DeviceStatus
				device={device}
				display="flex"
				columnGap={1}
				alignItems="center"
				ml={-0.35}
				mb={1}
				mt={0}
			/>
			<GridContainer>
				<GridItem sm={6} md={3}>
					<LabeledData label="Name">{device.name}</LabeledData>
				</GridItem>
				<GridItem sm={6} md={3}>
					<LabeledData label="Vintage">
						{device.equipment?.vintage || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={3}>
					<LabeledData label="Serial #">
						{device.equipment?.serialNumber || <EmptyValueDash />}
					</LabeledData>
				</GridItem>
				<GridItem sm={6} md={3}>
					<LabeledData label="Installed Date">
						<FormattedDate
							value={device.equipment?.installDate}
							formatDateProps={{ formatInUtc: true }}
						/>
					</LabeledData>
				</GridItem>
			</GridContainer>

			<Tabs
				panels={[
					{
						navLabel: "Wash Counts",
						component: <MachineModalWashCountsTab device={device} />,
					},
					{
						navLabel: "Device States",
						component: <MachineModalStatesTab device={device} />,
					},
					{
						navLabel: "Device Events",
						component: <MachineModalEventsTab device={device} />,
					},
				]}
			/>
		</Modal>
	)
}
