import React, { useMemo } from "react"

import { css, useTheme } from "@emotion/react"

import { Box, BoxProps } from "../layout"

export interface AnimatedEntranceProps extends Omit<BoxProps, "onClick" | "opacity"> {
	/**
	 * When should the content show?
	 */
	show: boolean
	direction?: "up" | "down" | "left" | "right" | "fade"
	duration?: number
	easing?: string
	delay?: number
	fade?: boolean
	/** Prevents the height of the animation from changing, but comes at the cost of the animation
	 * coming in from a hard, visible edge. */
	preventOverflow?: boolean
	className?: string
	outerContainerProps?: BoxProps
}

export const AnimatedEntrance: React.FC<AnimatedEntranceProps> = React.memo(
	({
		direction = "up",
		duration,
		easing,
		delay,
		fade = true,
		show = true,
		preventOverflow = false,
		className,
		outerContainerProps,
		children,
		...rest
	}) => {
		const { transitions } = useTheme()

		function getTransformFromDirection(
			transformDirection: AnimatedEntranceProps["direction"]
		) {
			switch (transformDirection) {
				case "up": {
					return "translateY(1rem)"
				}
				case "down": {
					return "translateY(-1rem)"
				}
				case "left": {
					return "translateX(50%)"
				}
				case "right": {
					return "translateX(-50%)"
				}
				case "fade": {
					return "translateX(0)"
				}
				default: {
					return "translateX(0)"
				}
			}
		}

		const overflowContainerCss = useMemo(() => {
			return css`
				position: relative;
				overflow: ${preventOverflow ? "hidden" : "visible"};
			`
		}, [preventOverflow])

		const animatedEntranceComputedStyle = useMemo(() => {
			return css`
				animation-duration: ${duration ?
					`${duration}ms`
				:	`${transitions.duration.enteringScreen}ms`};
				animation-timing-function: ${easing ? easing : transitions.easing.easeOut};
				animation-delay: ${delay ? `${delay}ms` : "0ms"};
				transform: ${getTransformFromDirection(direction)};
				opacity: ${fade ? 0 : 1};
			`
		}, [
			delay,
			direction,
			duration,
			easing,
			fade,
			transitions.easing.easeOut,
			transitions.duration.enteringScreen,
		])

		if (show) {
			return (
				<Box css={overflowContainerCss} {...outerContainerProps}>
					<Box
						{...rest}
						className={className}
						css={[animatedEntranceBaseStyle, animatedEntranceComputedStyle]}
					>
						{children}
					</Box>
				</Box>
			)
		}

		return <div />
	}
)

const animatedEntranceBaseStyle = css`
	position: relative;
	animation-name: move-to-position;
	animation-fill-mode: forwards;
`
