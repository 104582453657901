import React from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { titleCase } from "@ncs/ts-utils"

import { Textarea, TextareaProps } from "../inputs"

export interface TextareaFormFieldProps<TFieldValues extends FieldValues>
	extends Omit<TextareaProps, "value" | "onChange"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	placeholder?: string
	emptyValueFallback?: string | null
}

export const TextareaFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	placeholder,
	emptyValueFallback = null,
	...rest
}: TextareaFormFieldProps<TFieldValues>): React.ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<Textarea
						placeholder={placeholder}
						label={rest.label ?? titleCase(name) ?? undefined}
						{...rest}
						onBlur={onBlur}
						value={value != null ? String(value) : emptyValueFallback}
						onChange={(newValue) => onChange(newValue ?? emptyValueFallback)}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}
