import React, { ReactNode, useEffect, useMemo, useState } from "react"

import { css } from "@emotion/react"

import { IconButton } from "../buttons"
import { Box, BoxProps } from "../layout"
import { AnimatedEntrance } from "../transitions"
import { Label } from "./Label"
import { Paragraph, ParagraphProps } from "./Paragraph"

export interface LabeledDataProps extends BoxProps {
	label: string
	isLoading?: boolean
	className?: string
	paragraphProps?: ParagraphProps
	/** Render this instead of `children` when user clicks icon to edit. */
	editingRender?: (props: LabeledDataEditingRenderProps) => ReactNode
	/** Loading state for the onSaveEdit call. */
	isSavingEdit?: boolean
	/** Function to fire when user clicks the control to start editing. */
	onStartEdit?: () => void | Promise<void>
	/** Function to call when user clicks the check control when editing. */
	onSaveEdit?: () => void | Promise<void>
	/** Function to fire before calling onSaveEdit. Return a boolean for whether or not
	 * saving is allowed at this time. */
	validateEdit?: () => boolean
	/** Function to fire when user cancels out of the edit. */
	onCancelEdit?: () => void
	/** Specify the width property of the container when editing
	 * @default "100%"
	 */
	editingWidth?: string | number
}

export interface LabeledDataEditingRenderProps {
	label: string
	placeholder: string | undefined
	fillContainer: boolean
	onReturn: () => void | Promise<void>
	autoFocus: boolean
	disabled: boolean
}

export const LabeledData: React.FC<LabeledDataProps> = ({
	label,
	isLoading,
	className,
	paragraphProps,
	editingRender,
	onStartEdit,
	onSaveEdit,
	isSavingEdit: isSavingEditProp,
	validateEdit,
	onCancelEdit,
	editingWidth = "100%",
	children,
	...rest
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const [isSavingEditLocal, setIsSavingLocal] = useState(false)

	const isSavingEdit = isSavingEditProp || isSavingEditLocal

	const enterEdit = () => {
		setIsEditing(true)
		if (onStartEdit) void onStartEdit()
	}

	// Fired both when canceling and when done editing.
	const exitEdit = () => {
		setIsEditing(false)
	}

	const handleSaveEdit = async () => {
		if (!onSaveEdit) {
			throw new Error("no onSaveEdit function passed")
		}

		if (validateEdit) {
			const canSave = validateEdit()
			if (!canSave) {
				return
			}
		}
		setIsSavingLocal(true)
		await onSaveEdit()
		setIsSavingLocal(false)
		exitEdit()
	}

	useEffect(() => {
		const listenForEsc = (e: KeyboardEvent) => {
			if (e?.key?.toLowerCase() === "escape") {
				if (onCancelEdit) onCancelEdit()
				exitEdit()
			}
		}

		window.addEventListener("keyup", listenForEsc)

		return () => window.removeEventListener("keyup", listenForEsc)
	}, [onCancelEdit])

	const isValid = useMemo(() => {
		return isEditing && validateEdit ? validateEdit() : true
	}, [validateEdit, isEditing])

	const canEdit = !!editingRender && !!onSaveEdit

	return (
		<Box
			display="inline-flex"
			flexDirection="column"
			mb={1}
			width={isEditing ? editingWidth : undefined}
			{...rest}
			className={className}
			css={style}
		>
			{!isEditing && (
				<>
					<Label htmlFor={label}>{label}</Label>
					<Box display="flex" flexWrap="wrap" columnGap={0.5}>
						<Paragraph id={label} isLoading={isLoading} {...paragraphProps}>
							{children}
						</Paragraph>
						{canEdit && (
							<IconButton
								title="Edit"
								icon="pencil"
								onClick={enterEdit}
								color="primary"
								css={editButtonCss}
							/>
						)}
					</Box>
				</>
			)}
			{canEdit && isEditing && (
				<>
					<Label htmlFor={label}>{label}</Label>
					{editingRender({
						label: "",
						placeholder: `${label}...`,
						fillContainer: true,
						autoFocus: true,
						onReturn: handleSaveEdit,
						disabled: !!isSavingEdit,
					})}
					<Box mt={-0.5} ml={-0.15}>
						<AnimatedEntrance show direction="down">
							<IconButton
								icon="check"
								title="Save changes"
								onClick={handleSaveEdit}
								isLoading={isSavingEdit}
								color={isValid ? "dark-success" : "light-gray"}
								background="primary"
								disabled={!isValid || isSavingEdit}
							/>
							<IconButton
								icon="times"
								title="Cancel"
								onClick={() => {
									if (onCancelEdit) onCancelEdit()
									exitEdit()
								}}
								color="gray"
								disabled={isSavingEdit}
							/>
						</AnimatedEntrance>
					</Box>
				</>
			)}
		</Box>
	)
}

const style = css`
	label {
		margin-bottom: 0.15rem;
	}
`
const editButtonCss = css`
	position: relative;
	top: -0.5rem;
	left: -0.5rem;
`
