import React, { FC, memo, useMemo } from "react"

import { css, useTheme } from "@emotion/react"
import { Checkbox as MuiCheckbox } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

import { accessBoolean, accessString, BooleanAccessor, StringAccessor } from "@ncs/ts-utils"

import { ControlLabel } from "../typography"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export interface CheckboxGroupProps<Row> extends Omit<FieldContainerProps, "label"> {
	onChange?: (changedRow: Row, newState: boolean) => void
	rows: Row[]
	/**
	 * Show the list of checkboxes in columns?
	 */
	columnCounts?: Partial<Record<Breakpoint, number>>
	/**
	 * Get the checkbox value.
	 * @default "value"
	 */
	valueAccessor?: StringAccessor<Row>
	/**
	 * Get the checkbox label.
	 * @default "label"
	 */
	labelAccessor?: StringAccessor<Row>
	/**
	 * Should the checkbox be checked?
	 * @default "checked"
	 */
	checkedAccessor?: BooleanAccessor<Row>
	/**
	 * Should the checkbox be disabled?
	 * @default "disabled"
	 */
	disabledAccessor?: BooleanAccessor<Row>
	/**
	 * Should the checkbox be visible at all?
	 * @default "hidden"
	 */
	hiddenAccessor?: BooleanAccessor<Row>
	/** Prefix labels with a group name. Use to prevent id/htmlFor name conflicts when
	 * you use multiple checkbox groups on one page. */
	groupName?: string
	/**
	 * Show the checkboxes in a single horizontal row?
	 * @default false
	 */
	horizontal?: boolean
}

export const CheckboxGroupWithoutMemo = <Row,>({
	onChange,
	rows,
	groupName,
	valueAccessor = "value" as keyof Row,
	labelAccessor = "label" as keyof Row,
	checkedAccessor = "checked" as keyof Row,
	disabledAccessor = "disabled" as keyof Row,
	hiddenAccessor = "hidden" as keyof Row,
	columnCounts,
	horizontal,
	...rest
}: CheckboxGroupProps<Row>): React.ReactElement => {
	const theme = useTheme()

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>, row: Row) => {
		const { checked } = e.target

		if (onChange) onChange(row, checked)
	}

	const { xl, lg, md, sm, xs } = columnCounts ?? {}

	const columnCss = useMemo(() => {
		return css`
			break-inside: avoid;
			${theme.breakpoints.up("xs")} {
				column-count: ${xs ?? sm ?? md ?? lg ?? xl ?? 1};
			}
			${theme.breakpoints.up("sm")} {
				column-count: ${sm ?? md ?? lg ?? xl ?? xs ?? 1};
			}
			${theme.breakpoints.up("md")} {
				column-count: ${md ?? lg ?? xl ?? sm ?? xs ?? 1};
			}
			${theme.breakpoints.up("lg")} {
				column-count: ${lg ?? xl ?? md ?? sm ?? xs ?? 1};
			}
			${theme.breakpoints.up("xl")} {
				column-count: ${xl ?? lg ?? md ?? sm ?? xs ?? 1};
			}
			> li {
				break-inside: avoid;
			}
		`
	}, [xs, sm, md, lg, xl, theme.breakpoints])

	const listCss = useMemo(() => {
		return css`
			display: ${horizontal ? "flex" : undefined};
			flex-direction: ${horizontal ? "row" : undefined};
			column-gap: ${horizontal ? "2rem" : undefined};
			flex-wrap: wrap;
			position: relative;
			left: -0.5rem; // Make up for the large padding of the ripple area
			margin: 0;
			padding: 0;
			li {
				display: flex;
				align-items: flex-start;
			}
		`
	}, [horizontal])

	return (
		<div>
			<FieldContainer fillContainer={false} {...rest}>
				<ul css={[listCss, columnCss]}>
					{rows.map((row) => {
						const value = accessString(row, valueAccessor)
						const label = accessString(row, labelAccessor)
						const checked = accessBoolean(row, checkedAccessor, false, true)
						const disabled = accessBoolean(row, disabledAccessor, false, true)
						const hidden = accessBoolean(row, hiddenAccessor, false, true)
						const id = `${groupName ?? "unnamed-group"}-checkbox-${value}`

						if (hidden) return null

						return (
							<CheckboxGroupRow
								key={id}
								id={id}
								label={label}
								checked={checked}
								disabled={disabled}
								handleChange={(e) => {
									handleChange(e, row)
								}}
							/>
						)
					})}
				</ul>
			</FieldContainer>
		</div>
	)
}

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623
export const CheckboxGroup = React.memo(
	CheckboxGroupWithoutMemo
) as typeof CheckboxGroupWithoutMemo

interface CheckboxGroupRowProps {
	id: string
	checked: boolean
	disabled: boolean
	handleChange(e: React.ChangeEvent<HTMLInputElement>): void
	label: string
}

const CheckboxGroupRow: FC<CheckboxGroupRowProps> = memo(
	({ id, checked, disabled, handleChange, label }) => {
		return (
			<li>
				<MuiCheckbox
					id={id}
					checked={checked}
					disabled={disabled}
					color="primary"
					onChange={handleChange}
					css={checkboxCss}
				/>
				<ControlLabel
					htmlFor={id}
					checked={checked}
					disabled={disabled}
					top="0"
					marginTop="10px"
				>
					{label}
				</ControlLabel>
			</li>
		)
	}
)

const checkboxCss = css`
	position: relative;
	.MuiSvgIcon-root {
		width: 22px;
		height: 22px;
	}
`
