import { useCallback, useState } from "react"

import { arrayWrap } from "@ncs/ts-utils"

/**
 * If no arguments are passed, it returns a boolean if anyone is true
 */
export type IsSaving<SavingNames extends string | void> = (
	value?: SavingNames | SavingNames[]
) => boolean
export type SetSaving<SavingNames extends string | void> = (
	value: SavingNames extends string ? SavingNames | SavingNames[] : void
) => void
export type EndSaving<SavingNames extends string | void> = (
	value: SavingNames extends string ? SavingNames | SavingNames[] : void
) => void

export interface UseIsSavingReturn<SavingNames extends string | void = void> {
	isSaving: IsSaving<SavingNames>
	setSaving: SetSaving<SavingNames>
	endSaving: EndSaving<SavingNames>
}

/**
 * Just some sugar for maintaining a list of strings that represent potentially
 * concurrent saving sates. Can also be used as a simple boolean saving true or false.
 */
export const useIsSaving = <
	SavingNames extends string | void = void,
>(): UseIsSavingReturn<SavingNames> => {
	const [savingBoolean, setSavingBoolean] = useState(false)
	const [strings, setStrings] = useState<SavingNames[]>([])

	const isSaving: IsSaving<SavingNames> = useCallback(
		(value) => {
			if (!value) {
				return !!strings.length || savingBoolean
			}

			return arrayWrap(value).some((s) => strings.includes(s as SavingNames))
		},
		[savingBoolean, strings]
	)

	const setSaving: SetSaving<SavingNames> = useCallback((value) => {
		if (!value) {
			setSavingBoolean(true)
		} else {
			setStrings((prev) => {
				const newState = [
					...new Set([...prev, ...arrayWrap(value as SavingNames | SavingNames[])]),
				]

				return newState
			})
		}
	}, [])

	const endSaving: EndSaving<SavingNames> = useCallback((valueToEnd) => {
		if (!valueToEnd) {
			setStrings([])
			setSavingBoolean(false)
		} else {
			setStrings((prev) => {
				return prev.filter(
					(prevSaving) => !arrayWrap(valueToEnd).includes(prevSaving as string)
				)
			})
		}
	}, [])

	return {
		isSaving,
		setSaving,
		endSaving,
	}
}
