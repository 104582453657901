import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { isValidPhoneNumber } from "libphonenumber-js"
import { useForm } from "react-hook-form"
import { z } from "zod"

import {
	makeApiErrorMessage,
	useCreateCustomerDispatch,
	useDispatchPriorities,
} from "@ncs/ncs-api"
import {
	GridContainer,
	GridItem,
	Modal,
	PhoneInputFormField,
	SelectFormField,
	SiteSelectorFormField,
	TextareaFormField,
	TextInputFormField,
	useToast,
} from "@ncs/web-legos"

export interface NewServiceRequestModalProps {
	isOpen: boolean
	onClose: () => void
}

const NewServiceRequestFormSchema = z.object({
	bay: z.string().optional(),
	customer: z.string().nonempty("Choose a customer"),
	nte: z.string().optional(),
	phone: z
		.string()
		.nonempty("Phone number is required")
		.refine((value) => isValidPhoneNumber(value, "US"), {
			message: "Not a valid U.S. phone number",
		}),
	poNumber: z.string().optional(),
	priority: z.string().nonempty(),
	symptoms: z.string().nonempty("Description is required"),
})

export type NewServiceRequestForm = z.infer<typeof NewServiceRequestFormSchema>

export const NewServiceRequestModal: React.FC<NewServiceRequestModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { makeSuccessToast } = useToast()
	const [dispatchPriorities, dispatchPrioritiesAreLoading] = useDispatchPriorities()
	const [submissionError, setSubmissionError] = useState<string | null>(null)
	const createDispatch = useCreateCustomerDispatch()

	const {
		handleSubmit,
		control,
		reset: formReset,
		formState: { submitCount, isValid, isSubmitting },
	} = useForm<NewServiceRequestForm>({
		mode: "onSubmit",
		resolver: zodResolver(NewServiceRequestFormSchema),
	})

	const reset = () => {
		formReset()
		setSubmissionError(null)
	}

	const handleClose = () => {
		reset()
		onClose()
	}

	const handleSuccess = () => {
		makeSuccessToast("Service request created")
		handleClose()
	}

	const onSubmit = async (data: NewServiceRequestForm) => {
		try {
			await createDispatch(data)
			handleSuccess()
		} catch (e) {
			setSubmissionError(makeApiErrorMessage(e))
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title="New Service Request"
			maxWidth="md"
			rightButtons={[
				{
					variant: "primary-cta",
					buttonText: "Create",
					onClick: handleSubmit(onSubmit),
					disabled: submitCount > 0 && !isValid,
					isLoading: isSubmitting,
				},
			]}
			errorText={submissionError}
		>
			<GridContainer columnGap={2}>
				<GridItem xs={12} sm={6}>
					<SiteSelectorFormField
						control={control}
						name="customer"
						label="Site"
						fillContainer={true}
					/>
					<SelectFormField
						control={control}
						name="priority"
						label="Priority"
						options={dispatchPriorities ?? []}
						valueAccessor={(priority) => String(priority.id)}
						textAccessor="description"
						disabled={dispatchPrioritiesAreLoading}
						fillContainer={true}
					/>
					<TextareaFormField
						control={control}
						name="symptoms"
						label="Description"
						placeholder="Describe the issue..."
					/>
				</GridItem>

				<GridItem xs={12} sm={6}>
					<PhoneInputFormField
						control={control}
						name="phone"
						label="Contact phone number"
					/>
					<TextInputFormField
						control={control}
						name="bay"
						label="Location"
						placeholder="Bay number, etc..."
					/>
					<TextInputFormField control={control} name="poNumber" label="PO number" />
					<TextInputFormField control={control} name="nte" label="Not to exceed" />
				</GridItem>
			</GridContainer>
		</Modal>
	)
}
