import React, { memo } from "react"

import { ExtendableSelectProps, Select } from "../inputs"
import { CustomerInternal } from "@ncs/ncs-api/src"

export interface CustomerInternalSelectorProps extends ExtendableSelectProps<CustomerInternal> {}
export const CustomerInternalSelector: React.FC<any> = memo((props) => {
	const customerInternalStatus = [
		{ label: "Internal", value: "true" },
		{ label: "External", value: "false" },
	]

	return (
		<Select
			label="Customer Internal/External"
			{...props}
			options={customerInternalStatus ?? []}
			valueAccessor="value"
			textAccessor="label"
		/>
	)
})
