import React, { useMemo } from "react"

import { css, useTheme } from "@emotion/react"

import { withCssUnit } from "@ncs/ts-utils"

export interface ControlLabelProps {
	htmlFor: string
	checked?: boolean
	disabled?: boolean
	top?: number | string
	marginTop?: number | string
	noWrap?: boolean
}

/** For use alongside checkboxes and radio buttons. */
export const ControlLabel: React.FC<ControlLabelProps> = ({
	htmlFor,
	checked,
	disabled,
	top = "3px",
	marginTop,
	noWrap,
	children,
}) => {
	const theme = useTheme()

	const propsStyle = useMemo(
		() => css`
			top: ${withCssUnit(top)};
			margin-top: ${withCssUnit(marginTop)};
			opacity: ${disabled ? "0.5" : "1"};
			font-weight: ${checked ? "bold" : "normal"};
			color: ${checked ? theme.palette.primary.main : theme.palette.text.primary};
			cursor: ${disabled ? "arrow" : "pointer"};
			white-space: ${noWrap ? "nowrap" : "normal"};
		`,
		[
			checked,
			disabled,
			marginTop,
			top,
			noWrap,
			theme.palette.primary.main,
			theme.palette.text.primary,
		]
	)

	return (
		<label css={[baseStyle, propsStyle]} htmlFor={htmlFor}>
			{children}
		</label>
	)
}

const baseStyle = css`
	position: relative;
`
