import { FC } from "react"

import { BolBillTo } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface BolBillToSelectorProps extends ExtendableSelectProps<(typeof options)[number]> {}

export const BolBillToSelector: FC<BolBillToSelectorProps> = ({ ...rest }) => {
	return <Select fillContainer label="Bill of lading bill-to" {...rest} options={options} />
}

const options = [
	{
		value: BolBillTo.Customer,
		text: "Customer bill-to",
	},
	{
		value: BolBillTo.Internal,
		text: "Internal",
	},
	{
		value: BolBillTo.Superior,
		text: "Superior Logistics",
	},
	{
		value: BolBillTo.WorldWideLogistics,
		text: "World Wide Logistics",
	},
	{
		value: BolBillTo.Geodis,
		text: "Geodis",
	},
]
