import { FC, memo, useEffect, useRef } from "react"

import { PartGroup, usePartGroups } from "@ncs/ncs-api"

import { ExtendableSearchableSelectProps, SearchableSelect } from "../"

export interface PartGroupSelectorProps extends ExtendableSearchableSelectProps<PartGroup> {
	initialId?: string | null
}

export const PartGroupSelector: FC<PartGroupSelectorProps> = memo(
	({ initialId, onChange, ...rest }) => {
		const { data, isLoading } = usePartGroups({
			pageSize: 999, // Just grab em all.
		})

		const hasSetInitial = useRef(false)

		useEffect(() => {
			if (!!initialId && hasSetInitial.current === false && data.length > 0) {
				const initialSelection = data.find((d) => d.id === initialId)

				if (initialSelection) {
					onChange(initialSelection)
					hasSetInitial.current = true
				}
			}
		}, [data, initialId, onChange])

		return (
			<SearchableSelect
				label="Part group"
				{...rest}
				options={data}
				onItemSelect={onChange}
				getOptionLabel={(option) => option.description}
				isLoading={isLoading}
				sortingParam="description"
			/>
		)
	}
)
