import { Dispatch, ReactElement, SetStateAction } from "react"

import { InvoiceTypeSelector } from "../selectors"

interface InvoiceTypeQueryParams {
	type?: string | null
	invoiceType?: string | null
}

export interface InvoiceTypeQueryFilterProps<QueryParamState extends InvoiceTypeQueryParams> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	paramKey?: keyof InvoiceTypeQueryParams
}

export const InvoiceTypeQueryFilter = <QueryParamState extends InvoiceTypeQueryParams>({
	queryParamState,
	setQueryParamState,
	paramKey = "type",
}: InvoiceTypeQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			[paramKey]: newValue,
		}))
	}

	return (
		<InvoiceTypeSelector
			value={queryParamState[paramKey] ?? null}
			onChange={handleChange}
			disableNoSelectionOption={false}
			noSelectionOptionText="Any invoice type"
			fillContainer
		/>
	)
}
