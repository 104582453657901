import { createElement, Dispatch, ReactElement, SetStateAction } from "react"

import { Button } from "../buttons"
import { Box, GridContainer, GridItem, GridItemProps } from "../layout"

export interface QueryFiltersFilterProps<QueryParamState> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
}

type FilterElement<QueryParamState> = (
	props: QueryFiltersFilterProps<QueryParamState>
) => React.ReactElement

export interface FilterGridProps<QueryParamState extends {}> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	filters?: FilterElement<QueryParamState>[]
	fillRightToLeft?: boolean
	onReset?: (currentFilters: QueryParamState) => void
	showReset?: boolean
	resetValues?: Partial<QueryParamState>
	gridItemProps?: GridItemProps
}

export const FilterGrid = <QueryParamState extends {}>({
	queryParamState,
	setQueryParamState,
	filters = [],
	fillRightToLeft,
	onReset,
	showReset = true,
	resetValues,
	gridItemProps,
}: FilterGridProps<QueryParamState>): ReactElement => {
	const clearFilters = () => {
		// If you passed in a custom onReset, fire that, otherwise we'll reset everything except for
		// the usual suspects.
		if (onReset) {
			onReset(queryParamState)
		} else {
			const excludeFromReset = ["ordering"] as (keyof QueryParamState)[]

			const newState = Object.fromEntries(
				Object.entries(queryParamState).map(([key, value]) => [
					key,
					excludeFromReset.includes(key as keyof QueryParamState) ? value : null,
				])
			) as QueryParamState

			// Spread in the reset values object. Let's you specify when some value in the param state
			// shouldn't reset to null. This functionality was added in after the excludeFromReset
			// stuff above. This would probably be a cleaner way to preserve Table's ordering prop.
			setQueryParamState({
				...newState,
				...resetValues,
			})
		}
	}

	return (
		<>
			<GridContainer fillRightToLeft={fillRightToLeft} rowGap={0} my={0.5}>
				{filters.map((component, i) => (
					<GridItem key={i} xs={12} sm={6} md={4} lg={3} {...gridItemProps}>
						{createElement(component, {
							queryParamState,
							setQueryParamState,
						})}
					</GridItem>
				))}
			</GridContainer>

			{showReset && (
				<Box textAlign={fillRightToLeft ? "right" : undefined} mb={2}>
					<Button onClick={clearFilters} icon="undo">
						Reset filters
					</Button>
				</Box>
			)}
		</>
	)
}
