import React, { useMemo, useState } from "react"

import dayjs from "dayjs"

import { CustomerDispatchReportQueryParams, useDispatchCostsReport } from "@ncs/ncs-api"
import { ChartCard, LineChartOld } from "@ncs/web-legos"

export const ServiceRequestsReportingTab: React.FC = () => {
	const [queryParamState] = useState(initialDispatchReportQueryParams)
	const [dispatchReport, dispatchReportIsLoading] = useDispatchCostsReport(queryParamState)

	const reportWithoutNulls = useMemo(() => {
		return (dispatchReport ?? []).map((d) => ({
			...d,
			total: d.total ?? 0,
		}))
	}, [dispatchReport])

	return (
		<>
			{!dispatchReportIsLoading && (
				<ChartCard heading="Service Costs Per Month">
					<LineChartOld
						data={reportWithoutNulls}
						lineKeys="total"
						xDataKey="month"
						xTickFormatter={(value) =>
							dayjs()
								.month(value - 1)
								.format("MMM")
						}
						yTickFormatter={(value) => `$${value}`}
						withTooltip
					/>
				</ChartCard>
			)}
		</>
	)
}

export const initialDispatchReportQueryParams: CustomerDispatchReportQueryParams = {
	site: null,
	callReceivedGte: null,
	closedDateGte: null,
	statusId: null,
	callTypeId: null,
}
