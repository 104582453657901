import { Dispatch, SetStateAction, useState } from "react"

import { useLocation } from "react-router-dom"

export type UseLocalStorageStateOptions<State> = Partial<{
	/**
	 * Override the key used in local storage.
	 */
	keyOverride: string
	/**
	 * Turn off the local storage storing, if you need to.
	 */
	enabled: boolean
	/**
	 * This function will be fired upon initially trying to access stored state. If it returns
	 * true, the found state item will be returned.
	 */
	validate: (state: State) => boolean
}>

const defaultUseLocalStorageStateOptions: UseLocalStorageStateOptions<unknown> = {
	enabled: true,
}

/**
 * Like useState, except automatically syncs with localStorage.
 */
export const useLocalStorageState = <State>(
	initialValue: State,
	options?: UseLocalStorageStateOptions<State>
): [State, Dispatch<SetStateAction<State>>] => {
	const { keyOverride, enabled, validate }: UseLocalStorageStateOptions<State> = {
		...defaultUseLocalStorageStateOptions,
		...options,
	}

	// To avoid naming collisions, we'll use the URL pathname as the default key.
	// You can override that if you need to though, for example if you need
	// multiple things stored at the same URL, or if the URL is dynamically
	// generated.
	const { pathname } = useLocation()
	const key = `${keyOverride ? keyOverride : pathname}-state`

	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Try to get the item from storage. If it's there, that'll be our initial
			// value, otherwise use the passed parameter.
			const item = window.localStorage.getItem(key)

			if (enabled && item) {
				// Parse the item and validate if you passed a function in to do so.
				const parsed = JSON.parse(item)

				if (typeof validate === "function") {
					return validate(parsed) ? parsed : initialValue
				} else {
					return parsed
				}
			} else {
				return initialValue
			}
		} catch (error) {
			console.error(error)

			return initialValue
		}
	})

	// Return a wrapped version of useState's setter function that persists the new value to localStorage.
	const setValue = (value: Parameters<Dispatch<SetStateAction<State>>>[0]) => {
		try {
			// Allow value to be a function so we have same API as useState
			const valueToStore = value instanceof Function ? value(storedValue) : value

			// Save in state and in local storage.
			setStoredValue(valueToStore)
			if (enabled) {
				window.localStorage.setItem(key, JSON.stringify(valueToStore))
			}
		} catch (error) {
			console.error(error)
		}
	}

	return [storedValue, setValue]
}
