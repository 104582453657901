import React, { memo } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export type AmountDueSelectorProps = ExtendableSelectProps<(typeof amountDueOptions)[number]>

export enum AmountDue {
	PaymentDue = "payment_due",
	PartiallyPaid = "partially_paid",
	PaidInFull = "paid_in_full",
}

const amountDueOptions = [
	{ value: AmountDue.PaymentDue, text: "Payment due" },
	{ value: AmountDue.PartiallyPaid, text: "Partially paid" },
	{ value: AmountDue.PaidInFull, text: "Paid in full" },
]

export const AmountDueSelector: React.FC<AmountDueSelectorProps> = memo(
	({ value, onChange, ...rest }) => {
		return (
			<Select
				label="Amount due"
				{...rest}
				value={value}
				onChange={onChange}
				options={amountDueOptions}
			/>
		)
	}
)
