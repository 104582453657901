import { FC, memo, useMemo } from "react"

import { RadioGroup, RadioGroupProps } from "./RadioGroup"

export interface RadioBooleanProps
	extends Omit<
		RadioGroupProps<{ label: string; value: FakeStringBoolean }>,
		"value" | "onChange" | "options"
	> {
	value: boolean | null
	onChange: (newState: boolean) => void
	/** The text for the 'yes' option. */
	yesText?: string
	/** The text for the 'no' option.  */
	noText?: string
	/** If true, options will be No then Yes instead of Yes then No. */
	noFirst?: boolean
}

type FakeStringBoolean = "true" | "false"

/**
 * Like `RadioGroup`, except it has its options built in. There are just two of them,
 * and they're for true/false. It handles the fudging of string radio values into proper
 * boolean values.
 */
export const RadioBoolean: FC<RadioBooleanProps> = memo(
	({ value, onChange, yesText = "Yes", noText = "No", noFirst, ...rest }) => {
		const localValue = useMemo(() => {
			return (
				value == null ? null
				: value === true ? "true"
				: "false"
			)
		}, [value])

		const options = useMemo(() => {
			const result = [
				{
					label: yesText,
					value: "true" as const,
				},
				{
					label: noText,
					value: "false" as const,
				},
			]

			if (noFirst) {
				result.reverse()
			}

			return result
		}, [noText, yesText, noFirst])

		const handleChange = (newValue: FakeStringBoolean) => {
			// Call the onChange param with a real boolean.
			onChange(newValue === "true")
		}

		return (
			<RadioGroup
				{...rest}
				value={localValue}
				onChange={(newValue) => handleChange(newValue as FakeStringBoolean)}
				options={options}
			/>
		)
	}
)
