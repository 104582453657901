import { FC } from "react"

import { KeyboardDatePicker, KeyboardDatePickerProps } from "@material-ui/pickers"
import { Dayjs } from "dayjs"

import { cssMixins } from "../../util"
import { FieldContainer, FieldContainerProps } from "./FieldContainer"

export interface KeyboardDateInputProps extends FieldContainerProps {
	value: Dayjs | null
	onChange: (newDate: Dayjs | null) => void
	muiProps?: Omit<KeyboardDatePickerProps, "value" | "onChange">
}

export const KeyboardDateInput: FC<KeyboardDateInputProps> = ({
	value,
	onChange,
	muiProps,
	...rest
}) => {
	const handleDateChange = (newDate: Dayjs | null) => {
		onChange(newDate)
	}

	return (
		<FieldContainer label="Date" {...rest}>
			<KeyboardDatePicker
				value={value}
				onChange={handleDateChange}
				format={"MM/DD/YYYY"}
				placeholder="MM/DD/YYYY"
				inputVariant="outlined"
				{...muiProps}
				css={cssMixins.adaptMuiInputStyle}
			/>
		</FieldContainer>
	)
}
