export const isEnumMember = <T extends {}>(
	value: unknown,
	enumToCheck: T,
	failureCallback?: (failedValue: string) => void
): value is T[keyof T] => {
	if (value == null || value === "") {
		return false
	}

	const result = Object.values(enumToCheck).includes(value)

	if (!result && failureCallback) {
		failureCallback(String(value))
	}

	return result
}
