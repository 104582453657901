import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { StripeBankAccount, useVerifyBankAccount } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	ErrorText,
	Icon,
	Modal,
	NumericInputFormField,
	Paragraph,
} from "@ncs/web-legos"

export interface VerifyBankAccountModalProps {
	isOpen: boolean
	onClose: () => void
	bankAccount: StripeBankAccount | null
}

const AccountVerificationFormSchema = z.object({
	amount1: z.number().min(1),
	amount2: z.number().min(1),
})
type AccountVerificationForm = z.infer<typeof AccountVerificationFormSchema>

export const VerifyBankAccountModal: React.FC<VerifyBankAccountModalProps> = ({
	isOpen,
	onClose,
	bankAccount,
}) => {
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [verificationComplete, setVerificationComplete] = useState(false)
	const verifyAccount = useVerifyBankAccount(bankAccount?.id)

	const { control, reset, handleSubmit } = useForm<AccountVerificationForm>({
		resolver: zodResolver(AccountVerificationFormSchema),
	})

	const onSubmit = async (data: AccountVerificationForm) => {
		try {
			setIsSubmitting(true)
			await verifyAccount({
				amount1: data.amount1,
				amount2: data.amount2,
			})
			setVerificationComplete(true)
			setErrorMessage("")
		} catch (e) {
			setErrorMessage("Verification unsuccessful")
		} finally {
			setIsSubmitting(false)
		}
	}

	const handleClose = () => {
		reset()
		setErrorMessage(null)
		setIsSubmitting(false)
		setVerificationComplete(false)
		onClose()
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={handleClose}
			title="Verify Bank Account"
			rightButtons={
				!verificationComplete ?
					{
						buttonText: "Verify",
						variant: "primary-cta",
						onClick: handleSubmit(onSubmit),
						isLoading: isSubmitting,
					}
				:	undefined
			}
		>
			{verificationComplete ?
				<AnimatedEntrance show>
					<Box textAlign="center" my={2}>
						<Icon icon="check-circle" fontSize="2rem" color="success" />
						<Paragraph mt={1}>Account verification successful. Thank you.</Paragraph>
					</Box>
				</AnimatedEntrance>
			:	<>
					<Paragraph mb={1}>
						Enter the amounts, in cents, of the two test deposits into your account.
						For example, if the deposit is <strong>$0.51</strong>, enter{" "}
						<strong>51</strong>.
					</Paragraph>

					<Paragraph mb={1}>
						Please note that after 10 failed attempts you will no longer be able to
						verify this account.
					</Paragraph>

					<div>
						<NumericInputFormField
							control={control}
							name="amount1"
							label="Deposit 1"
							fillContainer={false}
						/>
					</div>
					<div>
						<NumericInputFormField
							control={control}
							name="amount2"
							label="Deposit 2"
							fillContainer={false}
						/>
					</div>
				</>
			}

			{!!errorMessage && <ErrorText textAlign="right">{errorMessage}</ErrorText>}
		</Modal>
	)
}
