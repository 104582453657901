import React from "react"

import { DispatchStatus, useDispatchStatuses } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export type DispatchStatusSelectorProps = ExtendableSelectProps<DispatchStatus>

export const DispatchStatusSelector: React.FC<DispatchStatusSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	const [dispatchStatuses, isLoading] = useDispatchStatuses()

	return (
		<Select
			label="Dispatch status"
			{...rest}
			value={value}
			onChange={onChange}
			options={dispatchStatuses ?? []}
			disabled={isLoading}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}
