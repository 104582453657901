import { FC, useMemo } from "react"

import { ConnectedDevice, useConnectedMachinesAtSites } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface ConnectedDeviceSelectorProps extends ExtendableSelectProps<ConnectedDevice> {
	customerId: string | null | undefined
}

/**
 * For now this only shows a customer's machine devices (and not Wash Counts or Vacuums)
 */
export const ConnectedDeviceSelector: FC<ConnectedDeviceSelectorProps> = ({
	customerId,
	...rest
}) => {
	const [machinesAtSite, machinesAtSiteLoading] = useConnectedMachinesAtSites({
		params: { customer: customerId },
		queryConfig: {
			enabled: !!customerId,
		},
	})

	const devices = useMemo(() => {
		if (!customerId || !machinesAtSite) {
			return []
		}

		return machinesAtSite
			.filter(({ customer }) => customer.id === customerId)
			.flatMap(({ devices: d }) => d)
	}, [customerId, machinesAtSite])

	return (
		<Select
			{...rest}
			options={devices}
			isLoading={machinesAtSiteLoading}
			label="Device"
			valueAccessor="id"
			initialValue={devices.length ? devices[0].id : undefined}
			textAccessor={(option) => `${option.name} ${option.equipment?.vintage}`}
		/>
	)
}
