import { FC, useMemo } from "react"

import { useHistory } from "react-router-dom"

import { ConnectedDevicesAtSite, GenericDeviceStatus, getGenericDeviceStatus } from "@ncs/ncs-api"
import { formatNumber } from "@ncs/ts-utils"
import {
	Box,
	Card,
	getAddressFields,
	Heading,
	Icon,
	IconFamily,
	IconName,
	IconProps,
	Paragraph,
} from "@ncs/web-legos"

export interface ConnectivitySiteCardProps {
	site: ConnectedDevicesAtSite
}

export const ConnectivitySiteCard: FC<ConnectivitySiteCardProps> = ({ site: connectedSite }) => {
	const history = useHistory()

	const { customer, devices } = connectedSite

	const normalDeviceCount = useMemo(() => {
		return devices.filter(
			(d) => getGenericDeviceStatus(d.state) === GenericDeviceStatus.Normal
		).length
	}, [devices])

	const warningDeviceCount = useMemo(() => {
		return devices.filter(
			(d) => getGenericDeviceStatus(d.state) === GenericDeviceStatus.Warning
		).length
	}, [devices])

	const errorDeviceCount = useMemo(() => {
		return devices.filter((d) => getGenericDeviceStatus(d.state) === GenericDeviceStatus.Error)
			.length
	}, [devices])

	const offlineDeviceCount = useMemo(() => {
		return devices.filter(
			(d) => getGenericDeviceStatus(d.state) === GenericDeviceStatus.Unknown
		).length
	}, [devices])

	const totalDevices =
		normalDeviceCount + warningDeviceCount + errorDeviceCount + offlineDeviceCount

	return (
		<Card
			variant="droplet"
			height="inherit"
			onClick={() => history.push(`/connectivity/${customer.id}`)}
		>
			<Heading variant="h2" bold>
				{customer.name}
			</Heading>
			<Paragraph small color="secondary" mt={0.25} mb={0.75}>
				{getAddressFields(customer, {
					exclude: ["name", "zip"],
				}).join(", ")}
			</Paragraph>

			{totalDevices === 0 && (
				<Paragraph color="secondary">No connectivity devices found</Paragraph>
			)}

			<DeviceCountRow
				count={errorDeviceCount}
				iconName="exclamation-triangle"
				iconColor="error"
				phrase="with errors"
			/>
			<DeviceCountRow
				count={warningDeviceCount}
				iconName="info-circle"
				iconColor="warning"
				phrase="with warnings"
			/>
			<DeviceCountRow
				count={normalDeviceCount}
				iconName="check-circle"
				iconColor="success"
				phrase="operating normally"
			/>
			<DeviceCountRow
				count={offlineDeviceCount}
				iconName="wifi-slash"
				iconColor="gray"
				iconFamily="regular"
				phrase="offline"
			/>
		</Card>
	)
}

interface DeviceCountRowProps {
	count: number
	iconName: IconName
	iconFamily?: IconFamily
	iconColor: IconProps["color"]
	phrase: string
}

const DeviceCountRow: FC<DeviceCountRowProps> = ({
	count,
	iconName,
	iconFamily = "solid",
	iconColor,
	phrase,
}) => {
	if (!count) return null

	return (
		<Box display="flex" alignItems="center" columnGap={1} mb={0.5}>
			<Icon icon={iconName} family={iconFamily} color={iconColor} fixedWidth />
			<Paragraph>
				{formatNumber(count)} device{count !== 1 && "s"} {phrase}
			</Paragraph>
		</Box>
	)
}
