import { FC, Fragment, memo, ReactNode, useMemo } from "react"

import { css } from "@emotion/react"
import { CSSProperties } from "@material-ui/core/styles/withStyles"

import { noNullish } from "@ncs/ts-utils"

import { Heading } from "../typography"
import { Box, BoxProps } from "./Box"

export interface CssGridTableProps extends Omit<BoxProps, "display" | "d"> {
	cells: (ReactNode | null | undefined)[]
	gridTemplateColumns: CSSProperties["gridTemplateColumns"]
	headers?: (string | number | null | undefined)[]
	inline?: boolean
}

export const CssGridTable: FC<CssGridTableProps> = memo(
	({ gridTemplateColumns, cells, headers = [], inline, ...rest }) => {
		const gridCss = useMemo(() => {
			return css`
				display: ${inline ? "inline-grid" : "grid"};
				grid-template-columns: ${gridTemplateColumns};
			`
		}, [gridTemplateColumns, inline])

		const filteredHeaders = useMemo(() => {
			return noNullish(headers)
		}, [headers])

		const filteredCells = useMemo(() => {
			return noNullish(cells)
		}, [cells])

		return (
			<Box css={gridCss} alignItems="center" {...rest}>
				{filteredHeaders.map((header) => (
					<Heading variant="h6" bold key={header} alignSelf="flex-end" mb={0.25}>
						{header}
					</Heading>
				))}
				{filteredCells.map((c, i) => (
					<Fragment key={i}>{c}</Fragment>
				))}
			</Box>
		)
	}
)
