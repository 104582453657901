import React, { useEffect } from "react"

import { useQueryClient } from "react-query"

import { useAuth } from "@ncs/ncs-api"
import { Box, Heading, Link } from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

export const Logout: React.FC = () => {
	const { logout } = useAuth()
	const queryClient = useQueryClient()

	useEffect(() => {
		logout()
		queryClient.removeQueries()
	}, [logout, queryClient])

	return (
		<PageContentWrapper title="Logged out">
			<Heading variant="h2" bold textAlign="center" mt={5}>
				You are now logged out.
			</Heading>
			<Box mt={5} textAlign="center">
				<Link to="/auth/login" icon="sign-in" underline>
					Log in
				</Link>
			</Box>
		</PageContentWrapper>
	)
}
