import React from "react"

import { Box } from "../layout"
import { Heading, Paragraph } from "../typography"
import { Card, CardProps } from "./Card"

export const ChartCard: React.FC<CardProps> = ({
	// Intercept these and customize them a bit for the chart situation.
	heading,
	headingIcon,
	headingDetail,
	headingIconFamily,
	renderRight,
	children,
	mb = 5,
	...rest
}) => {
	return (
		<Card mb={mb} variant="droplet" {...rest}>
			{(!!heading || !!headingDetail || !!renderRight) && (
				<Box d="flex" justifyContent="space-between" mb={2}>
					<div>
						{!!heading && (
							<Heading
								variant="h4"
								bold
								mb={0.35}
								icon={headingIcon}
								iconFamily={headingIconFamily}
							>
								{heading.toUpperCase()}
							</Heading>
						)}
						{!!headingDetail && (
							<Paragraph small color="secondary">
								{headingDetail}
							</Paragraph>
						)}
					</div>
					{!!renderRight && renderRight()}
				</Box>
			)}
			{children}
		</Card>
	)
}
