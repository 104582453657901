import { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState } from "react"

import dayjs from "dayjs"

import {
	DateFormat,
	getTimeAgoFromStartDate,
	getTimeAgoStartDate,
	isTimeAgo,
	TimeAgo,
} from "@ncs/ts-utils"

import { useChangeCallback } from "../../util"
import { TimeAgoSelector, TimeAgoSelectorProps } from "../selectors"

type TimeAgoQueryParamState = {
	startDate?: string | null
	callReceivedGte?: string | null
	closedDateGte?: string | null
	endDate: string | null
}

export interface TimeAgoQueryFilterProps<QueryParamState extends TimeAgoQueryParamState>
	extends Omit<TimeAgoSelectorProps, "value" | "onChange"> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	paramKey?: "startDate" | "callReceivedGte" | "closedDateGte"
	label?: string
	/**
	 * If we are given a date that doesn't match any TimeAgos, we can add a day to it
	 * and see if it does, and then try adding 2, then 3, etc, until we find it. When found,
	 * we'll run an onChange to set to the result. Useful because the selector's value is
	 * often stored in the URL and the tab gets opened and left open for days.
	 */
	fastForwardStaleValues?: boolean
}

export const TimeAgoQueryFilter = <QueryParamState extends TimeAgoQueryParamState>({
	queryParamState,
	setQueryParamState,
	paramKey = "startDate",
	label,
	fastForwardStaleValues = true,
}: TimeAgoQueryFilterProps<QueryParamState>): ReactElement => {
	// We need to keep the state of the selected option here locally, because
	// all we push up the query param state is a date, and we can't always go
	// from just that to the proper select option.
	const [localTimeAgo, setLocalTimeAgo] = useState<TimeAgo | null>(() => {
		return getTimeAgoFromStartDate(queryParamState[paramKey])
	})

	const handleChange = useCallback(
		(newValue: TimeAgo | null) => {
			// Set local state.
			setLocalTimeAgo(newValue)

			// Set the query param state accordingly.
			setQueryParamState((prev) => {
				const newState = {
					...prev,
					[paramKey]:
						newValue ?
							getTimeAgoStartDate(newValue).format(DateFormat.DateQueryParam)
						:	null,
					endDate: null, // Time ago by definition doesn't have an end date, because it's through today!
				}

				return newState
			})
		},
		[paramKey, setQueryParamState]
	)

	/**
	 * Fast forward the date if we're given something that that doesn't match up to a TimeAgo.
	 */
	useEffect(() => {
		if (
			fastForwardStaleValues &&
			queryParamState[paramKey] &&
			getTimeAgoFromStartDate(queryParamState[paramKey]) === null
		) {
			// Not sure what a reasonable upper bound is here, but if you're more than a year off... shrug.
			let limit = 365
			let newDate = dayjs(queryParamState[paramKey])

			while (limit > 0 && getTimeAgoFromStartDate(newDate) === null) {
				limit -= 1
				newDate = newDate.add(1, "day")
			}

			if (isTimeAgo(getTimeAgoFromStartDate(newDate)) === true) {
				handleChange(getTimeAgoFromStartDate(newDate))
			} else {
				// Just clear out the value if it's this far off.
				handleChange(null)
			}
		}
	}, [fastForwardStaleValues, paramKey, queryParamState, handleChange])

	// If the param state changes to something other that what we have in our local state,
	// then we should update accordingly.
	useChangeCallback(queryParamState[paramKey], (newParamsDate) => {
		const localDate =
			localTimeAgo ?
				getTimeAgoStartDate(localTimeAgo).format(DateFormat.DateQueryParam)
			:	null

		if (localDate !== newParamsDate) {
			setLocalTimeAgo(getTimeAgoFromStartDate(newParamsDate))
		}
	})

	return (
		<TimeAgoSelector
			value={localTimeAgo}
			onChange={handleChange}
			initialStartDate={queryParamState[paramKey]}
			fillContainer
			label={label}
		/>
	)
}
