import { useTheme } from "@emotion/react"
import { useMediaQuery } from "@material-ui/core"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

export const useScreenSizeMatch = (
	size: Breakpoint | null | undefined,
	method: "down" | "up" | "exact" = "down"
): boolean => {
	const theme = useTheme()

	const breakpointMethod =
		method === "down" ? theme.breakpoints.down
		: method === "up" ? theme.breakpoints.up
		: theme.breakpoints.only

	// Provide a fallback string because we're using a hook and it can't be nullish...
	const matches = useMediaQuery(breakpointMethod(size ?? "xl"))

	// ...but still bail out if size param was indeed nullish.
	if (!size) {
		return false
	}

	return matches
}
