import { memo, ReactElement } from "react"

import { SiteSelector } from "../selectors"

interface SiteParamState {
	site: string | null
}

export interface SiteQueryFilterProps<QueryParamState extends SiteParamState> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
}

const SiteQueryFilterWithoutMemo = <QueryParamState extends SiteParamState>({
	queryParamState,
	setQueryParamState,
}: SiteQueryFilterProps<QueryParamState>): ReactElement => {
	const onChange = (newValue?: string | null) => {
		setQueryParamState((prev) => ({ ...prev, site: newValue }))
	}

	return (
		<SiteSelector
			value={queryParamState.site}
			onChange={onChange}
			disableNoSelectionOption={false}
			noSelectionOptionText="All sites"
			fillContainer
		/>
	)
}

export const SiteQueryFilter = memo(
	SiteQueryFilterWithoutMemo
) as typeof SiteQueryFilterWithoutMemo
