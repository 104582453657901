import { FC, useMemo } from "react"

import { Lookup, useDispatchCallTypes } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export type DispatchCallTypeSelectorProps = ExtendableSelectProps<Lookup>

export const DispatchCallTypeSelector: FC<DispatchCallTypeSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	const [callTypes, callTypesAreLoading] = useDispatchCallTypes()

	const options = useMemo((): Lookup[] => {
		return [
			{
				id: "4,5,6",
				code: "",
				description: "Contract Types (MI/PI/PM)",
			},
			...(callTypes ?? []),
		]
	}, [callTypes])

	return (
		<Select
			label="Call type"
			{...rest}
			value={value}
			onChange={onChange}
			options={options}
			isLoading={callTypesAreLoading}
			valueAccessor="id"
			textAccessor="description"
		/>
	)
}
