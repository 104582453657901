/**
 * Brands are a different, you just get all of them in one import.
 */
import { fab, faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons"
/**
 * Regular-style icons are our default, so if you import from light or solid,
 * specify that in the import name to avoid collisions.
 */
import { faTable, faTimes as faTimesLight } from "@fortawesome/pro-light-svg-icons"
import {
	faAddressCard,
	faAngleRight,
	faArrowDown,
	faArrowsFromLine,
	faArrowsToLine,
	faArrowToBottom,
	faBars,
	faBell,
	faBellOn,
	faBolt,
	faBox,
	faBullhorn,
	faCalendar,
	faCarCrash,
	faCarWash,
	faChartBar,
	faCheck,
	faCheckCircle,
	faCheckSquare,
	faCloud,
	faCloudDownload,
	faCloudRain,
	faClouds,
	faCloudShowersHeavy,
	faCloudsMoon,
	faCloudSnow,
	faCloudsSun,
	faCloudSun,
	faCloudSunRain,
	faCommentAltLines,
	faCreditCard,
	faDollarSign,
	faDownload,
	faEllipsisV,
	faEmptySet,
	faEnvelope,
	faExternalLink,
	faEye,
	faEyeSlash,
	faFileInvoiceDollar,
	faFilePdf,
	faFilter,
	faFog,
	faGlobe,
	faHome,
	faHurricane,
	faInfoCircle,
	faLevelUp,
	faLink,
	faLongArrowDown,
	faLongArrowLeft,
	faLongArrowRight,
	faLongArrowUp,
	faMinus,
	faMoneyBillWave,
	faMoon,
	faMoonCloud,
	faPencil,
	faPhone,
	faPlus,
	faPrescriptionBottle,
	faRedo,
	faRouter,
	faSatelliteDish,
	faSearch,
	faShippingFast,
	faShoppingCart,
	faSignIn,
	faSignOut,
	faSms,
	faSparkles,
	faSpinnerThird,
	faSquare,
	faSun,
	faSunCloud,
	faTag,
	faThunderstorm,
	faTimes,
	faTrash,
	faUndo,
	faUniversity,
	faUpload,
	faUsdCircle,
	faUser,
	faUserHardHat,
	faUserHeadset,
	faUsersCog,
	faWifiSlash,
	faWind,
} from "@fortawesome/pro-regular-svg-icons"
import {
	faCaretDown as faCaretDownSolid,
	faCaretRight as faCaretRightSolid,
	faCaretUp as faCaretUpSolid,
	faCheckCircle as faCheckCircleSolid,
	faExclamationTriangle as faExclamationTriangleSolid,
	faInfoCircle as faInfoCircleSolid,
	faLock as faLockSolid,
	faMinusHexagon as faMinusHexagonSolid,
	faQuestionCircle as faQuestionCircleSolid,
	faShoppingCart as faShoppingCartSolid,
	faWrench as faWrenchSolid,
} from "@fortawesome/pro-solid-svg-icons"

export const faIcons = [
	faAddressCard,
	faAngleRight,
	faArrowDown,
	faArrowsFromLine,
	faArrowsToLine,
	faArrowToBottom,
	fab,
	faBars,
	faBell,
	faBellOn,
	faBolt,
	faBox,
	faBullhorn,
	faCalendar,
	faCanadianMapleLeaf,
	faCarCrash,
	faCaretDownSolid,
	faCaretRightSolid,
	faCaretUpSolid,
	faCarWash,
	faChartBar,
	faCheck,
	faCheckCircle,
	faCheckCircleSolid,
	faCheckSquare,
	faCloud,
	faCloudDownload,
	faCloudRain,
	faClouds,
	faCloudShowersHeavy,
	faCloudsMoon,
	faCloudSnow,
	faCloudsSun,
	faCloudSun,
	faCloudSunRain,
	faCommentAltLines,
	faCreditCard,
	faDollarSign,
	faDownload,
	faEllipsisV,
	faEmptySet,
	faEnvelope,
	faExclamationTriangleSolid,
	faExternalLink,
	faEye,
	faEyeSlash,
	faFileInvoiceDollar,
	faFilePdf,
	faFilter,
	faFog,
	faGlobe,
	faHome,
	faHurricane,
	faInfoCircle,
	faInfoCircleSolid,
	faLevelUp,
	faLink,
	faLockSolid,
	faLongArrowDown,
	faLongArrowLeft,
	faLongArrowRight,
	faLongArrowUp,
	faMinus,
	faMinusHexagonSolid,
	faMoneyBillWave,
	faMoon,
	faMoonCloud,
	faPencil,
	faPhone,
	faPlus,
	faPrescriptionBottle,
	faQuestionCircleSolid,
	faRedo,
	faRouter,
	faSatelliteDish,
	faSearch,
	faShippingFast,
	faShoppingCart,
	faShoppingCartSolid,
	faSignIn,
	faSignOut,
	faSms,
	faSparkles,
	faSpinnerThird,
	faSquare,
	faSun,
	faSunCloud,
	faTable,
	faTag,
	faThunderstorm,
	faTimes,
	faTimesLight,
	faTrash,
	faUndo,
	faUniversity,
	faUpload,
	faUsdCircle,
	faUser,
	faUserHardHat,
	faUserHeadset,
	faUsersCog,
	faWifiSlash,
	faWind,
	faWrenchSolid,
]
