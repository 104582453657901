import React from "react"

import { css } from "@emotion/react"

import { formatCurrency } from "@ncs/ts-utils"
import { Box, BoxProps } from "@ncs/web-legos"

export interface OrderSummaryRowProps extends BoxProps {
	label: string | React.ReactNode
	amount: string | number
}

export const OrderSummaryRow: React.FC<OrderSummaryRowProps> = ({ label, amount, ...rest }) => {
	return (
		<Box display="flex" justifyContent="space-between" mb={0.75} {...rest}>
			<span>{label}</span>
			<span css={amountStyle}>
				{typeof amount === "number" ? formatCurrency(amount) : amount}
			</span>
		</Box>
	)
}

const amountStyle = css`
	font-weight: bold;
`
