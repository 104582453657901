import React, { ReactNode } from "react"

import { Box, BoxProps } from "../layout"
import { AnimatedEntrance } from "../transitions"
import { ErrorText, Label } from "../typography"
import { css } from "@emotion/react"

export interface FieldContainerProps extends BoxProps {
	label?: string | null
	error?: string | null
	fillContainer?: boolean
	tooltip?: string | ReactNode
}

export const FieldContainer: React.FC<FieldContainerProps> = React.memo(
	({ label, error, fillContainer = true, tooltip, children, ...rest }) => {
		return (
			<Box
				display={fillContainer ? "flex" : "inline-flex"}
				width={fillContainer ? "100%" : "auto"}
				flexDirection="column"
				mb={0.9}
				{...rest}
			>
				{!!label && (
					<Box mb={0.75}>
						<Label tooltip={tooltip}>{label}</Label>
					</Box>
				)}
				{children}
				<AnimatedEntrance show={!!error} duration={200} direction="down">
					<Box mt={0.75}>
						<ErrorText>{error}</ErrorText>
					</Box>
				</AnimatedEntrance>
			</Box>
		)
	}
)
