import { IconName } from "../components/typography"

export const iconLookup = {
	"01d": {
		openWeather: "01d",
		fontAwesome: "sun",
	},
	"01n": {
		openWeather: "01n",
		fontAwesome: "moon",
	},
	"02d": {
		openWeather: "02d",
		fontAwesome: "clouds-sun",
	},
	"02n": {
		openWeather: "02n",
		fontAwesome: "moon-cloud",
	},
	"03d": {
		openWeather: "03d",
		fontAwesome: "clouds-sun",
	},
	"03n": {
		openWeather: "03n",
		fontAwesome: "clouds-moon",
	},
	"04d": {
		openWeather: "04d",
		fontAwesome: "clouds",
	},
	"04n": {
		openWeather: "04n",
		fontAwesome: "clouds",
	},
	"09d": {
		openWeather: "09d",
		fontAwesome: "cloud-rain",
	},
	"09n": {
		openWeather: "09n",
		fontAwesome: "cloud-rain",
	},
	"10d": {
		openWeather: "10d",
		fontAwesome: "cloud-showers-heavy",
	},
	"10n": {
		openWeather: "10n",
		fontAwesome: "cloud-showers-heavy",
	},
	"11d": {
		openWeather: "11d",
		fontAwesome: "thunderstorm",
	},
	"11n": {
		openWeather: "11n",
		fontAwesome: "thunderstorm",
	},
	"13d": {
		openWeather: "13d",
		fontAwesome: "cloud-snow",
	},
	"13n": {
		openWeather: "13n",
		fontAwesome: "cloud-snow",
	},
	"50d": {
		openWeather: "50d",
		fontAwesome: "fog",
	},
	"50n": {
		openWeather: "50n",
		fontAwesome: "fog",
	},
}

export const faWeatherIconLookup = (openWeatherIconName: string): IconName => {
	return (iconLookup[openWeatherIconName as keyof typeof iconLookup]?.fontAwesome ??
		"clouds") as IconName
}
