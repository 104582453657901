import React, { useCallback } from "react"

import { Control, Controller, FieldValues, Path, PathValue } from "react-hook-form"

import { extractNumber } from "@ncs/ts-utils"

import { NumericInput, NumericInputProps } from "../inputs"

export interface NumericInputFormFieldProps<TFieldValues extends FieldValues>
	extends NumericInputProps {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	placeholder?: string
	emptyValueFallback?: number | string | null
	returnValueAsString?: boolean
}

export const NumericInputFormField = <TFieldValues extends FieldValues>({
	name,
	control,
	placeholder,
	emptyValueFallback = null,
	returnValueAsString = false,
	...rest
}: NumericInputFormFieldProps<TFieldValues>): React.ReactElement => {
	const getValue = useCallback(
		(value: PathValue<TFieldValues, Path<TFieldValues>>) => {
			if (value == null || value === "") {
				return emptyValueFallback
			} else {
				return extractNumber(value)
			}
		},
		[emptyValueFallback]
	)

	const getOnChangeValue = useCallback(
		(value: number | undefined) => {
			if (value == null) {
				return returnValueAsString ? "" : emptyValueFallback
			} else {
				return returnValueAsString ? String(value) : extractNumber(value)
			}
		},
		[emptyValueFallback, returnValueAsString]
	)

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<NumericInput
						placeholder={placeholder}
						{...rest}
						onBlur={onBlur}
						value={getValue(value)}
						onChange={(newValue) => onChange(getOnChangeValue(newValue))}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}
