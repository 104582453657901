import React, { memo, useState } from "react"

import { Column } from "react-table"

import {
	CustomerEquipment,
	initialCustomerEquipmentQueryParams,
	useCustomerEquipment,
} from "@ncs/ncs-api"
import { SearchQueryFilter, Table, useUrlState } from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

import { EquipmentDetailModal } from "./components"

const columns: Column<CustomerEquipment>[] = [
	{
		Header: "Model",
		id: "eqpVintage_Name",
		accessor: (equipment) => equipment.eqpVintage?.name,
	},
	{
		Header: "Site",
		id: "customer_Name",
		accessor: (equipment) => equipment.customer.name,
	},
	{
		Header: "Serial #",
		accessor: "serialNumber",
		disableSortBy: true,
	},
]

export type MyEquipmentUrlState = {
	equipmentModalId: string | null
}

export const MyEquipment: React.FC = () => {
	const [queryParamState, setQueryParamState] = useState(initialCustomerEquipmentQueryParams)
	const equipmentQuery = useCustomerEquipment({
		params: queryParamState,
	})

	const [{ equipmentModalId }, { updateUrlValue }] = useUrlState<MyEquipmentUrlState>({
		equipmentModalId: null,
	})

	return (
		<PageContentWrapper heading="My Equipment" breadcrumbs={[{ name: "My Equipment" }]}>
			<Table
				columns={columns}
				query={equipmentQuery}
				toggledQueryFilters={[SearchQueryFilter]}
				queryParamState={queryParamState}
				setQueryParamState={setQueryParamState}
				onRowClick={(row) => updateUrlValue("equipmentModalId", row.original.id)}
				noDataText="No equipment found"
				loadingText="Loading your equipment..."
			/>

			<EquipmentDetailModal
				isOpen={!!equipmentModalId}
				onClose={() => updateUrlValue("equipmentModalId", null)}
				equipmentId={equipmentModalId}
				initialEquipmentData={equipmentQuery.data.find((e) => e.id === equipmentModalId)}
			/>
		</PageContentWrapper>
	)
}

export default memo(MyEquipment)
