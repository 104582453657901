/**
 * Check if a given string is a US state. Checks both abbreviation and full name.
 */
export const isUsState = (value: string | null | undefined): boolean => {
	if (!value) return false

	if (value.length === 2) {
		return usStates.some(({ abbreviation }) => value.toUpperCase() === abbreviation)
	}

	return usStates.some(({ name }) => value.toLowerCase() === name.toLowerCase())
}

/**
 * Check if a given string is a Canadian province. Checks both abbreviation and full name.
 */
export const isCanadianProvince = (value: string | null | undefined): boolean => {
	if (!value) return false

	if (value.length === 2) {
		return canadianProvinces.some(({ abbreviation }) => value.toUpperCase() === abbreviation)
	}

	return canadianProvinces.some(({ name }) => value.toLowerCase() === name.toLowerCase())
}

export const usStates: {
	name: string
	abbreviation: string
}[] = [
	{ abbreviation: "AL", name: "Alabama" },
	{ abbreviation: "AK", name: "Alaska" },
	{ abbreviation: "AS", name: "American Samoa" },
	{ abbreviation: "AZ", name: "Arizona" },
	{ abbreviation: "AR", name: "Arkansas" },
	{ abbreviation: "CA", name: "California" },
	{ abbreviation: "CO", name: "Colorado" },
	{ abbreviation: "CT", name: "Connecticut" },
	{ abbreviation: "DE", name: "Delaware" },
	{ abbreviation: "DC", name: "District Of Columbia" },
	{ abbreviation: "FM", name: "Federated States Of Micronesia" },
	{ abbreviation: "FL", name: "Florida" },
	{ abbreviation: "GA", name: "Georgia" },
	{ abbreviation: "GU", name: "Guam" },
	{ abbreviation: "HI", name: "Hawaii" },
	{ abbreviation: "ID", name: "Idaho" },
	{ abbreviation: "IL", name: "Illinois" },
	{ abbreviation: "IN", name: "Indiana" },
	{ abbreviation: "IA", name: "Iowa" },
	{ abbreviation: "KS", name: "Kansas" },
	{ abbreviation: "KY", name: "Kentucky" },
	{ abbreviation: "LA", name: "Louisiana" },
	{ abbreviation: "ME", name: "Maine" },
	{ abbreviation: "MH", name: "Marshall Islands" },
	{ abbreviation: "MD", name: "Maryland" },
	{ abbreviation: "MA", name: "Massachusetts" },
	{ abbreviation: "MI", name: "Michigan" },
	{ abbreviation: "MN", name: "Minnesota" },
	{ abbreviation: "MS", name: "Mississippi" },
	{ abbreviation: "MO", name: "Missouri" },
	{ abbreviation: "MT", name: "Montana" },
	{ abbreviation: "NE", name: "Nebraska" },
	{ abbreviation: "NV", name: "Nevada" },
	{ abbreviation: "NH", name: "New Hampshire" },
	{ abbreviation: "NJ", name: "New Jersey" },
	{ abbreviation: "NM", name: "New Mexico" },
	{ abbreviation: "NY", name: "New York" },
	{ abbreviation: "NC", name: "North Carolina" },
	{ abbreviation: "ND", name: "North Dakota" },
	{ abbreviation: "MP", name: "Northern Mariana Islands" },
	{ abbreviation: "OH", name: "Ohio" },
	{ abbreviation: "OK", name: "Oklahoma" },
	{ abbreviation: "OR", name: "Oregon" },
	{ abbreviation: "PW", name: "Palau" },
	{ abbreviation: "PA", name: "Pennsylvania" },
	{ abbreviation: "PR", name: "Puerto Rico" },
	{ abbreviation: "RI", name: "Rhode Island" },
	{ abbreviation: "SC", name: "South Carolina" },
	{ abbreviation: "SD", name: "South Dakota" },
	{ abbreviation: "TN", name: "Tennessee" },
	{ abbreviation: "TX", name: "Texas" },
	{ abbreviation: "UT", name: "Utah" },
	{ abbreviation: "VT", name: "Vermont" },
	{ abbreviation: "VI", name: "Virgin Islands" },
	{ abbreviation: "VA", name: "Virginia" },
	{ abbreviation: "WA", name: "Washington" },
	{ abbreviation: "WV", name: "West Virginia" },
	{ abbreviation: "WI", name: "Wisconsin" },
	{ abbreviation: "WY", name: "Wyoming" },
]

export const canadianProvinces: {
	abbreviation: string
	name: string
}[] = [
	{ abbreviation: "AB", name: "Alberta" },
	{ abbreviation: "BC", name: "British Columbia" },
	{ abbreviation: "MB", name: "Manitoba" },
	{ abbreviation: "NB", name: "New Brunswick" },
	{ abbreviation: "NL", name: "Newfoundland and Labrador" },
	{ abbreviation: "NT", name: "Northwest Territories" },
	{ abbreviation: "NS", name: "Nova Scotia" },
	{ abbreviation: "NU", name: "Nunavat Territory" },
	{ abbreviation: "ON", name: "Ontario" },
	{ abbreviation: "PE", name: "Prince Edward Island" },
	{ abbreviation: "QC", name: "Quebec" },
	{ abbreviation: "SK", name: "Saskatchewan" },
	{ abbreviation: "YT", name: "Yukon Territory" },
]
