import { Dispatch, ReactElement, SetStateAction } from "react"

import { DispatchCallTypeSelector } from "../selectors"

interface DispatchCallTypeQueryParams {
	callTypeId?: string | null
	callTypeId_In?: string | null
}

export interface DispatchCallTypeQueryFilterProps<
	QueryParamState extends DispatchCallTypeQueryParams,
> {
	queryParamState: QueryParamState
	setQueryParamState: Dispatch<SetStateAction<QueryParamState>>
	paramKey?: keyof DispatchCallTypeQueryParams
}

export const DispatchCallTypeQueryFilter = <QueryParamState extends DispatchCallTypeQueryParams>({
	queryParamState,
	setQueryParamState,
	paramKey = "callTypeId",
}: DispatchCallTypeQueryFilterProps<QueryParamState>): ReactElement => {
	const handleChange = (newValue: string | null) => {
		setQueryParamState((prev) => ({
			...prev,
			[paramKey]: newValue,
		}))
	}

	return (
		<DispatchCallTypeSelector
			value={queryParamState[paramKey] ?? null}
			onChange={handleChange}
			disableNoSelectionOption={false}
			noSelectionOptionText="Any call type"
			fillContainer
		/>
	)
}
