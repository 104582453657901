import { useLocation } from "react-router-dom"

import { arrayWrap } from "@ncs/ts-utils"

import { decodeUrlValue, ParamValue, UrlState } from "./UrlStateContext"

type StringParam = "string"
type NumberParam = "number"
type BooleanParam = "boolean"
type ArrayParam = "array"

export function useUrlParam<State extends UrlState = UrlState>(
	param: Extract<keyof State, string>
): string | null
export function useUrlParam<State extends UrlState = UrlState>(
	param: Extract<keyof State, string>,
	expectedType: StringParam
): string | null
export function useUrlParam<State extends UrlState = UrlState>(
	param: Extract<keyof State, string>,
	expectedType: NumberParam
): number | null
export function useUrlParam<State extends UrlState = UrlState>(
	param: Extract<keyof State, string>,
	expectedType: BooleanParam
): boolean | null
export function useUrlParam<State extends UrlState = UrlState>(
	param: Extract<keyof State, string>,
	expectedType: ArrayParam
): ParamValue[] | null
export function useUrlParam<State extends UrlState = UrlState>(
	param: Extract<keyof State, string>,
	expectedType: StringParam | NumberParam | BooleanParam | ArrayParam = "string"
): ParamValue | ParamValue[] {
	const location = useLocation()
	const locationParams = new URLSearchParams(location.search)
	const rawValue = locationParams.get(String(param))

	let value: ParamValue | ParamValue[]

	if (!rawValue) return null

	try {
		value = decodeUrlValue(rawValue)
	} catch (e) {
		// If JSON parse fails, then just run decodeURIComponent.
		value = decodeURIComponent(rawValue)
	}

	switch (expectedType) {
		case "string": {
			if (typeof value !== "string") {
				console.warn(
					`URL param "${param}" was expected to be a string, but a ${typeof value} was found instead. Coercing to a string...`
				)
			}
			return String(value)
		}
		case "number": {
			if (typeof value !== "number") {
				console.warn(
					`URL param "${param}" was expected to be a number, but a ${typeof value} was found instead. Coercing to a number...`
				)
			}
			value = Number(value)
			return !Number.isNaN(value) ? value : 0
		}
		case "boolean": {
			if (typeof value !== "boolean") {
				console.warn(
					`URL param "${param}" was expected to be a boolean, but a ${typeof value} was found instead. Coercing to a boolean...`
				)
				return value === "true" || value === "True"
			}
			return value
		}
		case "array": {
			if (!Array.isArray(value)) {
				console.warn(
					`URL param "${param}" was expected to be an array, but a ${typeof value} was found instead. Wrapping it in an array...`
				)
			}
			return arrayWrap(value)
		}
	}
}
