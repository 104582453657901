export const truck = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
		<path
			d="M1.5 0C0.671875 0 0 0.671875 0 1.5V11.5C0 12.3281 0.671875 13 1.5 13H2C2 14.6562 3.34375 16 5 16C6.65625 16 8 14.6562 8 13H12C12 14.6562 13.3438 16 15 16C16.6562 16 18 14.6562 18 13H19C19.5531 13 20 12.5531 20 12C20 11.4469 19.5531 11 19 11V9V8V7.41563C19 6.88438 18.7906 6.375 18.4156 6L16 3.58437C15.625 3.20937 15.1156 3 14.5844 3H13V1.5C13 0.671875 12.3281 0 11.5 0H1.5ZM13 5H14.5844L17 7.41563V8H13V5ZM3.5 13C3.5 12.6022 3.65804 12.2206 3.93934 11.9393C4.22064 11.658 4.60218 11.5 5 11.5C5.39782 11.5 5.77936 11.658 6.06066 11.9393C6.34196 12.2206 6.5 12.6022 6.5 13C6.5 13.3978 6.34196 13.7794 6.06066 14.0607C5.77936 14.342 5.39782 14.5 5 14.5C4.60218 14.5 4.22064 14.342 3.93934 14.0607C3.65804 13.7794 3.5 13.3978 3.5 13ZM15 11.5C15.3978 11.5 15.7794 11.658 16.0607 11.9393C16.342 12.2206 16.5 12.6022 16.5 13C16.5 13.3978 16.342 13.7794 16.0607 14.0607C15.7794 14.342 15.3978 14.5 15 14.5C14.6022 14.5 14.2206 14.342 13.9393 14.0607C13.658 13.7794 13.5 13.3978 13.5 13C13.5 12.6022 13.658 12.2206 13.9393 11.9393C14.2206 11.658 14.6022 11.5 15 11.5Z"
			fill="white"
		/>
	</svg>
)

export const screwdriver = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M5.0707 2.19577C4.69963 1.90672 4.17231 1.94188 3.83639 2.27389L2.27398 3.83631C1.94197 4.16832 1.90681 4.69563 2.19195 5.07061L5.31678 9.13289C5.49255 9.36334 5.76988 9.50006 6.05893 9.50006H8.17209L12.4297 13.7576C11.8555 14.8904 12.0391 16.3122 12.9882 17.2574L17.363 21.6322C17.8512 22.1205 18.6442 22.1205 19.1324 21.6322L21.6323 19.1323C22.1205 18.6441 22.1205 17.8512 21.6323 17.3629L17.2575 12.9881C16.3123 12.0429 14.8905 11.8554 13.7577 12.4296L9.50014 8.172V6.05884C9.50014 5.76589 9.36343 5.49246 9.13298 5.31669L5.0707 2.19577ZM2.77786 17.4723C2.28179 17.9683 2.00056 18.6441 2.00056 19.3472C2.00056 20.8119 3.18799 21.9994 4.65275 21.9994C5.35584 21.9994 6.03158 21.7181 6.52765 21.2221L11.129 16.6208C10.8243 15.8044 10.7774 14.9177 10.9883 14.0779L8.57832 11.6679L2.77786 17.4723ZM21.9995 7.62516C21.9995 7.21503 21.9565 6.81661 21.8745 6.43382C21.7807 5.99634 21.2456 5.88307 20.9292 6.19946L18.4332 8.69541C18.3161 8.81259 18.1559 8.879 17.9919 8.879L15.7498 8.87509C15.4061 8.87509 15.1248 8.59386 15.1248 8.25012V6.00806C15.1248 5.84401 15.1912 5.68386 15.3084 5.56668L17.8044 3.07072C18.1208 2.75433 18.0075 2.21921 17.57 2.12546C17.1833 2.04344 16.7849 2.00047 16.3748 2.00047C13.2695 2.00047 10.7501 4.51986 10.7501 7.62516V7.65641L14.0819 10.9883C15.4881 10.6328 17.0427 11.0078 18.1442 12.1093L18.7574 12.7225C20.6714 11.8241 21.9995 9.87894 21.9995 7.62516ZM4.18794 18.8745C4.18794 18.6259 4.2867 18.3875 4.46251 18.2117C4.63831 18.0359 4.87676 17.9371 5.12538 17.9371C5.37401 17.9371 5.61246 18.0359 5.78826 18.2117C5.96407 18.3875 6.06283 18.6259 6.06283 18.8745C6.06283 19.1232 5.96407 19.3616 5.78826 19.5374C5.61246 19.7132 5.37401 19.812 5.12538 19.812C4.87676 19.812 4.63831 19.7132 4.46251 19.5374C4.2867 19.3616 4.18794 19.1232 4.18794 18.8745Z"
			fill="white"
		/>
	</svg>
)

export const message = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M4.50011 3C3.12114 3 2 4.12114 2 5.50011V16.7506C2 18.1296 3.12114 19.2507 4.50011 19.2507H8.25027V22.3758C8.25027 22.6141 8.38309 22.829 8.59403 22.9345C8.80498 23.0399 9.0589 23.0165 9.25031 22.8759L14.0826 19.2507H19.5008C20.8797 19.2507 22.0009 18.1296 22.0009 16.7506V5.50011C22.0009 4.12114 20.8797 3 19.5008 3H4.50011Z"
			fill="white"
		/>
	</svg>
)

export const shoppingCart = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
		<path
			d="M20.3375 13.2625L21.9789 6.04029C22.0974 5.51883 21.7011 5.02227 21.1663 5.02227H7.52806L7.20979 3.46637C7.13049 3.07856 6.78924 2.80005 6.39337 2.80005H2.83333C2.37309 2.80005 2 3.17314 2 3.63338V4.18894C2 4.64918 2.37309 5.02227 2.83333 5.02227H5.25983L7.69899 16.9471C7.11545 17.2827 6.72222 17.9119 6.72222 18.6334C6.72222 19.7073 7.59278 20.5778 8.66667 20.5778C9.74056 20.5778 10.6111 19.7073 10.6111 18.6334C10.6111 18.0891 10.3873 17.5974 10.0269 17.2445H17.3064C16.9461 17.5974 16.7222 18.0891 16.7222 18.6334C16.7222 19.7073 17.5928 20.5778 18.6667 20.5778C19.7406 20.5778 20.6111 19.7073 20.6111 18.6334C20.6111 17.8635 20.1636 17.1982 19.5146 16.8832L19.7062 16.0403C19.8247 15.5188 19.4283 15.0223 18.8936 15.0223H9.57351L9.34625 13.9112H19.5249C19.914 13.9112 20.2513 13.6419 20.3375 13.2625Z"
			fill="white"
		/>
	</svg>
)

export const dollarSign = (): JSX.Element => (
	<svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
		<clipPath id="a">
			<path d="m0 0h24v24h-24z" />
		</clipPath>
		<g clipPath="url(#a)">
			<path
				d="m11.5 0c.8297 0 1.5.670312 1.5 1.5v1.67344c.075.00937.1453.01875.2203.03281.0188.00469.0328.00469.0516.00937l2.25.41251c.8156.15 1.3547.93281 1.2047 1.74374-.15.81094-.9328 1.35469-1.7438 1.20469l-2.2265-.40781c-1.4672-.21562-2.76098-.07031-3.67036.29063-.90937.36093-1.275.85781-1.35937 1.31718-.09375.50156-.02344.78281.05625.95625.08437.18282.25781.38906.6.61875.76406.50156 1.93598.82974 3.45468 1.23284l.1359.0375c1.3407.3562 2.9813.7875 4.2 1.5843.6657.436 1.2938 1.0266 1.6829 1.8516.3984.8391.4828 1.7766.3 2.775-.3235 1.7813-1.5516 2.9719-3.075 3.5953-.6422.2625-1.3407.4313-2.0813.5156v1.5657c0 .8297-.6703 1.5-1.5 1.5s-1.5-.6703-1.5-1.5v-1.636c-.01874-.0047-.04218-.0047-.06093-.0093h-.00938c-1.14375-.1782-3.02343-.6704-4.28906-1.2329-.75469-.3375-1.09687-1.2234-.75937-1.9781s1.22343-1.0969 1.97812-.7594c.97969.436 2.59219.8672 3.52502 1.0125 1.4953.2204 2.7281.0938 3.5625-.2484.7922-.3234 1.1531-.7922 1.2562-1.3547.0891-.4969.0188-.7828-.0609-.9562-.0891-.1875-.2625-.3938-.6094-.6235-.7687-.5015-1.9453-.8297-3.4687-1.2328l-.1313-.0328c-1.33592-.3656-2.97654-.8016-4.19529-1.5984-.66563-.436-1.28907-1.0313-1.67813-1.8563-.39375-.83904-.47344-1.77654-.28594-2.77498.3375-1.79062 1.67813-2.95312 3.20157-3.55781.62343-.24843 1.30781-.41718 2.02499-.51562v-1.65469c0-.829688.6703-1.5 1.5-1.5z"
				fill="#fff"
			/>
		</g>
	</svg>
)
