import React, { memo, useMemo, useState } from "react"

import { css } from "@emotion/react"

import {
	AlertCategory,
	APPLICATION,
	useAlertContactChoices,
	useAlertTypes,
	useIsSuperuser,
	useSites,
	useUserCanUse,
} from "@ncs/ncs-api"
import {
	Box,
	Callout,
	getAddressFields,
	Heading,
	HeadingDivider,
	Paragraph,
	SimpleButton,
	SiteSelector,
	useSetUrlState,
} from "@ncs/web-legos"

import { AccountTabName, AccountUrlState } from "../../Account"
import { useCombinedAlertRecipients } from "./alerts-tab-util"
import { AlertsCategoryTree } from "./components"

export const AlertsTab: React.FC = memo(() => {
	const setUrlState = useSetUrlState<AccountUrlState>()
	const canUseConnectivity = useUserCanUse(APPLICATION.Connectivity)
	const canUseDispatch = useUserCanUse(APPLICATION.CreateDispatch)

	const isSuperuser = useIsSuperuser()
	const [sitesRaw, sitesLoading] = useSites()
	const sites = useMemo(() => sitesRaw ?? [], [sitesRaw])
	const [selectedSiteId, setSelectedSiteId] = useState<string | null>(null)
	const selectedSite = useMemo(
		() => sites.find((s) => s.id === selectedSiteId),
		[sites, selectedSiteId]
	)

	const [recipients] = useCombinedAlertRecipients(selectedSiteId)
	const [alertTypes, alertsLoading] = useAlertTypes()
	const [contactAlertChoices] = useAlertContactChoices(selectedSite?.id)

	const machineAlerts = useMemo(() => {
		return (alertTypes ?? []).filter((a) => a.category === AlertCategory.MACHINE)
	}, [alertTypes])
	const serviceRequestAlerts = useMemo(() => {
		return (alertTypes ?? []).filter((a) => a.category === AlertCategory.DISPATCH)
	}, [alertTypes])
	const orderAlerts = useMemo(() => {
		return (alertTypes ?? []).filter((a) => a.category === AlertCategory.PART_ORDER)
	}, [alertTypes])

	if (!sitesLoading && sites.length === 0) {
		return (
			<Box display="flex" justifyContent="center" pt={3}>
				<Callout maxWidth={30} icon="info-circle">
					<Paragraph>
						Thanks for your interest, but at this time the <strong>Alerts</strong>{" "}
						functionality is limited to customers who have their car wash sites set up
						with NCS. Please reach out to your sales representative for more
						information!
					</Paragraph>
				</Callout>
			</Box>
		)
	}

	return (
		<>
			{isSuperuser && (
				<Callout p={1.5} mb={4} variant="info">
					<Heading bold mb={1} mt={0}>
						Notifications
					</Heading>

					<ul css={instructionsCss}>
						<li>
							<Paragraph>
								You can set notification preferences for individual sites, or set
								them for all sites at once.
							</Paragraph>
						</li>
						<li>
							<Paragraph>
								The alerts for each site can go to yourself or any other user that
								has permissions to that site.
							</Paragraph>
						</li>
						<li>
							<Paragraph>
								Manage contact information and the users that have permissions to
								yours sites in the{" "}
								<SimpleButton
									onClick={() => setUrlState({ tab: AccountTabName.Account })}
								>
									Account tab
								</SimpleButton>
							</Paragraph>
						</li>
					</ul>
				</Callout>
			)}

			<Box display="flex" alignItems="center" flexWrap="wrap" mb={4}>
				<Paragraph>Set alerts for: </Paragraph>
				<SiteSelector
					label={undefined}
					value={selectedSiteId}
					onChange={setSelectedSiteId}
					noSelectionOptionText="All your sites"
					disableNoSelectionOption={false}
					mb={0}
					ml={0.75}
				/>
			</Box>

			<HeadingDivider
				headingText={selectedSite?.name ?? "All Your Sites"}
				detailText={
					selectedSite ?
						getAddressFields(selectedSite, {
							exclude: ["name", "zip", "address1"],
						}).join(", ")
					:	undefined
				}
				icon={selectedSite ? undefined : "globe"}
				mb={3}
			/>

			{isSuperuser && !sitesLoading && (
				<Paragraph mb={2}>
					Showing only users with permissions to{" "}
					<strong>{selectedSite ? selectedSite.name : "all your sites"}</strong>.
				</Paragraph>
			)}

			{canUseDispatch && (
				<AlertsCategoryTree
					alertCategory={AlertCategory.DISPATCH}
					alerts={serviceRequestAlerts}
					recipients={recipients}
					contactAlertChoices={contactAlertChoices}
					alertsLoading={alertsLoading}
					selectedSiteId={selectedSiteId}
				/>
			)}
			<AlertsCategoryTree
				alertCategory={AlertCategory.PART_ORDER}
				alerts={orderAlerts}
				recipients={recipients}
				contactAlertChoices={contactAlertChoices}
				alertsLoading={alertsLoading}
				selectedSiteId={selectedSiteId}
			/>
			{canUseConnectivity && (
				<AlertsCategoryTree
					alertCategory={AlertCategory.MACHINE}
					alerts={machineAlerts}
					recipients={recipients}
					contactAlertChoices={contactAlertChoices}
					alertsLoading={alertsLoading}
					selectedSiteId={selectedSiteId}
				/>
			)}
		</>
	)
})

const instructionsCss = css`
	li {
		margin-bottom: 0.5rem;
		margin-left: 1rem;
		list-style-type: disc;
	}
`

AlertsTab.displayName = "AlertsTab"
