import { z } from "zod"

import { Id } from "@ncs/ts-utils"

import { OrderingQueryParams, SearchQueryParams } from "../../request-hooks"
import { APPLICATION } from "../auth"
import { LineItemType, OldLookup, ReferenceModel, ShipMethod } from "../common"
import { CustomerAlternateAddress, CustomerMinimal, CustomerWithAddress } from "../customers"
import { InventoryPart, PickListStatus } from "../inventory"
import { InvoiceStatus, LtlCarrier, PurchaseOrderStatus } from "../lookups"
import { PaymentMethod, SquareCardBrand } from "../payments"
import { ShippingService } from "../point-of-sale"
import { TerritoryMinimal, UserMinimal } from "../security"

export enum PartOrdersUrlPaths {
	Accessorial = "accessorial",
	AddDeliveryComment = "add_delivery_comment",
	AddOrderInvoiceLine = "add_order_invoice_line",
	AddTrackingInfo = "add_tracking_info",
	AdditionalEntryApproval = "additional_entry_approval",
	ApproveInvoice = "approve_invoice",
	BulkReceivePartOrder = "bulk_receive_part_order",
	Cancel = "cancel",
	CancelDrop = "cancel_drop",
	CheckFullShipment = "check_full_shipment",
	CheckItemLevels = "check_item_levels",
	CheckOrderItems = "check_order_items",
	Complete = "complete",
	Create = "create",
	Customer = "customer",
	CustomerOrders = "customer_orders",
	Dashboard = "dashboard",
	Deallocate = "deallocate",
	DeallocateLine = "deallocate_line",
	DeleteOrderInvoiceLine = "delete_order_invoice_line",
	Detail = "detail",
	Documents = "documents",
	FedEx = "fedex",
	FinalizePurchaseOrder = "finalize_purchase_order",
	ForceShip = "force_ship",
	FreightAccessorial = "freight_accessorial",
	Info = "info",
	Invoice = "invoice",
	Line = "line",
	LineItems = "line_items",
	LineOverrideRequest = "line_override_request",
	List = "list",
	Order = "order",
	OrderInProgress = "order_in_progress",
	Orders = "orders",
	PartCrossReference = "part_cross_reference",
	PartOrders = "part_orders",
	Parts = "parts",
	PickLists = "pick_lists",
	Promotions = "promotions",
	Punchout = "punchout",
	PurchaseOrder = "purchase-order",
	PurchaseOrders = "purchase_orders",
	Recalculate = "recalculate",
	RecalculateLine = "recalculate_line",
	ReceivePartOrder = "receive_part_order",
	ReceivePurchaseOrder = "receive_purchase_order",
	RecreateDocument = "recreate_document",
	RecreateOrderDoc = "recreate_order_doc",
	Reopen = "reopen",
	ResetOrder = "reset_order",
	Scheduler = "scheduler",
	Search = "search",
	Shipment = "shipment",
	ShipmentPackageItems = "shipment-package-items",
	Shipments = "shipments",
	Submit = "submit",
	UpdateOrderInvoiceLine = "update_order_invoice_line",
	UpdateQuantities = "update_quantities",
	UpdateShipment = "update_shipment",
	UploadAttachment = "upload_attachment",
}

export type PartOrderStatus = OldLookup<PartOrderStatusId>

export interface PartOrderPart {
	id: string
	partNumber: string
	partFamily: string | null
	description: string
	weight: string | null
}

export interface InTransitPartOrderItem {
	id: string
	carrier: Id<LtlCarrier, number> | null
	shipMethod: ShipMethod | null
	part: PartOrderPart
	quantityInTransit: number
	shipDate: string | null
	waybillNumberShippedOn: string | null
	freightCharge: string | null
	deliveryDate: string | null
	trackingLink: string | null
	estimatedDeliveryDate: string | null
}

export type OnlinePartOrderPart = {
	id: string
	onlinePartNumber: string
	description: string | null
	imageUrl: string | null
	title: string | null
}

export type CustomerPartOrderLineItem = {
	id: string
	description: string
	lineTypeId: string | null
	onlinePart: OnlinePartOrderPart | null
	onlinePartId: number | null
	part: {
		id: string
		listPrice: string
	} | null
	quantity: string
	quantityInTransit: number
	shippingMethodId: string | null
	status: null | {
		id: string
		code: string
		description: string
	}
	unitPrice: string
	unitTax: string | null
}

export type CustomerPartOrder = {
	id: string
	alternateAddress: {
		address1: string | null
		address2: string
		city: string
		postalcode: string // sic
		state: string
	} | null
	billToCustomer: CustomerMinimal
	comment: string | null
	createdBy: UserMinimal | null
	createdDate: string
	deliveryDate: string | null
	inTransit: InTransitPartOrderItem[]
	kbmOrderNumber: string | null
	lineItems: CustomerPartOrderLineItem[]
	modifiedBy: number | null
	modifiedDate: string | null
	orderDate: string
	orderId: number
	outsidePurchase: boolean
	purchaseOrderNumber: string | null
	shipToCustomer: CustomerWithAddress
	status: ReferenceModel | null
	taxTotal: string
	subTotal: string
	total: string
	vendorId: number | null
}

export interface CustomerPartOrderQueryParams extends SearchQueryParams, OrderingQueryParams {
	startDate: string | null
	endDate: string | null
	site: string | null
	partOrderStatus: string | null
}

export const CustomerPartOrderPostLineItemSchema = z.object({
	partId: z.string().nullable(),
	onlinePartId: z.string().nullable(),
	taxRateUsed: z.string(),
	unitTax: z.string(),
	unitPrice: z.number(),
	description: z.string(),
	quantity: z.number(),
	shipping: z.nativeEnum(ShippingService).nullable(),
	lineTypeId: z.nativeEnum(LineItemType),
})
export type CustomerPartOrderPostLineItem = z.infer<typeof CustomerPartOrderPostLineItemSchema>

export const PaymentMethodBundleSchema = z
	.object({
		method: z.literal(PaymentMethod.SQUARE_CARD),
		data: z.object({
			nonce: z.string(),
			verificationToken: z.string(),
			brand: z.nativeEnum(SquareCardBrand),
			last4: z.string(),
			expMonth: z.number(),
			expYear: z.number(),
			billingPostalCode: z.string().nullable(),
			digitalWalletType: z.string(),
		}),
	})
	.or(
		z.object({
			method: z.literal(PaymentMethod.CUSTOMER_ACCOUNT),
			data: z.number(),
		})
	)
	.or(
		z.object({
			method: z.literal(PaymentMethod.STORED_CARD),
			data: z.string(),
		})
	)
	.or(
		z.object({
			method: z.literal(PaymentMethod.STORED_BANK_ACCOUNT),
			data: z.string(),
		})
	)
export type PaymentMethodBundle = z.infer<typeof PaymentMethodBundleSchema>

export const CustomerPartOrderPostSchema = z.object({
	comment: z.string().nullable(),
	lineItems: z.array(CustomerPartOrderPostLineItemSchema),
	payment: PaymentMethodBundleSchema.optional(),
	purchaseOrderNumber: z.string().nullish(),
	sessionId: z.string().nullish(), // The punch out ID, if it exists.
	shipToCustomerId: z.string().optional(),
	alternateAddress: z
		.object({
			address1: z.string().nullable(),
			address2: z.string(),
			city: z.string(),
			state: z.string(),
			postalcode: z.string(),
		})
		.optional(),
	contactPhone: z.string().optional(), // Don't include on DB punchout.
})
export type CustomerPartOrderPost = z.infer<typeof CustomerPartOrderPostSchema>

export interface PartOrderDispatch {
	id: string
	customer: CustomerMinimal
	dispatchNumber: string
	poTicket: string | null
}

export interface PartOrderInvoiceStatus {
	id: InvoiceStatus["id"]
	code: InvoiceStatus["code"]
}

export interface PartOrderInvoice {
	id: string
	invoiceNumber: string
	status: PartOrderInvoiceStatus
	statusId: number
}

export interface DeliveryOrderLineMin {
	id: string
	quantity: string
	deliveryOrderId: string | null
}

export interface PickListLineMin {
	id: string
	pickListId: number | null
	statusId: number
	locationCode: string
	locationName: string
}

export interface PurchaseOrderMin {
	id: string
	kbmOrderNumber: string | null
	statusId: PurchaseOrderStatusId
}

export interface PurchaseOrderLineItemPartOrder {
	purchaseOrder: PurchaseOrderMin
}

export interface PartOrderVendor {
	id: string
	name: string | null
	vendorCode: string | null
}

export enum PartOrderStatusId {
	InProgress = "1",
	Submitted = "2",
	Ordered = "3",
	Shipped = "4",
	Received = "5",
	Removed = "6",
	InStock = "7",
	Pulled = "8",
	Invoiced = "9",
	Closed = "10",
	ShippedToCustomer = "11",
	PickListGenerated = "12",
	OneTimeYearEnd = "13",
	PendingShipment = "14",
	ReadyForPickup = "15",
	ReceivedByCustomer = "16",
	PaymentPending = "17",
	PaymentFailed = "18",
	DispatchCreated = "19",
	ShipmentComplete = "20",
	LocationBackstocked = "21",
	Hold = "22",
}

export interface CustomerInternal {
	isInternal: boolean | null
}

export interface CreatePartOrderPost {
	billToCustomerId: string
	shipToCustomerId: string
	useAlternateAddress: boolean
	alternateAddressId: string | null
	lineItems: {
		description: string
		partId: string | null
		quantity: number
		unitPrice: string // Price of the part for the customer in question.
		unitTax: string
		taxRateUsed: string
		lineTypeId: string | number
		overridePermission: string | null
		reasonId: string | null
		reasonComment: string | null // If reason selected was "Other"
		overrideAmount: number | null // Will be equal to unitPrice if user has permission to override price.
	}[]
	shipMethodId: string
	comment: string | null
	isMachineDown: boolean
	shipComplete: boolean
	purchaseOrderNumber: string | null
	sourceId: number
	targetDeliveryDate: string | null
	onlinePurchase: boolean
	appliedPromotions: string[] | null
	contactPhone: string
	onHold: boolean
	holders: string[] | null
	internalComment: string | null

	// They're there, but KC doesn't do anything with them.
	paymentMethodId: null
	squarePaymentId: null
	inventoryLocationId: null
}

export interface OrderDocument {
	id: number
	documentType: number
	name: string
}

export interface CheckPartOrderItemLevelsPost {
	customerId: string
	locationId?: string | null // System uses backend default for customer if this isn't included.
	items: {
		partId: string
		quantity: number
		description: string
		partNumber: string | null
	}[]
}

export interface OutOfStockItem {
	partId: string
	partNumber: string
	description: string
	quantity: number
}

export interface PartOrderItemLevels {
	rdc: string | null
	outOfStockItems: OutOfStockItem[]
}

export interface BulkReceivePartOrderPost {
	locationId: string
	partOrderId: string
	lines: {
		lineId: string
		binQuantities: {
			quantity: number
			binId: string

			// This will always be null here because bin destinations on a part order
			// aren't specified to a specific order, only purchase orders are like that.
			partOrderId: string | null
		}[]
	}[]
}

export enum BolBillTo {
	Customer = "customer",
	Geodis = "geodis",
	Internal = "internal",
	Superior = "superior",
	WorldWideLogistics = "wwl",
}

export interface UpdateShipmentPatch {
	sendShipment: boolean | null // If you're actually shipping the shipment.
	shipment: string | number
	bolBillTo: BolBillTo | null // Null if you're using portal fedex
	carrier: string | number | null
	newCarrier: string | null // max len 100
	trackingNumber: string | null // max len 100
	freight: number | null
	freightInvoiced: boolean | null // max decimal 2
	shipmentModified: boolean
	orderShipMethodId?: string
	packageMode: boolean
	itemsToShip: {
		id: string | null // LRTS id, null if split from another row.
		partOrderLineId: string
		quantity: number
		packageInfo: {
			packageId: string | null // Null if making a new one.
			weight: number
			newPackageName?: string // Needs to be there if making new package.
			packageItemId: string | null // Package size info.
		} | null
	}[]
	numberOfPallets: number | null
	proNumber: string | null
	scheduledShipDate: string
	geodis220ReceivedOn: string | null
}

export interface UpdateShipmentQuantitiesPatch {
	updatedLines: UpdateShipmentPatch["itemsToShip"]
}

export type UpdatePartOrderPatch = Partial<{
	shipComplete: boolean | null
	shipMethodId: string | null
	purchaseOrderNumber: string | null // max len 30
	comment: string | null // max len 320
	isMachineDown: boolean | null
	targetDeliveryDate: string | null
	newWarehouseId: string | null
	oldWarehouseId: string | null
	submit: boolean | null
	cancel: boolean | null
	putOnHold: boolean
	endHold: boolean
	holders: string[] | null
	internalComment: string | null
	contactPhone: string
	shipToId: string | null
	billToId: string | null
}>

export interface AddPartOrderLineItemPost {
	description: string // max len 255
	lineTypeId: string | number | null
	partId: string | null
	partOrderId: string
	quantity: number
	reasonId: string | null
	unitPrice: string // Pre-quantity, right?
	unitTax: string // Pre-quantity, right?
	unitTaxRate: string
}

export type UpdatePartOrderLineItemPatch = Partial<{
	partId: string | null
	unitPrice: string | null // 2 decimal place
	unitTax: string | null // 2 decimal place
	unitTaxRate: string | null // 7 decimal place
	reasonId: string | null
	description: string | null // max len 255
	reroute: boolean
	oldWarehouseId: string | null
	newWarehouseId: string | null
	dropShip: boolean
	quantityMoving: number | null // For routes and drop ships.
	quantity: number | null // For updating the line item's quantity.
	recalc: boolean | null
}>

export interface PartOrderMin {
	id: string
	orderId: number
	shipToCustomer: CustomerMinimal
}

export interface PartOrderLineMin {
	id: string
	partId: number | null
	partOrder: PartOrderMin
	quantity: string // The total quantity of this part on the part order, regardless of what was purchase order, what was pick list.

	// Not a thing anymore...?
	quantityOnOrder: number // The quantity of this part on this purchase order.
}

export interface PartOrderShipment {
	bolBillTo: string | null
	createdOn: string
	fedexIsAuthorized: boolean
	freight: number | null
	freightBillable: boolean
	inTransit: {
		id: string
		carrier: {
			id: string
			description: string
		} | null
		deliveryDate: string | null
		estimatedDeliveryDate: string | null
		promisedDate: string | null
		shipDate: string | null
		trackingLink: string | null
		waybill: string | null
	} | null
	lines: {
		id: string // LineReadyToShip ID
		description: string
		package: {
			id: string
			name: string
			weight: number
			packageItem: ShipmentPackageSize | null
		} | null
		partNumber: string
		partOrderLineId: string
		partOrderOrderId: string
		quantity: number
	}[]
	locationCode: string
	locationDescription: string
	locationId: string
	ltlCarrierId: string | null
	numberOfPallets: number | null
	packageMode: boolean
	pickedToBin: string | null
	proNumber: string | null
	shipmentId: number
	shipmentMethod: {
		id: string
		description: string
	} | null
	shipped: boolean
	geodis220ReceivedOn: string | null
	geodis940SentOn: string | null
	trackingNumber: string | null
	// Not there yet. Brad will add soon.
	scheduledShipDate?: string | null
}

export interface PartOrderShipmentQueryParams {
	partOrder: string | null
	allShipments: boolean | null // Backend defaults to false if not included
	location: string | null
}

export interface ShipmentPackageSize {
	id: string
	name: string
	width: number
	height: number
	length: number
}

export interface CreatePackageSizePost {
	name: string
	width: number
	height: number
	length: number
}

// This is for updating the In Transits of a part order shipment.
export interface PartOrderTrackingPost {
	shipment: string | null
	shippingInfo: string | null
	carrier: string | number | null
	newCarrier?: string | null
	proNumber: string | null
}

export interface PurchaseOrderDeliveryComment {
	comment: string
	deliveryDate: string
	createdDate: string
}

export interface PurchaseOrderLineItemMin {
	id: number
	deliveryPurchaseLine: DeliveryOrderLineMin[]
	demand:
		| null
		| {
				id: string
				partOrder: {
					lineId: string
					orderId: string
					quantity: number
				}
		  }[]
	part: PartOrderPart | null
	partPurchaseLine: PartOrderLineMin[]
	purchaseOrderId: number
	quantity: string
	quantityInTransit: string
	quantityReceivable: string
	// quantityReceived: string // BAD! Don't use even though it's there.
	receivedQuantity: number // GOOD! Do use.
	taxRateUsed: string | null
	unitPrice: string | null
	unitTax: string
}

export interface PartsOrderCreationSource {
	id: number
	description: string | null
}

export enum PurchaseOrderStatusId {
	Open = 1,
	Ordered = 2,
	Closed = 3,
	Canceled = 4,
	ReadyToReceive = 5,
	InProgress = 6,
}

export interface PurchaseOrder {
	id: number
	alternateAddress: CustomerAlternateAddress<number> | null
	billToCustomer: CustomerMinimal | null
	closedDate: string | null
	comments: string | null
	createdBy: UserMinimal | null
	createdDate: string | null
	deliveryComments: PurchaseOrderDeliveryComment[]
	kbmOrderNumber: string | null
	lineItems: PurchaseOrderLineItemMin[]
	modifiedBy: UserMinimal | null
	modifiedDate: string | null
	purchaseInTransit: InTransitPartOrderItem[]
	purchaseOrderNumber: string | null
	shipMethod: ReferenceModel | null
	shipMethodId: number | null
	shipToCustomer: CustomerWithAddress | null
	source: PartsOrderCreationSource
	status: PurchaseOrderStatus
	subTotal: string
	taxTotal: string
	total: string
	vendor: PartOrderVendor | null
	vendorOrderNumber: string | null
}

export interface PurchaseOrdersQueryParams extends SearchQueryParams, OrderingQueryParams {
	organization: string | null
	region: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
	purchaseOrderStatus: string | null
	sourceId: string | null
	vendor: number | null
	warehouse: string | null
}

export interface PurchaseOrderPost {
	orderDate: string
	comments: string | null
	shipToCustomerId: string
	billToCustomerId: string
	purchaseOrderNumber: string | null
	shipMethodId: string
	vendorId: string | null
	lineItems: PurchaseOrderPostLineItem[]
	subTotal: string
	taxTotal: string
	total: string
	createAndOrder: boolean
}

export interface PurchaseOrderPostLineItem {
	description: string
	lineTotal: string
	lineTypeId: string
	netPrice: string
	partId: string | null
	quantity: number
	taxRateUsed: string
	unitPrice: string
	unitTax: string
}

export type PurchaseOrderPatch = Partial<{
	comments: PurchaseOrderPost["comments"]
	purchaseOrderNumber: PurchaseOrderPost["purchaseOrderNumber"]
	shipMethodId: PurchaseOrderPost["shipMethodId"]
	shipToCustomerId: PurchaseOrderPost["shipToCustomerId"]
	vendorId: PurchaseOrderPost["vendorId"]
}>

export interface FinalizeNonGrimesPurchaseOrderPost {
	confirmationNumber: string | null
	comments: string | null
	lineItemDates: {
		lineItemId: string
		shipDate: string
	}[]
}

export interface PurchaseOrderLineItemPatch {
	partId: string
	quantity: number
	unitPrice: string
	unitTax: string
	taxRateUsed: string
	lineTotal: string
}

export interface PurchaseOrderLineItemPost extends PurchaseOrderLineItemPatch {
	purchaseOrderId: string
}

export interface BulkReceivePurchaseOrderPost {
	locationId: string
	purchaseOrderId: string
	lines: {
		lineId: string
		binQuantities: {
			quantity: number
			binId: string
			partOrderId: string | null
		}[]
	}[]
}

export interface OrderDeliveryCommentPost {
	orderId: string
	deliveryDate: string
	comment: string | null
}

export interface PartOrderPickList {
	id: string
	assignedTo: {
		id: string // User ID
		firstName: string
		lastName: string
	}
	comment: string | null
	createdDate: string
	items: {
		id: string // Pick list line item ID
		binCode: string
		binId: string
		description: string
		hazmat: boolean
		partId: string
		partNumber: string
		priority: number | null
		quantity: number
		weight: number
	}[]
	location: {
		id: string
		locationCode: string
		description: string
	}
	shipDate: string | null
	status: {
		id: string
		description: PickListStatus
	}
}

export enum Accessorial {
	LiftGate = "liftGate",
	PrivateResidence = "privateResidence",
	JobSiteDelivery = "jobSiteDelivery",
	TankerEndorsement = "tankerEndorsement",
	PalletJack = "palletJack",
	InsideDelivery = "insideDelivery",
	GuaranteedDelivery = "guaranteedDelivery",
	HighCostRegion = "highCostRegion",
	AppointmentNotification = "appointmentNotification",
	LimitedAccess = "limitedAccess",
	HandlingFee = "handlingFee",
}

export interface PartOrderAttachmentPost {
	file: File
}

export type PartOrderList = {
	id: string
	customerName: string
	customerNumber: string
	hazmat: boolean
	kbmOrderNumber: string | null
	lineItemCount: number | null
	locations: {
		fullyInStock: boolean
		locationId: string
		locationName: string
		locationNumber: string
		noneInStock: boolean
		partialInStock: boolean
		pickedToBin: string | null
		picker: {
			id: string
			firstName: string
			lastName: string
		} | null
		picking: boolean
		shipping: boolean
	}[]
	orderDate: string
	orderId: number
	orderStatusId: number | null
	shipMethodId: string
	shipMethodDescription: string
	status: string | null
	subTotal: number | null
}[]

export interface PartOrderListQueryParams extends SearchQueryParams, OrderingQueryParams {
	organization: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
	partOrderStatus: string | null
	warehouse: string | null
	warehouses: (string | number)[] | null
	futureOrders: boolean | null
	picker: string | null
	lineType: string | null
	orderLineStatus: string | null
	shipMethod: string | null
	subTotal: string | null
	dashboardActionableItems: boolean | null
	isInternal: boolean | null
}

export type PartOrder = {
	id: string
	alternateAddress: CustomerAlternateAddress<number> | null
	alternateAddressId: number
	billToCustomer: {
		id: string
		address1: string
		address2: string
		address3: string
		city: string
		customerNumber: string
		name: string
		phone: string | null
		postalcode: string | null // [sic]
		state: string
		territory: TerritoryMinimal | null
	}
	comment: string | null
	contactPhone: string | null
	createdBy: UserMinimal | null
	createdDate: string | null
	fullTruck: boolean
	holders:
		| {
				userId: string
				firstName: string
				lastName: string
				email: string
		  }[]
		| null
	invoice: {
		id: string
		invoiceNumber: string
		statusCode?: string
		statusDescription?: string
		statusId?: number
		total: string
	}[]
	isMachineDown: boolean
	isReceivable: boolean
	kbmOrderNumber: string | null
	lineItems: PartOrderLineItem[]
	onlinePurchase: boolean
	orderDate: string
	orderId: number
	outsidePurchase: boolean
	poRequired: boolean
	purchaseOrderNumber: string | null
	shipComplete: boolean
	shipMethod: {
		id: string
		code: string
		description: string
	}
	shipToCustomer: {
		id: string
		address1: string
		address2: string
		address3: string
		city: string
		customerNumber: string
		name: string
		phone: string | null
		postalcode: string | null // [sic]
		state: string
		territory: TerritoryMinimal | null
	}
	status: {
		id: PartOrderStatusId
		code: string
		description: string
	}
	subTotal: number
	targetDeliveryDate: string | null
	taxTotal: number
	total: number
	internalComment: string | null
}

export type PartOrderLineItem = {
	id: string
	description: string
	inTransits: {
		ltlCarrier: string | null
		quantityInTransit: number
		shipDate: string | null // Naive date!
		trackingLink: string | null
		trackingNumber: string | number | null
	}[]
	lineTypeId: LineItemType
	locationLines: {
		locationId: string
		locationName: string
		locationNumber: string
		quantityAvailable: number
		quantityPicking: number
		quantityReadyToShip: number
		quantityRequested: number
		stockItem: boolean
	}[]
	part: {
		id: string
		partNumber: string
		partFamily: string | null
		description: string
		hazmat: boolean
		weight: number
		uom?: string | null
	} | null
	quantity: string
	quantityDropShipped: {
		partOrderLineId: number
		purchaseOrder: number
		quantity: number
	}[]
	quantityReceived: number
	quantityShipped: number
	unitPrice: string
	unitTax: string
	overridePermission: {
		id: string
		description: APPLICATION
	} | null
	reasonId: number | null
	/**
	 * @deprecated
	 */
	approvals: {
		id: string
		approvedOn: string | null
		approverComments: string | null
		approverName: string | null
		deniedOn: string | null
		partOrderLineId: string
		pendingApproval: boolean
		rate: number
		reasonComment: string | null
		reasonDescription: string | null
		requestedBy: string
		requestedOn: string
	} | null
	approvalsList:
		| {
				id: string
				approvedOn: string | null
				approverComments: string | null
				approverName: string | null
				deniedOn: string | null
				partOrderLineId: string
				pendingApproval: boolean
				rate: number
				reasonComment: string | null
				reasonDescription: string | null
				requestedBy: string
				requestedOn: string
		  }[]
		| null
}

export interface UpdatePartOverInvoicePatch {
	invoiceId: string
	approve: boolean | null
	addInvoiceLineItem: boolean | null // True if adding a line item.
	lineItem: CreatePartOrderPost["lineItems"][number] | null
}

export interface UpdatePartOrderApproveAdditionalEntryPatch {
	invoiceId: string
}

export interface CreatePickListPost {
	assignedTo: string | number
	locationId: string | number
	lines: {
		partOrderLineId: string | number
		quantity: number
	}[]
	comment: string | null
	shipDate: string | null
	geodis: boolean
	numberOfPallets: number | null
}

export interface LineItemStockInfo {
	shortageAvailable: boolean
	fullAvailable: boolean
	locations: {
		locationId: string
		locationName: string
		locationNumber: string
		shortageAvailable: boolean
		fullAvailable: boolean
	}[]
	quantityOnOrder: number | null
}

export interface LineItemStockInfoQueryParams {
	location: string | number | null
	quantity: number // The shortage quantity.
}

export interface CancelDropShipPost {
	location: string
}

export interface Promotion {
	id: string
	description: string
}

export interface ValidatePromotionsPost {
	customerId: string
	promotionIds: string[]
	parts: {
		partId: string
		netPrice: number
		quantity: number
	}[]
}

export interface AppliedPromotions {
	id: string
	description: string
	lineItems: {
		partId: string
		quantity: number
		discount: number // The discount divided into each line. 0 if line wasn't discounted.
	}[]
	discount: number // Total discount of the promotion.

	// Just a list of parts that were passed into the promo validator.
	partsUsed: string[]
}

export interface CallFedExPost {
	shipmentId: string | number
	partOrderId: string | number
}

export type SchedulerDashboardData = {
	warehouse: string
	warehouseName: string
	warehouseCode: string
	unscheduledOrders: {
		chemical: {
			orders: number
			lines: number
		}
		detail: {
			orders: number
			lines: number
		}
		parts: {
			orders: number
			lines: number
		}
	}
	currentPicking: {
		orders: number
		lines: number
	}
	historicalTrends: {
		averageOrders: number
		averageLines: number
	}
}[]

export interface CheckOrderItemsPost {
	customerId: string
	locationId: string | null
	items:
		| {
				partId: string
				quantity: number
				unitPrice: number // But really the net price, what line item for customer actually is.
				description: string | undefined
				partNumber: string | undefined
		  }[]
		| null
}

export interface GeneratedLineItem {
	price: number
	lineItemType: LineItemType
	overridePermission: {
		id: string
		description: APPLICATION
	} | null
}

export interface CheckOrderItemsResult {
	stockItems: {
		rdc: string | null
		outOfStockItems: {
			partId: string
			partNumber: string
			description: string
			quantity: number
		}[]
	}
	freightTotals:
		| (GeneratedLineItem & {
				freightLineVisible: boolean
				exceedsMinOrders: boolean | null // If true, customer cannot get free freight..
				chemicalMin: number | null
				chemicalParts:
					| {
							id: string
							description: string
							lineTotal: number
							partNumber: string
					  }[]
					| null
		  })
		| null
	fuelSurcharge: GeneratedLineItem | null
	accessorials:
		| {
				freeFreightExcluded: boolean
				lineItemDescription: string
				lineItemTypeId: LineItemType
				rate: number
		  }[]
		| null
}

export interface DefaultAccessorial {
	lineItemTypeId: LineItemType
	description: string
}

export interface CreateLinePriceOverrideRequestPost {
	partOrderLineId: string
	unitPrice: number // The price being request
	reasonId: string
	reasonComment: string | null
}

export interface PartCrossReference {
	id: string
	part: InventoryPart
	/** The alternate description */
	description: string | null
	/** The NCS part ID. Dangerously yuge number. */
	partId: number
	/** The alternate part number */
	partNumber: string | null
}

export type PartCrossReferenceQueryParams = {
	search: string | null
	part: string | null
}

export interface CreatePartCrossReferencePost {
	partId: string // NCS part ID
	description: string // Alternate description
	partNumber: string // Alternate part number
}

export interface UpdatePartCrossReferencePatch {
	partId: string // NCS part ID
	description: string // Alternate description
	partNumber: string // Alternate part number
}
