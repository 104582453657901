import React, { useState } from "react"

import { APPLICATION, useUserCanUse } from "@ncs/ncs-api"
import { Button, Icon, Tabs, useUrlState } from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

import {
	NewServiceRequestModal,
	ServiceRequestsMyRequestsTab,
	ServiceRequestsReportingTab,
} from "./components"

export enum ServiceRequestTab {
	MyRequests = "My Requests",
	Reporting = "Reporting",
}

export type ServiceRequestsUrlState = {
	tab: ServiceRequestTab
}

export const ServiceRequests: React.FC = () => {
	const [showNewRequestModal, setShowNewRequestModal] = useState(false)
	const [{ tab }, { updateUrlValue }] = useUrlState<ServiceRequestsUrlState>({
		tab: ServiceRequestTab.MyRequests,
	})

	const canCreateDispatches = useUserCanUse(APPLICATION.CreateDispatch)

	return (
		<PageContentWrapper
			heading="Service Requests"
			breadcrumbs={[{ name: "Service Requests" }]}
			renderRight={() => {
				return canCreateDispatches ?
						<Button
							icon="plus"
							variant="primary-cta"
							onClick={() => setShowNewRequestModal(true)}
						>
							New Service Request
						</Button>
					:	undefined
			}}
		>
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab)}
				panels={[
					{
						navLabel: ServiceRequestTab.MyRequests,
						navIcon: <Icon icon="user-hard-hat" />,
						component: <ServiceRequestsMyRequestsTab />,
					},
					{
						navLabel: ServiceRequestTab.Reporting,
						navIcon: <Icon icon="chart-bar" />,
						component: <ServiceRequestsReportingTab />,
					},
				]}
			/>

			<NewServiceRequestModal
				isOpen={showNewRequestModal}
				onClose={() => setShowNewRequestModal(false)}
			/>
		</PageContentWrapper>
	)
}
