import { FC, Fragment, memo, ReactNode } from "react"

import { arrayWrap } from "@ncs/ts-utils"

import { cssMixins } from "../../util"
import { EmptyValueDash } from "./EmptyValueDash"
import { Paragraph, ParagraphProps } from "./Paragraph"

type ParagraphLine = ReactNode | string | number | null | undefined

export interface ParagraphListProps extends ParagraphProps {
	lines: ParagraphLine[]
	/** If a line value is falsy, should we skip it? */
	skipFalsy?: boolean
	/** If a line value is falsy, and we're not skipping them, show this instead. */
	fallback?: ReactNode
	/** Show this many lines while loading, otherwise we'll show the length of lines. */
	loadingRowCount?: number
}

export const ParagraphList: FC<ParagraphListProps> = memo(
	({
		lines,
		fallback = <EmptyValueDash />,
		skipFalsy = true,
		isLoading,
		loadingRowCount = lines.length > 1 ? lines.length : 3,
		...rest
	}) => {
		const paragraphProps: ParagraphProps = {
			mb: 0.15,
			...rest,
		}

		return (
			<div>
				{isLoading ?
					new Array(loadingRowCount)
						.fill(<Paragraph isLoading {...paragraphProps} />)
						.map((row, i) => <Fragment key={i}>{row}</Fragment>)
				:	lines.map((line, i) => {
						if (skipFalsy && !line) {
							return null
						}

						return (
							<Paragraph
								css={cssMixins.breakAnywhereStyle}
								{...paragraphProps}
								key={`${i}${line}`}
							>
								{line ?
									arrayWrap(line).map((lineBit, j) => {
										return <Fragment key={`${i}-${j}`}>{lineBit}</Fragment>
									})
								:	fallback}
							</Paragraph>
						)
					})
				}
			</div>
		)
	}
)

ParagraphList.displayName = "ParagraphList"
