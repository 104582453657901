import { FC } from "react"

import { FourDigitMachineCode } from "@ncs/ncs-api"
import { DescriptionList, ExtendableModalProps, Modal } from "@ncs/web-legos"

export interface MachineEventDetailModalProps extends ExtendableModalProps {
	event: FourDigitMachineCode | null
}

export const MachineEventDetailModal: FC<MachineEventDetailModalProps> = ({ event, ...rest }) => {
	return (
		<Modal {...rest} title="Event Code Details" maxWidth="md">
			{event && (
				<DescriptionList
					variant="code"
					items={[
						["Code", event.fourDigitCode?.machineCode],
						["Date - Time", `${event.eventDate} - ${event.eventTime}`],
						["Wash Count (Last 4)", event.countString],
						["Description", event.fourDigitCode?.description],
						["Definition", event.fourDigitCode?.definition],
						["Potential Cause", event.fourDigitCode?.potentialCause],
						["Seq. Code", event.sequence?.machineCode],
						["Seq. Description", event.sequence?.description],
						["Seq. Definition", event.sequence?.definition],
						["Seq. Potential Cause", event.sequence?.potentialCause],
						["2-Dig. Code", event.twoDigitCode?.machineCode],
						["2-Dig. State Description", event.twoDigitCode?.description],
						["2-Dig. State Definition", event.twoDigitCode?.definition],
						["2-Dig. State Potential Cause", event.twoDigitCode?.potentialCause],
					]}
				/>
			)}
		</Modal>
	)
}
