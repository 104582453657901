import React, { ReactNode, useMemo } from "react"

import { css } from "@emotion/react"
import { darken } from "polished"

import { cssMixins } from "../../util"
import { Box } from "../layout"
import { Tooltip } from "../tooltips"
import { Heading, Icon, IconName } from "../typography"

interface SortableParamState {
	ordering: string | null
}

export interface ColumnHeaderProps<QueryParamState> {
	columnId: string
	sortingServerSide: boolean
	canSort?: boolean
	tooltip?: ReactNode
	tooltipIcon?: IconName | null
	sorted?: "asc" | "desc"
	/** Allows component to request server-side sorting. */
	setQueryParamState?: React.Dispatch<React.SetStateAction<QueryParamState>>
	/** Background color for the header. */
	backgroundColor?: string
	/**
	 * Don't allow CSS text wrapping for this heading.
	 */
	noWrap?: boolean
}

export const ColumnHeader = <QueryParamState,>({
	columnId,
	canSort,
	sortingServerSide,
	tooltip,
	tooltipIcon,
	sorted,
	setQueryParamState,
	backgroundColor = "#fff",
	noWrap,
	children,
}: React.PropsWithChildren<ColumnHeaderProps<QueryParamState>>): React.ReactElement => {
	const onClick = () => {
		if (canSort && sortingServerSide && !!setQueryParamState) {
			// React Table has the reins on sorting state, so we'll look at what it
			// is telling us currently and based on that we can tell what it'll turn
			// into when user clicks. That's what we'll tell the server to do.
			setQueryParamState((prev) => {
				const ascOrDesc =
					sorted === "desc" ? ""
					: sorted === "asc" ? "-"
					: ""
				const ordering = sorted === "desc" ? null : `${ascOrDesc}${columnId}`
				// Type this out explicitly so it gets checked properly.
				const newSortState: SortableParamState = {
					ordering,
				}
				return {
					...prev,
					...newSortState,
				}
			})
		}
	}

	const cursorStyle = useMemo(() => {
		return css`
			cursor: ${canSort ? "pointer" : "auto"};
		`
	}, [canSort])

	return (
		<Box
			display="flex"
			alignItems="flex-end"
			onClick={onClick}
			css={[columnHeaderStyle, cursorStyle, noWrap ? cssMixins.noWrap : undefined]}
		>
			{tooltip ?
				<Tooltip
					title={tooltip}
					icon={tooltipIcon ?? undefined}
					noIcon={tooltipIcon === null}
				>
					<Heading variant="h6" bold flexWrap={noWrap ? "nowrap" : undefined}>
						{children}
					</Heading>
				</Tooltip>
			:	<Heading variant="h6" bold flexWrap={noWrap ? "nowrap" : undefined}>
					{children}
				</Heading>
			}
			{canSort && (
				<div css={sortArrowContainer}>
					<Icon
						icon="caret-up"
						family="solid"
						css={css`
							color: ${sorted === "asc" ?
								darken(0.75, backgroundColor)
							:	darken(0.15, backgroundColor)};
						`}
					/>
					<Icon
						icon="caret-down"
						family="solid"
						css={css`
							color: ${sorted === "desc" ?
								darken(0.75, backgroundColor)
							:	darken(0.15, backgroundColor)};
						`}
					/>
				</div>
			)}
		</Box>
	)
}

const columnHeaderStyle = css`
	height: 100%;
	h6 {
		margin: 0;
	}
	&:focus {
		outline: none;
	}
`
const sortArrowContainer = css`
	position: relative;
	top: 5px;
	display: flex;
	flex-direction: column;
	margin-left: 0.5rem;
	.fa-caret-down {
		position: relative;
		top: -5px;
	}
	.fa-caret-up {
		position: relative;
		top: 3px;
	}
`
