import { FC, useEffect, useState } from "react"

import { useThrottle } from "@react-hook/throttle"

import { usePrevious } from "../../util"
import { Textarea, TextareaProps } from "./Textarea"

export interface ThrottledTextareaProps extends Omit<TextareaProps, "value" | "onChange"> {
	value: string | null
	onChange: (newValue: string | null) => void
	fps?: number
}

/**
 * Like regular `Textarea`, except we use a local state proxy and the `onChange` prop is throttled.
 */
export const ThrottledTextarea: FC<ThrottledTextareaProps> = ({
	value: valueToThrottle,
	onChange: onChangeToThrottle,
	fps = 3,
	...rest
}) => {
	const [localValue, setLocalValue] = useState<string | null>(valueToThrottle)
	const [throttledValue, setThrottledValue] = useThrottle(localValue, fps)

	// Whenever local value changes, fire the throttled value updater.
	useEffect(() => {
		setThrottledValue(localValue)
	}, [localValue, setThrottledValue])

	// Whenever throttled value changes, fire the passed in onChange.
	useEffect(() => {
		onChangeToThrottle(throttledValue)
	}, [onChangeToThrottle, throttledValue])

	// The parent can't normally drive the value, but if they set it to null
	// we should respect that.
	const prevValueToThrottle = usePrevious(valueToThrottle)
	useEffect(() => {
		if (prevValueToThrottle !== null && valueToThrottle === null && localValue !== null) {
			setLocalValue(null)
		}
	}, [localValue, valueToThrottle, prevValueToThrottle])

	return <Textarea {...rest} value={localValue} onChange={setLocalValue} />
}
