import React, { useCallback, useMemo } from "react"

import { Box } from "@material-ui/core"
import { captureException } from "@sentry/minimal"
import { CellProps, Column } from "react-table"

import {
	createDocumentToken,
	CustomerDispatch,
	CustomerWorkOrder,
	getDocumentUrlForToken,
	useCustomerDispatchWorkOrders,
} from "@ncs/ncs-api"
import { formatDateTime } from "@ncs/ts-utils"
import { Button, EmptyValueDash, LoadingSpinner, Table } from "@ncs/web-legos"

export interface ServiceRequestDetailWorkOrdersTabProps {
	dispatch?: CustomerDispatch
}

export const ServiceRequestDetailWorkOrdersTab: React.FC<
	ServiceRequestDetailWorkOrdersTabProps
> = ({ dispatch }) => {
	const [workOrders, loading] = useCustomerDispatchWorkOrders(dispatch?.id ?? null)

	const handleViewDocument = useCallback(async (workOrder: CustomerWorkOrder) => {
		if (workOrder.documents) {
			try {
				await createDocumentToken(workOrder.documents.id, workOrder.documents.type).then(
					({ token }) => {
						const url = getDocumentUrlForToken(token)
						window.open(url, "_blank")
					}
				)
			} catch (e) {
				console.error(e)
				captureException(e)
			}
		}
	}, [])

	const columns: Column<CustomerWorkOrder>[] = useMemo(
		() => [
			{
				Header: "Work Order #",
				accessor: "workorderNumber",
				disableSortBy: true,
			},
			{
				Header: "Open Date (CTD)",
				id: "openDate",
				accessor: (workOrder) =>
					workOrder.openDate ? formatDateTime(workOrder.openDate) : <EmptyValueDash />,
			},
			{
				Header: "Closed Date (CTD)",
				id: "closedDate",
				accessor: (workOrder) =>
					workOrder.closedDate ?
						formatDateTime(workOrder.closedDate)
					:	<EmptyValueDash />,
			},
			{
				Header: "",
				id: "view",
				Cell: (cell: CellProps<CustomerWorkOrder>) => (
					<Box textAlign="right">
						<Button
							icon="download"
							variant="table"
							onClick={() => handleViewDocument(cell.row.original)}
						>
							Download
						</Button>
					</Box>
				),
			},
		],
		[handleViewDocument]
	)

	if (!dispatch) {
		return <LoadingSpinner />
	}

	return (
		<Table
			columns={columns}
			data={workOrders ?? []}
			isLoading={loading}
			onRowClick={(row) => handleViewDocument(row.original)}
			noDataText="No work orders associated with this service request"
		/>
	)
}
