import { FC } from "react"

import { css, Theme } from "@emotion/react"

export interface ShopBarNavSubmenuProps {
	items: {
		label: string | number
		onClickValue: string | number
	}[]
	onClick: (itemValue: string) => void
	columnCount?: number
}

export const ShopBarNavSubmenu: FC<ShopBarNavSubmenuProps> = ({ items, onClick, columnCount }) => {
	return (
		<div css={menuContainerStyle}>
			<ul
				css={[
					navItemListStyle,
					css`
						column-count: ${columnCount ?? 3};
					`,
				]}
			>
				{items.map((item) => (
					<li key={item.onClickValue}>
						<button onClick={() => onClick(item.onClickValue.toString())}>
							{item.label}
						</button>
					</li>
				))}
			</ul>
		</div>
	)
}

const menuContainerStyle = css`
	max-height: 40rem;
	overflow-y: auto;
`
const navItemListStyle = (theme: Theme) => css`
	margin-top: 1rem;
	column-gap: 2rem;
	break-inside: avoid-column;
	padding: 0 2rem 2rem 0;
	li {
		button {
			width: 15rem;
			font-size: 1rem;
			background: none;
			padding: none;
			margin-bottom: 0.5rem;
			border: 0;
			padding: 0;
			color: ${theme.palette.primary.main};
			text-align: left;
			&:hover {
				color: ${theme.palette.primary.light};
			}
		}
	}
`
