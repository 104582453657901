import { FC, useEffect, useState } from "react"

import { useAuth } from "@ncs/ncs-api"
import {
	AnimatedEntrance,
	Box,
	ErrorText,
	ExtendableModalProps,
	Modal,
	Paragraph,
	TextInput,
	useIsSaving,
	useToast,
} from "@ncs/web-legos"

export type DbUserPinModalProps = ExtendableModalProps

export const DbUserPinModal: FC<DbUserPinModalProps> = ({ ...rest }) => {
	const { setIsPrivilegedDbUser } = useAuth()
	const { makeSuccessToast } = useToast()

	const [pin, setPin] = useState<string | null>(null)
	const [failed, setFailed] = useState<boolean>(false)
	const { isSaving, setSaving, endSaving } = useIsSaving()

	const handleAuthorize = () => {
		setSaving()

		setTimeout(() => {
			if (pin === theSuperSecretPin) {
				setIsPrivilegedDbUser(true)
				makeSuccessToast("Expanded parts access granted")
				rest.onClose()
			} else {
				setFailed(true)
				endSaving()
			}
		}, 500)
	}

	useEffect(() => {
		setFailed(false)
	}, [pin])

	return (
		<Modal
			{...rest}
			title="Welcome Driven Brands User"
			rightButtons={{
				buttonText: "Authorize",
				onClick: handleAuthorize,
				isLoading: isSaving(),
			}}
		>
			<Paragraph mt={1} mb={3}>
				If you've been given a PIN for access to an expanded list of parts to purchase, you
				may enter it here.
			</Paragraph>

			<Box d="flex" flexDirection="column" justifyContent="center" alignItems="center">
				<TextInput
					value={pin}
					onChange={setPin}
					type="password"
					autoFocus
					fillContainer={false}
				/>
				<AnimatedEntrance mt={0.25} show={failed}>
					<ErrorText>Incorrect PIN</ErrorText>
				</AnimatedEntrance>
			</Box>
		</Modal>
	)
}

/**
 * Shhhhhhh!
 */
const theSuperSecretPin = "carwash1"
