import React, { FC, useMemo } from "react"

import { useHistory } from "react-router-dom"

import { APPLICATION, useAuth } from "@ncs/ncs-api"
import { Box, Button, Icon, Tabs, TabsProps, useUrlState } from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

import { AccountTab, AlertsTab, BillingTab, InvoicesTab, OrdersTab } from "./components"

export enum AccountTabName {
	Orders = "Orders",
	Invoices = "Invoices",
	Billing = "Billing",
	Alerts = "Alerts",
	Account = "Account",
}

export type AccountUrlState = {
	tab: AccountTabName
	from?: "checkout" | null // We might be directed here from checkout
}

export const Account: FC = () => {
	const { user } = useAuth()
	const history = useHistory()
	const [{ tab, from }, { updateUrlValue }] = useUrlState<AccountUrlState>({
		tab: AccountTabName.Orders,
		from: null,
	})

	const onLogout = () => {
		history.push("/auth/logout")
	}

	const tabPanels: TabsProps["panels"] = useMemo(() => {
		if (!user) {
			return []
		}

		const panels = [
			{
				navLabel: AccountTabName.Orders,
				component: <OrdersTab />,
				navIcon: <Icon icon="box" />,
			},
		]

		if (!user.apps.includes(APPLICATION.HidePaymentInfo)) {
			panels.push(
				{
					navLabel: AccountTabName.Invoices,
					component: <InvoicesTab />,
					navIcon: <Icon icon="file-invoice-dollar" />,
				},
				{
					navLabel: AccountTabName.Billing,
					component: <BillingTab />,
					navIcon: <Icon icon="credit-card" />,
				}
			)
		}

		panels.push(
			{
				navLabel: AccountTabName.Alerts,
				component: <AlertsTab />,
				navIcon: <Icon icon="bell-on" />,
			},
			{
				navLabel: AccountTabName.Account,
				component: <AccountTab />,
				navIcon: <Icon icon="user" />,
			}
		)

		return panels
	}, [user])

	return (
		<PageContentWrapper
			heading="Account"
			breadcrumbs={[{ name: "Account" }]}
			renderRight={() => (
				<Button icon="sign-out" onClick={onLogout}>
					Sign out
				</Button>
			)}
		>
			<Tabs
				currentTab={tab}
				onChange={(newTab) => updateUrlValue("tab", newTab as AccountTabName)}
				panels={tabPanels}
			/>

			{from === "checkout" && (
				<Box mt={2} display="flex" justifyContent="flex-end">
					<Button
						onClick={() => history.push("/shop/checkout")}
						variant="primary-cta"
						icon="long-arrow-right"
					>
						Return to checkout?
					</Button>
				</Box>
			)}
		</PageContentWrapper>
	)
}
