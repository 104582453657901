import React from "react"

import { formatNumber } from "@ncs/ts-utils"

import { BoxProps } from "../layout"
import { Paragraph } from "../typography"

export interface ResultCountSummaryProps extends BoxProps {
	currentTotal: number
	totalResults: number | null
	/** Adds the word "approximately" in front of the result count.  */
	resultsAreApproximate?: boolean
	/** What word to use for "result"?  */
	resultWord?: string
}

export const ResultCountSummary: React.FC<ResultCountSummaryProps> = ({
	currentTotal,
	totalResults,
	resultsAreApproximate,
	resultWord = "result",
	...rest
}) => {
	return (
		<Paragraph mb={0.5} color="secondary" small {...rest}>
			Showing {formatNumber(currentTotal)}{" "}
			{!!totalResults && (
				<>
					of {!!resultsAreApproximate && " approximately "} {formatNumber(totalResults)}{" "}
					{resultWord}
					{currentTotal !== 1 ? "s" : ""}
				</>
			)}
		</Paragraph>
	)
}
