import React, { memo } from "react"

import { css, Theme } from "@emotion/react"
import { Helmet } from "react-helmet-async"

import {
	Box,
	Breadcrumbs,
	BreadcrumbsProps,
	Heading,
	Icon,
	Link,
	Paragraph,
	useScrollToTopOnMount,
} from "@ncs/web-legos"

export interface PageContentWrapperProps {
	/** Shown in big letters at top of page. Also used as page title.  */
	heading?: string | null
	/** Shown in smaller letters beneath the heading. */
	headingDetail?: string | null
	headingLoading?: boolean
	/** Customize the title of the page, used in `<head>` tag. Page title falls back to `heading` if `title` is not provided, and "NCS Customer Portal" after that. */
	title?: string | null
	/** Arbitrary content to show opposite the heading at the top of the page. */
	renderRight?: () => React.ReactNode
	/** Array of objects with `name` and `to` properties. The last one in the array
	 * is styled differently to indicate it's the current page. */
	breadcrumbs?: BreadcrumbsProps["pages"]
	/** If true, hide the regular content and show a "Not Found" message instead. */
	showNotFound?: boolean
	backButtonLink?: string
	backButtonText?: string
}

export const PageContentWrapper: React.FC<PageContentWrapperProps> = memo(
	({
		heading,
		headingDetail,
		title,
		renderRight,
		breadcrumbs,
		showNotFound,
		backButtonLink,
		backButtonText = "Back",
		children,
	}) => {
		useScrollToTopOnMount()

		return (
			<>
				<Helmet
					titleTemplate="%s | NCS Customer Portal"
					defaultTitle="NCS Customer Portal"
				>
					<title>{title ?? heading ?? undefined}</title>
				</Helmet>

				<Box mb={3}>
					<Breadcrumbs pages={breadcrumbs} />
				</Box>

				{!!backButtonLink && (
					<Box mt={-0.75} mb={2.5}>
						<Link
							to={backButtonLink}
							icon="long-arrow-left"
							iconPosition="leading"
							css={css`
								font-size: 0.9rem;
							`}
						>
							{backButtonText}
						</Link>
					</Box>
				)}

				{(!!heading || !!headingDetail || !!renderRight) && (
					<Box display="flex" justifyContent="space-between" mb={4} flexWrap="wrap">
						<Box mr="auto" mb={1}>
							{heading && <Heading variant="h1">{heading}</Heading>}
							{headingDetail && (
								<Paragraph color="secondary">{headingDetail}</Paragraph>
							)}
						</Box>

						{!!renderRight && <Box mb={1}>{renderRight()}</Box>}
					</Box>
				)}

				{!showNotFound && children}

				{showNotFound && (
					<>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="center"
							py={5}
							mb={1}
						>
							<Icon icon="car-crash" fontSize="3rem" />
							<Heading ml={1}>Oops! We're having trouble loading this page.</Heading>
						</Box>

						<Box textAlign="center">
							<Paragraph>
								You might want to try{" "}
								<button
									css={refreshButtonCss}
									onClick={() => window.location.reload()}
								>
									refreshing this page
								</button>
								, or go <Link to="/">back to the home page</Link>.
							</Paragraph>
						</Box>
					</>
				)}
			</>
		)
	}
)

const refreshButtonCss = ({ palette }: Theme) => css`
	border: 0;
	padding: 0;
	margin: 0;
	background: none;
	font-weight: bold;
	text-decoration: underline;
	color: ${palette.primary.main};
`
