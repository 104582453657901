import { FC, useEffect, useState } from "react"

import { useHistory } from "react-router-dom"

import { useAuth } from "@ncs/ncs-api"
import { ErrorText, LoadingSpinner, useUrlState } from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

export type PunchoutUrlState = {
	sessionId: string | null
}

export const PunchOut: FC = () => {
	const history = useHistory()
	const [{ sessionId }] = useUrlState<PunchoutUrlState>({ sessionId: null })
	const { punchOutLogin } = useAuth()
	const [isLoading, setIsLoading] = useState(true)
	const [errorText, setErrorText] = useState<string | null>(null)

	useEffect(() => {
		const login = async () => {
			if (sessionId) {
				try {
					await punchOutLogin(sessionId)
					history.push("/")
				} catch (e) {
					setErrorText("Sorry, there was an error authenticating.")
				} finally {
					setIsLoading(false)
				}
			}
		}

		void login()
	}, [sessionId, punchOutLogin, history])

	return (
		<PageContentWrapper title="Authenticating">
			{isLoading && (
				<LoadingSpinner
					my={5}
					text="Authenticating. You will be redirected in a moment..."
				/>
			)}

			{!!errorText && (
				<ErrorText my={5} textAlign="center">
					{errorText}
				</ErrorText>
			)}
		</PageContentWrapper>
	)
}
