/**
 * Test if something is an honest-to-goodness object.
 */
export const isLiteralObject = (a: unknown): a is Object => {
	return !!a && (a as Object).constructor === Object
}

/**
 * Test if a string is a key of an object and type it as such.
 */
export const isKeyOf = <Obj extends Object>(
	k: string | number | symbol,
	obj: Obj
): k is keyof Obj => {
	if (k == null || k === "") return false

	return k in obj
}
