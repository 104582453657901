import React, { memo } from "react"

import { WorkOrderSiteStatus, useSiteStatuses } from "@ncs/ncs-api"

import { Select, SelectProps } from "../inputs"

export type SiteStatusSelectorProps = Omit<SelectProps<WorkOrderSiteStatus>, "options">

export const SiteStatusSelector: React.FC<SiteStatusSelectorProps> = memo(({ ...rest }) => {
	const [sites, sitesAreLoading] = useSiteStatuses()

	return (
		<Select
			label="Site Status"
			disabled={sitesAreLoading}
			{...rest}
			valueAccessor="id"
			textAccessor="description"
			options={sites || []}
		/>
	)
})
