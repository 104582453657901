import { FC } from "react"

import { DeviceStatusCategory } from "@ncs/ncs-api"

import { ExtendableSelectProps, Select } from "../inputs"

export interface DeviceStatusSelectorProps
	extends ExtendableSelectProps<(typeof options)[number]> {}

const options = Object.entries(DeviceStatusCategory).map(([text, value]) => ({
	text,
	value,
}))

export const DeviceStatusSelector: FC<DeviceStatusSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	return (
		<Select
			label="Device status"
			noSelectionOptionText="Any"
			disableNoSelectionOption={false}
			{...rest}
			options={options}
			value={value}
			onChange={onChange}
		/>
	)
}
