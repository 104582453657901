import React, { useMemo } from "react"

import dayjs, { Dayjs } from "dayjs"
import updateLocale from "dayjs/plugin/updateLocale"
import utc from "dayjs/plugin/utc"

import { DateFormat } from "@ncs/ts-utils"

import { DateTimeInput } from "../inputs"

dayjs.extend(utc)
dayjs.extend(updateLocale)

dayjs.updateLocale("en", {
	weekStart: 1, // 1 = Monday
})
interface EndDateTimeUtcParamState {
	endDate: string | null
}

export interface EndDateTimeUtcQueryFilterProps<QueryParamState extends EndDateTimeUtcParamState> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
}

export const EndDateTimeUtcQueryFilter = <QueryParamState extends EndDateTimeUtcParamState>({
	queryParamState,
	setQueryParamState,
}: EndDateTimeUtcQueryFilterProps<QueryParamState>): React.ReactElement => {
	const value = useMemo(
		() => (queryParamState.endDate ? dayjs.utc(queryParamState.endDate) : null),
		[queryParamState.endDate]
	)

	const handleChange = (newDate: Dayjs | null) => {
		setQueryParamState((prev) => ({
			...prev,
			endDate:
				newDate ?
					dayjs(newDate)
						.utc()
						.startOf("minute")
						.format(DateFormat.DateTimeQueryParamNoTz) // Django is finicky about including TZ, so just convert to UTC first.
				:	null,
		}))
	}

	return <DateTimeInput value={value} label="To date" onChange={handleChange} fillContainer />
}
