import React from "react"

import { StripeBankAccount } from "@ncs/ncs-api"

import { Box, BoxProps } from "../layout"
import { Icon, LabeledData } from "../typography"

export interface BankAccountProps extends BoxProps {
	account: StripeBankAccount
}

export const BankAccount: React.FC<BankAccountProps> = ({ account, ...rest }) => {
	return (
		<Box display="flex" {...rest}>
			<Box display="flex" flexGrow={0} mr={1} mt={0.75}>
				<Icon icon="university" />
			</Box>
			<Box mr={1} width={12}>
				<LabeledData label="Bank name">{account.bankName}</LabeledData>
			</Box>

			<Box mr={1} width={10}>
				<LabeledData label="Account holder">{account.accountHolderName}</LabeledData>
			</Box>
			<Box mr={1}>
				<LabeledData label="Account number">...{account.accountNumberLast4}</LabeledData>
			</Box>
		</Box>
	)
}
