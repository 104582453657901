import React from "react"

import { CustomerMachineEventLevel } from "@ncs/ncs-api"

import { Select, SelectProps } from "../inputs"

export interface CustomerMachineEventLevelSelectorProps
	extends Omit<SelectProps<(typeof options)[number]>, "options"> {}

const options = [
	{
		text: "Error",
		value: CustomerMachineEventLevel.Error,
	},
	{
		text: "Warning",
		value: CustomerMachineEventLevel.Warning,
	},
]

export const CustomerMachineEventLevelSelector: React.FC<
	CustomerMachineEventLevelSelectorProps
> = ({ ...rest }) => {
	return <Select label="Event type" {...rest} options={options} />
}
