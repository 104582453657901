import { FC } from "react"

import { css } from "@emotion/react"
import { useHistory } from "react-router-dom"

import { Button, Card, EventTracker, Heading, Paragraph, TrackingEvent } from "@ncs/web-legos"

import { learningHub } from "~/images/other"

export const QuickOrderCtaWide: FC = () => {
	const history = useHistory()

	return (
		<Card
			variant="droplet"
			css={css`
				// A couple things since we're putting that image as a background.
				position: relative;
				overflow: hidden;
				border: 0;
			`}
		>
			<div css={contentContainerStyle}>
				<Heading icon="bolt" bold>
					Site Quick Order
				</Heading>

				<Paragraph my={2}>
					Choose from a tailored list of products that work with the equipment you have
					at your sites.
				</Paragraph>

				<EventTracker event={TrackingEvent.QUICK_ORDER}>
					<Button
						variant="primary-cta"
						onClick={() => history.push("/shop/quick-order")}
						buttonText="Start Quick Order"
					/>
				</EventTracker>
			</div>

			<div css={imageBgStyle} />
		</Card>
	)
}

const contentContainerStyle = css`
	position: relative;
	z-index: 1;
	p {
		text-shadow: 0 0 5px white;
	}
`
const imageBgStyle = css`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)),
		url(${learningHub});
	background-position: center center;
	background-size: cover;
	z-index: 0;
`
