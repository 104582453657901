import { FC, memo, ReactNode, useEffect, useLayoutEffect, useRef } from "react"

import { Card } from "@square/web-payments-sdk-types"

import { Box, BoxProps } from "../../components/layout"
import { Information, Paragraph } from "../../components/typography"
import { useSquareCardContext } from "./SquareCardContext"

export interface SquareCardFormProps extends BoxProps {
	helperText?: string | ReactNode
	showSecuredByMessage?: boolean
}

export const SquareCardForm: FC<SquareCardFormProps> = memo((props) => {
	const { makeCard, card } = useSquareCardContext()

	useEffect(() => {
		makeCard()
	}, [makeCard])

	if (!card) {
		return null
	}

	/**
	 * Russian-doll this so that we have a component that takes a non-nullish Card.
	 */
	return <SquareCardFormInternal card={card} {...props} />
})

const SquareCardFormInternal: FC<SquareCardFormProps & { card: Card }> = ({
	helperText,
	showSecuredByMessage = true,
	card,
	...rest
}) => {
	const { clearCard } = useSquareCardContext()
	const cardRef = useRef<HTMLDivElement | null>(null)
	const hasAttached = useRef(false)

	useLayoutEffect(() => {
		if (!hasAttached.current && cardRef.current) {
			hasAttached.current = true
			void card.attach(cardRef.current)
		}
	}, [card])

	useEffect(() => {
		return () => {
			void clearCard()
		}
	}, [clearCard])

	return (
		<Box {...rest}>
			<div ref={cardRef} />
			{!!helperText &&
				(typeof helperText === "string" ?
					<Paragraph small color="secondary" my={1}>
						{helperText}
					</Paragraph>
				:	helperText)}
			{showSecuredByMessage && (
				<Information icon="lock" iconFamily="solid">
					Secured with Square
				</Information>
			)}
		</Box>
	)
}
