import React from "react"

import { Select, SelectProps } from "../inputs"

export enum ChartType {
	Bar = "bar",
	StackedBar = "stackedBar",
	Line = "line",
	Area = "area",
}

export interface ChartTypeSelectorProps
	extends Omit<SelectProps<(typeof options)[number]>, "options" | "value" | "onChange"> {
	value: ChartType
	onChange: (newType: ChartType) => void
}

const options: {
	value: ChartType
	text: string
}[] = [
	{
		value: ChartType.Bar,
		text: "Bar chart",
	},
	{
		value: ChartType.StackedBar,
		text: "Stacked bar chart",
	},
	{
		value: ChartType.Line,
		text: "Line chart",
	},
	{
		value: ChartType.Area,
		text: "Area chart",
	},
]

export const ChartTypeSelector: React.FC<ChartTypeSelectorProps> = ({
	value = ChartType.Bar,
	onChange,
	...rest
}) => {
	return (
		<Select
			showNoSelectionOption={false}
			label="Chart type"
			{...rest}
			options={options}
			value={value}
			onChange={(newValue) => onChange(newValue as ChartType)}
		/>
	)
}
