import { ReactElement, useCallback, useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { DateFormat } from "@ncs/ts-utils"

import { usePrevious } from "../../util"
import { KeyboardDateInput } from "../inputs"

interface KeyboardStartDateParamState {
	startDate?: string | null
	startOnAfter?: string | null
	startOnBefore?: string | null
}

export interface KeyboardStartDateQueryFilterProps<
	QueryParamState extends KeyboardStartDateParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
	param?: keyof KeyboardStartDateParamState
	label?: string
}

export const KeyboardStartDateQueryFilter = <QueryParamState extends KeyboardStartDateParamState>({
	queryParamState,
	setQueryParamState,
	param = "startDate",
	label = "Start date",
}: KeyboardStartDateQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedDate, setSelectedDate] = useState(() => {
		const initialDate = dayjs(queryParamState[param])

		return initialDate.isValid() ? initialDate : null
	})

	const handleChange = useCallback(
		(newValue: Dayjs | null) => {
			setSelectedDate(newValue)

			const newDate = dayjs(newValue)

			if (newDate.isValid()) {
				setQueryParamState((prev) => ({
					...prev,
					[param]: newValue?.format(DateFormat.DateQueryParam) ?? null,
				}))
			}
		},
		[setQueryParamState, param]
	)

	const prevQueryParamValue = usePrevious(queryParamState[param])
	useEffect(() => {
		if (
			queryParamState[param] === null &&
			prevQueryParamValue !== null &&
			selectedDate !== null
		) {
			handleChange(null)
		}
	}, [handleChange, prevQueryParamValue, queryParamState, param, selectedDate])

	return (
		<KeyboardDateInput
			label={label}
			value={selectedDate}
			onChange={handleChange}
			muiProps={{ disableFuture: true }}
		/>
	)
}
