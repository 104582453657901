import React, { useCallback, useMemo } from "react"

import { captureException } from "@sentry/minimal"
import { CellProps, Column } from "react-table"

import { createDocumentToken, getDocumentUrlForToken, VintageDocument } from "@ncs/ncs-api"
import { Box, Button, Table } from "@ncs/web-legos"

export interface EquipmentDocumentationTabProps {
	documents: VintageDocument[]
}

export const EquipmentDocumentationTab: React.FC<EquipmentDocumentationTabProps> = ({
	documents,
}) => {
	const handleViewDocument = useCallback(async (id: string) => {
		try {
			await createDocumentToken(id, 7).then(({ token }) => {
				const url = getDocumentUrlForToken(token)
				window.open(url, "_blank")
			})
		} catch (e) {
			console.error(e)
			captureException(e)
		}
	}, [])

	const columns: Column<VintageDocument>[] = useMemo(
		() => [
			{
				Header: "Document ID",
				accessor: "documentNumber",
				disableSortBy: true,
			},
			{
				Header: "Description",
				accessor: "description",
			},
			{
				Header: "",
				id: "view",
				Cell: (cell: CellProps<VintageDocument>) => (
					<Box textAlign="right">
						<Button
							icon="download"
							variant="table"
							onClick={() => handleViewDocument(cell.row.original.id)}
						>
							Download
						</Button>
					</Box>
				),
			},
		],
		[handleViewDocument]
	)

	return (
		<Table
			columns={columns}
			data={documents}
			noDataText="No documents found"
			onRowClick={(row) => handleViewDocument(row.original.id)}
		/>
	)
}
