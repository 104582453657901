import { FC, Fragment, HTMLAttributes, ReactNode, useMemo } from "react"

import { css, useTheme } from "@emotion/react"

import { withCssUnit } from "@ncs/ts-utils"

import { SkeletonRows } from "../transitions"
import { EmptyValueDash } from "./EmptyValueDash"

export interface DataListProps extends HTMLAttributes<HTMLDListElement> {
	/** Array of tuples containing the label, the value, and an optional loading boolean. */
	items: ([string, ListValue] | [string, ListValue, boolean])[]
	/** The width of the labels column. */
	labelWidth?: string | number
	/** The width of the skeleton row that renders when an item's loading boolean is true.  */
	skeletonWidth?: string | number
	/** Styling variant. */
	variant?: "regular" | "code" | "labeled-paragraph"
}

type ListValue = string | number | ReactNode | null | undefined

export const DescriptionList: FC<DataListProps> = ({
	items,
	labelWidth = "auto",
	skeletonWidth = 15,
	variant = "regular",
	...rest
}) => {
	const theme = useTheme()

	const computedListStyle = useMemo(() => {
		return css`
			grid-template-columns: ${withCssUnit(labelWidth)} 1fr;
		`
	}, [labelWidth])

	const variantStyle = useMemo(() => {
		switch (variant) {
			case "regular": {
				return css`
					dt {
						font-weight: bold;
					}
				`
			}
			case "code": {
				return css`
					dt {
						font-size: 0.75rem;
						font-weight: bold;
						text-transform: uppercase;
						color: ${theme.palette.text.secondary};
					}
					dd {
						font-family: "Courier New";
						font-size: 1rem;
					}
				`
			}
			case "labeled-paragraph": {
				return css`
					dt {
						color: ${theme.palette.text.secondary};
						font-size: 0.75rem;
						line-height: 1.428;
						font-weight: 400;
					}
					dd {
						font-size: 1rem;
						font-weight: normal;
					}
				`
			}
			default: {
				return undefined
			}
		}
	}, [variant, theme.palette.text.secondary])

	return (
		<dl css={[listStyle, computedListStyle, variantStyle]} {...rest}>
			{items.map(([label, value, loading = false]) => (
				<Fragment key={label}>
					<dt>{label}</dt>
					<dd>
						{loading ?
							<SkeletonRows
								rows={1}
								width={withCssUnit(skeletonWidth)}
								rowProps={{ my: 0 }}
							/>
						:	value ?? <EmptyValueDash />}
					</dd>
				</Fragment>
			))}
		</dl>
	)
}

const listStyle = css`
	display: grid;
	column-gap: 1rem;
	align-items: baseline;
	margin: 1rem 0;
	padding: 0;
	dt,
	dd {
		margin-bottom: 0.3rem;
	}
`
