import React from "react"
import { css, Theme } from "@emotion/react"

interface ContentWrapperProps {
	padding?: string
}

export const ContentWrapper: React.FC<ContentWrapperProps> = React.memo(
	({ children, padding }) => {
		return <div css={(theme) => style(theme, padding)}>{children}</div>
	}
)

const style = (theme: Theme, padding?: string) => css`
	max-width: 85rem;
	margin-left: auto;
	margin-right: auto;
	padding: ${padding || "0 1.5rem"};

	${theme.breakpoints.only("sm")} {
		padding: 0 0.75rem;
	}
	${theme.breakpoints.only("xs")} {
		padding: 0 0.5rem;
	}
`
