import { FC, memo, ReactNode, useMemo } from "react"

import { css, useTheme } from "@emotion/react"
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints"

import { Box, BoxProps } from "./Box"

export interface CssColumnsProps extends BoxProps {
	cells: ReactNode[]
	columnCounts: Partial<Record<Breakpoint, number>>
	columnGap?: number
}

export const CssColumns: FC<CssColumnsProps> = memo(
	({ cells, columnCounts: { xl, lg, md, sm, xs }, columnGap = 1, ...rest }) => {
		const theme = useTheme()

		const columnCss = useMemo(() => {
			return css`
				break-inside: avoid;
				${theme.breakpoints.up("xs")} {
					column-count: ${xs ?? sm ?? md ?? lg ?? xl ?? 1};
				}
				${theme.breakpoints.up("sm")} {
					column-count: ${sm ?? md ?? lg ?? xl ?? xs ?? 1};
				}
				${theme.breakpoints.up("md")} {
					column-count: ${md ?? lg ?? xl ?? sm ?? xs ?? 1};
				}
				${theme.breakpoints.up("lg")} {
					column-count: ${lg ?? xl ?? md ?? sm ?? xs ?? 1};
				}
				${theme.breakpoints.up("xl")} {
					column-count: ${xl ?? lg ?? md ?? sm ?? xs ?? 1};
				}
				break-inside: avoid;
				> div {
					break-inside: avoid;
				}
			`
		}, [xs, sm, md, lg, xl, theme.breakpoints])

		return (
			<Box css={columnCss} columnGap={columnGap} {...rest}>
				{cells.map((c, i) => (
					<div key={i}>{c}</div>
				))}
			</Box>
		)
	}
)
