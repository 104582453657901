import React, { memo } from "react"

import { css, Theme } from "@emotion/react"
import { transparentize } from "polished"

import { Box, BoxProps } from "../layout"
import { Icon, IconFamily, IconName } from "./Icon"
import { Paragraph } from "./Paragraph"

export interface InformationProps extends BoxProps {
	icon?: IconName
	iconFamily?: IconFamily
	fillContainer?: boolean
	color?: "info" | "warn" | "error"
}

export const Information: React.FC<InformationProps> = memo(
	({
		icon = "info-circle",
		iconFamily = "regular",
		fillContainer,
		color = "info",
		children,
		...rest
	}) => {
		const propsContainerStyle = (theme: Theme) => css`
			display: ${fillContainer ? "flex" : "inline-flex"};
			background-color: ${color === "warn" ? transparentize(0.85, theme.palette.warning.main)
			: color === "error" ? transparentize(0.25, theme.palette.error.main)
			: transparentize(0.97, theme.palette.primary.main)};
		`
		const textColorStyle = (theme: Theme) => css`
			color: ${color === "warn" ? theme.palette.warning.contrastText
			: color === "error" ? theme.palette.error.contrastText
			: theme.palette.text.secondary};
		`
		const iconColor =
			color === "error" ? "white"
			: color === "warn" ? "text"
			: "gray"

		return (
			<Box css={[containerStyle, propsContainerStyle]} {...rest}>
				<Icon icon={icon} family={iconFamily} color={iconColor} />
				<Paragraph ml={0.65} small css={textColorStyle}>
					{children}
				</Paragraph>
			</Box>
		)
	}
)

const containerStyle = (theme: Theme) => css`
	align-items: flex-start;
	padding: 1rem 1.5rem;
	border-radius: 15px;
	svg {
		margin-top: 0.075rem;
	}
`
