import { FC } from "react"

import { ConnectedDevicesAtSite } from "@ncs/ncs-api"
import { Box } from "@ncs/web-legos"

import { MachineEventsChart, WashCountTotalsChart } from "./components"

export interface DataReportingTabProps {
	customer: ConnectedDevicesAtSite["customer"]
}

export const DataReportingTab: FC<DataReportingTabProps> = ({ customer }) => {
	return (
		<>
			<Box
				d="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				gap={1}
				mt={3}
			/>
			<WashCountTotalsChart customerId={customer.id} />
			<MachineEventsChart customerId={customer.id} />
		</>
	)
}
