export const envelope = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
		<path
			d="M13.7327 3.43457H1.73267C1.60006 3.43457 1.47288 3.48725 1.37911 3.58102C1.28534 3.67479 1.23267 3.80196 1.23267 3.93457V12.4346C1.23267 12.6998 1.33802 12.9541 1.52556 13.1417C1.7131 13.3292 1.96745 13.4346 2.23267 13.4346H13.2327C13.4979 13.4346 13.7522 13.3292 13.9398 13.1417C14.1273 12.9541 14.2327 12.6998 14.2327 12.4346V3.93457C14.2327 3.80196 14.18 3.67479 14.0862 3.58102C13.9925 3.48725 13.8653 3.43457 13.7327 3.43457ZM13.2327 12.4346H2.23267V5.07145L7.39454 9.80332C7.48679 9.888 7.60745 9.93498 7.73267 9.93498C7.85788 9.93498 7.97855 9.888 8.07079 9.80332L13.2327 5.07145V12.4346Z"
			fill="white"
		/>
	</svg>
)

export const phone = (): JSX.Element => (
	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
		<path
			d="M14.2222 11.5635C14.1108 12.4104 13.6949 13.1877 13.0522 13.7504C12.4095 14.313 11.5839 14.6225 10.7297 14.621C5.76724 14.621 1.72974 10.5835 1.72974 5.62097C1.72821 4.76677 2.03767 3.94123 2.60033 3.29853C3.16298 2.65582 3.94035 2.23991 4.78724 2.12847C5.0014 2.10232 5.21827 2.14613 5.40548 2.25337C5.59269 2.3606 5.7402 2.52551 5.82599 2.72347L7.14599 5.67034V5.67784C7.21167 5.82938 7.2388 5.99482 7.22495 6.15939C7.2111 6.32396 7.1567 6.48254 7.06662 6.62097C7.05537 6.63784 7.04349 6.65347 7.03099 6.66909L5.72974 8.21159C6.19787 9.16284 7.19287 10.1491 8.15662 10.6185L9.67787 9.32409C9.69281 9.31153 9.70846 9.29985 9.72474 9.28909C9.86305 9.19684 10.0222 9.14053 10.1877 9.12526C10.3533 9.10998 10.52 9.13621 10.6729 9.20159L10.681 9.20534L13.6254 10.5247C13.8237 10.6102 13.989 10.7576 14.0966 10.9448C14.2042 11.1321 14.2483 11.3491 14.2222 11.5635Z"
			fill="white"
		/>
	</svg>
)
