import React, { useMemo } from "react"

import { css, useTheme } from "@emotion/react"
import { Divider as MuiDivider } from "@material-ui/core"

import { CARD_X_PADDING_MD_UP, CARD_X_PADDING_SM_DOWN } from "../cards"
import { Box, BoxProps } from "./Box"

export interface DividerProps extends BoxProps {
	/** Gives the divider negative margins equal to a `web-legos` `Card`. */
	fullCardBleed?: boolean
}

export const Divider: React.FC<DividerProps> = ({ fullCardBleed = false, ...rest }) => {
	const { breakpoints } = useTheme()

	const marginX = useMemo(() => {
		if (!fullCardBleed) return undefined

		return () => css`
			${breakpoints.up("md")} {
				margin-left: -${CARD_X_PADDING_MD_UP}rem;
				margin-right: -${CARD_X_PADDING_MD_UP}rem;
			}
			${breakpoints.down("sm")} {
				margin-left: -${CARD_X_PADDING_SM_DOWN}rem;
				margin-right: -${CARD_X_PADDING_SM_DOWN}rem;
			}
		`
	}, [breakpoints, fullCardBleed])

	return (
		<Box css={[dividerCss, marginX]} my={1.5} {...rest}>
			<MuiDivider />
		</Box>
	)
}

const dividerCss = css`
	.MuiDivider-root {
		background-color: #eee;
	}
`
