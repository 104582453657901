import React, { FC } from "react"

import { AuthProvider } from "./auth"
import { ClientVersionContextProvider } from "./client-version"
import { ReactQueryClientProvider } from "./query-client"

export enum ClientVersionHeader {
	Internal = "x-client-version-internal",
	Customer = "x-client-version-customer",
}

export interface NcsApiProviderProps {
	clientVersionHeader: ClientVersionHeader
	isDev?: boolean
}

export const NcsApiProvider: FC<NcsApiProviderProps> = ({
	clientVersionHeader,
	isDev,
	children,
}) => {
	// Note that these are in a specific order!
	return (
		<ReactQueryClientProvider>
			<ClientVersionContextProvider clientVersionHeader={clientVersionHeader}>
				<AuthProvider isDev={isDev}>{children}</AuthProvider>
			</ClientVersionContextProvider>
		</ReactQueryClientProvider>
	)
}
