import React, { memo } from "react"

import { DeviceStatusCategory } from "@ncs/ncs-api"

import { Icon } from "../typography"

export interface StatusIconProps {
	category: DeviceStatusCategory
}

export const StatusIcon: React.FC<StatusIconProps> = memo(({ category }) => {
	if (category === DeviceStatusCategory.Normal) {
		return <Icon icon="check-circle" family="solid" color="success" fixedWidth />
	}
	if (category === DeviceStatusCategory.Warning) {
		return <Icon icon="info-circle" family="solid" color="warning" fixedWidth />
	}
	if (category === DeviceStatusCategory.Error) {
		return <Icon icon="exclamation-triangle" family="solid" color="error" fixedWidth />
	}

	return <Icon icon="wifi-slash" fixedWidth />
})
