import React from "react"

import { TreeView as MuiTreeView, TreeViewProps as MuiTreeViewProps } from "@material-ui/lab"

/**
 * Doesn't actually do anything different than the MUI Tree View, but this just let's
 * us import from the same place when using Tree Item, which does actually have some
 * differences from MUI Tree Item.
 */
export const TreeView: React.FC<MuiTreeViewProps> = ({ children, ...rest }) => {
	return <MuiTreeView {...rest}>{children}</MuiTreeView>
}
