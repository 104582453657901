import { ReactElement, useEffect, useMemo, useState } from "react"

import { useThrottleCallback } from "@react-hook/throttle"

import { usePrevious } from "../../util"
import { ThrottledTextInput } from "../inputs"

interface SearchParamState {
	search: string | null
}

export interface SearchQueryFilterProps<QueryParamState extends SearchParamState> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
	label?: string
	placeholder?: string
	autoFocus?: boolean
}

export const SearchQueryFilter = <QueryParamState extends SearchParamState>({
	queryParamState,
	setQueryParamState,
	label = "Search",
	placeholder,
	autoFocus,
}: SearchQueryFilterProps<QueryParamState>): ReactElement => {
	const searchStateValue = useMemo(() => queryParamState.search || null, [queryParamState])

	// To avoid any potential performance jank when typing, maintain proxy local state.
	const [localValue, setLocalValue] = useState(searchStateValue)

	const prevSearchStateValue = usePrevious(searchStateValue)
	useEffect(() => {
		if (prevSearchStateValue !== searchStateValue && localValue !== searchStateValue) {
			setLocalValue(searchStateValue)
		}
	}, [localValue, prevSearchStateValue, searchStateValue])

	const throttledSetQueryParamState = useThrottleCallback((newValue: string | null) => {
		setQueryParamState((prev) => ({ ...prev, search: newValue }))
	}, 2)

	const prevLocalValue = usePrevious(localValue)
	useEffect(() => {
		if (localValue !== prevLocalValue) throttledSetQueryParamState(localValue || null)
	}, [localValue, prevLocalValue, throttledSetQueryParamState])

	return (
		<ThrottledTextInput
			value={localValue}
			onChange={setLocalValue}
			icon="search"
			label={label}
			placeholder={placeholder}
			fillContainer
			clearable
			autoFocus={autoFocus}
		/>
	)
}
