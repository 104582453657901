import {
	ApiDeleteMutation,
	ApiGetQuery,
	ApiPostMutation,
	useDeleteRequest,
	useGetRequest,
	usePostRequest,
} from "../../request-hooks"
import {
	AddSquareCardPost,
	AddStripeBankAccountPost,
	CustomerPaymentMethods,
	PaymentsUrlPaths,
	SetDefaultPaymentMethodPost,
	VerifyStripeBankAccountPost,
} from "./types"

export const useCustomerPaymentMethods = (): ApiGetQuery<CustomerPaymentMethods> => {
	return useGetRequest([PaymentsUrlPaths.Payments, PaymentsUrlPaths.Methods])
}

export const useSetDefaultPaymentMethod = (): ApiPostMutation<SetDefaultPaymentMethodPost> => {
	return usePostRequest(
		[PaymentsUrlPaths.Payments, PaymentsUrlPaths.Methods, PaymentsUrlPaths.SetDefault],
		{
			keyToInvalidate: [PaymentsUrlPaths.Payments, PaymentsUrlPaths.Methods],
		}
	)
}

export const useAddBankAccount = (): ApiPostMutation<AddStripeBankAccountPost> => {
	return usePostRequest([PaymentsUrlPaths.Payments, PaymentsUrlPaths.BankAccounts], {
		keyToInvalidate: [PaymentsUrlPaths.Payments],
	})
}

export const useVerifyBankAccount = (
	accountId?: string | null
): ApiPostMutation<VerifyStripeBankAccountPost> => {
	return usePostRequest(
		[PaymentsUrlPaths.Payments, PaymentsUrlPaths.BankAccounts, accountId ?? "", "verify"],
		{
			keyToInvalidate: [PaymentsUrlPaths.Payments],
			pythonifyExcludeList: ["amount1", "amount2"],
		}
	)
}

export const useDeleteBankAccount = (): ApiDeleteMutation => {
	return useDeleteRequest([PaymentsUrlPaths.Payments, PaymentsUrlPaths.BankAccounts], {
		keyToInvalidate: [PaymentsUrlPaths.Payments],
	})
}

export const useAddCard = (): ApiPostMutation<AddSquareCardPost> => {
	return usePostRequest([PaymentsUrlPaths.Payments, PaymentsUrlPaths.Cards], {
		keyToInvalidate: [PaymentsUrlPaths.Payments],
		pythonifyExcludeList: ["last4", "line1", "line2"],
	})
}

export const useDeleteCard = (): ApiDeleteMutation => {
	return useDeleteRequest([PaymentsUrlPaths.Payments, PaymentsUrlPaths.Cards], {
		keyToInvalidate: [PaymentsUrlPaths.Payments],
	})
}
