import { FC } from "react"

import { Column } from "react-table"

import {
	DeliveryOrderLineItem,
	InvoicePartOrderLineItem,
	KbmInvoiceLineItem,
	WorkOrderLineItem,
} from "@ncs/ncs-api"
import { extractNumber, formatCurrency } from "@ncs/ts-utils"
import { Table } from "@ncs/web-legos"

export type CombinedLineItem = Partial<
	InvoicePartOrderLineItem & WorkOrderLineItem & KbmInvoiceLineItem & DeliveryOrderLineItem
>

export interface InvoiceDetailLineItemsTabProps {
	lineItems: CombinedLineItem[]
	isLoading: boolean
}

const columns: Column<CombinedLineItem>[] = [
	{
		Header: "Description",
		id: "description",
		accessor: (lineItem) => lineItem.description || `Line item ID ${lineItem.id}`,
	},
	{
		Header: "Quantity",
		accessor: (lineItem) => extractNumber(lineItem.quantity ?? ""),
	},
	{
		Header: "Total",
		accessor: (lineItem) => {
			const quantity = parseFloat(lineItem.quantity ?? "")
			const unitTax = parseFloat(lineItem.unitTax ?? lineItem.tax ?? "")
			const price = parseFloat(lineItem.unitPrice ?? lineItem.netPrice ?? "")

			return formatCurrency((price + unitTax) * quantity)
		},
	},
]

export const InvoiceDetailLineItemsTab: FC<InvoiceDetailLineItemsTabProps> = ({
	lineItems,
	isLoading,
}) => {
	return (
		<Table
			data={lineItems}
			columns={columns}
			isLoading={isLoading}
			noDataText="No line items found"
		/>
	)
}
