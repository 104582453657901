import React, {
	Dispatch,
	PropsWithChildren,
	ReactElement,
	ReactNode,
	SetStateAction,
	useCallback,
	useMemo,
	useState,
} from "react"

import { css, Theme, useTheme } from "@emotion/react"
import { Checkbox } from "@material-ui/core"
import { transparentize } from "polished"
import { useLocation } from "react-router-dom"
import {
	Column,
	Row,
	SortingRule,
	TableOptions,
	useExpanded,
	useRowSelect,
	useSortBy,
	useTable,
} from "react-table"

import { ApiInfiniteGetQuery, DataExport, PortalReport } from "@ncs/ncs-api"
import { isLiteralObject, noNullish } from "@ncs/ts-utils"

import { useUrlParam } from "../../contexts"
import { useChangeCallback, useLocalStorageState } from "../../util"
import { ButtonProps } from "../buttons"
import { Box, BoxProps } from "../layout"
import { FilterGridProps } from "../query-filters"
import { LoadingSpinner } from "../transitions"
import { Icon, IconFamily, IconName, Paragraph } from "../typography"
import { AboveTable } from "./AboveTable"
import { BelowTable } from "./BelowTable"
import { ColumnHeader } from "./ColumnHeader"
import { PageSizes, RowObject } from "./table-util"
import { TableRowCell } from "./TableRowCell"
import { TableRowMenu } from "./TableRowMenu"

export interface TableProps<RowType extends Object, QueryParamState extends {} = {}>
	extends Omit<TableOptions<RowType>, "data"> {
	/** Array of columns. You probably want to explicitly type your columns array to this.
	 * Potential gotcha: Make sure you grab `Column` from `react-table`, not `react-table-6`. */
	columns: Column<RowType>[]
	/** For use when you're NOT server-side paginating. */
	data?: TableOptions<RowType>["data"]
	/** If you're server-side paginating, don't use `data`. Instead pass in your infinite query hook here. */
	query?: ApiInfiniteGetQuery<RowType>
	/** Search params object to pass to the filters.
	 * (If this is present, clicking column headers to sort will modify the `ordering` param.)
	 * Must be used with setQueryParamState. */
	queryParamState?: FilterGridProps<QueryParamState>["queryParamState"]
	/** Function to change search params to pass to the filters.
	 * Must be used with queryParamState. */
	setQueryParamState?: FilterGridProps<QueryParamState>["setQueryParamState"]
	/** Arrays of filters. Must be used with query param state management props above.
	 * Pinned filters are always shown, toggled filters live behind a "More" button. */
	pinnedQueryFilters?: FilterGridProps<QueryParamState>["filters"]
	toggledQueryFilters?: FilterGridProps<QueryParamState>["filters"]
	stickyHeader?: boolean
	onRowClick?: (row: Row<RowType>, index: number) => void
	noDataText?: string
	loadingText?: string
	minHeight?: string
	containerProps?: BoxProps
	/**
	 * Fired when Clear Filters is clicked in the Table's FilterGrid. Defaults to nulling everything but ordering.
	 */
	onReset?: (currentFilters: QueryParamState) => void
	/**
	 * When you click Reset Filters, we'll set everything to `null` and then also splat in whatever
	 * you pass for this prop.
	 */
	filterResetValues?: FilterGridProps<QueryParamState>["resetValues"]
	/** Completely turn off sorting for all columns. */
	disableAllSorting?: boolean
	/** Column ID and direction to sort by default. Note that this only applies to local sorting of the data. */
	defaultSort?: SortingRule<RowType>[]
	/** Allow `css` prop through */
	className?: string
	/** Loading state. */
	isLoading?: boolean
	/** Updating state. Disables the whole table when true. */
	isUpdating?: boolean
	/** The color of the header background, */
	headerBackgroundColor?: string
	/** Context menu for each row. */
	rowMenu?: (
		| {
				label: string
				onClick: (row: Row<RowType>) => void
				iconName?: IconName
				iconFamily?: IconFamily
				disabledAccessor?: (row: Row<RowType>) => boolean
				hiddenAccessor?: (row: Row<RowType>) => boolean
		  }
		| undefined
	)[]
	/** Show buttons at the top of the table. This causes checkboxes to show in reach row.
	 * The buttons' onClick gets passed some props related to bulk selection. */
	bulkActionButtons?: (Omit<ButtonProps, "onClick"> & {
		onClick: (bulkSelectionProps: {
			toggleAllRowsSelected: (newState?: boolean) => void
			selectedRows: Row<RowType>[]
			isAllRowsSelected: boolean
		}) => void
	})[]
	/** The return of a `useDataExport` hook. Renders an Export Data button above the table. */
	dataExport?: DataExport
	/** Should toggled filters start in their open state instead of closed? */
	showToggledFiltersByDefault?: boolean
	/** Should the toggled filters fill in right-to-left or left-to-right? */
	toggledFiltersFillDirection?: "rtl" | "ltr"
	/** How many records should the Load More button load? Only used when using the `data` prop.
	 * If you're using the `query` prop, then this is controlled via `pageSize`. */
	infiniteRowsIncrement?: number
	/** Use pagination instead of infinite rows. Currently only implemented for data coming in
	 * via the `query` prop. */
	pagination?: {
		page: number
		pageSize: PageSizes
	}
	setPagination?: Dispatch<SetStateAction<{ page: number; pageSize: PageSizes }>>
	/**
	 * Set the `vertical-align` property for the table rows.
	 * @default "top"
	 */
	rowVerticalAlign?: "top" | "middle" | "bottom"
	/** Any additional buttons you want to render in the top left position above Table.  */
	leftButtons?: ButtonProps[]
	/** Any additional buttons you want to render in the top right position above Table.  */
	rightButtons?: ButtonProps[]
	/**
	 * Save checking/unchecking of column visibility in local storage? Pass true to store it
	 * under a default key, pass a string to store it under that.
	 * @default false
	 */
	storeColumnVisibility?: boolean | string
	/**
	 * Any arbitrary content that you need to render between the filters and the table itself.
	 */
	contentUnderFilters?: ReactNode
	/**
	 * Provide a `PortalReport` for a bar chart, rendered above the table.
	 */
	barChartReport?: PortalReport
	/**
	 * Show a button to expand all rows in the table.
	 */
	expandAllRowsButton?: boolean
	/**
	 * A disabled row displays at half opacity but behaves normally otherwise.
	 */
	rowDisabledAccessor?: (row: RowObject<RowType>) => boolean
}

const TableWithoutMemo = <RowType extends object, QueryParamState extends {}>({
	data: dataProp,
	columns,
	stickyHeader = true,
	onRowClick,
	noDataText = "No data found.",
	isLoading: isLoadingProp,
	loadingText = "Loading data...",
	isUpdating: isUpdatingProp,
	minHeight,
	containerProps,
	query,
	queryParamState,
	setQueryParamState,
	pinnedQueryFilters = [],
	toggledQueryFilters = [],
	onReset,
	filterResetValues,
	disableAllSorting,
	defaultSort,
	headerBackgroundColor,
	rowMenu: rowMenuRaw,
	bulkActionButtons = [],
	dataExport,
	showToggledFiltersByDefault,
	toggledFiltersFillDirection = "rtl",
	infiniteRowsIncrement = 50,
	rowVerticalAlign = "top",
	leftButtons,
	rightButtons,
	className,
	pagination,
	setPagination,
	storeColumnVisibility = false,
	contentUnderFilters,
	barChartReport,
	expandAllRowsButton,
	rowDisabledAccessor,
	...rest
}: PropsWithChildren<TableProps<RowType, QueryParamState>>): ReactElement => {
	if ((!!pagination && !setPagination) || (!pagination && !!setPagination)) {
		throw new Error("pagination and setPagination must be passed together or not at all")
	}
	if (!!pagination && !query) {
		throw new Error("Paginate functionality only implemented when using a server-side query")
	}

	const theme = useTheme()
	const orderingParam = useUrlParam("ordering")
	const { pathname } = useLocation()

	/* Hiding / showing columns */
	const [columnVisibility, setColumnVisibility] = useLocalStorageState<{
		[columnHeader: string]: boolean | undefined
	}>(Object.fromEntries(columns.map((c) => [String(c.Header || c.id), !c.hiddenByDefault])), {
		keyOverride:
			typeof storeColumnVisibility === "string" ? storeColumnVisibility : (
				`columns-for-table-starting-with-${columns[0].Header}-at-${pathname}`
			),
		enabled: !!storeColumnVisibility,
		validate: (foundStorageItem) => {
			// Do some validation to make sure that the column headers Table is getting match the headers
			// that were stored. If they don't, reject the storage item.
			if (!isLiteralObject(foundStorageItem)) return false
			const foundHeaders = Object.keys(foundStorageItem)
			return Object.values(columns).every((c) => foundHeaders.includes(String(c.Header)))
		},
	})
	const visibleColumns = useMemo(() => {
		return columns.filter((c) => columnVisibility[String(c.Header || c.id)])
	}, [columns, columnVisibility])

	/* Client-side infinite scrolling */
	const [visibleRowCount, setVisibleRowCount] = useState(infiniteRowsIncrement)

	/** Data from either the data prop or from the infinite query. */
	const data = useMemo(() => {
		if (query?.data) {
			return query.data
		} else if (dataProp) {
			return dataProp
		}

		throw new Error("Table must have data either via the `data` prop or the `query` prop.")
	}, [dataProp, query])

	const getDefaultSort = (): SortingRule<RowType>[] => {
		if (defaultSort) {
			return defaultSort
		} else {
			if (orderingParam) {
				const desc = orderingParam.startsWith("-")
				if (desc) {
					return [
						{
							id: orderingParam.slice(1),
							desc: true,
						},
					]
				} else {
					return [
						{
							id: orderingParam,
						},
					]
				}
			}
		}
		return []
	}

	// As a side effect query of param state changing (which should not include pagination params),
	// we should reset the page back to 1.
	useChangeCallback(queryParamState, () => {
		if (setPagination) {
			setPagination((prev) => ({ ...prev, page: 1 }))
		}
	})

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		getToggleAllRowsSelectedProps,
		toggleAllRowsSelected,
		selectedFlatRows,
		isAllRowsSelected,
		toggleAllRowsExpanded,
	} = useTable(
		{
			data,
			columns: visibleColumns,
			initialState: {
				sortBy: getDefaultSort(),
			},
			// If you passed in an infinite query, then we won't do any sorting locally.
			manualSortBy: !!query,
			disableSortBy: disableAllSorting,
			...rest,
		},
		useSortBy,
		useExpanded,
		useRowSelect
	)

	const handleRowClick = useCallback(
		(row: Row<RowType>) => {
			if (onRowClick) onRowClick(row, row.index)
			if (row.canExpand) row.toggleRowExpanded()
		},
		[onRowClick]
	)

	const handleKeyUp = useCallback(
		(e: React.KeyboardEvent<HTMLTableRowElement>, row: Row<RowType>) => {
			// If you passed a click handler, we need to also make it keyboard accessible.
			if (onRowClick && (e.code === "Enter" || e.code === "Space")) {
				onRowClick(row, row.index)
			}
		},
		[onRowClick]
	)

	const rowMenu = useMemo(() => {
		if (!rowMenuRaw) {
			return undefined
		}
		return rowMenuRaw.flatMap((menuItem) => (menuItem ? [menuItem] : []))
	}, [rowMenuRaw])

	const usingQueryData = useMemo(() => !!query && !dataProp, [query, dataProp])

	const isUsingBulkActions = bulkActionButtons.length > 0

	const isUsingRowExpansion = useMemo(() => rows.some((row) => row.canExpand), [rows])

	const isLoading = !!(isLoadingProp || query?.isLoading)

	const isUpdating =
		!!(isLoading || query?.isFetching || isUpdatingProp) ||
		(!!pagination && !!query?.rawQuery.isPreviousData)

	// The combination of using dataProp data and using expandable rows is a problem, because
	// the presence of "hidden" sub-rows accidentally get counted towards our limit. So, we'll
	// make a smarter lookup here to track visibility.
	const rowVisibilityLookup = useMemo(() => {
		if (usingQueryData) return {}
		const lookup: Record<string, boolean> = {}
		let count = 0

		for (let i = 0; i < rows.length; i += 1) {
			const row = rows[i]
			lookup[row.id] = row.depth > 0 || count <= visibleRowCount
			if (row.depth === 0) count += 1
			if (count > visibleRowCount + 1) break
		}

		return lookup
	}, [rows, visibleRowCount, usingQueryData])

	const tableHeightStyle = useMemo(
		() => css`
			min-height: ${minHeight ?? "auto"};
		`,
		[minHeight]
	)
	const bodyRowStyle = useMemo(
		() => css`
			vertical-align: ${rowVerticalAlign};
			opacity: ${isLoading ? 0.5 : 1};
		`,
		[isLoading, rowVerticalAlign]
	)

	const tableHeadingStyle = useMemo(
		() => css`
			position: ${stickyHeader ? "sticky" : "relative"};
			top: 0;
			background: ${headerBackgroundColor ? headerBackgroundColor
			: stickyHeader ? "rgba(255, 255, 255, .85)"
			: "#fff"};
			border-bottom: 1px solid ${theme.palette.grey[200]};
		`,
		[stickyHeader, theme.palette.grey, headerBackgroundColor]
	)

	const tableUpdatingStyle = useMemo(() => {
		return css`
			opacity: ${isUpdating ? 0.5 : 1};
			pointer-events: ${isUpdating ? "none" : undefined};
		`
	}, [isUpdating])

	return (
		<div {...containerProps} css={outerContainer}>
			<AboveTable
				columns={columns}
				queryParamState={queryParamState}
				setQueryParamState={setQueryParamState}
				pinnedQueryFilters={pinnedQueryFilters}
				toggledQueryFilters={toggledQueryFilters}
				bulkActionButtons={bulkActionButtons}
				onReset={onReset}
				filterResetValues={filterResetValues}
				selectedFlatRows={selectedFlatRows}
				toggleAllRowsSelected={toggleAllRowsSelected}
				isAllRowsSelected={isAllRowsSelected}
				columnVisibility={columnVisibility}
				setColumnVisibility={setColumnVisibility}
				dataExport={dataExport}
				toggledFiltersFillDirection={toggledFiltersFillDirection}
				showToggledFiltersByDefault={showToggledFiltersByDefault}
				leftButtons={leftButtons}
				rightButtons={rightButtons}
				setPagination={setPagination}
				contentUnderFilters={contentUnderFilters}
				barChartReport={barChartReport}
				expandAllRowsButton={!!expandAllRowsButton}
				isSomeRowsExpanded={rows.some((r) => r.isExpanded)}
				toggleAllRowsExpanded={toggleAllRowsExpanded}
			/>

			<div css={innerContainer}>
				<table
					className={className}
					css={[tableStyle, tableHeightStyle]}
					{...getTableProps()}
				>
					<thead>
						{headerGroups.map((headerGroup) => {
							const { key, ...restHeaderGroupProps } =
								headerGroup.getHeaderGroupProps()

							return (
								<tr key={key} {...restHeaderGroupProps}>
									{isUsingBulkActions && (
										<th css={tableHeadingStyle}>
											<Box top={0.7} left={-0.5}>
												<Checkbox
													size="small"
													color="primary"
													{...getToggleAllRowsSelectedProps({
														title: "Select / Deselect All Rows",
													})}
												/>
											</Box>
										</th>
									)}

									{/* Empty header cell for the twisties on each row. */}
									{isUsingRowExpansion && <th css={tableHeadingStyle} />}

									{headerGroup.headers.map((column) => {
										const { key: columnKey, ...restColumnHeaderProps } =
											column.getHeaderProps(column.getSortByToggleProps())

										return (
											<th
												key={columnKey}
												{...restColumnHeaderProps}
												css={tableHeadingStyle}
											>
												<ColumnHeader
													columnId={column.id}
													canSort={column.canSort}
													tooltip={column.headingTooltip}
													tooltipIcon={column.headingTooltipIcon}
													sortingServerSide={!!query}
													setQueryParamState={setQueryParamState}
													backgroundColor={headerBackgroundColor}
													noWrap={column.noWrap}
													sorted={
														column.isSortedDesc ? "desc"
														: column.isSorted ?
															"asc"
														:	undefined
													}
												>
													{column.render("Header")}
												</ColumnHeader>
											</th>
										)
									})}

									{/* An extra empty heading column for the row menu */}
									{!!rowMenu && <th css={tableHeadingStyle} />}
								</tr>
							)
						})}
					</thead>

					<tbody {...getTableBodyProps()} css={tableUpdatingStyle}>
						{/* Show loading spinner row only when there are no other rows. */}
						{/* Table's opacity will indicate updating after there are rows. */}
						{isLoading && rows.length === 0 && (
							<tr>
								<td
									colSpan={
										columns.length +
										(rowMenu ? 1 : 0) +
										(bulkActionButtons.length > 0 ? 1 : 0)
									}
								>
									<LoadingSpinner text={loadingText} />
								</td>
							</tr>
						)}

						{rows
							.filter((row) => {
								// If you're doing pagination through a query, then we can just say yes, go ahead and show.
								if (usingQueryData) {
									return true
								} else {
									// Otherwise, we need to check if we can show it or not because potential sub-rows
									// interfere with the visible row count.
									return rowVisibilityLookup[row.id]
								}
							})
							.map((row) => {
								prepareRow(row)
								const { key, ...restRowProps } = row.getRowProps()
								const isClickable = row.canExpand || !!onRowClick
								const rowIsDisabled =
									typeof rowDisabledAccessor === "function" ?
										rowDisabledAccessor(row.original)
									:	false

								return (
									<tr
										key={key}
										{...restRowProps}
										css={[
											bodyRowStyle,
											rowIsDisabled ? disabledRowStyle : undefined,
										]}
										className={noNullish([
											isClickable ? "clickable-row" : null,
											row.depth > 0 ? "expanded-sub-row"
											: row.isExpanded && row.canExpand ?
												"expanded-parent-row"
											:	null,
										]).join(" ")}
										onClick={() => handleRowClick(row)}
										onKeyUp={(e) => handleKeyUp(e, row)}
										tabIndex={isClickable ? 0 : undefined}
									>
										{isUsingBulkActions && (
											<td css={checkBoxCellStyle}>
												<Box position="absolute" top={-0.15} left={0.25}>
													<Checkbox
														size="small"
														color="primary"
														{...row.getToggleRowSelectedProps()}
													/>
												</Box>
											</td>
										)}

										{isUsingRowExpansion && (
											<td css={expandCaretCellStyle}>
												{row.canExpand ?
													<Icon
														icon="angle-right"
														css={css`
															color: ${row.isExpanded ?
																theme.palette.primary.main
															:	"#bbb"};
															transform: rotate(
																${row.isExpanded ? "90deg" : "0"}
															);
															transition:
																transform 200ms ease-out,
																color 200ms ease-out;
														`}
													/>
												:	""}
											</td>
										)}

										{row.cells.map((cell) => {
											const { key: rowKey, ...restCellProps } =
												cell.getCellProps()

											return (
												<TableRowCell
													key={rowKey}
													className={restCellProps.className}
													role={restCellProps.role}
													rowIsExpanded={row.isExpanded}
													rowCanExpand={row.canExpand}
													rowDepth={row.depth}
													cellRenderFn={cell.render}
												/>
											)
										})}

										{/* For now we're making the call that we just don't show the row menu on sub rows. */}
										{!!rowMenu &&
											(row.depth === 0 ?
												<TableRowMenu menu={rowMenu} row={row} />
											:	<td />)}
									</tr>
								)
							})}
					</tbody>
				</table>

				{data.length === 0 && !isLoading && (
					<Paragraph my={1} color="secondary" small>
						{noDataText}
					</Paragraph>
				)}
			</div>

			<Box pl={1} mt={1}>
				<BelowTable
					data={data}
					dataProp={dataProp}
					query={query}
					infiniteRowsIncrement={infiniteRowsIncrement}
					visibleRowCount={visibleRowCount}
					setVisibleRowCount={setVisibleRowCount}
					isUpdating={isUpdating}
					pagination={pagination}
					setPagination={setPagination}
					usingQueryData={usingQueryData}
					toggleAllRowsExpanded={toggleAllRowsExpanded}
				/>
			</Box>
		</div>
	)
}

export const Table = React.memo(TableWithoutMemo) as typeof TableWithoutMemo

const outerContainer = css`
	position: relative;
	z-index: 1;
`
const innerContainer = (theme: Theme) => css`
	padding: 0 0.25rem 1rem 0.25rem; // This little bit of padding makes room for the table's box shadow.
	margin: 0 -0.25rem; // The negative margin makes the table's edge bump out to make up for the padding.
	${theme.breakpoints.down("md")} {
		overflow-x: auto;
	}
	${theme.breakpoints.down("sm")} {
		white-space: nowrap; // Table should scroll horizontally instead of causing overflow.
	}
`
const tableStyle = (theme: Theme) => css`
	position: relative;
	border-collapse: collapse;
	width: 100%;
	tr {
		transition: background 75ms ease-in-out;
	}
	thead {
		th {
			height: 2.9rem; // See below for why we're defining this explicitly.
			text-align: left;
			padding: 0 0.5rem 0.5rem 0.75rem;
			vertical-align: bottom;
			z-index: 1;
		}
	}
	tbody {
		* {
			text-rendering: optimizeSpeed;
		}
		tr {
			&:nth-of-type(even) {
				background: white;
			}
			&:nth-of-type(odd) {
				background: ${theme.palette.grey[100]};
			}
			&.clickable-row {
				cursor: pointer;
				&:hover,
				&:focus {
					background: ${theme.palette.grey[200]};
					outline: none;
				}
			}
			&.expanded-parent-row {
				background: ${transparentize(0.87, theme.palette.primary.main)};
				&.clickable-row:hover,
				&.clickable-row:focus {
					background: ${transparentize(0.85, theme.palette.primary.main)};
				}
			}
			&.expanded-sub-row {
				background: ${transparentize(0.92, theme.palette.primary.main)};
				&.clickable-row:hover,
				&.clickable-row:focus {
					background: ${transparentize(0.9, theme.palette.primary.main)};
				}
			}
		}
		// In certain browsers (cough cough, safari, cough cough), you can't simply
		// apply box shadow to tbody, like a civilized developer.
		&::after {
			content: " ";
			position: absolute;
			top: 2.9rem;
			right: 0;
			bottom: 0;
			left: 0;
			border: 1px solid ${theme.palette.grey[100]};
			box-shadow: ${theme.tableBoxShadow};
			z-index: -1;
		}
	}
	td {
		padding: 0.5rem 0.75rem;
		white-space: normal; // But cells can wrap.
	}
`

const checkBoxCellStyle = css`
	position: relative;
	width: 3rem;
`
const expandCaretCellStyle = css`
	text-align: center;
`
const disabledRowStyle = css`
	opacity: 0.5;
`
