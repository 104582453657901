import React, { useMemo } from "react"
import { useTheme } from "@emotion/react"
import { Box, BoxProps } from "../layout"
import { SkeletonRows } from "../transitions"
import { Icon, IconFamily, IconName, IconProps } from "./Icon"

export interface HeadingProps extends BoxProps {
	variant?: "h1-banner" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
	bold?: boolean
	uppercase?: boolean
	color?: "primary" | "secondary" | "success" | "error"
	icon?: IconName
	iconFamily?: IconFamily
	iconColor?: IconProps["color"]
	isLoading?: boolean
	customColor?: string
}

export const Heading: React.FC<HeadingProps> = React.memo(
	({
		variant = "h3",
		bold,
		uppercase,
		color,
		icon,
		iconFamily,
		iconColor,
		isLoading,
		opacity,
		children,
		customColor,
		...rest
	}) => {
		const { palette } = useTheme()

		const headingElement = useMemo(() => {
			return variant === "h1-banner" ? "h1" : variant
		}, [variant])

		const headingStyle = useMemo(() => {
			return {
				// Use JSS style notation because this is going directly in createElement.
				display: "block",
				fontSize: variant === "h1-banner" ? "2.313rem" : undefined,
				fontWeight:
					bold === false ? "normal"
					: bold ? "bold"
					: undefined,
				textTransform:
					uppercase ? "uppercase"
					: uppercase === false ? "capitalize"
					: undefined,
				margin: 0,
				color:
					customColor ? customColor
					: color === "primary" ? palette.primary.main
					: color === "secondary" ? palette.secondary.main
					: color === "success" ? palette.success.main
					: color === "error" ? palette.error.main
					: undefined,

				opacity,
			}
		}, [bold, variant, uppercase, palette, color, opacity])

		const composedChild = useMemo(() => {
			return (
				icon ?
					<>
						<Box display="inline-block" mt={-0.25} mr={0.75} opacity={opacity}>
							<Icon icon={icon} family={iconFamily} color={iconColor} fixedWidth />
						</Box>
						{isLoading ?
							<SkeletonRows
								containerProps={{
									display: "inline-block",
									width: "75%",
									position: "relative",
									top: -0.45,
								}}
								rowProps={{ my: 0, height: 1 }}
								rows={1}
							/>
						:	children}
					</>
				: isLoading ?
					<SkeletonRows
						rowProps={{ my: 0 }}
						rows={1}
						containerProps={{
							display: "inline-block",
							width: "75%",
							position: "relative",
						}}
					/>
				:	children
			)
		}, [children, icon, iconFamily, iconColor, isLoading, opacity])

		return (
			<Box {...rest}>
				{React.createElement(headingElement, { style: headingStyle }, composedChild)}
			</Box>
		)
	}
)
