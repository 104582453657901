import { AxiosRequestConfig } from "axios"
import cloneDeep from "lodash/cloneDeep"
import { useMutation, useQueryClient } from "react-query"

import { arrayWrap, CamelToSnakeCaseNested, pythonify } from "@ncs/ts-utils"

import { apiClient } from "../util"
import { ApiPostMutation, ApiPostMutationOptions } from "./types"

export const usePostRequest = <RequestData = void, ResponseData = unknown>(
	endpoint: string | string[],
	options?: ApiPostMutationOptions<RequestData, ResponseData>
): ApiPostMutation<RequestData, ResponseData> => {
	const queryClient = useQueryClient()

	// Sometimes the backend wants an ID to use in the URL itself, not in the POST body.
	const tryToAddId = (requestData: RequestData): string => {
		const id = requestData["id" as keyof RequestData] ?? ""

		if (id) {
			return `${id}/`
		}

		return ""
	}

	// If the ID was in the request data so that it could be added to the URL,
	// then we don't want to actually include in the POST sent to the backend.
	const tryToDeleteId = (requestData: RequestData): RequestData | Omit<RequestData, "id"> => {
		const id = requestData["id" as keyof RequestData] ?? ""

		if (id) {
			const postData = cloneDeep(requestData)
			delete postData["id" as keyof RequestData]

			return postData
		}

		return requestData
	}

	const mutation = useMutation(
		(requestDataRaw: RequestData) => {
			const requestData =
				options?.addRequestDataIdToUrl ? tryToDeleteId(requestDataRaw) : requestDataRaw

			let axiosConfig: AxiosRequestConfig | undefined
			if (requestData instanceof FormData) {
				axiosConfig = {
					headers: {
						"content-type":
							"multipart/form-data; boundary=----WebKitFormBoundary6CQcHKAPzXlSYnJt",
					},
				}
			}

			const response = apiClient.post<CamelToSnakeCaseNested<ResponseData>>(
				`${arrayWrap(endpoint).join("/")}/${
					options?.addRequestDataIdToUrl ? tryToAddId(requestDataRaw) : ""
				}${
					options?.urlPathsAfterId ?
						`${arrayWrap(options.urlPathsAfterId).join("/")}/`
					:	""
				}`,
				requestData instanceof FormData ? requestData : (
					pythonify(requestData, options?.pythonifyExcludeList)
				),
				axiosConfig
			)

			return response
		},
		{
			onError: (e) => {
				if (options?.onError) {
					options.onError(e)
				}
				console.error("usePostRequest mutation error", e)
			},
			onSuccess: (data, requestData) => {
				if (options?.onSuccess) {
					options.onSuccess(data, requestData)
				}
				if (options?.keyToInvalidate) {
					const keys =
						typeof options.keyToInvalidate === "string" ?
							[options.keyToInvalidate]
						:	options.keyToInvalidate
					keys.forEach((key) => {
						void queryClient.invalidateQueries(key)
					})
				}
			},
			retry: false,
		}
	)

	return mutation.mutateAsync
}
