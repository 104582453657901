import { useCallback } from "react"

import { UrlState, useUrlStateContext } from "./UrlStateContext"
import { SetUrlState } from "./use-url-state"

export const useSetUrlState = <State extends UrlState>(): SetUrlState<State> => {
	const [state, queryParamDispatch] = useUrlStateContext()

	const setUrlState: SetUrlState<State> = useCallback(
		(update) => {
			queryParamDispatch({
				type: "patch slice",
				payload: typeof update === "function" ? update(state as State) : update,
			})
		},
		[queryParamDispatch, state]
	)

	return setUrlState
}
