import { FC, useMemo } from "react"

import { css } from "@emotion/react"

import { ConnectedDevicesAtSite, getGenericDeviceStatus } from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Card,
	cssMixins,
	EmptyValueDash,
	Heading,
	Paragraph,
	StatusBlip,
	useUrlState,
} from "@ncs/web-legos"

import { MachineDetailModal } from "./components"

export interface ConnectedDevicesTabProp {
	customer: ConnectedDevicesAtSite["customer"]
	devices: ConnectedDevicesAtSite["devices"]
}

export type ConnectedDevicesTabUrlState = {
	modalDeviceId: string | null
}

export const ConnectedDevicesTab: FC<ConnectedDevicesTabProp> = ({ customer, devices }) => {
	const [{ modalDeviceId }, { updateUrlValue }] = useUrlState<ConnectedDevicesTabUrlState>({
		modalDeviceId: null,
	})
	const modalMachine = useMemo(() => {
		return (devices ?? []).find((m) => m.id === modalDeviceId)
	}, [modalDeviceId, devices])

	return (
		<>
			<Box css={machineTableGridStyle} px={1.5} mb={1.5}>
				<Heading variant="h6">Name</Heading>
				<Heading variant="h6">Vintage</Heading>
				<Heading variant="h6">Serial #</Heading>
				<Heading variant="h6">Status</Heading>
			</Box>

			{devices.map((device, i) => (
				<AnimatedEntrance key={device.id} show delay={75 * i}>
					<Card
						variant="droplet"
						backgroundColorCategory={getGenericDeviceStatus(device.state)}
						css={machineTableGridStyle}
						mb={1.5}
						onClick={() => updateUrlValue("modalDeviceId", device.id)}
					>
						<Heading variant="h4" bold css={cssMixins.breakWord}>
							{device.name}
						</Heading>
						<Paragraph>{device.equipment?.vintage || <EmptyValueDash />}</Paragraph>
						<Paragraph>
							{device.equipment?.serialNumber || <EmptyValueDash />}
						</Paragraph>
						<Box mt={-0.1}>
							<Box display="flex" alignItems="center" gap={0.5}>
								<StatusBlip status={device.state} size={1} />
								<Paragraph mt={0.25}>{titleCase(device.state)}</Paragraph>
							</Box>
						</Box>
					</Card>
				</AnimatedEntrance>
			))}

			{!!modalMachine && (
				<MachineDetailModal
					onClose={() => updateUrlValue("modalDeviceId", null)}
					device={modalMachine}
					customer={customer}
				/>
			)}
		</>
	)
}

const machineTableGridStyle = css`
	display: grid;
	grid-template-columns: 20rem 2fr 1fr 1fr;
	gap: 1rem;
`
