import { useState } from "react"

import { DateFormat, getTimeAgoStartDate, TimeAgo } from "@ncs/ts-utils"

import { TimeAgoSelector } from "../selectors"

interface CallReceivedQueryParamState {
	callReceivedGte: string | null
}

export interface CallReceivedQueryFilterProps<
	QueryParamState extends CallReceivedQueryParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
}

export const CallReceivedQueryFilter = <QueryParamState extends CallReceivedQueryParamState>({
	queryParamState,
	setQueryParamState,
}: CallReceivedQueryFilterProps<QueryParamState>): React.ReactElement => {
	// We need to keep the state of the selected option here locally, because
	// what we push up to query param state is different.
	const [timeAgo, setTimeAgo] = useState<TimeAgo | null>(null)

	const handleChange = (newValue: TimeAgo | null) => {
		// Set local state.
		setTimeAgo(newValue)

		// Set the query param state accordingly.
		if (!newValue) {
			setQueryParamState((prev) => ({
				...prev,
				callReceivedGte: null,
			}))
		} else {
			setQueryParamState((prev) => ({
				...prev,
				callReceivedGte: getTimeAgoStartDate(newValue).format(DateFormat.DateQueryParam),
			}))
		}
	}

	// We need to listen for the reset.
	if (!queryParamState.callReceivedGte && !queryParamState.callReceivedGte && timeAgo) {
		setTimeAgo(null)
	}

	return (
		<TimeAgoSelector
			label="Call Received"
			value={timeAgo}
			onChange={handleChange}
			fillContainer
		/>
	)
}
