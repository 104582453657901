import React, { useState } from "react"

import { zodResolver } from "@hookform/resolvers/zod"
import { captureException } from "@sentry/minimal"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { z } from "zod"

import { makeApiErrorMessage, useSetNewPassword, useTokenVerification } from "@ncs/ncs-api"
import {
	Box,
	Button,
	ErrorText,
	Heading,
	LoadingSpinner,
	TextInputFormField,
} from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

import { PasswordResetSuccessModal } from "./components"

const passwordResetFormSchema = z
	.object({
		password: z.string().min(6, "Must be at least 6 characters long"),
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: "Passwords do not match",
		path: ["confirmPassword"],
	})

type PasswordResetForm = z.infer<typeof passwordResetFormSchema>

export const ResetPassword: React.FC = () => {
	const { token } = useParams<{ token?: string }>()
	const [tokenVerified, setTokenVerified] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [, verificationLoading] = useTokenVerification(token, {
		queryConfig: {
			enabled: !tokenVerified,
			onSuccess: () => {
				setTokenVerified(true)
				setErrorMessage(null)
			},
			onError: (e) => {
				setErrorMessage(makeApiErrorMessage(e))
				captureException(e)
			},
		},
	})
	const setNewPassword = useSetNewPassword(token)
	const [submitting, setIsSubmitting] = useState(false)
	const [newPasswordSuccessful, setNewPasswordSuccessful] = useState(false)

	const {
		control,
		handleSubmit,
		formState: { isValid, submitCount },
	} = useForm<PasswordResetForm>({ resolver: zodResolver(passwordResetFormSchema) })

	const onSubmit = async ({ password }: PasswordResetForm) => {
		try {
			setIsSubmitting(true)
			setErrorMessage(null)
			await setNewPassword({ password })
			setNewPasswordSuccessful(true)
		} catch (e) {
			captureException(e)
			setErrorMessage(makeApiErrorMessage(e))
		} finally {
			setIsSubmitting(false)
		}
	}

	return (
		<PageContentWrapper title="Reset Password">
			<Box maxWidth={25} pt={5} m="auto">
				<Heading variant="h2" bold mb={2} textAlign="center">
					Reset Password
				</Heading>

				{!!errorMessage && <ErrorText textAlign="center">{errorMessage}</ErrorText>}

				{verificationLoading && <LoadingSpinner />}

				{tokenVerified && (
					<>
						<TextInputFormField
							control={control}
							type="password"
							name="password"
							label="New password"
						/>
						<TextInputFormField
							control={control}
							type="password"
							name="confirmPassword"
							placeholder="Confirm password..."
							onReturn={handleSubmit(onSubmit)}
						/>
						<Button
							variant="primary-cta"
							onClick={handleSubmit(onSubmit)}
							fillContainer
							containerProps={{ my: 2 }}
							disabled={!isValid && submitCount > 0}
							isLoading={submitting}
						>
							Set New Password
						</Button>
					</>
				)}
			</Box>

			<PasswordResetSuccessModal isOpen={newPasswordSuccessful} />
		</PageContentWrapper>
	)
}
