import { createContext, Dispatch, SetStateAction } from "react"

import { IconProps } from "../../components/typography"

export type ToastState = null | {
	message: string
	action?: ToastAction
	iconName?: IconProps["icon"]
	iconFamily?: IconProps["family"]
	iconColor?: IconProps["color"]
}

export interface ToastAction {
	actionText: string
	actionOnClick: () => void
	actionIcon?: IconProps["icon"]
}

export const ToastContext = createContext<
	[ToastState, Dispatch<SetStateAction<ToastState>>] | undefined
>(undefined)
