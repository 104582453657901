import { ChangeEvent, memo, ReactElement, useMemo, useState } from "react"

import { css, Theme } from "@emotion/react"
import MuiTab from "@material-ui/core/Tab"
import MuiTabs from "@material-ui/core/Tabs"

import { withCssUnit } from "@ncs/ts-utils"

import { AnimatedEntrance } from "../transitions"

export interface TabsProps<TabName extends string = string> {
	/** Optionally tell Tabs what the current tab is. */
	currentTab?: TabName | null
	/** Optionally tell Tabs what to do when user changes tabs. */
	onChange?(newTab: TabName): void
	/** Declare the height of all the panels. */
	height?: string
	/** Declare a min height for all the panels */
	minHeight?: string
	/** Declare a max height for all the panels. */
	maxHeight?: string
	panels: {
		navLabel: TabName
		navIcon?: ReactElement
		component: ReactElement
		disabled?: boolean
	}[]
	/** Disable all the tabs, so none are selected. */
	disabled?: boolean
}

const a11yProps = (tabName: string) => {
	return {
		id: `tabs-${tabName}`,
		"aria-controls": `tabpanel-${tabName}`,
	}
}

export const TabsWithoutMemo = <TabName extends string = string>({
	currentTab: propTab,
	onChange: setPropTab,
	height,
	minHeight,
	maxHeight,
	panels,
	disabled: allTabsDisabled,
}: TabsProps<TabName>): ReactElement => {
	const [localTab, setLocalTab] = useState(panels[0].navLabel)

	const handleChange = (event: ChangeEvent<unknown>, newValue: TabName) => {
		if (setPropTab) {
			setPropTab(newValue)
		} else {
			setLocalTab(newValue)
		}
	}

	const panelStyle = (theme: Theme) => css`
		max-height: ${maxHeight ?? "none"};
		height: ${height ?? "auto"};
		min-height: ${minHeight ?? "auto"};
		overflow-y: ${maxHeight || height ? "auto" : "initial"};
		padding: 1rem;
		${theme.breakpoints.only("xs")} {
			padding: 1rem 0;
		}
	`

	const currentTab = useMemo(
		() => (typeof propTab !== "undefined" ? propTab : localTab),
		[propTab, localTab]
	)

	return (
		<>
			<MuiTabs
				value={allTabsDisabled || currentTab === null ? false : currentTab}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				css={tabBarStyle}
			>
				{panels.map((p) => (
					<MuiTab
						key={p.navLabel}
						color="primary"
						label={p.navLabel}
						icon={p.navIcon}
						css={tabNavItemStyle}
						value={p.navLabel}
						disabled={allTabsDisabled || p.disabled}
						{...a11yProps(p.navLabel)}
					/>
				))}
			</MuiTabs>

			{allTabsDisabled || currentTab === null ? null : (
				panels.map((p) => (
					<div
						key={p.navLabel}
						id={`tabpanel-${p.navLabel}`}
						role="tabpanel"
						aria-labelledby={`tabs-${p.navLabel}`}
						hidden={currentTab !== p.navLabel}
						css={panelStyle}
					>
						{!p.disabled && (
							<AnimatedEntrance show={currentTab === p.navLabel} duration={275}>
								{p.component}
							</AnimatedEntrance>
						)}
					</div>
				))
			)}
		</>
	)
}

export const Tabs = memo(TabsWithoutMemo) as typeof TabsWithoutMemo

const tabBarStyle = (theme: Theme) => css`
	margin-top: 1rem;
	margin-bottom: 1.75rem;
	border-bottom: 1px solid ${theme.palette.grey[300]};
	box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
	.MuiTabs-scroller {
		// MUI adds an inline style for this, so we need to use !important to override that.
		overflow: auto !important;
	}
	.MuiTabs-flexContainer {
		${theme.breakpoints.down("sm")} {
			justify-content: space-around;
		}
	}
	.MuiTab-root {
		max-width: 300px;
	}
	.MuiTabs-indicator {
		height: 5px;
	}
`
const tabNavItemStyle = (theme: Theme) => css`
	// (This is .MuiTab-root)
	text-transform: none;
	transition: color ${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeOut};
	padding: 0 2rem;
	${theme.breakpoints.down("sm")} {
		padding: 0 1rem;
		min-width: auto;
		flex-shrink: 1;
	}
	${theme.breakpoints.only("xs")} {
		padding: 0;
	}
	.MuiTab-wrapper {
		flex-direction: row;
		font-size: 1.1rem;
		line-height: 1.15rem;
		font-weight: normal;
		height: 4rem;
		${theme.breakpoints.down("md")} {
			flex-direction: column;
		}
		${theme.breakpoints.only("sm")} {
			font-size: 1rem;
		}
		${theme.breakpoints.only("xs")} {
			height: auto;
			font-size: 0.75rem;
			white-space: nowrap;
		}
		.svg-inline--fa {
			position: relative;
			top: ${withCssUnit(theme.tabs?.navIconTop)};
			bottom: ${withCssUnit(theme.tabs?.navIconBottom)};
			margin-right: 0.85rem;
			font-size: 1.1em;
			margin-bottom: -1px;
			${theme.breakpoints.down("md")} {
				margin-right: 0;
				margin-top: -10px;
				margin-bottom: 7px;
			}
			${theme.breakpoints.down("sm")} {
				margin-top: 0;
			}
		}
	}
	&.MuiTab-textColorPrimary {
		opacity: 0.75;
		color: ${theme.palette.text.primary};
		&.Mui-selected {
			opacity: 1;
			color: ${theme.palette.primary.main};
			.MuiTab-wrapper {
				font-weight: bold;
			}
		}
		&:hover {
			opacity: 1;
		}
	}
	&.Mui-disabled {
		opacity: 0.25;
	}
`
