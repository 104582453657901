import { FC, HTMLProps, ReactNode } from "react"

import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from "@material-ui/core"

import { Box } from "../layout"
import { Icon, IconName, IconProps, Paragraph, ParagraphProps } from "../typography"

export interface TooltipProps extends Omit<MuiTooltipProps, "title" | "children"> {
	title: ReactNode | string
	icon?: IconName
	iconFamily?: IconProps["family"]
	iconColor?: IconProps["color"]
	noIcon?: boolean
	contentContainerProps?: HTMLProps<HTMLDivElement>
	paragraphProps?: ParagraphProps
	disabled?: boolean
}

export const Tooltip: FC<TooltipProps> = ({
	placement = "top-start",
	enterDelay = 500,
	title,
	icon = "info-circle",
	iconFamily = "regular",
	iconColor = "gray",
	noIcon,
	contentContainerProps,
	paragraphProps,
	disabled,
	children,
	...rest
}) => {
	return (
		<MuiTooltip
			title={
				<Box display="flex" columnGap={0.5}>
					{!noIcon && (
						<Box mt={0.1}>
							<Icon icon={icon} family={iconFamily} color={iconColor} />
						</Box>
					)}
					{typeof title === "string" ?
						<Paragraph small {...paragraphProps}>
							{title}
						</Paragraph>
					:	<div>{title}</div>}
				</Box>
			}
			placement={placement}
			enterDelay={enterDelay}
			disableHoverListener={disabled}
			{...rest}
		>
			<div {...contentContainerProps}>{children}</div>
		</MuiTooltip>
	)
}
