import { FC } from "react"

import { Box, BoxProps } from "../layout"
import { Icon, IconName } from "../typography"
import { Tooltip, TooltipProps } from "./Tooltip"

export interface TooltipIconProps extends Omit<BoxProps, "onClick"> {
	text?: string
	icon?: IconName
	tooltipProps?: Omit<TooltipProps, "title">
}

export const TooltipIcon: FC<TooltipIconProps> = ({
	text,
	icon = "info-circle",
	tooltipProps,
	...rest
}) => {
	return (
		<Box d="inline-block" mx={0.35} {...rest}>
			<Tooltip
				noIcon
				paragraphProps={{ d: "inline-block" }}
				contentContainerProps={{
					style: {
						display: "inline-block",
					},
				}}
				{...tooltipProps}
				title={typeof rest.children === "string" ? rest.children : text}
			>
				<Icon icon={icon} />
			</Tooltip>
		</Box>
	)
}
