import { useCallback, useLayoutEffect, useState } from "react"

/** Scrolls to the top of the page on load **/
export const useScrollToTopOnMount = (): void => {
	const trigger = useScrollToTop()
	useLayoutEffect(() => trigger(), [trigger])
}

/** Returns a function that will scroll to the top of the page when called **/
export const useScrollToTop = (): (() => void) => {
	const [triggered, setTriggered] = useState<Date>()

	// scroll to the top when triggered changes, except the first time
	useLayoutEffect(() => {
		let id: null | ReturnType<typeof setTimeout> = null
		if (typeof triggered !== "undefined") {
			id = setTimeout(() => window.scrollTo(0, 0), 1)
		}
		return () => {
			if (id) {
				clearTimeout(id)
			}
		}
	}, [triggered])

	const trigger = useCallback(() => {
		setTriggered(new Date())
	}, [])

	return trigger
}
