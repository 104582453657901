import { css } from "@emotion/react"

import AtlasGroteskBlack from "~/fonts/AtlasGroteskBlack.otf"
import AtlasGroteskBlackItalic from "~/fonts/AtlasGroteskBlackItalic.otf"

import AtlasGroteskBold from "~/fonts/AtlasGroteskBold.otf"
import AtlasGroteskBoldItalic from "~/fonts/AtlasGroteskBoldItalic.otf"

import AtlasGroteskLight from "~/fonts/AtlasGroteskLight.otf"
import AtlasGroteskLightItalic from "~/fonts/AtlasGroteskLightItalic.otf"

import AtlasGroteskMedium from "~/fonts/AtlasGroteskMedium.otf"
import AtlasGroteskMediumItalic from "~/fonts/AtlasGroteskMediumItalic.otf"

import AtlasGroteskRegular from "~/fonts/AtlasGroteskRegular.otf"
import AtlasGroteskRegularItalic from "~/fonts/AtlasGroteskRegularItalic.otf"

import AtlasGroteskThin from "~/fonts/AtlasGroteskThin.otf"
import AtlasGroteskThinItalic from "~/fonts/AtlasGroteskThinItalic.otf"

export const fonts = css`
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: black;
		font-style: normal;
		src: url(${AtlasGroteskBlack}) format("otf");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: black;
		font-style: italic;
		src: url(${AtlasGroteskBlackItalic}) format("otf");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: bold;
		font-style: normal;
		src: url(${AtlasGroteskBold}) format("otf");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: bold;
		font-style: italic;
		src: url(${AtlasGroteskBoldItalic}) format("otf");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: light;
		font-style: normal;
		src: url(${AtlasGroteskLight}) format("otf");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: light;
		font-style: italic;
		src: url(${AtlasGroteskLightItalic}) format("otf");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: medium;
		font-style: normal;
		src: url(${AtlasGroteskMedium}) format("otf");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: medium;
		font-style: italic;
		src: url(${AtlasGroteskMediumItalic}) format("otf");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: regular;
		font-style: normal;
		src: url(${AtlasGroteskRegular}) format("otf");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: regular;
		font-style: italic;
		src: url(${AtlasGroteskRegularItalic}) format("otf");
	}

	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: thin;
		font-style: normal;
		src: url(${AtlasGroteskThin}) format("otf");
	}
	@font-face {
		font-family: "Atlas Grotesk";
		font-weight: thin;
		font-style: italic;
		src: url(${AtlasGroteskThinItalic}) format("otf");
	}
`
