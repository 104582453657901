import React from "react"

import { CreditCard as ICreditCard, CreditCardBrand } from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"

import { Box, BoxProps } from "../layout"
import { Icon, IconFamily, IconName, LabeledData } from "../typography"

export interface CreditCardProps extends BoxProps {
	card: ICreditCard
}

const cardIconLookup: Record<CreditCardBrand, [IconName, IconFamily]> = {
	[CreditCardBrand.AMERICAN_EXPRESS]: ["cc-amex", "brands"],
	[CreditCardBrand.DINERS_CLUB]: ["cc-diners-club", "brands"],
	[CreditCardBrand.DISCOVER]: ["cc-discover", "brands"],
	[CreditCardBrand.JCB]: ["cc-jcb", "brands"],
	[CreditCardBrand.MASTERCARD]: ["cc-mastercard", "brands"],
	[CreditCardBrand.UNIONPAY]: ["credit-card", "regular"],
	[CreditCardBrand.VISA]: ["cc-visa", "brands"],
	[CreditCardBrand.OTHER]: ["credit-card", "regular"],
}

export const CreditCard: React.FC<CreditCardProps> = ({ card, ...rest }) => {
	return (
		<Box display="flex" {...rest}>
			<Box display="flex" flexGrow={0} mr={1} mt={0.75}>
				<Icon
					icon={cardIconLookup[card.brand][0]}
					family={cardIconLookup[card.brand][1]}
				/>
			</Box>
			<Box display="flex" flexWrap="wrap">
				<Box width={14} mr={1}>
					<LabeledData label={"Card"}>
						{titleCase(card.brand)} ...{card.last4}
					</LabeledData>
				</Box>
				<Box>
					<LabeledData label="Expires">
						{card.expMonth}/{card.expYear}
					</LabeledData>
				</Box>
			</Box>
		</Box>
	)
}
