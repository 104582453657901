import React, { memo } from "react"

import { CustomerSite, useSites } from "@ncs/ncs-api"

import { Select, SelectProps } from "../inputs"

export type SiteSelectorProps = Omit<SelectProps<CustomerSite>, "options">

export const SiteSelector: React.FC<SiteSelectorProps> = memo(({ ...rest }) => {
	const [sites, sitesAreLoading] = useSites()

	return (
		<Select
			label="Site"
			disabled={sitesAreLoading}
			{...rest}
			valueAccessor="id"
			textAccessor="name"
			options={sites ?? []}
		/>
	)
})
