import { FC, memo, useContext, useMemo } from "react"

import { css, Theme } from "@emotion/react"
import { Slide, Snackbar } from "@material-ui/core"

import { Button } from "../../components/buttons"
import { Box } from "../../components/layout"
import { Icon, Paragraph } from "../../components/typography"
import { ToastContext } from "./toast-context"

export const Toaster: FC = memo(() => {
	const toastContext = useContext(ToastContext)

	if (!toastContext) {
		throw new Error("Toast must be used within a ToastProvider")
	}

	const [toastState, setToastState] = toastContext

	const message = useMemo(() => {
		if (!toastState) {
			return null
		}

		const { message: messageText, iconName, iconColor, iconFamily } = toastState

		return (
			<Box display="flex" columnGap={0.75}>
				{!!iconName && (
					<Box mt={0.05}>
						<Icon icon={iconName} color={iconColor} family={iconFamily} />
					</Box>
				)}
				<Paragraph color="white">{messageText}</Paragraph>
			</Box>
		)
	}, [toastState])

	const action = useMemo(() => {
		if (!toastState?.action) {
			return undefined
		}

		const { actionText, actionOnClick, actionIcon } = toastState.action

		const handleClick = () => {
			// Close the toast when you click on the action.
			setToastState(null)
			actionOnClick()
		}

		return (
			<Button onClick={handleClick} trailingIcon={actionIcon} containerProps={{ mt: -0.2 }}>
				{actionText}
			</Button>
		)
	}, [toastState?.action, setToastState])

	return (
		<Snackbar
			autoHideDuration={6000}
			message={message}
			open={!!message}
			anchorOrigin={{
				vertical: "top",
				horizontal: "center",
			}}
			action={action}
			TransitionComponent={Slide}
			key={toastState?.message}
			onClose={(e, reason) => {
				// For some reason the clickaway is firing unexpectedly.
				// STFU, clickaway.
				if (reason !== "clickaway") {
					setToastState(null)
				}
			}}
			css={snackbarStyles}
		/>
	)
})

const snackbarStyles = (theme: Theme) => css`
	.MuiSnackbarContent-root {
		background: ${theme.palette.primary.dark};
	}
	.MuiSnackbarContent-action {
		padding-right: 1rem;
		button {
			.button-text {
				color: white;
				font-size: 1rem;
				font-weight: bold;
			}
			svg {
				color: white;
				font-size: 1rem;
			}
		}
	}
`
