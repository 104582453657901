import { useState } from "react"

import { Row } from "react-table"

import { SetUrlState, useUrlState } from "../../contexts"

export type PageSizes = 25 | 50 | 100 | 200

export type UsePaginationOptions = {
	defaultPage: number
	defaultPageSize: PageSizes
}

export type PaginationUrlState = {
	page: number
	pageSize: PageSizes
}

const defaultUsePaginationOptions: UsePaginationOptions = {
	defaultPage: 1,
	defaultPageSize: 50,
}

type Pagination = {
	page: number
	pageSize: PageSizes
}

export const usePaginationState = (optionsArg?: Partial<UsePaginationOptions>) => {
	const options: UsePaginationOptions = {
		...defaultUsePaginationOptions,
		...optionsArg,
	}

	return useState<Pagination>({
		page: options.defaultPage,
		pageSize: options.defaultPageSize,
	})
}

export const usePaginationUrlState = (
	optionsArg?: Partial<UsePaginationOptions>
): [Pagination, SetUrlState<Pagination>] => {
	const options: UsePaginationOptions = {
		...defaultUsePaginationOptions,
		...optionsArg,
	}

	const [pagination, { setUrlState: setPagination }] = useUrlState<{
		page: number
		pageSize: PageSizes
	}>(
		{
			page: options.defaultPage,
			pageSize: options.defaultPageSize,
		},
		{
			fallbackToInitialState: true,
		}
	)

	return [pagination, setPagination]
}

export const pageSizeSelectOptions: {
	value: PageSizes
	label: string
}[] = [
	{
		value: 25,
		label: "25",
	},
	{
		value: 50,
		label: "50",
	},
	{
		value: 100,
		label: "100",
	},
	{
		value: 200,
		label: "200",
	},
]

export enum ReactTableSortType {
	String = "string",
	Number = "number",
	Basic = "basic",
	Datetime = "datetime",
	Alphanumeric = "alphanumeric", // Default
}

/**
 * The actual data object for this row, unwrapped from the row context.
 */
export type RowObject<RowType extends Object> = Row<RowType>["original"]
