import React from "react"

import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

const client = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60, // 1 min stale time
		},
	},
})

export const ReactQueryClientProvider: React.FCC = ({ children }) => {
	return (
		<QueryClientProvider client={client}>
			<>
				<ReactQueryDevtools initialIsOpen={false} />
				{children}
			</>
		</QueryClientProvider>
	)
}
