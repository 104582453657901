import { FC } from "react"

import { YouTubeVideo } from "@ncs/web-legos"

import { PageContentWrapper } from "~/shared-components"

export const BlueWaveWelcome: FC = () => {
	return (
		<PageContentWrapper heading="Welcome, BlueWave!" breadcrumbs={[{ name: "BlueWave" }]}>
			<YouTubeVideo v="2x2pq7QOJqg" />
		</PageContentWrapper>
	)
}
