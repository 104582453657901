import { FC } from "react"

import { css, Theme } from "@emotion/react"

import {
	Box,
	EventTracker,
	Icon,
	Link,
	Paragraph,
	TrackingEvent,
	useScreenSizeMatch,
} from "@ncs/web-legos"

import { useOrderTotals } from "~/contexts"

export const ShopBarCartSummary: FC = () => {
	const screenIsTiny = useScreenSizeMatch("xs")
	const { totalItemCount } = useOrderTotals(false)

	return (
		<div css={cartStatusContainerStyle}>
			<EventTracker event={TrackingEvent.GO_TO_CART}>
				<Link to="/shop/cart-summary">
					<Box display="flex" alignItems="center">
						<div css={cartIconContainerStyle}>
							<Icon icon="shopping-cart" color="white" />
							{totalItemCount > 0 && (
								<div css={cartCountIndicatorStyle}>
									<span>{totalItemCount}</span>
								</div>
							)}
						</div>
						{!screenIsTiny && (
							<Paragraph bold color="white" mt={0.2}>
								CART
							</Paragraph>
						)}
					</Box>
				</Link>
			</EventTracker>
		</div>
	)
}

const cartStatusContainerStyle = css`
	margin-left: auto;
	a {
		text-decoration: none;
	}
`
const cartIconContainerStyle = (theme: Theme) => css`
	display: flex;
	align-items: center;
	position: relative;
	padding: 0 1rem;
`
const cartCountIndicatorStyle = (theme: Theme) => css`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: -0.5rem;
	right: 0.25rem;
	background: ${theme.palette.error.main};
	border-radius: 50%;
	height: 1rem;
	padding: 0 0.25rem;
	span {
		position: relative;
		top: 1px;
		color: white;
		font-size: 0.75rem;
	}
`
