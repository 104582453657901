import React, { useState } from "react"

import { css } from "@emotion/react"
import { captureException } from "@sentry/minimal"
import { Redirect } from "react-router-dom"

import {
	APPLICATION,
	CreditCard as ICreditCard,
	PaymentProvider,
	PaymentSource,
	StripeBankAccount,
	StripeBankAccountStatus,
	useAuth,
	useCustomerPaymentMethods,
	useSetDefaultPaymentMethod,
} from "@ncs/ncs-api"
import {
	BankAccount,
	Box,
	Button,
	CreditCard,
	GridContainer,
	GridItem,
	Heading,
	Icon,
	LoadingSpinner,
	Paragraph,
} from "@ncs/web-legos"

import {
	NewBankAccountModal,
	NewCardModal,
	RemoveMethodModal,
	VerifyBankAccountModal,
} from "./components"

export const BillingTab: React.FC = () => {
	const { user } = useAuth()
	const [paymentMethods, loading] = useCustomerPaymentMethods()
	const [showNewCardModal, setShowNewCardModal] = useState(false)
	const [showNewBankAccountModal, setShowNewBankAccountModal] = useState(false)
	const [bankAccountToVerify, setBankAccountToVerify] = useState<StripeBankAccount | null>(null)
	const [bankAccountToRemove, setBankAccountToRemove] = useState<StripeBankAccount | null>(null)
	const [cardToRemove, setCardToRemove] = useState<ICreditCard | null>(null)
	const setDefaultMethod = useSetDefaultPaymentMethod()
	const [idBeingSetToDefault, setIdBeingSetToDefault] = useState<string | null>(null)

	const setDefaultMethodBank = async (account: StripeBankAccount) => {
		try {
			setIdBeingSetToDefault(account.id)
			await setDefaultMethod({
				source: PaymentSource.BANK_ACCOUNT,
				provider: PaymentProvider.STRIPE,
				id: account.id,
			})
		} catch (e) {
			console.error(e)
			captureException(e)
		} finally {
			setIdBeingSetToDefault(null)
		}
	}

	const setDefaultMethodCard = async (card: ICreditCard) => {
		try {
			setIdBeingSetToDefault(card.id)
			await setDefaultMethod({
				source: PaymentSource.CARD,
				provider: PaymentProvider.SQUARE,
				id: card.id,
			})
		} catch (e) {
			console.error(e)
			captureException(e)
		} finally {
			setIdBeingSetToDefault(null)
		}
	}

	if (!!user && user.apps.includes(APPLICATION.HidePaymentInfo)) {
		return <Redirect to="/account" />
	}

	return (
		<>
			<Heading bold mb={2}>
				Your Cards
			</Heading>

			{loading && <LoadingSpinner justifyContent="flex-start" py={1} />}

			<ul>
				{!loading &&
					paymentMethods &&
					paymentMethods.cards.length > 0 &&
					paymentMethods.cards.map((card) => (
						<li
							key={`${card.last4}${card.expMonth}${card.expYear}`}
							css={methodRowStyle}
						>
							<GridContainer alignItems="center">
								<GridItem xs={12} sm={6}>
									<CreditCard card={card} />
								</GridItem>

								<GridItem display="flex" alignItems="center" xs={12} sm={3}>
									{(
										paymentMethods.defaultPaymentMethod?.source ===
											PaymentSource.CARD &&
										paymentMethods.defaultPaymentMethod.id === card.id
									) ?
										<Box display="flex" alignItems="center" pl={0.25}>
											<Icon
												icon="money-bill-wave"
												color="success"
												fontSize="1.1rem"
											/>
											<Paragraph ml={0.5} mt={0.2} small bold>
												Default payment method
											</Paragraph>
										</Box>
									:	<Button
											onClick={() => setDefaultMethodCard(card)}
											isLoading={idBeingSetToDefault === card.id}
											allowTextWrap
										>
											Make default payment method
										</Button>
									}
								</GridItem>

								<GridItem xs={12} sm={3}>
									<Button icon="trash" onClick={() => setCardToRemove(card)}>
										Remove
									</Button>
								</GridItem>
							</GridContainer>
						</li>
					))}
			</ul>
			{!loading && (!paymentMethods || paymentMethods.cards.length === 0) && (
				<Paragraph small color="secondary">
					No cards on file.
				</Paragraph>
			)}
			<Button
				icon="plus"
				variant="secondary-cta"
				containerProps={{ mt: 1 }}
				onClick={() => setShowNewCardModal(true)}
			>
				Add Card
			</Button>

			<Heading bold mt={5} mb={2}>
				Your Bank Accounts
			</Heading>

			{loading && <LoadingSpinner justifyContent="flex-start" py={1} />}

			<ul>
				{!loading &&
					paymentMethods &&
					paymentMethods.bankAccounts.length > 0 &&
					paymentMethods.bankAccounts.map((account) => (
						<li
							key={`${account.routingNumber}${account.accountNumberLast4}`}
							css={methodRowStyle}
						>
							<GridContainer>
								<GridItem xs={12} sm={6}>
									<BankAccount account={account} />
								</GridItem>

								<GridItem xs={12} sm={3} display="flex" alignItems="center">
									{(
										paymentMethods.defaultPaymentMethod?.source ===
											PaymentSource.BANK_ACCOUNT &&
										paymentMethods.defaultPaymentMethod.id === account.id
									) ?
										<Box display="flex" alignItems="center" pl={0.25}>
											<Icon
												icon="money-bill-wave"
												color="success"
												fontSize="1.1rem"
											/>
											<Paragraph ml={0.5} mt={0.2} small bold>
												Default payment method
											</Paragraph>
										</Box>
									:	<Button
											onClick={() => setDefaultMethodBank(account)}
											isLoading={idBeingSetToDefault === account.id}
											allowTextWrap
											disabled={
												account.status !== StripeBankAccountStatus.VERIFIED
											}
										>
											Make default payment method
										</Button>
									}
								</GridItem>

								<GridItem xs={12} sm={3} display="flex" alignItems="center">
									<Button
										icon="trash"
										onClick={() => setBankAccountToRemove(account)}
									>
										Remove
									</Button>
								</GridItem>
							</GridContainer>
							{(account.status === StripeBankAccountStatus.NEW ||
								account.status === StripeBankAccountStatus.VALIDATED) && (
								<Box mt={1} mb={3} ml={2}>
									<Paragraph mb={1}>
										Account has been added and is awaiting verification. Two
										small deposits will be made within 1-2 business days.
										Verify these deposits here to begin using the account.
									</Paragraph>
									<Button
										variant="primary-cta"
										onClick={() => setBankAccountToVerify(account)}
									>
										Verify test deposits
									</Button>
								</Box>
							)}
						</li>
					))}
			</ul>

			{!loading && (!paymentMethods || paymentMethods.bankAccounts.length === 0) && (
				<Paragraph small color="secondary">
					No bank accounts on file.
				</Paragraph>
			)}

			<Button
				icon="plus"
				variant="secondary-cta"
				containerProps={{ mt: 1 }}
				onClick={() => setShowNewBankAccountModal(true)}
			>
				Add Account
			</Button>

			{showNewCardModal && <NewCardModal onClose={() => setShowNewCardModal(false)} />}
			<NewBankAccountModal
				isOpen={showNewBankAccountModal}
				onClose={() => setShowNewBankAccountModal(false)}
			/>
			<VerifyBankAccountModal
				isOpen={!!bankAccountToVerify}
				onClose={() => setBankAccountToVerify(null)}
				bankAccount={bankAccountToVerify}
			/>
			<RemoveMethodModal
				isOpen={!!bankAccountToRemove || !!cardToRemove}
				onClose={() => {
					setBankAccountToRemove(null)
					setCardToRemove(null)
				}}
				bankAccount={bankAccountToRemove}
				card={cardToRemove}
			/>
		</>
	)
}

const methodRowStyle = css`
	max-width: 65rem;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	border-bottom: 1px solid #eee;
	&:last-child {
		padding-bottom: 0;
		margin-bottom: 0;
		border-bottom: 0;
	}
`
