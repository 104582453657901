export type PriceRange = {
	id: string
	min: number | null
	max: number | null
	text: string
}

export const priceRanges: PriceRange[] = [
	{ id: "1", min: null, max: 50, text: "< $50" },
	{ id: "2", min: 50, max: 100, text: "$50 - $99" },
	{ id: "3", min: 100, max: 200, text: "$100 - $199" },
	{ id: "4", min: 200, max: 500, text: "$200 - $499" },
	{ id: "5", min: 500, max: 1000, text: "$500 - $1000" },
	{ id: "6", min: 1000, max: null, text: "> $1000" },
]
