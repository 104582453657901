import React, { useMemo } from "react"

import { css, useTheme } from "@emotion/react"
import { Chip as MuiChip, ChipProps as MuiChipProps } from "@material-ui/core"
import { transparentize } from "polished"

import { maxChars } from "@ncs/ts-utils"

import { Icon, IconName } from "../typography"

export interface ChipProps {
	label: string
	title?: MuiChipProps["title"]
	onClick?: MuiChipProps["onClick"]
	onDelete?: MuiChipProps["onDelete"]
	icon?: IconName
	variant?: "pill" | "square"
	fillContainer?: boolean
	charLimit?: number
	small?: boolean
	className?: string
}

export const Chip: React.FC<ChipProps> = ({
	label,
	title,
	onClick,
	onDelete,
	icon,
	variant = "pill",
	fillContainer,
	charLimit,
	small,
	className,
}) => {
	const { palette } = useTheme()

	const chipCss = useMemo(() => {
		const styles = [
			css`
				font-weight: bold;
				font-size: 0.85rem;
				.MuiChip-label {
					position: relative;
					top: 1px;
				}
				.MuiChip-icon {
					margin-left: 0.75rem;
				}
				.MuiTouchRipple-root {
					display: none;
				}
			`,
		]

		if (fillContainer) {
			styles.push(css`
				display: flex;
				justify-content: space-between;
				align-items: center;
			`)
		}

		if (variant === "square") {
			styles.push(css`
				border-radius: 4px;
				background: ${transparentize(0.95, palette.primary.main)};
				border-color: ${transparentize(0.75, palette.primary.main)};
			`)
		}

		if (small) {
			styles.push(css`
				font-weight: normal;
				.MuiSvgIcon-root {
					font-size: 1.15rem;
				}
				.MuiChip-label {
					top: 0px;
					padding-left: 8px;
					padding-right: 8px;
				}
			`)
		}

		return styles
	}, [fillContainer, variant, palette.primary.main, small])

	return (
		<MuiChip
			variant="outlined"
			color="primary"
			css={chipCss}
			onClick={(e) => {
				if (onClick) {
					onClick(e)
				} else if (onDelete) {
					onDelete(e)
				}
			}}
			onDelete={onDelete}
			label={charLimit ? maxChars(label, charLimit) : label}
			title={title}
			icon={icon ? <Icon icon={icon} /> : undefined}
			className={className}
		/>
	)
}
