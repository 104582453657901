import { OrderingQueryParams } from "../../request-hooks"
import { DeviceStatusCategory } from "../connectivity"
import { CustomerMinimal } from "../customers"
import { Territory, UserMinimal } from "../security"
import { CustomerEquipmentMinimal } from "../workorders"

export enum ReportUrlPaths {
	ArDetail = "ar_detail",
	AssignUserReports = "assign_user_reports",
	ChemicalDetail = "chemical_detail",
	Connectivity = "connectivity",
	Customer = "customer",
	CustomerDashboard = "customer_dashboard",
	CustomerPortalPurchasers = "customer_portal_purchasers",
	CustomerPortalUsageSummary = "customer_portal_usage_summary",
	CustomerSalesRep = "customer_sales_rep",
	DispatchCounts = "dispatch_counts",
	Dispatches = "dispatches",
	DispatchesByMonth = "dispatches_by_month",
	DispatchHistory = "dispatch_history",
	ErpDetail = "erp_detail",
	ErpSummary = "erp_summary",
	LaborPricing = "labor_pricing",
	MachineEvents = "machine_events",
	MachineMilestone = "machine_milestone",
	NegativeOnHand = "negative_on_hand",
	PartConsolidation = "part_consolidation",
	PartOrderDetail = "part_order_detail",
	Permissions = "permissions",
	PickListDetail = "pick_list_detail",
	PmpiDetail = "pmpi_detail",
	PmpiSummary = "pmpi_summary",
	PricingModifications = "pricing_modifications",
	Profitability = "profitability",
	ProfitSummary = "profit_summary",
	ReportAssignments = "report_assignments",
	Reports = "reports",
	ReportsList = "reports_list",
	SalesRepDetail = "sales_rep_detail",
	SalesRepInvoices = "sales_rep_invoices",
	ServiceCentsPerWash = "service_cents_per_wash",
	ServiceChemicalLevels = "service_chemical_levels",
	ServiceTrend = "service_trend",
	SiteSurvey = "site_survey",
	SiteSurveyGrouped = "site_survey_grouped",
	VacuumTrend = "vacuum_trend",
	WarehouseOrderReport = "warehouse_order_report",
	WashCountHistory = "wash_count_history",
	WaterQuality = "water_quality",
	Weather = "weather",
	WorkOrderDetail = "work_order_detail",
}

/**
 * A report that's prepared for Recharts, probably for connectivity in the Customer Portal.
 */
export type PortalReport = {
	xKey: string
	xLabel: string
	yLabel: string
	data: Record<string, string | number>[] // Technically this should be <string, string | number> because of the x key's value.
	dataKeys: Record<string, string>
}

// Value should be appropriate for display in the UI.
export enum PortalReportName {
	ServiceCentsPerWash = "Cents Per Wash",
	ServiceChemicalLevels = "Chemical Levels",
	MachineEvents = "Machine Events",
	WashCounts = "Wash Counts",
	WaterQuality = "Water Quality",
	Weather = "Weather",
}

export enum ReportParamKeys {
	Frequency = "frequency",
	Device = "device",
	Site = "customer",
	StartDate = "start_date",
	EndDate = "end_date",
	Level = "level",
	Field = "field",
}

export enum ReportTimeSpan {
	Hourly = "hourly",
	Daily = "daily",
	Weekly = "weekly",
	Monthly = "monthly",
}

export interface CustomerWashCountHistoryReportParams {
	[ReportParamKeys.Site]?: string | null
	[ReportParamKeys.Device]?: string
	[ReportParamKeys.Frequency]: ReportTimeSpan
	[ReportParamKeys.StartDate]?: string | null
	[ReportParamKeys.EndDate]?: string | null
}

export interface MachineEventsReportParams {
	[ReportParamKeys.Site]?: string | null
	[ReportParamKeys.Device]?: string
	[ReportParamKeys.Frequency]: ReportTimeSpan
	[ReportParamKeys.StartDate]?: string | null
	[ReportParamKeys.EndDate]?: string | null
	[ReportParamKeys.Level]?: DeviceStatusCategory
}

export interface WaterQualityReportParams {
	[ReportParamKeys.Site]?: string | null
	[ReportParamKeys.Frequency]: ReportTimeSpan
	[ReportParamKeys.StartDate]?: string | null
	[ReportParamKeys.EndDate]?: string | null
}

export interface ServiceChemicalLevelsReportParams {
	[ReportParamKeys.Frequency]: ReportTimeSpan
	[ReportParamKeys.StartDate]?: string | null
	[ReportParamKeys.EndDate]?: string | null
	[ReportParamKeys.Site]?: string | null
}

export interface ServiceCentsPerWashReportParams {
	[ReportParamKeys.Frequency]: ReportTimeSpan
	[ReportParamKeys.StartDate]?: string | null
	[ReportParamKeys.EndDate]?: string | null
	[ReportParamKeys.Site]?: string | null
}

/** @deprecated */
export interface WeatherReportParams {
	[ReportParamKeys.Site]?: string | null
	[ReportParamKeys.Frequency]: ReportTimeSpan
	[ReportParamKeys.StartDate]?: string | null
	[ReportParamKeys.EndDate]?: string | null
}

export interface VacuumTrendReport {
	miscData: {
		average: number
		calculatedMin: number
		calculatedMax: number
		min: number
		max: number
	}
	reportData: PortalReport
}

export interface VacuumTrendReportParams {
	[ReportParamKeys.Device]: string | null
	[ReportParamKeys.StartDate]: string | null
	[ReportParamKeys.EndDate]: string | null
	[ReportParamKeys.Field]: string | null
}

export type DispatchReport = {
	year: number
	month: number
	count: number
	total: number
}[]

/**
 * The report that corresponds to the report SQL table.
 */
export interface Report {
	id: string
	name: string
	customFilters: boolean
}

export interface AssignUserReportsPost {
	userId: string
	reports: string[] | null
	reportAssignmentId: string | null
}

export type ReportPermissions = {
	id: number
	name: string
}[]

export interface ChemicalRevSummaryReportLine {
	customerName: string
	customerNumber: string
	deliveryOrderCogs: string
	deliveryOrderRevenue: string
	kbmOrderCogs: null
	kbmOrderRevenue: null
	month: number
	partOrderCogs: string
	partOrderRevenue: string
	territoryCode: string
	territoryDescription: string
	workOrderCogs: string
	workOrderRevenue: string
	year: number
}

export interface ChemicalRevSummaryReportQueryParams extends OrderingQueryParams {
	organization: string | null
	region: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
}

export interface InventoryConsolidationReportLine {
	/** Location code */
	location: string
	locationDescription: string
	/** Part number */
	part: string
	partDescription: string

	// Looks like the backend could theoretically send unlimited, but the old UI was constrained
	// to 5 bins, so we'll just assume that's good enough.
	bin1: string
	bin1Quantity: number
	bin2: string
	bin2Quantity: number
	bin3?: string
	bin3Quantity?: number
	bin4?: string
	bin4Quantity?: number
	bin5?: string
	bin5Quantity?: number
}

export interface InventoryConsolidationReportQueryParams {
	location: string | null
	territory: string | null
}

export interface DispatchHistoryReportLine {
	altCallBackNumber: string | null
	approvalType: string | null
	arrival: string | null
	assignedEmployee: UserMinimal | null
	billNotToExceed: string | null
	billToCustomer: {
		id: string
		name: string
		customerNumber: string
		address1: string | null
		address2: string | null
		address3: string | null
		city: string
		state: string
		postalcode: string
		territory: Territory | null
	} | null
	callBackNumber: string | null
	callReceivedDate: string | null
	callType: string | null
	callerName: string
	closedBy: UserMinimal | null
	closedDate: string | null
	closedReason: string | null
	createdBy: UserMinimal | null
	createdDate: string | null
	customer: {
		id: string
		name: string
		customerNumber: string
		address1: string | null
		address2: string | null
		address3: string | null
		city: string
		state: string
		postalcode: string
		territory: Territory | null
	}
	dispatchNumber: string
	downTime: string | null
	isReturnCall: boolean
	modifiedBy: UserMinimal | null
	modifiedDate: string
	numberOfAdditionalTechs: number | null
	offHoursDispatch: boolean | null
	outOfChemicals: boolean
	poTicket: string | null
	priority: string | null
	responseTime: string | null
	sendEmail: boolean
	source: string | null
	status: string | null
	symptoms: string | null
	washCounter: string | number | null
	woComments: string | null
}

export interface DispatchHistoryReportQueryParams extends OrderingQueryParams {
	organization: string | null
	region: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
	vintage: string | null
	callTypeId: string | null
	statusId: string | null
}

export interface ErpDetailReportLine {
	id: string
	comment: string | null
	createdDate: string
	customer: {
		id: string
		name: string
		customerNumber: string
		city: string
		state: string
		postalcode: string
		territory: Territory | null
	}
	dispatch: {
		id: string
		dispatchNumber: string
	} | null
	erpDeposit: string
	erpStatus: {
		id: number
		description: string
	}
	expirationDate: string | null
	partOrder: {
		id: string
		orderId: string
	} | null
	techNameEmployee: UserMinimal | null
	total: string
}

export interface ErpDetailReportQueryParams extends OrderingQueryParams {
	organization: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
	erpStatus: string | null
}

export interface ErpSummaryReportLine {
	approved: number
	completed: number
	inProgress: number
	month: number
	rejected: number
	submitted: number
	total: number
	void: number
	year: number
}

export interface ErpSummaryReportQueryParams extends OrderingQueryParams {
	organization: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
}

export interface LaborRatesReportLine {
	customerNumber: string
	flat: {
		group: string
		rate: number
	}
	labor: {
		group: string
		rate: number
	}
	name: string // Customer name
	territory: Territory | null
}

export interface LaborRatesReportQueryParams extends OrderingQueryParams {
	organization: string | null
	region: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
}

export interface MilestoneDetailReportLine {
	id: string
	approvedBy: UserMinimal | null
	approvedOn: string | null
	converted: boolean
	customer: CustomerMinimal
	customerEquipment: CustomerEquipmentMinimal
	identifiedOn: string
	milestoneErp: {
		id: string
		dispatch: {
			id: string
			assignedEmployee: UserMinimal | null
			statusDescription: string
			dispatchNumber: string
		} | null
		total: string
	} | null
	rejectedBy: UserMinimal | null
	rejectedOn: string | null
	status: {
		id: string
		description: string
	}
}

export interface MilestoneDetailReportQueryParams extends OrderingQueryParams {
	startDate: string | null
	endDate: string | null
	region: string | null
	territory: string | null
	customer: string | null
}

export interface NegativeOnHandReportLine {
	bin: {
		code: string
		location: {
			description: string
			locationCode: string
		}
	}
	inventory: {
		part: {
			partNumber: string
			description: string
		}
	}
	qty: number
}

export interface NegativeOnHandReportQueryParams extends OrderingQueryParams {
	region: string | null
	location: string | null
	territory: string | null
}

export interface PartOrderDetailReportLine {
	id: string
	actualShipDate: string | null
	billToCustomer: CustomerMinimal | null
	createdBy: UserMinimal | null
	dispatch: {
		id: string
		dispatchNumber: string
	} | null
	kbmOrderNumber: string | null
	orderDate: string
	orderFromWarehouse: {
		id: string
		locationCode: string
		description: string
	} | null
	orderId: number
	orderStatus: {
		id: string
		description: string
	}
	projectedShipTime: string | null
	shipToCustomer: CustomerMinimal | null
	shippedOnTime: boolean | "N/A"
}

export interface PartOrderDetailReportQueryParams extends OrderingQueryParams {
	region: string | null
	territory: string | null
	startDate: string | null
	endDate: string | null
	organization: string | null
	enterprise: string | null
	vehicleCare: true | null
}

export interface PickListDetailReportLine {
	id: string
	actualPickTime: string | null
	createdDate: string
	deliveryOrderId: string | number | null
	partOrder: {
		id: string
		orderId: number
	} | null
	pickedOnTime: boolean | "N/A"
	projectedPickTime: string | null
	status: {
		id: string
		description: string
	}
}

export interface PickListDetailReportQueryParams extends OrderingQueryParams {
	region: string | null
	territory: string | null
	location: string | null
	startDate: string | null
	endDate: string | null
}

export interface PricingModificationsReportLine {
	id: string
	actualPrice: string
	calculatedPrice: string
	createdDate: string
	deliveryOrderLineItem: {
		id: string
		deliveryOrderId: string
	} | null
	lineItemType: {
		id: string
		description: string
	}
	part: {
		id: string
		partNumber: string
		description: string
	} | null
	partOrderLineItem: {
		id: string
		partOrder: {
			id: string
			orderId: number
		}
	} | null
	user: UserMinimal
	workOrderLineItem: {
		id: string
		workorder: {
			id: string
			dispatch: {
				id: string
				dispatchNumber: string
			} | null
		}
	} | null
}

export interface PricingModificationsReportQueryParams extends OrderingQueryParams {
	customer: string | null
	startDate: string | null
	endDate: string | null
}

export interface SalesRepInvoicesReportLine {
	customerName: string
	customerNumber: string
	deliveryOrderRevenue: number
	partOrderRevenue: number
	salesRep: string
	totalInvoiced: string
	workOrderRevenue: number
}

export interface SalesRepInvoicesReportQueryParams {
	user: string | null
	startDate: string | null
	endDate: string | null
}

export interface SalesRepAssignmentsReportLine {
	customerName: string
	customerNumber: string
	role: string
	user: string
}

export interface SalesRepAssignmentsReportQueryParams {
	user: string | null
	customer: string | null
	organization: string | null
	enterprise: string | null
}

export interface SalesRepDetailReportLine {
	customerName: string
	customerNumber: string
	invoiceNumber: string | null
	invoiceStatus: string | null
	orderChemTotal: number
	orderNumber: number
	orderStatus: string
}

export interface SalesRepDetailReportQueryParams {
	user: string | null
	startDate: string | null
	endDate: string | null
}

export interface ServiceTrendReportLine {
	lastYearDispatches: number
	lastYearWorkOrders: number
	territory: {
		id: string
		code: string
		description: string
		region: {
			id: string
			code: string
			description: string
		}
	}
	totalDispatches: number
	totalWorkOrders: number
	weekEnd: string
	weekStart: string
}

export interface ServiceTrendReportQueryParams extends OrderingQueryParams {
	territory: string | null
	startDate: string | null
	endDate: string | null
}

export interface SiteSurveyReportLine {
	assignedDate: string | null
	assignedTo: UserMinimal
	createdDate: string
	customer: {
		id: string
		city: string
		customerNumber: string
		name: string
		postalcode: string
		state: string
		territory: {
			id: string
			code: string
			description: string
			region: {
				id: string
				code: string
				description: string
			}
		}
	} | null
	isSubmitted: boolean
	requestedBy: UserMinimal
}

export interface SiteSurveyReportQueryParams extends OrderingQueryParams {
	organization: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
	isSubmitted: boolean | null
}

export interface SiteSurveyGroupedReportLine {
	bayCondition: string | null
	chemicalLineup: string | null
	city: string | null
	customerNumber: string | null
	id: number
	ledLighting: boolean
	location: string | null
	machineAge: string | null
	machineReplacement: boolean
	marketingOverhaul: boolean
	payTeller: boolean
	roSystem: boolean
	stateZip: string | null
	store: string | null
	survey: string
	washCount: number | null
	washPackages: number | null
	washType: string | null
}

export interface SiteSurveyGroupedReportQueryParams extends OrderingQueryParams {
	organization: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
}

export interface WarehouseOrderReportLine {
	avgDeliveryTime: string
	avgPickTime: string
	avgShipTime: string
	totalLinesDelivered: number
	totalLinesDeliveredOntime: number // cspell:disable-line
	totalLinesInstock: number // cspell:disable-line
	totalLinesPicked: number
	totalLinesPickedOntime: number // cspell:disable-line
	totalLinesPlaced: number
	totalLinesShipped: number
	totalLinesShippedOntime: number // cspell:disable-line
	warehouse_Description: string
	warehouse_LocationCode: string
	warehouseId: number
}

export interface WarehouseOrderReportQueryParams extends OrderingQueryParams {
	territory: string | null
	startDate: string | null
	endDate: string | null
}

export interface WorkOrderDetailReportLine {
	assignedEmployee: UserMinimal
	closedDate: string | null
	customer: {
		id: string
		customerNumber: string
		name: string
		territory: {
			id: string
			code: string
			description: string
			region: {
				id: string
				code: string
				description: string
			}
		} | null
	}
	dispatch: {
		id: string
		dispatchNumber: string
	} | null
	driveTimeHours: string | null
	openDate: string
	total: string
}

export interface WorkOrderDetailReportQueryParams extends OrderingQueryParams {
	organization: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
}

export interface DispatchCountsReportLine {
	closed: number
	completed: number
	month: number
	open: number
	total: number
	year: number
}

export interface DispatchCountsReportQueryParams {
	organization: string | null
	region: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
}

export interface PmpiDetailReportLine {
	completed: boolean
	contract: {
		customer: {
			id: string
			customerNumber: string
			isFstActive: boolean
			name: string
		}
		customerEquipment: CustomerEquipmentMinimal | null
		number: string
		type: string
	}
	dispatch: {
		id: string
		callReceived: string | null
		closedDate: string | null
		dispatchNumber: string
	} | null
	frequency: string | null
	periodEndDate: string
	periodStartDate: string
}

export interface PmpiDetailReportQueryParams extends OrderingQueryParams {
	organization: string | null
	region: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
	notCompleted: boolean | null
}

export interface PmpiSummaryReportLine {
	completed: number
	notCompleted: number
	territoryCode: string | null
	territoryDescription: string | null
	territoryId: number | null
	total: number
}

export interface PmpiSummaryReportQueryParams {
	organization: string | null
	region: string | null
	enterprise: string | null
	territory: string | null
	customer: string | null
	startDate: string | null
	endDate: string | null
}

export enum PnlReportLevelOneTitle {
	TotalCompany = "Total Company",
	Equipment = "Equipment",
	Install = "Install",
	Maintenance = "Maintenance",
	Chemical = "Chemical",
}

export interface PnlReport {
	subCategories: PnlReportCategoryLine[]
	title: PnlReportLevelOneTitle
}

export type PnlReportLine = PnlReportCategoryLine | PnlReportAccountLine

export type PnlReportCategoryLine = {
	budget: string
	diff: string
	order?: number
	subCategories: PnlReportLine[]
	title: string
	total: string
}

export type PnlReportAccountLine = {
	accountNumber: string
	budget: string
	diff: string
	title: string
	total: string
}

export const isPnlReportAccountLine = (
	line: PnlReportCategoryLine | PnlReportAccountLine
): line is PnlReportAccountLine => {
	return (
		!!(line as PnlReportAccountLine).accountNumber &&
		!(line as PnlReportCategoryLine).subCategories
	)
}

export const isPnlReportCategoryLine = (
	line: PnlReportCategoryLine | PnlReportAccountLine
): line is PnlReportCategoryLine => {
	return (
		!(line as PnlReportAccountLine).accountNumber &&
		!!(line as PnlReportCategoryLine).subCategories
	)
}

export interface ProfitBreakdownReport {
	chemicalCogs: number
	chemicalExpenses: number
	chemicalNet: number
	chemicalRevenue: number
	customer?: {
		customerNumber: string
		isFstActive: boolean
		name: string
		id: number
	}
	equipmentCogs: number
	equipmentExpenses: number
	equipmentNet: number
	equipmentRevenue: number
	id: number
	installCogs: number
	installExpenses: number
	installNet: number
	installRevenue: number
	maintenanceCogs: number
	maintenanceExpenses: number
	maintenanceNet: number
	maintenanceRevenue: number
	name: string
	totalCogs: number
	totalExpenses: number
	totalNet: number
	totalRevenue: number
	type: "customer" | "territory"
}

export interface CustomerProfitReport {
	accountName: string
	accountNumber: string
	amount: number
	dispatchId: string | "None"
	invoiceId: string | "None"
	invoiceNumber: string | null
	managerZone: number | null
	partNumber: string | null
	quantity: number
	transactionDate: string // Naive date
}

export interface GetProfitReportsPost {
	customer: string | null
	endMonth: number | null // ID of ProfitabilityMonth
	enterprise: string | null
	organization: string | null
	startMonth: number | null // ID of ProfitabilityMonth
	territory: string | null
}

export interface CustomerPortalUsageSummary {
	meanOrderValue: number
	medianOrderValue: number
	newGuestAccounts: number
	ordersPlaced: number
	totalNewAccounts: number
	totalOrderValue: number
}

export type CustomerPortalUsageSummaryReportQueryParams = {
	startDate: string | null
	endDate: null
}

export interface CustomerPortalPurchasersReportLine {
	id: string
	email: string
	name: string
	isGuest: boolean
	lastPurchased: string
	totalSpent: number
	ordersCount: number
}

export type CustomerPortalPurchasersReportQueryParams = {
	part: string | null
	startDate: string | null
	endDate: null
}
