import { FC, useState } from "react"

import { DataExport } from "@ncs/ncs-api"

import { Button } from "../buttons"
import { LoadingSpinner } from "../transitions"
import { ErrorText, Paragraph } from "../typography"
import { Modal } from "./Modal"

export interface DataExportModalProps {
	isOpen: boolean
	onClose: () => void
	/** The return of a `useDataExport` hook. */
	dataExport: DataExport
}

export const DataExportModal: FC<DataExportModalProps> = ({ isOpen, onClose, dataExport }) => {
	const { requestReport, reportUrl, requestEmailOnCompletion, errorOccurred } = dataExport
	const [isRequestingEmail, setIsRequestingEmail] = useState(false)
	const [hasRequestedEmail, setHasRequestedEmail] = useState(false)

	const reset = () => {
		setIsRequestingEmail(false)
		setHasRequestedEmail(false)
	}

	const onOpen = () => {
		reset()
		requestReport()
	}

	// If you close the modal while it's still generating, we'll request the report for you.
	const closeWithEmail = () => {
		if (!hasRequestedEmail && !reportUrl) {
			void onRequestEmail()
		}
		onClose()
	}

	const onDownload = () => {
		window.open(reportUrl)
		onClose()
	}

	const onRequestEmail = async () => {
		try {
			setIsRequestingEmail(true)
			await requestEmailOnCompletion()
			setHasRequestedEmail(true)
		} catch (e) {
			console.error(e)
		} finally {
			setIsRequestingEmail(false)
		}
	}

	return (
		<Modal
			isOpen={isOpen}
			onClose={closeWithEmail}
			onOpen={onOpen}
			title="Export Data"
			maxWidth="xs"
		>
			{hasRequestedEmail && (
				<Paragraph>
					You'll receive an email when it's ready. You can safely close this window.
				</Paragraph>
			)}

			{!hasRequestedEmail && (
				<>
					{reportUrl ?
						<Paragraph mb={3}>Report ready!</Paragraph>
					:	<>
							<Paragraph mb={1}>
								Your report is being prepared. Depending on the size, this may take
								several minutes.
							</Paragraph>
							<Paragraph mb={1.5}>
								You can leave this modal open and download here when it's ready, or
								get an email with a download link when the report is ready.
							</Paragraph>
							<Paragraph small>
								(If you close this modal, we'll still send you the report link)
							</Paragraph>

							<LoadingSpinner text="Generating..." my={1} />

							<Button
								variant="secondary-cta"
								icon="envelope"
								isLoading={isRequestingEmail}
								fillContainer
								onClick={onRequestEmail}
							>
								Email When Ready
							</Button>
						</>
					}
					<Button
						icon="cloud-download"
						variant="primary-cta"
						onClick={onDownload}
						disabled={!reportUrl}
						fillContainer
						containerProps={{ mt: 1 }}
					>
						Download Report
					</Button>
				</>
			)}

			{errorOccurred && <ErrorText>There was an error requesting the report.</ErrorText>}
		</Modal>
	)
}
