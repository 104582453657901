import { ReactElement, useCallback, useEffect, useState } from "react"

import dayjs, { Dayjs } from "dayjs"

import { DateFormat } from "@ncs/ts-utils"

import { usePrevious } from "../../util"
import { KeyboardDateInput } from "../inputs"

interface KeyboardEndDateParamState {
	endDate: string | null
}

export interface KeyboardEndDateQueryFilterProps<
	QueryParamState extends KeyboardEndDateParamState,
> {
	queryParamState: QueryParamState
	setQueryParamState: React.Dispatch<React.SetStateAction<QueryParamState>>
}

export const KeyboardEndDateQueryFilter = <QueryParamState extends KeyboardEndDateParamState>({
	queryParamState,
	setQueryParamState,
}: KeyboardEndDateQueryFilterProps<QueryParamState>): ReactElement => {
	const [selectedDate, setSelectedDate] = useState(() => {
		const initialDate = dayjs(queryParamState.endDate)

		return initialDate.isValid() ? initialDate : null
	})

	const handleChange = useCallback(
		(newValue: Dayjs | null) => {
			setSelectedDate(newValue)

			const newDate = dayjs(newValue)

			if (newDate.isValid()) {
				setQueryParamState((prev) => ({
					...prev,
					endDate: newValue?.format(DateFormat.DateQueryParam) ?? null,
				}))
			}
		},
		[setQueryParamState]
	)

	const prevQueryParamValue = usePrevious(queryParamState.endDate)
	useEffect(() => {
		if (
			queryParamState.endDate === null &&
			prevQueryParamValue !== null &&
			selectedDate !== null
		) {
			handleChange(null)
		}
	}, [handleChange, prevQueryParamValue, queryParamState.endDate, selectedDate])

	return <KeyboardDateInput label="End date" value={selectedDate} onChange={handleChange} />
}
