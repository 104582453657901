import { useAuth } from "../contexts/auth"

export enum UserId {
	Admin = "1",
	DrivenBrands = "30313",
	TeraStevens = "594",
}

/** Pass a user ID and check if the authed user matches. */
export const useIsUser = (id: UserId): boolean => {
	const { user } = useAuth()

	return !!user && user.id === id
}
