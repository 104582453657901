import { FC, memo, useMemo } from "react"

import { useTheme } from "@emotion/react"
import dayjs from "dayjs"
import { z } from "zod"

import { PortalReportName, ReportTimeSpan } from "@ncs/ncs-api"
import { DateFormat, getTimeAgoStartDate, TimeAgo } from "@ncs/ts-utils"
import {
	ChartCard,
	ChartType,
	ConnectedDeviceSelector,
	formatDateTimeTick,
	GenericReportChart,
	makeChartOverlayBundle,
	ReportFrequencySelector,
	ReportOverlayBundle,
	Select,
	TimeAgoSelector,
	useLocalStorageState,
	validateChartConfiguration,
} from "@ncs/web-legos"

import { useCustomerConnectivityReports } from "~/util"

export interface MachineEventsChartProps {
	customerId: string
}

export const MachineEventsChart: FC<MachineEventsChartProps> = memo(({ customerId }) => {
	const theme = useTheme()
	const [{ frequency, timeAgo, selectedDeviceId, selectedOverlayReport }, setParamState] =
		useLocalStorageState<ReportPreferences>(
			{
				frequency: ReportTimeSpan.Daily,
				timeAgo: TimeAgo.WeeksAgo2,
				selectedDeviceId: null,
				selectedOverlayReport: null,
			},
			{
				keyOverride: `machine-events-chart-${customerId}`,
				validate: (values) => {
					return ReportPreferencesSchema.safeParse(values).success
				},
			}
		)

	const reports = useCustomerConnectivityReports({
		deviceId: selectedDeviceId,
		customerId,
		timeAgo,
		frequency,
	})

	const overlayReportBundle = useMemo((): ReportOverlayBundle | undefined => {
		return makeChartOverlayBundle(selectedOverlayReport, {
			[PortalReportName.WashCounts]: reports[PortalReportName.WashCounts],
			[PortalReportName.Weather]: reports[PortalReportName.Weather],
		})
	}, [reports, selectedOverlayReport])

	const reportConfigError = useMemo(() => {
		return validateChartConfiguration({
			report: reports[PortalReportName.MachineEvents],
			overlayReportBundle,
			loading: reports.isLoading,
			params: {
				frequency,
				customer: customerId,
			},
		})
	}, [frequency, overlayReportBundle, reports, customerId])

	return (
		<ChartCard
			heading="Device Event Counts"
			renderRight={() =>
				`${getTimeAgoStartDate(timeAgo)?.format(DateFormat.Date)} - ${dayjs().format(
					DateFormat.Date
				)}`
			}
		>
			<GenericReportChart
				report={reports["Machine Events"]}
				isLoading={reports.isLoading}
				defaultChartType={ChartType.Bar}
				errorMessageText={reportConfigError}
				xAxisTickFormatter={(value) => formatDateTimeTick(value, frequency, timeAgo)}
				tooltipLabelFormatter={(value) => formatDateTimeTick(value, frequency, timeAgo)}
				showLegend={false}
				overlayReportBundle={overlayReportBundle}
				filters={[
					<ConnectedDeviceSelector
						key="device"
						fillContainer
						customerId={customerId}
						value={selectedDeviceId}
						onChange={(newValue) => {
							setParamState((prev) => ({ ...prev, selectedDeviceId: newValue }))
						}}
						showNoSelectionOption={false}
					/>,
					<TimeAgoSelector
						key="TimeAgoSelector"
						fillContainer
						value={timeAgo}
						onChange={(newValue) => {
							if (newValue) {
								setParamState((prev) => ({ ...prev, timeAgo: newValue }))
							}
						}}
						showNoSelectionOption={false}
						reportFrequency={frequency}
					/>,
					<ReportFrequencySelector
						key="ReportFrequencySelector"
						value={frequency}
						onChange={(newValue) => {
							setParamState((prev) => ({ ...prev, frequency: newValue }))
						}}
						fillContainer
						timeAgo={timeAgo}
					/>,
					<Select
						key="overlayReportSelector"
						label="Data overlay"
						fillContainer
						disableNoSelectionOption={false}
						value={selectedOverlayReport}
						onChange={(newValue, newName) => {
							setParamState((prev) => ({
								...prev,
								selectedOverlayReport: newName?.value ?? null,
							}))
						}}
						options={[
							{
								value: PortalReportName.Weather,
								text: PortalReportName.Weather,
							},
							{
								value: PortalReportName.WashCounts,
								text: PortalReportName.WashCounts,
							},
						]}
					/>,
				]}
				colorArray={[theme.palette.chartColors[5]]}
			/>
		</ChartCard>
	)
})

const ReportPreferencesSchema = z.object({
	frequency: z.nativeEnum(ReportTimeSpan),
	timeAgo: z.nativeEnum(TimeAgo),
	selectedDeviceId: z.string().nullable(),
	selectedOverlayReport: z.nativeEnum(PortalReportName).nullable(),
})

type ReportPreferences = z.infer<typeof ReportPreferencesSchema>

MachineEventsChart.displayName = "MachineEventsChart"
