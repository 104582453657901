import React, { memo, useCallback, useState } from "react"

import { DatePicker, DatePickerProps } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import dayjs, { Dayjs } from "dayjs"

import { DateFormat } from "@ncs/ts-utils"

import { FieldContainer, FieldContainerProps } from "./FieldContainer"
import { TextInput } from "./TextInput"

export interface DateInputProps extends FieldContainerProps {
	value: Dayjs | null
	onChange: (newDate: Dayjs | null) => void
	datePickerProps?: Omit<
		DatePickerProps,
		"onChange" | "value" | "clearable" | "disablePast" | "shouldDisableDate" | "disabled"
	>
	onBlur?: () => void
	clearable?: boolean
	disabled?: boolean
	disablePast?: boolean
	disableToday?: boolean
	shouldDisableDate?: (day: Dayjs) => boolean
}

export const DateInput: React.FC<DateInputProps> = memo(
	({
		value,
		onChange,
		datePickerProps,
		onBlur,
		clearable,
		disabled,
		disablePast,
		disableToday,
		shouldDisableDate: shouldDisableDateProp,
		...rest
	}) => {
		const [showPicker, setShowPicker] = useState(false)

		const shouldDisableDate = useCallback(
			(day: MaterialUiPickersDate) => {
				const today = dayjs()
				const dayjsDay = dayjs(day)

				if (shouldDisableDateProp) {
					if (shouldDisableDateProp(dayjsDay) === true) {
						return true
					}
				}
				if (disableToday && dayjsDay.isSame(today, "day")) {
					return true
				}
				if (disablePast && dayjsDay.isBefore(today, "day")) {
					return true
				}

				return false
			},
			[disablePast, disableToday, shouldDisableDateProp]
		)

		return (
			<FieldContainer label="Date" {...rest}>
				<DatePicker
					autoOk
					value={value}
					onChange={onChange}
					format={DateFormat.Date}
					emptyLabel="Choose date..."
					clearable={clearable}
					disabled={disabled}
					shouldDisableDate={shouldDisableDate}
					TextFieldComponent={() => {
						return (
							<TextInput
								value={value?.format(DateFormat.Date)}
								onClick={() => {
									if (!disabled) {
										setShowPicker(true)
									}
								}}
								placeholder="Choose date..."
								icon="calendar"
								mb={0}
								disabled={disabled}
							/>
						)
					}}
					open={showPicker}
					onClose={() => {
						setShowPicker(false)
						// Fudge the onBlur here.
						if (onBlur) onBlur()
					}}
					{...datePickerProps}
				/>
			</FieldContainer>
		)
	}
)
