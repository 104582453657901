import React, { memo } from "react"

import { Modal, Paragraph } from "@ncs/web-legos"

import { useShopContext } from "~/contexts"

export interface RemoveFromCartModalProps {
	isOpen: boolean
	onClose: () => void
	partId: string | null
	partTitle: string | null
}

export const RemoveFromCartModal: React.FC<RemoveFromCartModalProps> = memo(
	({ isOpen, onClose, partId, partTitle }) => {
		const [, shopDispatch] = useShopContext()

		if (!partId) {
			return null
		}

		const onRemove = () => {
			if (partId) {
				shopDispatch({
					type: "remove from cart",
					payload: partId,
				})
			}
			onClose()
		}

		return (
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				rightButtons={{
					buttonText: "Remove",
					variant: "secondary-cta",
					onClick: onRemove,
				}}
			>
				<Paragraph>
					Remove <strong>{partTitle}</strong> from your cart?
				</Paragraph>
			</Modal>
		)
	}
)
