import isEqual from "lodash/isEqual"
import cloneDeep from "lodash/cloneDeep"

/**
 * Add an item to an array if it doesn't exist already. If it does, replace the old
 * one with the new one. Note that this does a wholesale replacement of old item with new item.
 * Could be extended to allow merging as well.
 */
export const addOrReplace = <Item>(
	newItem: Item,
	list: Item[],
	/** Called with each item in the list. Return true if it's a match, otherwise false. */
	compareFn: (itemToCompare: Item, index: number) => boolean
): Item[] => {
	const existingInstance = list.find((itemToCompare, i) => compareFn(itemToCompare, i))
	const newList = cloneDeep(list)

	return existingInstance ?
			newList.map((item, i) => (compareFn(item, i) ? newItem : item))
		:	[...newList, newItem]
}

/**
 * Add an item to the front of an array if it's not there already, or if it does
 * exist already, move it to the front.
 */
export const addOrPromote = <Item>(
	item: Item,
	list: Item[],
	/** Function will be called to see if each item in the array is the same as
	 * the item you're trying to add. Defaults to lodash deepEqual. */
	compareFn?: (item: Item) => boolean
): Item[] => {
	const filteredList = list.filter((itemToCompare) =>
		compareFn ? !compareFn(itemToCompare) : !isEqual(itemToCompare, item)
	)
	return [item, ...filteredList]
}

/** Add an item to an array if it's not in there already. Defaults to insertion at the beginning of the array. */
export const addIfNew = <Item>(
	item: Item,
	list: Item[],
	config?: {
		compareFn?: (existingItem: Item) => boolean
		method?: "push" | "unshift"
	}
): Item[] => {
	const compareFn =
		config?.compareFn ?
			config.compareFn
		:	(itemToCompare: Item) => isEqual(item, itemToCompare)

	if (list.every((existingItem) => compareFn(existingItem) === false)) {
		config?.method === "push" ? list.push(item) : list.unshift(item)
	}

	return [...list]
}

/**
 * Check if an item exists in an array, and if it does, remove, otherwise, add it.
 * Currently only as smart as `Array.includes`.
 */
export const toggleArrayItem = <Item>(item: Item, list: Item[]): Item[] => {
	if (list.includes(item)) {
		return [...list.filter((i) => i !== item)]
	} else {
		return [...list, item]
	}
}

/**
 * Takes an item that can optionally be an array and makes it an array by wrapping
 * it in one if necessary.
 */
export const arrayWrap = <Item>(item: Item | Item[]): Item[] => {
	if (Array.isArray(item)) {
		return item
	}

	return [item]
}

/**
 * Takes an item that might be an array, and if it is, return the first element in it,
 * otherwise, return the item itself.
 */
export const arrayUnwrap = <Item>(item: Item | Item[]): Item | null => {
	if (Array.isArray(item)) {
		return item[0] ?? null
	}

	return item
}

/**
 * Filters out nullish values from an array in a way that TypeScript understands.
 */
export const noNullish = <Item>(array: (Item | null | undefined)[]): Item[] => {
	return array.flatMap((value) => (value != null ? [value] : []))
}

/**
 * Filters out falsy values from an array in a way that TypeScript understands.
 */
export const noFalsy = <Item>(array: (Item | null | undefined)[]): Item[] => {
	return array.flatMap((value) => (value ? [value] : []))
}

/**
 * Move an element in an array by passing the index that it's at currently and
 * the index you'd like it to be at in the resulting new array.
 *
 * Note that indexes should be zero-based.
 */
export const moveListItem = <Item>(
	currentIndex: number,
	newIndex: number,
	list: Item[]
): Item[] => {
	const result: Item[] = []
	const itemToMove = list[currentIndex]

	list.forEach((item, i) => {
		// When you hit the item to move, don't do anything.
		if (i === currentIndex) {
			return
		}
		// When you hit the new index, add the item to move as well the the thing
		// that was there before. The order of these two should depend on if the moved
		// thing is going forwards to backwards.
		else if (i === newIndex) {
			const itemsToAdd = newIndex > currentIndex ? [item, itemToMove] : [itemToMove, item]
			result.push(...itemsToAdd)
		} else {
			result.push(item)
		}
	})

	return result
}
