import { FC } from "react"

import { ExtendableSelectProps, Select } from "../inputs"

export interface TwoDigitCodeStatusSelectorProps
	extends ExtendableSelectProps<(typeof options)[number], boolean> {}

const options = [
	{
		text: "Currently active states",
		value: "true",
	},
	{
		text: "Previous states",
		value: "false",
	},
]

export const TwoDigitCodeStatusSelector: FC<TwoDigitCodeStatusSelectorProps> = ({
	value,
	onChange,
	...rest
}) => {
	return (
		<Select
			label="Status"
			disableNoSelectionOption={false}
			noSelectionOptionText="Any"
			{...rest}
			value={
				value != null ?
					value === true ?
						"true"
					:	"false"
				:	null
			}
			onChange={(newValue) => {
				newValue != null ? onChange(newValue === "true") : onChange(null)
			}}
			options={options}
		/>
	)
}
