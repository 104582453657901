import { css, Theme } from "@emotion/react"
import { Paragraph } from "@ncs/web-legos/components"
import React from "react"
import { NavLink } from "react-router-dom"

const FooterNavigation: React.FC = () => (
	<nav css={footerNavigation} aria-label="Footer Navigation">
		<ul>
			<li>
				<Paragraph customColor="#87CEFA" bold mb={1}>
					SHOP
				</Paragraph>
			</li>
			<li>
				<NavLink
					to="/shop/search?categories=28&ordering=%2522title%2522"
					aria-label="Shop for Parts"
				>
					Parts
				</NavLink>
			</li>
			<li>
				<NavLink
					to="/shop/search?categories=%255B%252220%2522%252C%252215%2522%255D&ordering=%2522title%2522"
					aria-label="Shop for Chemicals"
				>
					Chemicals
				</NavLink>
			</li>
			{/* <li>
				<NavLink to="/missing_url" aria-label="Shop for Detailing products">
					Detailing
				</NavLink>
			</li>
			<li>
				<NavLink to="/missing_url" aria-label="Shop for Trending items">
					Trending
				</NavLink>
			</li>
			<li>
				<NavLink to="/missing_url" aria-label="Shop for Clearance items">
					Clearance
				</NavLink>
			</li> */}
		</ul>
	</nav>
)

export default FooterNavigation

const footerNavigation = (theme: Theme) => css`
	${theme.breakpoints.down("xs")} {
		margin: 3rem 0;
	}
	li {
		margin-bottom: 1rem;
		&:last-child {
			margin-bottom: 0;
		}
	}

	li a {
		color: #ffffff;
		text-decoration: none;
		font-weight: 500;

		&:focus {
			outline: 2px dashed #87cefa;
			outline-offset: 2px;
		}
	}
`
