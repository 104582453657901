import React, { memo, useMemo } from "react"

import { css, Theme } from "@emotion/react"
import { useHistory } from "react-router-dom"

import {
	APPLICATION,
	CreditCard,
	PaymentMethod,
	StripeBankAccount,
	StripeBankAccountStatus,
	useCustomerPaymentMethods,
	useSites,
	useUserCanUse,
} from "@ncs/ncs-api"
import { titleCase } from "@ncs/ts-utils"
import {
	AnimatedEntrance,
	Box,
	Button,
	encodeUrlState,
	LoadingSpinner,
	Paragraph,
	RadioGroup,
	Select,
	SimpleButton,
	SquareCardForm,
	useChangeCallback,
} from "@ncs/web-legos"

import { AccountTabName, AccountUrlState } from "~/views/account"

export interface PaymentSelectorProps {
	paymentMethodValue: PaymentMethod | null
	storedCardValue: CreditCard | null
	bankAccountValue: StripeBankAccount | null
	onChangeMethod: (newValue: PaymentMethod) => void
	onChangeStoredCard: (newValue: CreditCard) => void
	onChangeBankAccount: (newValue: StripeBankAccount) => void
	hiddenMethods?: PaymentMethod[]
	onNewCard?: () => void
	onNewBankAccount?: () => void
}

export const PaymentSelector: React.FC<PaymentSelectorProps> = memo(
	({
		paymentMethodValue,
		storedCardValue,
		bankAccountValue,
		onChangeMethod,
		onChangeStoredCard,
		onChangeBankAccount,
		hiddenMethods: hiddenMethodsProp = [],
		onNewBankAccount,
		onNewCard,
	}) => {
		const history = useHistory()
		const canSeePaymentInfo = !useUserCanUse(APPLICATION.HidePaymentInfo)
		const [sites, sitesLoading] = useSites()
		const [customerPaymentMethods, loading] = useCustomerPaymentMethods()
		const cards = customerPaymentMethods?.cards ? customerPaymentMethods.cards : []
		const bankAccounts =
			customerPaymentMethods?.bankAccounts ? customerPaymentMethods.bankAccounts : []

		// When customer payment methods first come in, set the defaults as best we can.
		useChangeCallback(
			customerPaymentMethods,
			(newPaymentMethods) => {
				// Because of a Square Form bug, don't automatically switch away when it is the current value.
				if (newPaymentMethods && paymentMethodValue !== PaymentMethod.SQUARE_CARD) {
					const defaultSource = newPaymentMethods.defaultPaymentMethod?.source

					if (defaultSource === "card") {
						onChangeMethod(PaymentMethod.STORED_CARD)
						const defaultCard = newPaymentMethods.cards.find(
							(c) => c.id === newPaymentMethods.defaultPaymentMethod?.id
						)

						if (defaultCard) {
							onChangeStoredCard(defaultCard)
						} else if (newPaymentMethods.cards.length === 1) {
							onChangeStoredCard(newPaymentMethods.cards[0])
						}
					}

					if (defaultSource === "bank_account") {
						onChangeMethod(PaymentMethod.STORED_BANK_ACCOUNT)
						const defaultBankAccount = newPaymentMethods.bankAccounts.find(
							(account) => account.id === newPaymentMethods.defaultPaymentMethod?.id
						)

						if (
							defaultBankAccount &&
							defaultBankAccount.status === StripeBankAccountStatus.VERIFIED
						) {
							onChangeBankAccount(defaultBankAccount)
						} else if (newPaymentMethods.bankAccounts.length === 1) {
							onChangeBankAccount(newPaymentMethods.bankAccounts[0])
						}
					}
				}
			},
			{ callOnSetup: true }
		)

		const handleMethodChange = async (newMethod: PaymentMethod) => {
			onChangeMethod(newMethod)

			// If user chose card, check if they have only one card. If so, select it automatically.
			if (newMethod === PaymentMethod.STORED_CARD && cards.length === 1) {
				onChangeStoredCard(cards[0])
			}
			// If user chose account, check if they have only one account. If so, select it automatically,
			// as long as it's verified.
			if (
				newMethod === PaymentMethod.STORED_BANK_ACCOUNT &&
				bankAccounts.length === 1 &&
				bankAccounts[0].status === StripeBankAccountStatus.VERIFIED
			) {
				onChangeBankAccount(bankAccounts[0])
			}
		}

		const bankAccountSelectValue =
			bankAccountValue ?
				`${bankAccountValue.routingNumber}${bankAccountValue.accountNumberLast4}`
			:	null
		const storedCardSelectValue =
			storedCardValue ? Object.values(storedCardValue).join("") : null

		const hiddenMethods = useMemo(() => {
			// Hide the Bill to Account option if there are no sites.
			if (sitesLoading || (sites ?? []).length === 0) {
				return [...hiddenMethodsProp, PaymentMethod.CUSTOMER_ACCOUNT]
			} else {
				// But if there are indeed sites, then just go with whatever happens to be passed into the component.
				return hiddenMethodsProp
			}
		}, [hiddenMethodsProp, sites, sitesLoading])

		return (
			<div css={container}>
				<div css={methodPicker}>
					<RadioGroup
						htmlName="payment-method"
						options={paymentOptions.filter(
							(option) => !hiddenMethods.includes(option.value)
						)}
						value={paymentMethodValue}
						onChange={(newValue, option) => handleMethodChange(option.value)}
					/>
				</div>

				<div css={methodContent}>
					<AnimatedEntrance
						show={paymentMethodValue === PaymentMethod.STORED_BANK_ACCOUNT}
					>
						{loading ?
							<LoadingSpinner />
						:	<>
								{bankAccounts.length > 0 ?
									<Select
										label="Select an account"
										value={bankAccountSelectValue}
										options={bankAccounts}
										valueAccessor={(account) =>
											`${account.routingNumber}${account.accountNumberLast4}`
										}
										disabledAccessor={(account) =>
											account.status !== StripeBankAccountStatus.VERIFIED
										}
										textAccessor={(account) =>
											`${account.bankName} (...${
												account.accountNumberLast4
											})${
												(
													account.status !==
													StripeBankAccountStatus.VERIFIED
												) ?
													" (unverified)"
												:	""
											}`
										}
										onChange={(newValue, newAccount) => {
											if (newAccount) onChangeBankAccount(newAccount)
										}}
									/>
								:	<Paragraph color="secondary">
										No bank accounts stored on file.
									</Paragraph>
								}

								{onNewBankAccount && canSeePaymentInfo && (
									<Box mt={0.5}>
										<Button icon="plus" onClick={onNewBankAccount}>
											Manage bank accounts
										</Button>
									</Box>
								)}
							</>
						}
					</AnimatedEntrance>

					<AnimatedEntrance show={paymentMethodValue === PaymentMethod.STORED_CARD}>
						{loading ?
							<LoadingSpinner />
						:	<>
								{cards.length > 0 ?
									<Select
										label="Select a card"
										value={storedCardSelectValue}
										options={cards}
										valueAccessor={(c) => Object.values(c).join("")}
										textAccessor={(c) =>
											`${titleCase(c.brand)} (...${c.last4})`
										}
										onChange={(newValue, newCard) => {
											if (newCard) onChangeStoredCard(newCard)
										}}
									/>
								:	<Paragraph color="secondary">No cards stored on file.</Paragraph>}

								{onNewCard && canSeePaymentInfo && (
									<Box mt={0.5}>
										<Button icon="plus" onClick={onNewCard}>
											Manage cards
										</Button>
									</Box>
								)}
							</>
						}
					</AnimatedEntrance>

					{paymentMethodValue === PaymentMethod.SQUARE_CARD && (
						<AnimatedEntrance show>
							<SquareCardForm
								helperText={
									onNewCard ?
										<Paragraph small color="secondary" mb={1}>
											Your card will not be stored on our servers. You can{" "}
											<SimpleButton
												onClick={() => {
													onChangeMethod(PaymentMethod.STORED_CARD)
													history.push({
														pathname: "/account",
														search: encodeUrlState<AccountUrlState>({
															tab: AccountTabName.Billing,
															from: "checkout",
														}),
													})
												}}
											>
												add a card
											</SimpleButton>{" "}
											to your account if you'd like to use it again in the
											future.
										</Paragraph>
									:	undefined
								}
							/>
						</AnimatedEntrance>
					)}
				</div>
			</div>
		)
	}
)

const container = (theme: Theme) => css`
	display: grid;
	grid-template-columns: auto 1fr;
	${theme.breakpoints.down("xs")} {
		grid-template-columns: 1fr;
	}
`
const methodPicker = css`
	padding-right: 3rem;
	margin-bottom: 2rem;
`
const methodContent = css`
	margin-top: 0.5rem;
	margin-bottom: 2rem;
	min-width: 20rem;
`

const paymentOptions = [
	{
		label: "Charge to account",
		value: PaymentMethod.CUSTOMER_ACCOUNT,
	},
	{
		label: "ACH Bank transfer",
		value: PaymentMethod.STORED_BANK_ACCOUNT,
	},
	{
		label: "Saved credit/debit card",
		value: PaymentMethod.STORED_CARD,
	},
	{
		label: "Credit/debit card",
		value: PaymentMethod.SQUARE_CARD,
	},
]
