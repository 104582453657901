import { FC, useState } from "react"

import { css, Theme } from "@emotion/react"

import { SupportIssueModal } from "~/shared-components"

export const HelpFab: FC = () => {
	const [showTicketModal, setShowTicketModal] = useState(false)

	return (
		<>
			<button css={fabContainerCss} onClick={() => setShowTicketModal(true)}>
				?
			</button>

			<SupportIssueModal
				isOpen={showTicketModal}
				onClose={() => setShowTicketModal(false)}
			/>
		</>
	)
}

const fabContainerCss = ({ palette }: Theme) => css`
	display: flex;
	position: fixed;
	justify-content: center;
	align-items: center;
	font-size: 1.75rem;
	font-weight: bold;
	bottom: 2rem;
	right: 2rem;
	padding-top: 5px;
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 50%;
	background: white;
	border: 3px solid black;
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.35);
	opacity: 0.5;
	transition-duration: 200ms;
	transition-timing-function: ease-out;
	transition-property: color, border-color, background, opacity, transform;
	&:hover {
		transform: scale(1.1);
		opacity: 1;
		color: white;
		border-color: ${palette.primary.main};
		background-color: ${palette.primary.main};
	}
`
