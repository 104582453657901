import React from "react"

import { Control, Controller, FieldValues, Path } from "react-hook-form"

import { Select, SelectProps } from "../inputs"

export interface SelectFormFieldProps<TFieldValues extends FieldValues, Options>
	extends Omit<SelectProps<Options>, "value" | "onChange"> {
	name: Path<TFieldValues>
	control: Control<TFieldValues>
	emptyValueFallback?: string | null
}

export const SelectFormField = <TFieldValues extends FieldValues, Options>({
	name,
	control,
	emptyValueFallback,
	...rest
}: SelectFormFieldProps<TFieldValues, Options>): React.ReactElement => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { onBlur, value, onChange }, fieldState: { error } }) => {
				return (
					<Select
						{...rest}
						onBlur={onBlur}
						value={value != null ? String(value) : emptyValueFallback ?? null}
						onChange={(newValue) => onChange(newValue ?? emptyValueFallback ?? null)}
						error={error?.message}
					/>
				)
			}}
		/>
	)
}
